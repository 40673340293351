import React from 'react';
import { Flex } from '@rebass/grid';
import { Spinner } from '../Spinner/Spinner';
import styled from 'styled-components/macro';

const Wrapper = styled(Flex)`
  height: 50vh;
`;

export const Loading = () => {
  return (
    <Wrapper
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      width={1}
      mt={5}
    >
      <Spinner />
    </Wrapper>
  );
};
