import React, { FC, ReactNode, useState } from 'react';
import { FilingDetails } from '../models/filing-details';
import { useImmer } from 'use-immer';
import { privateApi } from '../utils/api-adapter';
import { Document } from '../models/document';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import { IVoteProposal, IElectionItems } from '../models/vote-proposal';

interface FilingProviderStore {
  addDocument: (document: Document) => void;
  deleteDocument: (id: number) => void;
  deleteFiling: (id: string, backButtonPath: string) => void;
  documentIdEditing: number | null;
  fetchFiling: (id: string) => void;
  filing: FilingDetails | null;
  isEditDocumentDialogOpen: boolean;
  isLoading: boolean;
  isNewDocumentDialogOpen: boolean;
  resetFiling: () => void;
  setEditDocumentDialogOpen: (isOpen: boolean) => void;
  setDocumentIdEditing: (id: number | null) => void;
  setNewDocumentDialogOpen: (isOpen: boolean) => void;
  updateDocument: (document: Document) => void;
  addVoteProposals: (voteProposals: IVoteProposal[]) => void;
  updateVoteProposals: (VoteProposals: IVoteProposal[]) => void;
  updateElectionItems: (ElectionItems: IElectionItems[]) => void;
  addElectionItems: (ElectionItems: IElectionItems[]) => void;
  updateFilingOnSuccess: (filing: FilingDetails) => void;
}

export const FilingContext = React.createContext({} as FilingProviderStore);

export const FilingProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [filing, updateFiling] = useImmer<FilingDetails | null>(null);
  const [documentIdEditing, setDocumentIdEditing] = useState<number | null>(
    null,
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isNewDocumentDialogOpen, setNewDocumentDialogOpen] = useState<boolean>(
    false,
  );
  const [isEditDocumentDialogOpen, setEditDocumentDialogOpen] = useState<
    boolean
  >(false);

  const fetchFiling = async (id: string) => {
    try {
      setLoading(true);
      const fetchFilingResponse = await privateApi.get(`/filings/${id}/`);
      setLoading(false);
      updateFiling((draft) => (draft = fetchFilingResponse.data));
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const addDocument = (document: Document) => {
    updateFiling((draft) => {
      if (draft) {
        draft.documents.push(document);
      }
    });
  };

  const updateDocument = (doc: Document) => {
    updateFiling((draft) => {
      if (draft) {
        draft.documents[
          draft.documents.findIndex((document) => document.id === doc.id)
        ] = doc;
      }
    });
  };

  const addVoteProposals = (voteProposals: IVoteProposal[]) => {
    updateFiling((draft) => {
      if (draft && draft.voteProposals) {
        draft.voteProposals.push(...voteProposals);
      }
    });
  };

  const updateVoteProposals = (voteProposals: IVoteProposal[]) => {
    updateFiling((draft) => {
      if (draft && draft.voteProposals) {
        draft.voteProposals = [...voteProposals];
      }
    });
  };

  const addElectionItems = (electionItems: IElectionItems[]) => {
    updateFiling((draft) => {
      if (draft && draft.electionItems) {
        draft.electionItems.push(...electionItems);
      }
    });
  };

  const updateElectionItems = (electionItems: IElectionItems[]) => {
    updateFiling((draft) => {
      if (draft && draft.electionItems) {
        draft.electionItems = [...electionItems];
      }
    });
  };

  const deleteDocument = async (id: number) => {
    try {
      await privateApi.delete(`/attachments/${id}/`);
      updateFiling((draft) => {
        if (draft) {
          draft.documents = draft.documents.filter(
            (document) => document.id !== id,
          );
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const deleteFiling = async (id: string, backButtonPath: string) => {
    try {
      await privateApi.delete(`/filings/${id}/`);
      updateFiling((draft) => {
        if (draft) {
          draft = null;
        }
      });
      navigate(backButtonPath);
      toast.success('Filing was deleted.');
    } catch (error) {
      toast.error(error);
    }
  };

  const resetFiling = () => {
    updateFiling((draft) => (draft = {} as FilingDetails));
    setLoading(false);
  };

  const updateFilingOnSuccess = (filing: FilingDetails) => {
    updateFiling((draft) => (draft = filing));
  };

  const store: FilingProviderStore = {
    addDocument,
    deleteDocument,
    deleteFiling,
    documentIdEditing,
    fetchFiling,
    filing,
    isEditDocumentDialogOpen,
    isLoading,
    isNewDocumentDialogOpen,
    resetFiling,
    setDocumentIdEditing,
    setEditDocumentDialogOpen,
    setNewDocumentDialogOpen,
    updateFilingOnSuccess,
    updateDocument,
    addVoteProposals,
    updateVoteProposals,
    addElectionItems,
    updateElectionItems,
  };

  return (
    <FilingContext.Provider value={store}>{children}</FilingContext.Provider>
  );
};
