import axios, { AxiosTransformer } from 'axios';
import humps from 'humps';
import { getTokenFromCookies } from '../utils/get-token';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { refreshToken } from '../utils/refresh-auth-token';

const axiosConfiguration = {
  baseURL: `${process.env.REACT_APP_HUB_URL}/v1`,
  transformRequest: [
    (data: any) => humps.decamelizeKeys(data),
    ...(axios.defaults.transformRequest as AxiosTransformer[]),
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    (data: any) => humps.camelizeKeys(data),
  ],
};

const axiosFormDataConfiguration = {
  baseURL: `${process.env.REACT_APP_HUB_URL}/v1`,
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    (data: any) => humps.camelizeKeys(data),
  ],
};

const hubApi = axios.create(axiosConfiguration);
const hubApiFormData = axios.create(axiosFormDataConfiguration);

createAuthRefreshInterceptor(hubApi, refreshToken, {
  skipWhileRefreshing: false,
});
createAuthRefreshInterceptor(hubApiFormData, refreshToken, {
  skipWhileRefreshing: false,
});

hubApi.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `JWT ${getTokenFromCookies()}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

hubApi.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    switch (error.response.status) {
      case 404:
        break;
      case 500:
        break;
      default:
        break;
    }

    return Promise.reject(error);
  },
);

hubApiFormData.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = `JWT ${getTokenFromCookies()}`;
    config.headers['Content-Type'] = 'multipart/form-data';
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

hubApiFormData.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    switch (error.response.status) {
      case 404:
        break;
      case 500:
        break;
      default:
        break;
    }

    return Promise.reject(error);
  },
);

export { hubApi, hubApiFormData };
