import React from 'react';

type LabelProps = {
  htmlFor?: string;
  children: React.ReactNode;
  required?: boolean;
  showOptional?: boolean;
};

function Label({
  htmlFor,
  children,
  required = false,
  showOptional = false,
}: LabelProps) {
  return (
    <label
      htmlFor={htmlFor}
      className="text-xxs uppercase font-medium text-gray mb-1"
    >
      {children}{' '}
      {required ? <Asterisk /> : showOptional ? <OptionalText /> : null}
    </label>
  );
}

function Asterisk() {
  return <span className="text-red">*</span>;
}

function OptionalText() {
  return <span>(Optional)</span>;
}

export { Label };
