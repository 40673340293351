import React, { useState, FC, FormEvent } from 'react';
import Dropzone from 'react-dropzone';
import { Text } from '../common/Text';
import { privateApiAsFormData } from '../utils/api-adapter';
import { Label } from '../common/Label';
import { Select } from '../common/Select';
import { Asterisk } from '../common/Asterik/Asterik';
import styled from 'styled-components/macro';
import {
  border,
  BorderProps,
  borderColor,
  BorderColorProps,
  borderRadius,
  BorderRadiusProps,
} from 'styled-system';
import { Button } from '../common/Buttons/Button';
import { OutlineButton } from '../common/Buttons/OutlineButton';
import { InputGroup } from '../common/InputGroup';
import { FilingType } from '../models/filing-type';
import { Flex } from '@rebass/grid';
import { formatCamelCaseString } from '../utils/format-camel-case-string';
import { toast } from 'react-toastify';

const Link = styled(Text)`
  display: block;
  text-decoration: none;
`;

// eslint-disable-next-line
const DropWrapper = styled(Flex)<
  BorderProps & BorderColorProps & BorderRadiusProps
>`
  ${border}
  ${borderColor}
  ${borderRadius}
  height: 80px;
  width: 340px;
`;

const calculateInstructionsLink = (filingType: FilingType): string => {
  switch (filingType) {
    case FilingType.FundReport:
      return 'https://docs.google.com/spreadsheets/d/1lQCaNSfHXDGLaN0xzYDWq0HM4RzDq-Lmlf3mxZrP9zQ';
    case FilingType.FundMeeting:
      return 'https://docs.google.com/spreadsheets/d/1nvAOWWX5drY7DR6TpRPrH2jBuB28eRpzpg2MTJaOBH0';
    case FilingType.FirmMeeting:
      return 'https://docs.google.com/spreadsheets/d/1VFy4eXN9TUsXDyHnpvdDDEMWsEiSrMnp1xUDjXzMXEU';
    case FilingType.FirmInformationStatement:
      return 'https://docs.google.com/spreadsheets/d/1YoPWGHqiJ6RQ8IrV26kh7hb0n861FaF9m3MZJlECAxk';
    case FilingType.FirmConsentSolicitation:
      return 'https://docs.google.com/spreadsheets/d/1Qd8EJhIThf9jHBjXBqBsIdgLGQdjAyk75Hqr7fYXIGc';
    case FilingType.MeetingContest:
      return 'https://docs.google.com/spreadsheets/d/112Wnd7szmIGN_doBF70ovTDHj_S080SNnDCY0mUv7u4';
    case FilingType.FundInformationStatement:
      return 'https://docs.google.com/spreadsheets/d/1TLA1UnsZUi-T54SX2YT2xD-XVdbP2JoFsytcr7tR1f8';
    default:
      return '#';
  }
};

interface MultipleFilingsProps {
  onClose: () => void;
}

export const MultipleFilings: FC<MultipleFilingsProps> = ({ onClose }) => {
  const formData = new FormData();
  const filingTypes = Object.values(FilingType);
  const [filingType, setFilingType] = useState<FilingType | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleFilingTypeSelect = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = event.currentTarget;
    return setFilingType(value as FilingType);
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles) {
      setFile(acceptedFiles[0]);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    if (filingType) {
      event.preventDefault();
      formData.append('file', file as File, (file as File).name);
      formData.append('filing_type', filingType);
      try {
        setIsUploading(true);
        await privateApiAsFormData.post(`/filings/bulk-upload/`, formData);
        setIsUploading(false);
        onClose();
        toast.success('✅ Multiple filings were successfully uploaded.');
      } catch (error) {
        setIsUploading(false);
        toast.error(error.response.data.error);
      }
    }
  };

  return (
    <Flex color="black">
      <form onSubmit={handleSubmit}>
        <InputGroup>
          <Label htmlFor="filing-type">
            Type <Asterisk />
          </Label>
          <Select
            name="filing-type"
            id="filing-type"
            onChange={handleFilingTypeSelect}
            mt={2}
            defaultValue=""
          >
            <option value="" disabled={true}>
              Select a Filing Type
            </option>
            {filingTypes.map((filingType) => (
              <option key={filingType} value={filingType}>
                {formatCamelCaseString(filingType)}
              </option>
            ))}
          </Select>
        </InputGroup>

        {filingType && (
          <>
            <Text fontSize={1} fontWeight={500} mt={3} lineHeight="body">
              Instructions
            </Text>

            <Text fontSize={1} fontWeight={400} mt={2} lineHeight="body">
              Click the link below to download the{' '}
              {formatCamelCaseString(filingType)} template. Fill out the fields
              one CUSIP per row. If an issuer has more than 1 CUSIP, the issuer
              should appear multiple times.
            </Text>

            <Text fontSize={1} fontWeight={400} mt={2} lineHeight="body">
              Please make sure information is entered correctly. Capitalization
              and punctuation will be interpreted exactly as entered.
            </Text>

            <Link
              as="a"
              mt={3}
              href={calculateInstructionsLink(filingType)}
              target="_blank"
              color="green"
              fontWeight={600}
              fontSize={1}
            >
              {formatCamelCaseString(filingType)} Template
            </Link>
          </>
        )}

        {file ? (
          <Flex p={2} mt={4} bg="background">
            <Text color="blue" fontSize={1} letterSpacing="body">
              {file.name}
            </Text>

            <button onClick={handleRemoveFile}>x</button>
          </Flex>
        ) : (
          <Dropzone onDrop={handleDrop} multiple={false} accept=".csv">
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <DropWrapper
                  alignItems="center"
                  justifyContent="center"
                  p={3}
                  mx="auto"
                  border="1px dashed"
                  borderColor="gray"
                  borderRadius={3}
                  mt={4}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Text fontSize={1} lineHeight="body">
                      Drop file here
                    </Text>
                  ) : (
                    <Text fontSize={1} lineHeight="body">
                      Drag and drop a file or click to browse
                    </Text>
                  )}
                </DropWrapper>
              );
            }}
          </Dropzone>
        )}

        <Flex mt={4}>
          <OutlineButton width={1} mr={2} onClick={onClose}>
            Cancel
          </OutlineButton>

          <Button width={1} ml={2} disabled={isUploading} type="submit">
            {isUploading ? '⏳ Uploading' : 'Upload'}
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};
