import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  FilingDetails,
  Document,
  Reference,
  CurrentDisplay,
  DisplayType,
} from '../types';
import { MailOutline, Language, InsertDriveFile } from '@material-ui/icons';
import {
  ListItemProps,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
} from '@material-ui/core';

type Props = {
  filing: FilingDetails | undefined;
  anchorEl: null | HTMLElement;
  selectItem: ({ ...selected }: CurrentDisplay) => void;
  onClose: () => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    popoverPaper: {
      maxHeight: 'unset',
      maxWidth: 'unset',
      borderRadius: 0,
      marginTop: '1px',
      width: '399px',
    },
  }),
);

function PreviewMenu({ filing, anchorEl, selectItem, onClose }: Props) {
  const classes = useStyles();

  const sourceTypeNames = new Map([
    ['new_email', 'Broker Search'],
    ['update_email', 'Broker Search Update'],
    ['proposals_email', 'Meeting Agenda'],
    ['materials_email', 'Material URL(s)'],
    ['csv', 'Morrow .csv'],
  ]);

  if (!filing) {
    return null;
  }
  return (
    <Menu
      anchorEl={anchorEl}
      onClose={onClose}
      PopoverClasses={{ paper: classes.popoverPaper }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      keepMounted
      open={Boolean(anchorEl)}
      getContentAnchorEl={null}
      elevation={2}
    >
      <div>
        {filing.referenceMaterials.map((r: Reference, index: number) => (
          <PreviewMenuItem
            key={r.created}
            emailLabel={sourceTypeNames.get(r.sourceType)}
            handleSelect={(e: React.MouseEvent<HTMLLIElement>) => {
              e.preventDefault();
              selectItem({
                itemType:
                  r.sourceType === ('csv' as any)
                    ? ('csv' as DisplayType)
                    : ('email' as DisplayType),
                index: index,
              });
            }}
          />
        ))}

        {filing.voteProposals && filing.voteProposals.length > 0 ? (
          <PreviewMenuItem
            ballot={true}
            handleSelect={(e: React.MouseEvent<HTMLLIElement>) => {
              e.preventDefault();
              selectItem({
                itemType: 'ballot' as DisplayType,
              });
            }}
          />
        ) : null}

        {filing.documents.map((d: Document, index: number) => (
          <PreviewMenuItem
            key={d.id}
            documentLabel={d.name}
            handleSelect={(e: React.MouseEvent<HTMLLIElement>) => {
              e.preventDefault();
              selectItem({
                itemType: 'document' as DisplayType,
                index: index,
              });
            }}
          />
        ))}
      </div>
    </Menu>
  );
}

export { PreviewMenu };

type PreviewMenuItemProps = ListItemProps & {
  documentLabel?: string;
  ballot?: boolean;
  emailLabel?: string;
  handleSelect: (e: React.MouseEvent<HTMLLIElement>) => void;
};

const PreviewMenuItem = ({
  documentLabel,
  ballot,
  emailLabel,
  handleSelect,
}: PreviewMenuItemProps) => {
  return (
    <MenuItem button={true} onClick={handleSelect}>
      <ListItemIcon>
        {emailLabel ? (
          emailLabel === 'Morrow .csv' ? (
            <InsertDriveFile />
          ) : (
            <MailOutline />
          )
        ) : ballot ? (
          <Language />
        ) : (
          <InsertDriveFile />
        )}
      </ListItemIcon>
      <ListItemText>
        {emailLabel ? emailLabel : ballot ? 'Vote Website' : documentLabel}
      </ListItemText>
    </MenuItem>
  );
};
