import useSWR from 'swr';
import { AxiosResponse, AxiosError } from 'axios';
import { BrokersResponse } from './types';

function useBrokers() {
  const { data, mutate, error } = useSWR<
    AxiosResponse<BrokersResponse>,
    AxiosError
  >(`/brokers/`);

  return { data, mutate, error };
}

export { useBrokers };
