import React from 'react';
import styled from 'styled-components';
import { Edit, DeleteOutline } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

interface Props {
  isVisible: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

function BatchActionButtons({ isVisible, onEdit, onDelete }: Props) {
  return (
    <Wrapper isVisible={isVisible}>
      <BatchActionButton onClick={onEdit}>
        <Edit />
      </BatchActionButton>
      <BatchActionButton onClick={onDelete}>
        <DeleteOutline />
      </BatchActionButton>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  position: absolute;
  left: 0;
  top: 5px;
  background: #fff;
  z-index: 1;
`;

const BatchActionButton = styled(IconButton)`
  color: #00000080;

  &:hover {
    color: #000;
  }
`;

export { BatchActionButtons };
