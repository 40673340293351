import React from 'react';

type ProductIconProps = {
  product: Product;
  size?: 'small' | 'large';
};

type Product = 'px' | 'ca' | 'ps' | 'issuers';

const ProductIcon = ({ size = 'large', product }: ProductIconProps) => {
  const baseWrapperStyles = `rounded font-medium flex items-center justify-center text-primary-text`;
  switch (product) {
    case 'ca':
    case 'px':
    case 'ps':
      return (
        <div
          className={`${baseWrapperStyles} ${
            size === 'large' ? 'text-s' : 'text-xxs'
          }`}
          style={{
            minHeight: size === 'large' ? '1.875rem' : '24px',
            minWidth: size === 'large' ? '1.875rem' : '24px',
            backgroundColor: '#11CC99',
            textTransform: 'uppercase',
          }}
        >
          {product}
        </div>
      );
    default:
      return null;
  }
};

export { ProductIcon };
