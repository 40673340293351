import React from 'react';
import { Login } from '../Auth/Login';
import { useCanLogIn } from '../hooks/useCanLogIn';
import { GlobalStyles } from '../utils/global-styles';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const canLogIn = useCanLogIn();

  return canLogIn ? (
    <>
      <GlobalStyles />
      <Component {...rest} />
    </>
  ) : (
    <>
      <GlobalStyles />
      <Login />
    </>
  );
};

export { PrivateRoute };
