import useSWR from 'swr';
import { AxiosResponse, AxiosError } from 'axios';
import { EmailPreviewResponse } from './types';

function useTemplatePreview(filingId: string, slug: string) {
  const { data, mutate, error } = useSWR<
    AxiosResponse<EmailPreviewResponse>,
    AxiosError
  >(`/filings/${filingId}/preview/${slug}/`);

  return { data, mutate, error };
}

export { useTemplatePreview };
