import React from 'react';
import { SectionTitle } from '../SectionTitle';
import { pluralize } from '../../../utils/pluralize';
import { CorporateActionsResponse } from '../Activity';
import { Label } from '../../../components/Label';
import { ItemRow } from '../ItemRow';
import { CusipTagList } from '../../../components/CusipTagList';
import { formatDate } from '../../../utils/format-date';
import { humanize } from '../../../utils/humanize';
import { Link } from '@reach/router';
import { StatusLabel } from '../StatusLabel';
import { responseInterface } from 'swr';
import { AxiosError, AxiosResponse } from 'axios';
import { CorporateActionEdgarLink } from '../../../components/CorporateActionEdgarLink';

type CorporateActionsListProps = {
  cache: responseInterface<AxiosResponse<CorporateActionsResponse>, AxiosError>;
};

const CorporateActionsList = ({ cache }: CorporateActionsListProps) => {
  const corporateActions = cache.data ? cache.data.data : [];
  const count = corporateActions.length;

  if (count > 0)
    return (
      <div className="mt-8 first:mt-0">
        <SectionTitle>
          {count}{' '}
          {pluralize({
            singular: 'corporateAction',
            plural: 'corporateActions',
            count,
          })}
        </SectionTitle>

        <div className="grid gap-4 grid-cols-8 border-t border-light-gray p-4">
          <Label>State</Label>
          <Label>Cusip</Label>
          <Label>Issuer Name</Label>
          <Label>Edgar</Label>
          <Label>VCA Type</Label>
          <Label>Announcement Date</Label>
          <Label>Cutoff Date</Label>
          <Label>Status</Label>
        </div>

        <div>
          {corporateActions.map((corporateAction) => (
            <Link
              to={`/corporate-actions/${corporateAction.id}`}
              key={corporateAction.id}
              className="group mt-2 first:mt-0 block hover:no-underline hover:text-primary-text"
            >
              <ItemRow className="grid gap-4 grid-cols-8 group-hover:shadow-lg transition duration-150 ease-in-out">
                <StatusLabel status={corporateAction.operationsStatus} />
                <CusipTagList cusips={corporateAction.cusips} />
                <p>
                  <span className="font-medium">
                    {corporateAction.tickers ? corporateAction.tickers[0] : ''}
                  </span>{' '}
                  {corporateAction.issuerName}
                </p>
                <p>
                  <CorporateActionEdgarLink
                    announcementDate={corporateAction.announcementDate}
                    cik={
                      corporateAction.edgarFilings.length
                        ? corporateAction.edgarFilings[0].cik
                        : null
                    }
                    date={
                      corporateAction.edgarFilings.length
                        ? corporateAction.edgarFilings[0].filingDate
                        : null
                    }
                    filingType={
                      corporateAction.edgarFilings.length
                        ? corporateAction.edgarFilings[0].type
                        : null
                    }
                    link={
                      corporateAction.edgarFilings.length
                        ? corporateAction.edgarFilings[0].url
                        : null
                    }
                  />
                </p>
                <p className="capitalize">{humanize(corporateAction.type)}</p>
                <p>
                  {corporateAction.announcementDate ? (
                    formatDate(new Date(corporateAction.announcementDate))
                  ) : (
                    <span className="text-gray">n/a</span>
                  )}
                </p>
                <p>
                  {corporateAction.electionCutoffDate ? (
                    formatDate(new Date(corporateAction.electionCutoffDate))
                  ) : (
                    <span className="text-gray">n/a</span>
                  )}
                </p>
                <p className="capitalize">
                  {humanize(corporateAction.operationsStatus)}
                </p>
              </ItemRow>
            </Link>
          ))}
        </div>
      </div>
    );

  return null;
};

export { CorporateActionsList };
