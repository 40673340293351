import React from 'react';
import { FaAngleRight, FaAngleDown } from 'react-icons/fa';

type BrokersListProps = {
  brokers: Broker[];
  selectedBrokerIds: number[];
  onAddBroker: (brokerId: number) => void;
  onRemoveBroker: (brokerId: number) => void;
};

type Broker = {
  id: number;
  name: string;
  slug: string;
  globalBrokerId: string;
  totalPositions: number;
  childBrokers?: Broker[];
};

function BrokersList({
  brokers,
  selectedBrokerIds,
  onAddBroker,
  onRemoveBroker,
}: BrokersListProps) {
  return (
    <div className="py-3">
      {brokers.map((broker) => (
        <ParentBroker
          key={broker.id}
          broker={broker}
          onAddBroker={onAddBroker}
          onRemoveBroker={onRemoveBroker}
          selectedBrokerIds={selectedBrokerIds}
        />
      ))}
    </div>
  );
}

type ParentBrokerProps = {
  broker: Broker;
  onAddBroker: (brokerId: number) => void;
  onRemoveBroker: (brokerId: number) => void;
  selectedBrokerIds: number[];
};

function ParentBroker({
  broker,
  onAddBroker,
  onRemoveBroker,
  selectedBrokerIds,
}: ParentBrokerProps) {
  const [isShowingChildren, setIsShowingChildren] = React.useState(false);

  return (
    <React.Fragment>
      <div>
        <div
          key={broker.id}
          className="flex items-center justify-between py-4 border-b border-light-gray"
        >
          <div>
            <div className="flex items-center">
              <BrokerField
                broker={broker}
                onAddBroker={onAddBroker}
                onRemoveBroker={onRemoveBroker}
                selectedBrokerIds={selectedBrokerIds}
              />
            </div>
          </div>

          <div className="flex items-center">
            <span className="mr-4">{broker.totalPositions}</span>
            <div className="w-4">
              {broker.childBrokers && broker.childBrokers.length ? (
                isShowingChildren ? (
                  <FaAngleDown onClick={() => setIsShowingChildren(false)} />
                ) : (
                  <FaAngleRight onClick={() => setIsShowingChildren(true)} />
                )
              ) : null}
            </div>
          </div>
        </div>

        {!isShowingChildren &&
          broker.childBrokers &&
          broker.childBrokers.map((childBroker) => {
            if (selectedBrokerIds.includes(childBroker.id)) {
              return (
                <div
                  key={childBroker.id}
                  className="flex items-center justify-between py-4 border-b border-light-gray
              "
                >
                  <div className="ml-4 flex items-center">
                    <BrokerField
                      broker={childBroker}
                      onAddBroker={onAddBroker}
                      onRemoveBroker={onRemoveBroker}
                      selectedBrokerIds={selectedBrokerIds}
                    />
                  </div>

                  <div>
                    <span className="mr-8">{childBroker.totalPositions}</span>
                  </div>
                </div>
              );
            }

            return null;
          })}
      </div>

      {broker.childBrokers && isShowingChildren
        ? broker.childBrokers.map((childBroker) => (
            <div
              key={childBroker.id}
              className="flex items-center justify-between py-4 border-b border-light-gray
              "
            >
              <div className="ml-4 flex items-center">
                <BrokerField
                  broker={childBroker}
                  onAddBroker={onAddBroker}
                  onRemoveBroker={onRemoveBroker}
                  selectedBrokerIds={selectedBrokerIds}
                />
              </div>

              <div>
                <span className="mr-8">{childBroker.totalPositions}</span>
              </div>
            </div>
          ))
        : null}
    </React.Fragment>
  );
}

type BrokerFieldProps = {
  broker: Broker;
  onAddBroker: (brokerId: number) => void;
  onRemoveBroker: (brokerId: number) => void;
  selectedBrokerIds: number[];
};

function BrokerField({
  broker: { id, name },
  onAddBroker,
  onRemoveBroker,
  selectedBrokerIds,
}: BrokerFieldProps) {
  return (
    <React.Fragment>
      <input
        id={String(id)}
        type="checkbox"
        className="checkbox appearance-none h-4 w-4 border border-light-gray rounded-sm mr-4 outline-none"
        onChange={(event) => {
          const { checked, value } = event.currentTarget;

          if (checked) {
            onAddBroker(Number(value));
          } else {
            onRemoveBroker(Number(value));
          }
        }}
        checked={selectedBrokerIds.includes(id)}
        value={id}
      />
      <label htmlFor={String(id)} className="font-sm text-primary-text">
        {name}
      </label>
    </React.Fragment>
  );
}

export { BrokersList };
