import { FilingType } from './filing-type';
import { Stats } from './stats';
import { Document } from './document';
import { TimeZone } from './time-zone';
import { ITabulator, ISolicitor, ICompanyContact } from './intermediaries';
import { CorporateActionType } from './corporate-action-type';
import { IElectionItems, IVoteProposal } from './vote-proposal';
import { EdgarFiling } from './edgar';

export enum DeliveryMethod {
  NoticeAndAccess = 'notice_and_access',
  Traditional = 'traditional',
}

export interface Action {
  action: OperationsStatus;
  created: Date;
  id: number;
  modified: Date;
  performedAt: Date;
  username: string;
}

export enum OperationsStatus {
  New = 'new',
  Active = 'active',
  MaterialsRequested = 'materials_requested',
  ReadyForReview = 'ready_for_review',
  Approved = 'approved',
  Closed = 'closed',
}

export interface Security {
  id: number;
  created: string;
  modified: string;
  ticker: string | null;
  cusip: string;
  name: string;
  isin: string | null;
  issuer: number;
}

export interface Issuer {
  cik: number | null;
  edgarLandingPage: string | null;
  id: number;
  companyName: string;
  contactEmail: string | null;
}

export enum MeetingType {
  Annual = 'annual',
  Special = 'special',
}

export enum ProxyType {
  Traditional = 'traditional',
  Unspecified = 'unspecified',
}

export interface Meeting {
  address: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  contested: boolean;
  country: string;
  date: Date;
  isPhysical: boolean;
  postalCode: string;
  proxyType: ProxyType;
  state: string;
  time: string;
  timezone: TimeZone;
  title: null | string;
  type: MeetingType;
  virtualMeetingUrl: null | string;
}

export type ProposalTypes =
  | 'BoardOfDirectorsNomination'
  | 'PlanOfMergerProposalItem'
  | 'AdjournmentProposalItem'
  | 'ExecutiveCompensationProposalItem'
  | 'ManagementProposalItem'
  | 'ShareholderProposalItem'
  | 'SayOnPay'
  | 'RatificationOfAuditors'
  | 'Other';

export enum ReportType {
  Annual = 'annual',
  SemiAnnual = 'semi_annual',
}

export interface BulkUploadSet {
  id: number;
  csvFile: string;
}

export interface CorporateActionApproval {
  id: number;
  broker: number;
  corporateAction: number;
}

export interface FilingDetails {
  actionDetail?: string;
  actionHistory: Action[];
  actionType?: CorporateActionType;
  actualMaterialsRequired: number;
  brokerSearchReceivedDate: Date;
  bulkUploadSet: BulkUploadSet;
  bulkUploadSetId: number;
  checkListId: number;
  companyContactIds: number[];
  companyContacts: ICompanyContact[];
  contestingEntity?: string;
  contraCusip?: string;
  corporateActionApprovals?: CorporateActionApproval[];
  deliveryMethod: DeliveryMethod;
  digitalMaterialsExpectedDate: Date;
  digitalMaterialsReceivedDate: Date;
  documents: Document[];
  dtcExpirationDate?: string | null;
  edgarFilings?: EdgarFiling[];
  electionCutoffDate?: string | null;
  electionCutoffTime?: string | null;
  electionItems?: IElectionItems[];
  expirationDate?: string;
  externalHostUrl: string;
  filingDate: Date;
  fundOwner?: string;
  haveMaterialsBeenRequested: boolean;
  id: number;
  informationAgent?: string;
  informationAgentAddress?: string;
  informationAgentEmail?: string;
  informationAgentPhone?: string;
  invoicerAddress: string;
  invoicerAddressLine1: string;
  invoicerAddressLine2: string;
  invoicerAddressLine3: string;
  invoicerCity: string;
  invoicerCompanyName: string;
  invoicerContactEmail: string;
  invoicerContactName: string;
  invoicerContactPhoneNumber: string;
  invoicerCountry: string;
  invoicerPostalCode: string;
  invoicerState: string;
  isSolicitorInvoicing: boolean;
  isSolicitorPrinting: boolean;
  isSolicitorTabulating: boolean;
  issuer: Issuer;
  issuerFiling?: number;
  issuerId: number;
  meeting?: Meeting;
  meetingDate?: string | null;
  notes: string;
  notificationReceivedDate?: string | null;
  offerer?: string;
  offererTerms?: string;
  operationsStatus: OperationsStatus;
  paperMaterialsExpectedDate: Date;
  paperMaterialsReceivedDate: Date;
  paperMaterialsSentDate: Date;
  paperSendDeadline: Date;
  payoutCurrency?: string;
  recordDate: string;
  additionalRecordDates?: string[];
  recordDateEnd?: string | null;
  recordDateStart?: string;
  reportType?: ReportType;
  securities: Security[];
  securityIds: number[];
  solicitor: ISolicitor;
  solicitorAddressLine1: string;
  solicitorAddressLine2: string;
  solicitorCity: string;
  solicitorCompany: string;
  solicitorContactEmail: string;
  solicitorContactName: string;
  solicitorContactPhoneNumber: string;
  solicitorId: number;
  solicitorJobNumber: string;
  solicitorState: string;
  solicitorZip: string;
  stats: Stats;
  tabulator?: ITabulator;
  tabulatorAddressLine1?: string;
  tabulatorAddressLine2?: string;
  tabulatorCity?: string;
  tabulatorCompanyName?: string;
  tabulatorContactEmail?: string;
  tabulatorContactName?: string;
  tabulatorId?: number;
  tabulatorPhoneNumber?: string;
  tabulatorState?: string;
  tabulatorZip?: string;
  type: FilingType;
  voteCutoffDate?: Date;
  voteCutoffTime?: string;
  voteCutoffTimezone?: TimeZone;
  voteProposals?: IVoteProposal[];
}
