import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SectionTitle } from '../SectionTitle';
import { ItemRow } from '../ItemRow';
import { SecurityRow } from '../SecurityRow';
import { ItemRowColumn } from '../ItemRowColumn';
// import { PlusButton } from '../../../components/PlusButton';
import { ActionButtons } from '../ActionButtons';
import { Input } from '../../../components/Input';
import { Select } from '../../../components/Select';
import {
  Formik,
  Field,
  FormikProps,
  FieldProps,
  FormikActions,
  Form,
} from 'formik';
import { hubApi } from '../../../old/data/hubApiConfig';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import { humanize } from '../../../utils/humanize';
import { IssuerType, Security } from '..';
import { PageWrapper } from '../../../components/PageWrapper';

type DetailsProps = {
  id: string;
  name: string;
  issuerType: null | IssuerType;
  cik: null | string;
  fundOwner: null | string;
  securities: Security[];
};

type IssuerFormValues = {
  name: string;
  cik: string;
  issuerType: IssuerType;
};

const issuerTypeOptions = [
  { value: 'corporation', label: 'Corporation' },
  { value: 'investment_trust', label: 'Investment Trust' },
  { value: 'government', label: 'Government' },
];

function Details({
  name,
  issuerType,
  cik,
  securities,
  id,
}: DetailsProps & RouteComponentProps<{ id: string }>) {
  const [isEditingIssuer, setIsEditingIssuer] = React.useState(false);

  const handleSetEditing = () => setIsEditingIssuer(true);
  const handleCancelEditing = () => setIsEditingIssuer(false);
  const handleSaveIssuer = async (
    values: IssuerFormValues,
    actions: FormikActions<IssuerFormValues>,
  ) => {
    const { cik, issuerType, name } = values;
    actions.setSubmitting(true);
    try {
      const response = await hubApi.patch(`/admin/issuers/${id}/`, {
        name,
        cik,
        issuerType,
      });

      mutate(`/admin/issuers/${id}/`, response);
      setIsEditingIssuer(false);
      actions.resetForm();
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      toast.error(JSON.stringify(error.response.data));
    }
  };

  const handleActionSuccess = () => {
    mutate(`/admin/issuers/${id}/`);
  };

  return (
    <PageWrapper>
      <SectionTitle>Issuer</SectionTitle>
      <div>
        {isEditingIssuer && (
          <Formik
            initialValues={{
              name,
              issuerType: issuerType || 'corporation',
              cik: cik || '',
            }}
            onSubmit={handleSaveIssuer}
            render={({
              isValid,
              isSubmitting,
            }: FormikProps<IssuerFormValues>) => (
              <Form>
                <ItemRow className="grid-cols-4">
                  <Field
                    name="name"
                    render={({ field }: FieldProps<IssuerFormValues>) => (
                      <ItemRowColumn label="issuer name">
                        <Input {...field} />
                      </ItemRowColumn>
                    )}
                  />

                  <Field
                    name="issuerType"
                    render={({ field }: FieldProps<IssuerFormValues>) => (
                      <ItemRowColumn label="issuer type">
                        <Select
                          options={issuerTypeOptions}
                          {...field}
                          onChange={field.onChange}
                        />
                      </ItemRowColumn>
                    )}
                  />

                  <Field
                    name="cik"
                    render={({ field }: FieldProps<IssuerFormValues>) => (
                      <ItemRowColumn label="edgar cik">
                        <Input {...field} />
                      </ItemRowColumn>
                    )}
                  />

                  <div
                    className="flex items-center"
                    style={{ justifySelf: 'end' }}
                  >
                    <button
                      className="font-medium text-green hover:underline mr-4 disabled:text-light-gray disabled:cursor-not-allowed disabled:no-underline"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      {isSubmitting ? 'Saving...' : 'Save'}
                    </button>
                    <button
                      onClick={handleCancelEditing}
                      className="font-medium text-gray hover:text-green"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </ItemRow>
              </Form>
            )}
          />
        )}

        {!isEditingIssuer && (
          <ItemRow className="grid-cols-4">
            <ItemRowColumn label="issuer name">
              <p>{name}</p>
            </ItemRowColumn>
            <ItemRowColumn label="issuer type">
              <p className="capitalize">{humanize(issuerType || '')}</p>
            </ItemRowColumn>
            <ItemRowColumn label="edgar cik">
              <p>{cik}</p>
            </ItemRowColumn>

            <ActionButtons onEdit={handleSetEditing} />
          </ItemRow>
        )}
      </div>

      <div className="mt-8">
        <SectionTitle>Securities</SectionTitle>
        {securities.map((security) => (
          <SecurityRow
            {...security}
            key={security.id}
            onSave={handleActionSuccess}
            onDelete={handleActionSuccess}
          />
        ))}
      </div>

      {/* 
        <PlusButton className="mt-6" onClick={handleAddSecurity}>
          Add Security
        </PlusButton> */}
    </PageWrapper>
  );
}

export { Details };
