import { IElectionItems } from '../models/vote-proposal';

export const reIndexElections = (
  elections: IElectionItems[],
): IElectionItems[] => {
  return elections.map((election: IElectionItems, i: number) => {
    return {
      ...election,
      proposalNumber: 1,
      groupNumber: i + 1,
    };
  });
};
