import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import { Label } from '../Label';
import { Input } from '../Input';
import { InputGroup } from '../InputGroup';
import { IVoteProposalFormValues } from '../../models/vote-proposal';
import { ErrorLabel } from '../ErrorLabel';

export const ProposalTitle: FC<{}> = () => {
  return (
    <Field
      name="proposalTitle"
      render={({ field, form }: FieldProps<IVoteProposalFormValues>) => (
        <InputGroup>
          <Label htmlFor="proposal-title">Proposal Title</Label>
          {form.errors.proposalTitle && form.touched.proposalTitle && (
            <ErrorLabel>{form.errors.proposalTitle}</ErrorLabel>
          )}

          <Input id="proposal-title" {...field} />
        </InputGroup>
      )}
    />
  );
};
