import React from 'react';
import useSWR from 'swr';
import { privateApi } from '../old/utils/api-adapter';
import { Search } from '../@types/search';
import { useDebounce } from 'use-debounce';

const fetcher = (url: string) => privateApi.get<Search>(url);

function useSearch() {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 250);

  function onSetSearch(value: string) {
    setSearchTerm(value);
  }

  const { data, error } = useSWR(
    debouncedSearchTerm.length
      ? `/admin/search/?value=${debouncedSearchTerm}`
      : null,
    fetcher,
  );

  return { data, error, onSetSearch, searchTerm };
}

export { useSearch };
