import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Flex } from '@rebass/grid';
import styled from 'styled-components/macro';
import { Tabs } from '../../common/Tabs';
import { Tab } from '../../common/Tab';
import { Text } from '../../common/Text';
import { StatusBar } from '../../common/StatusBar';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../data/store';
import { CusipCell } from '../../common/List/CusipCell';
import qs from 'query-string';
import { fetchCompaniesFlow, setCompaniesOrdering } from '../../data/companies';
import { Company } from '../../models/prospectus/company';
import { formatDate } from '../../utils/format-date';
import { CompaniesLayout } from './CompaniesLayout';
import { formatNumberWithComma } from '../../utils/formatNumberWithComma';
import Table, { TableColumnsProps } from '../../common/Table/Table';
import TableRow from '../../common/Table/TableRow';
import Cell from '../../common/Table/Cell';

const StickyTabs = styled(Flex)`
  position: sticky;
  top: 73px;
  z-index: 1;
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

export const Companies: FC<RouteComponentProps> = () => {
  const {
    count,
    next,
    ordering,
    previous,
    isLoading,
    search,
    companies,
  } = useSelector((state: AppState) => state.companies);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompaniesFlow({ ordering, search }));
  }, [dispatch, ordering, search]);

  const handleSetOrdering = (order: string) => {
    dispatch(setCompaniesOrdering(order));
  };

  const handleChangePage = (path: string) => {
    const {
      limit,
      offset,
      ordering,
      search,
    }: {
      limit?: string;
      offset?: string;
      ordering?: string;
      search?: string;
    } = qs.parse(path);
    dispatch(
      fetchCompaniesFlow({
        limit,
        offset,
        ordering,
        search,
      }),
    );
  };

  return (
    <CompaniesLayout>
      <StickyTabs flexDirection="column" width={1} bg="white">
        <Tabs columns={1}>
          <Tab link="/old/issuers/companies">
            <Flex justifyContent="space-between">
              <Text>All Companies</Text>
              <Text className="count">{formatNumberWithComma(count)}</Text>
            </Flex>
          </Tab>
        </Tabs>
      </StickyTabs>

      <StatusBar
        count={count}
        next={next}
        onChangePage={handleChangePage}
        previous={previous}
        typeLabel="Companies"
      />

      <Table
        columns={columns}
        isLoading={isLoading}
        onSetOrdering={handleSetOrdering}
        ordering={ordering}
        variant="blue"
      >
        {companies.map((company, index) => (
          <Row key={company.id} company={company} index={index} />
        ))}
      </Table>
    </CompaniesLayout>
  );
};

interface RowProps {
  index: number;
  company: Company;
}

const Row: FC<RowProps> = ({ company }) => {
  return (
    <TableRow to={`old/issuers/companies/${company.id}`} variant="blue">
      <Cell>{company.name}</Cell>
      <Cell>
        <CusipCell cusips={company.cusips} />
      </Cell>
      <Cell>
        <Capitalize>{company.issuerType}</Capitalize>
      </Cell>
      <Cell align="right">
        {company.lastAnnualRecordDate
          ? formatDate(company.lastAnnualRecordDate)
          : 'N/A'}
      </Cell>
      <Cell align="right">
        {company.lastAnnualMeetingDate
          ? formatDate(company.lastAnnualMeetingDate)
          : 'N/A'}
      </Cell>
    </TableRow>
  );
};

const columns: TableColumnsProps[] = [
  {
    name: 'Company',
    key: 'company',
    orderingKey: 'name',
  },
  {
    name: 'CUSIP',
    key: 'cusip',
  },
  {
    name: 'Issuer Type',
    key: "'issuer-type",
    orderingKey: 'securities__security_type',
  },
  {
    name: 'Last Record Date',
    key: 'last-record-date',
    align: 'right',
  },
  {
    name: 'Last Annual Meeting',
    key: 'last-annual-meeting',
    align: 'right',
  },
];
