import React from 'react';

import { FilingDetails } from '../types';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

type DocPreviewProps = {
  filing: FilingDetails;
  index: number;
};

export const DocumentView = ({ filing, index }: DocPreviewProps) => {
  if (!filing.documents[index] || !filing.documents[index].attachment)
    return (
      <div
        style={{ height: '50vh' }}
        className="flex w-full flex-col items-center justify-center font-medium uppercase py-1 whitespace-no-wrap text-secondary-text tracking-wider"
      >
        <ErrorOutlineIcon fontSize="large" />
        <p className="pt-1">NO DOCUMENTS</p>
        <p className="pt-1">PROVIDED</p>
      </div>
    );
  return (
    <embed
      src={filing.documents[index].attachment || '#'}
      style={{ height: '100vh' }}
      className="w-full"
      key={filing.documents[index].id}
    />
  );
};
