import React from 'react';
import { FilingDetailsFormValues, TimeZone } from '../types';
import { FilingDatePicker } from './FilingDatePicker';
import { FormikProps, Field } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { createStyles, makeStyles } from '@material-ui/core/styles';

type MeetingProps = {
  formikBag: FormikProps<FilingDetailsFormValues>;
  isDraft: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    meetingBox: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginBottom: '24px',
      },
    },
    formError: {
      color: '#d33f33',
      fontSize: '.75rem',
      margin: '3px 0 0 14px',
    },
  }),
);

export const MeetingDetails: React.FC<MeetingProps> = ({
  formikBag,
  isDraft,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.meetingBox}>
      <div className="grid grid-cols-3 gap-6 mt-3">
        <FilingDatePicker
          name="meetingDate"
          label="Meeting Date"
          formikBag={formikBag}
          required={!isDraft}
        />
        <FormControl variant="outlined" fullWidth>
          <TextField
            type="time"
            name="meetingTime"
            variant="outlined"
            required={!isDraft}
            label="Meeting Time"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={formikBag.values.meetingTime || ''}
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) => {
              formikBag.setFieldValue('meetingTime', e.target.value);
            }}
          />
          {formikBag.errors.meetingTime && formikBag.touched.meetingTime ? (
            <div className={classes.formError}>
              {formikBag.errors.meetingTime}
            </div>
          ) : null}
        </FormControl>

        <FormControl variant="outlined" fullWidth>
          <TextField
            select
            required={!isDraft}
            label="Meeting Timezone"
            size="small"
            value={formikBag.values.meetingTimezone || ''}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              formikBag.setFieldValue('meetingTimezone', e.target.value);
            }}
          >
            <MenuItem value="" disabled />
            {Object.values(TimeZone).map((timeZone) => (
              <MenuItem key={timeZone} value={timeZone}>
                {timeZone}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>

      <div className="grid grid-cols-3 gap-6">
        <FilingDatePicker
          name="voteCutoffDate"
          label="Vote By Date"
          formikBag={formikBag}
          required={!isDraft}
        />

        <TextField
          type="time"
          name="voteCutoffTime"
          variant="outlined"
          label="Vote Cutoff Time"
          size="small"
          required={!isDraft}
          InputLabelProps={{
            shrink: true,
          }}
          value={formikBag.values.voteCutoffTime || ''}
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          ) => {
            formikBag.setFieldValue('voteCutoffTime', e.target.value);
          }}
        />
        <FormControl variant="outlined" fullWidth>
          <TextField
            select
            label="Vote Cutoff Timezone"
            size="small"
            required={!isDraft}
            value={formikBag.values.voteCutoffTimezone || ''}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              formikBag.setFieldValue('voteCutoffTimezone', e.target.value);
            }}
          >
            <MenuItem value="" disabled />
            {Object.values(TimeZone).map((timeZone) => (
              <MenuItem key={timeZone} value={timeZone}>
                {timeZone}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>

      <div>
        <FormControl variant="outlined" fullWidth>
          <TextField
            select
            label="Meeting format"
            required={!isDraft}
            size="small"
            value={formikBag.values.meetingFormat || ''}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              formikBag.setFieldValue('meetingFormat', e.target.value);
            }}
          >
            <MenuItem value="" disabled />
            <MenuItem value="physical">In-Person</MenuItem>
            <MenuItem value="online">Virtual</MenuItem>
            <MenuItem value="hybrid">In-Person and Virtual</MenuItem>
          </TextField>
          {formikBag.errors.meetingFormat && formikBag.touched.meetingFormat ? (
            <div className={classes.formError}>
              {formikBag.errors.meetingFormat}
            </div>
          ) : null}
        </FormControl>
      </div>
      <div>
        {formikBag.values.meetingFormat === 'physical' && (
          <Field
            component={FormikTextField}
            name="meetingAddress"
            label="Meeting Address"
            fullWidth
            size="small"
            multiline
            required={!isDraft}
            rows={3}
            variant="outlined"
          />
        )}
        {formikBag.values.meetingFormat === 'online' && (
          <Field
            component={FormikTextField}
            name="virtualMeetingUrl"
            label="Virtual Meeting URL Address"
            fullWidth
            size="small"
            required={!isDraft}
            variant="outlined"
          />
        )}
        {formikBag.values.meetingFormat === 'hybrid' && (
          <div className={classes.meetingBox}>
            <Field
              component={FormikTextField}
              name="meetingAddress"
              label="Meeting Address"
              fullWidth
              size="small"
              multiline
              required={!isDraft}
              rows={3}
              variant="outlined"
            />
            <Field
              component={FormikTextField}
              name="virtualMeetingUrl"
              label="Virtual Meeting URL Address"
              size="small"
              fullWidth
              required={!isDraft}
              variant="outlined"
            />
          </div>
        )}
      </div>
    </div>
  );
};
