import React from 'react';
import useSWR from 'swr';
import { privateApi } from '../../old/utils/api-adapter';
import { getPageOffset } from '../../utils/get-page-offset';
import { FilingListFiling } from './types';

export type Response = {
  count: number;
  next: null | string;
  previous: null | string;
  results: FilingListFiling[];
};

type Query = {
  pageSize: number;
  page: number;
  ordering: OrderingKey;
};

export type OrderingKey =
  | 'latest_edgar_filing_date'
  | 'first_record_date'
  | 'digital_materials_expected_date'
  | 'meeting_date'
  | 'digital_materials_received_date'
  | 'issuer_name'
  | 'stats__total_positions'
  | 'stats__digital_send_deadline'
  | 'issuer__company_name'
  | 'solicitor__name'
  | 'record_date'
  | 'polymorphic_ctype';

export type OnUpdateQuery = (
  taskList: 'drafts' | 'ready' | 'review',
  query: 'pageSize' | 'page' | 'ordering',
  value: string | number | OrderingKey,
) => void;

const fetcher = (url: string) => privateApi.get<Response>(url);

function useTasks() {
  const [draftsQuery, setRequestQuery] = React.useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: 'first_record_date',
  });
  const [readyQuery, setReadyQuery] = React.useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: 'digital_materials_expected_date',
  });
  const [reviewQuery, setReviewQuery] = React.useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: 'digital_materials_received_date',
  });

  const drafts = useSWR(
    `filings/?tab=drafts&limit=${draftsQuery.pageSize}&ordering=${
      draftsQuery.ordering
    }&offset=${getPageOffset({
      page: draftsQuery.page,
      pageSize: draftsQuery.pageSize,
    })}`,
    fetcher,
  );
  const ready = useSWR(
    `filings/?tab=complete&limit=${readyQuery.pageSize}&ordering=${
      readyQuery.ordering
    }&offset=${getPageOffset({
      page: readyQuery.page,
      pageSize: readyQuery.pageSize,
    })}`,
    fetcher,
  );
  const review = useSWR(
    `filings/?tab=review_emails&limit=${reviewQuery.pageSize}&ordering=${
      reviewQuery.ordering
    }&offset=${getPageOffset({
      page: reviewQuery.page,
      pageSize: reviewQuery.pageSize,
    })}`,
    fetcher,
  );

  const handleUpdateQuery: OnUpdateQuery = (taskList, query, value) => {
    switch (taskList) {
      case 'drafts':
        setRequestQuery({ ...draftsQuery, [query]: value });
        break;
      case 'ready':
        setReadyQuery({ ...readyQuery, [query]: value });
        break;
      case 'review':
        setReviewQuery({ ...reviewQuery, [query]: value });
        break;
    }
  };

  return {
    handleUpdateQuery,
    draftsQuery,
    readyQuery,
    reviewQuery,
    drafts,
    ready,
    review,
  };
}

export { useTasks };
