import React from 'react';
import { PageWrapper } from '../../../components/PageWrapper';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import { TableHeader } from '../../../components/TableHeader';
import { TableBodyRow } from '../../../components/TableBodyRow';
import { TableBodyCell } from '../../../components/TableBodyCell';
import { TableHeadCell } from '../../../components/TableHeadCell';
import { TickerAvatar } from '../../../components/TickerAvatar';
import { notEmpty } from '../../../utils/not-empty';
import { CusipTagList } from '../../../components/CusipTagList';
import { Table } from '../../../components/Table';
import { formatDate } from '../../../utils/format-date';
import { AlertIcon } from '../../../components/AlertIcon';
import { LoadingState } from '../../../components/LoadingState';
import { useTasks, VCAResult } from '../useTasks';
import { BrokerTagList } from '../../../components/BrokerTagList';
import { getActionTypeVerboseName } from '../utils/getActionTypeVerboseName';

const Open = (_: RouteComponentProps) => {
  const {
    openTasks: { data, error },
    openQuery: query,
    handleUpdateQuery,
  } = useTasks();

  function handleTableRowClick(path: string) {
    return function (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
      if (e.metaKey) {
        window.open(path, '_blank');
        return;
      }
      navigate(path);
    };
  }

  if (!error && !data)
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );

  if (error) return <PageWrapper>Error...</PageWrapper>;

  if (data)
    return (
      <PageWrapper>
        <TableHeader
          count={data.data.count}
          page={query.page}
          pageSize={query.pageSize}
          itemType="filing"
          onPageChange={(page) => handleUpdateQuery('open', 'page', page)}
          onPageSizeChange={(pageSize) =>
            handleUpdateQuery('open', 'pageSize', pageSize)
          }
        />
        <Table>
          <thead className="bg-white">
            <tr>
              <TableHeadCell name="Ticker" />
              <TableHeadCell
                name="Issuer"
                orderingKey="issuer__company_name"
                currentOrdering={query.ordering}
                onOrderingClick={(ordering: string) =>
                  handleUpdateQuery('open', 'ordering', ordering)
                }
              />
              <TableHeadCell name="CUSIP" />
              <TableHeadCell
                name="Type"
                orderingKey="polymorphic_ctype"
                currentOrdering={query.ordering}
                onOrderingClick={(ordering: string) =>
                  handleUpdateQuery('open', 'ordering', ordering)
                }
              />
              <TableHeadCell
                name="Cutoff Date"
                align="right"
                orderingKey="election_cutoff_date"
                currentOrdering={query.ordering}
                onOrderingClick={(ordering: string) =>
                  handleUpdateQuery('open', 'ordering', ordering)
                }
              />
              <TableHeadCell name="Brokers" align="right" />
            </tr>
          </thead>
          <tbody>
            {data.data.results.map((result) => (
              <TableBodyRow
                key={result.id}
                onClick={handleTableRowClick(`/corporate-actions/${result.id}`)}
              >
                <TableBodyCell>
                  <TickerAvatar tickers={getTickers(result)} />
                </TableBodyCell>
                <TableBodyCell>
                  <Link
                    className="text-blue hover:underline hover:text-blue"
                    to={`/issuers/${result.issuer.globalIssuerId}`}
                    target="_blank"
                    onClick={(event) => event.stopPropagation()}
                  >
                    {result.issuer.companyName}
                  </Link>
                </TableBodyCell>
                <TableBodyCell>
                  <CusipTagList cusips={getCusips(result)} />
                </TableBodyCell>
                <TableBodyCell>
                  <div>{getActionTypeVerboseName(result.type)}</div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-right text-red">
                    {result.electionCutoffDatetime ? (
                      formatDate(new Date(result.electionCutoffDatetime))
                    ) : (
                      <AlertIcon className="ml-2" />
                    )}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-right">
                    <BrokerTagList brokers={result.brokers} />
                  </div>
                </TableBodyCell>
              </TableBodyRow>
            ))}
          </tbody>
        </Table>
      </PageWrapper>
    );

  return null;
};

const getTickers = (result: VCAResult) => {
  const tickers = result.securities
    .map((security) => security.ticker)
    .filter(notEmpty);

  return tickers;
};

const getCusips = (result: VCAResult) => {
  const cusips = result.securities
    .map((security) => security.cusip)
    .filter(notEmpty);

  return cusips;
};

export { Open };
