import React, { FC, useEffect } from 'react';
import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchIssuer,
  updateIssuer,
  toggleConfirmSaveIssuerDialog,
} from '../../../data/funds';
import { RouteComponentProps, Router, navigate } from '@reach/router';
import { AppState } from '../../../data/store';
import { Loading } from '../../../common/Loading/Loading';
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  FormikActions,
} from 'formik';
import * as Yup from 'yup';
import { ReactComponent as ArrowLeft } from '../../../common/assets/arrow-left.svg';
import { TagList } from '../../../common/TagList';
import { InputGroup } from '../../../common/InputGroup';
import { Label } from '../../../common/Label';
import { Input } from '../../../common/Input';
import { GenericDate } from '../../../common/FormComponents/GenericDate';
import { OutlineButton } from '../../../common/Buttons/OutlineButton';
import { ConfirmSaveDialog } from './ConfirmSaveDialog';
import { AddDocument } from '../AddDocumentDialog';
import { FundsLayout } from '../FundsLayout';
import { ProspectusDetails } from './ProspectusDetails';
import { InvoicingDetails } from './InvoicingDetails';
import { Tabs as UnstyledTabs } from '../../../common/Tabs';
import { Tab } from '../../../common/Tab';
import { Text } from '../../../common/Text';
import { ReactComponent as DollarSignIcon } from '../../../common/assets/dollar-sign.svg';
import { ReactComponent as FileIcon } from '../../../common/assets/file.svg';
import { PlainButton } from '../../../common/Buttons/PlainButton';

const Header = styled(Box)`
  background: ${({ theme }) => theme.colors.white};
  border-left: 5px solid ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.black};
  padding: 30px;
  width: 100%;
`;

const HeaderFields = styled.div`
  margin-top: 20px;
`;

const HeadingInput = styled(Input)`
  border: none;
  font-size: 20px;
  font-weight: 500;
  height: auto;

  &:focus {
    border: none;
  }
`;

export const StyledBackButton = styled(PlainButton)`
  display: flex;
  align-items: center;
`;

const InlineInputs = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing[3]}px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin-top: 20px;
`;

const Tabs = styled(UnstyledTabs)`
  border: none;
  box-shadow: 0 2px 3px 0 #c9c6c1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin-top: 16px;
`;

const TransparentOutlineButton = styled(OutlineButton)`
  background: transparent;
`;

const Value = styled.p`
  text-transform: capitalize;
`;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
});

export interface FundDetailsFormValues {
  name: string;
  fiscalYearEnd: null | string;
  address: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  company: string;
  contact: string;
  notes: string;
  email: string;
}

type FundDetailsProps = RouteComponentProps<{
  id: string;
}>;

export const FundDetails: FC<FundDetailsProps> = ({ id }) => {
  const dispatch = useDispatch();
  const issuer = useSelector((state: AppState) =>
    id ? state.funds.issuers[id] : null,
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchIssuer(id));
    }
  }, [dispatch, id]);

  const handleConfirmSave = (formikBag: FormikProps<FundDetailsFormValues>) => {
    formikBag.submitForm();
    dispatch(toggleConfirmSaveIssuerDialog());
  };

  return issuer ? (
    <FundsLayout>
      <Box bg="background" color="black" p={3}>
        <Formik
          initialValues={{
            name: issuer.name || '',
            fiscalYearEnd: issuer.fiscalYearEnd || null,
            address:
              (issuer.invoicingAddress && issuer.invoicingAddress.address) ||
              '',
            addressLine1:
              (issuer.invoicingAddress &&
                issuer.invoicingAddress.addressLine1) ||
              '',
            addressLine2:
              (issuer.invoicingAddress &&
                issuer.invoicingAddress.addressLine2) ||
              '',
            addressLine3:
              (issuer.invoicingAddress &&
                issuer.invoicingAddress.addressLine3) ||
              '',
            city:
              (issuer.invoicingAddress && issuer.invoicingAddress.city) || '',
            state:
              (issuer.invoicingAddress && issuer.invoicingAddress.state) || '',
            postalCode:
              (issuer.invoicingAddress && issuer.invoicingAddress.postalCode) ||
              '',
            country:
              (issuer.invoicingAddress && issuer.invoicingAddress.country) ||
              '',
            company:
              (issuer.invoicingAddress && issuer.invoicingAddress.company) ||
              '',
            contact:
              (issuer.invoicingAddress && issuer.invoicingAddress.contact) ||
              '',
            notes:
              (issuer.invoicingAddress && issuer.invoicingAddress.notes) || '',
            email:
              (issuer.invoicingAddress && issuer.invoicingAddress.email) || '',
          }}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={(
            values: FundDetailsFormValues,
            actions: FormikActions<FundDetailsFormValues>,
          ) => {
            const data = {
              name: values.name,
              fiscalYearEnd: values.fiscalYearEnd,
            };
            const invoicingData = {
              address: values.address,
              addressLine1: values.addressLine1,
              addressLine2: values.addressLine2,
              addressLine3: values.addressLine3,
              city: values.city,
              state: values.state,
              postalCode: values.postalCode,
              country: values.country,
              company: values.company,
              contact: values.contact,
              notes: values.notes,
              email: values.email,
            };
            dispatch(updateIssuer(issuer.id, data, invoicingData));
            actions.setSubmitting(false);
          }}
          render={(formikBag: FormikProps<FundDetailsFormValues>) => (
            <Form>
              <Header>
                <StyledBackButton type="button" onClick={() => navigate('../')}>
                  <ArrowLeft /> Back
                </StyledBackButton>

                <HeaderFields>
                  <Field
                    name="name"
                    render={({ field }: FieldProps<FundDetailsFormValues>) => (
                      <InputGroup>
                        <HeadingInput {...field} placeholder="Fund Name" />
                      </InputGroup>
                    )}
                  />

                  <InlineInputs>
                    <InputGroup>
                      <Label>CUSIP(s)</Label>
                      <TagList
                        values={issuer.securities.map(
                          (security) => security.cusip,
                        )}
                      />
                    </InputGroup>

                    <InputGroup>
                      <Label>Type</Label>
                      <Value>{issuer.issuerType}</Value>
                    </InputGroup>

                    <Field
                      name="fiscalYearEnd"
                      render={({
                        field,
                        form,
                      }: FieldProps<FundDetailsFormValues>) => (
                        <GenericDate
                          field={field}
                          form={form}
                          label="Fiscal Year End"
                          fieldName={field.name}
                        />
                      )}
                    />

                    <InputGroup>
                      <Label>Fund Owner</Label>
                      {issuer.fundOwner && (
                        <Value>{issuer.fundOwner.name}</Value>
                      )}
                    </InputGroup>
                  </InlineInputs>
                </HeaderFields>
              </Header>

              <Tabs columns={2}>
                <Tab link="">
                  <Flex alignItems="center" justifyContent="center">
                    <FileIcon />
                    <Text ml={2}>Prospectus Details</Text>
                  </Flex>
                </Tab>
                <Tab link="invoicing-details">
                  <Flex alignItems="center" justifyContent="center">
                    <DollarSignIcon />
                    <Text ml={2}>Invoicing Details</Text>
                  </Flex>
                </Tab>
              </Tabs>

              <Router>
                <ProspectusDetails path="/" issuer={issuer} />
                <InvoicingDetails path="invoicing-details" />
              </Router>

              <Flex flexDirection="row-reverse" py={3}>
                <TransparentOutlineButton
                  type="button"
                  onClick={() => {
                    dispatch(toggleConfirmSaveIssuerDialog());
                  }}
                  mx={3}
                  disabled={!formikBag.isValid || formikBag.isSubmitting}
                >
                  Save
                </TransparentOutlineButton>
              </Flex>

              <ConfirmSaveDialog
                onConfirmSave={() => handleConfirmSave(formikBag)}
              />
            </Form>
          )}
        />
      </Box>

      {issuer && (
        <AddDocument
          issuer={issuer}
          selectableSecurities={issuer.securities.map((security) => {
            return {
              id: security.id,
              cusip: security.cusip,
            };
          })}
        />
      )}
    </FundsLayout>
  ) : (
    <Loading />
  );
};
