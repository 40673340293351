import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { MeetingDate } from '../common/FormComponents/MeetingDate';
import { Field, FieldProps } from 'formik';
import { FilingDetailsFormValues } from './Filing';
import { InputGroup } from '../common/InputGroup';
import { Label } from '../common/Label';
import { Input } from '../common/Input';
import { VoteCutoffDate } from '../common/FormComponents/VoteCutoffDate';
import { TimeZoneSelect } from '../common/FormComponents/TimeZoneSelect';
import { Textarea } from '../common/Textarea';
import styled from 'styled-components/macro';
import { ElevatedCard } from '../common/ElevatedCard';

const Wrapper = styled(ElevatedCard)`
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 16px;
  padding: 32px 16px;
`;

const InlineLabel = styled(Label)`
  margin-bottom: 0;
`;

const SectionHeader = styled.h3`
  font-size: 14px;
  font-weight: 500;
  grid-column: 1 / -1;
  margin-top: 32px;
`;

const MeetingLocationWrapper = styled.div`
  grid-column: 1 / -1;
`;

const InputGroupRow = styled(InputGroup)`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 32px;
  &:first-of-type {
    margin-left: 0;
  }
`;

const MeetingLocationSelectRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const MeetingDetails: FC<RouteComponentProps> = () => {
  return (
    <Wrapper>
      <div>
        <MeetingDate />

        <Field
          name="meetingTime"
          render={({ field }: FieldProps<FilingDetailsFormValues>) => (
            <InputGroup>
              <Label htmlFor="meeting-time">Meeting Time</Label>
              <Input {...field} id="meeting-time" type="time" />
            </InputGroup>
          )}
        />

        <Field
          name="meetingTimezone"
          render={({ field }: FieldProps<FilingDetailsFormValues>) => (
            <InputGroup>
              <Label htmlFor="meeting-time-zone">Meeting Time Zone</Label>
              <TimeZoneSelect {...field} id="meeting-time-zone" />
            </InputGroup>
          )}
        />
      </div>

      <div>
        <VoteCutoffDate />

        <Field
          name="voteCutoffTime"
          render={({ field }: FieldProps<FilingDetailsFormValues>) => (
            <InputGroup>
              <Label htmlFor="vote-cutoff-time">Vote Cutoff Time</Label>
              <Input {...field} id="vote-cutoff-time" type="time" />
            </InputGroup>
          )}
        />

        <Field
          name="voteCutoffTimezone"
          render={({ field }: FieldProps<FilingDetailsFormValues>) => (
            <InputGroup>
              <Label htmlFor="vote-cutoff-time-zone">
                Vote Cutoff Time Zone
              </Label>
              <TimeZoneSelect {...field} id="vote-cutoff-time-zone" />
            </InputGroup>
          )}
        />
      </div>

      <MeetingLocationWrapper>
        <SectionHeader>Meeting Location</SectionHeader>

        <MeetingLocationSelectRow>
          <Field
            name="physicalMeetingSelected"
            render={({ field, form }: FieldProps<FilingDetailsFormValues>) => (
              <InputGroupRow>
                <InlineLabel htmlFor="physical">Physical</InlineLabel>
                <Input
                  {...field}
                  type="checkbox"
                  id="physical"
                  value={field.value}
                  checked={field.value}
                  onChange={(event) =>
                    form.setFieldValue(
                      'physicalMeetingSelected',
                      event.currentTarget.checked,
                    )
                  }
                />
              </InputGroupRow>
            )}
          />

          <Field
            name="onlineMeetingSelected"
            render={({ field, form }: FieldProps<FilingDetailsFormValues>) => (
              <InputGroupRow>
                <InlineLabel htmlFor="online">Online</InlineLabel>
                <Input
                  {...field}
                  type="checkbox"
                  id="online"
                  value={field.value}
                  checked={field.value}
                  onChange={(event) =>
                    form.setFieldValue(
                      'onlineMeetingSelected',
                      event.currentTarget.checked,
                    )
                  }
                />
              </InputGroupRow>
            )}
          />
        </MeetingLocationSelectRow>

        <Field
          name="meetingAddress"
          render={({ field, form }: FieldProps<FilingDetailsFormValues>) =>
            form.values.physicalMeetingSelected && (
              <InputGroup>
                <Label htmlFor="meeting-address">Meeting Address</Label>
                <Textarea {...field} id="meeting-address" />
              </InputGroup>
            )
          }
        />

        <Field
          name="virtualMeetingUrl"
          render={({ field, form }: FieldProps<FilingDetailsFormValues>) =>
            form.values.onlineMeetingSelected && (
              <InputGroup>
                <Label htmlFor="virtual-meeting-url">Virtual Meeting URL</Label>
                <Input {...field} id="virtual-meeting-url" />
              </InputGroup>
            )
          }
        />
      </MeetingLocationWrapper>
    </Wrapper>
  );
};
