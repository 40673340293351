import React, { FC } from 'react';
import { Flex } from '@rebass/grid';
import styled from 'styled-components/macro';
import { ISolicitor } from '../../models/intermediaries';
import { FilingType } from '../../models/filing-type';
import { userIdEvent } from '../../utils/analytics-helpers';

const Wrapper = styled(Flex)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
`;

const Link = styled.a`
  color: #007aff;
  text-decoration: underline;
`;

interface SolicitorCellProps {
  actualMaterialsRequired: number | null;
  recordDate: string | null;
  solicitor: ISolicitor | null;
  solicitorJobNumber: string | null;
  id?: number | null;
  type?: FilingType | null;
}

export const SolicitorCell: FC<SolicitorCellProps> = ({
  actualMaterialsRequired,
  recordDate,
  solicitor,
  solicitorJobNumber,
  id,
  type,
}) => {
  const renderLink = () => {
    if (solicitor) {
      if (solicitor.name && solicitor.name.includes('Broadridge')) {
        return (
          <Link
            href="https://brokersearchanddistribution.broadridge.com/Request/RequestEntry"
            target="_blank"
            onClick={(event) => event.stopPropagation()}
          >
            Broadridge Portal
          </Link>
        );
      } else {
        const constructMaterialsText = () => {
          if (actualMaterialsRequired && actualMaterialsRequired > 0) {
            return `We will require ${actualMaterialsRequired} physical copies of the materials for this mailing.`;
          } else {
            return `We will not require physical copies of the materials for this mailing.`;
          }
        };

        const subjectText = `Say Communications - New Materials Request ${solicitorJobNumber}`;
        const bodyText = encodeURIComponent(
          `Hi ${
            solicitor.companyContacts[0] && solicitor.companyContacts[0].contact
              ? solicitor.companyContacts[0].contact.name
              : ''
          },\n\n${constructMaterialsText()}\n\nPlease send links and/or PDFs to reports@saytechnologies.com when they are available.\n\nJob Number: ${solicitorJobNumber}\n\nRecord Date: ${recordDate}`,
        );

        const solicitorContactReplyToEmailsString = solicitor.companyContacts
          .filter(
            (companyContact) => companyContact.role === 'solicitor_reply_to',
          )
          .map(
            (companyContact) =>
              companyContact.contact && companyContact.contact.email,
          )
          .join(',');

        return (
          <Link
            href={`mailto:${solicitorContactReplyToEmailsString}?subject=${subjectText}&body=${bodyText}`}
            target="_blank"
            onClick={(event) => {
              event.stopPropagation();
              userIdEvent(
                'Link to Solicitor Clicked',
                {
                  filingId: id,
                  filingType: type,
                  solicitorId: solicitor.id,
                  solicitorName: solicitor.name,
                },
                true,
              );
            }}
          >
            {solicitor.companyContacts[0] &&
            solicitor.companyContacts[0].contact &&
            solicitor.companyContacts[0].contact.name
              ? solicitor.companyContacts[0].contact.name
              : 'No Contact Name'}
          </Link>
        );
      }
    }
  };

  return solicitor ? (
    <Wrapper flexDirection="column">
      {solicitor && <span>{solicitor.name}</span>}
      {renderLink()}
    </Wrapper>
  ) : null;
};
