import React from 'react';
import { AxiosResponse } from 'axios';
import {
  FilingDetailsFormValues,
  FilingDetails,
  CurrentDisplay,
  OperationsStatus,
} from '../types';
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import { Paper, Chip } from '@material-ui/core';

import { ContactDetails } from './Contact';
import { MeetingDetails } from './Meeting';
import { MaterialsDetails } from './Materials';
import { BaseDetails } from './BaseDetails';
import { BallotDetails } from './BallotDetails';

import { FormikProps } from 'formik';

type View = 'Details' | 'Ballot' | 'Materials' | 'Meeting';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRoot: {
      '& > *': {
        margin: '12px 0',
        padding: '0 24px',
      },
    },
    detailsPane: {
      overflowX: 'hidden',
      overflowY: 'auto',
      height: 'calc(100vh - 16.5rem)',
    },
    chipNav: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#FFF',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingLeft: '24px',
      borderBottom: '1px solid #e5e5e5',
      zIndex: 1000,
    },
  }),
);

type ChoiceChipProps = {
  label: string;
  variant: 'outlined';
  key?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const ChoiceChip = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(1),
      fontSize: '14px',
    },
    outlined: {
      '$clickable&:hover, $clickable&:focus': {
        backgroundColor: 'rgba(17, 204, 153, 0.12)',
        borderColor: '#11cc99',
      },
    },
    clickable: {
      userSelect: 'none',
      WebkitTapHighlightColor: 'transparent',
      cursor: 'pointer',
      '&:hover, &:focus': {
        backgroundColor: 'rgba(17, 204, 153, 0.12)',
      },
      '&:active': {
        boxShadow: theme.shadows[1],
      },
    },
  }),
)((props: ChoiceChipProps) => <Chip {...props} />);

type DetailsFormProps = {
  formikBag: FormikProps<FilingDetailsFormValues>;
  data: AxiosResponse<FilingDetails> | undefined;
  additionalRecordDatesError: string;
  currentDisplay: CurrentDisplay;
  setCurrentDisplay: React.Dispatch<React.SetStateAction<CurrentDisplay>>;
};

const DetailsForm = ({
  data,
  formikBag,
  additionalRecordDatesError,
  currentDisplay,
  setCurrentDisplay,
}: DetailsFormProps) => {
  const classes = useStyles();

  // Navigation - Choice Chips
  const [currentChip, setCurrentChip] = React.useState<View>('Details');
  const handleChipClick = (newValue: View) => setCurrentChip(newValue);

  if (data) {
    const filing = data.data;
    const chips: View[] =
      filing.type === 'FundReport' ||
      filing.type === 'FundInformationStatement' ||
      filing.type === 'FirmInformationStatement'
        ? ['Details', 'Materials']
        : ['Details', 'Ballot', 'Materials', 'Meeting'];
    return (
      <Paper elevation={3} className={classes.detailsPane}>
        <div className={classes.chipNav}>
          {chips.map((c) => {
            return (
              <ChoiceChip
                key={c}
                variant="outlined"
                label={c}
                onClick={() => handleChipClick(c)}
                style={
                  currentChip === c
                    ? {
                        backgroundColor: 'rgba(17, 204, 153, 0.12)',
                        borderColor: '#11cc99',
                      }
                    : {}
                }
              />
            );
          })}
        </div>

        <div id="ProxyDetailsFormTab" className={classes.formRoot}>
          {currentChip === 'Details' && (
            <>
              <BaseDetails
                formikBag={formikBag}
                additionalRecordDatesError={additionalRecordDatesError}
                isDraft={filing.operationsStatus === OperationsStatus.Draft}
              />
              <div id="ProxyDetailContacts">
                <ContactDetails
                  isDraft={filing.operationsStatus === OperationsStatus.Draft}
                  formikBag={formikBag}
                />
              </div>
            </>
          )}
          {currentChip === 'Materials' && (
            <>
              <MaterialsDetails
                filingId={data.data.id.toString()}
                formikBag={formikBag}
                hasPaper={Boolean(filing.actualMaterialsRequired)}
                currentDisplay={currentDisplay}
                setCurrentDisplay={setCurrentDisplay}
              />
            </>
          )}
          {currentChip === 'Meeting' && (
            <>
              <MeetingDetails
                formikBag={formikBag}
                isDraft={filing.operationsStatus === OperationsStatus.Draft}
              />
            </>
          )}
          {currentChip === 'Ballot' && <BallotDetails />}
        </div>
      </Paper>
    );
  }
  return null;
};

export { DetailsForm };
