import React from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { GrayTabs } from '../../components/GrayTabs/GrayTabs';
import { CusipTypeahead } from '../../components/CusipTypeahead/CusipTypeahead';
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import { Select } from '../../components/Select';
import { Label } from '../../components/Label';
import { Input } from '../../components/Input';
import { DateInput } from '../../components/DateInput/DateInput';
import { FormStatusBar } from './FormStatusBar';
import { privateApi, privateApiAsFormData } from '../../old/utils/api-adapter';
import { FieldError } from '../../components/FieldError/FieldError';
import { FormValues, DocumentType, CorporateActionType } from './types';
import { validationSchema } from './validation-schema';
import { corporateActionTypeOptions, frequencyOptions } from './select-options';
import { createFormPayload, getElectionChoices } from './helpers';
import { CreatePageDocuments } from './CreatePageDocuments';
import { Header } from './Header';
import { toast } from 'react-toastify';
import { formatErrors } from './formatErrors';
import { DuplicateCheckDialog } from './DuplicateCheckDialog';
import { AxiosResponse } from 'axios';
import { TextareaAutosize } from './TextareaAutosize';

type CreateProps = RouteComponentProps;

export type CheckResponse = {
  duplicateExists: boolean;
  duplicateCorporateActionIds: Array<number>;
};

function Create(_: CreateProps) {
  const navigate = useNavigate();

  const [documents, setDocuments] = React.useState<
    { file: File; name: string; type: DocumentType }[]
  >([]);
  const [dialog, setDialog] = React.useState<
    | { resolve: () => void; reject: () => void; context: CheckResponse }
    | undefined
  >();

  function handleAddDocuments(files: File[]) {
    const formattedDocuments = files.map((file) => ({
      file,
      name: '',
      type: 'amendment_to_offer_to_purchase' as DocumentType,
    }));

    setDocuments(documents.concat(formattedDocuments));
  }

  function handleChangeType(index: number, type: DocumentType) {
    const updatedDocuments = documents.map((document, i) => {
      if (i === index) {
        return {
          ...document,
          type,
        };
      }
      return document;
    });

    setDocuments(updatedDocuments);
  }

  function handleChangeName(index: number, name: string) {
    const updatedDocuments = documents.map((document, i) => {
      if (i === index) {
        return {
          ...document,
          name,
        };
      }
      return document;
    });

    setDocuments(updatedDocuments);
  }

  function handleDeleteDocument(index: number) {
    const updatedDocuments = documents.filter((document, i) => i !== index);

    setDocuments(updatedDocuments);
  }

  const handleFormSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikActions<FormValues>,
  ) => {
    if (values.issuer && values.securities.length) {
      try {
        setSubmitting(true);

        // check for duplicate corporate actions
        const checkPayload = {
          cusips: values.securities.map((security) => security.cusip),
          announcementDate: values.announcementDate,
        };
        const checkResponse: AxiosResponse<CheckResponse> = await privateApi.post(
          `/admin/vca/check/`,
          checkPayload,
        );
        const userAction = new Promise((resolve, reject) =>
          setDialog({ resolve, reject, context: checkResponse.data }),
        );
        try {
          await userAction;
        } catch (_) {
          setSubmitting(false);
          setDialog(undefined);
          return;
        }
        setDialog(undefined);

        const payload = createFormPayload(values);
        const response = await privateApi.post(`/admin/vca/`, payload);
        const newCorporateActionId = response.data.id;

        const electionPayload = {
          title: values.electionTitle,
          detail: values.electionDetails,
          choices: getElectionChoices(values.type as CorporateActionType),
          corporateActionId: newCorporateActionId,
        };

        await privateApi.post(`/admin/elections/`, electionPayload);

        documents.forEach(async (document) => {
          const formData = new FormData();
          formData.append('file', document.file, document.file.name);
          const attachementResponse = await privateApi.post(`/attachments/`, {
            corporateAction: newCorporateActionId,
            name: document.name,
            type: document.type,
          });
          await privateApiAsFormData.post(
            `/attachments/${attachementResponse.data.id}/upload/`,
            formData,
          );
        });
        setSubmitting(false);
        navigate(`/corporate-actions/${response.data.id}`);
      } catch (error) {
        setSubmitting(false);
        toast.error(formatErrors(error.response.data), { autoClose: false });
      }
    }
  };

  return (
    <Formik
      initialValues={{
        securities: [],
        issuer: null,
        announcementDate: null,
        recordDate: null,
        electionCutoffDate: null,
        dtcExpirationDate: null,
        type: '' as CorporateActionType,
        operationsStatus: 'active',
        electionDetails: '',
        electionTitle: '',
        bidIncrement: '',
        consentPayment: '',
        contraCusip: '',
        dripReinvestmentPrice: '',
        frequency: '',
        grossDividend: '',
        maximumBidPrice: '',
        maximumQualifyingQuantity: '',
        minimumBidPrice: '',
        minimumQualifyingQuantity: '',
        offererParty: '',
        outTurn: '',
        price: '',
        ratio: '',
        tradeSecurity: '',
      }}
      onSubmit={handleFormSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
      }: FormikProps<FormValues>) => (
        <Form>
          <Header issuerName={values.issuer ? values.issuer.name : ''} />

          <GrayTabs tabs={tabs} />

          <FormStatusBar
            status="new"
            isSubmitting={isSubmitting}
            isValid={isValid}
          />

          <div className="grid grid-cols-2 gap-8 py-4 px-8">
            <div>
              <div className="py-4 block w-full border-b border-gray">
                <h2 className="text-secondary-text font-medium text-xs uppercase">
                  Filing Details
                </h2>
              </div>
              <div id="FilingDetailContainer" className="flex flex-col mt-4">
                <Field name="securities">
                  {({ field, form }: FieldProps<FormValues>) => (
                    <InputGroup>
                      <Label required={true}>CUSIP(s)</Label>
                      <CusipTypeahead
                        selectedItems={field.value}
                        onSelectItem={(security) => {
                          form.setFieldValue('securities', [
                            ...field.value,
                            security,
                          ]);
                          form.setFieldValue('issuer', {
                            id: security.issuer.id,
                            name: security.issuer.companyName,
                          });
                        }}
                        onClear={() => {
                          form.setFieldValue('securities', []);
                          form.setFieldValue('issuer', { id: 0, name: '' });
                        }}
                        onRemoveItem={(index) => {
                          if (field.value.length === 1) {
                            form.setFieldValue('issuer', {
                              id: 0,
                              name: '',
                            });
                          }

                          form.setFieldValue(
                            'securities',
                            field.value.filter(
                              (_: any, i: number) => i !== index,
                            ),
                          );
                        }}
                        issuerId={values.issuer ? values.issuer.id : undefined}
                        disabled={values.operationsStatus === 'closed'}
                      />
                    </InputGroup>
                  )}
                </Field>

                <Field name="issuer">
                  {({ field }: FieldProps<FormValues>) => (
                    <InputGroup>
                      <Label required={true}>Issuer</Label>
                      <Input
                        id="IssuerValue"
                        disabled={true}
                        type="text"
                        value={field.value ? field.value.name : ''}
                      />
                    </InputGroup>
                  )}
                </Field>

                <div className="grid grid-cols-2 gap-4">
                  <Field name="announcementDate">
                    {({ form, field }: FieldProps<FormValues>) => (
                      <InputGroup>
                        <Label required={true}>Announcement Date</Label>
                        <DateInput
                          name="announcementDate"
                          onChange={(date) => {
                            if (date) {
                              form.setFieldValue('announcementDate', date);
                            }
                          }}
                          selected={field.value}
                          dateFormat="MM/dd/yyyy h:mm aa"
                          showTime={true}
                          placeholder="MM/DD/YYYY H:MM AA"
                        />
                      </InputGroup>
                    )}
                  </Field>

                  <Field name="recordDate">
                    {({ field, form }: FieldProps<FormValues>) => (
                      <InputGroup>
                        <Label showOptional={true}>Record Date</Label>
                        <DateInput
                          name="recordDate"
                          onChange={(date) => {
                            if (date) {
                              form.setFieldValue('recordDate', date);
                            }
                          }}
                          selected={field.value}
                          placeholder="MM/DD/YYYY"
                        />
                      </InputGroup>
                    )}
                  </Field>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <Field name="electionCutoffDate">
                    {({ field, form }: FieldProps<FormValues>) => (
                      <InputGroup>
                        <Label required={true}>Election Cutoff Date</Label>
                        <DateInput
                          name="electionCutoffDate"
                          onChange={(date) => {
                            if (date) {
                              form.setFieldValue('electionCutoffDate', date);
                            }
                          }}
                          selected={field.value}
                          dateFormat="MM/dd/yyyy h:mm aa"
                          showTime={true}
                          placeholder="MM/DD/YYYY H:MM AA"
                        />
                      </InputGroup>
                    )}
                  </Field>

                  <Field name="dtcExpirationDate">
                    {({ field, form }: FieldProps<FormValues>) => (
                      <InputGroup>
                        <Label required={true}>DTC Expiration Date</Label>
                        <DateInput
                          name="dtcExpirationDate"
                          onChange={(date) => {
                            if (date) {
                              form.setFieldValue('dtcExpirationDate', date);
                            }
                          }}
                          selected={field.value}
                          dateFormat="MM/dd/yyyy"
                          placeholder="MM/DD/YYYY"
                        />
                      </InputGroup>
                    )}
                  </Field>
                </div>

                <Field name="type">
                  {({ field, form }: FieldProps<FormValues>) => (
                    <InputGroup>
                      <Label required={true}>
                        Voluntary Corporate Action Type
                      </Label>
                      <Select
                        {...field}
                        onChange={(event) => {
                          form.setFieldValue('type', event.currentTarget.value);
                          form.setFieldValue(
                            'electionTitle',
                            event.currentTarget.options[
                              event.currentTarget.selectedIndex
                            ].text,
                          );
                        }}
                        options={corporateActionTypeOptions}
                      />
                    </InputGroup>
                  )}
                </Field>

                {values.type ? (
                  <div
                    id="TypeValueContainer"
                    className="border-t border-light-gray pt-2 mt-4"
                  >
                    {values.type === 'OfferToPurchaseThirdPartyOffer' ? (
                      <Field name="offererParty">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={true}>offerer Party</Label>
                            <Input type="text" {...field} />
                            {errors.offererParty && touched.offererParty ? (
                              <FieldError>{errors.offererParty}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {(values.type === 'OfferToPurchaseBuyBack' ||
                      values.type === 'OfferToPurchaseThirdPartyOffer') &&
                    !values.price ? (
                      <Field name="bidIncrement">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Bid Increment</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {(values.type === 'OfferToPurchaseBuyBack' ||
                      values.type === 'OfferToPurchaseThirdPartyOffer') &&
                    !values.price ? (
                      <Field name="minimumBidPrice">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Minimum Bid Price</Label>
                            <Input type="text" {...field} />
                            {errors.minimumBidPrice &&
                            touched.minimumBidPrice ? (
                              <FieldError>{errors.minimumBidPrice}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {(values.type === 'OfferToPurchaseBuyBack' ||
                      values.type === 'OfferToPurchaseThirdPartyOffer') &&
                    !values.price ? (
                      <Field name="maximumBidPrice">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Maximum Bid Price</Label>
                            <Input type="text" {...field} />
                            {errors.maximumBidPrice &&
                            touched.maximumBidPrice ? (
                              <FieldError>{errors.maximumBidPrice}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === 'ExchangeOffer' ? (
                      <Field name="contraCusip">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Contra Cusip</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === 'ExchangeOffer' ? (
                      <Field name="ratio">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Ratio</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === 'MergerConsiderationElection' ||
                    values.type === 'ConsentSolicitation' ? (
                      <Field name="consentPayment">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Consent Payment</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === 'RightsIssue' ? (
                      <Field name="tradessss">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Trade Security</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === 'RightsIssue' ? (
                      <Field name="outTurn">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Out Turn</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === 'OddLotOffer' ? (
                      <Field name="minimumQualifyingQuantity">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={true}>
                              Minimum Qualifying Quantity
                            </Label>
                            <Input type="text" {...field} />
                            {errors.minimumQualifyingQuantity &&
                            touched.minimumQualifyingQuantity ? (
                              <FieldError>
                                {errors.minimumQualifyingQuantity}
                              </FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === 'OddLotOffer' ? (
                      <Field name="maximumQualifyingQuantity">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={true}>
                              Maximum Qualifying Quantity
                            </Label>
                            <Input type="text" {...field} />
                            {errors.maximumQualifyingQuantity &&
                            touched.maximumQualifyingQuantity ? (
                              <FieldError>
                                {errors.maximumQualifyingQuantity}
                              </FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === 'OddLotOffer' ||
                    values.type === 'OfferToPurchaseBuyBack' ||
                    values.type === 'OfferToPurchaseThirdPartyOffer' ? (
                      <Field name="price">
                        {({ field, form }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={values.type === 'OddLotOffer'}>
                              Price
                            </Label>
                            <Input
                              type="text"
                              {...field}
                              onChange={(event) => {
                                const value = event.target.value;
                                if (
                                  values.type === 'OfferToPurchaseBuyBack' ||
                                  values.type ===
                                    'OfferToPurchaseThirdPartyOffer'
                                ) {
                                  form.setFieldValue('bidIncrement', '');
                                  form.setFieldValue('minimumBidPrice', '');
                                  form.setFieldValue('maximumBidPrice', '');
                                }

                                form.setFieldValue('price', value);
                              }}
                            />
                            {errors.price && touched.price ? (
                              <FieldError>{errors.price}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === 'DividendOption' ? (
                      <Field name="frequency">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={true}>Frequency</Label>
                            <Select options={frequencyOptions} {...field} />
                            {errors.frequency && touched.frequency ? (
                              <FieldError>{errors.frequency}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === 'DividendOption' ? (
                      <Field name="grossDividend">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={true}>Gross Dividend</Label>
                            <Input type="text" {...field} />
                            {errors.grossDividend && touched.grossDividend ? (
                              <FieldError>{errors.grossDividend}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === 'DividendReinvestmentPlan' ? (
                      <Field name="dripReinvestmentPrice">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>DRIP Reinvestment Price</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    <Field name="electionTitle">
                      {({ field }: FieldProps<FormValues>) => (
                        <InputGroup>
                          <Label required={true}>Election Title</Label>
                          <Input type="text" {...field} />
                          {errors.electionTitle && touched.electionTitle ? (
                            <FieldError>{errors.electionTitle}</FieldError>
                          ) : null}
                        </InputGroup>
                      )}
                    </Field>

                    <Field name="electionDetails">
                      {({ field }: FieldProps<FormValues>) => (
                        <InputGroup>
                          <Label required={true}>Election Details</Label>
                          <TextareaAutosize minRows={3} {...field} />
                          {errors.electionDetails && touched.electionDetails ? (
                            <FieldError>{errors.electionDetails}</FieldError>
                          ) : null}
                        </InputGroup>
                      )}
                    </Field>
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <div
                className="rounded border border-light-gray"
                id="documentsField"
              >
                <CreatePageDocuments
                  documents={documents}
                  onAddDocuments={handleAddDocuments}
                  onChangeName={handleChangeName}
                  onChangeType={handleChangeType}
                  onDeleteDocument={handleDeleteDocument}
                />
              </div>
            </div>
          </div>
          {dialog && <DuplicateCheckDialog {...dialog} />}
        </Form>
      )}
    </Formik>
  );
}

function InputGroup({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-col mt-2">{children}</div>;
}

const tabs = [
  {
    label: 'Filing Details',
    path: '/corporate-actions/create',
  },
];

export { Create };
