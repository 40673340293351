import React from 'react';
import { OperationsStatus } from './types';
import { Tooltip, Chip } from '@material-ui/core';
import styled from 'styled-components';

interface StatusProps {
  filingStatus: OperationsStatus;
}

const StatusBadge = ({ filingStatus }: StatusProps) => {
  const mapStatusToLabel = new Map([
    [OperationsStatus.Draft, 'DRAFT'],
    [OperationsStatus.New, 'NEW'],
    [OperationsStatus.Active, 'ACTIVE'],
    [OperationsStatus.MaterialsRequested, 'REQUESTED'],
    [OperationsStatus.ReadyForReview, 'COMPLETE'],
    [OperationsStatus.Approved, 'APPROVED'],
    [OperationsStatus.Closed, 'CLOSED'],
  ]);

  return (
    <Tooltip title={filingStatus} arrow={true}>
      <StyledChip
        label={mapStatusToLabel.get(filingStatus) || ''}
        variant="outlined"
        size="small"
        status={filingStatus}
      />
    </Tooltip>
  );
};

const StyledChip = styled(Chip)<{ status: OperationsStatus }>`
  border: 1px solid
    ${({ status }) => {
      switch (status) {
        case OperationsStatus.Draft:
        case OperationsStatus.New:
          return 'rgba(0, 0, 0, 0.9)';
        case OperationsStatus.Active:
          return '#11CC99';
        case OperationsStatus.MaterialsRequested:
          return '#1853B4';
        case OperationsStatus.ReadyForReview:
          return '#EE8B35';
        case OperationsStatus.Approved:
          return '#11CC99';
        case OperationsStatus.Closed:
          return 'rgba(0, 0, 0, 0.9)';
      }
    }};
`;

export { StatusBadge };
