import React, { FC } from 'react';
import moment from 'moment';
import { FieldProps } from 'formik';
import { Asterisk } from '../Asterik/Asterik';
import { PopoverPosition } from '@blueprintjs/core';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { ErrorLabel } from '../ErrorLabel';
import { DateInput } from '@blueprintjs/datetime';
import styled from 'styled-components/macro';

const StyledDateInput = styled(DateInput)`
  font-size: 12px;
`;

interface IProps extends FieldProps<any> {
  disabled?: boolean;
  fieldName: string;
  label?: string;
  isRequired?: boolean;
}

const FORMAT = 'MM/DD/YYYY';
const DASH_FORMAT = 'YYYY-MM-DD';

export const GenericDate: FC<IProps> = ({
  disabled,
  field,
  form,
  fieldName,
  label,
  isRequired = false,
}) => {
  return (
    <InputGroup>
      {label && (
        <Label htmlFor={label}>
          {label} {isRequired && <Asterisk />}
          {(fieldName === 'recordDate' ||
            fieldName === 'brokerSearchReceivedDate' ||
            fieldName === 'notificationReceivedDate' ||
            fieldName === 'recordDateStart' ||
            fieldName === 'recordDateEnd' ||
            fieldName === 'electionCutoffDate') &&
            form.errors[fieldName] && (
              <ErrorLabel>{form.errors[fieldName]}</ErrorLabel>
            )}
        </Label>
      )}

      <StyledDateInput
        reverseMonthAndYearMenus={true}
        disabled={disabled}
        formatDate={(date) => (date ? moment(date).format(FORMAT) : '')}
        maxDate={new Date('12/31/2100')}
        parseDate={(str) => moment(str, FORMAT).toDate()}
        placeholder={FORMAT}
        popoverProps={{
          position: PopoverPosition.BOTTOM,
          usePortal: false,
        }}
        value={field.value ? moment(field.value, DASH_FORMAT).toDate() : null}
        onChange={(selectedDate: Date) => {
          const stringDate =
            selectedDate === null
              ? null
              : moment(selectedDate).format(DASH_FORMAT);
          form.setFieldValue(fieldName, stringDate);
        }}
      />
    </InputGroup>
  );
};
