import { capitalize } from '@material-ui/core';
import { decamelize } from 'humps';

function formatErrors(error: any) {
  const formattedErrorString = Object.entries(error).reduce((acc, curr) => {
    const fieldName = decamelize(curr[0]).split('_').join(' ');
    const fieldErrors = (curr[1] as string[]).join(' ');

    return `${acc}\n${capitalize(fieldName)}: ${fieldErrors}`;
  }, '');

  return formattedErrorString;
}

export { formatErrors };
