import React, { FC } from 'react';
import { Modal } from '../../../common/Modal';
import { Dialog } from '../../../common/Dialog/Dialog';
import { OutlineButton } from '../../../common/Buttons/OutlineButton';
import { Button } from '../../../common/Buttons/Button';
import styled from 'styled-components/macro';
import { Box } from '@rebass/grid';
import { Text } from '../../../common/Text';

const Buttons = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
`;

interface ConfirmDeleteProspectusDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmDeleteProspectusDialog: FC<ConfirmDeleteProspectusDialogProps> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      top={20}
    >
      <Dialog title="Delete Prospectus" isGray={true} onRequestClose={onCancel}>
        <Box width={1}>
          <Text as="p" mb={3}>
            Are you sure you want to delete this document? Deleting certain
            documents could render this prospectus undeliverable.
          </Text>

          <Buttons>
            <OutlineButton type="button" width={1} mr={2} onClick={onCancel}>
              Cancel
            </OutlineButton>

            <Button type="button" width={1} ml={2} onClick={onConfirm}>
              Confirm
            </Button>
          </Buttons>
        </Box>
      </Dialog>
    </Modal>
  );
};
