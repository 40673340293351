import React, { FC } from 'react';
import { Modal } from '../common/Modal';
import { Dialog } from '../common/Dialog/Dialog';
import styled from 'styled-components/macro';
import { OutlineButton as DefaultOutlineButton } from '../common/Buttons/OutlineButton';
import { Button as DefaultButton } from '../common/Buttons/Button';

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'text text'
    'cancel-button confirm-button';
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  width: 100%;
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights[2]};
  color: ${({ theme }) => theme.colors.black};
  grid-area: text;
`;

const OutlineButton = styled(DefaultOutlineButton)`
  grid-area: cancel-button;
`;

const Button = styled(DefaultButton)`
  grid-area: confirm-button;
`;

interface MarkReadyForReviewDialogProps {
  isOpen: boolean;
  onMarkReadyForReviewAndSave: () => void;
  onRequestClose: () => void;
  onSaveOnly: () => void;
}

export const MarkReadyForReviewDialog: FC<MarkReadyForReviewDialogProps> = ({
  isOpen,
  onMarkReadyForReviewAndSave,
  onRequestClose,
  onSaveOnly,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Dialog title="Ready for Review?" onRequestClose={onRequestClose}>
        <Wrapper>
          <Text>Would you like to mark this filing ready for review?</Text>

          <OutlineButton type="button" onClick={onSaveOnly}>
            Save Only
          </OutlineButton>

          <Button type="button" onClick={onMarkReadyForReviewAndSave}>
            Ready for Review
          </Button>
        </Wrapper>
      </Dialog>
    </Modal>
  );
};
