import React from 'react';

type ItemRowProps = {
  children: React.ReactNode;
  className?: string;
};

function ItemRow({ children, className }: ItemRowProps) {
  return (
    <div
      className={`grid gap-4 rounded-md px-4 py-6 border border-light-gray mt-2 first:mt-0 ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
}

export { ItemRow };
