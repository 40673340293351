import { useState } from 'react';
import { createContainer } from 'unstated-next';

interface LocationState {
  backButtonPath: string;
}

const initialLocationState = {
  backButtonPath: '/',
};

const useLocation = () => {
  const [location, updateLocation] = useState<LocationState>(
    initialLocationState,
  );

  const setBackButtonPath = (path: string) => {
    updateLocation({
      ...location,
      backButtonPath: path,
    });
  };

  return { location, setBackButtonPath };
};

const Location = createContainer(useLocation);

export { Location, useLocation };
