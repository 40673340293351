import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import { GenericDate } from './GenericDate';
import { IFilingCreateValues } from '../../models/filing-form';

interface MeetingDateProps {
  disabled?: boolean;
}

export const MeetingDate: FC<MeetingDateProps> = ({ disabled }) => {
  return (
    <Field
      name="meetingDate"
      render={({ field, form }: FieldProps<IFilingCreateValues>) => (
        <GenericDate
          field={field}
          form={form}
          label="Meeting Date"
          fieldName={'meetingDate'}
          isRequired={false}
        />
      )}
    />
  );
};
