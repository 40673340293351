import React from 'react';
import { RouteComponentProps, Link, LinkGetProps } from '@reach/router';
import { useCorporateAction } from './useCorporateAction';
import { Header } from './Header';
import { BrokerEmailStatusBar } from './BrokerEmailStatusBar';
import { FaChevronLeft, FaChevronRight, FaEnvelope } from 'react-icons/fa';
import { BrokersList } from './BrokersList';
import { toast } from 'react-toastify';
import { validateEmailAddress } from '../../utils/validate-email-address';
import useSWR from 'swr';
import { privateApi } from '../../old/utils/api-adapter';
import { ApproveDialog } from './ApproveDialog';
import { OperationsStatus } from './types';
import { formatUpdateStatusError } from '../../utils/format-update-status-error';

const brokersFetcher = (url: string) => privateApi.get<Broker[]>(url);
const emailPreviewFetcher = (url: string) => privateApi.get(url);

type Broker = {
  id: number;
  name: string;
  slug: string;
  globalBrokerId: string;
  totalPositions: number;
  childBrokers?: Broker[];
};

type BrokerEmailProps = RouteComponentProps<{ id: string }>;

function BrokerEmail({ id }: BrokerEmailProps) {
  const { data, mutate } = useCorporateAction(id);

  const brokers = useSWR(
    `/brokers/corporate-action-broker-typeahead/?ca_id=${id}`,
    brokersFetcher,
  );

  const [currentBrokerIndex, setCurrentBrokerIndex] = React.useState(0);
  const [selectedBrokerIds, setSelectedBrokerIds] = React.useState<number[]>(
    data ? data.data.brokers.map((b) => b.id) : [],
  );
  const [testEmailAddresses, setTestEmailAddresses] = React.useState<string[]>(
    [],
  );
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const emailPreview = useSWR(
    brokers.data && selectedBrokerIds.length
      ? `/admin/vca/${id}/preview/${getBrokerSlugById(
          selectedBrokerIds[currentBrokerIndex],
        )}/`
      : null,
    emailPreviewFetcher,
  );

  function flattenBrokers(brokers: Broker[]): Broker[] {
    return brokers
      .map((b) => [
        { ...b },
        ...flattenBrokers(b.childBrokers ? b.childBrokers : []),
      ])
      .flat();
  }

  function getBrokerSlugById(id: number) {
    if (brokers.data) {
      const flatBrokers = flattenBrokers(brokers.data.data);
      const broker = flatBrokers.find((broker) => broker.id === id);

      if (broker) {
        return broker.slug;
      }

      return null;
    }

    return null;
  }

  function getBrokerNameById(id: number) {
    if (brokers.data) {
      const flatBrokers = flattenBrokers(brokers.data.data);
      const broker = flatBrokers.find((broker) => broker.id === id);

      if (broker) {
        return broker.name;
      }

      return null;
    }

    return null;
  }

  React.useEffect(() => {
    if (data) {
      setSelectedBrokerIds(data.data.brokers.map((broker) => broker.id));
    }
  }, [data]);

  function handleChangeEmailAddresses(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const splitEmails = event.currentTarget.value.split(',');

    setTestEmailAddresses(splitEmails);
  }

  async function handleSendTestEmail(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      await privateApi.post(
        `/admin/vca/${id}/preview/${getBrokerSlugById(
          selectedBrokerIds[currentBrokerIndex],
        )}/`,
        { emails: testEmailAddresses },
      );
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error(`An error occurred when trying to send a test email.`);
      }
    }
    toast.success('Test email sent!');
  }

  function validateAllEmailAddresses() {
    return testEmailAddresses.every(validateEmailAddress);
  }

  function handleAddBroker(brokerId: number) {
    if (brokers.data) {
      const foundBroker = brokers.data.data.find(
        (broker) => broker.id === brokerId,
      );

      if (
        foundBroker &&
        foundBroker.childBrokers &&
        foundBroker.childBrokers.length
      ) {
        const parentAndChildBrokerIds = [
          ...foundBroker.childBrokers.map((childBroker) => childBroker.id),
          brokerId,
        ];

        setSelectedBrokerIds([
          ...selectedBrokerIds,
          ...parentAndChildBrokerIds,
        ]);
      } else {
        setSelectedBrokerIds([...selectedBrokerIds, brokerId]);
      }
    }
  }

  function handleRemoveBroker(brokerId: number) {
    if (brokers.data) {
      const foundBroker = brokers.data.data.find(
        (broker) => broker.id === brokerId,
      );

      if (
        foundBroker &&
        foundBroker.childBrokers &&
        foundBroker.childBrokers.length
      ) {
        const parentAndChildBrokerIds = [
          ...foundBroker.childBrokers.map((childBroker) => childBroker.id),
          brokerId,
        ];

        const filteredSelectedBrokerIds = selectedBrokerIds.filter(
          (selectedBrokerId) =>
            !parentAndChildBrokerIds.includes(selectedBrokerId),
        );

        setSelectedBrokerIds(filteredSelectedBrokerIds);
      } else {
        setSelectedBrokerIds(
          selectedBrokerIds.filter(
            (selectedBrokerId) => selectedBrokerId !== brokerId,
          ),
        );
      }
    }
  }

  async function handleSaveBrokerIds() {
    try {
      const vca = privateApi.patch(`/admin/vca/${id}/`, {
        brokerIds: selectedBrokerIds,
      });
      mutate(vca);
    } catch (error) {
      toast.error('Unable to add brokers to this corporate action.');
    }
  }

  async function handleMarkAsApproved() {
    try {
      const payload: { operationsStatus: OperationsStatus } = {
        operationsStatus: 'approved',
      };
      await privateApi.patch(`/admin/vca/${id}/update-status/`, payload);

      mutate();
      setIsDialogOpen(false);
    } catch (error) {
      toast.error(formatUpdateStatusError(error));
    }
  }

  const isActive = ({ isCurrent }: LinkGetProps) => {
    return isCurrent
      ? {
          className:
            'font-medium text-primary-text mr-8 last:mr-0 hover:no-underline hover:text-green border-b-2 border-green py-4',
        }
      : {
          className:
            'text-primary-text mr-8 last:mr-0 hover:no-underline hover:text-green border-b-2 border-transparent py-4',
        };
  };

  if (data) {
    const { issuer, operationsStatus } = data.data;

    return (
      <div>
        <Header issuerName={issuer ? issuer.companyName : ''} />

        <div className="px-8 py-4 bg-very-light-gray border-t border-b border-light-gray">
          <Link to={`/corporate-actions/${id}`} getProps={isActive}>
            Filing Details
          </Link>
          <Link
            to={`/corporate-actions/${id}/broker-email`}
            getProps={isActive}
          >
            Broker Email
          </Link>
        </div>

        <BrokerEmailStatusBar
          status={operationsStatus}
          actionButton={
            <ApproveButton
              onClick={() => {
                if (
                  data.data.operationsStatus === 'new' ||
                  data.data.operationsStatus === 'active'
                ) {
                  toast.error(
                    'Status must be ready for review in order to mark it as approved.',
                  );
                  return;
                }

                if (data.data.operationsStatus === 'approved') {
                  toast.error('Status is already marked as approved.');
                  return;
                }

                setIsDialogOpen(true);
              }}
              isDisabled={!selectedBrokerIds.length}
            />
          }
        />

        <div className="grid grid-cols-2 gap-8 py-4 px-8">
          <div>
            <h2 className="text-secondary-text font-medium text-xs uppercase">
              Add Brokers
            </h2>
            <div className="flex items-center justify-between bg-very-light-gray mt-4 p-4">
              <span className="uppercase text-secondary-text font-medium text-xxs">
                Broker
              </span>
              <span className="text-right uppercase text-secondary-text font-medium text-xxs">
                Positions
              </span>
            </div>

            <BrokersList
              brokers={brokers.data ? brokers.data.data : []}
              onAddBroker={handleAddBroker}
              onRemoveBroker={handleRemoveBroker}
              selectedBrokerIds={selectedBrokerIds}
            />

            <div className="grid grid-cols-2 gap-x-8 mt-10">
              <button
                type="button"
                className="text-gray hover:text-secondary-text transition-colors duration-300 justify-self-end"
                onClick={() => setSelectedBrokerIds([])}
              >
                Cancel
              </button>
              <button
                type="button"
                className="text-green hover:text-light-green transition-colors duration-300 justify-self-start disabled:text-light-gray disabled:cursor-not-allowed"
                disabled={!selectedBrokerIds.length}
                onClick={handleSaveBrokerIds}
              >
                Save
              </button>
            </div>
          </div>

          <div>
            <div className="rounded overflow-hidden border border-light-gray  bg-very-light-gray">
              <div className="grid items-center gap-x-8 grid-cols-2 px-4 py-2 bg-white">
                <h2 className="text-secondary-text font-medium text-xs uppercase">
                  Review Broker Email
                </h2>

                <form
                  onSubmit={handleSendTestEmail}
                  className="flex items-center"
                >
                  <input
                    placeholder="Send test email (separate emails by comma)"
                    className="bg-very-light-gray h-8 px-2 rounded border border-transparent focus:border-primary-text focus:outline-none focus:bg-white w-full"
                    value={testEmailAddresses.join(',')}
                    onChange={handleChangeEmailAddresses}
                    disabled={!selectedBrokerIds.length}
                  />
                  <button
                    disabled={
                      !testEmailAddresses.length ||
                      !validateAllEmailAddresses() ||
                      !selectedBrokerIds.length
                    }
                    type="submit"
                    className="text-green hover:text-light-green disabled:text-secondary-text disabled:cursor-not-allowed transition-colors duration-300 ml-4"
                  >
                    <FaEnvelope />
                  </button>
                </form>
              </div>

              {selectedBrokerIds.length ? (
                <div>
                  <div className="bg-dark-gray text-light-gray text-sm font-medium h-8 px-4 flex items-center justify-between">
                    <button
                      type="button"
                      onClick={() =>
                        setCurrentBrokerIndex(
                          (currentBrokerIndex + selectedBrokerIds.length - 1) %
                            selectedBrokerIds.length,
                        )
                      }
                    >
                      <FaChevronLeft className="mr-4" />
                    </button>
                    <span>
                      {getBrokerNameById(selectedBrokerIds[currentBrokerIndex])}{' '}
                      ({currentBrokerIndex + 1} of {selectedBrokerIds.length})
                    </span>
                    <button
                      type="button"
                      onClick={() =>
                        setCurrentBrokerIndex(
                          (currentBrokerIndex + 1) % selectedBrokerIds.length,
                        )
                      }
                    >
                      <FaChevronRight className="ml-4" />
                    </button>
                  </div>

                  <div className="h-8 flex items-center justify-center bg-white text-primary-text text-sm px-4">
                    <p className="whitespace-no-wrap">
                      <span className="font-medium">Subject Line:</span>{' '}
                      {emailPreview.data
                        ? emailPreview.data.data.subject
                        : 'Unable to load template'}
                    </p>
                  </div>
                  {emailPreview.data ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: emailPreview.data.data.body,
                      }}
                    />
                  ) : (
                    <div className="flex flex-col items-center justify-center py-64">
                      <span
                        role="img"
                        className="text-6xl"
                        aria-label="sad-face"
                      >
                        🌧
                      </span>
                      <p className="text-primary-text font-normal">
                        Unable to load template
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center py-64">
                  <span role="img" className="text-6xl" aria-label="sad-face">
                    😰
                  </span>
                  <p className="text-primary-text font-normal">
                    Add Brokers in order to preview email.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {isDialogOpen ? (
          <ApproveDialog
            onClose={() => setIsDialogOpen(false)}
            onConfirm={handleMarkAsApproved}
          />
        ) : null}
      </div>
    );
  }

  return null;
}

type ApproveButtonProps = {
  onClick: () => void;
  isDisabled?: boolean;
};

function ApproveButton({ onClick, isDisabled = false }: ApproveButtonProps) {
  return (
    <button
      className="bg-green rounded hover:bg-light-green transition-colors duration-300 h-8 text-xs font-medium text-primary-text px-2 disabled:bg-light-gray disabled:cursor-not-allowed"
      onClick={onClick}
      disabled={isDisabled}
    >
      Approve
    </button>
  );
}

export { BrokerEmail };
