import React, { useState, FC, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Input } from '../../../common/Input';
import { Box, Flex } from '@rebass/grid';
import { Formik, FormikProps, Field, FieldProps } from 'formik';
import * as Yup from 'yup';
import { SecurityTypeahead } from '../../SecurityTypeahead';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../data/store';
import { formatDate } from '../../../utils/format-date';
import { CusipCell } from '../../../common/List/CusipCell';
import { ConfirmDeleteProspectusDialog } from './ConfirmDeleteProspectusDialog';
import { ConfirmSaveProspectusDialog } from './ConfirmSaveProspectusDialog';
import { updateProspectus, deleteProspectus } from '../../../data/funds';
import { ErrorLabel } from '../../../common/ErrorLabel';
import { Button } from '../../../common/Buttons/Button';
import { PlainDangerButton } from '../../../common/Buttons/PlainDangerButton';
import { OutlineButton } from '../../../common/Buttons/OutlineButton';
import { createSecurity } from '../../../data/hubApiRoutes';
import { toast } from 'react-toastify';
import { ToastErrorList } from '../../../common/ToastErrorList';
import { userIdEvent } from '../../../utils/analytics-helpers';
import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';
import { PopoverPosition } from '@blueprintjs/core';

const FORMAT = '/YYYY';
const DASH_FORMAT = 'YYYY-MM-DD';

const FullWidthForm = styled(Flex)`
  font-size: 12px;
  width: 100%;
  min-width: 1400px;
`;

const Cell = styled(Box)`
  background: #fff;
  padding: 15px;
  text-transform: capitalize;
  overflow-x: auto;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.blue};

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const StyledDateInput = styled(DateInput)`
  font-size: 12px;
`;

enum UIState {
  Editing,
  Viewing,
}

interface FormValues {
  name: string;
  frontLink: string;
  website: string;
  securities: {
    id: string;
    cusip: string;
  }[];
  effectiveEndDate: string;
}

interface DocumentTableRowProps {
  prospectusId: string;
  issuerId: string;
  issuerSecurities: {
    id: string;
    cusip: string;
  }[];
  status: 'active' | 'upcoming' | 'archived';
}

const ValidationSchema = Yup.object().shape({
  securities: Yup.array().required('You must include at least one security'),
  name: Yup.string().required(),
  frontLink: Yup.string().url().notRequired(),
  fundWebsite: Yup.string().url().notRequired(),
});

const DocumentTableRow: FC<DocumentTableRowProps> = ({
  prospectusId,
  issuerSecurities,
  issuerId,
  status,
}) => {
  const prospectus = useSelector(
    (state: AppState) => state.funds.prospectuses[prospectusId],
  );
  const dispatch = useDispatch();
  const [isSaveDialogOpen, setSaveDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [uiState, setUIState] = useState<UIState>(UIState.Viewing);

  const prospectusSecurities =
    prospectus &&
    prospectus.securities
      .map((security) =>
        issuerSecurities.filter(
          (issuerSecurity) => issuerSecurity.id === security,
        ),
      )
      .flat();

  const calculatedProspectusExpirationDate = useMemo(() => {
    if (prospectus) {
      if (prospectus.effectiveEndDate) {
        return formatDate(prospectus.effectiveEndDate);
      }

      return formatDate(
        new Date(prospectus.effectiveStartDate).setFullYear(
          new Date(prospectus.effectiveStartDate).getFullYear() + 1,
        ),
      );
    }
  }, [prospectus]);

  const handleSaveClick = () => setSaveDialogOpen(true);
  const handleCancelSave = () => setSaveDialogOpen(false);
  const handleConfirmSave = (formikBag: FormikProps<FormValues>) => {
    formikBag.submitForm();
    setUIState(UIState.Viewing);
    setSaveDialogOpen(false);
  };

  const handleEditClick = (type: string) => {
    userIdEvent('Edit Document Clicked', {
      fundId: prospectus.id,
      documentType: type,
    });

    setUIState(UIState.Editing);
  };
  const handleCancelEditingClick = () => setUIState(UIState.Viewing);

  const handleDeleteClick = () => setDeleteDialogOpen(true);
  const handleCancelDelete = () => setDeleteDialogOpen(false);
  const handleConfirmDelete = () => {
    dispatch(deleteProspectus(prospectusId, issuerId, status, prospectus.type));
    setDeleteDialogOpen(false);
  };
  const { effectiveStartDate } = prospectus;

  const handleSubmit = ({
    name,
    securities,
    frontLink,
    website,
    effectiveEndDate,
  }: FormValues) => {
    const prospectus = {
      securityIds: securities.map((security) => security.id),
      name,
      frontLink,
      website,
      effectiveEndDate,
      effectiveStartDate,
    };
    dispatch(updateProspectus(prospectusId, prospectus));
  };

  return prospectus ? (
    <>
      <Formik
        initialValues={{
          name: prospectus.name,
          frontLink: prospectus.frontLink,
          website: prospectus.website,
          securities: prospectusSecurities,
          effectiveEndDate: prospectus.effectiveEndDate || '',
        }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
        render={(formikBag: FormikProps<FormValues>) => (
          <>
            <FullWidthForm>
              <Cell width={1 / 8}>
                {uiState === UIState.Editing && (
                  <Field
                    name="name"
                    render={({ field, form }: FieldProps<FormValues>) => (
                      <>
                        <Input {...field} placeholder="Name" />
                        {form.errors.name && (
                          <ErrorLabel>{form.errors.name}</ErrorLabel>
                        )}
                      </>
                    )}
                  />
                )}
                {uiState === UIState.Viewing && (
                  <Link href={prospectus.attachment} target="_blank">
                    {prospectus.name}
                  </Link>
                )}
              </Cell>
              <Cell width={1 / 8}>{prospectus.type}</Cell>
              <Cell width={1 / 8}>
                {uiState === UIState.Editing && (
                  <Field
                    name="securities"
                    render={({ field, form }: FieldProps<FormValues>) => {
                      const handleSelectSecurities = (
                        securities: {
                          id: string;
                          cusip: string;
                        }[],
                      ) => form.setFieldValue('securities', securities);

                      const handleAddSecurity = (query: string) => {
                        const security = {
                          cusip: query,
                          issuerId,
                        };
                        createSecurity(security)
                          .then((response) => {
                            return form.setFieldValue('securities', [
                              ...field.value,
                              {
                                id: response.data.id,
                                cusip: response.data.cusip,
                              },
                            ]);
                          })
                          .catch((error) => {
                            toast.error(
                              <ToastErrorList error={error.response.data} />,
                            );
                          });
                      };

                      return (
                        <>
                          <SecurityTypeahead
                            onAddSecurity={handleAddSecurity}
                            onSelectSecurities={handleSelectSecurities}
                            selectedItems={field.value}
                            items={issuerSecurities}
                          />
                          {form.errors.securities && (
                            <ErrorLabel>{form.errors.securities}</ErrorLabel>
                          )}
                        </>
                      );
                    }}
                  />
                )}
                {uiState === UIState.Viewing && (
                  <CusipCell
                    cusips={formikBag.values.securities.map(
                      (security) => security.cusip,
                    )}
                  />
                )}
              </Cell>
              <Cell width={1 / 8}>
                {formatDate(prospectus.effectiveStartDate)}
              </Cell>
              <Cell width={1 / 8}>
                {uiState === UIState.Editing && (
                  <Field
                    name="effectiveEndDate"
                    render={({ field, form }: FieldProps<FormValues>) => (
                      <>
                        <StyledDateInput
                          reverseMonthAndYearMenus={true}
                          formatDate={(date) =>
                            date ? moment(date).format(FORMAT) : ''
                          }
                          maxDate={new Date('12/31/2100')}
                          parseDate={(str) => moment(str, FORMAT).toDate()}
                          placeholder={FORMAT}
                          popoverProps={{
                            position: PopoverPosition.BOTTOM,
                            boundary: 'window',
                            usePortal: false,
                          }}
                          value={
                            field.value
                              ? moment(field.value, DASH_FORMAT).toDate()
                              : null
                          }
                          onChange={(selectedDate: Date) => {
                            const stringDate =
                              selectedDate === null
                                ? null
                                : moment(selectedDate).format(DASH_FORMAT);
                            form.setFieldValue(field.name, stringDate);
                          }}
                        />
                        {form.errors.effectiveEndDate && (
                          <ErrorLabel>
                            {form.errors.effectiveEndDate}
                          </ErrorLabel>
                        )}
                      </>
                    )}
                  />
                )}
                {uiState === UIState.Viewing &&
                  calculatedProspectusExpirationDate}
              </Cell>
              <Cell width={1 / 8}>
                {uiState === UIState.Editing && (
                  <Field
                    name="website"
                    render={({ field, form }: FieldProps<FormValues>) => (
                      <>
                        <Input {...field} placeholder="Website" />
                        {form.errors.website && (
                          <ErrorLabel>{form.errors.website}</ErrorLabel>
                        )}
                      </>
                    )}
                  />
                )}
                {uiState === UIState.Viewing && (
                  <Link href={prospectus.website} target="_blank">
                    {prospectus.website}
                  </Link>
                )}
              </Cell>
              <Cell width={1 / 8}>
                {uiState === UIState.Editing && (
                  <Field
                    name="frontLink"
                    render={({ field, form }: FieldProps<FormValues>) => (
                      <>
                        <Input {...field} placeholder="Front Link" />
                        {form.errors.frontLink && (
                          <ErrorLabel>{form.errors.frontLink}</ErrorLabel>
                        )}
                      </>
                    )}
                  />
                )}
                {uiState === UIState.Viewing && (
                  <Link href={prospectus.frontLink} target="_blank">
                    {prospectus.frontLink}
                  </Link>
                )}
              </Cell>
              <Cell width={1 / 8}>
                {uiState === UIState.Editing && (
                  <>
                    <OutlineButton
                      type="button"
                      onClick={handleCancelEditingClick}
                    >
                      Cancel
                    </OutlineButton>

                    <Button
                      type="button"
                      disabled={
                        !formikBag.isValid ||
                        formikBag.isSubmitting ||
                        effectiveStartDate > formikBag.values.effectiveEndDate
                      }
                      onClick={handleSaveClick}
                    >
                      Save
                    </Button>
                  </>
                )}
                {uiState === UIState.Viewing && (
                  <>
                    <Button
                      type="button"
                      onClick={() => {
                        handleEditClick(prospectus.type);
                      }}
                    >
                      Edit
                    </Button>

                    <PlainDangerButton
                      type="button"
                      onClick={handleDeleteClick}
                    >
                      Delete
                    </PlainDangerButton>
                  </>
                )}
              </Cell>
            </FullWidthForm>

            <ConfirmSaveProspectusDialog
              onConfirm={() => handleConfirmSave(formikBag)}
              isOpen={isSaveDialogOpen}
              onCancel={handleCancelSave}
            />

            <ConfirmDeleteProspectusDialog
              onConfirm={handleConfirmDelete}
              isOpen={isDeleteDialogOpen}
              onCancel={handleCancelDelete}
            />
          </>
        )}
      />
    </>
  ) : null;
};

export { DocumentTableRow };
