import React, { FC, MouseEvent } from 'react';
import { Flex } from '@rebass/grid';

import styled from 'styled-components/macro';
import { ReactComponent as Plus } from './assets/plus.svg';

const StyledButton = styled(Flex)<{ disabled: boolean }>`
  width: 100%;
  background-color: #ffffff;
  height: 55px;
  color: ${({ theme }) => theme.colors.black};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};
`;

const PlusButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  padding: 11px 0 0 0;
  text-align: center;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba(210, 210, 210, 0.3),
    0 7px 4px 0 rgba(210, 210, 210, 0.3), 0 0 7px 0 rgba(210, 210, 210, 0.3);
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  text-transform: uppercase;
  margin: 0 0 0 20px;
`;

interface IProps {
  disabled: boolean;
  label: string;
  hanldeClick: () => void;
}
export const AddButton: FC<IProps> = ({ disabled, label, hanldeClick }) => (
  <StyledButton
    flexDirection="row"
    disabled={disabled}
    alignItems="center"
    px={13}
    onClick={(e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (!disabled) {
        hanldeClick();
      }
    }}
  >
    <PlusButton>
      <Plus />
    </PlusButton>
    <Text>New {label}</Text>
  </StyledButton>
);
