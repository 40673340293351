import React, { useState, FC, MouseEvent, useContext } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as TrashIcon } from '../common/assets/trash.svg';
import { ReactComponent as EditIcon } from '../common/assets/edit.svg';
import { ReactComponent as FileIcon } from '../common/assets/file.svg';
import { Document } from '../models/document';
import { FilingContext } from '../data/Filing.Context';
import { DeleteDocumentWarningDialog } from './DeleteDocumentWarningDialog';

const Link = styled.a``;

const DocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 95px;
`;

const DocumentContent = styled.div<{ shouldDistribute: boolean }>`
  align-items: center;
  background: ${({ shouldDistribute }) => (shouldDistribute ? 'auto' : '#000')};
  border: 1px solid #d9d9d9;
  display: flex;
  height: 115px;
  justify-content: center;
  position: relative;
`;

const DistributeText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
`;

const ActionOverlay = styled.div`
  align-items: center;
  background: rgba(242, 242, 242, 0.85);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

const Action = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const DocumentTypeName = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 10px;
  margin-top: 8px;
  text-align: center;
  text-transform: capitalize;
`;

interface DocumentPreviewProps {
  document: Document;
}

export const DocumentPreview: FC<DocumentPreviewProps> = ({ document }) => {
  const {
    deleteDocument,
    setDocumentIdEditing,
    setEditDocumentDialogOpen,
  } = useContext(FilingContext);
  const [isMouseEntered, setMouseEntered] = useState<boolean>(false);
  const [isDeleteDocumentDialogOpen, setIsDeleteDocumentDialogOpen] = useState<
    boolean
  >(false);

  return (
    <>
      <DocumentWrapper>
        <Link
          href={document.attachment ? document.attachment : '#'}
          target="_blank"
        >
          <DocumentContent
            onMouseEnter={() => setMouseEntered(true)}
            onMouseLeave={() => setMouseEntered(false)}
            shouldDistribute={document.shouldDistributeInNextMailing}
          >
            {!document.shouldDistributeInNextMailing && (
              <DistributeText>Do Not Distribute</DistributeText>
            )}

            {document.shouldDistributeInNextMailing && <FileIcon />}

            {isMouseEntered && (
              <ActionOverlay>
                <Action
                  onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault();
                    setDocumentIdEditing(document.id);
                    setEditDocumentDialogOpen(true);
                  }}
                >
                  <EditIcon />
                </Action>
                <Action
                  onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault();
                    setIsDeleteDocumentDialogOpen(true);
                  }}
                >
                  <TrashIcon />
                </Action>
              </ActionOverlay>
            )}
          </DocumentContent>
        </Link>

        <DocumentTypeName>{document.name}</DocumentTypeName>
      </DocumentWrapper>

      <DeleteDocumentWarningDialog
        onConfirmDelete={() => deleteDocument(document.id)}
        onRequestClose={() => setIsDeleteDocumentDialogOpen(false)}
        isOpen={isDeleteDocumentDialogOpen}
      />
    </>
  );
};
