import React, { FC, ChangeEvent } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ElevatedCard } from '../../../common/ElevatedCard';
import { Field, FieldProps } from 'formik';
import { Input } from '../../../common/Input';
import { Textarea } from '../../../common/Textarea';
import { InputGroup } from '../../../common/InputGroup';
import { Label } from '../../../common/Label';
import { FundDetailsFormValues } from './FundDetails';
import styled from 'styled-components/macro';
import { geocode } from '../../../utils/geocode';

const InvoicingDetails: FC<RouteComponentProps> = () => {
  return (
    <ElevatedCard mt={3} p={3}>
      <Inputs>
        <Field
          name="address"
          render={({ field, form }: FieldProps<FundDetailsFormValues>) => (
            <InputGroup>
              <Label htmlFor="address">Address</Label>
              <Input
                id="address"
                {...field}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  form.setFieldValue('address', event.currentTarget.value);
                  geocode(event.currentTarget.value).then((address) => {
                    if (address) {
                      form.setFieldValue('addressLine1', address.line1);
                      form.setFieldValue('addressLine2', address.line2);
                      form.setFieldValue('addressLine3', address.line3);
                      form.setFieldValue('city', address.city);
                      form.setFieldValue('state', address.state);
                      form.setFieldValue('postalCode', address.postalCode);
                      form.setFieldValue('country', address.country);
                    }
                  });
                }}
              />
            </InputGroup>
          )}
        />

        <InputField name="company" displayName="Company" id="company" />

        <InputField name="contact" displayName="Contact" id="contact" />

        <InputField name="email" displayName="Email" id="email" type="email" />

        <InputField
          name="addressLine1"
          displayName="Address Line 1"
          id="address-line-1"
        />

        <InputField
          name="addressLine2"
          displayName="Address Line 2"
          id="address-line-2"
        />

        <InputField
          name="addressLine3"
          displayName="Address Line 3"
          id="address-line-3"
        />

        <InputField name="city" displayName="City" id="city" />

        <InputField name="state" displayName="State" id="state" />

        <InputField
          name="postalCode"
          displayName="Postal Code"
          id="postal-code"
        />

        <InputField name="country" displayName="Country" id="country" />

        <Field
          name="notes"
          render={({ field }: FieldProps<FundDetailsFormValues>) => (
            <InputGroup>
              <Label htmlFor="notes">Notes</Label>
              <Textarea id="notes" rows={5} {...field} />
            </InputGroup>
          )}
        />
      </Inputs>
    </ElevatedCard>
  );
};

export { InvoicingDetails };

const Inputs = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
`;

const InputField = ({
  name,
  displayName,
  id,
  type = 'text',
}: {
  name: string;
  displayName: string;
  id: string;
  type?: string;
}) => (
  <Field
    name={name}
    render={({ field }: FieldProps<FundDetailsFormValues>) => (
      <InputGroup>
        <Label htmlFor={id}>{displayName}</Label>
        <Input id={id} type={type} {...field} />
      </InputGroup>
    )}
  />
);
