import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { ReactComponent as Chevron } from './chevron.svg';

const StyleLi = styled.div<{ customHeight?: number }>`
  width: 100%;
  min-height: ${({ customHeight }) =>
    customHeight ? `${customHeight}px` : '50px'};
  height: auto;
  cursor: pointer;
`;

const StyledFlex = styled(Flex)`
  height: 50px;
  padding: 0 calc(1% + 10px) 0 calc(1% + 10px);
`;

const ChevronContainer = styled(Box)<{ open: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

interface IParentLi {
  title: string;
  open: boolean;
  children: React.ReactNode;
  customHeight?: number;
  onClick?: () => void;
}

export const ParentLi: React.FC<IParentLi> = ({
  title,
  open,
  children,
  onClick,
  customHeight,
}) => {
  return (
    <StyleLi onClick={onClick} customHeight={customHeight}>
      <StyledFlex
        flexDirection="row"
        width={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>{title}</Box>
        <ChevronContainer open={open}>
          <Chevron />
        </ChevronContainer>
      </StyledFlex>
      <div>{open && children}</div>
    </StyleLi>
  );
};
