import React, { FC } from 'react';
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  FormikActions,
} from 'formik';
import { RouteComponentProps, navigate } from '@reach/router';
import { Flex } from '@rebass/grid';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import { Input } from '../common/Input';
import { Button } from '../common/Buttons/Button';
import { InputGroup } from '../common/InputGroup';
import * as Yup from 'yup';
import { ssoPublicApi } from '../utils/api-adapter';
import { setAuthToken } from '../utils/set-token';
import jwtDecode from 'jwt-decode';

declare global {
  interface Window {
    analytics: any;
  }
}

const Wrapper = styled(Flex)`
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
`;

const StyledForm = styled(Form)`
  max-width: 425px;
  width: 100%;
`;

interface LoginFormValues {
  email: string;
  password: string;
}

const loginSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
});

const handleSubmit = async (email: string, password: string) => {
  return await ssoPublicApi.post(`/auth/login/`, {
    email,
    password,
  });
};

type UserTraits = {
  name: string;
  email: string;
  username: string;
};

const identifyUser = ({ id, traits }: { id: string; traits: UserTraits }) => {
  window.analytics.identify(id, traits);
};

export const Login: FC<RouteComponentProps> = ({ location }) => {
  return (
    <Wrapper alignItems="center" flexDirection="column" justifyContent="center">
      <Title>
        Mission Control{' '}
        <span role="img" aria-label="rocket">
          🚀
        </span>
      </Title>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginSchema}
        onSubmit={(
          values: LoginFormValues,
          actions: FormikActions<LoginFormValues>,
        ) =>
          handleSubmit(values.email, values.password)
            .then((response) => {
              actions.setSubmitting(false);
              const { token, user } = response.data;
              const decodedJWT: { groups: string[] } = jwtDecode(token);
              const canLogIn: boolean =
                decodedJWT.groups.includes('proxy_admins') &&
                decodedJWT.groups.includes('hub_admin');

              if (canLogIn) {
                setAuthToken(token);
                identifyUser({
                  id: user.pk,
                  traits: {
                    email: user.email,
                    username: user.username,
                    name: `${user.firstName} ${user.lastName}`,
                  },
                });
                if (location) {
                  navigate(location.pathname);
                } else {
                  navigate('');
                }
              }
            })
            .catch((error) => {
              actions.setSubmitting(false);
              /* if (error.response) 
                const nonFieldErrors: string[] | undefined =
                error.response.data.nonFieldErrors;

              if (nonFieldErrors) {
                const errorString = nonFieldErrors.reduce(
                  (acc: string, curr: string) =>
                    acc.length ? `${acc}\n${curr}` : curr,
                  '',
                );
                return toast.error(errorString);
                console.log(error)
              } */

              return toast.error(
                'Unable to login. Please check your credentials and try again.',
              );
            })
        }
        render={({ isValid, isSubmitting }: FormikProps<LoginFormValues>) => (
          <StyledForm>
            <Flex flexDirection="column">
              <Field
                name="email"
                render={({ field }: FieldProps<LoginFormValues>) => (
                  <InputGroup>
                    <Input
                      required={true}
                      type="text"
                      {...field}
                      placeholder="email@saytechnologies.com"
                    />
                  </InputGroup>
                )}
              />

              <Field
                name="password"
                render={({ field }: FieldProps<LoginFormValues>) => (
                  <InputGroup>
                    <Input
                      type="password"
                      required={true}
                      {...field}
                      placeholder="superdupersecret"
                    />
                  </InputGroup>
                )}
              />

              <Button mt={3} disabled={!isValid || isSubmitting}>
                {isSubmitting ? 'Loading... ⏳' : 'Log In'}
              </Button>
            </Flex>
          </StyledForm>
        )}
      />
    </Wrapper>
  );
};
