import React, { useState, useEffect, useContext } from 'react';
import { Field, FieldProps } from 'formik';
import { MultiSelect, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import {
  MenuItem,
  ITagProps,
  Intent,
  Button,
  PopoverPosition,
} from '@blueprintjs/core';

import { IFilingCreateValues } from '../../models/filing-form';
import { Asterisk } from '../Asterik/Asterik';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { ErrorLabel } from '../ErrorLabel';
import { toast } from 'react-toastify';
import { privateApi } from '../../utils/api-adapter';
import { FilingContext } from '../../data/Filing.Context';
import { OperationsStatus } from '../../models/filing-details';
import { BrokerChangesDialog } from '../../Filing/BrokerChangesDialog';

type Broker = {
  id: number;
  name: string;
  slug: string;
  globalBrokerId: string;
  childBrokers?: Broker[];
};

const BrokerMultiSelect = MultiSelect.ofType<Broker>();

const renderTag = (broker: Broker) => broker.name;

const fetchBrokers = async (query?: string) => {
  try {
    const queryString = query ? `?name=${query}` : '';
    const response = await privateApi.get<Broker[]>(
      `/brokers/corporate-action-broker-typeahead/?${queryString}`,
    );

    return response;
  } catch (error) {
    toast.error('Not able to find brokers');
  }
};

const filterBroker: ItemPredicate<Broker> = (query: string, broker: Broker) => {
  return `${broker.name.toLocaleLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

export const BrokerTypeahead = () => {
  const [query, setQuery] = useState<string>('');
  const [allBrokers, setAllBrokers] = useState<Broker[]>([] as Broker[]);
  const [showRemoveModal, toggleShowRemoveModal] = useState<boolean>(false);
  const { filing } = useContext(FilingContext);

  useEffect(() => {
    fetchBrokers(query).then((response) => {
      if (response) {
        setAllBrokers(response.data);
      }
    });
  }, [query]);

  return (
    <>
      <Field
        name="brokers"
        render={({
          field,
          form,
        }: FieldProps<IFilingCreateValues | FilingDetailsFormValues>) => {
          const renderBroker: ItemRenderer<Broker> = (
            broker,
            { handleClick, modifiers },
          ) => {
            if (!modifiers.matchesPredicate) {
              return null;
            }

            return (
              <MenuItem
                active={modifiers.active}
                icon={isBrokerSelected(broker) ? 'tick' : 'blank'}
                key={broker.id}
                onClick={handleClick}
                text={`${broker.name} - ${broker.slug}`}
                shouldDismissPopover={false}
              />
            );
          };

          const isBrokerSelected = (broker: Broker) => {
            if (
              field.value
                .map((mappedBroker: Broker) => mappedBroker.id)
                .indexOf(broker.id) !== -1
            ) {
              return true;
            }

            if (broker.childBrokers) {
              const matched: number = field.value.reduce(
                (acc: number, fieldValue: any) => {
                  broker.childBrokers!.forEach((childBroker) => {
                    if (childBroker.id === fieldValue.id) {
                      acc++;
                    }
                  });

                  return acc;
                },
                0,
              );

              return Boolean(matched);
            }

            return false;
          };

          const deselectBroker = (broker: Broker) => {
            if (broker.childBrokers && broker.childBrokers.length) {
              const childBrokerIds = broker.childBrokers.map(
                (childBroker) => childBroker.id,
              );

              const filteredBrokers = field.value.filter(
                (b: { id: number }) => !childBrokerIds.includes(b.id),
              );

              console.log(filteredBrokers);

              form.setFieldValue('brokers', filteredBrokers);
            } else {
              const filteredBrokers = field.value.filter(
                (b: { id: number }) => b.id !== broker.id,
              );

              form.setFieldValue('brokers', filteredBrokers);
            }
          };

          const handleBrokerSelect = (broker: Broker) => {
            if (!isBrokerSelected(broker)) {
              selectBroker(broker);
            } else {
              deselectBroker(broker);
            }
          };
          const selectBroker = (broker: Broker) => {
            if (broker.childBrokers && broker.childBrokers.length) {
              const brokersToAdd = [
                ...field.value,
                ...broker.childBrokers.map((childBroker) => ({
                  id: childBroker.id,
                  name: childBroker.name,
                  slug: childBroker.slug,
                })),
              ];
              form.setFieldValue('brokers', brokersToAdd);
            } else {
              const brokersToAdd = [
                ...field.value,
                {
                  id: broker.id,
                  name: broker.name,
                  slug: broker.slug,
                },
              ];
              form.setFieldValue('brokers', brokersToAdd);
            }
          };

          const getTagProps = (): ITagProps => {
            return {
              intent: Intent.NONE,
              minimal: false,
            };
          };

          const handleClear = () => {
            if (
              filing &&
              filing.operationsStatus === OperationsStatus.Approved
            ) {
              toggleShowRemoveModal(true);
              return;
            } else {
              form.setFieldValue('brokers', []);
            }
          };

          const clearButton = (): JSX.Element => {
            return field.value.length > 0 ? (
              <Button icon="cross" minimal={true} onClick={handleClear} />
            ) : (
              <></>
            );
          };

          return (
            <InputGroup>
              <Label htmlFor="brokers">
                Broker(s) <Asterisk />{' '}
                {form.errors.brokers && (
                  <ErrorLabel>{form.errors.brokers}</ErrorLabel>
                )}
              </Label>
              <BrokerMultiSelect
                itemPredicate={filterBroker}
                itemRenderer={renderBroker}
                items={allBrokers}
                noResults={<MenuItem text="No Broker Found" />}
                onItemSelect={(broker: Broker) => {
                  handleBrokerSelect(broker);
                }}
                onQueryChange={(typeQuery: string) => {
                  setQuery(typeQuery);
                }}
                popoverProps={{
                  position: PopoverPosition.BOTTOM,
                }}
                query={query}
                tagRenderer={renderTag}
                tagInputProps={{
                  tagProps: getTagProps(),
                  onRemove: (tag: string) => {
                    const brokerByName = allBrokers.find((b) => b.name === tag);

                    if (brokerByName) {
                      deselectBroker(brokerByName);
                    }
                  },
                  rightElement: clearButton(),
                }}
                selectedItems={field.value.reduce(
                  (selectedBrokers: any, currBroker: any) => {
                    allBrokers.forEach((allBroker) => {
                      if (allBroker.id === currBroker.id) {
                        selectedBrokers.push(allBroker);
                      }

                      if (
                        allBroker.childBrokers &&
                        allBroker.childBrokers.filter(
                          (childBroker) => childBroker.id === currBroker.id,
                        ).length > 0
                      ) {
                        if (!selectedBrokers.includes(allBroker)) {
                          selectedBrokers.push(allBroker);
                        }
                      }
                    });

                    return selectedBrokers;
                  },
                  [] as any,
                )}
              />
            </InputGroup>
          );
        }}
      />
      {
        <BrokerChangesDialog
          text="Cannot remove broker which has been approved."
          buttonText=""
          isOpen={showRemoveModal}
          onRequestClose={() => {
            toggleShowRemoveModal(false);
          }}
          onContinueSave={() => {
            return;
          }}
          hideButtons={true}
        />
      }
    </>
  );
};
