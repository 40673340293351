import React, { FC } from 'react';
import { Modal } from '../../../common/Modal';
import { Dialog } from '../../../common/Dialog/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../data/store';
import { OutlineButton } from '../../../common/Buttons/OutlineButton';
import { Button } from '../../../common/Buttons/Button';
import styled from 'styled-components/macro';
import { Box } from '@rebass/grid';
import { Text } from '../../../common/Text';
import { toggleConfirmSaveIssuerDialog } from '../../../data/funds';

const Buttons = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
`;

interface ConfirmSaveDialogProps {
  onConfirmSave: () => void;
}

export const ConfirmSaveDialog: FC<ConfirmSaveDialogProps> = ({
  onConfirmSave,
}) => {
  const { isConfirmSaveDialogOpen } = useSelector(
    (state: AppState) => state.funds.fundDetails,
  );
  const dispatch = useDispatch();

  const handleKeepEditing = () => dispatch(toggleConfirmSaveIssuerDialog());

  return (
    <Modal
      isOpen={isConfirmSaveDialogOpen}
      onRequestClose={handleKeepEditing}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      top={20}
    >
      <Dialog
        title="Save Issuer"
        isGray={true}
        onRequestClose={handleKeepEditing}
      >
        <Box width={1}>
          <Text as="p" mb={3}>
            Are you sure you want to make these edits?
          </Text>

          <Buttons>
            <OutlineButton
              type="button"
              width={1}
              mr={2}
              onClick={handleKeepEditing}
            >
              Cancel
            </OutlineButton>

            <Button type="button" width={1} ml={2} onClick={onConfirmSave}>
              Confirm
            </Button>
          </Buttons>
        </Box>
      </Dialog>
    </Modal>
  );
};
