import React, { FC, useState, useEffect } from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { MenuItem, PopoverPosition } from '@blueprintjs/core';
import { IFilingCreateValues } from '../../models/filing-form';
import { Asterisk } from '../Asterik/Asterik';
import { privateApi } from '../../utils/api-adapter';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { ErrorLabel } from '../ErrorLabel';
import {
  ISolicitor,
  ITabulator,
  ICompanyContact,
} from '../../models/intermediaries';
import { toast } from 'react-toastify';

const SolicitorSuggest = Suggest.ofType<ISolicitor>();

const renderInputValue = (solicitor: ISolicitor) => {
  return solicitor.name;
};

const renderSolicitor: ItemRenderer<ISolicitor> = (
  solicitor,
  { handleClick, modifiers },
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  const text = `${solicitor.name}`;

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={solicitor.id}
      onClick={handleClick}
      text={text}
    />
  );
};

const fetchSolicitors = async (query?: string) => {
  try {
    const queryString = query ? `?search=${encodeURIComponent(query)}` : '';
    const response = await privateApi.get(`/companies/${queryString}`);
    return response.data.results.filter(
      (result: ISolicitor | ITabulator) => result.type === 'Solicitor',
    );
  } catch (error) {
    toast.error('Not able to get solicitors');
  }
};

const filterSolicitor: ItemPredicate<ISolicitor> = (query, solicitor) => {
  return (
    `${solicitor.name.toLocaleLowerCase()}`.indexOf(query.toLowerCase()) >= 0
  );
};

const handleSelectSolicitor = (
  form: FormikProps<IFilingCreateValues | FilingDetailsFormValues>,
  solicitor: ISolicitor,
) => {
  form.setFieldValue('solicitor', solicitor);

  const replyToContacts = solicitor.companyContacts.filter(
    (companyContact) =>
      companyContact.role === 'solicitor_reply_to' &&
      companyContact.isDefaultForRole,
  );
  form.setFieldValue('replyTo', replyToContacts);

  if (solicitor.defaultTabulator) {
    form.setFieldValue('tabulator', solicitor.defaultTabulator);

    const sendToTabulatorContacts = solicitor.defaultTabulator.companyContacts.filter(
      (companyContact) =>
        companyContact.role === 'tabulator_reply_to' &&
        companyContact.isDefaultForRole,
    );

    form.setFieldValue('sendTo', sendToTabulatorContacts);
  } else {
    form.setFieldValue('tabulator', null);
    form.setFieldValue('sendTo', [] as ICompanyContact[]);
  }
};

export const SolicitorTypeahead: FC<{}> = () => {
  const [query, setQuery] = useState<string>('');
  const [solicitors, setSolicitors] = useState<ISolicitor[]>(
    [] as ISolicitor[],
  );

  useEffect(() => {
    fetchSolicitors(query).then((response) => {
      if (response) {
        setSolicitors(response);
      }
    });
  }, [query]);

  return (
    <Field
      name="solicitor"
      render={({
        field,
        form,
      }: FieldProps<IFilingCreateValues | FilingDetailsFormValues>) => (
        <InputGroup>
          <Label htmlFor="solicitor">
            Solicitor <Asterisk />
            {form.errors.solicitor && (
              <ErrorLabel>{form.errors.solicitor}</ErrorLabel>
            )}
          </Label>
          <SolicitorSuggest
            inputValueRenderer={renderInputValue}
            items={solicitors}
            itemRenderer={renderSolicitor}
            itemPredicate={filterSolicitor}
            noResults={<MenuItem text="No results!" />}
            query={query}
            onQueryChange={(typedQuery: string) => {
              setQuery(typedQuery);
            }}
            popoverProps={{
              position: PopoverPosition.BOTTOM,
            }}
            onItemSelect={(item: ISolicitor) =>
              handleSelectSolicitor(form, item)
            }
            selectedItem={field.value}
          />
        </InputGroup>
      )}
    />
  );
};
