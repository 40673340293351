import React from 'react';

type TableBodyCellProps = {
  children: React.ReactNode;
};

const TableBodyCell = ({ children }: TableBodyCellProps) => (
  <td className="align-middle p-4 border-t border-b border-light-gray first:border-l first:rounded-md first:rounded-r-none last:border-r last:rounded-md last:rounded-l-none text-secondary-text">
    {children}
  </td>
);

export { TableBodyCell };
