import React from 'react';
import { Login } from '../../old/Auth/Login';
import { useCanLogIn } from '../../old/hooks/useCanLogIn';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const canLogIn = useCanLogIn();

  return canLogIn ? <Component {...rest} /> : <Login />;
};

export { PrivateRoute };
