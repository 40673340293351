import React from 'react';
import { FiAlertOctagon } from 'react-icons/fi';

type ErrorCardProps = {
  title: string;
  body: string;
};

const ErrorCard = ({ title, body }: ErrorCardProps) => {
  return (
    <div className="border border-light-gray bg-very-light-gray rounded-md px-8 py-64 flex flex-col items-center">
      <div className="bg-light-red text-secondary-text rounded-full h-16 w-16 flex items-center justify-center">
        <FiAlertOctagon size="2rem" />
      </div>
      <p className="text-primary-text font-medium text-lg mt-2">{title}</p>
      <p className="text-secondary-text text-base leading-normal mt-2">
        {body}
      </p>
    </div>
  );
};

export { ErrorCard };
