import React from 'react';
import DatePicker from 'react-datepicker';

type DateInputProps = {
  name: string;
  selected: Date | null;
  required?: boolean;
  onChange: (date: null | Date) => void;
  showTime?: boolean;
  timeFormat?: string;
  dateFormat?: string;
  placeholder?: string;
};

function DateInput({
  name,
  selected,
  required = false,
  onChange,
  showTime = false,
  timeFormat,
  dateFormat,
  placeholder = '',
}: DateInputProps) {
  return (
    <DatePicker
      name={name}
      selected={selected}
      className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none w-full"
      required={required}
      onChange={onChange}
      showTimeInput={showTime}
      timeFormat={timeFormat}
      dateFormat={dateFormat}
      placeholderText={placeholder}
    />
  );
}

export { DateInput };
