import React from 'react';
import {
  RouteComponentProps,
  useNavigate,
  Link as RouterLink,
  useLocation,
} from '@reach/router';
import { LoadingState } from '../../components/LoadingState';
import { useTasks, OrderingKey } from './useTasks';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  Checkbox,
  Link,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import { Solicitor } from './types';
import { EdgarDateTableCell } from './EdgarDateTableCell';
import { formatDate } from '../../utils/format-date';
import { FilingListFiling } from './types';
import { useTableOrdering } from './useTableOrdering';
import { useTableStyles } from './useTableStyles';
import { CusipTagList } from './CusipTagList';
import { formatCamelCaseString } from '../../utils/format-camel-case';
import { BatchActionButtons } from './BatchActionButtons';
import styled from 'styled-components';
import {
  deleteFilings,
  editFilings,
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from './batch-filings-context';

type Props = RouteComponentProps;

const Ready = (_: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useTableStyles();
  const {
    ready: { data, error, mutate },
    readyQuery: query,
    handleUpdateQuery,
  } = useTasks();
  const batchFilings = useBatchFilingsState();
  const dispatch = useBatchFilingsDispatch();
  const { getIsActive, direction } = useTableOrdering(query.ordering);
  const [
    batchActionButtonsVisible,
    setBatchActionButtonsVisible,
  ] = React.useState(false);

  function handlePageSizeChange(event: React.ChangeEvent<{ value: unknown }>) {
    const pageSize = event.target.value;

    if (typeof pageSize === 'number') {
      handleUpdateQuery('ready', 'pageSize', pageSize);
    }
  }

  function handlePageChange(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) {
    handleUpdateQuery('ready', 'page', page);
  }

  function handleSelectAll(event: React.ChangeEvent<HTMLInputElement>) {
    if (!data) return;

    if (event.target.checked) {
      const allFilings = data.data.results.map((filing) => filing.id);
      dispatch({
        type: 'SELECT_ALL_FILINGS',
        filings: allFilings,
        path: location.pathname,
      });
      return;
    }

    dispatch({ type: 'DESELECT_ALL_FILINGS' });
  }

  function handleSelect(
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) {
    if (event.target.checked) {
      dispatch({ type: 'SELECT_FILING', filing: id, path: location.pathname });
    } else {
      dispatch({ type: 'DESELECT_FILING', filing: id });
    }
  }

  function handleTableRowClick(id: number) {
    navigate(`/proxy/${id}`);
  }

  function handleOrderingClick(orderingKey: OrderingKey) {
    switch (direction) {
      case 'desc':
        return handleUpdateQuery('ready', 'ordering', orderingKey);
      case 'asc':
      default:
        return handleUpdateQuery('ready', 'ordering', `-${orderingKey}`);
    }
  }

  function handleBatchDeleteFilings() {
    deleteFilings(dispatch, batchFilings.filings, mutate);
  }

  function handleBatchEditFilings() {
    editFilings(batchFilings.filings, navigate);
  }

  if (!error && !data)
    return (
      <Box p={4}>
        <LoadingState />
      </Box>
    );

  if (error) return <Box p={4}>Error...</Box>;

  if (data) {
    const TableHeaderContainer = ({ children }: any) => {
      return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box fontSize={16} component="span" fontWeight={500}>
            {data.data.count} Filings
          </Box>

          {children}
        </Box>
      );
    };

    return (
      <Box p={4}>
        <Box
          border={1}
          borderLeft={0}
          borderRight={0}
          borderTop={0}
          borderColor="divider"
        >
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 200]}
            count={data.data.count}
            rowsPerPage={query.pageSize}
            page={query.page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePageSizeChange}
            component={TableHeaderContainer}
            labelRowsPerPage="Show"
          />
        </Box>
        <Table className={classes.table}>
          <TableHead
            onMouseEnter={() => setBatchActionButtonsVisible(true)}
            onMouseLeave={() => setBatchActionButtonsVisible(false)}
          >
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={handleSelectAll}
                  checked={
                    batchFilings.filings.length > 0 &&
                    batchFilings.filings.length === data.data.results.length
                  }
                  indeterminate={
                    batchFilings.filings.length > 0 &&
                    batchFilings.filings.length < data.data.results.length
                  }
                />
              </TableCell>
              <RelativeTableCell
                className={classes.tableHeadCell}
                style={{ width: '300px' }}
              >
                <BatchActionButtons
                  isVisible={
                    batchActionButtonsVisible &&
                    Boolean(batchFilings.filings.length)
                  }
                  onDelete={handleBatchDeleteFilings}
                  onEdit={handleBatchEditFilings}
                />
                <ReverseTableSortLabel
                  active={getIsActive('issuer__company_name')}
                  direction={direction}
                  onClick={() => handleOrderingClick('issuer__company_name')}
                >
                  Issuer
                </ReverseTableSortLabel>
              </RelativeTableCell>
              <TableCell className={classes.tableHeadCell}>CUSIP</TableCell>
              <TableCell className={classes.tableHeadCell}>
                <ReverseTableSortLabel
                  active={getIsActive('polymorphic_ctype')}
                  direction={direction}
                  onClick={() => handleOrderingClick('polymorphic_ctype')}
                >
                  Event
                </ReverseTableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <ReverseTableSortLabel
                  active={getIsActive('latest_edgar_filing_date')}
                  direction={direction}
                  onClick={() =>
                    handleOrderingClick('latest_edgar_filing_date')
                  }
                >
                  EDGAR
                </ReverseTableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <ReverseTableSortLabel
                  active={getIsActive('first_record_date')}
                  direction={direction}
                  onClick={() => handleOrderingClick('first_record_date')}
                >
                  Record
                </ReverseTableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <ReverseTableSortLabel
                  active={getIsActive('digital_materials_expected_date')}
                  direction={direction}
                  onClick={() =>
                    handleOrderingClick('digital_materials_expected_date')
                  }
                >
                  Expected
                </ReverseTableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>Solicitor</TableCell>
              <TableCell className={classes.tableHeadCell}>
                <ReverseTableSortLabel
                  active={getIsActive('meeting_date')}
                  direction={direction}
                  onClick={() => handleOrderingClick('meeting_date')}
                >
                  Meeting
                </ReverseTableSortLabel>
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                style={{ maxWidth: '30px' }}
              >
                Materials
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data.results.map((filing) => (
              <TableRow
                key={filing.id}
                selected={batchFilings.filings.includes(filing.id)}
                onClick={() => handleTableRowClick(filing.id)}
                hover={true}
                className={classes.tableBodyRow}
              >
                <TableCell className={classes.tableBodyCellCheckbox}>
                  <Checkbox
                    onChange={(event) => handleSelect(event, filing.id)}
                    checked={batchFilings.filings.includes(filing.id)}
                    onClick={(event) => event.stopPropagation()}
                  />
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  <Tooltip arrow title={filing.issuer.companyName}>
                    <Link
                      to={`/issuers/${filing.issuer.globalIssuerId}`}
                      component={RouterLink}
                    >
                      {filing.issuer.companyName}
                    </Link>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  <CusipTagList
                    cusips={filing.securities.map((security) => security.cusip)}
                  />
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {formatCamelCaseString(filing.type)}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {filing.edgarFilings && filing.edgarFilings[0] && (
                    <EdgarDateTableCell
                      edgarFilings={filing.edgarFilings}
                      issuer={filing.issuer}
                    />
                  )}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {filing.recordDate
                    ? formatDate(new Date(filing.recordDate))
                    : ''}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {filing.stats &&
                  filing.stats.estimatedDigitalMaterialsExpectedDate
                    ? formatDate(
                        new Date(
                          filing.stats.estimatedDigitalMaterialsExpectedDate,
                        ),
                      )
                    : ''}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {filing.solicitor ? getSolicitor(filing.solicitor) : ''}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {getMeetingDate(filing)}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {getAreMaterialsFiled(filing)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  }

  return null;
};

const getSolicitor = (solicitor: Solicitor) => {
  if (solicitor.name && solicitor.name.includes('Broadridge')) {
    return (
      <Link
        href="http://myservice.broadridge.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Broadridge
      </Link>
    );
  }
  return (
    <React.Fragment>
      <Link
        href="https://app.frontapp.com/inboxes/teams/folders/780739"
        target="_blank"
        rel="noopener noreferrer"
      >
        {solicitor.name}
      </Link>
    </React.Fragment>
  );
};

const getMeetingDate = (filing: FilingListFiling) => {
  switch (filing.type) {
    case 'MeetingContest':
    case 'FundMeeting':
    case 'FirmMeeting':
      return filing.meetingDate ? formatDate(new Date(filing.meetingDate)) : '';
    default:
      return 'N/A';
  }
};

const getAreMaterialsFiled = (filing: FilingListFiling): 'Yes' | 'No' => {
  if (filing.referenceMaterials.length) {
    return 'Yes';
  }

  return 'No';
};

const RelativeTableCell = styled(TableCell)`
  position: relative;
`;

const ReverseTableSortLabel = styled(TableSortLabel)`
  flex-direction: row-reverse;
  margin-left: -26px;
`;

export { Ready };
