import styled from 'styled-components/macro';

export const Tabs = styled.div<{ columns?: number }>`
  background-color: #f2f2f2;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  border-left: 1px solid ${({ theme }) => theme.colors.background};
  border-right: 1px solid ${({ theme }) => theme.colors.background};
  display: grid;
  grid-template-columns: repeat(
    ${({ columns }) => (columns ? columns : '4')},
    1fr
  );
`;
