import React from 'react';
import styled from 'styled-components';

interface Props {
  logo: string;
  name: string;
}

function BrokerLogo({ logo, name }: Props) {
  return (
    <Wrapper>
      <img src={logo} alt={name} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { BrokerLogo };
