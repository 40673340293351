import React, { FC } from 'react';
import { decamelize } from 'humps';
import styled from 'styled-components/macro';

import { FilingType } from '../../models/filing-type';
import { Select } from '../Select';

const StyledSelect = styled(Select)`
  text-transform: capitalize;
`;

interface IFilingTypeSearchProps {
  filingType: FilingType | '';
  filterOutCorporateAction?: boolean;
  onChange: (filingType: FilingType | '') => void;
}

export const FilingTypeSearch: FC<IFilingTypeSearchProps> = ({
  filingType,
  filterOutCorporateAction,
  onChange,
}) => {
  const FilingTypesArray = (): FilingType[] => {
    if (filterOutCorporateAction) {
      return Object.keys(FilingType)
        .filter((filingType) => filingType !== FilingType.CorporateAction)
        .map((filingType: string) => FilingType[filingType as FilingType]);
    }
    return Object.keys(FilingType).map(
      (filingType: string) => FilingType[filingType as FilingType],
    );
  };

  return (
    <StyledSelect
      value={filingType}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        e.target.value ? onChange(e.target.value as FilingType) : onChange('')
      }
    >
      <option value={''}>Filter by Filing Type</option>
      {FilingTypesArray().map((filingType: FilingType, i: number) => {
        return (
          <option value={filingType} key={`${filingType}-${i}`}>
            {decamelize(filingType, { separator: ' ' })}
          </option>
        );
      })}
    </StyledSelect>
  );
};
