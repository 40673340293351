import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Security } from '../Prospectus';
import { SecurityRow } from '../Prospectus/SecurityRow';

type ArchivedProspectusListProps = {
  securities: Security[];
};

const ArchivedProspectusList = ({
  securities,
}: ArchivedProspectusListProps & RouteComponentProps) => {
  return (
    <div>
      {securities
        .filter((security) => security.prospectuses.archived.length > 0)
        .map((security) => (
          <SecurityRow
            key={security.id}
            security={security}
            status="archived"
            isEditable={false}
            showCheckmark={false}
          />
        ))}
    </div>
  );
};

export { ArchivedProspectusList };
