import React from 'react';
import { Field, FieldProps } from 'formik';
import { GenericDate } from '../../common/FormComponents/GenericDate';
import { FilingDetailsFormValues } from '../Filing';
import { InputGroup } from '../../common/InputGroup';
import { Label } from '../../common/Label';
import { Input } from '../../common/Input';
import { ErrorLabel } from '../../common/ErrorLabel';

export const CorporateActionsFields = () => {
  return (
    <>
      <Field
        name="notificationReceivedDate"
        render={({ field, form }: FieldProps<FilingDetailsFormValues>) => (
          <GenericDate
            field={field}
            form={form}
            label="Notification Received Date"
            fieldName={field.name}
          />
        )}
      />

      <Field
        name="electionCutoffDate"
        render={({ field, form }: FieldProps<FilingDetailsFormValues>) => (
          <GenericDate
            field={field}
            form={form}
            label="Election Cutoff Date"
            fieldName={field.name}
          />
        )}
      />

      <Field
        name="electionCutoffTime"
        render={({ field, form }: FieldProps<FilingDetailsFormValues>) => (
          <InputGroup>
            <Label htmlFor="election-cutoff-time">
              Election Cutoff Time{' '}
              {form.errors['electionCutoffTime'] && (
                <ErrorLabel>{form.errors['electionCutoffTime']}</ErrorLabel>
              )}
            </Label>
            <Input {...field} id="election-cutoff-time" type="time" />
          </InputGroup>
        )}
      />

      <Field
        name="expirationDate"
        render={({ field, form }: FieldProps<FilingDetailsFormValues>) => (
          <GenericDate
            field={field}
            form={form}
            label="Expiration Date"
            fieldName={field.name}
          />
        )}
      />

      <Field
        name="dtcExpirationDate"
        render={({ field, form }: FieldProps<FilingDetailsFormValues>) => (
          <GenericDate
            field={field}
            form={form}
            label="DTC Expiration Date"
            fieldName={field.name}
          />
        )}
      />
    </>
  );
};
