import React, { FC } from 'react';
import { Flex } from '@rebass/grid';
import styled from 'styled-components/macro';

interface TagListProps {
  values: string[];
}

const Wrapper = styled(Flex)`
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  min-height: 30px;
  flex-wrap: wrap;
`;

const Tag = styled(Flex)`
  align-items: center;
  background: #595959;
  border-radius: 3px;
  height: 20px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 10px;
`;

const TagList: FC<TagListProps> = ({ values }) => {
  const tags = values.map((value) => <Tag key={value}>{value}</Tag>);

  return <Wrapper>{tags}</Wrapper>;
};

export { TagList };
