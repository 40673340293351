import {
  OperationsStatus,
  FilingDetails,
} from '../../../models/filing-details';

const findLatestAction = (
  status: OperationsStatus,
  filing: FilingDetails | null,
) => {
  return (
    filing && filing.actionHistory.find((action) => action.action === status)
  );
};

export { findLatestAction };
