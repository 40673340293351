import React, { FC, useState, useContext } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Formik, FormikProps, FormikActions } from 'formik';
import axios from 'axios';
import { decamelize } from 'humps';
import * as Yup from 'yup';
import { useContainer } from 'unstated-next';

import { ProposalType } from '../../common/VoteFormComponents/ProposalType';
import { Routine } from '../../common/VoteFormComponents/RoutineType';
import { ProposalDetails } from '../../common/VoteFormComponents/ProposalDetails';
import { ProposalDirectors } from '../../common/VoteFormComponents/ProposalDirectors';
import { RecommendationType } from '../../common/VoteFormComponents/Recommendation';
import { CusipTypeaheadWithoutApi } from '../../common/VoteFormComponents/CusipTypeaheadWithoutApi';
import { ProposalTitle } from '../../common/VoteFormComponents/ProposalTitle';
import styled from 'styled-components/macro';
import { Button } from '../../common/Buttons/Button';
import { OutlineButton } from '../../common/Buttons/OutlineButton';
import {
  IVoteProposalFormValues,
  VoteType,
  ProposalTypes,
  RoutineTypes,
  RecommendationTypes,
  ICusip,
  IVoteProposalPayload,
  IVoteProposalFormDirector,
  IVoteProposalFormDetail,
} from '../../models/vote-proposal';
import { VoteType as VoteTypeFormElement } from '../../common/VoteFormComponents/VoteType';
import { privateApi } from '../../utils/api-adapter';
import { ErrorModal } from './ErrorModal';
import { FilingContext } from '../../data/Filing.Context';
import { EditTypes } from './VoteContainer';
import { VoteProposals } from '../../data/VoteProposal';

interface IProps {
  currentGroupNumber: number;
  resetVoteProposalsState: (type: EditTypes) => void;
  initialValues: IVoteProposalFormValues | null;
  isEditing: boolean;
  handleCancel: () => void;
}

const SubmitContainer = styled(Flex)`
  border-top: 1px solid #d8d8d8;
`;

export const ProxyVoteForm: FC<IProps> = ({
  currentGroupNumber,
  resetVoteProposalsState,
  initialValues,
  isEditing,
  handleCancel,
}) => {
  const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([] as string[]);

  const { filing } = useContext(FilingContext);
  const { addProposalGroup, updateProposalGroup } = useContainer(VoteProposals);

  const cusips: ICusip[] = filing
    ? filing.securities.map((security) => {
        return {
          id: security.id,
          cusip: security.cusip,
          name: security.name,
        };
      })
    : ([] as ICusip[]);

  const determineVoteType = (type: string) => {
    if (type === 'For, Against, Abstain') {
      return 'election_majority';
    } else if (type === 'For, Withhold') {
      return 'election_plurality';
    } else if (type === 'Yes, No') {
      return 'election_majority_yesno';
    } else if (type === 'For, Against') {
      return 'election_majority_foragainst';
    } else if (type === 'For, Withhold, Abstain') {
      return 'election_majority_forwithholdabstain';
    } else if (type === 'For, Against, Withhold') {
      return 'election_majority_foragainstwithhold';
    } else if (type === 'For, Abstain') {
      return 'election_majority_forabstain';
    } else {
      return '';
    }
  };

  const genericPayload = (values: IVoteProposalFormValues, index: number) => {
    const voteChoices: string[] = values.voteType
      .split(', ')
      .map((voteChoice: string) => voteChoice);
    voteChoices.push('none');

    const payload = {
      filing: filing ? filing.id : NaN,
      groupNumber: currentGroupNumber,
      isRoutine: values.routine === 'Routine',
      proposalNumber: index + 1,
      recommendationType: values.recommendation
        .toLowerCase()
        .split(' ')
        .join('_'),
      securities: values.securities.map((security: ICusip) => security.id),
      title: values.proposalTitle,
      type: values.proposalType,
      voteChoices,
      voteType: determineVoteType(values.voteType),
    };
    if (payload.type === 'SayOnPay') {
      const { voteType, ...theRest } = payload;
      return theRest;
    } else {
      return payload;
    }
  };

  return (
    <Flex width={1} px={25} pt={20} pb={45}>
      <Formik
        initialValues={
          initialValues
            ? { ...initialValues }
            : {
                details: [{ id: null, detail: '' }],
                directors: [{ id: null, director: '' }],
                routine: 'Non-Routine' as RoutineTypes,
                recommendation: 'For' as RecommendationTypes,
                securities: cusips,
                voteType: 'For, Withhold' as VoteType,
                proposalTitle: 'Election of Directors',
                proposalType: 'BoardOfDirectorsNomination' as ProposalTypes,
              }
        }
        onSubmit={(
          values: IVoteProposalFormValues,
          formikActions: FormikActions<IVoteProposalFormValues>,
        ) => {
          let payloads: IVoteProposalPayload[] = [] as IVoteProposalPayload[];
          if (
            values.proposalType === 'BoardOfDirectorsNomination' &&
            values.directors
          ) {
            payloads = values.directors.map(
              (director: IVoteProposalFormDirector, index: number) => {
                return {
                  directorName: director.director,
                  id: director.id,
                  ...genericPayload(values, index),
                };
              },
            );
          } else if (
            values.proposalType !== 'BoardOfDirectorsNomination' &&
            values.details
          ) {
            payloads = values.details.map(
              (detail: IVoteProposalFormDetail, index: number) => {
                return {
                  details: detail.detail,
                  id: detail.id,
                  ...genericPayload(values, index),
                };
              },
            );
          }

          const apiCalls = payloads.map((payload: IVoteProposalPayload) => {
            const { id, ...theRest } = payload;
            return id
              ? privateApi.patch(`/vote-proposals/${id}/`, theRest)
              : privateApi.post(`/vote-proposals/`, theRest);
          });

          axios
            .all(apiCalls)
            .then((responses) => {
              formikActions.resetForm();
              if (isEditing) {
                resetVoteProposalsState(EditTypes.edit);
                updateProposalGroup(responses.map((response) => response.data));
              } else {
                resetVoteProposalsState(EditTypes.create);
                addProposalGroup(responses.map((response) => response.data));
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 404) {
                const errors = [
                  'There was 404 status on this request. Make sure the endpoint you are hitting exists!',
                ];
                setErrorMessages(errors);
                setShowErrorScreen(true);
              } else if (error.response.status === 500) {
                const errors = [
                  'Yikes! 500 error. This means a rare exception came up. Please check Sentry or contact engineering.',
                ];
                setErrorMessages(errors);
                setShowErrorScreen(true);
              } else {
                const errors: string[] = Object.keys(error.response.data).map(
                  (key: string) => {
                    const reducedError: string = error.response.data[
                      key
                    ].reduce((acc: string, cv: string) => {
                      return `${acc} ${cv}`;
                    }, '');
                    return `${decamelize(key, {
                      separator: ' ',
                    })}: ${reducedError}`;
                  },
                );
                setErrorMessages(errors);
                setShowErrorScreen(true);
              }
            });
        }}
        validationSchema={(formikBag: FormikProps<IVoteProposalFormValues>) => {
          return Yup.object().shape({
            proposalType: Yup.string().required(),
            securities: Yup.array().required(
              'You must include at least one cusip',
            ),
            directors: Yup.array().when('proposalType', {
              is: 'BoardOfDirectorsNomination',
              then: Yup.array().of(
                Yup.object().shape({
                  id: Yup.mixed(),
                  director: Yup.string().required('Directors cannot be blank'),
                }),
              ),
            }),
            details: Yup.array().when('proposalType', {
              is: (val) => val !== 'BoardOfDirectorsNomination',
              then: Yup.array().of(
                Yup.object().shape({
                  id: Yup.mixed(),
                  detail: Yup.string().required(
                    'Proposal details cannot be blank',
                  ),
                }),
              ),
            }),
          });
        }}
        render={(props: FormikProps<IVoteProposalFormValues>) => {
          return (
            <Flex width={1} flexDirection="column">
              <Flex flexDirection="column" width={4 / 5}>
                <Flex flexDirection="row" justifyContent="space-between">
                  <Box width={1 / 3}>
                    <ProposalType disabled={isEditing} />
                  </Box>
                  <Box width={1 / 3}>
                    <Routine />
                  </Box>
                </Flex>
                <Flex flexDirection="row" justifyContent="space-between">
                  <Box width={1 / 3}>
                    <VoteTypeFormElement />
                  </Box>
                  <Box width={1 / 3}>
                    <RecommendationType />
                  </Box>
                </Flex>

                <Flex flexDirection="row" justifyContent="space-between">
                  <Box width={1 / 2}>
                    <ProposalTitle />
                  </Box>
                  <Box width={1 / 3}>
                    <CusipTypeaheadWithoutApi cusips={cusips} />
                  </Box>
                </Flex>

                {props.values.proposalType === 'BoardOfDirectorsNomination' ? (
                  <ProposalDirectors directors={props.values.directors} />
                ) : (
                  <ProposalDetails details={props.values.details} />
                )}
              </Flex>
              <SubmitContainer
                mt={45}
                width={1}
                pt={25}
                justifyContent="flex-end"
              >
                <OutlineButton
                  small={true}
                  width={130}
                  type="button"
                  mr={15}
                  onClick={(e) => {
                    e.preventDefault();
                    props.resetForm();
                    handleCancel();
                  }}
                >
                  Cancel
                </OutlineButton>
                <Button
                  small={true}
                  width={130}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    props.submitForm();
                  }}
                >
                  Save Proposal
                </Button>
              </SubmitContainer>
            </Flex>
          );
        }}
      />
      <ErrorModal
        isOpen={showErrorScreen}
        onClose={() => setShowErrorScreen(false)}
        errorMessages={errorMessages}
      />
    </Flex>
  );
};
