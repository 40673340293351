import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    rootDialog: {
      position: 'absolute',
      overflow: 'hidden',
    },
    backdrop: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,.8)',
      width: '100vw',
      height: '100vh',
    },
    dialogContainer: {
      maxHeight: '90vh',
      overflow: 'hidden',
    },
    dialogContent: {
      '& > *': {
        margin: '12px 0',
      },
    },
  }),
);

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onRequestClose: () => void;
  existingFilingId: number;
};

export const DuplicateConfirmationDialog = ({
  isOpen,
  onConfirm,
  onRequestClose,
  existingFilingId,
}: Props) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={onRequestClose}
        classes={{
          root: classes.rootDialog,
          container: classes.dialogContainer,
        }}
        BackdropProps={{
          classes: { root: classes.backdrop },
        }}
        style={{ position: 'absolute' }}
        className={classes.rootDialog}
      >
        <DialogTitle>Duplicate Filing</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <p>
            A filing with the same filing type, issuer, and record date already
            exists. Would you like to add this filing anyway?
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/old/filings/${existingFilingId}`}
            >
              Filing #{existingFilingId}
            </a>
          </p>
        </DialogContent>

        <DialogActions>
          <Button onClick={onRequestClose} type="button">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              onConfirm();
            }}
          >
            Add Anyway
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
