import { ProposalTypes } from '../models/vote-proposal';

const formatVoteProposalType = (proposalType: ProposalTypes) => {
  switch (proposalType) {
    case ProposalTypes.BoardOfDirectorsNomination:
      return 'Election of Directors';
    case ProposalTypes.RatificationOfAuditors:
      return 'Ratification of Auditors';
    case ProposalTypes.ExecutiveCompensationProposalItem:
      return 'Executive Compensation';
    case ProposalTypes.SayOnPay:
      return 'Say on Pay';
    case ProposalTypes.ManagementProposalItem:
      return 'Management Proposal';
    case ProposalTypes.ShareholderProposalItem:
      return 'Shareholder Proposal';
    case ProposalTypes.PlanOfMergerProposalItem:
      return 'Plan of Merger';
    case ProposalTypes.AdjournmentProposalItem:
      return 'Adjournment of Meeting';
    case ProposalTypes.Other:
      return 'Other Proposal';
    default:
      return null;
  }
};

export { formatVoteProposalType };
