export const theme = {
  colors: {
    blue: '#1954b5',
    green: '#11CC99',
    lightGreen: '#6BE0BA',
    gold: '#ECCE97',
    lightCream: '#FCF4EA',
    red: '#D33F33',
    yellow: '#eed235',
    lightRed: '#E2685D',
    lighterRed: '#EB9A92',
    orange: '#EE8B35',
    white: '#FFFFFF',
    black: '#1A1A19',
    background: '#f2f2f2',
    gray: '#b2b2b2',
    darkGray: '#d9d9d9',
    darkGrayText: '#737373',
    lightGrayText: '#979797',
    divider: '#313130',
    border: '#5F5F5F',
    lightBorder: '#B2B2B2',
    lightDivider: '#D9D9D9',
  },
  spacing: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [10, 12, 14, 16, 20, 24, 36, 48, 80, 96],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  radii: [0, 2, 4, 16, 9999, '100%'],
  lineHeights: { solid: 1, title: 1.25, body: 1.5 },
  buttons: {
    primary: { color: '#000', backgroundColor: '#11cc99' },
    secondary: { color: '#000', backgroundColor: '#2F3032' },
  },
};

export type Theme = typeof theme;
