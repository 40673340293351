import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { hubApi } from '../../old/data/hubApiConfig';
import useSWR from 'swr';
import { PageWrapper } from '../../components/PageWrapper';
import { LoadingState } from '../../components/LoadingState';
import { Header } from './Header';
import { Details } from './Details';
import { Activity } from './Activity';
import { Invoicing } from './Invoicing';
import { Prospectus } from './Prospectus';
import { ErrorCard } from '../../components/ErrorCard';
import { GrayTabs } from '../../components/GrayTabs/GrayTabs';

type Response = {
  id: string;
  name: string;
  issuerType: null | IssuerType;
  cik: null | string;
  fundOwner: null | string;
  securities: Security[];
};

export type Security = {
  id: string;
  name: string;
  securityType: null | SecurityType;
  cusip: string;
  ticker: null | string;
};

export type IssuerType = 'corporation' | 'investment_trust' | 'government';
export type SecurityType = 'etf' | 'fund' | 'adr' | 'etn' | 'bond' | 'stock';

const issuerFetcher = (url: string) => hubApi.get<Response>(url);

function Issuer({ id }: RouteComponentProps<{ id: string }>) {
  const { data, error } = useSWR(`/admin/issuers/${id}/`, issuerFetcher);

  if (!error && !data)
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );

  if (error)
    return (
      <PageWrapper>
        <ErrorCard
          title="Unable to load this issuer."
          body="Something went wrong when attempting to load this issuer. Please contact support for help with this issue."
        />
      </PageWrapper>
    );

  if (data) {
    const getTabs = () => {
      if (data.data.issuerType === 'corporation') {
        return [
          { label: 'Issuer Details', path: '' },
          { label: 'Activity', path: 'activity' },
          { label: 'Invoicing', path: 'invoicing' },
        ];
      } else {
        return [
          { label: 'Issuer Details', path: '' },
          { label: 'Prospectus Documents', path: 'prospectus' },
          { label: 'Activity', path: 'activity' },
          { label: 'Invoicing', path: 'invoicing' },
        ];
      }
    };

    return (
      <div id="issuer-body">
        <Header issuerName={data.data.name} />
        <GrayTabs tabs={getTabs()} />
        <Router>
          <Details path="/" {...data.data} />
          <Prospectus path="prospectus/*" />
          <Activity path="activity" />
          <Invoicing path="invoicing" />
        </Router>
      </div>
    );
  }

  return null;
}

export { Issuer };
