import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

const StyledLi = styled(Flex)`
  width: 100%;
  list-style-type: none;
  padding: 0;
  min-height: 50px;
  height: auto;
  cursor: pointer;
`;

const SelectedContainer = styled(Flex)`
  width: 1%;
`;

const Selected = styled(Box)<{ selected: boolean }>`
  flex: 1;
  width: 100%;
  background-color: ${({ selected }) => (selected ? '#11cc99' : 'tranparent')};
`;

const ChildrenContainer = styled(Flex)`
  margin-left: 10px;
  width: 99%;
`;

interface IUnorderedList {
  children: React.ReactNode;
  selected: boolean;
  onClick?: () => void;
}

export const ListItem: React.FC<IUnorderedList> = ({
  children,
  selected,
  onClick,
}) => {
  return (
    <StyledLi onClick={onClick} flexDirection="row" width={1}>
      <SelectedContainer flexDirection="column">
        <Selected selected={selected} mr={11} />
      </SelectedContainer>
      <ChildrenContainer flexDirection="row" alignItems="center">
        <Box flex={1}>{children}</Box>
      </ChildrenContainer>
    </StyledLi>
  );
};
