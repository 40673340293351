import styled from 'styled-components/macro';
import { space, SpaceProps } from 'styled-system';

export const Select = styled.select<SpaceProps>`
  ${space}
  border: 2px solid ${({ theme }) => theme.colors.lightDivider};
  border-radius: ${({ theme }) => theme.radii[2]}px;
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  height: 30px;
  background: #ffffff;
  padding: 0 10px;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.divider};
    outline: none;
  }

  &:invalid {
    color: ${({ theme }) => theme.colors.gray};
  }
`;
