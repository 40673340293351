import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { GenericDate } from './GenericDate';
import { IFilingCreateValues } from '../../models/filing-form';

export const VoteCutoffDate: FC<{}> = () => {
  return (
    <Field
      name="voteCutoffDate"
      render={({ field, form }: FieldProps<IFilingCreateValues>) => (
        <GenericDate
          field={field}
          form={form}
          label="Vote Cutoff Date"
          fieldName={'voteCutoffDate'}
          isRequired={false}
        />
      )}
    />
  );
};
