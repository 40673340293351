import React, { FC, useState, useEffect } from 'react';
import { Field, FieldProps } from 'formik';
import { Select, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { MenuItem, Button } from '@blueprintjs/core';
import { IFilingCreateValues, RelatedFiling } from '../../models/filing-form';
import { Asterisk } from '../Asterik/Asterik';
import { privateApi } from '../../utils/api-adapter';
import { Filing } from '../../models/filing';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { toast } from 'react-toastify';

interface IProps {
  issuerId: number;
}

const FilingSelect = Select.ofType<Filing>();

const renderFiling: ItemRenderer<Filing> = (
  filing,
  { handleClick, modifiers },
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      key={filing.id}
      text={`${filing.id} - ${filing.issuer.companyName} - ${
        filing.meetingDate
          ? filing.meetingDate
          : filing.recordDate
          ? filing.recordDate
          : ''
      }`}
      onClick={handleClick}
      shouldDismissPopover={false}
    />
  );
};

const fetchFilings = async (issuerId: number, query?: string) => {
  try {
    const response = await privateApi.get(
      `/filings/?filing_type=firmmeeting&filing_type=fundmeeting&status__not=closed&issuer_id=${issuerId}`,
    );
    return response;
  } catch (error) {
    toast.error('Not able to get filings');
  }
};

const filterFiling: ItemPredicate<Filing> = (query, filing) => {
  return (
    `${filing.issuer.companyName.toLocaleLowerCase()}`.indexOf(
      query.toLowerCase(),
    ) >= 0
  );
};

export const FilingTypeahead: FC<IProps> = ({ issuerId }) => {
  const [filingsFromApi, setFilingsFromApi] = useState<Filing[]>(
    [] as Filing[],
  );

  useEffect(() => {
    fetchFilings(issuerId).then((response) => {
      if (response) {
        setFilingsFromApi(response.data.results);
      }
    });
  }, [issuerId]);

  return (
    <Field
      name="relatedFiling"
      render={({ field, form }: FieldProps<IFilingCreateValues>) => {
        return (
          <InputGroup>
            <Label htmlFor="relatedFiling">
              Related Filing <Asterisk />
            </Label>
            <FilingSelect
              itemPredicate={filterFiling}
              items={filingsFromApi}
              itemRenderer={renderFiling}
              noResults={<MenuItem disabled={true} text="No results." />}
              onItemSelect={(item: Filing) => {
                const relatedFiling: RelatedFiling = {
                  deliveryMethod: item.deliveryMethod,
                  meetingDate: item.meetingDate ? item.meetingDate : null,
                  solicitorCompany: item.solicitorCompany,
                  issuer: item.issuer,
                  recordDate: item.recordDate,
                };
                form.setFieldValue('cusips', item.securities);
                form.setFieldValue('issuerFiling', item.id);
                form.setFieldValue('relatedFiling', relatedFiling);
              }}
            >
              <Button
                text={
                  field.value
                    ? `${field.value.issuer.companyName} - ${
                        field.value.meetingDate
                          ? field.value.meetingDate
                          : field.value.recordDate
                          ? field.value.recordDate
                          : ''
                      }`
                    : 'Select a related filing'
                }
                rightIcon="double-caret-vertical"
              />
            </FilingSelect>
          </InputGroup>
        );
      }}
    />
  );
};
