import React from 'react';
import { Link, LinkGetProps } from '@reach/router';

type GrayTabsProps = {
  tabs: { label: string; path: string; isDisabled?: boolean }[];
};

function GrayTabs({ tabs }: GrayTabsProps) {
  return (
    <div className="px-8 py-4 bg-very-light-gray border-t border-b border-light-gray">
      {tabs.map((tab) => (
        <Tab key={tab.path} to={tab.path}>
          {tab.label}
        </Tab>
      ))}
    </div>
  );
}

type TabProps = {
  to: string;
  children: React.ReactNode;
};

function Tab({ to, children }: TabProps) {
  const isActive = ({ isCurrent }: LinkGetProps) => {
    return isCurrent
      ? {
          className:
            'font-medium text-primary-text mr-8 last:mr-0 hover:no-underline hover:text-green border-b-2 border-green py-4',
        }
      : {
          className:
            'text-primary-text mr-8 last:mr-0 hover:no-underline hover:text-green border-b-2 border-transparent py-4',
        };
  };

  return (
    <Link to={to} getProps={isActive}>
      {children}
    </Link>
  );
}

export { GrayTabs };
