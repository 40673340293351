import React from 'react';

type TableBodyRowProps = {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
};

const TableBodyRow = ({ children, onClick }: TableBodyRowProps) => (
  <tr
    className={`hover:shadow-lg transition duration-150 ease-in-out rounded-md text-sm cursor-pointer`}
    onClick={onClick}
  >
    {children}
  </tr>
);

export { TableBodyRow };
