import React, { FC, useState } from 'react';
import { MultiSelect, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import {
  MenuItem,
  ITagProps,
  Intent,
  Button,
  PopoverPosition,
} from '@blueprintjs/core';

interface Security {
  id: string;
  cusip: string;
}

interface SecuirtyTypeaheadProps {
  items: Security[];
  onAddSecurity: (query: string) => void;
  onSelectSecurities: (securities: Security[]) => void;
  selectedItems: Security[];
}

const SecurityMultiSelect = MultiSelect.ofType<Security>();

const renderTag = (security: Security) => {
  return security.cusip;
};

const filterSecurity: ItemPredicate<Security> = (query, security) => {
  return (
    `${security.cusip.toLocaleLowerCase()}`.indexOf(query.toLowerCase()) >= 0
  );
};

export const SecurityTypeahead: FC<SecuirtyTypeaheadProps> = ({
  items,
  onAddSecurity,
  onSelectSecurities,
  selectedItems,
}) => {
  const [query, setQuery] = useState<string>('');

  const renderSeccurity: ItemRenderer<Security> = (
    security,
    { handleClick, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        icon={isSecuritySelected(security) ? 'tick' : 'blank'}
        key={security.id}
        onClick={handleClick}
        text={security.cusip}
        shouldDismissPopover={false}
      />
    );
  };

  const isSecuritySelected = (security: Security) => {
    return getSelectedSecurityIndex(security) !== -1;
  };

  const getSelectedSecurityIndex = (security: Security) => {
    return selectedItems
      .map((mappedSecurity: Security) => mappedSecurity.id)
      .indexOf(security.id);
  };

  const deselectSecurity = (index: number) => {
    const filteredSecurities = selectedItems.filter(
      (security: Security, i: number) => i !== index,
    );
    onSelectSecurities(filteredSecurities);
  };

  const handleSecuritySelect = (security: Security) => {
    if (!isSecuritySelected(security)) {
      selectSecurity(security);
    } else {
      deselectSecurity(getSelectedSecurityIndex(security));
    }
  };
  const selectSecurity = (security: Security) => {
    const securitiesToAdd = [...selectedItems, security];
    onSelectSecurities(securitiesToAdd);
  };

  const getTagProps = (): ITagProps => {
    return {
      intent: Intent.NONE,
      minimal: false,
    };
  };

  const handleTagRemove = (tag: string, index: number) => {
    deselectSecurity(index);
  };

  const handleClear = () => {
    onSelectSecurities([]);
  };

  const clearButton = (): JSX.Element => {
    return selectedItems.length > 0 ? (
      <Button icon="cross" minimal={true} onClick={handleClear} />
    ) : (
      <></>
    );
  };

  return (
    <SecurityMultiSelect
      itemPredicate={filterSecurity}
      itemRenderer={renderSeccurity}
      items={items}
      noResults={
        <MenuItem
          icon="plus"
          disabled={query.length !== 9}
          onClick={() => {
            setQuery('');
            onAddSecurity(query);
          }}
          text="Add New Secuirty"
        />
      }
      onItemSelect={(security: Security) => {
        handleSecuritySelect(security);
      }}
      onQueryChange={(typeQuery: string) => {
        setQuery(typeQuery);
      }}
      popoverProps={{
        position: PopoverPosition.BOTTOM,
      }}
      query={query}
      tagRenderer={renderTag}
      tagInputProps={{
        tagProps: getTagProps(),
        onRemove: (tag: string, index: number) => {
          handleTagRemove(tag, index);
        },
        rightElement: clearButton(),
      }}
      selectedItems={selectedItems}
    />
  );
};
