export enum CorporateActionType {
  BIDS = 'Repurchase Offer',
  DRIP = 'Dividend Reinvestment',
  DIVO = 'Dividend Option',
  CONE = 'Consideration Election',
  TEND = 'Tender Offer',
  EXOF = 'Exchange Offer',
  ODLT = 'Odd Lot Sale/Purchase',
  RHTS = 'Rights Issue',
  PRIO = 'Priority Issue',
  OTH = 'Other',
}
