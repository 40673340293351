import { ActionStatus } from './types';
import styled from 'styled-components/macro';

const Action = styled.li`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
  margin-top: 3px;

  &:first-child {
    margin-top: 0;
  }
`;

const ActionTitle = styled.span<{ status: ActionStatus }>`
  background: ${({ status, theme }) => {
    switch (status) {
      case ActionStatus.Complete:
        return theme.colors.green;
      case ActionStatus.Incomplete:
        return theme.colors.background;
      case ActionStatus.Danger:
        return theme.colors.red;
      case ActionStatus.Active:
        return '#ffbc00';
      default:
        return theme.colors.background;
    }
  }};
  padding: 8px;
  width: 240px;
`;

const ActionDate = styled.span`
  font-weight: 400;
  margin-left: 16px;
`;

export { Action, ActionTitle, ActionDate };
