import React, { FC, useState, useEffect } from 'react';
import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import { getIssuersByName } from '../data/hubApiRoutes';
import { toast } from 'react-toastify';

const IssuerSuggest = Suggest.ofType<Issuer>();

const renderInputValue = (issuer: Issuer) => {
  return issuer.name;
};

const renderIssuer: ItemRenderer<Issuer> = (
  issuer,
  { handleClick, modifiers },
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  const text = `${issuer.name}`;

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={issuer.id}
      onClick={handleClick}
      text={text}
    />
  );
};

const filterIssuer: ItemPredicate<Issuer> = (query, issuer) => {
  return `${issuer.name.toLocaleLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

interface Issuer {
  id: string;
  name: string;
}

interface IssuerTypeaheadProps {
  onAddIssuer: (query: string) => void;
  onSelectIssuer: (issuer: Issuer) => void;
  selectedItem: Issuer;
}

export const IssuerTypeahead: FC<IssuerTypeaheadProps> = ({
  onAddIssuer,
  onSelectIssuer,
  selectedItem,
}) => {
  const [query, setQuery] = useState<string>('');
  const [issuers, setIssuers] = useState<Issuer[]>([]);

  useEffect(() => {
    getIssuersByName(query)
      .then((response) => setIssuers(response.data))
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data));
      });
  }, [query]);

  return (
    <IssuerSuggest
      inputValueRenderer={renderInputValue}
      items={issuers}
      itemRenderer={renderIssuer}
      itemPredicate={filterIssuer}
      noResults={
        <MenuItem
          icon="plus"
          onClick={() => onAddIssuer(query)}
          text="Add New Issuer"
        />
      }
      query={query}
      popoverProps={{
        popoverClassName: 'issuer-popover',
        position: 'bottom-left',
      }}
      onQueryChange={(typeQuery: string) => {
        setQuery(typeQuery);
      }}
      onItemSelect={(item: Issuer) => {
        onSelectIssuer(item);
      }}
      selectedItem={selectedItem}
    />
  );
};
