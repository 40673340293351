import React, { FC } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { CusipCell } from '../../../common/List/CusipCell';
import { Button } from '../../../common/Buttons/Button';
import { formatDate } from '../../../utils/format-date';
import { StatusBar } from '../../../common/StatusBar';
import { FilingListResponse } from '../../../models/filing-list';
import { formatNumberWithComma } from '../../../utils/formatNumberWithComma';
import { CorporateActionType } from '../../../models/corporate-action-type';
import { Ordering } from '../../../data/Ordering.Context';
import { formatRecordDateRange } from '../../../utils/formatRecordDateRange';
import Table, { TableColumnsProps } from '../../../common/Table/Table';
import TableRow from '../../../common/Table/TableRow';
import Cell from '../../../common/Table/Cell';

interface ReviewEmailsProps extends RouteComponentProps {
  filings: FilingListResponse;
  isLoading: boolean;
  onChangePage: (path: string) => void;
}

export const ReviewEmails: FC<ReviewEmailsProps> = ({
  filings,
  isLoading,
  onChangePage,
  children,
}) => {
  const { ordering, setOrder } = Ordering.useContainer();

  const handleSetOrdering = (order: string) => {
    setOrder('caReviewEmails', order);
  };

  const renderFullActionTypeText = (actionType: CorporateActionType) =>
    CorporateActionType[actionType as any];

  return (
    <>
      <StatusBar
        count={filings.count}
        next={filings.next}
        onChangePage={onChangePage}
        previous={filings.previous}
        typeLabel="Corporate Action Tasks"
      />

      <Table
        columns={columns}
        onSetOrdering={handleSetOrdering}
        ordering={ordering.caReviewEmails}
        variant="gold"
        isLoading={isLoading}
      >
        {filings.results.map((filing) => {
          return (
            <TableRow
              key={filing.id}
              to={`old/filings/${filing.id}/mailings`}
              variant="gold"
            >
              <Cell>{filing.issuer.companyName}</Cell>
              <Cell>
                <CusipCell
                  cusips={filing.securities.map((security) => security.cusip)}
                />
              </Cell>
              <Cell>
                {filing.actionType &&
                  renderFullActionTypeText(filing.actionType)}
              </Cell>
              <Cell align="right">
                {filing.recordDateStart &&
                  formatRecordDateRange(
                    filing.recordDateStart,
                    filing.recordDateEnd,
                  )}
              </Cell>
              <Cell align="right">
                {filing.stats &&
                  formatNumberWithComma(filing.stats.fullSharePositions)}
              </Cell>
              <Cell align="right">
                {filing.electionCutoffDate &&
                  formatDate(filing.electionCutoffDate)}
              </Cell>
              <Cell align="right">
                {filing.stats && filing.stats.digitalSendDeadline
                  ? formatDate(filing.stats.digitalSendDeadline)
                  : ''}
              </Cell>
              <Cell align="center">
                <Button
                  small={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate &&
                      navigate(
                        `/old/filings/corporate-actions/review-emails/${filing.id}`,
                        {
                          state: { param: 'is_corporate_action_broker' },
                        },
                      );
                  }}
                  m="0 auto"
                  width={'175px'}
                >
                  Review Email
                </Button>
              </Cell>
            </TableRow>
          );
        })}
      </Table>

      {children}
    </>
  );
};

const columns: TableColumnsProps[] = [
  {
    name: 'Issuer',
    key: 'issuer',
    orderingKey: 'issuer_name',
  },
  {
    name: 'CUSIP',
    key: 'cusip',
  },
  {
    name: 'Action Type',
    key: 'action-type',
    orderingKey: 'action_type',
  },
  {
    name: 'Record Date',
    key: 'record-date',
    orderingKey: 'record_date_start',
    align: 'right',
  },
  {
    name: 'Positions',
    key: 'positions',
    orderingKey: 'stats__full_share_positions',
    align: 'right',
  },
  {
    name: 'Cutoff Date',
    key: 'cutoff-date',
    orderingKey: 'cutoff_date',
    align: 'right',
  },
  {
    name: 'Send Deadline',
    key: 'send-deadline',
    orderingKey: 'stats__digital_send_deadline',
    align: 'right',
  },
  {
    name: 'Action',
    key: 'action',
  },
];
