import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Flex, Box } from '@rebass/grid';
import { ReactComponent as ClearSearch } from './clear-search.svg';

const StyledClearSearch = styled(ClearSearch)`
  cursor: pointer;
`;

const Tag = styled(Flex)`
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
`;

interface SearchTagProps {
  onClearSearch: () => void;
  tag: string;
}

export const SearchTag: FC<SearchTagProps> = ({ onClearSearch, tag }) => {
  return (
    <Tag alignItems="center" px={3} py={2}>
      <Box mr={2}>{tag}</Box>
      <StyledClearSearch onClick={onClearSearch} />
    </Tag>
  );
};
