import { privateApi } from '../../old/utils/api-adapter';
import useSWR from 'swr';
import { FilingDetails } from './types';

const fetcher = (url: string) => privateApi.get<FilingDetails>(url);

function useProxy(id: string | undefined) {
  const { data, mutate, error } = useSWR(`/filings/${id}/`, fetcher);

  return { data, mutate, error };
}

export { useProxy };
