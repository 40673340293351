/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { RouteComponentProps, Router, Redirect } from '@reach/router';
import { Pending } from './Tabs/Pending';
import { Flex } from '@rebass/grid';
import { Text } from '../common/Text';
import { Tab } from '../common/Tab';
import { Tabs } from '../common/Tabs';
import styled from 'styled-components/macro';
import { ResultsLayout } from './ResultsLayout';
import { Dismissed } from './Tabs/Dismissed';
import { Confirmed } from './Tabs/Confirmed';
import { Ordering } from '../data/Ordering.Context';
import { AllResults } from '../data/SECFilings';

const StickyTabs = styled(Flex)`
  background: ${({ theme }) => theme.colors.white};
  position: sticky;
  top: 73px;
  z-index: 1;
`;

export enum TabChoice {
  Pending = 'pending',
  Dismissed = 'dismissed',
  Confirmed = 'confirmed',
}

export const Results: FC<RouteComponentProps> = () => {
  const { ordering } = Ordering.useContainer();
  const { allResults, getResultsByTab } = AllResults.useContainer();

  useEffect(() => {
    getResultsByTab(TabChoice.Pending, ordering.resultsPending);
  }, [ordering.resultsPending]);

  useEffect(() => {
    getResultsByTab(TabChoice.Confirmed, ordering.resultsConfirmed);
  }, [ordering.resultsConfirmed]);

  useEffect(() => {
    getResultsByTab(TabChoice.Dismissed, ordering.resultsDismissed);
  }, [ordering.resultsDismissed]);

  return (
    <ResultsLayout>
      <StickyTabs flexDirection="column" width={1}>
        <Tabs columns={3}>
          <Tab link="pending">
            <Flex justifyContent="space-between">
              <Text>Pending</Text>
              {/* TODO Get count */}
              <Text className="count">{allResults.pending.results.count}</Text>
            </Flex>
          </Tab>
          <Tab link="confirmed">
            <Flex justifyContent="space-between">
              <Text>Confirmed</Text>
              {/* TODO Get count */}
              <Text className="count">
                {allResults.confirmed.results.count}
              </Text>
            </Flex>
          </Tab>
          <Tab link="dismissed">
            <Flex justifyContent="space-between">
              <Text>Dismissed</Text>
              {/* TODO Get count */}
              <Text className="count">
                {allResults.dismissed.results.count}
              </Text>
            </Flex>
          </Tab>
        </Tabs>
      </StickyTabs>

      <div>
        <Router>
          <Redirect from="/" to="/results/pending" noThrow={true} />
          <Pending path="pending" />
          <Dismissed path="dismissed" />
          <Confirmed path="confirmed" />
        </Router>
      </div>
    </ResultsLayout>
  );
};
