import React from 'react';
import { Tabs, Tab, Button, IconButton } from '@material-ui/core/';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IFilingCreateValues, OperationsStatus } from '../types';
import { FormikProps } from 'formik';

import { HeaderTitle } from '../Details';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { ProductIcon } from '../../../components/ProductIcon';
import { CusipTagList } from '../CusipTagList';
import { StatusBadge } from '../StatusBadge';

import { formatCamelCaseString } from '../../../utils/format-camel-case';
import { formatDate } from '../../../utils/format-date';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      boxSizing: 'border-box',
      zIndex: 1100,
      flexShrink: 0,
      position: 'sticky',
      top: 0,
      left: 'auto',
      right: 0,
      backgroundColor: '#FFFFFF',
      '& > *': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #e5e5e5',
        padding: '0 24px',
      },
    },
    mainTitleBar: {
      height: '58px',
    },
    mainTitle: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        marginRight: '16px',
      },
    },
    filingTitleBar: {
      height: '64px',
      cursor: 'default !important',
    },
    filingTitle: {
      width: '30%',
    },
    ctaHeader: {
      minHeight: '48px',
    },
    errorHeaderNone: {
      display: 'none',
    },
    errorHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 24px',
      fontSize: '.75rem',
    },
    saveButton: {
      marginRight: '12px',
    },
  }),
);

type CreateHeaderProps = {
  formikBag: FormikProps<IFilingCreateValues>;
  errorBanner: boolean;
  setErrorBanner: (error: boolean) => void;
  badRequest: string;
};

export const CreateHeader = ({
  formikBag,
  errorBanner,
  setErrorBanner,
  badRequest,
}: CreateHeaderProps) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div className={classes.mainTitleBar}>
        <div className={classes.mainTitle}>
          <ProductIcon product="px" />
          <h1 className="font-medium text-primary-text text-xl capitalize inline-block">
            {formikBag.values.type
              ? formatCamelCaseString(formikBag.values.type)
              : 'Draft Filing'}
          </h1>
          <StatusBadge filingStatus={OperationsStatus.New} />
        </div>
      </div>

      <div id="ProxyDetailHeader" className={classes.filingTitleBar}>
        <div className={classes.filingTitle}>
          <HeaderTitle label="Issuer">
            <div>
              {formikBag.values.issuer &&
              formikBag.values.issuer.companyName ? (
                formikBag.values.issuer.companyName
              ) : (
                <span style={{ color: 'rgba(0,0,0,.38)' }}>Issuer</span>
              )}
            </div>
          </HeaderTitle>
        </div>
        <HeaderTitle label="CUSIP">
          {formikBag.values.cusips.length > 0 ? (
            <CusipTagList
              cusips={formikBag.values.cusips.map((item) => item.cusip)}
            />
          ) : (
            <span style={{ color: 'rgba(0,0,0,.38)' }}>CUSIP</span>
          )}
        </HeaderTitle>

        <HeaderTitle
          label="Filing Type"
          value={
            formikBag.values.type ? (
              formatCamelCaseString(formikBag.values.type)
            ) : (
              <span style={{ color: 'rgba(0,0,0,.38)' }}>Filing Type</span>
            )
          }
        />

        <HeaderTitle
          label="Record Date"
          value={
            formikBag.values.recordDate ? (
              formatDate(new Date(formikBag.values.recordDate))
            ) : (
              <span style={{ color: 'rgba(0,0,0,.38)' }}>Record Date</span>
            )
          }
        />

        <HeaderTitle label="EDGAR Date">
          <span style={{ color: 'rgba(0,0,0,.38)' }}>N/A</span>
        </HeaderTitle>
        <HeaderTitle label="Positions">
          <span style={{ color: 'rgba(0,0,0,.38)' }}>N/A</span>
        </HeaderTitle>
        <HeaderTitle label="Materials">
          <span style={{ color: 'rgba(0,0,0,.38)' }}>N/A</span>
        </HeaderTitle>
      </div>
      <div id="FilingDetailTab" className={classes.ctaHeader}>
        <Tabs
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          value=""
        >
          <Tab label="Filing" value="" />
          <Tab label="Email" value="email" disabled />
        </Tabs>
        <div id="FilingDetailSave">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              setErrorBanner(false);
              if (formikBag.errors || !formikBag.isValid) {
                setErrorBanner(true);
              }
            }}
          >
            Add Filing
          </Button>
        </div>
      </div>

      {/* Very basic - replace with error message(s) mapping fields to specific tabs. */}
      <div
        className={errorBanner ? classes.errorHeader : classes.errorHeaderNone}
      >
        <div>
          <ErrorOutlineIcon style={{ fill: '#d33f33', marginRight: '12px' }} />
          <span>Incomplete submission. {badRequest}</span>
        </div>
        <IconButton size="small" onClick={() => setErrorBanner(false)}>
          <CancelIcon fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );
};
