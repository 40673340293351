import React from 'react';
import { ChoiceChip } from '../DetailsForm';
import { FilingType } from '../types';
import { Tooltip } from '@material-ui/core';

type ChipNavProps = {
  type: FilingType;
};

export const ChipNav = ({ type }: ChipNavProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ChoiceChip
        variant="outlined"
        label="Details"
        style={{
          backgroundColor: 'rgba(17, 204, 153, 0.12)',
          borderColor: '#11cc99',
          marginRight: '8px',
        }}
      />
      <Tooltip
        title="You must add the filing before you can work on this section"
        placement="right"
        arrow
      >
        <div>
          {type !== 'FundReport' &&
            type !== 'FundInformationStatement' &&
            type !== 'FirmInformationStatement' && (
              <ChoiceChip
                variant="outlined"
                label="Ballot"
                style={{
                  color: '#979797',
                }}
              />
            )}

          <ChoiceChip
            variant="outlined"
            label="Materials"
            style={{
              color: '#979797',
            }}
          />

          {type !== 'FundReport' &&
            type !== 'FundInformationStatement' &&
            type !== 'FirmInformationStatement' && (
              <ChoiceChip
                variant="outlined"
                label="Meeting"
                style={{
                  color: '#979797',
                }}
              />
            )}
        </div>
      </Tooltip>
    </div>
  );
};
