import { schema } from 'normalizr';

const prospectusSchema = new schema.Entity('prospectuses');

const issuerSchema = new schema.Entity('issuers', {
  prospectuses: {
    active: [prospectusSchema],
    archived: [prospectusSchema],
    upcoming: [prospectusSchema],
  },
});
const issuerListSchema = new schema.Array(issuerSchema);

export { issuerSchema, issuerListSchema, prospectusSchema };
