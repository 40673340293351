import React, { FC } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { CusipCell } from '../../../common/List/CusipCell';
import { Button } from '../../../common/Buttons/Button';
import { formatDate } from '../../../utils/format-date';
import { formatCamelCaseString } from '../../../utils/format-camel-case-string';
import { StatusBar } from '../../../common/StatusBar';
import { FilingListResponse } from '../../../models/filing-list';
import { formatNumberWithComma } from '../../../utils/formatNumberWithComma';
import { formatMeetingDateCell } from '../../../utils/format-meeting-date-cell';
import { Ordering } from '../../../data/Ordering.Context';
import Table, { TableColumnsProps } from '../../../common/Table/Table';
import TableRow from '../../../common/Table/TableRow';
import Cell from '../../../common/Table/Cell';
import { EdgarDateTableCell } from '../../../common/EdgarDateTableCell';
import { userIdEvent } from '../../../utils/analytics-helpers';

interface ReviewEmailsProps extends RouteComponentProps {
  filings: FilingListResponse;
  isLoading: boolean;
  onChangePage: (path: string) => void;
}

export const ReviewEmails: FC<ReviewEmailsProps & RouteComponentProps> = ({
  filings,
  isLoading,
  onChangePage,
  children,
}) => {
  const { ordering, setOrder } = Ordering.useContainer();

  const handleSetOrdering = (order: string) => {
    setOrder('reviewEmails', order);
  };

  return (
    <>
      <StatusBar
        count={filings.count}
        next={filings.next}
        onChangePage={onChangePage}
        previous={filings.previous}
        typeLabel="Proxy Tasks"
      />

      <Table
        columns={columns}
        isLoading={isLoading}
        onSetOrdering={handleSetOrdering}
        ordering={ordering.reviewEmails}
        variant="green"
      >
        {filings.results.map((filing, index) => {
          return (
            <TableRow
              key={filing.id}
              to={`old/filings/${filing.id}/mailings`}
              variant="green"
            >
              <Cell>{filing.issuer.companyName}</Cell>
              <Cell>
                {filing.edgarFilings && (
                  <EdgarDateTableCell
                    edgarFilings={filing.edgarFilings}
                    issuer={filing.issuer}
                  />
                )}
              </Cell>
              <Cell>
                <CusipCell
                  cusips={filing.securities.map((security) => security.cusip)}
                />
              </Cell>
              <Cell>{formatCamelCaseString(filing.type)}</Cell>
              <Cell align="right">
                {filing.recordDate ? formatDate(filing.recordDate) : ''}
              </Cell>
              <Cell align="right">
                {filing.stats &&
                  formatNumberWithComma(filing.stats.totalPositions)}
              </Cell>
              <Cell align="right">
                {formatMeetingDateCell(filing.type, filing.meetingDate)}
              </Cell>
              <Cell align="right">
                {filing.stats && filing.stats.digitalSendDeadline
                  ? formatDate(filing.stats.digitalSendDeadline)
                  : ''}
              </Cell>
              <Cell align="center">
                <Button
                  small={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    userIdEvent('Review Email Clicked', {
                      filingId: filing.id,
                      filingType: filing.type,
                    });
                    navigate &&
                      navigate(
                        `/old/filings/proxy/review-emails/${filing.id}`,
                        {
                          state: { param: 'is_proxy_broker' },
                        },
                      );
                  }}
                  m="0 auto"
                  width={'175px'}
                >
                  Review Email
                </Button>
              </Cell>
            </TableRow>
          );
        })}
      </Table>

      {children}
    </>
  );
};

const columns: TableColumnsProps[] = [
  {
    name: 'Issuer',
    key: 'issuer',
    orderingKey: 'issuer_name',
  },
  {
    name: 'Edgar',
    key: 'edgar',
    orderingKey: 'latest_edgar_filing_date',
  },
  {
    name: 'CUSIP',
    key: 'cusip',
  },
  {
    name: 'Filing Type',
    key: 'filing-type',
  },
  {
    name: 'Record Date',
    key: 'record-date',
    orderingKey: 'first_record_date',
    align: 'right',
  },
  {
    name: 'Positions',
    key: 'positions',
    orderingKey: 'stats__total_positions',
    align: 'right',
  },
  {
    name: 'Meeting Date',
    key: 'meeting-date',
    orderingKey: 'meeting_date',
    align: 'right',
  },
  {
    name: 'Send Deadline',
    key: 'send-deadline',
    orderingKey: 'stats__digital_send_deadline',
    align: 'right',
  },
  {
    name: 'Action',
    key: 'action',
    align: 'center',
  },
];
