import React, { FC } from 'react';
import { EdgarFiling } from '../models/edgar';
import { EdgarDateLink } from './EdgarDateLink';
import { Issuer } from '../models/filing-list';

type EdgarDateTableCellProps = {
  edgarFilings: EdgarFiling[];
  issuer: Issuer;
};

type LatestEdgarFiling = undefined | EdgarFiling;

const EdgarDateTableCell: FC<EdgarDateTableCellProps> = ({
  edgarFilings,
  issuer,
}) => {
  const { edgarLandingPage } = issuer;
  const latestEdgarFiling: LatestEdgarFiling = edgarFilings[0];

  if (latestEdgarFiling && latestEdgarFiling.filingDate && edgarLandingPage) {
    return (
      <EdgarDateLink
        date={latestEdgarFiling.filingDate}
        edgarLandingPage={edgarLandingPage}
        operationStatus={latestEdgarFiling.status}
      />
    );
  }

  return null;
};

export { EdgarDateTableCell };
