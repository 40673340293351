import numeral from 'numeral';

function formatNumber(num: number) {
  if (num === 0) {
    return '0';
  }

  if (isDecimal(num)) {
    return numeral(num).format('0,0.00');
  } else {
    return numeral(num).format('0,0');
  }
}

function isDecimal(num: number) {
  if (num % 1 === 0) {
    return false;
  } else {
    return true;
  }
}

export { formatNumber };
