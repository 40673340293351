import { Stats } from './stats';
import { FilingType } from './filing-type';
import { ISolicitor } from './intermediaries';
import { CorporateActionType } from './corporate-action-type';
import { TimeZone } from './time-zone';
import { EdgarFiling } from './edgar';

export interface FilingListResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: FilingListFiling[];
}

export interface FilingListFiling {
  actionType?: CorporateActionType;
  actualMaterialsRequired: number;
  brokers?: { id: number; name: string; slug: string }[];
  created: Date;
  daysOpen: number;
  deliveryMethod: DeliveryMethod;
  digitalMaterialsExpectedDate: Date | null;
  digitalMaterialsReceivedDate: Date | null;
  digitalSendDeadline: Date | null;
  documents: any[];
  edgarFilings?: EdgarFiling[];
  electionCutoffDate?: string | null;
  electionCutoffTimezome?: TimeZone;
  expirationDate?: string;
  haveMaterialsBeenRequested: boolean;
  id: number;
  informationAgent?: string;
  informationAgentEmail?: string | null;
  invoicerContactEmail: string | null;
  invoiceSentDate: Date | null;
  issuer: Issuer;
  meetingDate?: Date | null;
  operationsStatus: OperationsStatus;
  paperMaterialsExpectedDate: Date | null;
  paperMaterialsReceivedDate: Date | null;
  paperSendDeadline: Date | null;
  recordDate: string | null;
  recordDateEnd?: string | null;
  recordDateStart?: string;
  securities: Security[];
  solicitor: ISolicitor | null;
  solicitorCompany: null | string;
  solicitorContactEmail: null | string;
  solicitorContactName: null | string;
  solicitorJobNumber: null | string;
  stats: null | Stats;
  status: Status;
  type: FilingType;
}

export enum DeliveryMethod {
  NoticeAndAccess = 'notice_and_access',
  Traditional = 'traditional',
}

export interface Issuer {
  cik: number | null;
  edgarLandingPage: string | null;
  id: number;
  companyName: string;
  contactEmail: string | null;
}

export enum OperationsStatus {
  New = 'new',
  Active = 'active',
  MaterialsRequested = 'materials_requested',
  ReadyForReview = 'ready_for_review',
  Approved = 'approved',
  Closed = 'closed',
}

export interface Security {
  id: number;
  created: Date;
  modified: Date;
  ticker: string | null;
  cusip: string;
  name: string;
  isin: string | null;
  issuer: number;
}

export enum Status {
  SendMaterialsEstimate = 'send_materials_estimate',
  ConfirmMeetingReportInfo = 'comfirm_meeting_report_info',
  ConfirmMeetingInfo = 'confirm_meeting_info',
  ConfirmReportInfo = 'confirm_report_info',
  SendMaterialsCount = 'send_materials_count',
  ReceiveMaterials = 'receive_materials',
  SendMaterials = 'send_materials',
  SendInvoice = 'send_invoice',
  Closed = 'closed',
}
