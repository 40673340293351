import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  OperationsStatus,
  FilingListResponse,
  FilingListFiling,
} from '../../../models/filing-list';
import { UnmarkReadyForReviewModal } from '../UnmarkReadyForReviewModal';
import { CusipCell } from '../../../common/List/CusipCell';
import { Button } from '../../../common/Buttons/Button';
import { StatusBar } from '../../../common/StatusBar';
import { GrayButton } from '../../../common/Buttons/GrayButton';
import { formatDate } from '../../../utils/format-date';
import { formatNumberWithComma } from '../../../utils/formatNumberWithComma';
import { Ordering } from '../../../data/Ordering.Context';
import { MailToCell } from '../../../common/List/MailToCell';
import { renderFullActionTypeText } from '../../../utils/renderFullActionTypeText';
import { formatRecordDateRange } from '../../../utils/formatRecordDateRange';
import Table, { TableColumnsProps } from '../../../common/Table/Table';
import TableRow from '../../../common/Table/TableRow';
import Cell from '../../../common/Table/Cell';

interface InProgressProps extends RouteComponentProps {
  filings: FilingListResponse;
  isLoading: boolean;
  onChangePage: (path: string) => void;
  onMarkReadyForReview: (
    filingId: number,
    operationsStatus: OperationsStatus.ReadyForReview,
  ) => void;
  onUnmarkReadyForReview: (
    filingId: number,
    operationsStatus: OperationsStatus.ReadyForReview,
  ) => void;
}

export const InProgress: FC<InProgressProps> = ({
  filings,
  isLoading,
  onChangePage,
  onMarkReadyForReview,
  onUnmarkReadyForReview,
}) => {
  const { ordering, setOrder } = Ordering.useContainer();
  const [
    unmarkReadyForReviewModalState,
    setUnmarkReadyForReviewModalState,
  ] = useState<{
    isOpen: boolean;
    filingId: number;
  }>({ isOpen: false, filingId: 0 });

  const handleSetOrdering = (order: string) => {
    setOrder('caInProgress', order);
  };

  const markReadyForReview = (filingId: number) => {
    return onMarkReadyForReview(filingId, OperationsStatus.ReadyForReview);
  };

  const unmarkReadyForReview = (filingId: number) => {
    return setUnmarkReadyForReviewModalState({ isOpen: true, filingId });
  };

  const handleUndoMarkReadyForReview = () => {
    onUnmarkReadyForReview(
      unmarkReadyForReviewModalState.filingId,
      OperationsStatus.ReadyForReview,
    );
    return setUnmarkReadyForReviewModalState({ isOpen: false, filingId: 0 });
  };

  const renderReadyForReviewButton = (filing: FilingListFiling) => {
    return filing.operationsStatus === OperationsStatus.ReadyForReview ? (
      <GrayButton
        small={true}
        m="0 auto"
        width={'185px'}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          return unmarkReadyForReview(filing.id);
        }}
      >
        Ready for Review
      </GrayButton>
    ) : (
      <Button
        small={true}
        width={'185px'}
        m="0 auto"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          return markReadyForReview(filing.id);
        }}
      >
        Mark Ready for Review
      </Button>
    );
  };

  return (
    <>
      <StatusBar
        count={filings.count}
        next={filings.next}
        onChangePage={onChangePage}
        previous={filings.previous}
        typeLabel="Corporate Action Tasks"
      />

      <Table
        columns={columns}
        isLoading={isLoading}
        onSetOrdering={handleSetOrdering}
        ordering={ordering.caInProgress}
        variant="gold"
      >
        {filings.results.map((filing) => {
          return (
            <TableRow
              key={filing.id}
              to={`old/filings/${filing.id}/mailings`}
              variant="gold"
            >
              <Cell>{filing.issuer.companyName}</Cell>
              <Cell>
                <CusipCell
                  cusips={filing.securities.map((security) => security.cusip)}
                />
              </Cell>
              <Cell>
                {filing.actionType &&
                  renderFullActionTypeText(filing.actionType)}
              </Cell>
              <Cell align="right">
                {filing.recordDateStart &&
                  formatRecordDateRange(
                    filing.recordDateStart,
                    filing.recordDateEnd,
                  )}
              </Cell>
              <Cell align="right">
                {filing.stats &&
                  formatNumberWithComma(filing.stats.materialsRequired)}
              </Cell>
              <Cell align="right">
                {filing.stats && filing.stats.digitalSendDeadline
                  ? formatDate(filing.stats.digitalSendDeadline)
                  : ''}
              </Cell>
              <Cell>
                {filing.informationAgent &&
                  filing.informationAgentEmail &&
                  filing.actionType &&
                  filing.issuer.companyName && (
                    <MailToCell
                      actionType={filing.actionType}
                      issuerName={filing.issuer.companyName}
                      name={filing.informationAgent}
                      email={filing.informationAgentEmail}
                    />
                  )}
              </Cell>
              <Cell align="center">{renderReadyForReviewButton(filing)}</Cell>
            </TableRow>
          );
        })}
      </Table>

      <UnmarkReadyForReviewModal
        isOpen={unmarkReadyForReviewModalState.isOpen}
        onRequestClose={() =>
          setUnmarkReadyForReviewModalState({ isOpen: false, filingId: 0 })
        }
        onUndoMarkReadyForReview={handleUndoMarkReadyForReview}
      />
    </>
  );
};

const columns: TableColumnsProps[] = [
  {
    name: 'Issuer',
    key: 'issuer',
    orderingKey: 'issuer__company_name',
  },
  {
    name: 'CUSIP',
    key: 'cusip',
  },
  {
    name: 'Action Type',
    key: 'action-type',
    orderingKey: 'action_type',
  },
  {
    name: 'Record Date',
    key: 'record-date',
    orderingKey: 'record_date_start',
    align: 'right',
  },
  {
    name: 'Materials',
    key: 'materials',
    orderingKey: 'stats__materials_required',
    align: 'right',
  },
  {
    name: 'Send Deadline',
    key: 'send-deadline',
    orderingKey: 'stats__digital_send_deadline',
    align: 'right',
  },
  {
    name: 'Information Agent',
    key: 'information-agent',
    orderingKey: 'information_agent',
    align: 'right',
  },
  {
    name: 'Action',
    key: 'action',
    align: 'center',
  },
];
