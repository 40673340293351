import React from 'react';
import { useCombobox, useMultipleSelection } from 'downshift';
import { privateApi } from '../../old/utils/api-adapter';
import useSWR from 'swr';
import { FiPlus, FiX } from 'react-icons/fi';

const fetcher = (url: string) => privateApi.get<Item[]>(url);

type Item = {
  id: number;
  cusip: string;
  name: string;
  issuer: Issuer;
};

type Issuer = {
  id: number;
  companyName: string;
  contactEmail: null | string;
  cik: number | null;
  edgarLandingPage: null | string;
};

type CusipTypeaheadProps = {
  selectedItems: Item[];
  onSelectItem: (item: Item) => void;
  onRemoveItem: (index: number) => void;
  onClear: () => void;
  issuerId?: number;
  disabled?: boolean;
};

function CusipTypeahead({
  selectedItems,
  onSelectItem,
  onClear,
  onRemoveItem,
  issuerId,
  disabled = false,
}: CusipTypeaheadProps) {
  const [inputValue, setInputValue] = React.useState('');
  const { data } = useSWR(
    `/issuers/cusip-typeahead/?cusip=${inputValue}&issuer_id=${issuerId || ''}`,
    fetcher,
  );
  const items = data ? data.data : [];
  const itemToString = (item: Item | null) => (item ? item.cusip : '');
  const [isFocused, setIsFocused] = React.useState(false);
  const { getSelectedItemProps, getDropdownProps } = useMultipleSelection({
    initialSelectedItems: selectedItems,
  });
  const getFilteredItems = (items: Item[]) =>
    items.filter(
      (item) =>
        selectedItems.map((selectedItem) => selectedItem.id).indexOf(item.id) <
        0,
    );
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
    openMenu,
    closeMenu,
  } = useCombobox({
    inputValue,
    items: getFilteredItems(items),
    itemToString,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
          };
      }
      return changes;
    },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          if (inputValue) {
            setInputValue(inputValue);
          } else {
            setInputValue('');
          }
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('');
            onSelectItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  return (
    <div
      className={`relative ${
        disabled ? 'bg-very-light-gray disabled:cursor-not-allowed' : ''
      }`}
    >
      <div
        {...getComboboxProps()}
        className={`border rounded outline-none h-8 px-2 w-full flex items-center ${
          isFocused ? 'border-primary-text' : 'border-light-gray'
        }`}
        id="CusipValue"
      >
        <div className="flex items-center">
          {selectedItems.map((selectedItem, index) => (
            <div
              className="bg-primary-text inline-flex items-center rounded px-2 text-white mr-2"
              key={index}
              {...getSelectedItemProps({ selectedItem, index })}
            >
              {selectedItem.cusip}
              {!disabled ? (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveItem(index);
                  }}
                >
                  <FiX className="ml-1" />
                </button>
              ) : null}
            </div>
          ))}
        </div>
        <input
          className="focus:outline-none w-full h-full disabled:bg-very-light-gray disabled:cursor-not-allowed"
          disabled={disabled}
          {...getInputProps(
            getDropdownProps({
              onFocus: () => {
                if (!isOpen) {
                  openMenu();
                  setIsFocused(true);
                }
              },
              onBlur: () => {
                setIsFocused(false);
              },
            }),
          )}
        />
        {!disabled ? (
          <button
            tabIndex={-1}
            onClick={() => {
              onClear();
            }}
            aria-label="clear selection"
            id="CusipClear"
            type="button"
          >
            <FiX />
          </button>
        ) : null}
      </div>

      {inputValue.length > 1 ? (
        <ul
          className={`${
            isOpen ? 'p-2 rounded border border-light-gray' : null
          } overflow-auto text-sm text-primary-text absolute bg-white w-full z-10`}
          style={{ maxHeight: '25rem' }}
          id="CusipData"
          {...getMenuProps()}
        >
          {isOpen && !data && (
            <li className="text-primary-text text-sm">Loading...</li>
          )}

          {isOpen && data && items.length
            ? getFilteredItems(items).map((item, index) => (
                <li
                  key={`${item}${index}`}
                  className={`text-primary-text text-sm mt-2 cursor-pointer ${
                    highlightedIndex === index ? 'font-medium' : 'font-normal'
                  }`}
                  {...getItemProps({ item, index })}
                >
                  {item.cusip}
                </li>
              ))
            : null}

          {isOpen && data && !items.length ? (
            <li className={`text-primary-text text-sm cursor-pointer`}>
              <button
                type="button"
                className="flex items-center disabled:text-light-gray disabled:cursor-not-allowed"
                disabled={inputValue.length !== 9}
                onClick={() => {
                  closeMenu();
                  onSelectItem({
                    cusip: inputValue,
                    id: 0,
                    issuer: {
                      cik: null,
                      companyName: '',
                      contactEmail: '',
                      edgarLandingPage: '',
                      id: 0,
                    },
                    name: '',
                  });
                }}
              >
                <FiPlus /> Add CUSIP
              </button>
            </li>
          ) : null}
        </ul>
      ) : null}
    </div>
  );
}

export { CusipTypeahead };
