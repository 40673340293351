import React, { ReactNode, useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate, ReactEditor } from 'slate-react';
import {
  Editor,
  Transforms,
  createEditor,
  Node,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import styled from 'styled-components';
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatListNumbered,
  FormatListBulleted,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

interface ButtonProps {
  format: string;
  icon: ReactNode;
}

interface Props {
  value: any;
  onChange: (value: Node[]) => void;
}

const HOTKEYS: { [index: string]: string } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

function ProposalContextEditor({ value, onChange }: Props) {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  return (
    <Slate
      editor={editor}
      value={
        value
          ? typeof value === 'string'
            ? [{ children: [{ text: value }] }]
            : value
          : [
              {
                children: [{ text: '' }],
              },
            ]
      }
      onChange={onChange}
    >
      <Toolbar>
        <MarkButton format="bold" icon={<FormatBold />} />
        <MarkButton format="italic" icon={<FormatItalic />} />
        <MarkButton format="underline" icon={<FormatUnderlined />} />
        <BlockButton format="numbered-list" icon={<FormatListNumbered />} />
        <BlockButton format="bulleted-list" icon={<FormatListBulleted />} />
      </Toolbar>
      <StyledEditable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Enter proposal context (optional)"
        spellCheck={true}
        autoFocus={true}
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event as any)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
}

const toggleBlock = (editor: ReactEditor, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        (!Editor.isEditor(n) && SlateElement.isElement(n) && n.type) as string,
      ),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: ReactEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: ReactEditor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor: ReactEditor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }: any) => {
  switch (element.type) {
    case 'bulleted-list':
      return <BulletedList {...attributes}>{children}</BulletedList>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <NumberedList {...attributes}>{children}</NumberedList>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }: ButtonProps) => {
  const editor = useSlate();

  return (
    <IconButton
      color={isBlockActive(editor, format) ? 'primary' : 'default'}
      edge="start"
      type="button"
      aria-label={format}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </IconButton>
  );
};

const MarkButton = ({ format, icon }: ButtonProps) => {
  const editor = useSlate();

  return (
    <IconButton
      color={isMarkActive(editor, format) ? 'primary' : 'default'}
      edge="start"
      type="button"
      aria-label={format}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </IconButton>
  );
};

const Toolbar = styled.div`
  border-bottom: 1px solid rgba(51, 49, 49, 0.12);
  padding: 0 24px;
`;

const StyledEditable = styled(Editable)`
  border-bottom: 1px solid rgba(51, 49, 49, 0.12);
  padding: 14px 24px;
  font-size: 12px;
  color: #787878;
`;

const BulletedList = styled.ul`
  list-style: disc;
  margin: initial;
  padding: initial;
`;

const NumberedList = styled.ol`
  list-style: decimal;
  margin: initial;
  padding: initial;
`;

export { ProposalContextEditor };
