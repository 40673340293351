import React from 'react';

type TableProps = {
  children: React.ReactNode;
};

const Table = ({ children }: TableProps) => (
  <table
    className="w-full"
    style={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}
  >
    {children}
  </table>
);

export { Table };
