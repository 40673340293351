import React from 'react';
import useSWR from 'swr';
import { privateApi } from '../../old/utils/api-adapter';
import {
  Issuer,
  InformationAgent,
  Brokers,
  Security,
  CorporateActionType,
} from './types';
import { getPageOffset } from '../../utils/get-page-offset';

interface Response {
  count: number;
  next: null | string;
  previous: null | string;
  results: VCAResult[];
}

export interface VCAResult {
  announcementDate: string;
  applicableDates: string[];
  brokers: Brokers[];
  documents: string[]; // update with documents
  edgarFilings: EdgarFiling[];
  electionCutoffDatetime: string;
  id: string;
  informationAgent: InformationAgent;
  issuer: Issuer;
  notificationReceivedDate: null | string;
  operationsStatus: string;
  recordDate: string;
  securities: Security[];
  stats: Stats | null;
  type: CorporateActionType;
}

interface EdgarFiling {
  cik: null | string;
  filingDate: null | string;
  status: null | string;
  type: null | string;
  url: null | string;
}

interface Stats {
  digitalSendDeadline: Date | null;
}

interface Query {
  pageSize: number;
  page: number;
  ordering: string;
}

type OnUpdateQuery = (
  taskList: 'new' | 'in progress' | 'open',
  query: 'pageSize' | 'page' | 'ordering',
  value: string | number,
) => void;

const fetcher = (url: string) => privateApi.get<Response>(url);

function useTasks() {
  const [newQuery, setNewQuery] = React.useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: 'deadline',
  });

  const [inProgressQuery, setInProgressQuery] = React.useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: 'deadline',
  });

  const [openQuery, setOpenQuery] = React.useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: 'election_cutoff_date',
  });

  const newTasks = useSWR(
    `/admin/vca/?tab=new&limit=${newQuery.pageSize}&ordering=${
      newQuery.ordering
    }&offset=${getPageOffset({
      page: newQuery.page,
      pageSize: newQuery.pageSize,
    })}`,
    fetcher,
  );

  const inProgressTasks = useSWR(
    `/admin/vca/?tab=in_progress&limit=${inProgressQuery.pageSize}&ordering=${
      inProgressQuery.ordering
    }&offset=${getPageOffset({
      page: inProgressQuery.page,
      pageSize: inProgressQuery.pageSize,
    })}`,
    fetcher,
  );

  const openTasks = useSWR(
    `/admin/vca/?tab=open&limit=${openQuery.pageSize}&ordering=${
      openQuery.ordering
    }&offset=${getPageOffset({
      page: openQuery.page,
      pageSize: openQuery.pageSize,
    })}`,
    fetcher,
  );

  const handleUpdateQuery: OnUpdateQuery = (taskList, query, value) => {
    switch (taskList) {
      case 'new':
        setNewQuery({ ...newQuery, [query]: value });
        break;
      case 'in progress':
        setInProgressQuery({ ...inProgressQuery, [query]: value });
        break;
      case 'open':
        setOpenQuery({ ...openQuery, [query]: value });
        break;
    }
  };

  return {
    newTasks,
    inProgressTasks,
    openTasks,
    handleUpdateQuery,
    newQuery,
    inProgressQuery,
    openQuery,
  };
}

export { useTasks };
