import React, { FC } from 'react';
import { Modal } from '../common/Modal';
import styled from 'styled-components/macro';
import { OperationsStatus } from '../models/filing-details';
import { Dialog } from '../common/Dialog/Dialog';
import { OutlineButton as DefaultOutlineButton } from '../common/Buttons/OutlineButton';
import { Button as DefaultButton } from '../common/Buttons/Button';

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'text text'
    'cancel-button confirm-button';
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  width: 100%;
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights[2]};
  color: ${({ theme }) => theme.colors.black};
  grid-area: text;
`;

const OutlineButton = styled(DefaultOutlineButton)`
  grid-area: cancel-button;
`;

const Button = styled(DefaultButton)`
  grid-area: confirm-button;
`;

interface IProps {
  isOpen: boolean;
  handleUpdateOperationsStatus: (
    operationsStatus: OperationsStatus.Approved,
  ) => void;
  toggleModal: () => void;
}
export const EmailPreviewModal: FC<IProps> = ({
  isOpen,
  toggleModal,
  handleUpdateOperationsStatus,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={toggleModal}>
      <Dialog title="Approve Filing?" onRequestClose={toggleModal}>
        <Wrapper>
          <Text>
            Are you sure you would like to approve this email? Approved emails
            will be sent to shareholders.
          </Text>

          <OutlineButton type="button" onClick={() => toggleModal()}>
            Go Back
          </OutlineButton>

          <Button
            type="button"
            onClick={() => {
              handleUpdateOperationsStatus(OperationsStatus.Approved);
              toggleModal();
            }}
          >
            Approve
          </Button>
        </Wrapper>
      </Dialog>
    </Modal>
  );
};
