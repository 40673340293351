import React, { FC, useState, useEffect } from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { MenuItem, PopoverPosition } from '@blueprintjs/core';
import { IFilingCreateValues } from '../../models/filing-form';
import { privateApi } from '../../utils/api-adapter';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { ITabulator, ISolicitor } from '../../models/intermediaries';
import { toast } from 'react-toastify';

const TabulatorSuggest = Suggest.ofType<ITabulator>();

const renderInputValue = (tabulator: ITabulator) => {
  return tabulator.name;
};

const renderTabulator: ItemRenderer<ITabulator> = (
  tabulator,
  { handleClick, modifiers },
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  const text = `${tabulator.name}`;

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={tabulator.id}
      onClick={handleClick}
      text={text}
    />
  );
};

const fetchTabulators = async (query?: string) => {
  try {
    const queryString = query ? `?search=${encodeURIComponent(query)}` : '';
    const response = await privateApi.get(`/companies/${queryString}`);
    return response.data.results.filter(
      (result: ISolicitor | ITabulator) => result.type === 'Tabulator',
    );
  } catch (error) {
    toast.error('Not able to get tabulators');
  }
};

const filterTabulator: ItemPredicate<ITabulator> = (query, tabulator) => {
  return (
    `${tabulator.name.toLocaleLowerCase()}`.indexOf(query.toLowerCase()) >= 0
  );
};

export const TabulatorTypeahead: FC<{}> = () => {
  const [query, setQuery] = useState<string>('');
  const [tabulators, setTabulators] = useState<ITabulator[]>(
    [] as ITabulator[],
  );

  useEffect(() => {
    fetchTabulators(query).then((response) => {
      if (response) {
        setTabulators(response);
      }
    });
  }, [query]);

  const handleSelectTabulator = (
    form: FormikProps<IFilingCreateValues | FilingDetailsFormValues>,
    tabulator: ITabulator,
  ) => {
    form.setFieldValue('tabulator', tabulator);
    const sendToContacts = tabulator.companyContacts.filter(
      (companyContact) =>
        companyContact.role === 'tabulator_reply_to' &&
        companyContact.isDefaultForRole,
    );

    form.setFieldValue('sendTo', sendToContacts);
  };

  return (
    <Field
      name="tabulator"
      render={({
        field,
        form,
      }: FieldProps<IFilingCreateValues | FilingDetailsFormValues>) => (
        <InputGroup>
          <Label htmlFor="tabulator">Tabulator</Label>
          <TabulatorSuggest
            inputValueRenderer={renderInputValue}
            items={tabulators}
            itemRenderer={renderTabulator}
            itemPredicate={filterTabulator}
            noResults={<MenuItem text="No results!" />}
            query={query}
            onQueryChange={(typedQuery: string) => {
              setQuery(typedQuery);
            }}
            popoverProps={{
              position: PopoverPosition.BOTTOM,
            }}
            onItemSelect={(item: ITabulator) => {
              handleSelectTabulator(form, item);
            }}
            selectedItem={field.value}
          />
        </InputGroup>
      )}
    />
  );
};
