import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { SolicitorCell } from '../../../common/List/SolicitorCell';
import { CusipCell } from '../../../common/List/CusipCell';
import { formatDate } from '../../../utils/format-date';
import { formatCamelCaseString } from '../../../utils/format-camel-case-string';
import { StatusBar } from '../../../common/StatusBar';
import { formatMeetingDateCell } from '../../../utils/format-meeting-date-cell';
import { FilingType } from '../../../models/filing-type';
import { Ordering } from '../../../data/Ordering.Context';
import { AllFilings } from '../../../data/AllFilings';
import { MailToCell } from '../../../common/List/MailToCell';
import { formatRecordDateRange } from '../../../utils/formatRecordDateRange';
import Table, { TableColumnsProps } from '../../../common/Table/Table';
import TableRow from '../../../common/Table/TableRow';
import Cell from '../../../common/Table/Cell';
import { EdgarDateTableCell } from '../../../common/EdgarDateTableCell';

const formatProxyFilingRecordDate = (recordDate: string) =>
  formatDate(recordDate);

export const Open: FC<RouteComponentProps> = () => {
  const { ordering, setOrder } = Ordering.useContainer();
  const { allFilings, changePage } = AllFilings.useContainer();
  const { isLoading, open } = allFilings;
  const { filings } = open;

  const handleSetOrdering = (order: string) => {
    setOrder('open', order);
  };

  return (
    <>
      <StatusBar
        count={filings.count}
        next={filings.next}
        onChangePage={changePage}
        previous={filings.previous}
        typeLabel="Filings"
      />

      <Table
        columns={columns}
        isLoading={isLoading}
        onSetOrdering={handleSetOrdering}
        ordering={ordering.open}
        variant="gray"
      >
        {filings.results.map((filing) => {
          return (
            <TableRow
              to={`old/filings/${filing.id}/mailings`}
              key={`open-filing-${filing.id}`}
              variant={
                filing.type === FilingType.CorporateAction ? 'gold' : 'green'
              }
            >
              <Cell>{filing.issuer.companyName}</Cell>
              <Cell>
                {filing.edgarFilings && (
                  <EdgarDateTableCell
                    edgarFilings={filing.edgarFilings}
                    issuer={filing.issuer}
                  />
                )}
              </Cell>
              <Cell>
                <CusipCell
                  cusips={filing.securities.map((security) => security.cusip)}
                />
              </Cell>
              <Cell>{formatCamelCaseString(filing.type)}</Cell>
              <Cell align="right">
                {filing.type === FilingType.CorporateAction
                  ? filing.recordDateStart &&
                    formatRecordDateRange(
                      filing.recordDateStart,
                      filing.recordDateEnd,
                    )
                  : filing.recordDate &&
                    formatProxyFilingRecordDate(filing.recordDate)}
              </Cell>
              <Cell align="right">
                {filing.type === FilingType.CorporateAction
                  ? 'N/A'
                  : filing.stats &&
                    filing.stats.estimatedDigitalMaterialsExpectedDate
                  ? formatDate(
                      filing.stats.estimatedDigitalMaterialsExpectedDate,
                    )
                  : ''}
              </Cell>
              <Cell align="right">
                {formatMeetingDateCell(filing.type, filing.meetingDate)}
              </Cell>
              <Cell>
                {filing.type === FilingType.CorporateAction ? (
                  filing.informationAgent &&
                  filing.informationAgentEmail &&
                  filing.actionType &&
                  filing.issuer.companyName ? (
                    <MailToCell
                      actionType={filing.actionType}
                      issuerName={filing.issuer.companyName}
                      name={filing.informationAgent}
                      email={filing.informationAgentEmail}
                    />
                  ) : (
                    'N/A'
                  )
                ) : (
                  <SolicitorCell
                    actualMaterialsRequired={
                      filing.stats && filing.stats.materialsRequired
                    }
                    recordDate={filing.recordDate}
                    solicitor={filing.solicitor}
                    solicitorJobNumber={filing.solicitorJobNumber}
                  />
                )}
              </Cell>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
};

const columns: TableColumnsProps[] = [
  {
    name: 'Issuer',
    key: 'issuer',
    orderingKey: 'issuer__company_name',
  },
  {
    name: 'Edgar',
    key: 'edgar',
    orderingKey: 'latest_edgar_filing_date',
  },
  {
    name: 'CUSIP',
    key: 'cusip',
  },
  {
    name: 'Filing Type',
    key: 'filing-type',
  },
  {
    name: 'Record Date',
    key: 'record-date',
    orderingKey: 'first_record_date',
    align: 'right',
  },
  {
    name: 'Expected Date',
    key: 'expected-date',
    orderingKey: 'digital_materials_expected_date',
    align: 'right',
  },
  {
    name: 'Meeting Date',
    key: 'meeting-date',
    orderingKey: 'meeting_date',
    align: 'right',
  },
  {
    name: 'Contact',
    key: 'contact',
    orderingKey: 'solicitor__name',
  },
];
