import React, { FC } from 'react';
import { StatusBar } from '../../../common/StatusBar';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../data/store';
import { CusipCell } from '../../../common/List/CusipCell';
import {
  setAllFundsOrdering,
  fetchFundsAndEtfsFlow,
} from '../../../data/funds';
import qs from 'query-string';
import { AddDocument } from '../AddDocumentDialog';
import { formatDate } from '../../../utils/format-date';
import Table, { TableColumnsProps } from '../../../common/Table/Table';
import Cell from '../../../common/Table/Cell';
import TableRow from '../../../common/Table/TableRow';
import { formatNumberWithComma } from '../../../utils/formatNumberWithComma';

interface RowProps {
  index: number;
  issuer: string;
}

const Row: FC<RowProps> = ({ issuer }) => {
  const fund = useSelector((state: AppState) => state.funds.issuers[issuer]);
  const cusips = fund.securities.map((security) => security.cusip);

  return (
    <TableRow to={`old/issuers/funds/${fund.id}`} variant="blue">
      <Cell>{fund.name}</Cell>
      <Cell>
        <CusipCell cusips={cusips} />
      </Cell>
      <Cell align="right">
        {formatDate(fund.stats.prospectusEstimatedEndDate)}
      </Cell>
      <Cell align="right">
        {fund.stats.totalPositions
          ? formatNumberWithComma(fund.stats.totalPositions)
          : null}
      </Cell>
    </TableRow>
  );
};

const AllFunds = () => {
  const { count, next, ordering, previous, issuers } = useSelector(
    (state: AppState) => state.funds.all,
  );
  const { isLoading } = useSelector((state: AppState) => state.funds);
  const dispatch = useDispatch();

  const handleSetOrdering = (order: string) => {
    dispatch(setAllFundsOrdering(order));
  };

  const handleChangePage = (path: string) => {
    const {
      limit,
      offset,
      ordering,
      search,
    }: {
      limit?: string;
      offset?: string;
      ordering?: string;
      search?: string;
    } = qs.parse(path);
    dispatch(
      fetchFundsAndEtfsFlow({
        limit,
        offset,
        ordering,
        search,
      }),
    );
  };

  return (
    <>
      <StatusBar
        count={count}
        next={next}
        onChangePage={handleChangePage}
        previous={previous}
        typeLabel="Funds"
      />

      <Table
        columns={columns}
        isLoading={isLoading}
        onSetOrdering={handleSetOrdering}
        ordering={ordering}
        variant="blue"
      >
        {issuers.map((issuer, index) => (
          <Row key={issuer} issuer={issuer} index={index} />
        ))}
      </Table>

      <AddDocument />
    </>
  );
};

export { AllFunds };

const columns: TableColumnsProps[] = [
  {
    name: 'Fund',
    key: 'fund',
    orderingKey: 'name',
  },
  {
    name: 'CUSIP',
    key: 'cusip',
  },
  {
    name: 'Prosp Exp Date',
    key: 'prosp-exp-date',
    orderingKey: 'stats__prospectus_estimated_end_date',
    align: 'right',
  },
  {
    name: 'Positions',
    key: 'positions',
    orderingKey: 'stats__total_positions',
    align: 'right',
  },
];
