import React, {
  MouseEvent,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Modal } from '../common/Modal';
import { Dialog } from '../common/Dialog/Dialog';
import { Formik, FormikProps, Field, FieldProps, Form } from 'formik';
import {
  DocumentType,
  Document,
  CorporateActionDocumentType,
} from '../models/document';
import Yup from 'yup';
import { InputGroup } from '../common/InputGroup';
import { Label } from '../common/Label';
import { Select } from '../common/Select';
import { Asterisk } from '../common/Asterik/Asterik';
import { Input } from '../common/Input';
import { CusipTypeaheadWithoutApi } from '../common/VoteFormComponents/CusipTypeaheadWithoutApi';
import { OutlineButton } from '../common/Buttons/OutlineButton';
import { Button } from '../common/Buttons/Button';
import styled from 'styled-components/macro';
import { privateApi, privateApiAsFormData } from '../utils/api-adapter';
import { FilingContext } from '../data/Filing.Context';
import { capitalize } from '../utils/capitalize';
import { Flex } from '@rebass/grid';
import { Text } from '../common/Text';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { FilingType } from '../models/filing-type';

const EditDocumentFormForm = styled(Form)`
  width: 100%;
`;

const EditDocumentFormButtons = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  margin-top: 32px;
`;

const FileDropWrapper = styled.div`
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  margin: 32px 0;
  padding: 32px;
`;

const FileDropText = styled.p`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
`;

interface Security {
  id: number;
  cusip: string;
  name: string;
}

interface DocumentFormValues {
  documentTitle: string;
  documentType: DocumentType | CorporateActionDocumentType;
  newDocument?: File;
  securities: Security[];
  shouldDistribute: boolean;
}

export const EditDocumentForm = () => {
  const {
    documentIdEditing,
    filing,
    isEditDocumentDialogOpen,
    setDocumentIdEditing,
    setEditDocumentDialogOpen,
    updateDocument,
  } = useContext(FilingContext);
  const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
  const [document, setDocument] = useState<Document>({} as Document);

  useEffect(() => {
    if (documentIdEditing && filing && filing.documents) {
      const foundDocument = filing.documents.find(
        (document) => document.id === documentIdEditing,
      );

      if (foundDocument) {
        setDocument(foundDocument);
      }
    }

    return () => {
      setDocumentIdEditing(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentIdEditing]);

  const editDocument = async (values: DocumentFormValues) => {
    try {
      setIsDocumentLoading(true);
      const attachmentPayload = {
        filing: filing && filing.id,
        securities: values.securities.map((security) => security.id),
        name: values.documentTitle,
        type: values.documentType,
        shouldDistributeInNextMailing: values.shouldDistribute,
      };
      const attachmentsResponse = await privateApi.patch(
        `/attachments/${document.id}/`,
        attachmentPayload,
      );
      updateDocument(attachmentsResponse.data);

      if (values.newDocument) {
        const formData = new FormData();
        formData.append(
          'file',
          values.newDocument as File,
          (values.newDocument as File).name,
        );
        const uploadResponse = await privateApiAsFormData.post(
          `/attachments/${attachmentsResponse.data.id}/upload/`,
          formData,
        );
        updateDocument(uploadResponse.data);
      }
      setIsDocumentLoading(false);
      setEditDocumentDialogOpen(false);
    } catch (error) {
      toast.error(error);
      setIsDocumentLoading(false);
    }
  };

  // find security on filing given a document.security id
  const initialSecurities = useMemo(() => {
    if (document.securities) {
      return document.securities.reduce((acc: Security[], curr: number) => {
        if (filing) {
          const foundSecuirty = filing.securities.find(
            (filingSecurity) => filingSecurity.id === curr,
          );

          if (foundSecuirty) {
            acc.push(foundSecuirty);
          }
        }

        return acc;
      }, []);
    }
  }, [document.securities, filing]);

  return (
    filing && (
      <Modal
        isOpen={isEditDocumentDialogOpen}
        onRequestClose={() => setEditDocumentDialogOpen(false)}
      >
        <Dialog
          title="Edit Document"
          onRequestClose={() => setEditDocumentDialogOpen(false)}
        >
          <Formik
            initialValues={{
              securities: initialSecurities || [],
              documentType: document.type,
              documentTitle: document.name || '',
              shouldDistribute: document.shouldDistributeInNextMailing || false,
            }}
            enableReinitialize={true}
            validationSchema={() =>
              Yup.object().shape({
                securities: Yup.array().required(),
                documentType: Yup.string().required(),
                documentTitle: Yup.string().required(),
                shouldDistribute: Yup.bool().required(),
              })
            }
            onSubmit={editDocument}
            render={(formikBag: FormikProps<DocumentFormValues>) => (
              <EditDocumentFormForm>
                <Field
                  name="documentType"
                  render={({ field, form }: FieldProps<DocumentFormValues>) => (
                    <InputGroup>
                      <Label>
                        Document Type <Asterisk />
                      </Label>
                      <Select {...field}>
                        {Object.values(
                          filing.type === FilingType.CorporateAction
                            ? CorporateActionDocumentType
                            : DocumentType,
                        ).map((documentType) => (
                          <option key={documentType} value={documentType}>
                            {capitalize(documentType.split('_').join(' '))}
                            {(documentType === 'summary' ||
                              documentType === 'statutory') &&
                              ' Prospectus'}
                          </option>
                        ))}
                      </Select>
                    </InputGroup>
                  )}
                />

                <Field
                  name="documentTitle"
                  render={({ field, form }: FieldProps<DocumentFormValues>) => (
                    <InputGroup>
                      <Label>
                        Document Title <Asterisk />
                      </Label>
                      <Input {...field} placeholder="Title" />
                    </InputGroup>
                  )}
                />

                {filing && (
                  <CusipTypeaheadWithoutApi cusips={filing.securities} />
                )}

                <Field
                  name="shouldDistribute"
                  render={({ field, form }: FieldProps<DocumentFormValues>) => (
                    <InputGroup>
                      <Label>
                        Distribute <Asterisk />
                      </Label>
                      <Select {...field}>
                        <option value="true">Distribute to Shareholders</option>
                        <option value="false">
                          Don't Distribute to Shareholders
                        </option>
                      </Select>
                    </InputGroup>
                  )}
                />

                <Field
                  name="newDocument"
                  render={({ field, form }: FieldProps<DocumentFormValues>) =>
                    field.value ? (
                      <>
                        <Text
                          color="black"
                          fontSize={0}
                          fontWeight={500}
                          letterSpacing="body"
                          mt={3}
                        >
                          New Document
                        </Text>
                        <Flex
                          p={2}
                          my={2}
                          bg="background"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text color="blue" fontSize={0} letterSpacing="body">
                            {field.value.name}
                          </Text>

                          <button
                            onClick={(event: MouseEvent<HTMLButtonElement>) => {
                              event.preventDefault();
                              formikBag.setFieldValue('newDocument', null);
                            }}
                          >
                            x
                          </button>
                        </Flex>
                      </>
                    ) : (
                      <Dropzone
                        onDrop={(acceptedFiles: File[]) =>
                          form.setFieldValue('newDocument', acceptedFiles[0])
                        }
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps, isDragActive }) => {
                          return (
                            <>
                              <Text
                                color="black"
                                fontSize={0}
                                fontWeight={500}
                                letterSpacing="body"
                                mt={3}
                              >
                                Existing Document
                              </Text>
                              <Flex
                                p={2}
                                my={2}
                                bg="background"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <a
                                  href={
                                    document.attachment
                                      ? document.attachment
                                      : '#'
                                  }
                                >
                                  <Text
                                    color="blue"
                                    fontSize={0}
                                    letterSpacing="body"
                                  >
                                    {document.name}
                                  </Text>
                                </a>
                              </Flex>

                              <FileDropWrapper {...getRootProps()}>
                                <input {...getInputProps()} />
                                <FileDropText>
                                  {isDragActive
                                    ? 'Drop file here'
                                    : 'Drag a file here or click to replace'}
                                </FileDropText>
                              </FileDropWrapper>
                            </>
                          );
                        }}
                      </Dropzone>
                    )
                  }
                />

                <EditDocumentFormButtons>
                  <OutlineButton
                    type="button"
                    onClick={() => setEditDocumentDialogOpen(false)}
                  >
                    Cancel
                  </OutlineButton>
                  <Button
                    disabled={!formikBag.isValid || isDocumentLoading}
                    type="submit"
                    onClick={(event: MouseEvent<HTMLButtonElement>) => {
                      event.preventDefault();
                      formikBag.submitForm();
                    }}
                  >
                    {isDocumentLoading ? 'Uploading...' : 'Upload'}
                  </Button>
                </EditDocumentFormButtons>
              </EditDocumentFormForm>
            )}
          />
        </Dialog>
      </Modal>
    )
  );
};
