import React from 'react';

type SectionTitleProps = {
  children: React.ReactNode;
};

function SectionTitle({ children }: SectionTitleProps) {
  return (
    <h2 className="text-base font-medium text-primary-text mb-4 capitalize">
      {children}
    </h2>
  );
}

export { SectionTitle };
