import React, { FC, ReactNode } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Button } from '../../common/Buttons/Button';
import styled from 'styled-components/macro';
import { Search } from '../../common/Search/Search';
import { Navigation } from '../../common/Navigation';
import { SayLogotype } from '../../common/SayLogotype';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../data/store';
import { toggleDocumentDialog, setFundsSearchTerm } from '../../data/funds';
import { userIdEvent } from '../../utils/analytics-helpers';

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 73px 1fr;
  grid-template-areas:
    'header header'
    'nav body';
`;

const Header = styled.header`
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  padding: 16px 0;
  position: sticky;
  top: 0;
  z-index: 1;
  grid-area: header;
`;

const ComponentContainer = styled.div`
  grid-area: body;
`;

const FundsLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { search } = useSelector((state: AppState) => state.funds);
  const dispatch = useDispatch();

  const handleSearch = (searchTerm: string) => {
    dispatch(setFundsSearchTerm(searchTerm));
  };

  const handleAddDocumentClick = () => {
    userIdEvent('Create Fund Dialog Clicked', {});
    dispatch(toggleDocumentDialog());
  };

  return (
    <Container>
      <Navigation />

      <Header>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          px={3}
          width={1}
        >
          <Flex alignItems="center" width={1 / 3}>
            <SayLogotype />

            <Box ml={4} width={1}>
              <Search onSearch={handleSearch} searchTerm={search} />
            </Box>
          </Flex>

          <Flex width={2 / 3} justifyContent="flex-end">
            <Button onClick={handleAddDocumentClick}>Create Fund</Button>
          </Flex>
        </Flex>
      </Header>

      <ComponentContainer>{children}</ComponentContainer>
    </Container>
  );
};

export { FundsLayout };
