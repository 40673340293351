import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    rootDialog: {
      position: 'absolute',
      overflow: 'hidden',
    },
    backdrop: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,.8)',
      width: '100vw',
      height: '100vh',
    },
    dialogContainer: {
      maxHeight: '90vh',
      overflow: 'hidden',
    },
    dialogContent: {
      '& > *': {
        margin: '12px 0',
      },
    },
  }),
);

type DeleteDialogProps = {
  isOpen: boolean;
  onConfirmDelete: () => void;
  onRequestClose: () => void;
  deleteType: Option;
};

type Option = 'filing' | 'prospectus' | 'document';

export const DeleteConfirmationDialog = ({
  isOpen,
  onConfirmDelete,
  onRequestClose,
  deleteType,
}: DeleteDialogProps) => {
  const classes = useStyles();

  const renderWarningText = (deleteType: Option) => {
    switch (deleteType) {
      case 'filing':
        return 'Are you sure you want to delete this filing?';
      case 'document':
        return 'Are you sure you want to delete this document?';
      case 'prospectus':
        return 'Are you sure you want to delete this document?';
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={onRequestClose}
        classes={{
          root: classes.rootDialog,
          container: classes.dialogContainer,
        }}
        BackdropProps={{
          classes: { root: classes.backdrop },
        }}
        style={{ position: 'absolute' }}
        className={classes.rootDialog}
      >
        <DialogTitle>Delete</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {renderWarningText(deleteType)}
        </DialogContent>

        <DialogActions>
          <Button onClick={onRequestClose} type="button">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              onConfirmDelete();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
