import React from 'react';
import { Changeset, OperationsStatus } from '../types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
} from '@material-ui/core';
import styled from 'styled-components';
import { Edit, ExpandMore, CheckCircle } from '@material-ui/icons';
import { format } from 'date-fns';
import { StatusBadge } from '../StatusBadge';
import { ChangesetRowDetails } from './ChangesetRowDetails';

interface Props {
  changeset: Changeset;
  status: OperationsStatus;
}

function ChangesetRow({ changeset, status }: Props) {
  const date = format(
    new Date(changeset.created),
    'EEEE, MMMM, d, yyyy h:mm a',
  );
  const initials = changeset.user
    ? `${changeset.user.firstName.charAt(0)} ${changeset.user.lastName.charAt(
        0,
      )}`
    : 'S';
  const firstName = changeset.user ? changeset.user.firstName : 'System';
  const isStatusUpdate = 'operationsStatus' in changeset.before;
  const beforeEntries = Object.entries(changeset.before);
  const afterEntries = Object.entries(changeset.after);

  function formatTitle() {
    switch (changeset.type) {
      case 'filing_created':
        return `${firstName} created a New Filing`;
      case 'attribute': {
        if (isStatusUpdate) {
          return `${firstName} marked ${changeset.after.operationsStatus
            .split('_')
            .join(' ')}`;
        } else {
          return `${firstName} edited ${formatTabsText()}`;
        }
      }
      case 'security':
        return `${firstName} updated a security`;
      case `vote_proposal_created`:
        return `${firstName} created a vote proposal`;
      case `vote_proposal_deleted`:
        return `${firstName} deleted a vote proposal`;
      case `vote_proposal_modified`:
        return `${firstName} edited a vote proposal`;
    }
  }

  function formatTabsText() {
    return beforeEntries.reduce((text, field) => {
      const fieldName = field[0];
      const tabName = formFieldToTabMap[fieldName];

      if (text.includes(fieldName)) {
        return text;
      } else {
        if (text.length) {
          return `${text}, ${tabName}`;
        } else {
          return `${text}${tabName}`;
        }
      }
    }, '');
  }

  return (
    <Wrapper>
      <StyledAccordion square={true}>
        <StyledAccordionSummary expandIcon={<ExpandMore />}>
          {isStatusUpdate ? <StyledCheckCircleIcon /> : <StyledEditIcon />}
          <StyledAvatar>{initials}</StyledAvatar>
          <div>
            <Title>{formatTitle()}</Title>
            <DateTime>{date}</DateTime>
          </div>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <ChangesetRowDetails
            beforeEntries={beforeEntries}
            afterEntries={afterEntries}
            type={changeset.type}
          />
        </StyledAccordionDetails>
      </StyledAccordion>

      <BadgeWrapper>
        <StatusBadge filingStatus={status} />
      </BadgeWrapper>
    </Wrapper>
  );
}

interface FormFieldToTabMap {
  [index: string]: 'Details' | 'Ballot' | 'Materials' | 'Meeting';
}

const formFieldToTabMap: FormFieldToTabMap = {
  additionalRecordDates: 'Details',
  brokerSearchReceivedDate: 'Details',
  companyContacts: 'Details',
  deliveryMethod: 'Details',
  digitalMaterialsExpectedDate: 'Details',
  invoicerAddress: 'Details',
  invoicerContactEmail: 'Details',
  invoicerAddressLine1: 'Details',
  invoicerAddressLine2: 'Details',
  invoicerAddressLine3: 'Details',
  invoicerCity: 'Details',
  invoicerState: 'Details',
  invoicerPostalCode: 'Details',
  invoicerCountry: 'Details',
  invoicerCompany: 'Details',
  invoicerContact: 'Details',
  invoicerNotes: 'Details',
  issuer: 'Details',
  notes: 'Details',
  paperMaterialsReceivedDate: 'Details',
  recordDate: 'Details',
  securities: 'Details',
  solicitor: 'Details',
  solicitorJobNumber: 'Details',
  tabulator: 'Details',
  type: 'Details',
  voteProposals: 'Ballot',
  documents: 'Materials',
  meeting: 'Meeting',
  voteCutoffDate: 'Meeting',
  voteCutoffTime: 'Meeting',
  voteCutoffTimezone: 'Meeting',
  voteCutoffDatetime: 'Meeting',
};

const Wrapper = styled.div`
  border-bottom: 1px solid #e5e5e5;
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none;

  &:before {
    background-color: transparent;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    display: grid;
    grid-template-columns: 24px 24px 1fr;
    column-gap: 16px;
  }
`;

const StyledEditIcon = styled(Edit)`
  color: #00000080;
  margin: 0;
`;

const StyledCheckCircleIcon = styled(CheckCircle)`
  color: #11cc99;
  margin: 0;
`;

const StyledAvatar = styled(Avatar)`
  background: #000000de;
  width: 24px;
  height: 24px;
  margin: 0;
  font-weight: 500;
  font-size: 10px;
`;

const Title = styled.p`
  font-size: 14px;
  color: #000000;
  display: block;
  text-decoration: capitalize;
`;

const DateTime = styled.p`
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #12121261;
  display: block;
`;

const BadgeWrapper = styled.div`
  margin-top: 5px;
  margin-left: 96px;
  margin-bottom: 16px;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0 0 0 96px;
`;

export { ChangesetRow };
