import { SECFilingStatus } from './sec-filing-list';
import { FilingDetails } from './filing-details';

export enum requestType {
  POST = 'post',
  PATCH = 'patch',
}

export interface ISECFiling {
  id: number;
  created: string;
  filing: FilingDetails;
  filingDate: string | null;
  modified: string;
  status: SECFilingStatus;
  type: string;
  url: string;
}

export interface IChoiceResults {
  requestType: requestType;
  voteProposalId: number;
  choice: string;
  totalShares: string;
  votedAtMeeting: boolean;
  meetingResultId?: number;
}

// NOTE: higher level key based on proposal id; lower level vote choice
export interface ISECFilingProposalResults {
  [key: string]: {
    [key: string]: IChoiceResults;
  };
}

export interface ISECFilingForm {
  status: SECFilingStatus;
  meetingResults: ISECFilingProposalResults;
}
