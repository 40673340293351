import { ICusip } from '../../models/vote-proposal';
import { Security } from '../../models/filing';

export const cusip = (
  securities: Security[],
  securitiesInProposal: number[],
): ICusip[] => {
  const foundSecurities: Security[] = [];

  securitiesInProposal.map((securityId: number) => {
    const foundSecurity = securities.find(
      (security) => security.id === securityId,
    );

    return foundSecurity && foundSecurities.push(foundSecurity);
  });

  return foundSecurities.map((foundSecurity) => {
    return {
      id: foundSecurity.id,
      name: foundSecurity.name,
      cusip: foundSecurity.cusip,
    };
  });
};
