import { createContainer } from 'unstated-next';
import { useImmer } from 'use-immer';
import {
  FilingListResponse,
  FilingListFiling,
  OperationsStatus,
} from '../models/filing-list';
import { privateApi } from '../utils/api-adapter';
import { toast } from 'react-toastify';
import qs from 'query-string';
import humps from 'humps';

enum TabChoice {
  InProgress = 'ca_in_progress',
  ReviewEmails = 'ca_review_emails',
  OnGoing = 'ca_ongoing',
}

interface CorporateActionsState {
  inProgress: {
    filings: FilingListResponse;
  };
  reviewEmails: {
    filings: FilingListResponse;
  };
  ongoing: {
    filings: FilingListResponse;
  };
  isLoading: boolean;
  hasData: boolean;
  search: string;
  isNewFilingDialogOpen: boolean;
}

const initialCorporateActionsState: CorporateActionsState = {
  inProgress: {
    filings: {
      count: 0,
      next: null,
      previous: null,
      results: [] as FilingListFiling[],
    },
  },
  ongoing: {
    filings: {
      count: 0,
      next: null,
      previous: null,
      results: [] as FilingListFiling[],
    },
  },
  reviewEmails: {
    filings: {
      count: 0,
      next: null,
      previous: null,
      results: [] as FilingListFiling[],
    },
  },
  isLoading: false,
  hasData: false,
  search: '',
  isNewFilingDialogOpen: false,
};

const useCorporateActions = () => {
  const [corporateActions, updateCorporateActions] = useImmer<
    CorporateActionsState
  >(initialCorporateActionsState);

  const updateIsLoading = (isLoading: boolean) => {
    updateCorporateActions((draft) => {
      draft.isLoading = isLoading;
    });
  };

  const getTasksFilingByTab = async (
    tab: TabChoice,
    order: string,
    search: string,
  ) => {
    const camelizedTab = humps.camelize(tab.replace('ca', ''));
    const orderingQuery = `&ordering=${order}`;
    const searchQuery = search ? `&search=${encodeURIComponent(search)}` : '';
    try {
      updateIsLoading(true);
      const filingsReponse = await privateApi.get(
        `/filings/?tab=${tab}${searchQuery}${orderingQuery}`,
      );

      updateCorporateActions((draft) => {
        // @ts-ignore
        draft[camelizedTab].filings = filingsReponse.data;
        draft.isLoading = false;
        draft.hasData = true;
      });
    } catch (error) {
      updateIsLoading(false);
      toast.error('error fetching corporate actions');
    }
  };

  const changePage = async (path: string) => {
    const { tab } = qs.parse(path);
    const url = path.split('/v1')[1];

    try {
      updateIsLoading(true);
      const { data } = await privateApi.get(url);
      const type = humps.camelize((tab as string).replace('ca', ''));
      updateCorporateActions((draft) => {
        // @ts-ignore
        draft[type as TabChoice].filings = data;
        draft.isLoading = false;
        draft.hasData = true;
      });
    } catch (error) {
      updateIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      }
    }
  };

  const markReadyForReview = async (
    filingId: number,
    operationsStatus: OperationsStatus.ReadyForReview,
  ) => {
    try {
      await privateApi.patch(`/filings/${filingId}/update-status/`, {
        operationsStatus,
      });
      updateCorporateActions((draft) => {
        const { results } = draft.inProgress.filings;
        results[
          results.findIndex((result) => result.id === filingId)
        ].operationsStatus = operationsStatus;
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      }
    }
  };

  const setSearch = (search: string) =>
    updateCorporateActions((draft) => {
      draft.search = search;
    });

  const unmarkReadyForReview = async (
    filingId: number,
    operationsStatus: OperationsStatus.ReadyForReview,
  ) => {
    try {
      await privateApi.patch(`/filings/${filingId}/unmark-status/`, {
        operationsStatus,
      });
      updateCorporateActions((draft) => {
        const { results } = draft.inProgress.filings;
        results[
          results.findIndex((result) => result.id === filingId)
        ].operationsStatus = OperationsStatus.Active;
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      }
    }
  };

  const setIsNewFilingDialogOpen = (isOpen: boolean) =>
    updateCorporateActions((draft) => {
      draft.isNewFilingDialogOpen = isOpen;
    });

  return {
    changePage,
    corporateActions,
    getTasksFilingByTab,
    markReadyForReview,
    setSearch,
    unmarkReadyForReview,
    setIsNewFilingDialogOpen,
  };
};

const CorporateActions = createContainer(useCorporateActions);

export { CorporateActions, useCorporateActions };
