import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  Tabs as ReachTabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab as ReachTab,
} from '@reach/tabs';
import { DocumentTableRow } from './DocumentTableRow';
import styled from 'styled-components/macro';
import { Flex, Box } from '@rebass/grid';
import { useDispatch } from 'react-redux';
import { toggleDocumentDialog } from '../../../data/funds';
import { Issuer } from '../../../models/prospectus/issuer';

const InfoBar = styled.div`
  background: #595959;
  color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: repeat(2, auto);
  font-size: 14px;
  font-weight: 300;
  grid-gap: 32px;
  padding: 8px 16px;
`;

const InfoBarValue = styled.span`
  color: ${({ theme }) => theme.colors.green};
`;

const Documents = styled.div`
  background: #fff;
  padding: 30px;
`;

const AddDocumentButton = styled.button`
  background: transparent;
  border: 1px dashed ${({ theme }) => theme.colors.background};
  font-size: 12px;
  font-weight: 500;
  padding: 20px;
  text-align: left;
  transition: border-color 0.25s ease;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.colors.gray};
    cursor: pointer;
  }
`;

const StyledTabPanels = styled(TabPanels)`
  margin-top: 15px;
`;

const StyledTab = styled(ReachTab)`
  background: transparent;
  border: none;
  font-size: 12px;
  padding: 0;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &[data-selected] {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    font-weight: 500;
  }
`;

const List = styled.div`
  width: 100%;
`;

const ListHeaderWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.background};
  font-size: 10px;
  font-weight: 600;
  padding: 15px;
`;

const NoDocumentsWrapper = styled(Flex)`
  min-width: 1400px;
`;

const ListHeader = () => {
  return (
    <ListHeaderWrapper>
      <Box width={1 / 8}>Doc Name</Box>
      <Box width={1 / 8}>Doc Type</Box>
      <Box width={1 / 8}>CUSIP(s)</Box>
      <Box width={1 / 8}>Effective Date</Box>
      <Box width={1 / 8}>Prosp Exp Date</Box>
      <Box width={1 / 8}>Url</Box>
      <Box width={1 / 8}>Front Link</Box>
      <Box width={1 / 8} />
    </ListHeaderWrapper>
  );
};

const NoDocuments = () => (
  <NoDocumentsWrapper justifyContent="center" p={4}>
    No Documents. Click below to add one.
  </NoDocumentsWrapper>
);

interface ProspectusDetailsProps extends RouteComponentProps {
  issuer: Issuer;
}

const ProspectusDetails: FC<ProspectusDetailsProps> = ({ issuer }) => {
  const dispatch = useDispatch();
  const handleAddDocumentClick = () => dispatch(toggleDocumentDialog());

  return issuer.prospectuses ? (
    <>
      <InfoBar>
        <span>
          Positions: <InfoBarValue>{issuer.stats.totalPositions}</InfoBarValue>
        </span>

        <span>
          Positions with Paper Preference:{' '}
          <InfoBarValue>
            {issuer.stats.totalPaperPositions
              ? issuer.stats.totalPaperPositions
              : 0}
          </InfoBarValue>
        </span>
      </InfoBar>

      <Documents>
        <ReachTabs>
          <TabList>
            <StyledTab type="button">
              Active ({issuer.prospectuses && issuer.prospectuses.active.length}
              )
            </StyledTab>
            <StyledTab type="button">
              Upcoming ({issuer.prospectuses.upcoming.length})
            </StyledTab>
            <StyledTab type="button">
              Archived ({issuer.prospectuses.archived.length})
            </StyledTab>
          </TabList>

          <StyledTabPanels>
            <TabPanel>
              <List>
                <ListHeader />
                {issuer.prospectuses.active.length ? (
                  issuer.prospectuses.active.map((prospectus) => (
                    <DocumentTableRow
                      key={prospectus}
                      prospectusId={prospectus}
                      issuerId={issuer.id}
                      issuerSecurities={issuer.securities.map((security) => ({
                        id: security.id,
                        cusip: security.cusip,
                      }))}
                      status="active"
                    />
                  ))
                ) : (
                  <NoDocuments />
                )}
              </List>
            </TabPanel>
            <TabPanel>
              <List>
                <ListHeader />
                {issuer.prospectuses.upcoming.length ? (
                  issuer.prospectuses.upcoming.map((prospectus) => (
                    <DocumentTableRow
                      key={prospectus}
                      prospectusId={prospectus}
                      issuerId={issuer.id}
                      issuerSecurities={issuer.securities.map((security) => ({
                        id: security.id,
                        cusip: security.cusip,
                      }))}
                      status="upcoming"
                    />
                  ))
                ) : (
                  <NoDocuments />
                )}
              </List>
            </TabPanel>
            <TabPanel>
              <List>
                <ListHeader />
                {issuer.prospectuses.archived.length ? (
                  issuer.prospectuses.archived.map((prospectus) => (
                    <DocumentTableRow
                      key={prospectus}
                      prospectusId={prospectus}
                      issuerId={issuer.id}
                      issuerSecurities={issuer.securities.map((security) => ({
                        id: security.id,
                        cusip: security.cusip,
                      }))}
                      status="archived"
                    />
                  ))
                ) : (
                  <NoDocuments />
                )}
              </List>
            </TabPanel>
          </StyledTabPanels>
        </ReachTabs>

        <AddDocumentButton onClick={handleAddDocumentClick} type="button">
          + Add Document
        </AddDocumentButton>
      </Documents>
    </>
  ) : null;
};

export { ProspectusDetails };
