import * as Yup from 'yup';
import { OperationsStatus, FilingType } from './types';

export const draftValidationSchema = Yup.object().shape({
  type: Yup.string().required('Type is required'),
  issuer: Yup.string().required('Issuer is required'),
  cusips: Yup.string().required('At least one CUSIP is required'),
  brokerSearchReceivedDate: Yup.string()
    .required('Broker Search Date cannot be blank')
    .nullable(),
  recordDate: Yup.string().required('Record Date cannot be blank').nullable(),
  solicitor: Yup.object().required('You must include a solicitor'),
  replyTo: Yup.array()
    .of(Yup.object())
    .min(1, 'At least one contact is required'),
  deliveryMethod: Yup.string().required('Delivery Method cannot be blank'),
});

const baseValidationSchema = draftValidationSchema.shape(
  {
    invoicerContactEmail: Yup.string().when('invoicerAddress', {
      is: (val) => !!val,
      then: Yup.string(),
      otherwise: Yup.string()
        .email()
        .required('A valid invoicer email address is required'),
    }),
    invoicerAddress: Yup.string().when('invoicerContactEmail', {
      is: (val) => !!val,
      then: Yup.string(),
      otherwise: Yup.string().required('Invoicer Address is required'),
    }),
    fundOwner: Yup.string().when('type', {
      is: (val) => val === 'FundReport' || val === 'FundInformationStatement',
      then: Yup.string().required('Fund Owner is required'),
      otherwise: Yup.string().nullable(),
    }),
    reportType: Yup.string().when('type', {
      is: (val) => val === 'FundReport',
      then: Yup.string().required('Report type is required'),
      otherwise: Yup.string().nullable(),
    }),
    digitalMaterialsReceivedDate: Yup.string()
      .required('Digital Received Date is required')
      .nullable(),
  },
  [['invoicerContactEmail', 'invoicerAddress']],
);

const votingValidationSchema = baseValidationSchema.shape({
  tabulator: Yup.object().required('You must include a tabulator'),
  sendTo: Yup.array()
    .of(Yup.object())
    .min(1, 'At least one contact is required'),
  voteCutoffDate: Yup.string()
    .required('Vote Cutoff Date cannot be blank')
    .nullable(),
  voteCutoffTime: Yup.string().required('Vote Cutoff Time cannot be blank'),
  voteCutoffTimezone: Yup.string().required('Vote Cutoff Date cannot be blank'),
});

const meetingValidationSchema = votingValidationSchema.shape({
  meetingType: Yup.string().required('Meeting Type cannot be blank'),
  meetingFormat: Yup.string().required('Meeting Format cannot be blank'),
  meetingDate: Yup.string().required('Meeting Date cannot be blank').nullable(),
  meetingTime: Yup.string().required('Meeting Time cannot be blank').nullable(),
  meetingTimezone: Yup.string().required('Meeting Timezone cannot be blank'),
  meetingAddress: Yup.string().when('meetingFormat', {
    is: (value) => value === 'physical' || value === 'hybrid',
    then: Yup.string().required('Address is required'),
    otherwise: Yup.string().nullable(),
  }),
  virtualMeetingUrl: Yup.string().when('meetingFormat', {
    is: (value) => value === 'online' || value === 'hybrid',
    then: Yup.string().required('URL is required'),
    otherwise: Yup.string().nullable(),
  }),
});

export const getValidationSchemaByStatus = (
  filingType: FilingType,
  status: OperationsStatus,
  updateStatus: boolean,
) => {
  const mapTypeToSchema = new Map([
    [FilingType.FirmConsentSolicitation, votingValidationSchema],
    [FilingType.FirmInformationStatement, baseValidationSchema],
    [FilingType.FirmMeeting, meetingValidationSchema],
    [FilingType.FundInformationStatement, baseValidationSchema],
    [FilingType.FundMeeting, meetingValidationSchema],
    [FilingType.FundReport, baseValidationSchema],
    [FilingType.MeetingContest, meetingValidationSchema],
  ]);
  switch (status) {
    case OperationsStatus.Draft:
      return draftValidationSchema;
    case OperationsStatus.ReadyForReview:
    case OperationsStatus.Approved:
    case OperationsStatus.Closed:
      return mapTypeToSchema.get(filingType);
    default:
      return updateStatus
        ? mapTypeToSchema.get(filingType)
        : draftValidationSchema;
  }
};

export const documentValidationSchema = Yup.object().shape({
  securities: Yup.array().required(),
  document: Yup.mixed().required(),
  documentType: Yup.string().required(),
  documentTitle: Yup.string().required(),
});

export const editDocumentValidationSchema = Yup.object().shape({
  securities: Yup.array().required(),
  documentType: Yup.string().required(),
  documentTitle: Yup.string().required(),
});
