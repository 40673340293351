import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box } from '@rebass/grid';
import { CompanyFiling, setFilingsListOrdering } from '../../../data/companies';
import { CusipCell } from '../../../common/List/CusipCell';
import { formatCamelCaseString } from '../../../utils/format-camel-case-string';
import { formatRecordDateRange } from '../../../utils/formatRecordDateRange';
import { formatNumberWithComma } from '../../../utils/formatNumberWithComma';
import { FilingType } from '../../../models/filing-type';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../data/store';
import { formatDate } from '../../../utils/format-date';
import Table, { TableColumnsProps } from '../../../common/Table/Table';
import TableRow from '../../../common/Table/TableRow';
import Cell from '../../../common/Table/Cell';

const FilingsList: FC<RouteComponentProps> = () => {
  const { filingsList } = useSelector((state: AppState) => state.companies);
  const { ordering, filings, isLoading } = filingsList;
  const dispatch = useDispatch();

  const handleSetOrdering = (ordering: string) =>
    dispatch(setFilingsListOrdering(ordering));

  return (
    <>
      <Box mt={3}>
        <Table
          columns={columns}
          isLoading={isLoading}
          onSetOrdering={handleSetOrdering}
          ordering={ordering}
          top={0}
          variant="gray"
        >
          {filings.map((filing, index) => (
            <Row filing={filing} index={index} key={filing.id} />
          ))}
        </Table>
      </Box>
    </>
  );
};

export { FilingsList };

interface RowProps {
  filing: CompanyFiling;
  index: number;
}

const Row: FC<RowProps> = ({ filing }) => {
  return (
    <TableRow
      to={`/old/filings/${filing.id}/mailings`}
      variant={filing.type === FilingType.CorporateAction ? 'gold' : 'green'}
    >
      <Cell>
        <CusipCell cusips={filing.cusips} />
      </Cell>
      <Cell>{formatCamelCaseString(filing.type)}</Cell>
      <Cell align="right">
        {formatRecordDateRange(filing.recordDateStart, filing.recordDateEnd)}
      </Cell>
      <Cell align="right">{formatNumberWithComma(filing.positions)}</Cell>
      <Cell align="right">
        {filing.meetingDate && formatDate(filing.meetingDate)}
      </Cell>
    </TableRow>
  );
};

const columns: TableColumnsProps[] = [
  {
    name: 'CUSIP',
    key: 'cusip',
  },
  {
    name: 'Filing Type',
    key: 'filing-type',
  },
  {
    name: 'Record Date',
    key: 'record-date',
    orderingKey: 'date',
    align: 'right',
  },
  {
    name: 'Positions',
    key: 'positions',
    orderingKey: 'stats__total_positions',
    align: 'right',
  },
  {
    name: 'Meeting Date',
    key: 'meeting-date',
    orderingKey: 'meeting_date',
    align: 'right',
  },
];
