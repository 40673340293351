import React from 'react';

type Props = {
  html: string | null;
};

export const EmailView = ({ html }: Props) => {
  function createMarkup() {
    return {
      __html: html ? html : '',
    };
  }

  return (
    <>
      <div className="p-8" dangerouslySetInnerHTML={createMarkup()} />
    </>
  );
};
