import React from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { ProposalType, Routineness, VoteType } from '../types';

const useStyles = makeStyles((theme) =>
  createStyles({
    proposalRow: {
      fontSize: '12px',
      color: theme.palette.text.primary,
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing(1.75)}px ${theme.spacing(3)}px`,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    body: {
      color: '#121212',
      fontSize: '12px',
    },
    bodyCapitalized: {
      textTransform: 'capitalize',
      color: '#121212',
      fontSize: '12px',
    },
  }),
);

type Props = {
  title: string;
  routineness: Routineness;
  numberOfProposals: number;
  recommendationType: string;
  voteType: VoteType;
  proposalType: ProposalType;
  children?: React.ReactNode;
};

function BallotDetailsProposalGroupRow({
  title,
  routineness,
  numberOfProposals,
  recommendationType,
  children,
  voteType,
  proposalType,
}: Props) {
  const classes = useStyles();

  return (
    <Grid
      container={true}
      spacing={1}
      alignItems="center"
      className={classes.proposalRow}
    >
      <Grid item={true} xs={4} className={classes.body}>
        {title}
      </Grid>
      <Grid item={true} xs={3} className={classes.bodyCapitalized}>
        {formatVoteChoices(voteType, proposalType)}
      </Grid>
      <Grid item={true} xs={3} className={classes.body}>
        {routineness === 'routine' ? 'Routine' : 'Non-Routine'}
      </Grid>
      <Grid item={true} xs={1} className={classes.body}>
        {numberOfProposals}
      </Grid>
      <Grid item={true} xs={1} className={classes.bodyCapitalized}>
        {recommendationType}
      </Grid>
      {children}
    </Grid>
  );
}

function formatVoteChoices(voteType: VoteType, proposalType: ProposalType) {
  if (proposalType === 'SayOnPay') {
    return 'One Year, Two Years, Three Years, Four Years';
  }

  switch (voteType) {
    case 'election_majority':
      return 'For, Against, Abstain';
    case 'election_majority_forabstain':
      return 'For, Abstain';
    case 'election_majority_foragainst':
      return 'For, Against';
    case 'election_majority_foragainstwithhold':
      return 'For, Against, Withhold';
    case 'election_majority_forwithholdabstain':
      return 'For, Withhold, Abstain';
    case 'election_majority_yesno':
      return 'Yes, No';
    case 'election_plurality':
      return 'For, Withhold';
    default:
      return 'None';
  }
}

export { BallotDetailsProposalGroupRow };
