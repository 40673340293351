import React, { FC, useState } from 'react';
import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { MultiSelect, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import {
  MenuItem,
  ITagProps,
  Intent,
  Button,
  PopoverPosition,
} from '@blueprintjs/core';
import { IFilingCreateValues } from '../../models/filing-form';
import { Asterisk } from '../Asterik/Asterik';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { IVoteProposalFormValues } from '../../models/vote-proposal';
import { ErrorLabel } from '../ErrorLabel';

interface ICusip {
  readonly id: number;
  readonly cusip: string;
  readonly name: string;
}

interface IProps {
  cusips: ICusip[];
}

const CusipMultiSelect = MultiSelect.ofType<ICusip>();

const renderTag = (cusip: ICusip) => {
  return cusip.cusip;
};

const filterCusip: ItemPredicate<ICusip> = (query, cusip) => {
  return `${cusip.cusip.toLocaleLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

export const CusipTypeaheadWithoutApi: FC<IProps> = ({ cusips }) => {
  const [query, setQuery] = useState<string>('');

  return (
    <Field
      name="securities"
      render={({
        field,
        form,
      }: FieldProps<IFilingCreateValues | IVoteProposalFormValues>) => {
        const deselectCusip = (index: number) => {
          const filteredCusips = field.value.filter(
            (cusip: ICusip, i: number) => i !== index,
          );
          form.setFieldValue('securities', filteredCusips);
        };

        const handleCusipSelect = (cusip: ICusip) => {
          if (!isCusipSelected(cusip)) {
            selectCusip(cusip);
          } else {
            deselectCusip(getSelectedCusipIndex(cusip));
          }
        };
        const selectCusip = (cusip: ICusip) => {
          const cusipsToAdd = [...field.value, cusip];
          form.setFieldValue('securities', cusipsToAdd);
        };

        const getTagProps = (): ITagProps => {
          return {
            intent: Intent.NONE,
            minimal: false,
          };
        };

        const handleTagRemove = (tag: string, index: number) => {
          deselectCusip(index);
        };

        const handleClear = () => {
          form.setFieldValue('securities', []);
        };

        const clearButton = (): JSX.Element => {
          return field.value.length > 0 ? (
            <Button icon="cross" minimal={true} onClick={handleClear} />
          ) : (
            <></>
          );
        };

        const getSelectedCusipIndex = (cusip: ICusip) => {
          return field.value
            .map((mappedCusip: ICusip) => mappedCusip.id)
            .indexOf(cusip.id);
        };

        const isCusipSelected = (cusip: ICusip) => {
          return getSelectedCusipIndex(cusip) !== -1;
        };

        const renderCusip: ItemRenderer<ICusip> = (
          cusip,
          { handleClick, modifiers },
        ) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }

          return (
            <MenuItem
              active={modifiers.active}
              icon={isCusipSelected(cusip) ? 'tick' : 'blank'}
              key={cusip.id}
              onClick={handleClick}
              text={cusip.cusip}
              shouldDismissPopover={false}
            />
          );
        };

        return (
          <InputGroup>
            <Label htmlFor="securities">
              CUSIPS(s) <Asterisk />
            </Label>
            {(form.errors as FormikErrors<IVoteProposalFormValues>)
              .securities &&
              (form.touched as FormikTouched<IVoteProposalFormValues>)
                .securities && (
                <ErrorLabel>
                  {
                    (form.errors as FormikErrors<IVoteProposalFormValues>)
                      .securities
                  }
                </ErrorLabel>
              )}
            <CusipMultiSelect
              itemPredicate={filterCusip}
              itemRenderer={renderCusip}
              items={cusips}
              noResults={<div>No Results</div>}
              onItemSelect={(cusip: ICusip) => {
                handleCusipSelect(cusip);
              }}
              onQueryChange={(typeQuery: string) => {
                setQuery(typeQuery);
              }}
              popoverProps={{
                position: PopoverPosition.BOTTOM,
              }}
              query={query}
              tagRenderer={renderTag}
              tagInputProps={{
                tagProps: getTagProps(),
                onRemove: (tag: string, index: number) => {
                  handleTagRemove(tag, index);
                },
                rightElement: clearButton(),
              }}
              selectedItems={field.value}
            />
          </InputGroup>
        );
      }}
    />
  );
};
