import { CorporateActionType, DocumentType } from './types';

export const corporateActionTypeOptions: {
  value: CorporateActionType | '';
  label: string;
  disabled?: boolean;
}[] = [
  {
    value: '',
    label: 'Select a type',
    disabled: true,
  },
  {
    value: 'ConsentSolicitation',
    label: 'Consent Solicitation',
  },
  {
    value: 'DividendOption',
    label: 'Dividend Option',
  },
  {
    value: 'DividendReinvestmentPlan',
    label: 'Dividend Reinvestment Plan',
  },
  {
    value: 'ExchangeOffer',
    label: 'Exchange Offer',
  },
  {
    value: 'MergerConsiderationElection',
    label: 'Merger Consideration Election',
  },
  {
    value: 'OddLotOffer',
    label: 'Odd Lot Offer',
  },
  {
    value: 'OfferToPurchaseBuyBack',
    label: 'Buyback',
  },
  {
    value: 'OfferToPurchaseThirdPartyOffer',
    label: 'Offer to Purchase',
  },
  {
    value: 'RightsIssue',
    label: 'Rights Issue',
  },
];

export const documentTypeOptions: { value: DocumentType; label: string }[] = [
  {
    value: 'amendment_to_offer_to_purchase',
    label: 'Amendment to Offer to Purchase',
  },
  {
    value: 'client_letter',
    label: 'Client Letter',
  },
  {
    value: 'letter_of_transmittal',
    label: 'Letter of Transmittal',
  },
  {
    value: 'merger_proposal',
    label: 'Merger Proposal',
  },
  {
    value: 'notice_19a',
    label: 'Notice 19A',
  },
  {
    value: 'notice_of_guaranteed_delivery',
    label: 'Notice of Guaranteed Delivery',
  },
  {
    value: 'offering_circular',
    label: 'Offering Circular',
  },
  {
    value: 'offer_to_purchase',
    label: 'Offer to Purchase',
  },
  {
    value: 'schedule_14d_9',
    label: 'Schedule 14D 9',
  },
  {
    value: 'schedule_to',
    label: 'Schedule To',
  },
  {
    value: 'shareholder_letter',
    label: 'Shareholder Letter',
  },
];

export const frequencyOptions: {
  value: string;
  label: string;
  disabled?: boolean;
}[] = [
  { value: '', label: 'Select a frequency', disabled: true },
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Quarterly', label: 'Quarterly' },
  { value: 'Annually', label: 'Annually' },
  { value: 'Semi-Annually', label: 'Semi-Annually' },
  { value: 'Irregular', label: 'Irregular' },
];
