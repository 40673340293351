import React from 'react';
import * as Yup from 'yup';
import { determineIfPastToday } from './index';
import { Prospectus } from '..';
import { formatDate } from '../../../../utils/format-date';
import { ActionButtons } from '../../ActionButtons';
import {
  Formik,
  Form,
  FormikProps,
  Field,
  FieldProps,
  FormikActions,
  ErrorMessage,
} from 'formik';
import { Input } from '../../../../components/Input';
import { DeleteConfirmationDialog } from '../../../../components/Dialog/DeleteConfirmationDialog';
import { addDays, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { hubApi } from '../../../../old/data/hubApiConfig';
import { toast } from 'react-toastify';
import { zonedTimeToUtc } from 'date-fns-tz';

type ProspectusRowProps = {
  isEditable: boolean;
  prospectus: Prospectus;
  onUpdateSuccess?: () => void;
};

type ProspectusFormValues = {
  type: string;
  name: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  website: string;
};

const prospectusTypeOptions = [
  {
    value: 'summary',
    label: 'Summary Prospectus',
  },
  {
    value: 'sticker',
    label: 'Sticker',
  },
  {
    value: 'statement_of_additional_information',
    label: 'Statement of Additional Information',
  },
  {
    value: 'statutory',
    label: 'Statutory Prospectus',
  },
];

const NY_TIME_ZONE_STRING = 'America/New_York';

const ProspectusRow = ({
  isEditable,
  prospectus,
  onUpdateSuccess = () => false,
}: ProspectusRowProps) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = React.useState(false);

  const handleChangeEffectiveDate = (
    date: Date,
    form: FormikProps<ProspectusFormValues>,
  ) => {
    const effectiveEndDate = addDays(date, 364);

    form.setFieldValue('effectiveStartDate', date);
    form.setFieldValue('effectiveEndDate', effectiveEndDate);
  };

  const handleCancelEditing = () => setIsEditing(false);
  const handleUpdateProspectus = async (
    values: ProspectusFormValues,
    actions: FormikActions<ProspectusFormValues>,
  ) => {
    try {
      actions.setSubmitting(true);
      await hubApi.patch(`/securities/prospectuses/${prospectus.id}/`, {
        name: values.name,
        type: values.type,
        frontLink: prospectus.frontLink,
        website: values.website,
        securityIds: prospectus.securities,
        effectiveStartDate: format(values.effectiveStartDate, 'yyyy-MM-dd'),
      });
      actions.setSubmitting(false);
      setIsEditing(false);
      toast.success('Prospectus was updated successfully.');
      onUpdateSuccess();
    } catch (error) {
      actions.setSubmitting(false);
      toast.error(JSON.stringify(error.response.data), {});
    }
  };

  const handleDeleteProspectus = async () => {
    try {
      await hubApi.delete(`/securities/prospectuses/${prospectus.id}/`);
      onUpdateSuccess();
      setConfirmDeleteDialog(false);
      toast.success('Prospectus was deleted successfully.');
    } catch (error) {
      setConfirmDeleteDialog(false);
      toast.error(JSON.stringify(error.response), {});
    }
  };

  return isEditing ? (
    <Formik
      initialValues={{
        effectiveEndDate:
          zonedTimeToUtc(prospectus.effectiveEndDate, NY_TIME_ZONE_STRING) ||
          new Date(),
        effectiveStartDate:
          zonedTimeToUtc(prospectus.effectiveStartDate, NY_TIME_ZONE_STRING) ||
          addDays(new Date(), 364),
        name: prospectus.name || '',
        type: prospectus.type || '',
        website: prospectus.website || '',
      }}
      enableReinitialize={true}
      onSubmit={handleUpdateProspectus}
      validationSchema={Yup.object().shape({
        website: Yup.string()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Please enter a valid URL.',
          )
          .required('URL is required.'),
      })}
    >
      {({
        isValid,
        isSubmitting,
        values,
      }: FormikProps<ProspectusFormValues>) => (
        <Form>
          <div className="p-4 border border-light-gray grid gap-4 grid-cols-issuerDetailsEditableProspectusRow items-center w-full">
            <Field name="type">
              {({ field }: FieldProps<ProspectusFormValues>) => (
                <Column>
                  <Label>Doc Type</Label>
                  {
                    prospectusTypeOptions.filter(
                      (option) => option.value === prospectus.type,
                    )[0]['label']
                  }
                </Column>
              )}
            </Field>
            <Field name="name">
              {({ field }: FieldProps<ProspectusFormValues>) => (
                <Column>
                  <Label>Doc Name</Label>
                  <Input {...field} />
                </Column>
              )}
            </Field>
            <Field name="effectiveStartDate">
              {({ field, form }: FieldProps<ProspectusFormValues>) => (
                <Column>
                  <Label>Effective Date</Label>
                  <DatePicker
                    name="effectiveStartDate"
                    selected={field.value}
                    className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
                    onChange={(date) => {
                      if (date) {
                        handleChangeEffectiveDate(date, form);
                      }
                    }}
                  />
                </Column>
              )}
            </Field>
            {(values.type === 'summary' || values.type === 'statutory') && (
              <Field name="effectiveEndDate">
                {({ field, form }: FieldProps<ProspectusFormValues>) => (
                  <Column>
                    <Label>Expected Date</Label>
                    <DatePicker
                      name="effectiveEndDate"
                      selected={field.value}
                      className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
                      onChange={(date) => {
                        if (date) {
                          form.setFieldValue('effectiveEndDate', date);
                        }
                      }}
                    />
                  </Column>
                )}
              </Field>
            )}
            <Field name="website">
              {({ field }: FieldProps<ProspectusFormValues>) => (
                <Column>
                  <Label>Doc Link</Label>
                  <Input {...field} />
                  <ErrorMessage name="website" />
                </Column>
              )}
            </Field>
            <div className="flex items-center" style={{ justifySelf: 'end' }}>
              <button
                className="font-medium text-green hover:underline mr-4 disabled:text-light-gray disabled:cursor-not-allowed disabled:no-underline"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </button>
              <button
                onClick={handleCancelEditing}
                className="font-medium text-gray hover:text-green"
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <>
      <div
        className={`p-4 border border-light-gray grid gap-4 grid-cols-issuerDetailsEditableProspectusRow justify-around items-center w-full ${
          determineIfPastToday(new Date(prospectus.effectiveEndDate))
            ? 'bg-very-light-gray'
            : 'bg-white'
        }`}
      >
        <Column>
          <Label>Doc Type</Label>
          <span>
            {
              prospectusTypeOptions.filter(
                (option) => option.value === prospectus.type,
              )[0]['label']
            }
          </span>
        </Column>
        <Column>
          <Label>Doc Name</Label>
          <a
            href={prospectus.attachment}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue hover:underline hover:text-blue"
          >
            {prospectus.name}
          </a>
        </Column>
        <Column>
          <Label>Effective Date</Label>
          <span>{formatDate(new Date(prospectus.effectiveStartDate))}</span>
        </Column>
        <Column>
          <Label>Expected Date</Label>
          <span
            className={
              determineIfPastToday(new Date(prospectus.effectiveEndDate))
                ? 'text-red'
                : 'text-primary-text'
            }
          >
            {formatDate(new Date(prospectus.effectiveEndDate))}
          </span>
        </Column>
        <Column>
          <Label>Doc Link</Label>
          {prospectus.website !== '' ? (
            <span>
              <a
                href={prospectus.website}
                target="_blank"
                rel="noopener noreferrer"
                className="border-b border-green hover:text-green hover:no-underline"
              >
                Fund Link
              </a>
            </span>
          ) : (
            <span>--</span>
          )}
        </Column>

        {isEditable && (
          <ActionButtons
            onEdit={() => setIsEditing(true)}
            onDelete={() => setConfirmDeleteDialog(true)}
          />
        )}
      </div>
      <DeleteConfirmationDialog
        deleteType="prospectus"
        isOpen={confirmDeleteDialog}
        onConfirmDelete={() => handleDeleteProspectus()}
        onRequestClose={() => setConfirmDeleteDialog(false)}
      />
    </>
  );
};

const Column = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col">{children}</div>
);

const Label = ({ children }: { children: React.ReactNode }) => (
  <span className="font-medium text-xxs text-secondary-text uppercase">
    {children}
  </span>
);

export { ProspectusRow };
