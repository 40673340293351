import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import { Label } from '../Label';
import { Input } from '../Input';
import { InputGroup } from '../InputGroup';
import { FilingDetailsFormValues } from '../../Filing/Filing';

export const SolicitorJobNumber: FC<{}> = () => {
  return (
    <Field
      name="solicitorJobNumber"
      render={({ field }: FieldProps<FilingDetailsFormValues>) => (
        <InputGroup>
          <Label htmlFor="solicitor-job-number">Solicitor Job Number</Label>

          <Input id="solicitor-job-number" {...field} />
        </InputGroup>
      )}
    />
  );
};
