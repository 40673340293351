import React from 'react';

type PageWrapperProps = {
  children: React.ReactNode;
};

const PageWrapper = ({ children }: PageWrapperProps) => (
  <div className="p-8">{children}</div>
);

export { PageWrapper };
