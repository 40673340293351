import React from 'react';
import { TextField } from 'formik-material-ui';
import { Field, FormikProps } from 'formik';
import { geocode } from '../../../old/utils/geocode';
import { FilingDetailsFormValues, IFilingCreateValues } from '../types';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type FieldProps = {
  formikBag:
    | FormikProps<FilingDetailsFormValues>
    | FormikProps<IFilingCreateValues>;
  required: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    addressBox: {
      paddingTop: '24px',
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginBottom: '24px',
      },
    },
  }),
);

export const InvoicerAddressFields = ({ formikBag, required }: FieldProps) => {
  const classes = useStyles();
  return (
    <div className={classes.addressBox}>
      <Field
        name="invoicerAddress"
        id="address"
        size="small"
        variant="outlined"
        fullWidth
        label="Invoicer Address"
        component={TextField}
        required={formikBag.values.invoicerContactEmail ? false : required}
        helperText="Invoice fields will be autofilled based on the above invoice address. If
        they are not properly filled, please update them before adding the
        filing"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          formikBag.setFieldValue('invoicerAddress', event.currentTarget.value);
          geocode(event.currentTarget.value).then((address) => {
            if (address) {
              formikBag.setFieldValue('invoicerAddressLine1', address.line1);
              formikBag.setFieldValue('invoicerAddressLine2', address.line2);
              formikBag.setFieldValue('invoicerAddressLine3', address.line3);
              formikBag.setFieldValue('invoicerCity', address.city);
              formikBag.setFieldValue('invoicerState', address.state);
              formikBag.setFieldValue('invoicerPostalCode', address.postalCode);
              formikBag.setFieldValue('invoicerCountry', address.country);
            }
          });
        }}
      />

      <Field
        id="address-address1"
        component={TextField}
        fullWidth
        size="small"
        label="Invoicer Address 1"
        name="invoicerAddressLine1"
        variant="outlined"
      />
      <Field
        id="address-address2"
        component={TextField}
        fullWidth
        size="small"
        label="Invoicer Address 2"
        name="invoicerAddressLine2"
        variant="outlined"
      />
      <Field
        id="address-address3"
        component={TextField}
        fullWidth
        size="small"
        label="Invoicer Address 3"
        name="invoicerAddressLine3"
        variant="outlined"
      />

      <Field
        id="address-city"
        component={TextField}
        fullWidth
        size="small"
        label="Invoicer City"
        name="invoicerCity"
        variant="outlined"
      />

      <Field
        id="address-state"
        component={TextField}
        fullWidth
        size="small"
        label="Invoicer State"
        name="invoicerState"
        variant="outlined"
      />

      <Field
        id="address-postal"
        component={TextField}
        fullWidth
        size="small"
        label="Invoicer Postal Code"
        name="invoicerPostalCode"
        variant="outlined"
      />
    </div>
  );
};
