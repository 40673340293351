import styled from 'styled-components/macro';
import { space, SpaceProps, width, WidthProps } from 'styled-system';
import { darken } from 'polished';

interface GrayButtonProps extends SpaceProps, WidthProps {
  small?: boolean;
}

export const GrayButton = styled.button<GrayButtonProps>`
  ${space}
  ${width}
  background-color: ${({ theme }) => theme.colors.gray};
  border: none;
  border-radius: ${({ theme }) => theme.radii[3]}px;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme, small }) => theme.fontSizes[small ? 0 : 1]}px;
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  padding: ${({ theme, small }) =>
    `${theme.spacing[small ? 1 : 2]}px ${theme.spacing[small ? 3 : 4]}px`};
  transition: background 0.25s ease, color 0.25s ease;

  &:enabled:hover {
    background: ${({ theme }) => darken(0.1, theme.colors.gray)};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
