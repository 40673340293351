import React from 'react';
import Dropzone from 'react-dropzone';
import { ReactComponent as TrashIcon } from './trash.svg';
import { ReactComponent as FileIcon } from './file.svg';
import { v4 as uuid } from 'uuid';
import { hubApiFormData } from '../../old/data/hubApiConfig';
import { toast } from 'react-toastify';
import { capitalize } from '../../utils/capitalize';
import { addDays, format } from 'date-fns';
import DatePicker from 'react-datepicker';

type UploadProps = {
  onCancel: () => void;
  onSuccess: () => void;
  id: string;
};

const Upload = ({ onCancel, id, onSuccess }: UploadProps) => {
  const [prospectuses, setProspectuses] = React.useState(new Set());

  const addNewProspectus = (files: File[]) =>
    files.forEach((file) =>
      setProspectuses(
        new Set(prospectuses.add(generateProspectusFromNewFile(file))),
      ),
    );

  const handleDeleteProspectus = (prospectus: Prospectus) => {
    const copySet = new Set(prospectuses);
    copySet.delete(prospectus);
    setProspectuses(copySet);
  };

  const handleProspectusChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    id: string,
  ) => {
    const prospectusesCopy = ([...prospectuses] as Prospectus[]).map(
      (prospectus) => {
        if (id !== prospectus.id) {
          return prospectus;
        }

        return {
          ...prospectus,
          [event.currentTarget.name]: event.currentTarget.value,
        };
      },
    );

    setProspectuses(new Set(prospectusesCopy));
  };

  const handleChangeDocumentType = (type: string, id: string) => {
    const switchType = (type: string) => {
      switch (type) {
        case 'summary':
          return 'Summary Prospectus';
        case 'statutory':
          return 'Statutory Prospectus';
        case 'sticker':
          return 'Sticker';
        case 'statement_of_additional_information':
          return 'Statement of Additional Information';
        default:
          return capitalize(type.split('_').join(' '));
      }
    };
    const prospectusesCopy = ([...prospectuses] as Prospectus[]).map(
      (prospectus) => {
        if (id !== prospectus.id) {
          return prospectus;
        }

        return {
          ...prospectus,
          type,
          name: switchType(type),
        };
      },
    );

    setProspectuses(new Set(prospectusesCopy));
  };

  const handleChangeEffectiveDate = (date: Date | null, id: string) => {
    if (date) {
      const effectiveEndDate = addDays(date, 364);
      const prospectusesCopy = ([...prospectuses] as Prospectus[]).map(
        (prospectus) => {
          if (id !== prospectus.id) {
            return prospectus;
          }

          return {
            ...prospectus,
            effectiveStartDate: date,
            effectiveEndDate,
          };
        },
      );

      setProspectuses(new Set(prospectusesCopy));
    }
  };

  const handleChangeExpectedDate = (date: Date | null, id: string) => {
    if (date) {
      const prospectusesCopy = ([...prospectuses] as Prospectus[]).map(
        (prospectus) => {
          if (id !== prospectus.id) {
            return prospectus;
          }

          return {
            ...prospectus,
            effectiveEndDate: date,
          };
        },
      );

      setProspectuses(new Set(prospectusesCopy));
    }
  };

  const handleCreateProspectuses = async (event: React.FormEvent) => {
    event.preventDefault();
    const allFormData = ([...prospectuses] as Prospectus[])
      .sort((a, b) =>
        a.type === 'statutory' ? -1 : b.type === 'statutory' ? 1 : 0,
      )
      .sort((a, b) =>
        a.type === 'summary' ? -1 : b.type === 'summary' ? 1 : 0,
      )
      .map((prospectus, i, arr) => {
        const formData = new FormData();
        formData.append(
          'attachment',
          prospectus.attachment,
          prospectus.attachment.name,
        );
        formData.append('type', prospectus.type);
        formData.append('name', prospectus.name);
        formData.append('website', prospectus.website);
        formData.append(
          'effective_start_date',
          format(prospectus.effectiveStartDate, 'yyyy-MM-dd'),
        );
        if (prospectus.type === 'summary' || prospectus.type === 'statutory') {
          formData.append(
            'effective_end_date',
            format(prospectus.effectiveEndDate, 'yyyy-MM-dd'),
          );
        }
        formData.append('security_ids', id);
        formData.append(
          'document_package_fully_uploaded',
          arr.length === i + 1 ? 'true' : 'false',
        );

        return formData;
      });

    for (let i = 0; i < allFormData.length; i++) {
      try {
        await hubApiFormData.post('/securities/prospectuses/', allFormData[i]);
        onSuccess();
      } catch (error) {
        if (error.response.data) {
          return toast.error(error.response.data.nonFieldErrors.join('\n'), {
            autoClose: false,
          });
        }
        return toast.error(
          'An unknown error occurred. Please ensure your selections are correct.',
          { autoClose: false },
        );
      }
    }
  };

  return (
    <form className="w-full" onSubmit={handleCreateProspectuses}>
      {([...prospectuses] as Prospectus[]).map((prospectus) => (
        <div key={prospectus.id}>
          <div className="flex items-center justify-between p-4 border border-light-gray">
            <span className="text-sm flex items-center">
              <FileIcon className="mr-2" />
              {prospectus.attachment.name}
            </span>
            <button
              onClick={() => handleDeleteProspectus(prospectus)}
              type="button"
            >
              <TrashIcon />
            </button>
          </div>
          <div className="grid items-center gap-8 border border-light-gray grid-cols-maintenanceModalUploadProspectusRow p-4">
            <Column>
              <Label>Doc Type</Label>
              <select
                className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
                name="type"
                value={prospectus.type}
                onChange={(event) =>
                  handleChangeDocumentType(
                    event.currentTarget.value,
                    prospectus.id,
                  )
                }
              >
                <option value="summary">Summary Prospectus</option>
                <option value="statutory">Statutory Prospectus</option>
                <option value="sticker">Sticker</option>
                <option value="statement_of_additional_information">
                  Statement of Additional Information
                </option>
              </select>
            </Column>
            <Column>
              <Label>Doc Name</Label>
              <input
                type="text"
                name="name"
                className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
                required={true}
                onChange={(event) =>
                  handleProspectusChange(event, prospectus.id)
                }
                value={prospectus.name}
              />
            </Column>
            <Column>
              <Label>Effective Date</Label>
              <DatePicker
                name="effectiveStartDate"
                className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
                required={true}
                onChange={(date) =>
                  handleChangeEffectiveDate(date, prospectus.id)
                }
                selected={prospectus.effectiveStartDate}
              />
            </Column>
            {(prospectus.type === 'summary' ||
              prospectus.type === 'statutory') && (
              <Column>
                <Label>Expected Date</Label>
                <DatePicker
                  name="effectiveEndDate"
                  className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
                  required={true}
                  onChange={(date) =>
                    handleChangeExpectedDate(date, prospectus.id)
                  }
                  selected={prospectus.effectiveEndDate}
                />
              </Column>
            )}
            <Column>
              <Label>Doc Link</Label>
              <input
                type="url"
                name="website"
                className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
                onChange={(event) =>
                  handleProspectusChange(event, prospectus.id)
                }
                value={prospectus.website}
              />
            </Column>
          </div>
        </div>
      ))}

      <div className="">
        <Dropzone
          onDrop={(acceptedFiles: File[]) => addNewProspectus(acceptedFiles)}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={`border border-dashed rounded flex justify-center items-center h-16 ${
                  isDragActive ? 'border-primary-text' : 'border-gray'
                }`}
              >
                <input {...getInputProps()} />
                <span className="text-primary-text text-sm">
                  {isDragActive
                    ? 'Drop file here'
                    : 'Drag and drop a file or click to browse'}
                </span>
              </div>
            );
          }}
        </Dropzone>
      </div>

      <div className="flex flex-col items-center mt-8">
        <button
          className="bg-green text-primary-text text-sm font-medium h-10 w-40 rounded-full disabled:text-secondary-text disabled:bg-light-gray disabled:cursor-not-allowed"
          disabled={!prospectuses.size}
        >
          Upload
        </button>
        <button
          className="text-secondary-text text-sm mt-4"
          onClick={onCancel}
          type="button"
        >
          Go Back
        </button>
      </div>
    </form>
  );
};

type Prospectus = {
  id: string;
  type: ProspectusType;
  name: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  website: string;
  attachment: File;
};

type ProspectusType =
  | 'summary'
  | 'sticker'
  | 'statement_of_additional_information'
  | 'statutory';

const generateProspectusFromNewFile = (file: File): Prospectus => {
  return {
    id: uuid(),
    type: 'summary',
    name: 'Summary Prospectus',
    effectiveStartDate: new Date(),
    effectiveEndDate: addDays(new Date(), 364),
    website: '',
    attachment: file,
  };
};

const Column = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col">{children}</div>
);

const Label = ({ children }: { children: React.ReactNode }) => (
  <span className="font-medium text-xxs text-secondary-text uppercase">
    {children}
  </span>
);

export { Upload };
