import React, { FC, useEffect, useContext } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useContainer } from 'unstated-next';
import { Flex } from '@rebass/grid';

import { FilingContext } from '../../data/Filing.Context';
import { RouteComponentProps } from '@reach/router';
import { FilingType } from '../../models/filing-type';
import { ProxyVote } from './ProxyVote';
import { CAElection } from './CAElection';
import { groupProposals } from '../../utils/groupVoteProposals';
import { orderElections } from '../../utils/orderElections';
import { reIndexVPGroups } from '../../utils/reIndexVPGroups';
import { reIndexElections } from '../../utils/reIndexElections';
import { VoteProposals } from '../../data/VoteProposal';

interface IProps extends RouteComponentProps<{ id: string }> {
  type: FilingType;
}

export enum EditTypes {
  edit = 'edit',
  create = 'create',
}

export const VoteContainer: FC<IProps> = ({ type, id }) => {
  const { filing } = useContext(FilingContext);
  const {
    setGroupedProposals,
    setShouldReorder,
    handleSaveReorder,
    groupedProposals,
    setElectionItems,
    shouldReorder,
    onDragEnd,
  } = useContainer(VoteProposals);

  useEffect(() => {
    if (filing) {
      if (filing.type !== FilingType.CorporateAction) {
        if (filing.voteProposals) {
          const groupedProposalsArray = groupProposals(filing.voteProposals);
          const reIndexedProposals = reIndexVPGroups(groupedProposalsArray);
          setGroupedProposals((draft) => (draft = reIndexedProposals));
          setShouldReorder(true);
        }
      } else {
        if (filing.electionItems) {
          const orderedElections = orderElections(filing.electionItems);
          const reIndexedElections = reIndexElections(orderedElections);
          setElectionItems((draft) => (draft = reIndexedElections));
          setShouldReorder(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [filing]);

  useEffect(() => {
    if (shouldReorder) {
      handleSaveReorder();
      setShouldReorder(false);
    }
    // eslint-disable-next-line
  }, [shouldReorder, groupedProposals]);

  const switchType = (): JSX.Element => {
    switch (type) {
      case FilingType.CorporateAction:
        return <CAElection />;
      default:
        return <ProxyVote />;
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Flex flexDirection="column" width={1}>
        {switchType()}
      </Flex>
    </DragDropContext>
  );
};
