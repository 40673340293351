import React from 'react';

type SelectProps = {
  options: Option[];
  value: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
};

type Option = {
  value: string;
  label: string;
  disabled?: boolean;
};

const Select = ({
  options,
  onChange,
  value,
  onBlur = () => false,
  name,
}: SelectProps) => {
  return (
    <select
      onChange={onChange}
      value={value}
      className="h-8 border border-light-gray rounded outline-none focus:border-primary-text w-full px-1"
      onBlur={onBlur}
      name={name}
    >
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          disabled={option.disabled ? option.disabled : false}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
};

export { Select };
