import React from 'react';
import {
  RouteComponentProps,
  useNavigate,
  Link,
  LinkGetProps,
} from '@reach/router';
import { CusipTypeahead } from '../../components/CusipTypeahead/CusipTypeahead';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { Label } from '../../components/Label';
import { Input } from '../../components/Input';
import { DateInput } from '../../components/DateInput/DateInput';
import { FormStatusBar } from './FormStatusBar';
import { privateApi } from '../../old/utils/api-adapter';
import { FieldError } from '../../components/FieldError/FieldError';
import {
  CorporateActionType,
  DetailsFormValues,
  OperationsStatus,
} from './types';
import { validationSchema } from './validation-schema';
import { corporateActionTypeOptions, frequencyOptions } from './select-options';
import { createFormPayload, getElectionChoices } from './helpers';
import { DetailsPageDocuments } from './DetailsPageDocuments';
import { toast } from 'react-toastify';
import { useCorporateAction } from './useCorporateAction';
import { Header } from './Header';
import Switch from '@material-ui/core/Switch';
import { Box, Button, ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { formatErrors } from './formatErrors';
import { formatUpdateStatusError } from '../../utils/format-update-status-error';
import { zonedTimeToUtc } from 'date-fns-tz';
import { TextareaAutosize } from './TextareaAutosize';
import { Select } from '../../components/Select';

const NY_TIME_ZONE_STRING = 'America/New_York';

const switchTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#11cc99',
    },
    secondary: { main: '#737373' },
  },
});

type DetailsProps = RouteComponentProps<{ id: string }>;

function Details({ id }: DetailsProps) {
  const { data, mutate } = useCorporateAction(id);
  const navigate = useNavigate();
  const [showReport, setShowReport] = React.useState(false);
  const [
    isConfirmDeleteDialogOpen,
    setIsConfirmDeleteDialogOpen,
  ] = React.useState(false);

  const vcaReport = React.useMemo(() => {
    if (data) {
      return data.data.documents.find(
        (document) => document.type === 'corporate_action_details',
      );
    }
  }, [data]);

  const isEDIVCA: boolean = vcaReport ? true : false;

  const handleFormSubmit = async (
    values: DetailsFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    if (values.issuer && values.securities.length) {
      try {
        setSubmitting(true);
        const payload = createFormPayload(values);
        const electionPayload = {
          title: values.electionTitle,
          detail: values.electionDetails,
          choices: getElectionChoices(values.type as CorporateActionType),
          corporateActionId: id,
        };

        const response = await privateApi.patch(`/admin/vca/${id}/`, payload);

        if (values.operationsStatus === 'new') {
          const payload: { operationsStatus: OperationsStatus } = {
            operationsStatus: 'active',
          };

          await privateApi.patch(`/admin/vca/${id}/update-status/`, payload);
        }

        await privateApi.patch(
          `/admin/elections/${response.data.election.id}/`,
          electionPayload,
        );
        setSubmitting(false);
        mutate();
      } catch (error) {
        setSubmitting(false);
        toast.error(formatErrors(error.response.data), { autoClose: false });
      }
    }
  };

  async function handleMarkReadyForReview() {
    if (data) {
      const status = data.data.operationsStatus;

      if (status === 'new') {
        toast.error(
          'Status must be active in order to mark it ready for review.',
        );
        return;
      }

      if (
        status === 'ready_for_review' ||
        status === 'approved' ||
        status === 'closed'
      ) {
        navigate(`/corporate-actions/${id}/broker-email`);
        return;
      }

      if (status === 'active') {
        try {
          const payload: { operationsStatus: OperationsStatus } = {
            operationsStatus: 'ready_for_review',
          };
          await privateApi.patch(`/admin/vca/${id}/update-status/`, payload);
          mutate();
          navigate(`/corporate-actions/${id}/broker-email`);
        } catch (error) {
          toast.error(formatUpdateStatusError(error));
        }
      }
    }
  }

  async function handleDeleteVca() {
    try {
      await privateApi.delete(`/admin/vca/${id}/`);
      navigate(`/corporate-actions/tasks/new`);
    } catch (error) {
      toast.error(JSON.stringify(error.response.data, null, 2));
    }
  }

  const isActive = ({ isCurrent }: LinkGetProps) => {
    return isCurrent
      ? {
          className:
            'font-medium text-primary-text mr-8 last:mr-0 hover:no-underline hover:text-green border-b-2 border-green py-4',
        }
      : {
          className:
            'text-primary-text mr-8 last:mr-0 hover:no-underline hover:text-green border-b-2 border-transparent py-4',
        };
  };

  if (data) {
    const initialValues: DetailsFormValues = {
      securities: data.data.securities
        ? data.data.securities.map((security) => ({
            cusip: security.cusip,
            id: security.id,
          }))
        : [],
      issuer: data.data.issuer
        ? { id: data.data.issuer.id, name: data.data.issuer.companyName }
        : null,
      announcementDate: new Date(data.data.announcementDate) || null,
      recordDate: data.data.recordDate
        ? zonedTimeToUtc(data.data.recordDate, NY_TIME_ZONE_STRING)
        : null,
      electionCutoffDate: data.data.electionCutoffDatetime
        ? new Date(data.data.electionCutoffDatetime)
        : null,
      dtcExpirationDate: data.data.dtcExpirationDate
        ? new Date(data.data.dtcExpirationDate)
        : null,
      type: (data.data.type as CorporateActionType) || '',
      operationsStatus: data.data.operationsStatus || 'active',
      electionDetails: data.data.election.detail || '',
      electionTitle: data.data.election.title || '',
      bidIncrement: data.data.bidIncrement || '',
      consentPayment: data.data.consentPayment || '',
      contraCusip: data.data.contraCusip || '',
      dripReinvestmentPrice: data.data.dripReinvestmentPrice || '',
      frequency: data.data.frequency || '',
      grossDividend: data.data.grossDividend || '',
      maximumBidPrice: data.data.maximumBidPrice || '',
      maximumQualifyingQuantity: data.data.maximumQualifyingQuantity || '',
      minimumBidPrice: data.data.minimumBidPrice || '',
      minimumQualifyingQuantity: data.data.minimumQualifyingQuantity || '',
      offererParty: data.data.offererParty || '',
      outTurn: data.data.outTurn || '',
      price: data.data.price || '',
      ratio: data.data.ratio || '',
      tradeSecurity: data.data.tradeSecurity || '',
      documents: data.data.documents
        ? data.data.documents.map((doc) => ({
            id: doc.id,
            name: doc.name,
            type: doc.type,
            attachment: doc.attachment,
          }))
        : [],
    };

    return (
      <ThemeProvider theme={switchTheme}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, formikBag) =>
            handleFormSubmit(values, formikBag.setSubmitting)
          }
          enableReinitialize={true}
          validationSchema={validationSchema}
          isInitialValid={true}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            isValid,
            dirty,
            setSubmitting,
          }: FormikProps<DetailsFormValues>) => (
            <Form>
              <Header issuerName={values.issuer ? values.issuer.name : ''} />

              <div className="px-8 py-4 bg-very-light-gray border-t border-b border-light-gray">
                <Link to={`/corporate-actions/${id}`} getProps={isActive}>
                  Filing Details
                </Link>
                {data.data.operationsStatus !== 'new' ? (
                  <Link
                    to={`/corporate-actions/${id}/broker-email`}
                    getProps={isActive}
                    onClick={async (event) => {
                      event.preventDefault();
                      try {
                        if (dirty) {
                          await handleFormSubmit(values, setSubmitting);
                        }

                        await handleMarkReadyForReview();
                      } catch (error) {
                        toast.error('Unable to mark VCA ready for review.');
                      }
                    }}
                  >
                    Broker Email
                  </Link>
                ) : null}
              </div>

              <FormStatusBar
                status={data.data.operationsStatus}
                isSubmitting={isSubmitting}
                isValid={isValid}
                actionButton={
                  <ReadyForReviewButton
                    onMarkReadyForReview={async () => {
                      try {
                        if (dirty) {
                          await handleFormSubmit(values, setSubmitting);
                        }

                        await handleMarkReadyForReview();
                      } catch (error) {
                        toast.error('Unable to mark VCA ready for review.');
                      }
                    }}
                    isDisabled={!isValid}
                  />
                }
              />

              <div className="grid grid-cols-2 gap-8 py-4 px-8">
                <div>
                  <div className="py-4 w-full border-b border-gray flex items-center justify-between">
                    <h2 className="text-secondary-text font-medium text-xs uppercase">
                      Filing Details {isEDIVCA ? '(Auto Created)' : ''}
                    </h2>

                    {isEDIVCA ? (
                      <div className="flex items-center">
                        <span className="text-secondary-text font-medium text-xs uppercase">
                          Report
                        </span>

                        <Switch
                          checked={showReport}
                          name="report"
                          onChange={() => setShowReport(!showReport)}
                          color="primary"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-col mt-4">
                    <Field name="securities">
                      {({ field, form }: FieldProps<DetailsFormValues>) => (
                        <InputGroup>
                          <Label required={true}>CUSIP(s)</Label>
                          <CusipTypeahead
                            selectedItems={field.value}
                            onSelectItem={(security) => {
                              form.setFieldValue('securities', [
                                ...field.value,
                                security,
                              ]);
                              form.setFieldValue('issuer', {
                                id: security.issuer.id,
                                name: security.issuer.companyName,
                              });
                            }}
                            onClear={() => {
                              form.setFieldValue('securities', []);
                              form.setFieldValue('issuer', { id: 0, name: '' });
                            }}
                            onRemoveItem={(index) => {
                              if (field.value.length === 1) {
                                form.setFieldValue('issuer', {
                                  id: 0,
                                  name: '',
                                });
                              }

                              form.setFieldValue(
                                'securities',
                                field.value.filter(
                                  (_: any, i: number) => i !== index,
                                ),
                              );
                            }}
                            issuerId={
                              values.issuer ? values.issuer.id : undefined
                            }
                            disabled={values.operationsStatus === 'closed'}
                          />
                        </InputGroup>
                      )}
                    </Field>
                    <Field name="issuer">
                      {({ field }: FieldProps<DetailsFormValues>) => (
                        <InputGroup>
                          <Label required={true}>Issuer</Label>
                          <Input
                            disabled={true}
                            type="text"
                            value={field.value ? field.value.name : ''}
                          />
                        </InputGroup>
                      )}
                    </Field>
                    <div className="grid grid-cols-2 gap-4">
                      <Field name="announcementDate">
                        {({ form, field }: FieldProps<DetailsFormValues>) => (
                          <InputGroup>
                            <Label required={true}>Announcement Date</Label>
                            <DateInput
                              name="announcementDate"
                              onChange={(date) => {
                                if (date) {
                                  form.setFieldValue('announcementDate', date);
                                }
                              }}
                              selected={field.value}
                              dateFormat="MM/dd/yyyy h:mm aa"
                              showTime={true}
                              placeholder="MM/DD/YYYY H:MM AA"
                            />
                          </InputGroup>
                        )}
                      </Field>

                      <Field name="recordDate">
                        {({ field, form }: FieldProps<DetailsFormValues>) => (
                          <InputGroup>
                            <Label showOptional={true}>Record Date</Label>
                            <DateInput
                              name="recordDate"
                              onChange={(date) => {
                                if (date) {
                                  form.setFieldValue('recordDate', date);
                                }
                              }}
                              selected={field.value}
                              placeholder="MM/DD/YYYY"
                            />
                          </InputGroup>
                        )}
                      </Field>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <Field name="electionCutoffDate">
                        {({ field, form }: FieldProps<DetailsFormValues>) => (
                          <InputGroup>
                            <Label required={true}>Election Cutoff Date</Label>
                            <DateInput
                              name="electionCutoffDate"
                              onChange={(date) => {
                                if (date) {
                                  form.setFieldValue(
                                    'electionCutoffDate',
                                    date,
                                  );
                                }
                              }}
                              selected={field.value}
                              dateFormat="MM/dd/yyyy h:mm aa"
                              showTime={true}
                              placeholder="MM/DD/YYYY H:MM AA"
                            />
                          </InputGroup>
                        )}
                      </Field>

                      <Field name="dtcExpirationDate">
                        {({ field, form }: FieldProps<DetailsFormValues>) => (
                          <InputGroup>
                            <Label required={true}>DTC Expiration Date</Label>
                            <DateInput
                              name="dtcExpirationDate"
                              onChange={(date) => {
                                if (date) {
                                  form.setFieldValue('dtcExpirationDate', date);
                                }
                              }}
                              selected={field.value}
                              dateFormat="MM/dd/yyyy"
                              placeholder="MM/DD/YYYY"
                            />
                          </InputGroup>
                        )}
                      </Field>
                    </div>

                    <Field name="type">
                      {({ field }: FieldProps<DetailsFormValues>) => (
                        <InputGroup>
                          <Label>Voluntary Corporate Action Type</Label>
                          <Input
                            disabled={true}
                            type="text"
                            value={
                              corporateActionTypeOptions.filter(
                                (i) => i.value === field.value,
                              )[0]['label']
                            }
                          />
                        </InputGroup>
                      )}
                    </Field>
                    {values.type ? (
                      <div className="border-t border-light-gray pt-2 mt-4">
                        {values.type === 'OfferToPurchaseThirdPartyOffer' ? (
                          <Field name="offererParty">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>offerer Party</Label>
                                <Input type="text" {...field} />
                                {errors.offererParty && touched.offererParty ? (
                                  <FieldError>{errors.offererParty}</FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {(values.type === 'OfferToPurchaseBuyBack' ||
                          values.type === 'OfferToPurchaseThirdPartyOffer') &&
                        !values.price ? (
                          <Field name="bidIncrement">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Bid Increment</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {(values.type === 'OfferToPurchaseBuyBack' ||
                          values.type === 'OfferToPurchaseThirdPartyOffer') &&
                        !values.price ? (
                          <Field name="minimumBidPrice">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Minimum Bid Price</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {(values.type === 'OfferToPurchaseBuyBack' ||
                          values.type === 'OfferToPurchaseThirdPartyOffer') &&
                        !values.price ? (
                          <Field name="maximumBidPrice">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Maximum Bid Price</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {values.type === 'ExchangeOffer' ? (
                          <Field name="contraCusip">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Contra Cusip</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {values.type === 'ExchangeOffer' ? (
                          <Field name="ratio">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Ratio</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {values.type === 'MergerConsiderationElection' ||
                        values.type === 'ConsentSolicitation' ? (
                          <Field name="consentPayment">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Consent Payment</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {values.type === 'RightsIssue' ? (
                          <Field name="tradeSecurity">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Trade Security</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {values.type === 'RightsIssue' ? (
                          <Field name="outTurn">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Out Turn</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {values.type === 'OddLotOffer' ? (
                          <Field name="minimumQualifyingQuantity">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>
                                  Minimum Qualifying Quantity
                                </Label>
                                <Input type="text" {...field} />
                                {errors.minimumQualifyingQuantity &&
                                touched.minimumQualifyingQuantity ? (
                                  <FieldError>
                                    {errors.minimumQualifyingQuantity}
                                  </FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {values.type === 'OddLotOffer' ? (
                          <Field name="maximumQualifyingQuantity">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>
                                  Maximum Qualifying Quantity
                                </Label>
                                <Input type="text" {...field} />
                                {errors.maximumQualifyingQuantity &&
                                touched.maximumQualifyingQuantity ? (
                                  <FieldError>
                                    {errors.maximumQualifyingQuantity}
                                  </FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {values.type === 'OddLotOffer' ||
                        values.type === 'OfferToPurchaseBuyBack' ||
                        values.type === 'OfferToPurchaseThirdPartyOffer' ? (
                          <Field name="price">
                            {({
                              field,
                              form,
                            }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={values.type === 'OddLotOffer'}>
                                  Price
                                </Label>
                                <Input
                                  type="text"
                                  {...field}
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    if (
                                      values.type ===
                                        'OfferToPurchaseBuyBack' ||
                                      values.type ===
                                        'OfferToPurchaseThirdPartyOffer'
                                    ) {
                                      form.setFieldValue('bidIncrement', '');
                                      form.setFieldValue('minimumBidPrice', '');
                                      form.setFieldValue('maximumBidPrice', '');
                                    }

                                    form.setFieldValue('price', value);
                                  }}
                                />
                                {errors.price && touched.price ? (
                                  <FieldError>{errors.price}</FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {values.type === 'DividendOption' ? (
                          <Field name="frequency">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>Frequency</Label>
                                <Select options={frequencyOptions} {...field} />
                                {errors.frequency && touched.frequency ? (
                                  <FieldError>{errors.frequency}</FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {values.type === 'DividendOption' ? (
                          <Field name="grossDividend">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>Gross Dividend</Label>
                                <Input type="text" {...field} />
                                {errors.grossDividend &&
                                touched.grossDividend ? (
                                  <FieldError>
                                    {errors.grossDividend}
                                  </FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        {values.type === 'DividendReinvestmentPlan' ? (
                          <Field name="dripReinvestmentPrice">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>DRIP Reinvestment Price</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}

                        <Field name="electionTitle">
                          {({ field }: FieldProps<DetailsFormValues>) => (
                            <InputGroup>
                              <Label required={true}>Election Title</Label>
                              <Input type="text" {...field} />
                              {errors.electionTitle && touched.electionTitle ? (
                                <FieldError>{errors.electionTitle}</FieldError>
                              ) : null}
                            </InputGroup>
                          )}
                        </Field>

                        <Field name="electionDetails">
                          {({ field }: FieldProps<DetailsFormValues>) => (
                            <InputGroup>
                              <Label required={true}>Election Details</Label>
                              <TextareaAutosize minRows={3} {...field} />
                              {errors.electionDetails &&
                              touched.electionDetails ? (
                                <FieldError>
                                  {errors.electionDetails}
                                </FieldError>
                              ) : null}
                            </InputGroup>
                          )}
                        </Field>
                      </div>
                    ) : null}
                    <Box display="flex" alignItems="start" marginTop={2}>
                      <Button
                        color="secondary"
                        disableElevation={true}
                        disableRipple={true}
                        onClick={() => setIsConfirmDeleteDialogOpen(true)}
                        disabled={
                          data.data.operationsStatus === 'approved' ||
                          data.data.operationsStatus === 'closed'
                        }
                      >
                        Delete Filing
                      </Button>
                    </Box>
                  </div>
                </div>

                <div>
                  <div className="rounded border border-light-gray">
                    {showReport && vcaReport ? (
                      <React.Fragment>
                        <div className="py-4 px-8 block w-full border-b border-light-gray">
                          <h2 className="text-secondary-text font-medium text-xs uppercase">
                            Report
                          </h2>
                        </div>
                        <embed
                          src={vcaReport.attachment}
                          className="h-screen w-full"
                          key={vcaReport.id}
                        />
                      </React.Fragment>
                    ) : (
                      <DetailsPageDocuments corporateActionId={id!} />
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        {isConfirmDeleteDialogOpen ? (
          <ConfirmDeleteDialog
            onClose={() => setIsConfirmDeleteDialogOpen(false)}
            onConfirm={handleDeleteVca}
          />
        ) : null}
      </ThemeProvider>
    );
  }

  return null;
}

type ReadyForReviewButtonProps = {
  onMarkReadyForReview: () => void;
  isDisabled: boolean;
};

function ReadyForReviewButton({
  onMarkReadyForReview,
  isDisabled,
}: ReadyForReviewButtonProps) {
  return (
    <button
      className="bg-green rounded hover:bg-light-green transition-colors duration-300 h-8 text-xs font-medium text-primary-text px-2 disabled:bg-light-gray disabled:cursor-not-allowed"
      onClick={onMarkReadyForReview}
      disabled={isDisabled}
      type="button"
    >
      Review broker emails
    </button>
  );
}

function InputGroup({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-col mt-2">{children}</div>;
}

export { Details };
