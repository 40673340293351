import React from 'react';
import { RouteComponentProps, Link, useNavigate } from '@reach/router';
import { hubApi } from '../../old/data/hubApiConfig';
import useSWR from 'swr';
import { PageWrapper } from '../../components/PageWrapper';
import { LoadingState } from '../../components/LoadingState';
import { TableHeader } from '../../components/TableHeader';
import { Table } from '../../components/Table';
import { TableHeadCell } from '../../components/TableHeadCell';
import { TableBodyRow } from '../../components/TableBodyRow';
import { TickerAvatar } from '../../components/TickerAvatar';
import { TableBodyCell } from '../../components/TableBodyCell';
import { CusipTagList } from '../../components/CusipTagList';
import { formatNumber } from '../../utils/format-number';
import { humanize } from '../../utils/humanize';
import { formatDate } from '../../utils/format-date';
import { IssuersPageHeader } from '../../components/IssuersPageHeader';
import { getPageOffset } from '../../utils/get-page-offset';
import { ErrorCard } from '../../components/ErrorCard';

type Response = {
  count: number;
  next: string;
  previous: null;
  results: Issuer[];
};

type Issuer = {
  id: string;
  name: string;
  issuerType: IssuerType | null;
  tickers: string[];
  cusips: string[];
  positions: number;
  recordDate: string | null;
  expectedDate: string | null;
};

export type Query = {
  pageSize: number;
  page: number;
  ordering: string;
};

type OnUpdateQuery = (
  query: 'pageSize' | 'page' | 'ordering',
  value: string | number,
) => void;

type IssuerType = 'corporation' | 'investment_trust' | 'government';

const issuersFetcher = (url: string) => hubApi.get<Response>(url);

const Issuers = (_: RouteComponentProps) => {
  const navigate = useNavigate();

  const [query, setQuery] = React.useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: '-positions',
  });

  const { data, error } = useSWR(
    `/admin/issuers/?limit=${query.pageSize}&ordering=${
      query.ordering
    }&offset=${getPageOffset({
      page: query.page,
      pageSize: query.pageSize,
    })}`,
    issuersFetcher,
  );

  const handleUpdateQuery: OnUpdateQuery = (name, value) =>
    setQuery({ ...query, [name]: value });

  if (!error && !data)
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );

  if (error)
    return (
      <PageWrapper>
        <ErrorCard
          title="Unable to load issuers."
          body="Something went wrong when attempting to load the requested list of issuers. Please contact support for help with this issue."
        />
      </PageWrapper>
    );

  if (data)
    return (
      <React.Fragment>
        <IssuersPageHeader count={data.data.count} />

        <PageWrapper>
          <TableHeader
            count={data.data.count}
            page={query.page}
            pageSize={query.pageSize}
            itemType="issuer"
            onPageChange={(page) => handleUpdateQuery('page', page - 1)}
            onPageSizeChange={(pageSize) =>
              handleUpdateQuery('pageSize', pageSize)
            }
          />
          <Table>
            <thead className="bg-white">
              <tr>
                <TableHeadCell name="Ticker" />
                <TableHeadCell
                  name="Issuer"
                  orderingKey="name"
                  currentOrdering={query.ordering}
                  onOrderingClick={(ordering: string) =>
                    handleUpdateQuery('ordering', ordering)
                  }
                />
                <TableHeadCell name="CUSIP" />
                <TableHeadCell name="Type" />
                <TableHeadCell
                  name="Positions"
                  orderingKey="positions"
                  currentOrdering={query.ordering}
                  onOrderingClick={(ordering: string) =>
                    handleUpdateQuery('ordering', ordering)
                  }
                />
                <TableHeadCell name="Record Date" />
                <TableHeadCell name="Expect Date" />
              </tr>
            </thead>
            <tbody>
              {data.data.results.map((issuer) => (
                <TableBodyRow
                  key={issuer.id}
                  onClick={() => navigate(`/issuers/${issuer.id}`)}
                >
                  <TableBodyCell>
                    <TickerAvatar tickers={issuer.tickers} />
                  </TableBodyCell>
                  <TableBodyCell>
                    <Link
                      className="text-blue hover:underline hover:text-blue"
                      to={`/issuers/${issuer.id}`}
                      target="_blank"
                      onClick={(event) => event.stopPropagation()}
                    >
                      {issuer.name}
                    </Link>
                  </TableBodyCell>
                  <TableBodyCell>
                    <CusipTagList cusips={issuer.cusips} />
                  </TableBodyCell>
                  <TableBodyCell>
                    <span className="capitalize text-primary-text">
                      {humanize(issuer.issuerType || '')}
                    </span>
                  </TableBodyCell>
                  <TableBodyCell>
                    <span className="font-medium text-primary-text">
                      {formatNumber(issuer.positions)}
                    </span>
                  </TableBodyCell>
                  <TableBodyCell>
                    {issuer.recordDate ? (
                      <DateCell date={issuer.recordDate} />
                    ) : (
                      'n/a'
                    )}
                  </TableBodyCell>
                  <TableBodyCell>
                    {issuer.expectedDate ? (
                      <DateCell
                        date={issuer.expectedDate}
                        highlightExpired={true}
                      />
                    ) : (
                      'n/a'
                    )}
                  </TableBodyCell>
                </TableBodyRow>
              ))}
            </tbody>
          </Table>
        </PageWrapper>
      </React.Fragment>
    );

  return null;
};

const DateCell = ({
  date,
  highlightExpired = false,
}: {
  date: null | string;
  highlightExpired?: boolean;
}) => {
  if (!date) return <span className="text-gray">n/a</span>;

  if (highlightExpired && isDateExpired(new Date(date))) {
    return <span className="text-red">{formatDate(new Date(date))}</span>;
  }

  return (
    <span className="text-primary-text">{formatDate(new Date(date))}</span>
  );
};

function isDateExpired(date: Date) {
  const now = new Date();

  return now.getTime() > date.getTime();
}

export { Issuers };
