import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Flex } from '@rebass/grid';
import { RouteComponentProps } from '@reach/router';
import { ReactComponent as Slash } from './slash.svg';
import { Text } from '../common/Text';

const Wrapper = styled(Flex)`
  height: 100vh;
  position: relative;
`;

export const FourOhFour: FC<RouteComponentProps> = () => (
  <Wrapper flexDirection="column" alignItems="center" justifyContent="center">
    <Slash />

    <Flex flexDirection="column" alignItems="center" mt={4}>
      <Text as="h1" fontSize={4} fontWeight={600}>
        Page Not Found
      </Text>
    </Flex>
  </Wrapper>
);
