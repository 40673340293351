import { Flex, Box } from '@rebass/grid';

import styled from 'styled-components/macro';
import { OutlineButton } from '../../common/Buttons/OutlineButton';

export const Card = styled(Flex)`
  border: 0.8px solid #e6e6e6;
  font-size: 14px;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  width: 100%;
  background-color: #ffffff;
`;

export const RowHeader = styled(Flex)`
  border-bottom: 1px solid #d8d8d8;
`;

export const SecurityBox = styled.div`
  background: #595959;
  border-radius: 2.56px;
  width: 85px;
  padding: 5px;
  font-size: 14px;
  margin: 0 0 5px 0;
  color: #ffffff;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights[1]};
`;

export const ProposalTypeContainer = styled(Box)`
  text-transform: capitalize;
`;

export const ProposalNumber = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights[5]};
`;

export const ProposalRecommendation = styled(Box)`
  font-weight: ${({ theme }) => theme.fontWeights[5]};
  font-size: 12px;
`;

export const RecommendationType = styled.span`
  text-transform: uppercase;
`;

export const VoteChoice = styled(Flex)`
  font-weight: ${({ theme }) => theme.fontWeights[5]};
  text-transform: uppercase;
  margin: 0 40px 0 0;
`;

export const Circle = styled(Box)`
  border: 1px solid #d9d9d9;
  width: 17px;
  height: 17px;
  border-radius: 9px;
  margin: 0 7px 0 0;
`;

export const StyledButton = styled(OutlineButton)`
  max-height: 35px;
  align-self: center;
`;

export const DeleteButton = styled(StyledButton)`
  transition: all 0.5s;
  &:enabled:hover {
    border: 1px solid ${({ theme }) => theme.colors.red};
    cursor: pointer;
  }
  margin: 0 0 0 10px;
`;

export const StyledDeleteMessage = styled.p`
  margin: 0 0 25px 0;
`;
