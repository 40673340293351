import React, { FC, ReactNode } from 'react';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components/macro';
import { Search } from '../common/Search/Search';
import { Navigation } from '../common/Navigation';
import { SayLogotype } from '../common/SayLogotype';
import { AllFilings as AllFilingsContext } from '../data/AllFilings';
import { navigate } from '@reach/router';

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 73px 1fr;
  grid-template-areas:
    'header header'
    'nav body';
`;

const Header = styled.header`
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  padding: 16px 0;
  position: sticky;
  top: 0;
  z-index: 1;
  grid-area: header;
`;

const ComponentContainer = styled.div`
  grid-area: body;
`;

const ResultsLayout: FC<{ children: ReactNode }> = ({ children }) => {
  // TODO: Change this to not use AllFilings Container
  const { allFilings, setSearch } = AllFilingsContext.useContainer();

  const handleSearch = (searchTerm: string) => {
    setSearch(searchTerm);
    navigate('/old/filings/all/open');
  };

  return (
    <Container>
      <Navigation />

      <Header>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          px={3}
          width={1}
        >
          <Flex alignItems="center" width={2 / 3}>
            <SayLogotype />

            <Box ml={4} width={1 / 2}>
              <Search onSearch={handleSearch} searchTerm={allFilings.search} />
            </Box>
          </Flex>
        </Flex>
      </Header>

      <ComponentContainer>{children}</ComponentContainer>
    </Container>
  );
};

export { ResultsLayout };
