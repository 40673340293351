import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { ReactComponent as ChevronLeft } from './assets/chevron-left.svg';
import { ReactComponent as ChevronRight } from './assets/chevron-right.svg';
import { format } from 'date-fns';
import qs from 'query-string';
import numeral from 'numeral';

const Wrapper = styled(Flex)`
  background-color: #fff;
  color: #313130;
  position: sticky;
  top: 120px;
  z-index: 1;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const PaginationControls = styled(Flex)`
  color: #313130;
  font-size: 12px;
  font-weight: 500;
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

interface StatusBarProps {
  count: number;
  next: string | null;
  onChangePage: (path: string) => void;
  previous: string | null;
  typeLabel: string;
}

export const StatusBar: FC<StatusBarProps> = ({
  count,
  next,
  onChangePage,
  previous,
  typeLabel,
}) => {
  const handlePreviousClick = () => {
    if (previous) {
      onChangePage(previous);
    }
  };

  const handleNextClick = () => {
    if (next) {
      onChangePage(next);
    }
  };

  const currentlyShowing = useMemo(() => {
    if (next) {
      const parsed = qs.parse(next);

      return `${Number(parsed.offset) - 99} - ${parsed.offset}`;
    } else if (previous) {
      const startNumber = Math.floor(count / 100) * 100;

      return `${startNumber} - ${count}`;
    }

    return `1 - ${count}`;
  }, [count, next, previous]);

  return (
    <Wrapper alignItems="center" justifyContent="space-between" p={3}>
      <Title>
        {typeLabel}: {format(new Date(), 'MM/dd/yy')}
      </Title>

      <PaginationControls alignItems="center">
        <Flex alignItems="center">
          <Button disabled={!previous} onClick={handlePreviousClick}>
            <ChevronLeft />
          </Button>

          <p>
            Showing {currentlyShowing} of {numeral(count).format('0,0')}
          </p>

          <Button disabled={!next} onClick={handleNextClick}>
            <ChevronRight />
          </Button>
        </Flex>
      </PaginationControls>
    </Wrapper>
  );
};
