import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { UnmarkRequestedModal } from '../UnmarkRequestedModal';
import { SolicitorCell } from '../../../common/List/SolicitorCell';
import { CusipCell } from '../../../common/List/CusipCell';
import { Button } from '../../../common/Buttons/Button';
import { formatDate } from '../../../utils/format-date';
import { formatCamelCaseString } from '../../../utils/format-camel-case-string';
import { StatusBar } from '../../../common/StatusBar';
import {
  FilingListResponse,
  FilingListFiling,
  OperationsStatus,
} from '../../../models/filing-list';
import { formatNumberWithComma } from '../../../utils/formatNumberWithComma';
import { GrayButton } from '../../../common/Buttons/GrayButton';
import { Ordering } from '../../../data/Ordering.Context';
import Table, { TableColumnsProps } from '../../../common/Table/Table';
import TableRow from '../../../common/Table/TableRow';
import Cell from '../../../common/Table/Cell';
import { userIdEvent } from '../../../utils/analytics-helpers';

interface RequestMaterialsProps extends RouteComponentProps {
  filings: FilingListResponse;
  isLoading: boolean;
  onChangePage: (path: string) => void;
  onMarkMaterialsRequested: (
    filingId: number,
    operationsStatus: OperationsStatus.MaterialsRequested,
  ) => void;
  onUnmarkMaterialsRequested: (
    filingId: number,
    operationsStatus: OperationsStatus.MaterialsRequested,
  ) => void;
}

export const RequestMaterials: FC<RequestMaterialsProps> = ({
  filings,
  isLoading,
  onChangePage,
  onMarkMaterialsRequested,
  onUnmarkMaterialsRequested,
}) => {
  const { ordering, setOrder } = Ordering.useContainer();

  const [unmarkRequestedModalState, setUnmarkRequestedModalState] = useState<{
    isOpen: boolean;
    filingId: number;
  }>({ isOpen: false, filingId: 0 });

  const handleSetOrdering = (order: string) => {
    setOrder('requestMaterials', order);
  };

  const markMaterialsRequested = (filingId: number) => {
    return onMarkMaterialsRequested(
      filingId,
      OperationsStatus.MaterialsRequested,
    );
  };

  const unmarkMaterialsRequested = (filingId: number) => {
    return setUnmarkRequestedModalState({ isOpen: true, filingId });
  };

  const handleUndoMarkRequested = () => {
    onUnmarkMaterialsRequested(
      unmarkRequestedModalState.filingId,
      OperationsStatus.MaterialsRequested,
    );
    return setUnmarkRequestedModalState({ isOpen: false, filingId: 0 });
  };

  const renderRequestMaterialsButton = (filing: FilingListFiling) => {
    return filing.operationsStatus === OperationsStatus.MaterialsRequested ? (
      <GrayButton
        small={true}
        width={'150px'}
        m="0 auto"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          return unmarkMaterialsRequested(filing.id);
        }}
      >
        Requested
      </GrayButton>
    ) : (
      <Button
        small={true}
        width={'150px'}
        m="0 auto"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (filing.solicitor) {
            userIdEvent('Mark Requested Clicked', {
              filingId: filing.id,
              filingType: filing.type,
              solictorId: filing.solicitor.id,
            });
          }
          return markMaterialsRequested(filing.id);
        }}
      >
        Mark Requested
      </Button>
    );
  };

  return (
    <>
      <StatusBar
        count={filings.count}
        next={filings.next}
        onChangePage={onChangePage}
        previous={filings.previous}
        typeLabel="Proxy Tasks"
      />

      <Table
        columns={columns}
        ordering={ordering.requestMaterials}
        onSetOrdering={handleSetOrdering}
        variant="green"
        isLoading={isLoading}
      >
        {filings.results.map((filing) => {
          return (
            <TableRow
              key={filing.id}
              to={`old/filings/${filing.id}/mailings`}
              variant="green"
            >
              <Cell>{filing.issuer.companyName}</Cell>
              <Cell>
                <CusipCell
                  cusips={filing.securities.map((security) => security.cusip)}
                />
              </Cell>
              <Cell>{formatCamelCaseString(filing.type)}</Cell>
              <Cell align="right">
                {filing.recordDate ? formatDate(filing.recordDate) : ''}
              </Cell>
              <Cell align="right">
                {filing.stats &&
                  formatNumberWithComma(filing.stats.materialsRequired)}
              </Cell>
              <Cell>{filing.solicitorJobNumber}</Cell>
              <Cell>
                <SolicitorCell
                  id={filing.id}
                  type={filing.type}
                  actualMaterialsRequired={
                    filing.stats && filing.stats.materialsRequired
                  }
                  recordDate={filing.recordDate}
                  solicitor={filing.solicitor}
                  solicitorJobNumber={filing.solicitorJobNumber}
                />
              </Cell>
              <Cell align="center">{renderRequestMaterialsButton(filing)}</Cell>
            </TableRow>
          );
        })}
      </Table>

      <UnmarkRequestedModal
        isOpen={unmarkRequestedModalState.isOpen}
        onRequestClose={() =>
          setUnmarkRequestedModalState({ isOpen: false, filingId: 0 })
        }
        onUndoMarkRequested={handleUndoMarkRequested}
      />
    </>
  );
};

const columns: TableColumnsProps[] = [
  {
    name: 'Issuer',
    key: 'issuer',
    orderingKey: 'issuer__company_name',
  },
  {
    name: 'CUSIP',
    key: 'cusip',
  },
  {
    name: 'Filing Type',
    key: 'filing-type',
  },
  {
    name: 'Record Date',
    key: 'record-date',
    orderingKey: 'first_record_date',
    align: 'right',
  },
  {
    name: 'Materials',
    key: 'materials',
    align: 'right',
    orderingKey: 'stats__materials_required',
  },
  {
    name: 'Solicitor Job #',
    key: 'solicitor-job-number',
  },
  {
    name: 'Solicitor / Contact',
    key: 'solicitor-contact',
    orderingKey: 'solicitor__name',
  },
  {
    name: 'Action',
    key: 'action',
    align: 'center',
  },
];
