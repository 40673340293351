import React, { useState } from 'react';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { Field, FormikProps } from 'formik';
import { FilingDetailsFormValues, IFilingCreateValues } from '../types';
import { format, zonedTimeToUtc } from 'date-fns-tz';
type DateProps = {
  formikBag:
    | FormikProps<FilingDetailsFormValues>
    | FormikProps<IFilingCreateValues>;
  name: string;
  label: string;
  required?: boolean;
};

const FORMAT = 'MM/DD/YYYY';
const DASH_FORMAT = 'yyyy-MM-dd';

export const FilingDatePicker = ({
  name,
  label,
  required = false,
  formikBag,
}: DateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Field
      InputLabelProps={{ shrink: true }}
      component={KeyboardDatePicker}
      fullWidth
      required={required}
      label={label}
      name={name}
      format={FORMAT}
      variant="inline"
      inputVariant="outlined"
      size="small"
      allowKeyboardControl={true}
      KeyboardButtonProps={{
        onFocus: () => {
          setIsOpen(true);
        },
        onBlur: () => {
          setIsOpen(false);
        },
      }}
      PopoverProps={{
        disableEnforceFocus: true,
        disableRestoreFocus: true,
        disableAutoFocus: true,
        onClose: () => {
          setIsOpen(false);
        },
      }}
      InputProps={{
        onFocus: () => {
          setIsOpen(true);
        },
      }}
      open={isOpen}
      onChange={(selectedDate: any, value: string) => {
        const formatDate = (date: any) => {
          if (!date) return new Date().toLocaleString();
          // Get the timezone from browser using native methods
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const dateTmp = Date.parse(date.toLocaleString());
          const localDate = zonedTimeToUtc(dateTmp, timezone);
          return format(localDate, DASH_FORMAT);
        };
        if (selectedDate.isValid()) {
          selectedDate = formatDate(selectedDate);
        }
        formikBag.setFieldValue(name, selectedDate);
        setIsOpen(false);
      }}
    />
  );
};
