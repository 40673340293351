import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

type Props = {
  required: boolean;
};

export const InvoicerContactEmail = ({ required }: Props) => {
  return (
    <Field
      component={TextField}
      fullWidth
      size="small"
      label="Invoicer Email Address"
      name="invoicerContactEmail"
      variant="outlined"
      required={required}
    />
  );
};
