import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import {
  IVoteProposalFormValues,
  SayOnPayRecTypes,
  IElectionItemsFormValues,
} from '../../models/vote-proposal';
import { InputGroup } from '../InputGroup';
import { Label } from '../Label';
import { Asterisk } from '../Asterik/Asterik';
import { Select } from '../Select';
import styled from 'styled-components/macro';

const StyledSelect = styled(Select)`
  text-transform: capitalize;
`;

export const RecommendationType: FC<{}> = () => {
  const sayOnPayRecommendationTypes = Object.values(SayOnPayRecTypes);

  return (
    <Field
      name="recommendation"
      render={({
        field,
        form,
      }: FieldProps<IVoteProposalFormValues | IElectionItemsFormValues>) => {
        return (
          <InputGroup>
            <Label htmlFor="recommendation">
              Recommendation Type <Asterisk />
            </Label>
            <StyledSelect id="recommendation" {...field}>
              {form.values.proposalType === 'SayOnPay'
                ? sayOnPayRecommendationTypes.map((recommendationType) => (
                    <option key={recommendationType} value={recommendationType}>
                      {recommendationType.split('_').join(' ')}
                    </option>
                  ))
                : form.values.proposalType === 'Corporate Action'
                ? [
                    ...(form.values as IElectionItemsFormValues).electionType.split(
                      '_',
                    ),
                    'none',
                  ].map((voteChoice) => (
                    <option key={voteChoice} value={voteChoice}>
                      {voteChoice}
                    </option>
                  ))
                : [
                    ...(form.values as IVoteProposalFormValues).voteType.split(
                      ', ',
                    ),
                    'None',
                  ].map((voteChoice) => (
                    <option key={voteChoice} value={voteChoice}>
                      {voteChoice}
                    </option>
                  ))}
              }
            </StyledSelect>
          </InputGroup>
        );
      }}
    />
  );
};
