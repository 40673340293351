import { Formik, Form, Field } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { Send } from '@material-ui/icons';
import {
  InputAdornment,
  CircularProgress,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import styled from 'styled-components';
import { useSendTestEmail } from './useSendTestEmail';
import Alert from '@material-ui/lab/Alert';

interface FormValues {
  email: string;
}

interface Props {
  filingId: string;
  selectedBroker: string;
}

const initialValues: FormValues = { email: '' };

const validationSchema = Yup.object().shape({
  email: Yup.string().email('').required(''),
});

function SendTestForm({ filingId, selectedBroker }: Props) {
  const { sendTestEmail } = useSendTestEmail();
  const [open, setOpen] = useState(false);

  function handleClose(
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            actions.setSubmitting(true);
            await sendTestEmail(filingId, selectedBroker, {
              emails: values.email.replace(/\s/g, '').split(','),
            });
            setOpen(true);
            actions.setSubmitting(false);
            actions.resetForm();
          } catch (_) {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field
              name="email"
              placeholder="Send test emails (separated by comma)"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isSubmitting ? (
                      <CircularProgress size={20} />
                    ) : (
                      <IconButton
                        size="small"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SendIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              component={Input}
            />
          </Form>
        )}
      </Formik>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Your email was successfully sent!"
      >
        <Alert
          onClose={handleClose}
          severity="success"
          elevation={6}
          variant="filled"
        >
          Test email was successfully sent!
        </Alert>
      </Snackbar>
    </>
  );
}

const Input = styled(TextField)`
  width: 450px;
  padding: 0;

  .MuiFormHelperText-root {
    display: none;
  }

  input {
    padding: 8px 16px;
    font-size: 14px;
`;

const SendIcon = styled(Send)`
  color: rgba(0, 0, 0, 0.33);
`;

export { SendTestForm };
