import React, { FC, ChangeEvent, useContext } from 'react';
import { Field, FieldProps } from 'formik';
import { IVoteProposalFormValues } from '../../models/vote-proposal';
import { InputGroup } from '../InputGroup';
import { Label } from '../Label';
import { Asterisk } from '../Asterik/Asterik';
import { Select } from '../Select';
import { FilingContext } from '../../data/Filing.Context';
import { FilingType } from '../../models/filing-type';
import { ErrorLabel } from '../ErrorLabel';

export const VoteType: FC<{}> = () => {
  const { filing } = useContext(FilingContext);

  return (
    <Field
      name="voteType"
      render={({ field, form }: FieldProps<IVoteProposalFormValues>) => {
        return (
          <InputGroup>
            <Label htmlFor="voteType">
              Vote Type <Asterisk />{' '}
              {form.errors['voteType'] && (
                <ErrorLabel>{form.errors['voteType']}</ErrorLabel>
              )}
            </Label>
            <Select
              id="voteType"
              {...field}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                form.setFieldValue('voteType', e.target.value);
                const voteTypes = e.target.value.split(', ');
                const foundVoteType = voteTypes.find(
                  (voteType: string) => voteType === form.values.recommendation,
                );
                !foundVoteType &&
                  form.setFieldValue('recommendation', voteTypes[0]);
              }}
            >
              {form.values.proposalType === 'SayOnPay' ? (
                <option value="One year, Two years, Three years, Abstain">
                  One year, Two years, Three years, Abstain
                </option>
              ) : (
                <>
                  <option value="For, Against">For, Against</option>
                  <option value="For, Abstain">For, Abstain</option>
                  <option value="For, Against, Abstain">
                    For, Against, Abstain
                  </option>
                  <option value="For, Against, Withhold">
                    For, Against, Withhold
                  </option>
                  <option value="For, Withhold">For, Withhold</option>

                  <option value="For, Withhold, Abstain">
                    For, Withhold, Abstain
                  </option>
                  <option value="Yes, No">Yes, No</option>

                  {filing &&
                    filing.type === FilingType.FirmConsentSolicitation && (
                      <option value="Revoke My Consent, Do Not Revoke My Consent, Abstain">
                        Revoke my consent, Do not revoke my consent, Abstain
                      </option>
                    )}
                </>
              )}
            </Select>
          </InputGroup>
        );
      }}
    />
  );
};
