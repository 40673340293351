import React, { ReactNode } from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import Box from '@material-ui/core/Box';

import {
  FilingDetailsFormValues,
  FilingType,
  IFilingCreateValues,
} from '../types';
import { InvoicerAddressFields } from './InvoicerAddressFields';
import { InvoicerContactEmail } from './InvoicerContactEmail';
import {
  SolicitorTypeahead,
  SolicitorContactsTypeahead,
  TabulatorContactsTypeahead,
  TabulatorTypeahead,
} from '../../../components/typeaheads';

type ContactProps = {
  formikBag:
    | FormikProps<FilingDetailsFormValues>
    | FormikProps<IFilingCreateValues>;
  isDraft: boolean;
};

type Company = {
  id: number;
  name: string;
  address: null | string;
  companyContacts: CompanyContact[];
  defaultTabulator?: DefaultTabulator | null;
  type: CompanyType;
};

export interface CompanyContact {
  id: number;
  contact: Contact;
  role: Role;
  isDefaultForRole: boolean;
}

export interface Contact {
  id: number;
  name: string;
  email: string;
  phone: null | string;
}

type Role = 'other' | 'solicitor_reply_to' | 'tabulator_reply_to';

type DefaultTabulator = {
  id: number;
  name: string;
  address: string;
  companyContacts: CompanyContact[];
};

type CompanyType = 'Solicitor' | 'Tabulator';

export const ContactDetails: React.FC<ContactProps> = ({
  formikBag,
  isDraft,
}) => {
  const { solicitor, tabulator } = formikBag.values;
  let { type } = formikBag.values;
  type = type.replace(/\s/g, '') as FilingType;

  function setReplyToContactsFromSolicitor(solicitor: Company) {
    const replyToContacts = solicitor.companyContacts.filter(
      (companyContact) =>
        companyContact.role === 'solicitor_reply_to' &&
        companyContact.isDefaultForRole,
    );
    formikBag.setFieldValue('replyTo', replyToContacts);
  }

  function setDefaultTabulatorFromCompany(company: Company) {
    if (company.defaultTabulator) {
      formikBag.setFieldValue('tabulator', company.defaultTabulator);
    }
  }

  function setSendToContactsFromCompany(company: Company) {
    if (company.defaultTabulator) {
      const sendToTabulatorContacts = company.defaultTabulator.companyContacts.filter(
        (companyContact) =>
          companyContact.role === 'tabulator_reply_to' &&
          companyContact.isDefaultForRole,
      );
      formikBag.setFieldValue('sendTo', sendToTabulatorContacts);
    }
  }

  function filterReplyToContacts(solicitor: Company) {
    return solicitor.companyContacts.filter(
      (companyContact) => companyContact.role === 'solicitor_reply_to',
    );
  }

  function filterSendToContacts(tabulator: Company) {
    return tabulator.companyContacts.filter(
      (companyContact) => companyContact.role === 'tabulator_reply_to',
    );
  }

  const errorStyle = {
    color: '#d33f33',
    fontSize: '.75rem',
    margin: '3px 0 0 14px',
  };

  const fields = (): ReactNode => {
    switch (type) {
      case 'FundReport':
        return (
          <>
            <Box>
              <Field name="solicitor">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <SolicitorTypeahead
                    onClear={() => form.setFieldValue('solicitor', null)}
                    onSelect={(solicitor) => {
                      form.setFieldValue('solicitor', solicitor);
                      setReplyToContactsFromSolicitor(solicitor);
                    }}
                    value={field.value}
                  />
                )}
              </Field>
              {formikBag.errors.solicitor && (
                <div style={errorStyle}>{formikBag.errors.solicitor}</div>
              )}
              {solicitor && (
                <Field name="replyTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <SolicitorContactsTypeahead
                      value={field.value}
                      options={filterReplyToContacts(solicitor as any)}
                      onSetSolicitorContacts={(contacts) =>
                        form.setFieldValue('replyTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
              {formikBag.errors.replyTo && (
                <div style={errorStyle}>{formikBag.errors.replyTo}</div>
              )}
            </Box>

            <Box>
              <Field
                mb={2}
                component={TextField}
                fullWidth
                size="small"
                required={!isDraft}
                label="Fund Owner"
                name="fundOwner"
                variant="outlined"
              />
            </Box>
            <Box pt={2}>
              <InvoicerContactEmail
                required={formikBag.values.invoicerAddress ? false : !isDraft}
              />
              <InvoicerAddressFields
                formikBag={formikBag}
                required={!isDraft}
              />
              <Field
                component={TextField}
                size="small"
                name="notes"
                label="Notes"
                fullWidth
                multiline
                rows={5}
                variant="outlined"
              />
            </Box>
          </>
        );
      case 'FundMeeting':
        return (
          <>
            <Box>
              <Field name="solicitor">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <SolicitorTypeahead
                    onClear={() => form.setFieldValue('solicitor', null)}
                    onSelect={(solicitor) => {
                      form.setFieldValue('solicitor', solicitor);
                      setReplyToContactsFromSolicitor(solicitor);
                      setDefaultTabulatorFromCompany(solicitor);
                      setSendToContactsFromCompany(solicitor);
                    }}
                    value={field.value}
                  />
                )}
              </Field>
              {formikBag.errors.solicitor && (
                <div style={errorStyle}>{formikBag.errors.solicitor}</div>
              )}
              {solicitor && (
                <Field name="replyTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <SolicitorContactsTypeahead
                      value={field.value}
                      options={filterReplyToContacts(solicitor as any)}
                      onSetSolicitorContacts={(contacts) =>
                        form.setFieldValue('replyTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
              {formikBag.errors.replyTo && (
                <div style={errorStyle}>{formikBag.errors.replyTo}</div>
              )}
              <Field name="tabulator">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <TabulatorTypeahead
                    onClear={() => form.setFieldValue('tabulator', null)}
                    onSelect={(tabulator) => {
                      form.setFieldValue('tabulator', tabulator);
                      setSendToContactsFromCompany(tabulator);
                    }}
                    value={field.value}
                    required={!isDraft}
                  />
                )}
              </Field>

              {tabulator && (
                <Field name="sendTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <TabulatorContactsTypeahead
                      value={field.value}
                      options={filterSendToContacts(tabulator as any)}
                      onSetTabulatorContacts={(contacts) =>
                        form.setFieldValue('sendTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
            </Box>
            <Box pt={2}>
              <Field
                component={TextField}
                size="small"
                fullWidth
                required={!isDraft}
                label="Fund Owner"
                name="fundOwner"
                variant="outlined"
              />{' '}
            </Box>
            <Box pt={2}>
              <InvoicerContactEmail
                required={formikBag.values.invoicerAddress ? false : !isDraft}
              />
              <InvoicerAddressFields
                formikBag={formikBag}
                required={!isDraft}
              />
              <Field
                component={TextField}
                name="notes"
                label="Notes"
                size="small"
                fullWidth
                multiline
                rows={5}
                variant="outlined"
              />
            </Box>
          </>
        );
      case 'FirmMeeting':
        return (
          <>
            <Box>
              <Field name="solicitor">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <SolicitorTypeahead
                    onClear={() => form.setFieldValue('solicitor', null)}
                    onSelect={(solicitor) => {
                      form.setFieldValue('solicitor', solicitor);
                      setReplyToContactsFromSolicitor(solicitor);
                      setDefaultTabulatorFromCompany(solicitor);
                      setSendToContactsFromCompany(solicitor);
                    }}
                    value={field.value}
                  />
                )}
              </Field>
              {formikBag.errors.solicitor && (
                <div style={errorStyle}>{formikBag.errors.solicitor}</div>
              )}
              {solicitor && (
                <Field name="replyTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <SolicitorContactsTypeahead
                      value={field.value}
                      options={filterReplyToContacts(solicitor as any)}
                      onSetSolicitorContacts={(contacts) =>
                        form.setFieldValue('replyTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
              {formikBag.errors.replyTo && (
                <div style={errorStyle}>{formikBag.errors.replyTo}</div>
              )}
              <Field name="tabulator">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <TabulatorTypeahead
                    onClear={() => form.setFieldValue('tabulator', null)}
                    onSelect={(tabulator) => {
                      form.setFieldValue('tabulator', tabulator);
                      setSendToContactsFromCompany(tabulator);
                    }}
                    value={field.value}
                    required={!isDraft}
                  />
                )}
              </Field>

              {tabulator && (
                <Field name="sendTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <TabulatorContactsTypeahead
                      value={field.value}
                      options={filterSendToContacts(tabulator as any)}
                      onSetTabulatorContacts={(contacts) =>
                        form.setFieldValue('sendTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
            </Box>
            <Box pt={2}>
              <InvoicerContactEmail
                required={formikBag.values.invoicerAddress ? false : !isDraft}
              />
              <InvoicerAddressFields
                formikBag={formikBag}
                required={!isDraft}
              />
              <Field
                component={TextField}
                name="notes"
                label="Notes"
                fullWidth
                size="small"
                multiline
                rows={5}
                variant="outlined"
              />
            </Box>
          </>
        );
      case 'FirmInformationStatement':
        return (
          <>
            <Box>
              <Field name="solicitor">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <SolicitorTypeahead
                    onClear={() => form.setFieldValue('solicitor', null)}
                    onSelect={(solicitor) => {
                      form.setFieldValue('solicitor', solicitor);
                      setReplyToContactsFromSolicitor(solicitor);
                    }}
                    value={field.value}
                  />
                )}
              </Field>
              {formikBag.errors.solicitor && (
                <div style={errorStyle}>{formikBag.errors.solicitor}</div>
              )}
              {solicitor && (
                <Field name="replyTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <SolicitorContactsTypeahead
                      value={field.value}
                      options={filterReplyToContacts(solicitor as any)}
                      onSetSolicitorContacts={(contacts) =>
                        form.setFieldValue('replyTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
              {formikBag.errors.replyTo && (
                <div style={errorStyle}>{formikBag.errors.replyTo}</div>
              )}
            </Box>

            <Box pt={2}>
              <InvoicerContactEmail
                required={formikBag.values.invoicerAddress ? false : !isDraft}
              />
              <InvoicerAddressFields
                formikBag={formikBag}
                required={!isDraft}
              />
              <Field
                component={TextField}
                name="notes"
                label="Notes"
                size="small"
                fullWidth
                multiline
                rows={5}
                variant="outlined"
              />
            </Box>
          </>
        );
      case 'FundInformationStatement':
        return (
          <>
            <Box>
              <Field name="solicitor">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <SolicitorTypeahead
                    onClear={() => form.setFieldValue('solicitor', null)}
                    onSelect={(solicitor) => {
                      form.setFieldValue('solicitor', solicitor);
                      setReplyToContactsFromSolicitor(solicitor);
                    }}
                    value={field.value}
                  />
                )}
              </Field>
              {formikBag.errors.solicitor && (
                <div style={errorStyle}>{formikBag.errors.solicitor}</div>
              )}
              {solicitor && (
                <Field name="replyTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <SolicitorContactsTypeahead
                      value={field.value}
                      options={filterReplyToContacts(solicitor as any)}
                      onSetSolicitorContacts={(contacts) =>
                        form.setFieldValue('replyTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
              {formikBag.errors.replyTo && (
                <div style={errorStyle}>{formikBag.errors.replyTo}</div>
              )}
            </Box>

            <Box pt={2}>
              <Field
                component={TextField}
                fullWidth
                required={!isDraft}
                label="Fund Owner"
                name="fundOwner"
                variant="outlined"
                size="small"
              />
            </Box>
            <Box pt={2}>
              <InvoicerContactEmail
                required={formikBag.values.invoicerAddress ? false : !isDraft}
              />
              <InvoicerAddressFields
                formikBag={formikBag}
                required={!isDraft}
              />
              <Field
                component={TextField}
                name="notes"
                label="Notes"
                size="small"
                fullWidth
                multiline
                rows={5}
                variant="outlined"
              />
            </Box>
          </>
        );
      case 'FirmConsentSolicitation':
        return (
          <>
            <Box>
              <Field name="solicitor">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <SolicitorTypeahead
                    onClear={() => form.setFieldValue('solicitor', null)}
                    onSelect={(solicitor) => {
                      form.setFieldValue('solicitor', solicitor);
                      setReplyToContactsFromSolicitor(solicitor);
                      setDefaultTabulatorFromCompany(solicitor);
                      setSendToContactsFromCompany(solicitor);
                    }}
                    value={field.value}
                  />
                )}
              </Field>
              {formikBag.errors.solicitor && (
                <div style={errorStyle}>{formikBag.errors.solicitor}</div>
              )}
              {solicitor && (
                <Field name="replyTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <SolicitorContactsTypeahead
                      value={field.value}
                      options={filterReplyToContacts(solicitor as any)}
                      onSetSolicitorContacts={(contacts) =>
                        form.setFieldValue('replyTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
              {formikBag.errors.replyTo && (
                <div style={errorStyle}>{formikBag.errors.replyTo}</div>
              )}
              <Field name="tabulator">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <TabulatorTypeahead
                    onClear={() => form.setFieldValue('tabulator', null)}
                    onSelect={(tabulator) => {
                      form.setFieldValue('tabulator', tabulator);
                      setSendToContactsFromCompany(tabulator);
                    }}
                    value={field.value}
                    required={!isDraft}
                  />
                )}
              </Field>

              {tabulator && (
                <Field name="sendTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <TabulatorContactsTypeahead
                      value={field.value}
                      options={filterSendToContacts(tabulator as any)}
                      onSetTabulatorContacts={(contacts) =>
                        form.setFieldValue('sendTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
            </Box>

            <Box pt={2}>
              <InvoicerContactEmail
                required={formikBag.values.invoicerAddress ? false : !isDraft}
              />
              <InvoicerAddressFields
                formikBag={formikBag}
                required={!isDraft}
              />
              <Field
                component={TextField}
                name="notes"
                label="Notes"
                size="small"
                fullWidth
                multiline
                rows={5}
                variant="outlined"
              />
            </Box>
          </>
        );
      case 'MeetingContest':
        return (
          <>
            <Box>
              <Field name="solicitor">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <SolicitorTypeahead
                    onClear={() => form.setFieldValue('solicitor', null)}
                    onSelect={(solicitor) => {
                      form.setFieldValue('solicitor', solicitor);
                      setReplyToContactsFromSolicitor(solicitor);
                      setDefaultTabulatorFromCompany(solicitor);
                      setSendToContactsFromCompany(solicitor);
                    }}
                    value={field.value}
                  />
                )}
              </Field>
              {formikBag.errors.solicitor && (
                <div style={errorStyle}>{formikBag.errors.solicitor}</div>
              )}
              {solicitor && (
                <Field name="replyTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <SolicitorContactsTypeahead
                      value={field.value}
                      options={filterReplyToContacts(solicitor as any)}
                      onSetSolicitorContacts={(contacts) =>
                        form.setFieldValue('replyTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
              {formikBag.errors.replyTo && (
                <div style={errorStyle}>{formikBag.errors.replyTo}</div>
              )}
              <Field name="tabulator">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <TabulatorTypeahead
                    onClear={() => form.setFieldValue('tabulator', null)}
                    onSelect={(tabulator) => {
                      form.setFieldValue('tabulator', tabulator);
                      setSendToContactsFromCompany(tabulator);
                    }}
                    value={field.value}
                    required={!isDraft}
                  />
                )}
              </Field>

              {tabulator && (
                <Field name="sendTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <TabulatorContactsTypeahead
                      value={field.value}
                      options={filterSendToContacts(tabulator as any)}
                      onSetTabulatorContacts={(contacts) =>
                        form.setFieldValue('sendTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
            </Box>

            <Box pt={2}>
              <InvoicerContactEmail
                required={formikBag.values.invoicerAddress ? false : !isDraft}
              />
              <InvoicerAddressFields
                formikBag={formikBag}
                required={!isDraft}
              />
              <Field
                component={TextField}
                name="notes"
                label="Notes"
                size="small"
                fullWidth
                multiline
                rows={5}
                variant="outlined"
              />
            </Box>
          </>
        );
      default:
        return (
          <>
            <Box>
              <Field name="solicitor">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <SolicitorTypeahead
                    onClear={() => form.setFieldValue('solicitor', null)}
                    onSelect={(solicitor) => {
                      form.setFieldValue('solicitor', solicitor);
                      setReplyToContactsFromSolicitor(solicitor);
                      setDefaultTabulatorFromCompany(solicitor);
                      setSendToContactsFromCompany(solicitor);
                    }}
                    value={field.value}
                  />
                )}
              </Field>
              {formikBag.errors.solicitor && (
                <div style={errorStyle}>{formikBag.errors.solicitor}</div>
              )}
              {solicitor && (
                <Field name="replyTo">
                  {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                    <SolicitorContactsTypeahead
                      value={field.value}
                      options={filterReplyToContacts(solicitor as any)}
                      onSetSolicitorContacts={(contacts) =>
                        form.setFieldValue('replyTo', contacts)
                      }
                    />
                  )}
                </Field>
              )}
              {formikBag.errors.replyTo && (
                <div style={errorStyle}>{formikBag.errors.replyTo}</div>
              )}
            </Box>

            <Box pt={2}>
              <InvoicerContactEmail
                required={formikBag.values.invoicerAddress ? false : !isDraft}
              />
              <InvoicerAddressFields
                formikBag={formikBag}
                required={!isDraft}
              />
              <Field
                component={TextField}
                name="notes"
                size="small"
                label="Notes"
                fullWidth
                multiline
                rows={5}
                variant="outlined"
              />
            </Box>
          </>
        );
    }
  };

  return <>{fields()}</>;
};
