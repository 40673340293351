/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { RouteComponentProps, Redirect, Router } from '@reach/router';
import { EmailPreview } from '../../EmailPreview/EmailPreview';
import { Flex } from '@rebass/grid';
import { Tab } from '../../common/Tab';
import { Text } from '../../common/Text';
import { Tabs } from '../../common/Tabs';
import styled from 'styled-components/macro';
import { InProgress, ReviewEmails, OnGoing } from './Tabs';
import { Ordering } from '../../data/Ordering.Context';
import { CorporateActions as CorporateActionsData } from '../../data/CorporateActions';
import { CorporateActionsLayout } from './CorporateActionsLayout';

const StickyTabs = styled(Flex)`
  position: sticky;
  top: 73px;
  z-index: 1;
`;

enum TabChoice {
  InProgress = 'ca_in_progress',
  ReviewEmails = 'ca_review_emails',
  OnGoing = 'ca_ongoing',
}

export const CorporateActions: FC<RouteComponentProps> = () => {
  const { ordering } = Ordering.useContainer();
  const {
    corporateActions,
    getTasksFilingByTab,
    changePage,
    markReadyForReview,
    unmarkReadyForReview,
  } = CorporateActionsData.useContainer();

  useEffect(() => {
    getTasksFilingByTab(
      TabChoice.InProgress,
      ordering.caInProgress,
      corporateActions.search,
    );
  }, [ordering.caInProgress, corporateActions.search]);

  useEffect(() => {
    getTasksFilingByTab(
      TabChoice.ReviewEmails,
      ordering.caReviewEmails,
      corporateActions.search,
    );
  }, [ordering.caReviewEmails, corporateActions.search]);

  useEffect(() => {
    getTasksFilingByTab(
      TabChoice.OnGoing,
      ordering.caOnGoing,
      corporateActions.search,
    );
  }, [ordering.caOnGoing, corporateActions.search]);

  return (
    <CorporateActionsLayout>
      <StickyTabs flexDirection="column" width={1} bg="white">
        <Tabs columns={3}>
          <Tab link="in-progress">
            <Flex justifyContent="space-between">
              <Text>In Progress</Text>
              <Text className="count">
                {corporateActions.inProgress.filings.count}
              </Text>
            </Flex>
          </Tab>

          <Tab link="review-emails">
            <Flex justifyContent="space-between">
              <Text>Review Emails</Text>
              <Text className="count">
                {corporateActions.reviewEmails.filings.count}
              </Text>
            </Flex>
          </Tab>
          <Tab link="on-going">
            <Flex justifyContent="space-between">
              <Text>On Going</Text>
              <Text className="count">
                {corporateActions.ongoing.filings.count}
              </Text>
            </Flex>
          </Tab>
        </Tabs>
      </StickyTabs>

      <Router>
        <Redirect
          from="/"
          to="/old/filings/corporate-actions/in-progress"
          noThrow={true}
        />
        <InProgress
          path="in-progress"
          isLoading={corporateActions.isLoading}
          filings={corporateActions.inProgress.filings}
          onChangePage={changePage}
          onMarkReadyForReview={markReadyForReview}
          onUnmarkReadyForReview={unmarkReadyForReview}
        />
        <ReviewEmails
          path="review-emails"
          isLoading={corporateActions.isLoading}
          filings={corporateActions.reviewEmails.filings}
          onChangePage={changePage}
        >
          <EmailPreview path=":id" isCorpAction={true} />
        </ReviewEmails>
        <OnGoing
          path="on-going"
          isLoading={corporateActions.isLoading}
          filings={corporateActions.ongoing.filings}
          onChangePage={changePage}
        />
      </Router>
    </CorporateActionsLayout>
  );
};
