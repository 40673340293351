import { privateApi } from '../../old/utils/api-adapter';
import useSWR from 'swr';
import { DocumentType, OperationsStatus } from './types';

type CorporateAction = {
  announcementDate: string;
  applicableDates: string[];
  bidIncrement?: string;
  brokers: Broker[];
  consentPayment: string;
  contraCusip?: string;
  documents: Document[];
  dripReinvestmentPrice?: string;
  dtcExpirationDate: null | string;
  election: Election;
  electionCutoffDatetime: string;
  frequency?: string;
  grossDividend?: string;
  id: number;
  informationAgent: null;
  issuer: Issuer;
  maximumBidPrice?: string;
  maximumQualifyingQuantity?: string;
  minimumBidPrice?: string;
  minimumQualifyingQuantity?: string;
  notificationReceivedDate: null;
  offererParty?: string;
  operationsStatus: OperationsStatus;
  outTurn?: string;
  price?: string;
  ratio?: string;
  recordDate: null | string;
  securities: Security[];
  tradeSecurity?: string;
  type: string;
};

type Broker = {
  id: number;
  name: string;
  slug: string;
  theme: Theme;
  introducingBroker: string;
  isElectronicOnly: boolean;
};

type Theme = {
  primaryColor: null | string;
  secondaryColor: null | string;
  bannerBackgroundColor: null | string;
  bannerTextColor: null | string;
  bannerGradient: null | string;
  ctaBackgroundColor: null | string;
  ctaTextColor: null | string;
  linkColor: null | string;
  logo: null | string;
  logoMultiplier: null | string;
  logoEmailWidth: null | number;
  logoEmailHeight: null | number;
  brokerTermsUrl: null | string;
  brokerPrivacyUrl: null | string;
};

type Document = {
  attachment: string;
  corporateAction: number;
  filing: null | string;
  id: number;
  isInternal: boolean;
  name: string;
  pdf: null | string;
  securities: any[];
  should_distribute_in_next_mailing: boolean;
  sort_order: null | number;
  type: DocumentType;
};

type Election = {
  id: number;
  title: string;
  detail: string;
  electionChoices: string[];
};

type Issuer = {
  id: number;
  companyName: string;
  contactEmail: string;
  cik: null | string;
  edgarLandingPage: null | string;
};

type Security = {
  id: number;
  created: string;
  modified: string;
  ticker: string;
  cusip: string;
  name: string;
  isin: null | string;
  issuer: number;
};

const fetcher = (url: string) => privateApi.get<CorporateAction>(url);

function useCorporateAction(id: string | undefined) {
  const { data, mutate, error } = useSWR(`/admin/vca/${id}/`, fetcher);

  return { data, mutate, error };
}

export { useCorporateAction };
