import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface ErrorMessage {
  [key: string]: string[];
}

const Item = styled.li`
  text-transform: capitalize;
`;

const formatError = (error: ErrorMessage) => {
  return Object.values(error).flat();
};

interface ToastErrorListProps {
  error: ErrorMessage;
}

const ToastErrorList: FC<ToastErrorListProps> = ({ error }) => {
  const formattedErrors = formatError(error);

  return (
    <ul>
      {formattedErrors.map((error: string) => (
        <Item key={error}>{error}</Item>
      ))}
    </ul>
  );
};

export { ToastErrorList };
