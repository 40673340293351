import React from 'react';
import { Field, FieldProps } from 'formik';
import { GenericDate } from '../../common/FormComponents/GenericDate';

export const ProxyFields = () => {
  return (
    <>
      <Field
        name="brokerSearchReceivedDate"
        render={({ field, form }: FieldProps<any>) => (
          <GenericDate
            field={field}
            form={form}
            label="Broker Search Date"
            fieldName={field.name}
          />
        )}
      />

      <Field
        name="expectedDate"
        render={({ field, form }: FieldProps<any>) => (
          <GenericDate
            field={field}
            form={form}
            label="Expected Date"
            fieldName={field.name}
          />
        )}
      />

      <Field
        name="digitalMaterialsReceivedDate"
        render={({ field, form }: FieldProps<any>) => (
          <GenericDate
            field={field}
            form={form}
            label="Digitial Received Date"
            fieldName={field.name}
          />
        )}
      />

      <Field
        name="paperMaterialsReceivedDate"
        render={({ field, form }: FieldProps<any>) => (
          <GenericDate
            field={field}
            form={form}
            label="Paper Received Date"
            fieldName={field.name}
          />
        )}
      />
    </>
  );
};
