import { Text, TextProps } from './Text';
import React, { ReactNode } from 'react';

interface LabelProps extends TextProps {
  children: ReactNode;
  htmlFor?: string;
}

export const Label = (props: LabelProps) => (
  <Text
    {...props}
    as="label"
    color="black"
    fontSize={0}
    fontWeight={4}
    mb={1}
  />
);
