import React, { FC, ReactNode } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Button } from '../../common/Buttons/Button';
import styled from 'styled-components/macro';
import { NewFiling } from '../../NewFiling/NewFiling';
import { Search } from '../../common/Search/Search';
import { Navigation } from '../../common/Navigation';
import { SayLogotype } from '../../common/SayLogotype';
import { FilingTypeSearch } from '../../common/Search/FilingTypeSearch';
import { FilingType } from '../../models/filing-type';
import { Proxy as ProxyContext } from '../../data/Proxy';
import { userIdEvent } from '../../utils/analytics-helpers';

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 73px 1fr;
  grid-template-areas:
    'header header'
    'nav body';
`;

const Header = styled.header`
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  padding: 16px 0;
  position: sticky;
  top: 0;
  z-index: 1;
  grid-area: header;
`;

const ComponentContainer = styled.div`
  grid-area: body;
`;

const ProxyLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    proxy,
    setFilingTypeFilter,
    setSearch,
    setIsNewFilingDialogOpen,
  } = ProxyContext.useContainer();

  const handleSearch = (searchTerm: string) => {
    setSearch(searchTerm);
  };

  const handleChangeFilingType = (filingType: FilingType | '') => {
    setFilingTypeFilter(filingType);
  };

  return (
    <Container>
      <Navigation />

      <Header>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          px={3}
          width={1}
        >
          <Flex alignItems="center" width={2 / 3}>
            <SayLogotype />

            <Box ml={4} width={1 / 2}>
              <Search onSearch={handleSearch} searchTerm={proxy.search} />
            </Box>

            <Box ml={4} width={1 / 2}>
              <FilingTypeSearch
                filingType={proxy.filingTypeFilter}
                filterOutCorporateAction={true}
                onChange={handleChangeFilingType}
              />
            </Box>
          </Flex>

          <Flex width={1 / 3} justifyContent="flex-end">
            <Button
              onClick={() => {
                setIsNewFilingDialogOpen(true);
                userIdEvent('Create Filing Clicked', {});
              }}
            >
              Create New Filing
            </Button>
          </Flex>
        </Flex>
      </Header>

      <ComponentContainer>{children}</ComponentContainer>

      <NewFiling
        isOpen={proxy.isNewFilingDialogOpen}
        onClose={() => setIsNewFilingDialogOpen(false)}
      />
    </Container>
  );
};

export { ProxyLayout };
