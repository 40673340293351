import React from 'react';
import { ReactComponent as CustomerIcon } from './customer.svg';
import { ReactComponent as ChevronIcon } from './chevron.svg';
import { ReactComponent as CalendarIcon } from './calendar.svg';
import { formatDate } from '../../utils/format-date';
import { formatNumber } from '../../utils/format-number';
import { ProspectusRow } from '../../features/IssuerDetails/Prospectus/SecurityRow/ProspectusRow';

type ProspectusSecurityRowProps = {
  security: Security;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  showCheckmark?: boolean;
  className?: string;
  onUpdateSuccess?: () => void;
};

type Security = {
  id: string;
  cusip: string;
  ticker: null | string;
  name: string;
  securityType: null | string;
  price: null | string;
  priceLastUpdated: null | string;
  primaryColor: null | string;
  iconUrl: null | string;
  prospectuses: Prospectus[];
  stats: SecurityStats;
};

type SecurityStats = {
  onHoldTrades: number;
  monthlyTrades: number;
};

type Prospectus = {
  id: string;
  type: ProspectusType;
  name: string;
  frontLink: string;
  website: string;
  attachment: string;
  securities: string[];
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  description: string;
};

type ProspectusType =
  | 'summary'
  | 'sticker'
  | 'statement_of_additional_information'
  | 'statutory';

const ProspectusSecurityRow = ({
  security,
  onChange,
  isChecked,
  showCheckmark = true,
  className,
  onUpdateSuccess = () => false,
}: ProspectusSecurityRowProps) => {
  const hasProspectuses = security.prospectuses.length > 0;
  const [isExpanded, setIsExpanded] = React.useState(hasProspectuses);

  const summaryAndStatutoryProspectus = security.prospectuses.find(
    (prospectus) =>
      prospectus.type === 'summary' || prospectus.type === 'statutory',
  );
  const expectedDate =
    summaryAndStatutoryProspectus &&
    summaryAndStatutoryProspectus.effectiveEndDate;
  const isPastExpectedDate =
    expectedDate && determineIfPastToday(new Date(expectedDate));

  const toggleProspectusRows = () => setIsExpanded(!isExpanded);

  return (
    <React.Fragment>
      <div
        className={`grid ${
          showCheckmark
            ? 'grid-cols-prospectusSecurityRow'
            : 'grid-cols-prospectusSecurityRowWithoutCheckmark'
        } gap-4 items-center w-full border border-light-gray px-2 py-4 cursor-pointer bg-whitej ${
          className || ''
        }`}
        onClick={toggleProspectusRows}
      >
        {showCheckmark && (
          <label className="hover:bg-light-gray flex items-center justify-center rounded-md w-6 h-6">
            <input
              type="checkbox"
              onChange={onChange}
              onClick={(event) => event.stopPropagation()}
              checked={isChecked}
              className="appearance-none border border-gray h-4 w-4 bg-white checkbox focus:outline-none hover:border-primary-text cursor-pointer"
            />
          </label>
        )}
        <div className="border border-primary-text rounded text-xs px-1">
          {security.cusip}
        </div>
        <div>
          <span className="text-primary-text font-medium border-b border-green">
            {security.ticker}:
          </span>{' '}
          <span className="text-secondary-text">{security.name}</span>
        </div>
        <div className="flex items-center">
          <span className="text-primary-text font-medium">
            {formatNumber(security.stats.monthlyTrades)}
          </span>
          <CustomerIcon className="ml-2" />
        </div>
        <div
          className={`flex items-center ${
            isPastExpectedDate
              ? 'text-red'
              : expectedDate
              ? 'text-primary-text'
              : 'text-secondary-text'
          }`}
        >
          <span>
            {expectedDate ? formatDate(new Date(expectedDate)) : '-- -- -- --'}
          </span>
          <CalendarIcon className="text-secondary-text ml-3" />
        </div>

        <div
          className={`flex items-center ${
            !summaryAndStatutoryProspectus ? 'justify-between' : 'justify-end'
          }`}
        >
          {!summaryAndStatutoryProspectus && (
            <div className="bg-red text-white font-medium text-xxs h-3 w-3 rounded-full flex items-center justify-center mr-2">
              !
            </div>
          )}

          <div className={`mr-2 ${isExpanded ? 'transform rotate-90' : ''}`}>
            <ChevronIcon />
          </div>
        </div>
      </div>

      {isExpanded &&
        hasProspectuses &&
        security.prospectuses.map((prospectus: Prospectus) => (
          <ProspectusRow
            key={prospectus.id}
            isEditable={true}
            prospectus={prospectus}
            onUpdateSuccess={onUpdateSuccess}
          />
        ))}

      {isExpanded && !hasProspectuses && (
        <div className="p-8 border border-light-gray flex items-center justify-center w-full bg-white text-sm text-gray">
          No Documents
        </div>
      )}
    </React.Fragment>
  );
};

const determineIfPastToday = (date: Date) =>
  new Date().getTime() > date.getTime();

export { ProspectusSecurityRow };
