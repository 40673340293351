import React from 'react';
import { ReactComponent as VCAIcon } from '../../components/Icons/vca.svg';
import { ReactComponent as GrayVCAIcon } from '../../components/Icons/gray-vca.svg';
import { BackArrow } from '../../components/BackArrow';

type Props = {
  issuerName: string;
};

function Header({ issuerName }: Props) {
  return (
    <header className="bg-white p-8 pt-2">
      <div>
        <BackArrow to="/corporate-actions/tasks/new" />
      </div>

      <div className="flex items-center mt-5">
        {issuerName ? <VCAIcon /> : <GrayVCAIcon />}
        <h1
          className={`font-medium text-2xl ml-5 ${
            issuerName ? 'text-primary-text' : 'text-secondary-text'
          }`}
        >
          {issuerName ? issuerName : 'Filing'}
        </h1>
      </div>
    </header>
  );
}

export { Header };
