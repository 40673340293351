import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Flex } from '@rebass/grid';

import { CusipCell } from '../../common/List/CusipCell';
import { formatDate } from '../../utils/format-date';
import { StatusBar } from '../../common/StatusBar';
import { Ordering } from '../../data/Ordering.Context';
import { AllResults } from '../../data/SECFilings';
import Table, { TableColumnsProps } from '../../common/Table/Table';
import TableRow from '../../common/Table/TableRow';
import Cell from '../../common/Table/Cell';
import { ISECFilingList } from '../../models/sec-filing-list';
import { Security } from '../../models/filing-list';
import { StatusBox } from '../../common/edgar/StatusBox';

export const Dismissed: FC<RouteComponentProps> = () => {
  const { ordering, setOrder } = Ordering.useContainer();
  const { allResults, changePage } = AllResults.useContainer();
  const { results } = allResults.dismissed;
  const { isLoading } = allResults;

  const handleSetOrdering = (order: string) => {
    setOrder('resultsDismissed', order);
  };

  return (
    <>
      <StatusBar
        onChangePage={changePage}
        count={10}
        next={results.next}
        previous={results.previous}
        typeLabel="Dismissed Results"
      />

      <Table
        isLoading={isLoading}
        columns={columns}
        onSetOrdering={handleSetOrdering}
        ordering={ordering.resultsDismissed}
        variant="gray"
      >
        {results.results.map((result: ISECFilingList) => {
          return (
            <TableRow
              to={`old/results/all/${result.id}`}
              variant={'orange'}
              key={`${result.id}-dismissed`}
            >
              <Cell>{result.filing.issuer.companyName}</Cell>
              <Cell>
                <CusipCell
                  cusips={result.filing.securities.map(
                    (security: Security) => security.cusip,
                  )}
                />
              </Cell>
              <Cell align="right">
                {result.filing.meetingDate &&
                  formatDate(result.filing.meetingDate)}
              </Cell>
              <Cell align="right">
                <a href={result.url} target="_blank" rel="noopener noreferrer">
                  {formatDate(result.filingDate)}
                </a>
              </Cell>
              <Cell align="right">
                <Flex justifyContent="flex-end">
                  <StatusBox status={result.status}>{result.status}</StatusBox>
                </Flex>
              </Cell>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
};

const columns: TableColumnsProps[] = [
  {
    name: 'Issuer',
    key: 'issuer',
  },
  {
    name: 'CUSIP',
    key: 'cusip',
  },
  {
    name: 'Meeting Date',
    key: 'meeting-date',
    orderingKey: 'meeting_date',
    align: 'right',
  },
  {
    name: 'Edgar Date',
    key: 'edgar-date',
    align: 'right',
  },
  {
    name: 'Status',
    key: 'status',
    align: 'right',
  },
];
