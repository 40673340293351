import React from 'react';
import { Router, RouteComponentProps, Redirect } from '@reach/router';
import { Flex } from '@rebass/grid';
import { AllFilings } from './old/Filings/AllFilings';
import { Proxy } from './old/Filings/Proxy';
import { CorporateActions } from './old/Filings/CorporateActions';
import { Filing } from './old/Filing/Filing';
import { FiveHundred } from './old/ErrorPages/FiveHundered';
import { FourOhFour } from './old/ErrorPages/FourOhFour';

import { Funds } from './old/Issuers/Funds/Funds';
import { FundDetails } from './old/Issuers/Funds/FundDetails/FundDetails';
import { Companies } from './old/Issuers/Companies/Companies';
import { CompanyDetails } from './old/Issuers/Companies/CompanyDetails';
import { PrivateRoute } from './old/common/PrivateRoute';
import { MeetingResultsRoute } from './old/common/MeetingResultsRoute';
import { Results } from './old/SECFilings';
import { ResultsDetail } from './old/SECFilings/ResultsDetail';
import { globalHistory } from '@reach/router';
import { MeetingResultsEmailPreview } from './old/EmailPreview/MeetingResultsEmailPreview';

declare global {
  interface Window {
    analytics: any;
  }
}

globalHistory.listen(({ location }) =>
  window.analytics.page(location.pathname),
);

const Old: React.FC<RouteComponentProps> = () => (
  <Flex flexDirection="column">
    <Router>
      <PrivateRoute
        component={CorporateActions}
        path="filings/corporate-actions/*"
      />
      <Redirect
        from="/"
        to="/old/filings/proxy/request-materials"
        noThrow={true}
      />
      <PrivateRoute component={Proxy} path="filings/proxy/*" />
      <Redirect from="filings" to="/old/filings/all/open" noThrow={true} />
      <PrivateRoute component={AllFilings} path="filings/all/*" />
      <Redirect
        from="filings/:id"
        to="/old/filings/:id/mailings"
        noThrow={true}
      />
      <PrivateRoute component={Filing} path="filings/:id/*" />
      <Redirect
        from="issuers/"
        to="/old/issuers/funds?tab=all"
        noThrow={true}
      />
      <PrivateRoute component={Funds} path="issuers/funds/" />
      <PrivateRoute component={FundDetails} path="issuers/funds/:id/*" />
      <PrivateRoute component={Companies} path="issuers/companies" />
      <PrivateRoute component={CompanyDetails} path="issuers/companies/:id/*" />
      <MeetingResultsRoute component={Results} path="results/*" />
      <Redirect from="results" to="/old/results/pending" noThrow={true} />
      <MeetingResultsRoute component={ResultsDetail} path="results/all/:id" />
      <MeetingResultsRoute
        component={MeetingResultsEmailPreview}
        path="results/all/:id/email-preview"
      />
      <FiveHundred path="500" />
      <FourOhFour path="404" default={true} />
    </Router>
  </Flex>
);

export { Old };
