import React, { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { hubApi } from '../../old/data/hubApiConfig';
import { LoadingState } from '../LoadingState';
import { ReactComponent as CalendarIcon } from '../../components/Icons/calendar.svg';
import { ProspectusSecurityRow } from '../ProspectusSecurityRow';
import { UploadView } from './UploadView';
import { format } from 'date-fns';
import axios from 'axios';
import { toast } from 'react-toastify';
import PublishIcon from '@material-ui/icons/Publish';
import { Link } from '@reach/router';
import DatePicker from 'react-datepicker';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';

type Response = {
  id: string;
  name: string;
  issuerType: string;
  fiscalYearEnd: null | string;
  fundOwner: null | string;
  cik: null | string;
  securities: Security[];
  stats: ResultStats;
};

type Security = {
  id: string;
  cusip: string;
  ticker: null | string;
  name: string;
  securityType: null | string;
  price: null | string;
  priceLastUpdated: null | string;
  primaryColor: null | string;
  iconUrl: null | string;
  prospectuses: Prospectus[];
  stats: SecurityStats;
};

type Prospectus = {
  id: string;
  type: ProspectusType;
  name: string;
  frontLink: string;
  website: string;
  attachment: string;
  securities: string[];
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  description: string;
};

type ProspectusType =
  | 'summary'
  | 'sticker'
  | 'statement_of_additional_information'
  | 'statutory';

type SecurityStats = {
  onHoldTrades: number;
  monthlyTrades: number;
};

type ResultStats = {
  monthlyTrades: number;
};

type View = 'default' | 'uploading' | 'editing date';

type MaintenanceModalProps = {
  id: string;
  onClose: () => void;
};

const prospectusFetcher = (url: string) => hubApi.get<Response>(url);

const useStyles = makeStyles(() =>
  createStyles({
    paperFullScreen: { margin: '2rem' },
  }),
);

const MaintenanceModal = ({ id, onClose }: MaintenanceModalProps) => {
  const response = useSWR(
    `/issuers/issuer/${id}/prospectus/`,
    prospectusFetcher,
  );
  const { data, error } = response;
  const classes = useStyles();
  const [selectedSecurities, setSelectedSecurities] = React.useState(new Set());
  const [view, setView] = React.useState<View>('default');
  const [showingCusips, setShowingCusips] = React.useState(true);
  const [expectedDate, setExpectedDate] = React.useState(new Date());
  const areAllChecked = useMemo(() => {
    if (data) {
      return (
        Array.from(selectedSecurities).length === data.data.securities.length
      );
    } else {
      return false;
    }
  }, [selectedSecurities, data]);

  const doesSelectedSecuritiesHaveProspectuses = () => {
    if (data) {
      return getSecuritiesById(
        [...selectedSecurities] as string[],
        data.data.securities,
      ).every((security) => security.prospectuses.length > 0);
    }

    return false;
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (data) {
      if (event.currentTarget.checked && data) {
        const allSecurityIds = data.data.securities.map(
          (security) => security.id,
        );
        setSelectedSecurities(new Set(allSecurityIds));
      } else {
        setSelectedSecurities(new Set());
      }
    }
  };

  const handleSecuritySelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    if (event.currentTarget.checked) {
      setSelectedSecurities(new Set(selectedSecurities.add(id)));
    } else {
      const copySet = new Set(selectedSecurities);
      copySet.delete(id);
      if (copySet.size === 0) {
        setView('default');
      }
      setSelectedSecurities(copySet);
    }
  };

  const handleUpdateExpectedDate = () => {
    if (data && doesSelectedSecuritiesHaveProspectuses()) {
      const allSummaryAndStatutoryProspectuses = getSecuritiesById(
        [...selectedSecurities] as string[],
        data.data.securities,
      )
        .map((security) => security.prospectuses)
        .reduce((acc, curr) => [...acc, ...curr])
        .filter(
          (prospectus) =>
            prospectus.type === 'summary' || prospectus.type === 'statutory',
        );

      const promises = allSummaryAndStatutoryProspectuses.map((prospectus) =>
        hubApi.patch(`/securities/prospectuses/${prospectus.id}/`, {
          name: prospectus.name,
          type: prospectus.type,
          frontLink: prospectus.frontLink,
          website: prospectus.website,
          securityIds: prospectus.securities,
          effectiveStartDate: prospectus.effectiveStartDate,
          effectiveEndDate: format(expectedDate, 'yyyy-MM-dd'),
        }),
      );

      axios
        .all(promises)
        .then(() => {
          mutate(`/issuers/issuer/${id}/prospectus/`);
          setView('default');
        })
        .catch((error) => {
          if (error.response.data) {
            return toast.error(error.response.data.nonFieldErrors.join('\n'), {
              autoClose: false,
            });
          }

          return toast.error(
            'An unknown error occurred. Please ensure your selections are correct.',
            { autoClose: false },
          );
        });
    }
  };

  const handleChangeExpectedDate = (date: Date | null) => {
    if (date) {
      setExpectedDate(date);
    }
  };

  const handleCancelUpload = () => setView('default');

  const handleCreateProspectusSuccess = () => {
    mutate(`/issuers/issuer/${id}/prospectus/`);
    setView('default');
    toast.success('Your prospectus documents were successfully created.');
  };

  if (!error && !data)
    return (
      <Dialog
        open={true}
        className={classes.paperFullScreen}
        onClose={onClose}
        fullScreen
      >
        <LoadingState />
      </Dialog>
    );

  if (error)
    return (
      <Dialog
        className={classes.paperFullScreen}
        open={true}
        onClose={onClose}
        fullScreen
      >
        <div className="flex items-center justify-center h-full w-full">
          Error...
        </div>
      </Dialog>
    );

  if (data) {
    const issuer = data.data;

    return (
      <Dialog
        className={classes.paperFullScreen}
        open={true}
        onClose={onClose}
        fullScreen
      >
        <header className="p-8 border-b border-light-gray">
          <h1 className="font-medium">
            <Link
              className="text-blue hover:underline hover:text-blue"
              to={`/issuers/${issuer.id}`}
              target="_blank"
            >
              {issuer.name}
            </Link>
          </h1>
        </header>

        <div className="py-4 px-8">
          <p className="text-secondary-text font-sm">
            Select one or more securities to upload documents or edit expected
            date.
          </p>

          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center">
              <div
                className={`border border-primary-text rounded h-8 px-2 flex items-center ${
                  view !== 'default'
                    ? 'border-gray bg-very-light-gray text-secondary-text'
                    : 'border-primary-text bg-white text-primary-text'
                }`}
              >
                <input
                  type="checkbox"
                  id="select-all"
                  onChange={handleSelectAll}
                  checked={areAllChecked}
                  disabled={view !== 'default'}
                  className="appearance-none border border-gray h-4 w-4 bg-white checkbox focus:outline-none hover:border-primary-text cursor-pointer"
                />
                <label
                  htmlFor="select-all"
                  className="ml-2 font-medium text-sm"
                >
                  Select all
                </label>
              </div>
              <Tooltip arrow placement="top" title="Upload document">
                <span>
                  <button
                    className={`border rounded h-8 px-2 bg-white ml-2 
                  ${
                    selectedSecurities.size === 0
                      ? 'opacity-50 border-gray bg-very-light-gray text-secondary-text '
                      : ''
                  }{" "}
                    ${
                      view !== 'uploading'
                        ? 'border-primary-text bg-white text-primary-text'
                        : 'border-green bg-white text-green'
                    }
                  `}
                    onClick={() =>
                      view === 'uploading'
                        ? setView('default')
                        : setView('uploading')
                    }
                    disabled={selectedSecurities.size === 0}
                    style={
                      selectedSecurities.size === 0
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                  >
                    <PublishIcon />
                  </button>
                </span>
              </Tooltip>

              <Tooltip arrow placement="top" title="Edit expected date">
                <div
                  className={`border rounded h-8 px-2 bg-white ml-2 flex items-center 
                    ${
                      selectedSecurities.size === 0 ||
                      !doesSelectedSecuritiesHaveProspectuses()
                        ? 'opacity-50 border-gray bg-very-light-gray text-secondary-text '
                        : ''
                    }{" "} 
                    ${
                      view !== 'editing date'
                        ? 'border-primary-text bg-white text-primary-text'
                        : 'border-green bg-white text-primary-text'
                    }
                `}
                >
                  <CalendarIcon className="mr-2" />
                  <DatePicker
                    className="bg-transparent focus:outline-none w-20"
                    selected={expectedDate}
                    onFocus={() => setView('editing date')}
                    onChange={handleChangeExpectedDate}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && expectedDate) {
                        handleUpdateExpectedDate();
                      }
                    }}
                    disabled={
                      selectedSecurities.size === 0 ||
                      !doesSelectedSecuritiesHaveProspectuses()
                    }
                  />
                </div>
              </Tooltip>

              {view === 'editing date' && (
                <div>
                  <button
                    className="text-green font-sm ml-4"
                    onClick={() => handleUpdateExpectedDate()}
                    disabled={!expectedDate}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>

          {(view === 'editing date' || view === 'uploading') && (
            <div className="rounded-lg border border-gray mt-4 overflow-hidden">
              <div className="flex items-center px-5 py-4 bg-very-light-gray">
                <span>Cusip(s):</span>
                {[...selectedSecurities].map((security) => (
                  <div
                    key={security as string}
                    className="border border-secondary-text text-secondary-text rounded text-xs px-1 ml-2"
                  >
                    {getCusipById(security as string, issuer.securities)}
                  </div>
                ))}
              </div>

              {view === 'uploading' && (
                <UploadView
                  onCancel={handleCancelUpload}
                  securities={[...selectedSecurities] as string[]}
                  onSuccess={handleCreateProspectusSuccess}
                />
              )}
            </div>
          )}

          {view === 'uploading' && (
            <button
              className="my-8 mx-auto font-medium text-primary-text text-xs border border-primary-text rounded h-8 w-32 block"
              onClick={() => setShowingCusips(!showingCusips)}
            >
              {showingCusips ? 'Hide cusips' : 'Show cusips'}
            </button>
          )}

          {showingCusips && (
            <div>
              {issuer.securities.map((security) => (
                <ProspectusSecurityRow
                  key={security.id}
                  onUpdateSuccess={() =>
                    mutate(`/issuers/issuer/${id}/prospectus/`)
                  }
                  security={security}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleSecuritySelect(event, security.id)
                  }
                  isChecked={selectedSecurities.has(security.id)}
                  className="mt-2"
                />
              ))}
            </div>
          )}
        </div>
      </Dialog>
    );
  }

  return null;
};

const getCusipById = (id: string, securities: Security[]) => {
  const security = securities.find((security) => security.id === id);

  return security && security.cusip;
};

const getSecuritiesById = (ids: string[], securities: Security[]) => {
  const filteredSecurities = securities.filter(
    (item) => ids.indexOf(item.id) !== -1,
  );

  return filteredSecurities;
};

export { MaintenanceModal };
