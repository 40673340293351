import React from 'react';
import { ReactComponent as ChevronLeftIcon } from '../chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../chevron-right.svg';
import { ReactComponent as ChevronDoubleLeftIcon } from '../chevron-double-left.svg';
import { ReactComponent as ChevronDoubleRightIcon } from '../chevron-double-right.svg';

type ButtonType =
  | 'skip all left'
  | 'skip left'
  | 'skip right'
  | 'skip all right';

type SkipButtonProps = {
  disabled?: boolean;
  buttonType: ButtonType;
  className?: string;
  onClick: () => void;
};

const SkipButton = ({
  disabled,
  buttonType,
  className,
  onClick,
}: SkipButtonProps) => (
  <button
    disabled={disabled}
    className={`border border-light-gray h-6 w-6 rounded-sm ${
      disabled ? `text-secondaaary-text` : `text-primary-text`
    } ${className}`}
    onClick={onClick}
  >
    {renderIcon(buttonType)}
  </button>
);

export { SkipButton };

const renderIcon = (buttonType: ButtonType) => {
  switch (buttonType) {
    case 'skip all left':
      return <ChevronDoubleLeftIcon className="inline" />;
    case 'skip all right':
      return <ChevronDoubleRightIcon className="inline" />;
    case 'skip left':
      return <ChevronLeftIcon className="inline" />;
    case 'skip right':
      return <ChevronRightIcon className="inline" />;
  }
};
