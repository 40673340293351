import React, { FC, useContext, useState } from 'react';
import { useContainer } from 'unstated-next';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { Flex, Box } from '@rebass/grid';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import {
  Card,
  RowHeader,
  SecurityBox,
  ProposalTypeContainer,
  StyledButton,
  DeleteButton,
} from './VoteStyledComponents';

import {
  IVoteProposal,
  RecommendationTypes,
  IVoteProposalFormValues,
  VoteType,
  IVoteProposalFormDetail,
  IVoteProposalFormDirector,
} from '../../models/vote-proposal';
import { cusip } from './VoteHelpers';
import { FilingContext } from '../../data/Filing.Context';
import { AddButton } from '../../common/AddButton';
import { ProposalGroup } from './ProposalGroup';
import { ProxyVoteForm } from './ProxyVoteForm';
import { DeleteModal } from './DeleteModal';
import { theme } from '../../utils/theme';
import { VoteProposals } from '../../data/VoteProposal';
import { EditTypes } from './VoteContainer';
import { formatVoteProposalType } from '../../utils/format-vote-proposal-type';

export const ProxyVote: FC<{}> = () => {
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [proposalGroupToDelete, setProposalGroupToDelete] = useState<number>(
    NaN,
  );
  const [proposalGroupToEdit, setProposalGroupToEdit] = useState<number>(NaN);
  const [
    initialValues,
    setInitialValues,
  ] = useState<IVoteProposalFormValues | null>(null);

  const { filing } = useContext(FilingContext);
  const { groupedProposals, deleteProposalGroup } = useContainer(VoteProposals);

  const routine = (isRoutine: boolean) =>
    isRoutine ? 'Routine' : 'Non-Routine';

  const convertVoteType = (voteChoices: string[]): string => {
    const capitalizedVoteChoices = voteChoices.map(
      (v) => v.charAt(0).toUpperCase() + v.slice(1),
    );
    return capitalizedVoteChoices.join(', ');
  };

  const composeInitialValues = (
    proposalsInGroup: IVoteProposal[],
  ): IVoteProposalFormValues => {
    return proposalsInGroup.reduce(
      (acc: IVoteProposalFormValues, cur: IVoteProposal) => {
        const voteProposalFormValues = { ...acc };
        if (
          acc.proposalType === 'BoardOfDirectorsNomination' &&
          cur.directorName
        ) {
          // eslint-disable-next-line
          voteProposalFormValues.directors
            ? (voteProposalFormValues.directors = [
                ...voteProposalFormValues.directors,
                { director: cur.directorName, id: cur.id },
              ])
            : [{ director: cur.directorName, id: cur.id }];

          return voteProposalFormValues;
        } else {
          if (cur.details) {
            voteProposalFormValues.details
              ? (voteProposalFormValues.details = [
                  ...voteProposalFormValues.details,
                  { detail: cur.details, id: cur.id },
                ])
              : (voteProposalFormValues.details = [
                  { detail: cur.details, id: cur.id },
                ]);
            return voteProposalFormValues;
          }
          return acc;
        }
      },
      {
        proposalTitle: proposalsInGroup[0].title,
        voteType: convertVoteType(proposalsInGroup[0].voteChoices) as VoteType,
        proposalType: proposalsInGroup[0].type,
        routine: proposalsInGroup[0].isRoutine ? 'Routine' : 'Non-Routine',
        recommendation: (proposalsInGroup[0].recommendationType
          .charAt(0)
          .toUpperCase() +
          proposalsInGroup[0].recommendationType.slice(
            1,
          )) as RecommendationTypes,
        securities: filing
          ? cusip(filing.securities, proposalsInGroup[0].securities)
          : [],
        details: [] as IVoteProposalFormDetail[],
        directors: [] as IVoteProposalFormDirector[],
      },
    );
  };

  const resetVoteProposalsState = (type: EditTypes) => {
    if (type === EditTypes.create) {
      setShowCreateForm(false);
      setInitialValues(null);
    } else if (type === EditTypes.edit) {
      setShowCreateForm(false);
      setIsEditing(false);
      setInitialValues(null);
      setProposalGroupToEdit(NaN);
    }
  };

  return (
    <Flex bg="background" pb={15} flexDirection="column">
      <Droppable
        droppableId="proxy-groups"
        type="GROUP"
        isDropDisabled={showCreateForm || isEditing}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver
                ? transparentize('.5', '#1954b5')
                : 'transparent',
            }}
            {...provided.droppableProps}
          >
            {groupedProposals.map(
              (voteProposalGroup: IVoteProposal[], i: number) => {
                return (
                  <Draggable
                    draggableId={`proxyVoteGroup-${voteProposalGroup[0].groupNumber}`}
                    key={`draggable-proxy-vote-group-${voteProposalGroup[0].groupNumber}`}
                    index={i}
                  >
                    {(dragProvided, dragSnapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                      >
                        <Card
                          key={`vp-group-${i}`}
                          px={'1px'}
                          flexDirection="column"
                          bg="white"
                        >
                          <RowHeader
                            flexDirection="row"
                            width={1}
                            pt={20}
                            pb={15}
                            pr={35}
                            pl={10}
                          >
                            <Box
                              mr={4}
                              pt={1}
                              {...dragProvided.dragHandleProps}
                            >
                              <FontAwesomeIcon
                                icon={faGripVertical}
                                color={theme.colors.blue}
                              />{' '}
                            </Box>
                            <Flex flexDirection="row" width={3 / 4}>
                              <ProposalTypeContainer width={1 / 3} pt="5px">
                                {formatVoteProposalType(
                                  voteProposalGroup[0].type,
                                )}
                              </ProposalTypeContainer>
                              <Box width={1 / 3} pt="5px">
                                {routine(voteProposalGroup[0].isRoutine)}
                              </Box>
                              <Flex width={1 / 3} flexDirection="row">
                                <Box width={1 / 5} pt="5px">
                                  CUSIP(s):
                                </Box>
                                <Flex width={4 / 5} flexDirection="column">
                                  {filing &&
                                    cusip(
                                      filing.securities,
                                      voteProposalGroup[0].securities,
                                    ).map((security) => {
                                      return (
                                        <SecurityBox key={security.cusip}>
                                          {security.cusip}
                                        </SecurityBox>
                                      );
                                    })}
                                </Flex>
                              </Flex>
                            </Flex>
                            {!isEditing && (
                              <Flex
                                flexDirection="row"
                                justifyContent="flex-end"
                                width={1 / 4}
                              >
                                {!showCreateForm && (
                                  <>
                                    <StyledButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setInitialValues(
                                          composeInitialValues(
                                            voteProposalGroup,
                                          ),
                                        );
                                        setProposalGroupToEdit(
                                          voteProposalGroup[0].groupNumber,
                                        );
                                        setIsEditing(true);
                                        setShowCreateForm(true);
                                      }}
                                    >
                                      Edit
                                    </StyledButton>
                                    <DeleteButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setProposalGroupToDelete(
                                          voteProposalGroup[0].groupNumber,
                                        );
                                      }}
                                    >
                                      Delete
                                    </DeleteButton>
                                  </>
                                )}
                              </Flex>
                            )}
                          </RowHeader>
                          <ProposalGroup
                            voteProposalGroup={voteProposalGroup}
                            groupNumber={voteProposalGroup[0].groupNumber}
                          />
                          {showCreateForm &&
                            isEditing &&
                            proposalGroupToEdit ===
                              voteProposalGroup[0].groupNumber && (
                              <ProxyVoteForm
                                currentGroupNumber={proposalGroupToEdit}
                                resetVoteProposalsState={
                                  resetVoteProposalsState
                                }
                                initialValues={initialValues}
                                isEditing={true}
                                handleCancel={() => {
                                  setShowCreateForm(false);
                                  setIsEditing(false);
                                  setProposalGroupToEdit(NaN);
                                }}
                              />
                            )}
                        </Card>
                      </div>
                    )}
                  </Draggable>
                );
              },
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {showCreateForm && !isEditing && (
        <Card bg="white">
          <ProxyVoteForm
            currentGroupNumber={groupedProposals.length + 1}
            resetVoteProposalsState={resetVoteProposalsState}
            initialValues={null}
            isEditing={false}
            handleCancel={() => setShowCreateForm(false)}
          />{' '}
        </Card>
      )}

      <Card>
        <AddButton
          label="Vote Proposal Group"
          disabled={showCreateForm}
          hanldeClick={() => {
            if (!showCreateForm) {
              setShowCreateForm(true);
            }
          }}
        />
      </Card>
      <DeleteModal
        isOpen={Boolean(proposalGroupToDelete)}
        message={`Are you sure you want to delete these proposals?`}
        handleDeleteProposalGroup={() => {
          deleteProposalGroup(proposalGroupToDelete);
          setProposalGroupToDelete(NaN);
        }}
        handleModalToggle={() => setProposalGroupToDelete(NaN)}
      />
    </Flex>
  );
};
