import React, { FC, MouseEvent } from 'react';
import { Flex } from '@rebass/grid';
import { Field, FieldProps } from 'formik';
import { GenericDate } from './GenericDate';
import { ErrorLabel } from '../ErrorLabel';
import { IFilingCreateValues } from '../../models/filing-form';
import { FilingDetailsFormValues } from '../../Filing/Filing';

interface IAdditionalRecordDates {
  error: string;
  additionalRecordDates?: string[];
}

export const AdditionalRecordDates: FC<IAdditionalRecordDates> = ({
  additionalRecordDates,
  error,
}) => {
  const canAddAnotherField = (): boolean => {
    if (additionalRecordDates) {
      return additionalRecordDates.every((date: string) => date !== '');
    } else {
      return false;
    }
  };
  return (
    <div>
      {additionalRecordDates &&
        additionalRecordDates.map((additionalRecordDate: string, i: number) => {
          return (
            <div key={`add-record-date-${i}`}>
              <Field
                name={`additionalRecordDates.${i}`}
                render={({
                  field,
                  form,
                }: FieldProps<
                  IFilingCreateValues | FilingDetailsFormValues
                >) => (
                  <>
                    {error && i === 0 && <ErrorLabel>{error}</ErrorLabel>}
                    <GenericDate
                      field={field}
                      form={form}
                      label={`Addtional Record Date ${i + 2}`}
                      fieldName={`additionalRecordDates.${i}`}
                    />
                    {i + 1 === additionalRecordDates.length && (
                      <Flex mt={2}>
                        <button
                          type="button"
                          disabled={additionalRecordDates.length === 1}
                          onClick={(e: MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const filteredRecordDates = [
                              ...additionalRecordDates,
                            ].filter(
                              (recordDate) =>
                                recordDate !== additionalRecordDate,
                            );
                            form.setFieldValue('additionalRecordDates', [
                              ...filteredRecordDates,
                            ]);
                          }}
                        >
                          -
                        </button>
                        <button
                          type="button"
                          disabled={!canAddAnotherField()}
                          onClick={(e: MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            form.setFieldValue('additionalRecordDates', [
                              ...additionalRecordDates,
                              '',
                            ]);
                          }}
                        >
                          +
                        </button>
                      </Flex>
                    )}
                  </>
                )}
              />
            </div>
          );
        })}
    </div>
  );
};
