import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Box } from '@rebass/grid';
import { Link as ReachLink } from '@reach/router';

const TabWrapper = styled(Box)`
  border-right: 1px solid #f2f2f2;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  padding: ${({ theme }) => theme.spacing[3]}px;
  width: 100%;

  &:last-of-type {
    border-right: none;
  }
`;

const NavLink = (props: any) => {
  return (
    <ReachLink
      {...props}
      getProps={({ href, location }) => {
        const currentLocationUrl = location.pathname + location.search;
        const isActive = href === currentLocationUrl;

        return isActive ? { className: `${props.className} active` } : null;
      }}
    />
  );
};

const Link = styled(NavLink)`
  color: ${({ theme }) => theme.colors.darkGrayText};
  background: ${({ theme }) => theme.colors.background};
  width: 100%;
  text-decoration: none;

  svg {
    stroke: ${({ theme }) => theme.colors.darkGrayText};
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.darkGrayText};
  }

  &.active {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};

    svg {
      stroke: ${({ theme }) => theme.colors.black};
    }

    .count {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;

interface TabProps {
  children: ReactNode;
  link: string;
}

export const Tab: FC<TabProps> = ({ children, link }) => (
  <Link to={link}>
    <TabWrapper>{children}</TabWrapper>
  </Link>
);
