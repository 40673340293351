export enum FilingType {
  CorporateAction = 'CorporateAction',
  FirmConsentSolicitation = 'FirmConsentSolicitation',
  FirmInformationStatement = 'FirmInformationStatement',
  FirmMeeting = 'FirmMeeting',
  FundInformationStatement = 'FundInformationStatement',
  FundMeeting = 'FundMeeting',
  FundReport = 'FundReport',
  MeetingContest = 'MeetingContest',
}
