import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { privateApi } from '../../old/utils/api-adapter';
import useSWR from 'swr';

type Props = {
  issuerId?: string | null;
  value: { id: number; cusip: string; name: string }[];
  onSetCusips: (
    cusips: {
      id: number;
      cusip: string;
      name: string;
    }[],
  ) => void;
  onSetIssuer?: (issuer: {
    companyName: string;
    contactEmail: string | null;
    id: number | null;
  }) => void;
};

type Item = {
  id: number;
  cusip: string;
  name: string;
  issuer: Issuer;
};

type Issuer = {
  id: number;
  companyName: string;
  contactEmail: null | string;
  cik: number | null;
  edgarLandingPage: null | string;
};

const fetcher = (url: string) => privateApi.get<Item[]>(url);

function CusipTypeahead({ issuerId, onSetCusips, value, onSetIssuer }: Props) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const { data, error } = useSWR(
    `/issuers/cusip-typeahead/?cusip=${searchTerm ? searchTerm : ''}${
      issuerId !== null ? `&issuer_id=${issuerId}` : ''
    }`,
    fetcher,
  );

  const options = data
    ? data.data.map((d) => ({
        id: d.id,
        cusip: d.cusip,
        name: d.name,
      }))
    : [];

  const getIssuer = (cusip: string) =>
    data ? data.data.filter((d) => (d.cusip = cusip))[0].issuer : null;

  return (
    <Autocomplete
      multiple
      options={[...value, ...options]}
      getOptionLabel={(option) => option.cusip}
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      filterSelectedOptions
      inputValue={searchTerm}
      loading={!data && !error}
      value={value}
      ChipProps={{ size: 'small' }}
      noOptionsText="No CUSIPs"
      onChange={(_, changeValue) => {
        // onSetIssuer only passed when creating a new filing, helps set issuer field from scratch
        if (data && onSetIssuer) {
          if (changeValue.length > 0) {
            // get first cusip in list and retrieve related issuer
            const selectedIssuer = getIssuer(changeValue[0].cusip);
            selectedIssuer &&
              onSetIssuer({
                companyName: selectedIssuer.companyName,
                contactEmail: selectedIssuer.contactEmail,
                id: selectedIssuer.id,
              });
          } else {
            // manually clear issuer if values are deleted until empty
            onSetIssuer({
              companyName: '',
              contactEmail: '',
              id: null,
            });
          }
        }
        return onSetCusips(changeValue);
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'clear') {
          onSetCusips([]);
          onSetIssuer &&
            onSetIssuer({
              companyName: '',
              contactEmail: '',
              id: null,
            });
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      renderInput={(params) => (
        <div id="CusipTypeAhead">
          <TextField
            {...params}
            variant="outlined"
            size="small"
            required={true}
            label="CUSIP"
            placeholder="Start typing CUSIP..."
          />
        </div>
      )}
    />
  );
}

export { CusipTypeahead };
