import React, { FC, useEffect, useMemo } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { MissingDeliveryDocument, AllFunds } from './Tabs';
import { Flex } from '@rebass/grid';
import { Tab } from '../../common/Tab';
import { Text } from '../../common/Text';
import { Tabs } from '../../common/Tabs';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../data/store';
import queryString from 'query-string';
import { fetchFundsAndEtfsFlow } from '../../data/funds';
import { AlertIcon } from './AlertIcon';
import { FundsLayout } from './FundsLayout';
import { userIdEvent } from '../../utils/analytics-helpers';

enum RoutePaths {
  All = 'all',
  MissingDeliveryDocument = 'missing-delivery-document',
}

const StickyTabs = styled(Flex)`
  position: sticky;
  top: 73px;
  z-index: 1;
`;

export const Funds: FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const { funds } = useSelector((state: AppState) => state);
  const query = queryString.parse(location ? location.search : '');
  const { tab } = query;

  useEffect(() => {
    dispatch(
      fetchFundsAndEtfsFlow({
        ordering: funds.all.ordering,
        search: funds.search,
      }),
    );

    dispatch(
      fetchFundsAndEtfsFlow({
        ordering: funds.missingDeliveryDocuments.ordering,
        search: funds.search,
        prospectusStatus: 'missing',
      }),
    );
  }, [
    dispatch,
    funds.all.ordering,
    funds.missingDeliveryDocuments.ordering,
    funds.search,
  ]);

  useEffect(() => {
    if (tab === undefined) {
      navigate(`?tab=${RoutePaths.All}`);
    }
  }, [tab]);

  const isMissingDocumentTabVisible = useMemo(
    () => (funds.missingDeliveryDocuments.count > 0 ? true : false),
    [funds.missingDeliveryDocuments.count],
  );

  return (
    <FundsLayout>
      <StickyTabs flexDirection="column" width={1} bg="white">
        <Tabs columns={isMissingDocumentTabVisible ? 2 : 1}>
          <Tab link={`?tab=${RoutePaths.All}`}>
            <Flex justifyContent="space-between">
              <Text>All Funds</Text>
              <Text className="count">{funds.all.count}</Text>
            </Flex>
          </Tab>

          {isMissingDocumentTabVisible && (
            <Tab link={`?tab=${RoutePaths.MissingDeliveryDocument}`}>
              <Flex
                justifyContent="space-between"
                onClick={() =>
                  userIdEvent('Missing Delivery Document Tab Clicked', {})
                }
              >
                <Flex>
                  <Text color="red" mr={2}>
                    Missing Delivery Document
                  </Text>
                  <AlertIcon />
                </Flex>
                <Text className="count">
                  {funds.missingDeliveryDocuments.count}
                </Text>
              </Flex>
            </Tab>
          )}
        </Tabs>
      </StickyTabs>

      {tab === RoutePaths.All && <AllFunds />}
      {tab === RoutePaths.MissingDeliveryDocument && (
        <MissingDeliveryDocument />
      )}
    </FundsLayout>
  );
};
