import React from 'react';
import { TableTitle } from '../TableTitle';
import { PaginationPageSize } from '../PaginationPageSize';
import { PaginationButtons } from '../PaginationButtons';
import { pluralize } from '../../utils/pluralize';
import { formatNumber } from '../../utils/format-number';

type TableHeaderProps = {
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  count: number;
  itemType: 'fund' | 'security' | 'issuer' | 'filing';
  displayButton?: boolean;
  disabled?: boolean;
  markMaterialsRequested?: () => void;
};

const TableHeader = ({
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  count,
  itemType,
  displayButton,
  disabled,
  markMaterialsRequested,
}: TableHeaderProps) => {
  const totalPages = Math.ceil(count / pageSize);
  const pluralItemType = React.useMemo(() => {
    switch (itemType) {
      case 'security':
        return 'securities';
      case 'fund':
      case 'issuer':
      case 'filing':
      default:
        return `${itemType}s`;
    }
  }, [itemType]);
  const title = `${formatNumber(count)} ${pluralize({
    count,
    plural: pluralItemType,
    singular: itemType,
  })}`;

  return (
    <div className="flex justify-between items-end border-b border-light-gray pb-4 pt-4 -mt-4 bg-white">
      <div className="flex items-center">
        <TableTitle title={title} />
        {count > pageSize && (
          <PaginationPageSize
            onChange={onPageSizeChange}
            count={count}
            pageSize={pageSize}
          />
        )}
      </div>
      <div className="flex flex-col items-end -mt-4">
        {displayButton && (
          <button
            onClick={markMaterialsRequested}
            className="bg-green mb-4 rounded hover:bg-light-green transition-colors duration-300 h-8 text-xs font-medium text-primary-text px-2 disabled:bg-light-gray disabled:cursor-not-allowed"
            disabled={disabled}
          >
            Requested Materials
          </button>
        )}
        <PaginationButtons
          currentPage={page + 1}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export { TableHeader };
