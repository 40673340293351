import React from 'react';

type ChoiceProps = {
  value: string;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Choice = ({ label, value, onChange }: ChoiceProps) => (
  <div className="p-8 border border-t-0 border-light-gray text-sm text-primary-text flex items-center w-full">
    <input
      id={value}
      type="radio"
      name="action"
      value={value}
      onChange={onChange}
    />
    <label className="ml-4" htmlFor={value}>
      {label}
    </label>
  </div>
);

export { Choice };
