import React from 'react';
import { ReactComponent as Logo } from './assets/say-logotype.svg';
import styled from 'styled-components/macro';

const EnvLabel = styled.p`
  background: ${({ theme }) => theme.colors.red};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  margin-left: 16px;
  padding: 4px 8px;
`;

export const SayLogotype = () => {
  return (
    <>
      <Logo />

      {process.env.REACT_APP_ENV_LABEL && (
        <EnvLabel>{process.env.REACT_APP_ENV_LABEL}</EnvLabel>
      )}
    </>
  );
};
