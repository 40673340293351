import styled from 'styled-components/macro';
import { Flex } from '@rebass/grid';

export const StatusBox = styled(Flex)<{ status: string }>`
  color: #000;
  align-items: center;
  border-radius: 3px;
  font-size: 12px;
  height: 20px;
  margin-right: 5px;
  margin-top: 5px;
  text-transform: uppercase;
  padding: 0 10px;
  width: min-content;
  background-color: ${({ status, theme }) =>
    status === 'confirmed'
      ? theme.colors.green
      : status === 'dismissed'
      ? theme.colors.red
      : theme.colors.yellow};
`;
