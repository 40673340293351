import React from 'react';
import { hubApi } from '../../old/data/hubApiConfig';
import { toast } from 'react-toastify';

type DismissProps = {
  onCancel: () => void;
  onSuccess: () => void;
  id: string;
};

const Dismiss = ({ onCancel, onSuccess, id }: DismissProps) => {
  const handleDismiss = () => {
    hubApi
      .get(`/securities/security/${id}/dismiss-trade-alert/`)
      .then(onSuccess)
      .catch((error) => {
        if (error.response.data) {
          return toast.error(error.response.data.nonFieldErrors.join('\n'), {
            autoClose: false,
          });
        }

        return toast.error(
          'An unknown error occurred. Please ensure your selections are correct.',
          { autoClose: false },
        );
      });
  };

  return (
    <div className="w-full">
      <div className="flex flex-col items-center mt-8">
        <button
          className="bg-green text-primary-text text-sm font-medium h-10 w-40 rounded-full disabled:text-secondary-text disabled:bg-light-gray disabled:cursor-not-allowed"
          onClick={handleDismiss}
        >
          Dismiss
        </button>
        <button
          className="text-secondary-text text-sm mt-4"
          onClick={onCancel}
          type="button"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export { Dismiss };
