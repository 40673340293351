import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { PageHeader } from '../../components/PageHeader';
import { Tabs } from '../../components/Tabs';
import { useTasks } from './useTasks';

type LayoutProps = RouteComponentProps & {
  children: React.ReactNode;
  path: string;
};

const TaskListLayout = ({ children }: LayoutProps) => {
  const { newTasks, inProgressTasks, openTasks } = useTasks();
  const newCount = newTasks.data ? newTasks.data.data.count : 0;
  const inProgressCount = inProgressTasks.data
    ? inProgressTasks.data.data.count
    : 0;
  const openCount = openTasks.data ? openTasks.data.data.count : 0;

  return (
    <React.Fragment>
      <PageHeader product="ca" />
      <div id="VcaTabs">
        <Tabs
          tabs={[
            {
              title: 'New',
              count: newCount,
              to: '/corporate-actions/tasks/new',
            },
            {
              title: 'In Progress',
              count: inProgressCount,
              to: '/corporate-actions/tasks/in-progress',
            },
            {
              title: 'Open',
              count: openCount,
              to: '/corporate-actions/tasks/open',
            },
          ]}
        />
      </div>
      {children}
    </React.Fragment>
  );
};

export { TaskListLayout };
