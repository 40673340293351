import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { ElevatedCard } from '../common/ElevatedCard';
import { Field, FieldProps } from 'formik';
import { FilingDetailsFormValues } from './Filing';
import { InputGroup } from '../common/InputGroup';
import { Label } from '../common/Label';
import { Input } from '../common/Input';
import { Textarea } from '../common/Textarea';

const Wrapper = styled(ElevatedCard)`
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  margin-top: 16px;
  padding: 32px 16px;
`;

export const ActionDetails: FC<RouteComponentProps> = () => {
  return (
    <Wrapper>
      <Field
        name="payoutCurrency"
        render={({ field }: FieldProps<FilingDetailsFormValues>) => (
          <InputGroup>
            <Label htmlFor="payout-currency">Payout Currency</Label>
            <Input id="payout-currency" {...field} />
          </InputGroup>
        )}
      />

      <Field
        name="offerer"
        render={({ field }: FieldProps<FilingDetailsFormValues>) => (
          <InputGroup>
            <Label htmlFor="offerer">Offerer</Label>
            <Input id="offerer" {...field} />
          </InputGroup>
        )}
      />

      <Field
        name="offererTerms"
        render={({ field }: FieldProps<FilingDetailsFormValues>) => (
          <InputGroup>
            <Label htmlFor="offerer-terms">Offerer Terms</Label>
            <Input id="offerer-terms" {...field} />
          </InputGroup>
        )}
      />

      <Field
        name="contraCusip"
        render={({ field }: FieldProps<FilingDetailsFormValues>) => (
          <InputGroup>
            <Label htmlFor="contra-cusip">Contra CUSIP</Label>
            <Input id="contra-cusip" {...field} />
          </InputGroup>
        )}
      />

      <Field
        name="actionDetail"
        render={({ field }: FieldProps<FilingDetailsFormValues>) => (
          <InputGroup>
            <Label htmlFor="action-detail">Action Detail</Label>
            <Textarea id="action-detail" {...field} rows={5} />
          </InputGroup>
        )}
      />
    </Wrapper>
  );
};
