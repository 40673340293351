export enum ProposalTypes {
  BoardOfDirectorsNomination = 'BoardOfDirectorsNomination',
  PlanOfMergerProposalItem = 'PlanOfMergerProposalItem',
  AdjournmentProposalItem = 'AdjournmentProposalItem',
  ExecutiveCompensationProposalItem = 'ExecutiveCompensationProposalItem',
  ManagementProposalItem = 'ManagementProposalItem',
  ShareholderProposalItem = 'ShareholderProposalItem',
  SayOnPay = 'SayOnPay',
  RatificationOfAuditors = 'RatificationOfAuditors',
  Other = 'OtherProposalItem',
}

export type VoteType =
  | 'For, Against'
  | 'For, Against, Abstain'
  | 'For, Against, Withhold'
  | 'For, Withhold'
  | 'One year, Two years, Three years, Abstain'
  | 'Revoke My Consent, Do Not Revoke My Consent, Abstain'
  | 'For, Withhold, Abstain'
  | 'Yes, No'
  | 'For, Abstain';

export type ElectionType =
  | 'yes_no'
  | 'yes_no_partial'
  | 'cash_stock'
  | 'cash_stock_mixed';

export type RoutineTypes = 'Routine' | 'Non-Routine';

export enum RecommendationTypes {
  Abstain = 'Abstain',
  Against = 'Against',
  For = 'For',
  No = 'No',
  None = 'None',
  OneYear = 'One year',
  ThreeYears = 'Three years',
  TwoYears = 'Two years',
  Withhold = 'Withhold',
  Cash = 'Cash',
  Stock = 'Stock',
  Mixed = 'Mixed',
  Yes = 'Yes',
}

export enum SayOnPayRecTypes {
  None = 'None',
  OneYear = 'One_year',
  ThreeYears = 'Three_years',
  TwoYears = 'Two_years',
  Abstain = 'Abstain',
}

export interface IMeetingResult {
  id: number;
  voteProposal: number;
  choice: string;
  totalShares: string;
}

export interface IReorderPayload {
  id: number;
  groupNumber: number;
  proposalNumber: number;
}

export interface IVoteProposal {
  details?: string;
  directorName?: string;
  filing: number;
  groupNumber: number;
  id: number;
  isRoutine: boolean;
  proposalNumber: number;
  recommendationType: string;
  securities: number[];
  title: string;
  votedAtMeeting: boolean;
  type: ProposalTypes;
  voteChoices: string[];
  voteType: string;
  meetingResults?: IMeetingResult[];
}

export interface IElectionItems {
  id: number;
  corporateAction: number;
  proposalNumber: number;
  groupNumber: number;
  electionType: ElectionType;
  recommendationType: RecommendationTypes;
  securities: number[];
  details: string;
  voteChoices: string[];
  title: string;
}

export interface ICusip {
  readonly id: number;
  readonly cusip: string;
  readonly name: string;
}

export interface IVoteProposalFormDirector {
  director: string;
  id: number | null;
}

export interface IVoteProposalFormDetail {
  detail: string;
  id: number | null;
}

export interface IVoteProposalFormValues {
  proposalTitle: string;
  voteType: VoteType;
  proposalType: ProposalTypes;
  routine: RoutineTypes;
  recommendation: RecommendationTypes;
  securities: ICusip[];
  details?: IVoteProposalFormDetail[];
  directors?: IVoteProposalFormDirector[];
}

export interface IElectionItemsFormValues {
  electionType: ElectionType;
  id: number | null;
  recommendation: RecommendationTypes;
  securities: ICusip[];
  proposalType: string;
  proposalNumber: number;
  groupNumber: number;
  proposalTitle?: string;
  details?: string;
}

export interface IVoteProposalPayload {
  groupNumber: number;
  isRoutine: boolean;
  proposalNumber: number;
  recommendationType: string;
  securities: number[];
  title: string;
  type: ProposalTypes;
  voteChoices: string[];
  id: number | null;
  voteType?: string;
  director?: string;
  detail?: string;
}
