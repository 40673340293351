import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

type Props = {
  value: CompanyContact[];
  options: CompanyContact[];
  onSetTabulatorContacts: (companyContacts: CompanyContact[]) => void;
};

export interface CompanyContact {
  id: number;
  contact: Contact;
  role: Role;
  isDefaultForRole: boolean;
}

export interface Contact {
  id: number;
  name: string;
  email: string;
  phone: null | string;
}

type Role = 'other' | 'solicitor_reply_to' | 'tabulator_reply_to';

function TabulatorContactsTypeahead({
  value,
  options,
  onSetTabulatorContacts,
}: Props) {
  const [searchTerm, setSearchTerm] = React.useState('');

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.contact.email}
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      filterSelectedOptions
      inputValue={searchTerm}
      ChipProps={{ size: 'small' }}
      noOptionsText="No tabulator contacts"
      onChange={(_, changeValue) => {
        onSetTabulatorContacts(changeValue);
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'clear') {
          onSetTabulatorContacts([]);
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label="Tabulator Contact(s)"
          placeholder="Start typing tabulator contact..."
          required={true}
        />
      )}
    />
  );
}

export { TabulatorContactsTypeahead };
