import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

import { Nav } from '../Nav';
import { Header } from '../Header';

type AppLayoutProps = {
  children: React.ReactNode;
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#11cc99',
    },
    secondary: {
      main: '#11cc99',
    },
    error: {
      main: '#d33f33',
    },
    warning: {
      main: '#ffd64d',
    },
    info: {
      main: '#1853B4',
    },
    success: {
      main: '#11cc99',
    },
    divider: '#e5e5e5',
  },
  typography: {
    fontFamily: ['Franklin Gothic', 'sans-serif'].join(','),
  },
  overrides: {
    MuiChip: {
      labelSmall: {
        fontSize: '12px !important',
      },
    },
    MuiTouchRipple: {
      rippleVisible: {
        color: '#11cc99',
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          color: '#000',
          backgroundColor: 'rgba(17, 204, 153, 0.04) !important',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiButton: {
      root: {
        color: '#1a1a19',
        borderRadius: '4px',
        letterSpacing: '1.25px',
        '&:disabled': {
          color: 'rgba(0, 0, 0, 0.38) !important',
        },
      },

      text: {
        color: '#1a1a19',
        '&:hover': {
          backgroundColor: 'rgba(17, 204, 153, 0.04) !important',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
        '&$focusVisible': {
          backgroundColor: 'rgba(17, 204, 153, 0.12)',
        },
        '&:active': {
          backgroundColor: '#11B789',
        },
      },
      textPrimary: {
        color: '#11cc99',
      },
      outlinedPrimary: {
        borderColor: '#11cc99',
        color: '#1a1a19',
        '&:hover': {
          backgroundColor: 'rgba(17, 204, 153, 0.04) !important',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
        '&$focusVisible': {
          backgroundColor: 'rgba(17, 204, 153, 0.12)',
        },
        '&:active': {
          backgroundColor: '#11B789',
        },
      },
      contained: {
        backgroundColor: '#11cc99',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: '#0FC493 !important',
          '@media (hover: none)': {
            boxShadow: 'none',
            backgroundColor: '#11cc99',
          },
        },
        '&$focusVisible': {
          backgroundColor: '#10B386',
        },
        '&:active': {
          backgroundColor: '#11B789',
        },
        TouchRippleProps: {
          rippleVisible: {
            color: '#000',
          },
        },
      },
    },
  },
});

const AppLayout = ({ children }: AppLayoutProps & RouteComponentProps) => {
  const [isNavCollapsed, setIsNavCollapsed] = React.useState(false);

  const handleToggleNav = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <ThemeProvider theme={theme}>
      <div
        className={`min-h-screen bg-white text-primary-text grid ${
          isNavCollapsed
            ? 'grid-cols-appLayoutCollapsedNav'
            : 'grid-cols-appLayoutExpandedNav'
        }`}
      >
        <Nav isCollapsed={isNavCollapsed} handleToggleNav={handleToggleNav} />

        <main
          className="flex flex-col flex-grow min-h-screen"
          style={{ minWidth: '75rem' }}
        >
          <Header />
          {children}
        </main>
      </div>
    </ThemeProvider>
  );
};

export { AppLayout };
