import React from 'react';
import { Router, RouteComponentProps, Redirect } from '@reach/router';
import { InProgress, New, Open } from './tasks';
import { Create } from './Create';
import { Details } from './Details';
import { TaskListLayout } from './TaskListLayout';
import { BrokerEmail } from './BrokerEmail';

const CorporateActions: React.FC<RouteComponentProps> = () => {
  return (
    <Router primary={false}>
      <Create path="create" />
      <Details path=":id" />
      <BrokerEmail path=":id/broker-email" />
      <TaskListLayout path="tasks">
        <New path="new" />
        <InProgress path="in-progress" />
        <Open path="open" />
      </TaskListLayout>
      <Redirect from="/" to="tasks/new" noThrow={true} />
    </Router>
  );
};

export { CorporateActions };
