import React, { useState } from 'react';
import { Modal } from '../common/Modal';
import { Dialog } from '../common/Dialog/Dialog';
import { SingleFiling } from './SingleFiling';
import { MultipleFilings } from './MultipleFilings';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const NewFiling = ({ isOpen, onClose }: Props) => {
  const [selectedNavItem, setSelectedNavItem] = useState<string>(
    'Single Filing',
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      top={20}
    >
      <Dialog
        title="New Filing"
        isGray={true}
        onRequestClose={onClose}
        setSelectedNavItem={setSelectedNavItem}
        navItems={['Single Filing', 'Multiple Filings']}
      >
        {selectedNavItem === 'Single Filing' && (
          <SingleFiling onClose={onClose} />
        )}
        {selectedNavItem === 'Multiple Filings' && (
          <MultipleFilings onClose={onClose} />
        )}
      </Dialog>
    </Modal>
  );
};
