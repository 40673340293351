import React from 'react';
import {
  Button,
  Box,
  makeStyles,
  Grid,
  createStyles,
  IconButton,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useParams } from '@reach/router';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  ProposalType,
  RecommendationType,
  Routineness,
  TooltipText,
  VoteProposal,
  VoteType,
} from '../types';
import { useProxy } from '../useProxy';
import { DragIndicator, Edit, Delete, ExpandMore } from '@material-ui/icons';
import { BallotDetailsProposalForm } from './BallotDetailsProposalForm';
import { privateApi } from '../../../old/utils/api-adapter';
import { createEditor, Node } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import styled from 'styled-components';
import { withHistory } from 'slate-history';

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.primary,
      borderBottom: `1px solid ${theme.palette.divider}`,
      fontSize: '12px',
      fontWeight: 500,
      padding: `${theme.spacing(1.75)}px ${theme.spacing(3)}px`,
    },
    addProposalButtonWrapper: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    proposalRow: {
      fontSize: '12px',
      fontWeight: 500,
      color: theme.palette.text.primary,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    accordionSummary: {
      position: 'relative',
      paddingLeft: `${theme.spacing(3)}px`,
      paddingRight: `${theme.spacing(3)}px`,
    },
    accordionDetail: {
      position: 'relative',
      padding: 0,
    },
    dragIndicator: {
      position: 'absolute',
      left: 0,
      color: '#C4CAC8',
    },
    proposalGroupActionButtons: {
      background: theme.palette.common.white,
      position: 'absolute',
      zIndex: 1,
      right: `${theme.spacing(5)}px`,
    },
    tooltipText: {
      background: theme.palette.common.white,
      resize: 'none',
      color: '#787878',
      fontSize: '12px',
      width: '100%',
    },
    voteChoiceLabel: {
      textTransform: 'capitalize',
    },
    icon: {
      color: '#C4CAC8',
    },
    body: {
      color: '#121212',
      fontSize: '12px',
    },
    bodyCapitalized: {
      color: '#121212',
      fontSize: '12px',
      textTransform: 'capitalize',
    },
    expandMoreIcon: {
      position: 'absolute',
      right: '12px',
      color: '#C4CAC8',
    },
    fullWidth: {
      width: '100%',
    },
    proposalAccordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    proposalAccordionDetailsRow: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      width: '100%',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    voteChoiceRadios: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export type GroupedProposal = {
  groupNumber: number;
  routineness: Routineness;
  recommendationType: RecommendationType;
  voteChoices: string[];
  title: string;
  voteType: VoteType;
  type: ProposalType;
  proposals: Proposal[];
};
type Proposal = {
  id: number;
  proposalNumber: number;
  tooltipText: TooltipText;
  details?: string;
  directorName?: string;
};

function BallotDetails() {
  const classes = useStyles();
  const params = useParams();
  const { data, mutate } = useProxy(params.id);

  const [
    activeProposalGroupIndex,
    setActiveProposalGroupIndex,
  ] = React.useState<number | null>(null);
  const [isShowingForm, setIsShowingForm] = React.useState(false);

  const groupedProposals = React.useMemo(
    () =>
      data
        ? data.data.voteProposals
          ? sortProposals(groupVoteProposals(data.data.voteProposals))
          : []
        : [],
    [data],
  );

  async function handleOnDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    if (result.type === 'proposalGroup') {
      const newGroupedProposals = reorder<GroupedProposal>(
        groupedProposals,
        result.source.index,
        result.destination.index,
      ).map((gp, index) => ({
        ...gp,
        groupNumber: index + 1,
        proposals: gp.proposals.map((p, index) => ({
          ...p,
          proposalNumber: index + 1,
        })),
      }));

      reorderRequest(newGroupedProposals);
    } else {
      const newGroupedProposals = groupedProposals
        .map((groupedProposal) => {
          if (groupedProposal.groupNumber === Number(result.type)) {
            return {
              ...groupedProposal,
              proposals: reorder<Proposal>(
                groupedProposal.proposals,
                result.source.index,
                result.destination!.index,
              ),
            };
          }

          return groupedProposal;
        })
        .map((gp, index) => ({
          ...gp,
          groupNumber: index + 1,
          proposals: gp.proposals.map((p, index) => ({
            ...p,
            proposalNumber: index + 1,
          })),
        }));

      reorderRequest(newGroupedProposals);
    }
  }

  async function reorderRequest(groupedProposals: GroupedProposal[]) {
    if (data && data.data.voteProposals) {
      const payload = groupedProposals
        .map((gp) => {
          return gp.proposals.map((p) => ({
            id: p.id,
            proposalNumber: p.proposalNumber,
            groupNumber: gp.groupNumber,
          }));
        })
        .flat();

      try {
        mutate(
          {
            ...data,
            data: {
              ...data.data,
              voteProposals: data.data.voteProposals.map((voteProposal) => {
                const voteProposalId = voteProposal.id;
                const foundProposalFromPayload = payload.find(
                  (proposal) => proposal.id === voteProposalId,
                );
                if (foundProposalFromPayload) {
                  return {
                    ...voteProposal,
                    groupNumber: foundProposalFromPayload.groupNumber,
                    proposalNumber: foundProposalFromPayload.proposalNumber,
                  };
                }
                return voteProposal;
              }),
            },
          },
          false,
        );
        await privateApi.patch(`/admin/vote-proposals/reorder/`, payload);
      } catch (error) {
        console.error(error.response.data);
      }
    }
  }

  return (
    <>
      <Grid
        component="header"
        className={classes.header}
        container={true}
        spacing={1}
      >
        <Grid item={true} xs={4}>
          Proposal Name
        </Grid>
        <Grid item={true} xs={3}>
          Type
        </Grid>
        <Grid item={true} xs={3}>
          Routine
        </Grid>
        <Grid item={true} xs={1}>
          Item
        </Grid>
        <Grid item={true} xs={1}>
          Board
        </Grid>
      </Grid>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        {isShowingForm ? (
          <BallotDetailsProposalForm
            value={
              activeProposalGroupIndex !== null
                ? groupedProposals[activeProposalGroupIndex]
                : {
                    groupNumber: groupedProposals.length + 1,
                    routineness: 'nonRoutine',
                    proposals: [],
                    recommendationType: 'for',
                    title: 'Election of Directors',
                    type: 'BoardOfDirectorsNomination',
                    voteChoices: ['for'],
                    voteType: 'election_plurality',
                  }
            }
            onCancelEditing={() => {
              setIsShowingForm(false);
              setActiveProposalGroupIndex(null);
            }}
            isNew={activeProposalGroupIndex === null}
            securities={
              data ? data.data.securities.map((security) => security.id) : []
            }
            onSave={() => {
              mutate();
              setIsShowingForm(false);
            }}
            filingId={params.id}
          />
        ) : (
          <>
            <Droppable droppableId="groupedProposalList" type="proposalGroup">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes.fullWidth}
                >
                  {groupedProposals.map((groupedProposal, index) => (
                    <ProposalGroup
                      key={groupedProposal.groupNumber}
                      groupedProposal={groupedProposal}
                      index={index}
                      onSetGroupEditing={() => {
                        setActiveProposalGroupIndex(index);
                        setIsShowingForm(true);
                      }}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Box className={classes.addProposalButtonWrapper}>
              <Button
                startIcon={<Add color="primary" />}
                onClick={() => setIsShowingForm(true)}
              >
                Proposal
              </Button>
            </Box>
          </>
        )}
      </DragDropContext>
    </>
  );
}

// child components

type ProposalGroupProps = {
  groupedProposal: GroupedProposal;
  index: number;
  onSetGroupEditing: () => void;
};

function ProposalGroup({
  groupedProposal,
  index,
  onSetGroupEditing,
}: ProposalGroupProps) {
  const classes = useStyles();
  const params = useParams();
  const { mutate } = useProxy(params.id);
  const [isShowingActions, setIsShowingActions] = React.useState(false);

  function handleDeleteClick(proposalIds: number[]) {
    Promise.all(
      proposalIds.map((proposalId) =>
        privateApi.delete(`/vote-proposals/${proposalId}/`),
      ),
    )
      .then((_) => {
        mutate();
      })
      .catch((_) => console.error('Could not delete this proposal group.'));
  }

  return (
    <Draggable draggableId={String(groupedProposal.groupNumber)} index={index}>
      {(provided) => (
        <Accordion
          square={true}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            onMouseEnter={() => setIsShowingActions(true)}
            onMouseLeave={() => setIsShowingActions(false)}
            className={classes.accordionSummary}
            IconButtonProps={{
              className: classes.expandMoreIcon,
              size: 'small',
            }}
          >
            <Grid container={true} spacing={1} alignItems="center">
              <DragIndicator className={classes.dragIndicator} />
              <Grid item={true} xs={4} className={classes.body}>
                {groupedProposal.title}
              </Grid>
              <Grid item={true} xs={3} className={classes.bodyCapitalized}>
                {formatVoteChoices(
                  groupedProposal.voteType,
                  groupedProposal.type,
                )}
              </Grid>
              <Grid item={true} xs={3} className={classes.body}>
                {groupedProposal.routineness === 'routine'
                  ? 'Routine'
                  : 'Non-Routine'}
              </Grid>
              <Grid item={true} xs={1} className={classes.body}>
                {groupedProposal.proposals.length}
              </Grid>
              <Grid item={true} xs={1} className={classes.bodyCapitalized}>
                {groupedProposal.recommendationType}
              </Grid>
              {isShowingActions && (
                <ProposalGroupActionButtons
                  onEditClick={onSetGroupEditing}
                  onDeleteClick={() =>
                    handleDeleteClick(
                      groupedProposal.proposals.map((p) => p.id),
                    )
                  }
                />
              )}
            </Grid>
          </AccordionSummary>

          <AccordionDetails className={classes.accordionDetail}>
            <Droppable
              droppableId={String(groupedProposal.groupNumber)}
              type={String(groupedProposal.groupNumber)}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes.fullWidth}
                >
                  {groupedProposal.proposals.map((proposal, index) => (
                    <Proposal
                      key={proposal.id}
                      proposal={proposal}
                      index={index}
                      voteChoices={groupedProposal.voteChoices}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
}

type ProposalGroupActionButtonsProps = {
  onEditClick: () => void;
  onDeleteClick: () => void;
};

function ProposalGroupActionButtons({
  onEditClick,
  onDeleteClick,
}: ProposalGroupActionButtonsProps) {
  const classes = useStyles();

  return (
    <div className={classes.proposalGroupActionButtons}>
      <IconButton
        aria-label="edit"
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          onEditClick();
        }}
      >
        <Edit className={classes.icon} />
      </IconButton>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          onDeleteClick();
        }}
      >
        <Delete className={classes.icon} />
      </IconButton>
    </div>
  );
}

type ProposalProps = {
  proposal: Proposal;
  index: number;
  voteChoices: string[];
};

function Proposal({ proposal, index, voteChoices }: ProposalProps) {
  const initialValue = proposal.tooltipText
    ? typeof proposal.tooltipText === 'string'
      ? [{ children: [{ text: proposal.tooltipText }] }]
      : proposal.tooltipText
    : [
        {
          children: [{ text: '' }],
        },
      ];
  const classes = useStyles();
  const [value, setValue] = React.useState<Node[]>(initialValue);
  const editor = React.useMemo(
    () => withHistory(withReact(createEditor())),
    [],
  );
  const renderElement = React.useCallback(
    (props) => <Element {...props} />,
    [],
  );
  const renderLeaf = React.useCallback((props) => <Leaf {...props} />, []);

  return (
    <Draggable draggableId={String(proposal.id)} index={index}>
      {(provided) => (
        <Accordion
          square={true}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className={classes.accordionSummary}
            IconButtonProps={{
              className: classes.expandMoreIcon,
              size: 'small',
            }}
          >
            <DragIndicator className={classes.dragIndicator} />
            <Box>{proposal.directorName || proposal.details}</Box>
          </AccordionSummary>

          <AccordionDetails className={classes.proposalAccordionDetails}>
            {proposal.tooltipText ? (
              <div className={classes.proposalAccordionDetailsRow}>
                <Slate
                  editor={editor}
                  value={value}
                  onChange={(value) => setValue(value)}
                >
                  <StyledEditable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    readOnly={true}
                  />
                </Slate>
              </div>
            ) : null}

            <div className={classes.proposalAccordionDetailsRow}>
              <VoteChoicesPreview voteChoices={voteChoices} />
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
}

const Element = ({ attributes, children, element }: any) => {
  switch (element.type) {
    case 'bulleted-list':
      return <BulletedList {...attributes}>{children}</BulletedList>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <NumberedList {...attributes}>{children}</NumberedList>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

type VoteChoicesPreviewProps = {
  voteChoices: string[];
};

function VoteChoicesPreview({ voteChoices }: VoteChoicesPreviewProps) {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <RadioGroup
        aria-label="choices"
        name="choices"
        row={true}
        className={classes.voteChoiceRadios}
      >
        {voteChoices.map((voteChoice) => (
          <FormControlLabel
            key={voteChoice}
            value="disabled"
            disabled={true}
            control={<Radio size="small" />}
            label={voteChoice}
            className={classes.voteChoiceLabel}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

// utils

function sortProposals(groupedProposals: GroupedProposal[]) {
  const sorted = groupedProposals
    .sort((a, b) => a.groupNumber - b.groupNumber)
    .map((groupedProposal) => ({
      ...groupedProposal,
      proposals: groupedProposal.proposals.sort(
        (a, b) => a.proposalNumber - b.proposalNumber,
      ),
    }));

  return sorted;
}

function groupVoteProposals(voteProposals: VoteProposal[]) {
  const grouped = voteProposals.reduce((acc, voteProposal) => {
    const {
      groupNumber,
      isRoutine,
      recommendationType,
      voteChoices,
      title,
      voteType,
      type,
      id,
      proposalNumber,
      details,
      directorName,
      tooltipText,
    } = voteProposal;

    const foundGroupedProposal = acc.find(
      (vp: GroupedProposal) => vp.groupNumber === groupNumber,
    );

    if (foundGroupedProposal) {
      const newAcc = acc.map((a) => {
        if (a.groupNumber === groupNumber) {
          return {
            ...a,
            proposals: [
              ...a.proposals,
              {
                id,
                proposalNumber,
                tooltipText,
                details,
                directorName,
              },
            ],
          };
        }

        return a;
      });

      return newAcc;
    } else {
      acc.push({
        groupNumber,
        routineness: isRoutine ? 'routine' : 'nonRoutine',
        recommendationType,
        voteChoices,
        title,
        voteType,
        type,
        proposals: [
          {
            id,
            proposalNumber,
            tooltipText,
            details,
            directorName,
          },
        ],
      });
    }

    return acc;
  }, [] as GroupedProposal[]);

  return grouped;
}

function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function formatVoteChoices(voteType: VoteType, proposalType: ProposalType) {
  if (proposalType === 'SayOnPay') {
    return 'One Year, Two Years, Three Years, Four Years';
  }

  switch (voteType) {
    case 'election_majority':
      return 'For, Against, Abstain';
    case 'election_majority_forabstain':
      return 'For, Abstain';
    case 'election_majority_foragainst':
      return 'For, Against';
    case 'election_majority_foragainstwithhold':
      return 'For, Against, Withhold';
    case 'election_majority_forwithholdabstain':
      return 'For, Withhold, Abstain';
    case 'election_majority_yesno':
      return 'Yes, No';
    case 'election_plurality':
      return 'For, Withhold';
    default:
      return 'None';
  }
}

const StyledEditable = styled(Editable)`
  font-size: 12px;
  color: #787878;
`;

const BulletedList = styled.ul`
  list-style: disc;
  margin: initial;
  padding: initial;
`;

const NumberedList = styled.ol`
  list-style: decimal;
  margin: initial;
  padding: initial;
`;

export { BallotDetails };
