import styled from 'styled-components/macro';
import { space, SpaceProps, width, WidthProps } from 'styled-system';

interface InputProps extends SpaceProps, WidthProps {}

export const Textarea = styled.textarea<InputProps>`
  ${space}
  ${width}
  border: 2px solid ${({ theme }) => theme.colors.lightDivider};
  border-radius: ${({ theme }) => theme.radii[2]}px;
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  padding: ${({ theme }) => theme.spacing[2]}px;
  resize: none;

  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors.divider};
  }
`;
