import React from 'react';
import { RouteComponentProps } from '@reach/router';
import useSWR from 'swr';
import { privateApi } from '../../../old/utils/api-adapter';
import { FilingsList } from '../FilingsList';
import { CorporateActionsList } from '../CorporateActionsList';
import { PageWrapper } from '../../../components/PageWrapper';

export type FilingsResponse = Filing[];
export type CorporateActionsResponse = CorporateAction[];

interface Filing {
  id: number;
  issuerId: string;
  issuerName: string;
  cusips: string[];
  tickers: string[] | null[];
  edgarDate: null | string;
  edgarUrl: null | string;
  type: string;
  operationsStatus: string;
  recordDate: string;
  meetingDate: string;
  positions: number;
  expectedDate: string;
}

interface CorporateAction {
  announcementDate: string;
  cusips: string[];
  edgarFilings: EdgarFiling[];
  electionCutoffDate: string;
  id: number;
  issuerId: string;
  issuerName: string;
  operationsStatus: string;
  positions: number;
  record_date: string;
  tickers: string[] | null;
  type: string;
}

interface EdgarFiling {
  cik: null | string;
  filingDate: null | string;
  status: null | string;
  type: null | string;
  url: null | string;
}

const issuerFilingsFetcher = (url: string) =>
  privateApi.get<FilingsResponse>(url);
const issuerCorporateActionsFetcher = (url: string) =>
  privateApi.get<CorporateActionsResponse>(url);

function Activity({ id }: RouteComponentProps<{ id: string }>) {
  const filings = useSWR(`/admin/issuers/${id}/filings/`, issuerFilingsFetcher);

  const corporateActions = useSWR(
    `/admin/issuers/${id}/corporate-actions/`,
    issuerCorporateActionsFetcher,
  );

  return (
    <PageWrapper>
      <FilingsList cache={filings} />

      <CorporateActionsList cache={corporateActions} />
    </PageWrapper>
  );
}

export { Activity };
