import { FilingType } from './filing-type';
import { ISolicitor, ITabulator, ICompanyContact } from './intermediaries';
import { CorporateActionType } from './corporate-action-type';

export interface IFilingCreateValues {
  issuer: Issuer | null;
  actionDetail?: string | null;
  actionType?: CorporateActionType | '';
  dtcExpirationDate?: string | null;
  electionCutoffDate?: string | null;
  electionCutoffTime?: string | null;
  expirationDate?: string | null;
  informationAgent?: string;
  informationAgentEmail?: string;
  notificationReceivedDate?: string | null;
  recordDateEnd?: string | null;
  recordDateStart?: string | null;
  deliveryMethod: DeliveryMethod | null;
  type: FilingType;
  reportType: ReportType;
  fundOwner?: string;
  cusips: {
    id: number;
    cusip: string;
    name: string;
  }[];
  recordDateCompare: boolean;
  additionalRecordDates?: string[];
  brokers?: Broker[];
  materialsExpectedDate?: string;
  recordDate?: string;
  brokerSearchReceivedDate?: string;
  meetingDate?: string;
  meetingType?: MeetingType;
  solicitorJobNumber?: string;
  solicitor?: ISolicitor;
  tabulator?: ITabulator;
  contestingEntity?: string;
  issuerFiling?: string;
  notes?: string;
  replyTo?: ICompanyContact[];
  sendTo?: ICompanyContact[];
  invoicerAddress?: string;
  invoicerAddressLine1?: string;
  invoicerAddressLine2?: string;
  invoicerAddressLine3?: string;
  invoicerCity?: string;
  invoicerState?: string;
  invoicerPostalCode?: string;
  invoicerContactEmail?: string;
  voteCutoffDate?: string;
  relatedFiling?: RelatedFiling | null;
}

type Broker = {
  id: number;
  name: string;
  slug: string;
};

export interface Issuer {
  id: number;
  companyName: string;
  contactEmail: null | string;
}

export interface Meeting {
  type: MeetingType;
  title: null;
  date: string | null;
  time: null | string;
  timezone: string;
  contested: boolean;
  proxyType: ProxyType;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  country: string;
  state: string;
  isPhysical: boolean;
  virtualMeetingURL: null | string;
}

export enum ProxyType {
  Traditional = 'traditional',
  Unspecified = 'unspecified',
}

export interface RelatedFiling {
  deliveryMethod: DeliveryMethod;
  meetingDate: string | null;
  solicitorCompany: string | null;
  issuer: Issuer;
  recordDate: string | null;
}

export type ReportType = 'annual' | 'semi_annual';

export enum DeliveryMethod {
  NoticeAndAccess = 'notice_and_access',
  Traditional = 'traditional',
}

export enum MeetingType {
  Annual = 'annual',
  Special = 'special',
}
