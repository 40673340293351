import { toast } from 'react-toastify';
import axios from 'axios';

async function geocode(address: string) {
  const url = `https://geocoder.say.rocks/`;
  const requestBody = { address };

  try {
    const { data } = await axios.post(url, requestBody);

    const {
      house_number,
      house,
      level,
      road,
      city,
      state,
      unit,
      postcode,
      country,
    } = data;

    const address1 = house_number ? `${house_number} ${road}` : road;
    const address = {
      line1: address1 || '',
      line2: unit || level || '',
      line3: house || '',
      city: city || '',
      state: state || '',
      postalCode: postcode || '',
      country: country || '',
    };

    return address;
  } catch (err) {
    toast.error(`Unable to fetch: ${err} from ${url}`);
  }
}

export { geocode };
