import React from 'react';
import { OperationsStatus } from './types';

function StatusBadge({ status }: { status: OperationsStatus }) {
  switch (status) {
    case 'new':
      return (
        <div className={`${baseBadgeClasses} border-primary-text bg-white`}>
          New
        </div>
      );
    case 'active':
    case 'materials_requested':
    case 'ready_for_review':
      return (
        <div className={`${baseBadgeClasses} border-light-blue bg-light-blue`}>
          In Progress
        </div>
      );
    case 'approved':
      return (
        <div
          className={`${baseBadgeClasses} border-light-green bg-light-green`}
        >
          Open
        </div>
      );
    case 'closed':
      return (
        <div className={`${baseBadgeClasses} border-light-red bg-light-green`}>
          Closed
        </div>
      );
  }
}

const baseBadgeClasses =
  'h-4 text-xs border rounded px-2 flex items-center justify-center uppercase';

export { StatusBadge };
