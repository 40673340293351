import { IVoteProposal } from '../models/vote-proposal';

export const reIndexVPGroups = (
  vpGroups: IVoteProposal[][],
): IVoteProposal[][] => {
  return vpGroups.map((vpGroup: IVoteProposal[], i: number) => {
    return vpGroup.map((vp: IVoteProposal, j: number) => {
      return {
        ...vp,
        groupNumber: i + 1,
        proposalNumber: j + 1,
      };
    });
  });
};
