import { ReactComponent as SvgSpinner } from './spinner.svg';
import styled, { keyframes } from 'styled-components/macro';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(SvgSpinner)<{ size?: number }>`
  animation: ${spin} 1s infinite;
  height: ${({ size }) => (size ? size : '40')}px;
  width: ${({ size }) => (size ? size : '40')}px;
  .spinner-border {
    fill: transparent;
    height: 100%;
    stroke-width: 2;
    width: 100%;
  }

  .path {
    stroke-dasharray: 170;
    stroke-dashoffset: 20;
  }

  stop {
    stop-color: ${({ theme }) => theme.colors.green};
  }
`;
