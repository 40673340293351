/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { RouteComponentProps, Redirect, Router } from '@reach/router';
import { RequestMaterials } from './Tabs/RequestMaterials';
import { CompleteFilings } from './Tabs/Complete';
import { ReviewEmails } from './Tabs/ReviewEmails';
import { EmailPreview } from '../../EmailPreview/EmailPreview';
import { Flex } from '@rebass/grid';
import { Tab } from '../../common/Tab';
import { Text } from '../../common/Text';
import { Tabs } from '../../common/Tabs';
import styled from 'styled-components/macro';
import { Ordering } from '../../data/Ordering.Context';
import { Proxy as ProxyContext } from '../../data/Proxy';
import { ProxyLayout } from './ProxyLayout';

const StickyTabs = styled(Flex)`
  position: sticky;
  top: 73px;
  z-index: 1;
`;

enum TabChoice {
  Complete = 'complete',
  RequestMaterials = 'request_materials',
  ReviewEmails = 'review_emails',
}

export const Proxy: FC<RouteComponentProps> = () => {
  const { ordering } = Ordering.useContainer();
  const {
    getTasksFilingByTab,
    proxy,
    changePage,
    markMaterialsRequested,
    markReadyForReview,
    unmarkMaterialsRequested,
    unmarkReadyForReview,
  } = ProxyContext.useContainer();

  useEffect(() => {
    getTasksFilingByTab(
      TabChoice.Complete,
      ordering.completeFilings,
      proxy.search,
      proxy.filingTypeFilter,
    );
  }, [ordering.completeFilings, proxy.search, proxy.filingTypeFilter]);

  useEffect(() => {
    getTasksFilingByTab(
      TabChoice.RequestMaterials,
      ordering.requestMaterials,
      proxy.search,
      proxy.filingTypeFilter,
    );
  }, [ordering.requestMaterials, proxy.search, proxy.filingTypeFilter]);

  useEffect(() => {
    getTasksFilingByTab(
      TabChoice.ReviewEmails,
      ordering.reviewEmails,
      proxy.search,
      proxy.filingTypeFilter,
    );
  }, [ordering.reviewEmails, proxy.filingTypeFilter, proxy.search]);

  return (
    <ProxyLayout>
      <StickyTabs flexDirection="column" width={1} bg="white">
        <Tabs columns={3}>
          <Tab link="request-materials">
            <Flex justifyContent="space-between">
              <Text>Request Materials</Text>
              <Text className="count">
                {proxy.requestMaterials.filings.count}
              </Text>
            </Flex>
          </Tab>
          <Tab link="complete-filings">
            <Flex justifyContent="space-between">
              <Text>Complete Filings</Text>
              <Text className="count">{proxy.complete.filings.count}</Text>
            </Flex>
          </Tab>
          <Tab link="review-emails">
            <Flex justifyContent="space-between">
              <Text>Review Emails</Text>
              <Text className="count">{proxy.reviewEmails.filings.count}</Text>
            </Flex>
          </Tab>
        </Tabs>
      </StickyTabs>

      <Router>
        <Redirect
          from="/"
          to="/old/filings/proxy/request-materials"
          noThrow={true}
        />
        <RequestMaterials
          path="request-materials"
          isLoading={proxy.isLoading}
          filings={proxy.requestMaterials.filings}
          onChangePage={changePage}
          onMarkMaterialsRequested={markMaterialsRequested}
          onUnmarkMaterialsRequested={unmarkMaterialsRequested}
        />
        <CompleteFilings
          path="complete-filings"
          isLoading={proxy.isLoading}
          filings={proxy.complete.filings}
          onChangePage={changePage}
          onMarkReadyForReview={markReadyForReview}
          onUnmarkReadyForReview={unmarkReadyForReview}
        />
        <ReviewEmails
          path="review-emails"
          isLoading={proxy.isLoading}
          filings={proxy.reviewEmails.filings}
          onChangePage={changePage}
        >
          <EmailPreview path=":id" />
        </ReviewEmails>
      </Router>
    </ProxyLayout>
  );
};
