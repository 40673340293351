import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

type Props = {
  value: CompanyContact[];
  options: CompanyContact[];
  onSetSolicitorContacts: (companyContacts: CompanyContact[]) => void;
};

export interface CompanyContact {
  id: number;
  contact: Contact;
  role: Role;
  isDefaultForRole: boolean;
}

export interface Contact {
  id: number;
  name: string;
  email: string;
  phone: null | string;
}

type Role = 'other' | 'solicitor_reply_to' | 'tabulator_reply_to';

function SolicitorContactsTypeahead({
  value,
  options,
  onSetSolicitorContacts,
}: Props) {
  const [searchTerm, setSearchTerm] = React.useState('');

  return (
    <Autocomplete
      multiple
      style={{ paddingTop: '24px' }}
      options={options}
      getOptionLabel={(option) => option.contact.email}
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      filterSelectedOptions
      inputValue={searchTerm}
      ChipProps={{ size: 'small' }}
      noOptionsText="No solicitor contacts"
      onChange={(_, changeValue) => {
        onSetSolicitorContacts(changeValue);
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'clear') {
          onSetSolicitorContacts([]);
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label="Solicitor Contact(s)"
          placeholder="Start typing solicitor contacts..."
          required={true}
        />
      )}
    />
  );
}

export { SolicitorContactsTypeahead };
