import { hubApi, hubApiFormData } from './hubApiConfig';
import { IssuerInvoicingAddress } from '../models/prospectus/issuer';

const getFunds = ({
  search = '',
  ordering = '',
  prospectusStatus = '',
  limit = '100',
  offset = '',
}: {
  search?: string;
  ordering?: string;
  prospectusStatus?: string;
  limit?: string;
  offset?: string;
}) =>
  hubApi.get(
    `/issuers/?prospectus_status=${prospectusStatus}&issuer_type=investment_trust&search=${encodeURIComponent(
      search,
    )}&ordering=${ordering}&limit=${limit}&offset=${offset}`,
  );

const getCompanies = ({
  search = '',
  ordering = '',
  limit = '100',
  offset = '',
}: {
  search?: string;
  ordering?: string;
  limit?: string;
  offset?: string;
}) =>
  hubApi.get(
    `/issuers/?issuer_type=corporation&search=${encodeURIComponent(
      search,
    )}&ordering=${ordering}&limit=${limit}&offset=${offset}`,
  );

const createProspectusDocument = (payload: any) =>
  hubApiFormData.post('/securities/prospectuses/', payload);

const getIssuersByName = (name: string) =>
  hubApi.get(`/issuers/issuer-typeahead/?name=${name}`);

const getFundOwnersByName = (name: string) =>
  hubApi.get(`/issuers/fund-owner-typeahead/?name=${name}/`);

const createSecurity = (security: { cusip: string; issuerId: string | null }) =>
  hubApi.post(`/securities/security/`, security);

const createIssuer = (name: string) =>
  hubApi.post('/issuers/issuer/', { name });

const getIssuerById = (id: string) => hubApi.get(`/issuers/issuer/${id}/`);

const updateIssuerById = (
  id: string,
  issuer: {
    name: string;
    fiscalYearEnd?: string | null;
  },
  invoicingAddress: IssuerInvoicingAddress,
) => {
  const payload = {
    ...issuer,
    invoicingAddress,
  };

  return hubApi.patch(`/issuers/issuer/${id}/`, payload);
};

const updateProspectusById = (
  id: string,
  prospectus: {
    name: string;
    frontLink: string;
    website: string;
    securityIds: string[];
    effectiveEndDate: string;
    effectiveStartDate: string;
  },
) => hubApi.patch(`/securities/prospectuses/${id}/`, prospectus);

const deleteProspectus = (id: string) =>
  hubApi.delete(`/securities/prospectuses/${id}/`);

export {
  getFunds,
  getCompanies,
  createProspectusDocument,
  createIssuer,
  getIssuersByName,
  getIssuerById,
  updateIssuerById,
  updateProspectusById,
  deleteProspectus,
  getFundOwnersByName,
  createSecurity,
};
