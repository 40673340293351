import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import { IFilingCreateValues } from '../../models/filing-form';
import { Asterisk } from '../Asterik/Asterik';
import { Label } from '../Label';
import { Input } from '../Input';
import { InputGroup } from '../InputGroup';
import { ErrorLabel } from '../ErrorLabel';

export const FundOwner: FC<{}> = () => {
  return (
    <Field
      name="fundOwner"
      render={({ field, form }: FieldProps<IFilingCreateValues>) => (
        <InputGroup>
          <Label htmlFor="fund-owner">
            Fund Owner <Asterisk />
            {form.errors.fundOwner && (
              <ErrorLabel>{form.errors.fundOwner}</ErrorLabel>
            )}
          </Label>

          <Input id="fund-owner" {...field} />
        </InputGroup>
      )}
    />
  );
};
