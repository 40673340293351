import React from 'react';
import {
  CorporateAction,
  CorporateActionFiling,
  MeetingResult,
  Proxy,
  ProxyFiling,
  Search,
  Prospectus,
} from '../../@types/search';
import { ReactComponent as CorporateActionIcon } from './corporate-action.svg';
import { ReactComponent as ProxyIcon } from './proxy.svg';
import { ReactComponent as ProspectusIcon } from './prospectus.svg';
import { ReactComponent as ResultsIcon } from './results.svg';
import { ReactComponent as IssuerIcon } from './issuers.svg';
import { useNavigate } from '@reach/router';
import { humanize } from '../../utils/humanize';
import { formatDate } from '../../utils/format-date';

import {
  makeStyles,
  Theme,
  createStyles,
  createMuiTheme,
} from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Chip from '@material-ui/core/Chip';
import { ThemeProvider } from '@material-ui/core';

type SearchResultsProps = {
  className?: string;
  style?: React.CSSProperties;
  isLoading: boolean;
  isError: boolean;
  results: Search | null;
  onClear: () => void;
};

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '16px 14px',
      },
      sizeSmall: {
        padding: '6px 12px',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerBox: {
      backgroundColor: '#FFFFFF',
      boxShadow:
        '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);0 5px 4px -4px rgba(0,0,0,0.5)',
    },
    containerPaper: {
      padding: '16px 80px',
      backgroundColor: '#FFFFFF',
      borderBottom: '1px solid #e5e5e5',
      boxShadow:
        '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);0 5px 4px -4px rgba(0,0,0,0.5)',
    },
    rowStyle: {
      '&:hover': {
        background: '#e5e5e5',
        cursor: 'pointer',
      },
    },
    resultsTable: {
      marginLeft: '128px',
      overflow: 'auto',
      borderTop: '1px solid #e5e5e5',
    },
    resultsRowHeader: {
      padding: theme.spacing(1, 2),
      background: '#FFF',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        background: '#e5e5e5',
        cursor: 'pointer',
      },
    },
    selectedRowHeader: {
      padding: theme.spacing(1, 3),
      background: '#FFF',
      display: 'flex',
      alignItems: 'center',
    },
    blankCell: {
      border: 0,
      width: '12px',
    },
  }),
);

type Product =
  | 'filings'
  | 'meetingResults'
  | 'tasks'
  | 'corporateActions'
  | 'issuers';

const SearchResults = ({
  isLoading,
  isError,
  results,
  className = '',
  style = {},
  onClear,
}: SearchResultsProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(
    null,
  );
  const handleSelectProduct = (product: Product) => setSelectedProduct(product);
  const handleBackClick = () => setSelectedProduct(null);
  const handleClose = () => {
    onClear();
    setSelectedProduct(null);
  };
  function handleTableRowClick(path: string) {
    return function (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
      if (e.metaKey) {
        window.open(path, '_blank');
        return;
      }
      navigate(path);
      handleClose();
    };
  }

  if (isLoading)
    return (
      <Paper elevation={0} className={classes.containerPaper}>
        Loading...
      </Paper>
    );

  if (isError)
    return (
      <Paper elevation={0} className={classes.containerPaper}>
        Error
      </Paper>
    );

  if (results && !selectedProduct) {
    return (
      <ThemeProvider theme={theme}>
        <Table
          id="SearchResultContainer"
          className={classes.containerBox}
          size="small"
        >
          <TableBody>
            <TableRow
              id={'ProxyResult'}
              className={classes.rowStyle}
              onClick={() =>
                results.filings.reduce(
                  (acc, curr) => acc + curr.results.length,
                  0,
                ) > 0
                  ? handleSelectProduct('filings')
                  : null
              }
            >
              <TableCell className={classes.blankCell}>
                <IconButton disabled>
                  <ProxyIcon />{' '}
                </IconButton>
              </TableCell>
              <TableCell>
                {results.filings.reduce(
                  (acc, curr) => acc + curr.results.length,
                  0,
                )}{' '}
                filings
              </TableCell>
            </TableRow>

            <TableRow
              id={'ProspectusResult'}
              className={classes.rowStyle}
              onClick={() =>
                results.tasks.length ? handleSelectProduct('tasks') : null
              }
            >
              <TableCell className={classes.blankCell}>
                <IconButton disabled>
                  <ProspectusIcon />
                </IconButton>
              </TableCell>
              <TableCell>{results.tasks.length} tasks</TableCell>
            </TableRow>
            <TableRow
              className={classes.rowStyle}
              onClick={() =>
                results.corporateActions.reduce(
                  (acc, curr) => acc + curr.results.length,
                  0,
                )
                  ? handleSelectProduct('corporateActions')
                  : null
              }
            >
              <TableCell className={classes.blankCell}>
                <IconButton disabled id={'CorporateActionResult'}>
                  <CorporateActionIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                {results.corporateActions.reduce(
                  (acc, curr) => acc + curr.results.length,
                  0,
                )}{' '}
                filings
              </TableCell>
            </TableRow>
            <TableRow
              id={'MeetingResult'}
              className={classes.rowStyle}
              onClick={() =>
                results.meetingResults.reduce(
                  (acc, curr) => acc + curr.results.length,
                  0,
                )
                  ? handleSelectProduct('meetingResults')
                  : null
              }
            >
              <TableCell className={classes.blankCell}>
                <IconButton disabled>
                  <ResultsIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                {results.meetingResults.reduce(
                  (acc, curr) => acc + curr.results.length,
                  0,
                )}{' '}
                meeting results
              </TableCell>
            </TableRow>
            <TableRow
              id={'IssuersResult'}
              className={classes.rowStyle}
              onClick={() =>
                results.issuers.length ? handleSelectProduct('issuers') : null
              }
            >
              <TableCell className={classes.blankCell}>
                <IconButton disabled>
                  <IssuerIcon />
                </IconButton>
              </TableCell>
              <TableCell style={{ border: 0 }}>
                {results.issuers.length} issuers
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ThemeProvider>
    );
  }

  if (results && selectedProduct) {
    switch (selectedProduct) {
      case 'filings':
        return (
          <ProxyIssuers
            issuers={results.filings}
            className={className}
            style={style}
            onBackClick={handleBackClick}
            onClose={handleClose}
          />
        );
      case 'corporateActions':
        return (
          <CorporateActionIssuers
            issuers={results.corporateActions}
            className={className}
            style={style}
            onBackClick={handleBackClick}
            onClose={handleClose}
          />
        );
      case 'meetingResults':
        return (
          <MeetingResults
            issuers={results.meetingResults}
            className={className}
            style={style}
            onBackClick={handleBackClick}
            onClose={handleClose}
          />
        );
      case 'issuers':
        return (
          <SelectedView
            onBackClick={handleBackClick}
            className={className}
            style={style}
            icon={<IssuerIcon />}
            title={`${results.issuers.length} issuers`}
            onClose={handleClose}
          >
            <Table id="SelectedResults" className={classes.resultsTable}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className={tableHeadTitleStyles}>Issuers</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.issuers.map((issuer) => (
                  <TableRow
                    key={issuer.id}
                    className={classes.rowStyle}
                    onClick={handleTableRowClick(`/issuers/${issuer.id}`)}
                  >
                    <TableCell>
                      <span>{issuer.name}</span>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    style={{ maxHeight: '20px', border: 0 }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </SelectedView>
        );
      case 'tasks':
        type parsedTask = {
          cusip: string;
          minDate: string;
        };

        const parseTasks = (prospectus: Prospectus): parsedTask => {
          let cusip = '';
          let minDate = '9999';
          for (let j = 0; j < prospectus['statuses'].length; j++) {
            if (prospectus['statuses'][j] === 'missing') {
              cusip = prospectus['cusips'][j];
              break;
            } else if (minDate > prospectus['expectedDates'][j]) {
              minDate = prospectus['expectedDates'][j];
              cusip = prospectus['cusips'][j];
            }
          }
          return { cusip, minDate };
        };

        return (
          <SelectedView
            onBackClick={handleBackClick}
            className={className}
            style={style}
            icon={<ProspectusIcon />}
            title={`${results.tasks.length} tasks`}
            onClose={handleClose}
          >
            <Table id="SelectedResults" className={classes.resultsTable}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className={tableHeadTitleStyles}>Issuer</span>
                  </TableCell>
                  <TableCell>
                    <span className={tableHeadTitleStyles}>CUSIP</span>
                  </TableCell>
                  <TableCell>
                    <span className={tableHeadTitleStyles}>Expected Date</span>
                  </TableCell>
                  <TableCell>
                    <span className={tableHeadTitleStyles}>Status</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.tasks.map((prospectus, index) => (
                  <TableRow
                    key={`${prospectus.issuerId}-${index}`}
                    className={classes.rowStyle}
                    onClick={handleTableRowClick(
                      `/prospectuses/missing-expired?id=${prospectus.issuerId}`,
                    )}
                  >
                    <TableCell>{prospectus.issuerName}</TableCell>
                    <TableCell>{parseTasks(prospectus).cusip}</TableCell>
                    <TableCell>
                      {!prospectus.statuses.includes('missing')
                        ? formatDate(new Date(parseTasks(prospectus).minDate))
                        : null}
                    </TableCell>
                    <TableCell>
                      <span className="capitalize">
                        {prospectus.statuses.includes('missing')
                          ? 'Missing'
                          : null}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    style={{ maxHeight: '20px', border: 0 }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </SelectedView>
        );
    }
  }

  return (
    <Paper elevation={0} className={classes.containerPaper}>
      Unable to retrieve results for your query.
    </Paper>
  );
};

const tableHeadTitleStyles = `uppercase text-xxs text-secondary-text font-medium`;

function ProxyIssuers({
  issuers,
  onBackClick,
  className,
  style,
  onClose,
}: {
  issuers: Proxy[];
  onBackClick: () => void;
  className: string;
  style: React.CSSProperties;
  onClose: () => void;
}) {
  const classes = useStyles();
  const [selectedIssuer, setSelectedIssuer] = React.useState<string>('');
  const navigate = useNavigate();
  const handleSelectIssuer = (id: string) => setSelectedIssuer(id);
  const handleBackToIssuersClick = () => setSelectedIssuer('');

  function handleTableRowClick(path: string) {
    return function (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
      if (e.metaKey) {
        window.open(path, '_blank');
        return;
      }
      navigate(path);
      onClose();
    };
  }

  const issuer = React.useMemo(
    () => issuers.find((issuer) => issuer.id === selectedIssuer),
    [selectedIssuer, issuers],
  );

  const totalNumberOfFilings = issuers.reduce(
    (acc, curr) => acc + curr.results.length,
    0,
  );

  const newFiling = issuer ? filterAndSortByStatus(issuer.results, 'new') : [];
  const open = issuer ? filterAndSortByStatus(issuer.results, 'open') : [];
  const active = issuer ? filterAndSortByStatus(issuer.results, 'active') : [];
  const materialsRequested = issuer
    ? filterAndSortByStatus(issuer.results, 'materials_requested')
    : [];
  const readyForReview = issuer
    ? filterAndSortByStatus(issuer.results, 'ready_for_review')
    : [];
  const approved = issuer
    ? filterAndSortByStatus(issuer.results, 'approved')
    : [];
  const closed = issuer ? filterAndSortByStatus(issuer.results, 'closed') : [];

  const filings: ProxyFiling[] = [
    ...newFiling,
    ...open,
    ...active,
    ...materialsRequested,
    ...readyForReview,
    ...approved,
    ...closed,
  ];

  if (selectedIssuer && issuer) {
    return (
      <SelectedView
        onBackClick={handleBackToIssuersClick}
        className={className}
        style={style}
        icon={<ProxyIcon />}
        title={issuer.name}
        onClose={onClose}
      >
        <Table id="SelectedResults" className={classes.resultsTable}>
          <TableHead>
            <TableRow>
              <TableCell>
                <span className={tableHeadTitleStyles}>State</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Filing Type</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Record Date</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Status</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filings.map((filing) => (
              <TableRow
                key={filing.id}
                className={classes.rowStyle}
                onClick={handleTableRowClick(`/proxy/${filing.id}`)}
              >
                <TableCell>
                  <StateTag status={filing.status} />
                </TableCell>
                <TableCell>{filing.type}</TableCell>

                <TableCell>{formatDate(new Date(filing.recordDate))}</TableCell>

                <TableCell>{filing.status}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell style={{ maxHeight: '20px', border: 0 }}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </SelectedView>
    );
  }
  return (
    <SelectedView
      onBackClick={onBackClick}
      className={className}
      style={style}
      icon={<ProxyIcon />}
      title={`${totalNumberOfFilings} filings`}
      onClose={onClose}
    >
      <Table id="SelectedResults" className={classes.resultsTable}>
        <TableHead>
          <TableRow>
            <TableCell>
              <span className={tableHeadTitleStyles}>Issuers</span>
            </TableCell>
            <TableCell>
              <span className={tableHeadTitleStyles}>Total Filings</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {issuers.map((issuer) =>
            issuer.results.length ? (
              <TableRow
                key={issuer.id}
                className={classes.rowStyle}
                onClick={() => handleSelectIssuer(issuer.id)}
              >
                <TableCell>{issuer.name}</TableCell>
                <TableCell>{issuer.results.length}</TableCell>
              </TableRow>
            ) : null,
          )}
          <TableRow>
            <TableCell style={{ maxHeight: '20px', border: 0 }}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </SelectedView>
  );
}

function CorporateActionIssuers({
  issuers,
  onBackClick,
  className,
  style,
  onClose,
}: {
  issuers: CorporateAction[];
  onBackClick: () => void;
  className: string;
  style: React.CSSProperties;
  onClose: () => void;
}) {
  const classes = useStyles();
  const [selectedIssuer, setSelectedIssuer] = React.useState<string>('');
  const navigate = useNavigate();
  const handleSelectIssuer = (id: string) => setSelectedIssuer(id);
  const handleBackToIssuersClick = () => setSelectedIssuer('');
  function handleTableRowClick(path: string) {
    return function (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
      if (e.metaKey) {
        window.open(path, '_blank');
        return;
      }
      navigate(path);
      onClose();
    };
  }

  const issuer = React.useMemo(
    () => issuers.find((issuer) => issuer.id === selectedIssuer),
    [selectedIssuer, issuers],
  );

  const totalNumberOfFilings = issuers.reduce(
    (acc, curr) => acc + curr.results.length,
    0,
  );

  const newFiling = issuer ? filterAndSortByStatus(issuer.results, 'new') : [];
  const open = issuer ? filterAndSortByStatus(issuer.results, 'open') : [];
  const active = issuer ? filterAndSortByStatus(issuer.results, 'active') : [];
  const materialsRequested = issuer
    ? filterAndSortByStatus(issuer.results, 'materials_requested')
    : [];
  const readyForReview = issuer
    ? filterAndSortByStatus(issuer.results, 'ready_for_review')
    : [];
  const approved = issuer
    ? filterAndSortByStatus(issuer.results, 'approved')
    : [];
  const closed = issuer ? filterAndSortByStatus(issuer.results, 'closed') : [];

  const filings: CorporateActionFiling[] = [
    ...newFiling,
    ...open,
    ...active,
    ...materialsRequested,
    ...readyForReview,
    ...approved,
    ...closed,
  ];

  if (selectedIssuer && issuer) {
    return (
      <SelectedView
        onBackClick={handleBackToIssuersClick}
        className={className}
        style={style}
        icon={<CorporateActionIcon />}
        title={issuer.name}
        onClose={onClose}
      >
        <Table id="SelectedResults" className={classes.resultsTable}>
          <TableHead>
            <TableRow>
              <TableCell>
                <span className={tableHeadTitleStyles}>State</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Filing Type</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Record Date</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Status</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filings.map((filing) => (
              <TableRow
                key={filing.id}
                className={classes.rowStyle}
                onClick={handleTableRowClick(`/corporate-actions/${filing.id}`)}
              >
                <TableCell>
                  <StateTag status={filing.status} />
                </TableCell>
                <TableCell>{filing.type}</TableCell>
                <TableCell>{formatDate(new Date(filing.recordDate))}</TableCell>
                <TableCell>{humanize(filing.status)}</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell style={{ maxHeight: '20px', border: 0 }}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </SelectedView>
    );
  }

  return (
    <SelectedView
      onBackClick={onBackClick}
      className={className}
      style={style}
      icon={<CorporateActionIcon />}
      title={`${totalNumberOfFilings} filings`}
      onClose={onClose}
    >
      <Table id="SelectedResults" className={classes.resultsTable}>
        <TableHead>
          <TableRow>
            <TableCell>
              <span className={tableHeadTitleStyles}>Issuers</span>
            </TableCell>
            <TableCell>
              <span className={tableHeadTitleStyles}>Total Filings</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {issuers.map((issuer) =>
            issuer.results.length ? (
              <TableRow
                key={issuer.id}
                className={classes.rowStyle}
                onClick={() => handleSelectIssuer(issuer.id)}
              >
                <TableCell>{issuer.name}</TableCell>
                <TableCell>{issuer.results.length}</TableCell>
              </TableRow>
            ) : null,
          )}
          <TableRow>
            <TableCell style={{ maxHeight: '20px', border: 0 }}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </SelectedView>
  );
}

function MeetingResults({
  issuers,
  onBackClick,
  className,
  style,
  onClose,
}: {
  issuers: MeetingResult[];
  onBackClick: () => void;
  className: string;
  style: React.CSSProperties;
  onClose: () => void;
}) {
  const classes = useStyles();
  const [selectedIssuer, setSelectedIssuer] = React.useState<string>('');
  const navigate = useNavigate();
  const handleSelectIssuer = (id: string) => setSelectedIssuer(id);
  const handleBackToIssuersClick = () => setSelectedIssuer('');
  function handleTableRowClick(path: string) {
    return function (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
      if (e.metaKey) {
        window.open(path, '_blank');
        return;
      }
      navigate(path);
      onClose();
    };
  }

  const issuer = React.useMemo(
    () => issuers.find((issuer) => issuer.id === selectedIssuer),
    [selectedIssuer, issuers],
  );

  const totalNumberOfFilings = issuers.reduce(
    (acc, curr) => acc + curr.results.length,
    0,
  );

  if (selectedIssuer && issuer) {
    return (
      <SelectedView
        onBackClick={handleBackToIssuersClick}
        className={className}
        style={style}
        icon={<ResultsIcon />}
        title={issuer.name}
        onClose={onClose}
      >
        <Table id="SelectedResults" className={classes.resultsTable}>
          <TableHead>
            <TableRow>
              <TableCell>
                {' '}
                <span className={tableHeadTitleStyles}>Status</span>
              </TableCell>
              <TableCell>
                {' '}
                <span className={tableHeadTitleStyles}>Filing Date</span>
              </TableCell>
            </TableRow>
          </TableHead>{' '}
          <TableBody>
            {issuer.results.map((filing) => (
              <TableRow
                key={filing.id}
                className={classes.rowStyle}
                onClick={handleTableRowClick(`/old/results/all/${filing.id}`)}
              >
                <TableCell>{humanize(filing.status)}</TableCell>
                <TableCell>{formatDate(new Date(filing.filingDate))}</TableCell>
              </TableRow>
            ))}
            <TableCell></TableCell>
            <TableRow>
              <TableCell style={{ maxHeight: '20px', border: 0 }}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </SelectedView>
    );
  }

  return (
    <SelectedView
      onBackClick={onBackClick}
      className={className}
      style={style}
      icon={<ResultsIcon />}
      title={`${totalNumberOfFilings} meeting results`}
      onClose={onClose}
    >
      <Table id="SelectedResults" className={classes.resultsTable}>
        <TableHead>
          <TableRow>
            <TableCell>
              {' '}
              <span className={tableHeadTitleStyles}>Issuers</span>
            </TableCell>
            <TableCell>
              {' '}
              <span className={tableHeadTitleStyles}>Total Filings</span>
            </TableCell>
          </TableRow>
        </TableHead>{' '}
        <TableBody>
          {issuers.map((issuer) =>
            issuer.results.length ? (
              <TableRow
                key={issuer.id}
                className={classes.rowStyle}
                onClick={() => handleSelectIssuer(issuer.id)}
              >
                <TableCell>{issuer.name}</TableCell>
                <TableCell>{issuer.results.length}</TableCell>
              </TableRow>
            ) : null,
          )}
          <TableRow>
            <TableCell style={{ maxHeight: '20px', border: 0 }}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </SelectedView>
  );
}

function SelectedView({
  onBackClick,
  children,
  style,
  className,
  icon,
  title,
  onClose,
}: {
  onBackClick: () => void;
  children: React.ReactNode;
  style: React.CSSProperties;
  className: string;
  icon: React.ReactNode;
  title: string;
  onClose: () => void;
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.containerBox}>
      <div id="SearchSelectedTitle" className={classes.selectedRowHeader}>
        <IconButton
          size="medium"
          id="ResultBackButton"
          onClick={onBackClick}
          edge="start"
          style={{ marginRight: '20px' }}
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
        <IconButton edge="start" disabled style={{ marginRight: '20px' }}>
          {icon}
        </IconButton>
        {title}
      </div>
      {children}
    </Paper>
  );
}

function StateTag({ status }: { status: string }) {
  switch (status) {
    case 'approved':
    case 'closed':
      return (
        <Chip
          style={{ backgroundColor: '#e5e5e5' }}
          size="small"
          label="Closed"
        />
      );
    default:
      return (
        <Chip
          style={{
            border: '1px solid #11CC99',
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }}
          size="small"
          label="Open"
        />
      );
  }
}

function filterAndSortByStatus(
  filings: ProxyFiling[] | CorporateActionFiling[],
  status: string,
) {
  return filings
    .filter((filing) => filing.status === status)
    .sort(
      (a, b) =>
        new Date(a.recordDate).getTime() - new Date(b.recordDate).getTime(),
    );
}

export { SearchResults };
