import React from 'react';

type TableTitleProps = {
  title: string;
};

const TableTitle = ({ title }: TableTitleProps) => (
  <h2 className="text-base font-medium mr-12 capitalize">{title}</h2>
);

export { TableTitle };
