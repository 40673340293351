import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { privateApi } from '../../old/utils/api-adapter';
import useSWR from 'swr';

type Props = {
  onClear: () => void;
  onSelect: (solicitor: Company) => void;
  value: Company;
};

type Companies = {
  count: number;
  next: string;
  previous: null;
  results: Company[];
};

type Company = {
  id: number;
  name: string;
  address: null | string;
  companyContacts: CompanyContact[];
  defaultTabulator?: DefaultTabulator | null;
  type: CompanyType;
};

export interface CompanyContact {
  id: number;
  contact: Contact;
  role: Role;
  isDefaultForRole: boolean;
}

export interface Contact {
  id: number;
  name: string;
  email: string;
  phone: null | string;
}

type Role = 'other' | 'solicitor_reply_to' | 'tabulator_reply_to';

type DefaultTabulator = {
  id: number;
  name: string;
  address: string;
  companyContacts: CompanyContact[];
};

type CompanyType = 'Solicitor' | 'Tabulator';

const fetcher = (url: string) => privateApi.get<Companies>(url);

function SolicitorTypeahead({ onClear, onSelect, value }: Props) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const { data, error } = useSWR(`/companies/?search=${searchTerm}`, fetcher);

  const options = data
    ? data.data.results.filter((result) => result.type === 'Solicitor')
    : [];

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      filterSelectedOptions
      inputValue={searchTerm}
      loading={!data && !error}
      ChipProps={{ size: 'small' }}
      noOptionsText="No solicitors"
      onChange={(_, changeValue, reason) => {
        if (reason === 'clear' || reason === 'remove-option') {
          onClear();
        }

        if (reason === 'select-option' && changeValue) {
          onSelect(changeValue);
        }
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'clear') {
          onClear();
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Solicitor"
          size="small"
          placeholder="Start typing solicitor..."
          required={true}
        />
      )}
    />
  );
}

export { SolicitorTypeahead };
