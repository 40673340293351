import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { IFilingCreateValues } from '../../models/filing-form';
import { Asterisk } from '../Asterik/Asterik';
import { Label } from '../Label';
import { Select } from '../Select';
import { InputGroup } from '../InputGroup';
import { FilingType } from '../../models/filing-type';
import { formatCamelCaseString } from '../../utils/format-camel-case-string';
import { ErrorLabel } from '../ErrorLabel';

export const FilingTypeSelect: FC<{}> = () => {
  const filingTypes = Object.values(FilingType);

  return (
    <Field
      name="type"
      render={({ field, form }: FieldProps<IFilingCreateValues>) => (
        <InputGroup>
          <Label htmlFor="type">
            Type <Asterisk />{' '}
            {form.errors['type'] && (
              <ErrorLabel>{form.errors['type']}</ErrorLabel>
            )}
          </Label>

          <Select id="type" required={true} {...field}>
            <option value="" disabled={true}>
              Select a Filing Type
            </option>
            {filingTypes.map((filingType) => (
              <option key={filingType} value={filingType}>
                {formatCamelCaseString(filingType)}
              </option>
            ))}
          </Select>
        </InputGroup>
      )}
    />
  );
};
