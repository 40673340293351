import React from 'react';
import { navigate, useMatch } from '@reach/router';
import { SearchInput, SearchResults } from '../../features/Search';
import { useSearch } from '../../hooks/useSearch';
import { Proxy } from '../../old/data/Proxy';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Theme,
} from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons';
import styled from 'styled-components';
import { useBatchFilingsState } from '../../features/Proxy/batch-filings-context';
import { ReactComponent as CorporateActionIcon } from '../../features/Search/corporate-action.svg';
import { ReactComponent as ProxyIcon } from '../../features/Search/proxy.svg';
import {
  useProxyWarningDispatch,
  useProxyWarningState,
  navigateAway,
} from '../../features/Proxy/proxy-warning-context';
import useOnClickOutside from 'use-onclickoutside';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bar: {
      height: '4.375rem',
      borderBottom: '1px solid #e5e5e5',
    },
    root: {
      minHeight: '4.375rem',
      justifyContent: 'space-between',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    icons: {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    createButton: {
      color: '#1a1a19',
      width: '112px',
    },
    menuItem: {
      minHeight: '4rem',
      minWidth: '25rem',
      padding: '0 !important',
      '&:hover': {
        backgroundColor: 'rgba(17, 204, 153, 0.04)',
      },
    },
    menuItemLayout: {
      display: 'flex',
      alignItems: 'stretch',
      width: '100%',
    },
    iconWrapper: {
      padding: '1.5rem',
    },
    labelWrapper: {
      flexGrow: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderBottom: '1px solid rgba(0,0,0,.12)',
    },
    searchResults: {
      position: 'absolute',
      width: '100%',
      top: '4.375rem',
      right: 0,
      left: 0,
      backgroundColor: '#FFF',
    },
  }),
);

const Header = () => {
  const classes = useStyles();
  const { searchTerm, onSetSearch, data, error } = useSearch();
  // remove eventually: old v1 logic
  const {
    setIsNewFilingDialogOpen: setIsNewProxyFilingDialogOpen,
  } = Proxy.useContainer();
  const match = useMatch('/proxy/:id');
  const filingId = match ? match.id : undefined;
  const batchFilings = useBatchFilingsState();
  const isLoading = !data && !error;
  const isError = !data && error;

  const searchResults = data && data.data ? data.data : null;
  const showingResults = searchTerm;

  const proxyCheck = useProxyWarningState();
  const dispatch = useProxyWarningDispatch();

  // Create Filing menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleCreateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCreateClose = () => {
    setAnchorEl(null);
  };

  // Detect search focus
  const [isSearchWide, setIsSearchWide] = React.useState(false);
  const ref = React.useRef(null);
  useOnClickOutside(ref, () => onSetSearch(''));

  function handleBackClick() {
    if (batchFilings.filings.length && filingId) {
      navigate(
        `/proxy/${getPreviousBatchFiling(
          batchFilings.filings,
          Number(filingId),
        )}`,
      );
    } else {
      window.history.back();
    }
  }

  function handleForwardClick() {
    if (batchFilings.filings.length && filingId) {
      navigate(
        `/proxy/${getNextBatchFiling(batchFilings.filings, Number(filingId))}`,
      );
    } else {
      window.history.forward();
    }
  }

  return (
    <>
      <AppBar
        aria-label="menu"
        position="sticky"
        elevation={!isSearchWide && !showingResults ? 0 : 1}
        className={classes.bar}
        style={
          !isSearchWide && !showingResults
            ? { background: '#F8f8f8' }
            : { background: '#FFFFFF' }
        }
      >
        <Toolbar className={classes.root}>
          <div className="flex align-center">
            {!showingResults && !isSearchWide ? (
              <>
                <ChevronButtons>
                  <IconButton
                    size="medium"
                    aria-label="go back"
                    onClick={handleBackClick}
                  >
                    <ChevronLeft fontSize="small" />
                  </IconButton>

                  <IconButton
                    size="medium"
                    aria-label="go forward"
                    onClick={handleForwardClick}
                  >
                    <ChevronRight fontSize="small" />
                  </IconButton>
                </ChevronButtons>
              </>
            ) : null}

            <div ref={ref}>
              <SearchInput
                onChange={onSetSearch}
                value={searchTerm}
                onClear={() => onSetSearch('')}
                setIsSearchWide={setIsSearchWide}
              />
              {showingResults ? (
                <div className={classes.searchResults}>
                  <SearchResults
                    isLoading={isLoading}
                    isError={isError}
                    results={searchResults}
                    onClear={() => onSetSearch('')}
                  />
                  ){' '}
                </div>
              ) : null}
            </div>
          </div>

          {!showingResults && !isSearchWide ? (
            <div color="'#11cc99'">
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                className={classes.createButton}
                onClick={handleCreateClick}
              >
                Create
              </Button>

              <Menu
                aria-controls="simple-menu"
                aria-haspopup="true"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCreateClose}
                getContentAnchorEl={null}
              >
                <MenuItem
                  disableGutters={true}
                  className={classes.menuItem}
                  onClick={() => {
                    handleCreateClose();
                    navigateAway(
                      '/corporate-actions/create',
                      proxyCheck,
                      dispatch,
                    );
                  }}
                >
                  <div className={classes.menuItemLayout}>
                    <div className={classes.iconWrapper}>
                      <CorporateActionIcon />
                    </div>
                    <div className={classes.labelWrapper}>
                      <div>Voluntary Corporate Action</div>
                    </div>
                  </div>
                </MenuItem>
                <MenuItem
                  disableGutters={true}
                  className={classes.menuItem}
                  onClick={() => {
                    handleCreateClose();
                    navigateAway('/proxy/create', proxyCheck, dispatch);
                  }}
                >
                  <div className={classes.menuItemLayout}>
                    <div className={classes.iconWrapper}>
                      <ProxyIcon />
                    </div>
                    <div className={classes.labelWrapper}>
                      <div>Proxy Filing</div>
                    </div>
                  </div>
                </MenuItem>
                <MenuItem
                  disableGutters={true}
                  className={classes.menuItem}
                  onClick={() => {
                    handleCreateClose();
                    setIsNewProxyFilingDialogOpen(true);
                    navigate('/old/filings/proxy/request-materials');
                  }}
                >
                  <div className={classes.menuItemLayout}>
                    <div className={classes.iconWrapper}>
                      <ProxyIcon />
                    </div>
                    <div className={classes.labelWrapper}>
                      <div>Proxy Filing (Legacy)</div>
                    </div>
                  </div>
                </MenuItem>
                <MenuItem
                  disableGutters={true}
                  className={classes.menuItem}
                  onClick={() => {
                    handleCreateClose();
                  }}
                >
                  <div className={classes.menuItemLayout}>
                    <div className={classes.iconWrapper}>
                      <FileCopyIcon style={{ fill: 'rgba(0, 0, 0, 0.5)' }} />
                    </div>
                    <div className={classes.labelWrapper}>
                      <div>Multiple Filings</div>
                    </div>
                  </div>
                </MenuItem>
              </Menu>
            </div>
          ) : null}
        </Toolbar>
      </AppBar>
    </>
  );
};

function getNextBatchFiling(filings: number[], selected: number) {
  const currentIndex = filings.indexOf(selected);
  const next = filings[(currentIndex + 1) % filings.length];

  return next;
}

function getPreviousBatchFiling(filings: number[], selected: number) {
  const currentIndex = filings.indexOf(selected);
  const previous =
    filings[(currentIndex + filings.length - 1) % filings.length];

  return previous;
}

const ChevronButtons = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
`;

export { Header };
