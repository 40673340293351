import React, { FC } from 'react';
import { Flex } from '@rebass/grid';
import { Link } from '@reach/router';
import { Location } from '../data/Location.Container';

import { ReactComponent as Close } from '../common/assets/Close.svg';
import styled from 'styled-components/macro';

interface EmailPreviewHeaderProps {
  subject: string;
}

export const EmailPreviewHeader: FC<EmailPreviewHeaderProps> = ({
  subject,
}) => {
  const { location } = Location.useContainer();

  return (
    <StyledNav
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex flexDirection="row">
        <Bold>Subject Line:</Bold>{' '}
        <TitleText>{formatSubject(subject)}</TitleText>
      </Flex>
      <StyledLink to={location.backButtonPath}>
        <Flex flexDirection="row" alignItems="center">
          <CloseText>Close</CloseText> <Close />
        </Flex>
      </StyledLink>
    </StyledNav>
  );
};

const formatSubject = (subject: string) => {
  if (
    subject.includes('This broker does not have an available email template')
  ) {
    return 'No Subject';
  }

  return subject;
};

const StyledNav = styled(Flex)`
  height: 60px;
  width: 100%;
  box-shadow: 0 2px 3px 0 #d9d9d9;
  padding: 0 45px 0 10px;
  position: sticky;
  top: 0;
`;

const Bold = styled.p`
  font-weight: 700;
  margin-right: 10px;
`;

const TitleText = styled.p`
  color: ${({ theme }) => theme.colors.black};
`;

const CloseText = styled.p`
  margin-right: 16px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
