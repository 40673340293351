import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import styled from 'styled-components/macro';

import { IFilingCreateValues } from '../../models/filing-form';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { Input } from '../Input';
import { Label } from '../Label';
import { InputGroupRow } from '../InputGroup';

const StyledInput = styled(Input)`
  vertical-align: middle;
  height: auto;
  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.divider};
  }
`;

export const RecordDateCompare: FC<{}> = () => {
  return (
    <Field
      name="recordDateCompare"
      render={({
        field,
        form,
      }: FieldProps<IFilingCreateValues | FilingDetailsFormValues>) => (
        <InputGroupRow>
          <Label>
            Record Date Compare
            <StyledInput
              ml={2}
              pt={1}
              type="checkbox"
              checked={field.value}
              {...field}
              onChange={() => {
                if (form.values.recordDateCompare) {
                  form.setFieldValue('recordDateCompare', false);
                } else {
                  form.setFieldValue('recordDateCompare', true);
                  form.setFieldValue('additionalRecordDates', new Array(''));
                }
              }}
            />
          </Label>
        </InputGroupRow>
      )}
    />
  );
};
