import { createSlice, Action } from '@reduxjs/toolkit';
import { Issuer, IssuerInvoicingAddress } from '../models/prospectus/issuer';
import {
  getIssuerById,
  updateIssuerById,
  updateProspectusById,
  deleteProspectus as deleteProspectusById,
  createProspectusDocument,
  getFunds,
} from './hubApiRoutes';
import { normalize } from 'normalizr';
import {
  issuerSchema,
  prospectusSchema,
  issuerListSchema,
} from './schemas/issuer';
import { Prospectus } from '../models/prospectus/prospectus';
import { ThunkAction } from 'redux-thunk';
import { AppState } from './store';
import { toast } from 'react-toastify';
import { userIdEvent } from '../utils/analytics-helpers';

const initialState = {
  isDocumentDialogOpen: false,
  isConfirmCancelDocumentDialogOpen: false,
  issuers: {} as { [key: string]: Issuer },
  prospectuses: {} as { [key: string]: Prospectus },
  all: {
    count: 0,
    next: null,
    previous: null,
    issuers: [] as string[],
    ordering: 'stats__prospectus_estimated_end_date',
  },
  missingDeliveryDocuments: {
    count: 0,
    next: null,
    previous: null,
    issuers: [] as string[],
    ordering: '-name',
  },
  search: '',
  isLoading: false,
  fundDetails: {
    isConfirmSaveDialogOpen: false,
    isLoading: false,
  },
};

export const issuersSlice = createSlice({
  name: 'funds',
  initialState,
  reducers: {
    createProspectusDocumentRequest: () => {},
    createProspectusDocumentFailure: () => {},
    createProspectusDocumentSueccess: () => {},
    fetchFundsAndEtfsRequest: (state) => {
      state.isLoading = true;
    },
    fetchFundsAndEtfsFailure: (state) => {
      state.isLoading = false;
    },
    setAllFunds: (state, action) => {
      state.isLoading = false;
      state.all.count = action.payload.count;
      state.all.next = action.payload.next;
      state.all.previous = action.payload.previous;
      state.all.issuers = action.payload.issuers.result;
      state.issuers = Object.assign(
        state.issuers,
        action.payload.issuers.entities.issuers,
      );
      state.prospectuses = Object.assign(
        state.prospectuses,
        action.payload.issuers.entities.prospectuses,
      );
    },
    setMissingDeliveryDocumentFunds: (state, action) => {
      state.isLoading = false;
      state.missingDeliveryDocuments.count = action.payload.count;
      state.missingDeliveryDocuments.next = action.payload.next;
      state.missingDeliveryDocuments.previous = action.payload.previous;
      state.missingDeliveryDocuments.issuers = action.payload.issuers.result;
      state.issuers = Object.assign(
        state.issuers,
        action.payload.issuers.entities.issuers,
      );
      state.prospectuses = Object.assign(
        state.prospectuses,
        action.payload.issuers.entities.prospectuses,
      );
    },
    setAllFundsOrdering: (state, action) => {
      state.all.ordering = action.payload;
    },
    setMissingDeliveryDocumentOrdering: (state, action) => {
      state.missingDeliveryDocuments.ordering = action.payload;
    },
    setFundsSearchTerm: (state, action) => {
      state.search = action.payload;
    },
    toggleDocumentDialog: (state) => {
      state.isDocumentDialogOpen = !state.isDocumentDialogOpen;
    },
    toggleConfirmCloseDocumentDialog: (state) => {
      state.isConfirmCancelDocumentDialogOpen = !state.isConfirmCancelDocumentDialogOpen;
    },
    toggleConfirmSaveIssuerDialog: (state) => {
      state.fundDetails.isConfirmSaveDialogOpen = !state.fundDetails
        .isConfirmSaveDialogOpen;
    },
    fetchIssuerRequest: (state) => {
      state.fundDetails.isLoading = true;
    },
    fetchIssuerFailure: (state) => {
      state.fundDetails.isLoading = false;
    },
    fetchIssuerSuccess: (state, action) => {
      state.fundDetails.isLoading = false;
      state.issuers = Object.assign(
        state.issuers,
        action.payload.entities.issuers,
      );
      state.prospectuses = Object.assign(
        state.prospectuses,
        action.payload.entities.prospectuses,
      );
    },
    updateIssuerSuccess: (state, action) => {
      state.issuers = Object.assign(
        state.issuers,
        action.payload.entities.issuers,
      );
      state.prospectuses = Object.assign(
        state.prospectuses,
        action.payload.entities.prospectuses,
      );
    },
    updateProspectusSuccess: (state, action) => {
      state.prospectuses = Object.assign(
        state.prospectuses,
        action.payload.entities.prospectuses,
      );
    },
    deleteProspectusSuccess: (state, action) => {
      delete state.prospectuses[action.payload.prospectusId];
      state.issuers[action.payload.issuerId].prospectuses[
        action.payload.status
      ] = state.issuers[action.payload.issuerId].prospectuses[
        action.payload.status
      ].filter((prospectus) => prospectus !== action.payload.prospectusId);
    },
    createProspectusSuccess: (state, action) => {
      state.prospectuses = Object.assign(
        state.prospectuses,
        action.payload.prospectus.entities.prospectuses,
      );
      state.issuers[action.payload.issuerId].prospectuses.upcoming.push(
        action.payload.prospectus.result,
      );
    },
  },
});

const { actions, reducer } = issuersSlice;

export const {
  fetchFundsAndEtfsRequest,
  fetchFundsAndEtfsFailure,
  setAllFunds,
  setMissingDeliveryDocumentFunds,
  setAllFundsOrdering,
  setMissingDeliveryDocumentOrdering,
  setFundsSearchTerm,
  toggleDocumentDialog,
  toggleConfirmCloseDocumentDialog,
  toggleConfirmSaveIssuerDialog,
  fetchIssuerRequest,
  fetchIssuerFailure,
  fetchIssuerSuccess,
  updateIssuerSuccess,
  updateProspectusSuccess,
  deleteProspectusSuccess,
  createProspectusSuccess,
} = actions;

export const fetchFundsAndEtfsFlow = ({
  search,
  ordering,
  prospectusStatus,
  limit,
  offset,
}: {
  search?: string;
  ordering?: string;
  limit?: string;
  offset?: string;
  prospectusStatus?: string;
}): ThunkAction<void, AppState, null, Action<string>> => {
  return (dispatch) => {
    dispatch(fetchFundsAndEtfsRequest());
    getFunds({
      search,
      ordering,
      limit,
      offset,
      prospectusStatus,
    })
      .then((response) => {
        const normalizedIssuers = normalize(
          response.data.results,
          issuerListSchema,
        );
        const { count, next, previous } = response.data;

        if (prospectusStatus === 'missing') {
          dispatch(
            setMissingDeliveryDocumentFunds({
              count,
              next,
              previous,
              issuers: normalizedIssuers,
            }),
          );
        } else {
          dispatch(
            setAllFunds({
              count,
              next,
              previous,
              issuers: normalizedIssuers,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(fetchFundsAndEtfsFailure());
        toast.error(JSON.stringify(error.response.data));
      });
  };
};

export const fetchIssuer = (
  id: string,
): ThunkAction<void, AppState, null, Action<string>> => {
  return (dispatch) => {
    dispatch(fetchIssuerRequest());
    return getIssuerById(id)
      .then((response) => {
        const normalizedIssuer = normalize(response.data, issuerSchema);
        dispatch(fetchIssuerSuccess(normalizedIssuer));
      })
      .catch((error) => {
        dispatch(fetchIssuerFailure());
        toast.error(JSON.stringify(error.response.data));
      });
  };
};

export const updateIssuer = (
  id: string,
  issuer: { name: string; fiscalYearEnd: string | null },
  invoicingAddress: IssuerInvoicingAddress,
): ThunkAction<void, AppState, null, Action<string>> => {
  return (dispatch) => {
    updateIssuerById(id, issuer, invoicingAddress)
      .then((response) => {
        const normalizedIssuer = normalize(response.data, issuerSchema);
        userIdEvent('Save Fund Clicked', {
          fundId: id,
          save: true,
        });
        dispatch(updateIssuerSuccess(normalizedIssuer));
        toast.success('Fund saved.');
      })
      .catch((error) => {
        userIdEvent('Save Fund Clicked', {
          fundId: id,
          save: false,
          validationError: error.response.data,
        });

        toast.error(JSON.stringify(error.response.data));
      });
  };
};

export const createProspectusFlow = (
  formData: FormData,
  issuerId: string,
): ThunkAction<void, AppState, null, Action<string>> => {
  return (dispatch) => {
    return createProspectusDocument(formData)
      .then((response) => {
        const normalizedProspectus = normalize(response.data, prospectusSchema);
        dispatch(
          createProspectusSuccess({
            prospectus: normalizedProspectus,
            issuerId,
          }),
        );
        dispatch(fetchIssuer(issuerId));
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data));
      });
  };
};

export const updateProspectus = (
  id: string,
  prospectus: {
    name: string;
    frontLink: string;
    website: string;
    securityIds: string[];
    effectiveEndDate: string;
    effectiveStartDate: string;
  },
): ThunkAction<void, AppState, null, Action<string>> => {
  return (dispatch) => {
    return updateProspectusById(id, prospectus)
      .then((response) => {
        const normalizedProspectus = normalize(response.data, prospectusSchema);
        dispatch(updateProspectusSuccess(normalizedProspectus));
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data));
      });
  };
};

export const deleteProspectus = (
  prospectusId: string,
  issuerId: string,
  status: 'active' | 'upcoming' | 'archived',
  documentType: string,
): ThunkAction<void, AppState, null, Action<string>> => {
  return (dispatch) => {
    return deleteProspectusById(prospectusId)
      .then(() => {
        dispatch(deleteProspectusSuccess({ prospectusId, issuerId, status }));
        userIdEvent('Delete Document Clicked', {
          fundId: prospectusId,
          documentType,
          save: true,
        });
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data));
        userIdEvent('Delete Document Clicked', {
          fundId: prospectusId,
          documentType,
          save: false,
        });
      });
  };
};

export default reducer;
