import React from 'react';
import { ProductIcon } from '../ProductIcon';
import { PageTitle } from '../PageTitle';

type PageHeaderProps = {
  product: Product;
};

type Product = 'ca' | 'px' | 'ps';

const PageHeader = ({ product }: PageHeaderProps) => {
  const renderTitleFromProduct = (product: Product) => {
    switch (product) {
      case 'ca':
        return 'Voluntary Corporate Actions';
      case 'ps':
        return 'Prospectus';
      case 'px':
        return 'Proxy';
    }
  };

  return (
    <div className="p-8 border-b border-light-gray flex items-center">
      <div id={product} className="mr-3">
        <ProductIcon product={product} />
      </div>
      <PageTitle>{renderTitleFromProduct(product)}</PageTitle>
    </div>
  );
};

export { PageHeader };
