import React, { useReducer } from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components/macro';
import { theme } from '../utils/theme';
import { getTokenFromCookies } from '../utils/get-token';

import jwt_decode from 'jwt-decode';

enum ArrowDirection {
  Up,
  Down,
}

type ActionType = {
  type: 'TOGGLE_FILINGS_LIST' | 'TOGGLE_ISSUERS_LIST';
};

interface State {
  isFilingsListOpen: boolean;
  isIssuersListOpen: boolean;
}

const NavigationContainer = styled.div`
  font-family: 'Franklin Gothic', 'Inter UI var alt', sans-serif;
  font-feature-settings: 'calt' 1, 'ss02' 1, 'tnum' 1;
  grid-area: nav;
  height: calc(100vh - 73px);
  overflow-y: auto;
  position: sticky;
  top: 73px;
`;

const ListItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  height: 55px;
  padding: 0 10px;
`;

const CollapsableListHeader = styled(ListItem)`
  &:hover {
    cursor: pointer;
  }
`;

const CollapsableList = styled.ul<{ isOpen: boolean }>`
  overflow: hidden;
  height: auto;
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0px')};
  transition: max-height 0.25s ease;
`;

const SubListItem = styled(ListItem)<{ highlightColor?: string }>`
  padding-left: 30px;
  position: relative;

  a {
    align-items: center;
    color: ${({ theme }) => theme.colors.black};
    display: flex;
    height: 100%;
    outline: none;
    width: 100%;
  }

  a.active {
    &:before {
      background: ${({ highlightColor, theme }) =>
        highlightColor ? highlightColor : theme.colors.gray};
      content: '';
      width: 10px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const Arrow = styled.div<{ direction: ArrowDirection }>`
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${({ theme }) => theme.colors.black};
  transform: ${({ direction }) => {
    switch (direction) {
      case ArrowDirection.Up:
        return 'rotate(0deg)';
      case ArrowDirection.Down:
        return 'rotate(180deg)';
      default:
        return 'rotate(0)';
    }
  }};
  transition: transform 0.25s linear;
`;

const NavLink = (props: any) => (
  <Link
    {...props}
    getProps={({ isPartiallyCurrent }) => {
      return isPartiallyCurrent ? { className: 'active' } : null;
    }}
  />
);

const initialState: State = {
  isFilingsListOpen: true,
  isIssuersListOpen: true,
};

const reducer = (state: State, action: ActionType) => {
  switch (action.type) {
    case 'TOGGLE_FILINGS_LIST':
      return {
        ...state,
        isFilingsListOpen: !state.isFilingsListOpen,
      };
    case 'TOGGLE_ISSUERS_LIST':
      return {
        ...state,
        isIssuersListOpen: !state.isIssuersListOpen,
      };
    default:
      throw new Error();
  }
};

export const Navigation = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const token = getTokenFromCookies();
  const decoded = jwt_decode(token) as any;
  const hasPermissions =
    decoded &&
    decoded.permissions &&
    decoded.permissions.find(
      (per: string) => per === 'admin_can_view_meeting_results',
    );

  return (
    <NavigationContainer data-testid="site-navigation">
      <ul>
        <CollapsableListHeader
          onClick={() => dispatch({ type: 'TOGGLE_FILINGS_LIST' })}
        >
          <span>Filings</span>
          <Arrow
            direction={
              state.isFilingsListOpen ? ArrowDirection.Up : ArrowDirection.Down
            }
          />
        </CollapsableListHeader>
        <CollapsableList isOpen={state.isFilingsListOpen}>
          <SubListItem highlightColor={theme.colors.green}>
            <NavLink to="/old/filings/proxy">Proxy</NavLink>
          </SubListItem>
          <SubListItem highlightColor={theme.colors.gold}>
            <NavLink to="/old/filings/corporate-actions">
              Corporate Actions
            </NavLink>
          </SubListItem>
          <SubListItem>
            <NavLink to="/old/filings/all">All Filings</NavLink>
          </SubListItem>
          <SubListItem>
            <NavLink to="/prospectuses">Prospectuses (New MC)</NavLink>
          </SubListItem>
        </CollapsableList>

        <CollapsableListHeader
          onClick={() => dispatch({ type: 'TOGGLE_ISSUERS_LIST' })}
        >
          <span>Issuers</span>
          <Arrow
            direction={
              state.isIssuersListOpen ? ArrowDirection.Up : ArrowDirection.Down
            }
          />
        </CollapsableListHeader>
        <CollapsableList isOpen={state.isIssuersListOpen}>
          <SubListItem highlightColor={theme.colors.blue}>
            <NavLink to="/old/issuers/funds">Funds</NavLink>
          </SubListItem>

          <SubListItem highlightColor={theme.colors.blue}>
            <NavLink to="/old/issuers/companies">Companies</NavLink>
          </SubListItem>
        </CollapsableList>
        {hasPermissions && (
          <SubListItem highlightColor={theme.colors.orange}>
            <NavLink to="/old/results">Results</NavLink>
          </SubListItem>
        )}
      </ul>
    </NavigationContainer>
  );
};
