import { configureStore, combineReducers } from '@reduxjs/toolkit';
import fundsReducer from './funds';
import companiesReducer from './companies';

const reducer = combineReducers({
  funds: fundsReducer,
  companies: companiesReducer,
});

const store = configureStore({
  reducer,
});

export type AppState = ReturnType<typeof reducer>;
export default store;
