import React from 'react';
import addDays from 'date-fns/addDays';
import isWithin from 'date-fns/isWithinInterval';

interface Props {
  announcementDate: string;
  filingType: string | null;
  link: string | null;
  date: string | null;
  cik: string | null;
}

function CorporateActionEdgarLink({
  link,
  filingType,
  announcementDate,
  date,
  cik,
}: Props) {
  const thirtyDaysFromAnnouncementDate = addDays(
    new Date(announcementDate),
    30,
  );
  const withinThirtyDays = date
    ? isWithin(new Date(date), {
        start: new Date(announcementDate),
        end: thirtyDaysFromAnnouncementDate,
      })
    : false;
  const calculateLink = link
    ? link
    : cik
    ? `https://www.sec.gov/cgi-bin/browse-edgar?CIK=${cik}`
    : 'https://www.sec.gov/edgar/searchedgar/companysearch.html';
  const calculateFilingType = () => {
    if (filingType && filingTypeCodes.includes(filingType)) {
      return filingType;
    } else {
      return 'Other';
    }
  };

  return withinThirtyDays ? (
    <a
      className="text-blue no-underline hover:underline hover:text-blue"
      href={calculateLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      {calculateFilingType()}
    </a>
  ) : null;
}

const filingTypeCodes = [
  'SC TO-T',
  'SC TO-T/A',
  'SC TO-I',
  'SC TO-C',
  'SC 14D-9',
  'SC 14D9/A',
  'SC 14D9C',
  'F-1/A (?)',
  'SC 13E3/A',
  '425',
  '6-K',
  '8-K',
  '10-Q',
];

export { CorporateActionEdgarLink };
