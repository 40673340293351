import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { IFilingCreateValues } from '../../models/filing-form';
import { Asterisk } from '../Asterik/Asterik';
import { Label } from '../Label';
import { Select } from '../Select';
import { InputGroup } from '../InputGroup';

interface MeetingTypeFieldProps {
  disabled?: boolean;
}

export const MeetingTypeField: FC<MeetingTypeFieldProps> = ({ disabled }) => {
  return (
    <Field
      name="meetingType"
      render={({ field }: FieldProps<IFilingCreateValues>) => (
        <InputGroup>
          <Label htmlFor="meeting-type">
            Meeting Type <Asterisk />
          </Label>
          <Select id="meeting-type" {...field} disabled={disabled}>
            <option value="annual">Annual</option>
            <option value="special">Special</option>
          </Select>
        </InputGroup>
      )}
    />
  );
};
