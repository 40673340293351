import React, { FC, MouseEvent } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { Location } from '../../data/Location.Container';

interface TableRowProps {
  to?: string;
  variant: string;
}

const TableRow: FC<TableRowProps> = ({ children, to, variant }) => {
  const { setBackButtonPath } = Location.useContainer();

  return (
    <Tr
      onClick={(event: MouseEvent<HTMLTableRowElement>) => {
        setBackButtonPath(window.location.pathname);

        if (event.ctrlKey || event.metaKey) {
          window.open(`${window.location.origin}/${to}`);
        } else {
          navigate(`/${to}`);
        }
      }}
      variant={variant}
    >
      {children}
    </Tr>
  );
};

export default TableRow;

const Tr = styled.tr<{ variant: string }>`
  cursor: pointer;

  &:hover {
    box-shadow: inset 5px 0 1px
      ${({ theme, variant }) => {
        switch (variant) {
          case 'gold':
            return theme.colors.gold;
          case 'blue':
            return theme.colors.blue;
          case 'green':
            return theme.colors.green;
          case 'gray':
            return theme.colors.back;
          case 'orange':
            return theme.colors.orange;
          default:
            return theme.colors.black;
        }
      }};
  }

  &:nth-child(even) {
    td {
      background: ${({ theme, variant }) => {
        switch (variant) {
          case 'gold':
            return transparentize(0.9, theme.colors.gold);
          case 'blue':
            return transparentize(0.9, theme.colors.blue);
          case 'green':
            return transparentize(0.9, theme.colors.green);
          case 'gray':
            return transparentize(0.9, theme.colors.back);
          case 'orange':
            return transparentize(0.9, theme.colors.orange);
          default:
            return transparentize(0.9, theme.colors.black);
        }
      }};
    }
  }
`;
