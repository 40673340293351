import React, { FC, useEffect, useContext, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { depascalize } from 'humps';
import { RouteComponentProps, Router, Redirect, navigate } from '@reach/router';
import { toast } from 'react-toastify';

import { ReactComponent as ArrowLeft } from '../common/assets/arrow-left.svg';
import { PlainButton } from '../common/Buttons/PlainButton';
import { Text } from '../common/Text';
import { CusipTypeahead } from '../common/FormComponents/CusipTypeahead';
import { RecordDate } from '../common/FormComponents/RecordDate';
import { MeetingDate } from '../common/FormComponents/MeetingDate';
import { MeetingTypeField } from '../common/FormComponents/MeetingType';
import { DeliveryMethodField } from '../common/FormComponents/DeliveryMethod';
import {
  Formik,
  Form as FormikForm,
  FormikProps,
  FormikActions,
  Field,
  FieldProps,
} from 'formik';
import styled from 'styled-components/macro';
import { Flex, Box } from '@rebass/grid';
import { Tab } from '../common/Tab';
import { Mailings } from './Mailings/Mailings';
import { MeetingDetails } from './MeetingDetails';
import { ContactDetails } from './ContactDetails';
import { ReactComponent as MailIcon } from '../common/assets/mail.svg';
import { ReactComponent as VoteIcon } from '../common/assets/check-circle.svg';
import { ReactComponent as CalendarIcon } from '../common/assets/calendar.svg';
import { ReactComponent as ActionIcon } from '../common/assets/play.svg';
import { ReactComponent as UserIcon } from '../common/assets/user.svg';
import { FilingContext } from '../data/Filing.Context';
import { MeetingType, DeliveryMethod } from '../models/filing';
import { ICusip } from '../models/vote-proposal';
import { TimeZone } from '../models/time-zone';
import { ReportType } from '../models/filing-form';
import { FilingType } from '../models/filing-type';
import { privateApi } from '../utils/api-adapter';
import { PlainDangerButton } from '../common/Buttons/PlainDangerButton';
import { DeleteFilingWarningDialog } from './DeleteFilingWarningDialog';
import { MarkReadyForReviewDialog } from './MarkReadyForReviewDialog';
import { BrokerChangesDialog } from './BrokerChangesDialog';
import {
  OperationsStatus,
  CorporateActionApproval,
} from '../models/filing-details';
import { Tabs } from '../common/Tabs';
import { ReportTypeField, CorporateActionType } from '../common/FormComponents';
import { Loading } from '../common/Loading/Loading';
import { formatCamelCaseString } from '../utils/format-camel-case-string';
import {
  ICompanyContact,
  ISolicitor,
  ITabulator,
} from '../models/intermediaries';
import { ErrorLabel } from '../common/ErrorLabel';
import { ActionDetails } from './ActionDetails';
import { VoteContainer } from './Vote/VoteContainer';
import { CorporateActionType as ActionType } from '../models/corporate-action-type';
import { GenericDate } from '../common/FormComponents/GenericDate';
import { Location } from '../data/Location.Container';
import { OutlineButton } from '../common/Buttons/OutlineButton';
import { BrokerTypeahead } from '../common/FormComponents/BrokerTypeahead';
import { AllFilingsLayout } from '../Filings/AllFilings/AllFilingsLayout';
import { RecordDateCompare } from '../common/FormComponents/RecordDateCompare';
import { AdditionalRecordDates } from '../common/FormComponents/AdditionalRecordDates';
import { EdgarDateLink } from '../common/EdgarDateLink';
import { userIdEvent } from '../utils/analytics-helpers';

const Form = styled(FormikForm)`
  width: 100%;
`;

const FilingDetailsHeaderFields = styled.div<{ ca?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing[3]}px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  padding: ${({ theme }) => theme.spacing[3]}px;
  padding-bottom: ${({ theme }) => theme.spacing[4]}px;
  border-left: 6px solid
    ${({ theme, ca }) => (ca ? theme.colors.gold : theme.colors.green)};
`;

const ErrorDot = styled.div`
  background: ${({ theme }) => theme.colors.red};
  width: 5px;
  height: 5px;
  vertical-align: middle;
  border-radius: 2.5px;
  margin: 0 0 0 10px;
`;

const StyledTabs = styled(Tabs)`
  border: none;
  box-shadow: 0 2px 3px 0 #c9c6c1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin-top: 16px;
`;

const StyledBox = styled(Box)<{ ca?: boolean }>`
  border-left: 6px solid
    ${({ theme, ca }) => (ca ? theme.colors.gold : theme.colors.green)};
`;

const StyledOutlineButton = styled(OutlineButton)`
  background: transparent;
`;

export interface FilingDetailsFormValues {
  actionDetail: string | null;
  actionType: ActionType | '';
  brokerSearchReceivedDate: Date | null;
  contraCusip: string;
  cusips: ICusip[];
  brokers?: { id: number; name: string; slug: string }[];
  deliveryMethod: DeliveryMethod;
  digitalMaterialsReceivedDate: Date | null;
  digitalSendDeadline: string | null;
  dtcExpirationDate: string | null;
  electionCutoffDate?: string | null;
  electionCutoffTime?: string | null;
  expectedDate: Date | null;
  expirationDate?: string | null;
  fundOwner?: string;
  informationAgent: string;
  informationAgentAddress: string;
  informationAgentEmail: string;
  informationAgentPhone: string;
  invoicerAddress?: string;
  invoicerAddressLine1?: string;
  invoicerAddressLine2?: string;
  invoicerAddressLine3?: string;
  invoicerCity?: string;
  invoicerState?: string;
  invoicerPostalCode?: string;
  invoicerCountry?: string;
  invoicerContactEmail?: string;
  meetingAddress: string | null;
  meetingDate: Date | null;
  meetingTime: string | null;
  meetingTimezone: TimeZone | null;
  meetingType: MeetingType;
  notes?: string;
  notificationReceivedDate: string | null;
  offerer: string;
  offererTerms: string;
  onlineMeetingSelected: boolean;
  paperMaterialsReceivedDate: Date | null;
  paperSendDeadline: string | null;
  payoutCurrency: string;
  physicalMeetingSelected: boolean;
  recordDate: string | null;
  recordDateEnd: string | null;
  recordDateStart: string | null;
  recordDateCompare: boolean;
  additionalRecordDates?: string[];
  replyTo?: ICompanyContact[];
  reportType?: ReportType;
  sendTo?: ICompanyContact[];
  solicitor?: ISolicitor;
  solicitorJobNumber?: string;
  tabulator?: ITabulator;
  type: FilingType;
  virtualMeetingUrl: string | null;
  voteCutoffDate: Date | null;
  voteCutoffTime: string | null;
  voteCutoffTimezone: TimeZone | null;
}

type Broker = {
  id: number;
  name: string;
  slug: string;
};

interface FilingProps extends RouteComponentProps {
  id?: string;
}

export const Filing: FC<FilingProps> = ({ id }) => {
  const {
    deleteFiling,
    fetchFiling,
    filing,
    isLoading,
    resetFiling,
    updateFilingOnSuccess,
  } = useContext(FilingContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [additionalRecordDatesError, setAdditionalRecordDatesError] = useState<
    string
  >('');

  const { location } = Location.useContainer();

  const [
    isMarkReadyForReviewDialogOpen,
    setMarkReadyForReviewDialogOpen,
  ] = useState<boolean>(false);

  const [
    isDeleteFilingWarningDialogOpen,
    setIsDeleteFilingWarningDialogOpen,
  ] = useState<boolean>(false);

  const [isBrokerChangesDialogOpen, toggleBrokerChangesDialog] = useState<
    boolean
  >(false);

  const [brokerDialogText, setBrokerDialogText] = useState<string>('');
  const [brokerDialogButtonText, setBrokerDialogButtonText] = useState<string>(
    '',
  );

  const [badRequest, setBadRequest] = useState<string>('');

  const showRecordDateEnd = useMemo(() => {
    if (filing && filing.recordDateEnd && filing.recordDateStart) {
      return filing.recordDateStart !== filing.recordDateEnd;
    } else {
      return false;
    }
  }, [filing]);

  const checkIfBrokersAreDifferent = (
    newBrokerList: CorporateActionApproval[] | undefined,
    exisitingBrokerList: CorporateActionApproval[] | undefined,
  ): boolean => {
    if (
      (!newBrokerList || newBrokerList.length === 0) &&
      (!exisitingBrokerList || exisitingBrokerList.length === 0)
    ) {
      return false;
    } else if (
      newBrokerList &&
      newBrokerList.length > 0 &&
      (!exisitingBrokerList || exisitingBrokerList.length === 0)
    ) {
      setBrokerDialogText(
        `Are you sure you want to add ${
          newBrokerList.length > 1 ? 'these brokers' : 'this broker'
        }?`,
      );
      setBrokerDialogButtonText(
        `Add ${newBrokerList.length > 1 ? 'brokers' : 'broker'}`,
      );
      return true;
    } else if (newBrokerList && exisitingBrokerList) {
      if (newBrokerList.length > exisitingBrokerList.length) {
        setBrokerDialogText(
          `Are you sure you want to add ${
            newBrokerList.length - exisitingBrokerList.length > 1
              ? 'these brokers'
              : 'this broker'
          }?`,
        );
        setBrokerDialogButtonText(
          `Add ${
            newBrokerList.length - exisitingBrokerList.length > 1
              ? 'brokers'
              : 'broker'
          }`,
        );
        return true;
      } else if (newBrokerList.length === exisitingBrokerList.length) {
        const combined = [...newBrokerList, ...exisitingBrokerList];
        let hasChanged = false;
        const counts: any = {};
        combined.map((broker) =>
          counts[broker.broker]
            ? (counts[broker.broker] = 1)
            : (counts[broker.broker] = counts[broker.broker] + 1),
        );
        for (let i = 0; i < Object.keys(counts).length; i++) {
          if (counts[Object.keys(counts)[i]] === 1) {
            hasChanged = true;
            break;
          }
        }
        return hasChanged;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (id) {
      fetchFiling(id);
    }

    return () => {
      resetFiling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleFormSubmit = (
    values: FilingDetailsFormValues,
    actions: FormikActions<FilingDetailsFormValues>,
  ) => {
    if (values.recordDateCompare && values.additionalRecordDates) {
      const everyAddRecordDate = values.additionalRecordDates.every(
        (recordDate: string) => Boolean(recordDate),
      );
      if (!everyAddRecordDate) {
        setAdditionalRecordDatesError(
          'All additional record dates must be filled in.',
        );
        actions.setSubmitting(false);
        return;
      }
    }

    if (filing) {
      const hasDistributableDocuments: boolean =
        filing.documents.filter(
          (document) => document.shouldDistributeInNextMailing === true,
        ).length > 0;
      const isActiveCorporateActionFiling =
        filing.type === FilingType.CorporateAction &&
        filing.operationsStatus === OperationsStatus.Active;
      const isMaterialsRequestedProxyFiling =
        filing.type !== FilingType.CorporateAction &&
        filing.operationsStatus === OperationsStatus.MaterialsRequested;
      const isActiveProxyFiling =
        filing.type !== FilingType.CorporateAction &&
        filing.operationsStatus === OperationsStatus.Active;
      const isOpenCorporateActionFiling =
        filing.type === FilingType.CorporateAction &&
        filing.operationsStatus !== OperationsStatus.Closed;

      const brokerValuesToCorporateActionApprovals: CorporateActionApproval[] = values.brokers
        ? values.brokers.map((broker) => {
            return {
              id: 0,
              broker: broker.id,
              corporateAction: 0,
            };
          })
        : ([] as CorporateActionApproval[]);

      const areBrokersDifferent = checkIfBrokersAreDifferent(
        brokerValuesToCorporateActionApprovals,
        filing.corporateActionApprovals,
      );

      if (isOpenCorporateActionFiling && areBrokersDifferent) {
        toggleBrokerChangesDialog(true);
      }

      if (
        isActiveCorporateActionFiling ||
        ((isMaterialsRequestedProxyFiling || isActiveProxyFiling) &&
          hasDistributableDocuments)
      ) {
        setMarkReadyForReviewDialogOpen(true);
      } else {
        patchFiling(values);
      }
    }
  };

  const patchFiling = (
    values: FilingDetailsFormValues,
    readyForReview?: boolean,
  ) => {
    setIsSaving(true);
    if (filing) {
      const {
        solicitor,
        tabulator,
        replyTo,
        sendTo,
        cusips,
        electionCutoffDate,
        electionCutoffTime,
        meetingDate,
        meetingTime,
        meetingTimezone,
        voteCutoffDate,
        voteCutoffTime,
        voteCutoffTimezone,
        meetingType,
        meetingAddress,
        virtualMeetingUrl,
        expectedDate,
        reportType,
        fundOwner,
        actionDetail,
        actionType,
        contraCusip,
        dtcExpirationDate,
        expirationDate,
        informationAgent,
        informationAgentAddress,
        informationAgentEmail,
        informationAgentPhone,
        notificationReceivedDate,
        offerer,
        offererTerms,
        payoutCurrency,
        recordDateEnd,
        recordDateStart,
        brokers,
        additionalRecordDates,
        recordDateCompare,
        onlineMeetingSelected,
        physicalMeetingSelected,
        ...theRest
      } = values;

      const meeting = {
        date: meetingDate,
        time: meetingTime,
        timezone: meetingTimezone,
        type: meetingType,
        address: physicalMeetingSelected ? meetingAddress : '',
        virtualMeetingUrl: onlineMeetingSelected ? virtualMeetingUrl : '',
      };

      const securityIds = cusips.map((cusip) => cusip.id);

      let brokerIds: { broker: number }[] = [] as { broker: number }[];

      if (brokers) {
        brokerIds = brokers.map((broker: Broker) => {
          return { broker: broker.id };
        });
      }

      let payload: any = {
        ...theRest,
        digitalMaterialsExpectedDate: expectedDate,
        companyContactIds: [],
        issuerId: filing.issuer.id,
        securityIds,
      };

      if (solicitor) {
        payload.solicitorId = solicitor.id;
      }

      if (replyTo) {
        const ids = replyTo.map((contact) => contact.id);
        payload.companyContactIds = [...payload.companyContactIds, ...ids];
      }

      if (
        filing.type === 'FirmMeeting' ||
        filing.type === 'FundMeeting' ||
        filing.type === 'FirmConsentSolicitation' ||
        filing.type === 'MeetingContest'
      ) {
        if (tabulator) {
          payload.tabulatorId = tabulator.id;
        }
        if (sendTo) {
          const ids = sendTo.map((contact) => contact.id);
          payload.companyContactIds = [...payload.companyContactIds, ...ids];
        }
      }

      if (recordDateCompare) {
        payload.additionalRecordDates = additionalRecordDates;
      } else if (!recordDateCompare && filing.additionalRecordDates) {
        payload.additionalRecordDates = [];
      }

      switch (filing && filing.type) {
        case 'MeetingContest':
        case 'FundMeeting':
        case 'FirmMeeting':
          payload = {
            ...payload,
            voteCutoffTime,
            voteCutoffDate,
            voteCutoffTimezone,
            meeting,
          };
          break;
        case 'FirmConsentSolicitation':
          payload = {
            ...payload,
            voteCutoffTime,
            voteCutoffDate,
            voteCutoffTimezone,
            meeting,
          };
          break;
        case 'FundInformationStatement':
          payload = {
            ...payload,
            fundOwner,
          };
          break;
        case 'FundReport':
          payload = {
            ...payload,
            fundOwner,
            reportType,
          };
          break;
        case 'CorporateAction':
          payload = {
            actionDetail,
            actionType,
            contraCusip,
            dtcExpirationDate,
            electionCutoffDate,
            electionCutoffTime,
            expirationDate,
            informationAgent,
            informationAgentAddress,
            informationAgentEmail,
            informationAgentPhone,
            invoicerAddress: values.invoicerAddress,
            invoicerContactEmail: values.invoicerContactEmail,
            issuerId: filing.issuer.id,
            notes: values.notes,
            notificationReceivedDate,
            offerer,
            offererTerms,
            payoutCurrency,
            recordDateEnd: showRecordDateEnd ? recordDateEnd : recordDateStart,
            recordDateStart,
            securityIds,
            corporateActionApprovals: brokerIds,
            type: filing.type,
          };
          break;
        default:
          break;
      }

      const response = privateApi.patch(`/filings/${filing.id}/`, payload);
      response
        .then((res) => {
          setIsSaving(false);
          updateFilingOnSuccess(res.data);
          if (readyForReview) {
            markReadyForReview();
          }
          setBadRequest('');
          toast.success('Filing saved!');
          userIdEvent('Save Filing Clicked', {
            filingId: id,
            operationsStatus: filing.operationsStatus,
            filingType: filing.type,
            save: true,
          });
        })
        .catch((error) => {
          setIsSaving(false);

          if (error.response) {
            const err = Object.keys(error.response.data).reduce(
              (acc: string, cv: string) => {
                acc = `${acc} ${depascalize(cv).split('_').join(' ')}: ${
                  error.response.data[cv]
                }.`;
                return acc;
              },
              '',
            );
            setBadRequest(err);
            userIdEvent('Save Filing Clicked', {
              filingId: id,
              operationsStatus: filing.operationsStatus,
              filingType: filing.type,
              save: false,
            });
          }
        });
    }
  };

  const markReadyForReview = async () => {
    if (id) {
      try {
        setIsSaving(true);
        const response = await privateApi.patch(
          `/filings/${id}/update-status/`,
          {
            operationsStatus: OperationsStatus.ReadyForReview,
          },
        );
        setIsSaving(false);
        if (response.status) fetchFiling(id);
      } catch (error) {
        setIsSaving(false);
        if (filing) {
          userIdEvent(
            'Mark Ready for Review Unsuccessful',
            {
              operationsStatus: filing.operationsStatus,
              filingId: filing.id,
              filingType: filing.type,
              validationError: error.response.data.error,
            },
            true,
          );
        }
        if (error.response) {
          toast.error(error.response.data.error);
        }
      }
    }
  };

  return (
    <AllFilingsLayout>
      <Box bg="background" color="black" px={3}>
        {isLoading && <Loading />}

        {filing && filing.issuer && (
          <Box width={1}>
            <StyledBox
              bg="white"
              p={3}
              ca={filing.type === FilingType.CorporateAction}
            >
              <PlainButton
                display="flex"
                alignItems="center"
                onClick={() => {
                  navigate(location.backButtonPath);
                }}
              >
                <ArrowLeft /> Back
              </PlainButton>

              <Flex justifyContent="space-between">
                <div>
                  <Text as="h1" fontSize={3} fontWeight={4} mt={4}>
                    {filing.issuer.companyName}
                  </Text>

                  {filing.edgarFilings &&
                    filing.edgarFilings[0] &&
                    filing.edgarFilings[0].filingDate &&
                    filing.issuer.edgarLandingPage && (
                      <Text as="p" fontSize={2} fontWeight={4} mt={3}>
                        Edgar date:{' '}
                        <EdgarDateLink
                          date={filing.edgarFilings[0].filingDate}
                          operationStatus={filing.operationsStatus}
                          edgarLandingPage={filing.issuer.edgarLandingPage}
                        />
                      </Text>
                    )}
                </div>
                <Text as="h1" fontSize={3} fontWeight={4} mt={0}>
                  {formatCamelCaseString(filing.type)}
                </Text>
              </Flex>
            </StyledBox>

            <Flex>
              <Formik
                initialValues={{
                  brokerSearchReceivedDate:
                    filing.brokerSearchReceivedDate || null,
                  actionDetail: filing.actionDetail || '',
                  actionType: filing.actionType || '',
                  contraCusip: filing.contraCusip || '',
                  dtcExpirationDate: filing.dtcExpirationDate || null,
                  electionCutoffDate: filing.electionCutoffDate || null,
                  electionCutoffTime: filing.electionCutoffTime || null,
                  expirationDate: filing.expirationDate || null,
                  informationAgent: filing.informationAgent || '',
                  informationAgentAddress: filing.informationAgentAddress || '',
                  informationAgentEmail: filing.informationAgentEmail || '',
                  informationAgentPhone: filing.informationAgentPhone || '',
                  notificationReceivedDate:
                    filing.notificationReceivedDate || null,
                  offerer: filing.offerer || '',
                  offererTerms: filing.offererTerms || '',
                  payoutCurrency: filing.payoutCurrency || '',
                  recordDateEnd: filing.recordDateEnd || null,
                  recordDateStart: filing.recordDateStart || null,
                  recordDateCompare: Boolean(
                    filing.additionalRecordDates &&
                      filing.additionalRecordDates.length > 0,
                  ),
                  additionalRecordDates: filing.additionalRecordDates,
                  cusips:
                    filing.securities.map((security) => {
                      return {
                        id: security.id,
                        cusip: security.cusip,
                        name: security.name,
                      };
                    }) || [],
                  brokers:
                    (filing.corporateActionApprovals &&
                      filing.corporateActionApprovals.map((caApproval) => {
                        return {
                          id: caApproval.broker,
                          name: '',
                          slug: '',
                        };
                      })) ||
                    [],
                  deliveryMethod:
                    filing.deliveryMethod || DeliveryMethod.NoticeAndAccess,
                  digitalMaterialsReceivedDate:
                    filing.digitalMaterialsReceivedDate || null,
                  expectedDate: filing.digitalMaterialsExpectedDate || null,
                  digitalSendDeadline: filing.stats.digitalSendDeadline || '',
                  paperSendDeadline: filing.stats.paperSendDeadline || '',
                  meetingAddress:
                    (filing.meeting && filing.meeting.address) || '',
                  meetingDate: (filing.meeting && filing.meeting.date) || null,
                  meetingTime: (filing.meeting && filing.meeting.time) || null,
                  meetingTimezone:
                    (filing.meeting && filing.meeting.timezone) || null,
                  meetingIsPhysical:
                    (filing.meeting && filing.meeting.isPhysical) || true,
                  meetingType:
                    (filing.meeting && filing.meeting.type) ||
                    MeetingType.Annual,
                  notes: filing.notes || '',
                  paperMaterialsReceivedDate:
                    filing.paperMaterialsReceivedDate || null,
                  recordDate: filing.recordDate || null,
                  replyTo: filing.companyContacts.filter(
                    (companyContact) =>
                      companyContact.role === 'solicitor_reply_to',
                  ),
                  sendTo: filing.companyContacts.filter(
                    (companyContact) =>
                      companyContact.role === 'tabulator_reply_to',
                  ),
                  solicitor: filing.solicitor || undefined,
                  solicitorJobNumber: filing.solicitorJobNumber || undefined,
                  tabulator: filing.tabulator || undefined,
                  type: filing.type || ('' as FilingType),
                  virtualMeetingUrl:
                    (filing.meeting && filing.meeting.virtualMeetingUrl) ||
                    null,
                  voteCutoffDate: filing.voteCutoffDate || null,
                  voteCutoffTime: filing.voteCutoffTime || null,
                  voteCutoffTimezone: filing.voteCutoffTimezone || null,
                  invoicerContactEmail: filing.invoicerContactEmail || '',
                  reportType: filing.reportType
                    ? filing.reportType
                    : ('annual' as ReportType),
                  fundOwner: filing.fundOwner || '',
                  invoicerAddress: filing.invoicerAddress || '',
                  invoicerAddressLine1: filing.invoicerAddressLine1 || '',
                  invoicerAddressLine2: filing.invoicerAddressLine2 || '',
                  invoicerAddressLine3: filing.invoicerAddressLine3 || '',
                  invoicerCity: filing.invoicerCity || '',
                  invoicerState: filing.invoicerState || '',
                  invoicerPostalCode: filing.invoicerPostalCode || '',
                  invoicerCountry: filing.invoicerCountry || '',
                  onlineMeetingSelected:
                    (filing.meeting &&
                      Boolean(filing.meeting.virtualMeetingUrl)) ||
                    false,
                  physicalMeetingSelected:
                    (filing.meeting && Boolean(filing.meeting.address)) ||
                    false,
                }}
                validationSchema={() => {
                  return Yup.object().shape({
                    cusips: Yup.string().required(
                      'At least one cusip is required',
                    ),
                    recordDate: Yup.string().when('type', {
                      is: (value) =>
                        value === 'FirmConsentSolicitation' ||
                        value === 'FirmInformationStatement' ||
                        value === 'FirmMeeting' ||
                        value === 'FundInformationStatement' ||
                        value === 'FundMeeting' ||
                        value === 'FundReport' ||
                        value === 'MeetingContest',
                      then: Yup.string().required(
                        'Record Date cannot be blank',
                      ),
                      otherwise: Yup.string().nullable(),
                    }),
                    brokerSearchReceivedDate: Yup.string().when('type', {
                      is: (value) =>
                        value === 'FirmConsentSolicitation' ||
                        value === 'FirmInformationStatement' ||
                        value === 'FirmMeeting' ||
                        value === 'FundInformationStatement' ||
                        value === 'FundMeeting' ||
                        value === 'FundReport' ||
                        value === 'MeetingContest',
                      then: Yup.string().required(
                        'Search Date cannot be blank',
                      ),
                      otherwise: Yup.string().nullable(),
                    }),
                    fundOwner: Yup.string().when('type', {
                      is: (val) =>
                        val === 'FundReport' ||
                        val === 'FundInformationStatement',
                      then: Yup.string().required(),
                      otherwise: Yup.string().nullable(),
                    }),
                    solicitor: Yup.object().when('type', {
                      is: (value) =>
                        value === 'FirmConsentSolicitation' ||
                        value === 'FirmInformationStatement' ||
                        value === 'FirmMeeting' ||
                        value === 'FundInformationStatement' ||
                        value === 'FundMeeting' ||
                        value === 'FundReport' ||
                        value === 'MeetingContest',
                      then: Yup.object().required(
                        'You must include a solicitor',
                      ),
                      otherwise: Yup.object().nullable(),
                    }),
                    replyTo: Yup.array().when('type', {
                      is: (value) =>
                        value === 'FirmConsentSolicitation' ||
                        value === 'FirmInformationStatement' ||
                        value === 'FirmMeeting' ||
                        value === 'FundInformationStatement' ||
                        value === 'FundMeeting' ||
                        value === 'FundReport' ||
                        value === 'MeetingContest',
                      then: Yup.array().of(Yup.object()).min(1),
                      otherwise: Yup.array().of(Yup.object()).min(0),
                    }),
                    electionCutoffDate: Yup.string().when('type', {
                      is: 'CorporateAction',
                      then: Yup.string()
                        .nullable()
                        .required('Election Cutoff Date is required.'),
                      otherwise: Yup.string().nullable(),
                    }),
                    electionCutoffTime: Yup.string().when('type', {
                      is: 'CorporateAction',
                      then: Yup.string()
                        .nullable()
                        .required('Election Cutoff Time is required.'),
                      otherwise: Yup.string().nullable(),
                    }),
                    informationAgentEmail: Yup.string().when('type', {
                      is: 'CorporateAction',
                      then: Yup.string().email(
                        'Must be a valid email address.',
                      ),
                      otherwise: Yup.string().nullable(),
                    }),
                  });
                }}
                enableReinitialize={true}
                onSubmit={handleFormSubmit}
                render={(formikBag: FormikProps<FilingDetailsFormValues>) => {
                  return (
                    <Form>
                      <FilingDetailsHeaderFields
                        ca={
                          formikBag.values.type === FilingType.CorporateAction
                        }
                      >
                        {badRequest !== '' && (
                          <ErrorLabel>{badRequest}</ErrorLabel>
                        )}

                        {/* cusip */}
                        <CusipTypeahead
                          hideAddNewCusip={true}
                          isUpdate={true}
                        />

                        {filing.type === FilingType.CorporateAction ? (
                          <>
                            <Field
                              name="recordDateStart"
                              render={({
                                field,
                                form,
                              }: FieldProps<FilingDetailsFormValues>) => (
                                <GenericDate
                                  field={field}
                                  form={form}
                                  label="Record Date"
                                  fieldName={field.name}
                                />
                              )}
                            />

                            {showRecordDateEnd && (
                              <Field
                                name="recordDateEnd"
                                render={({
                                  field,
                                  form,
                                }: FieldProps<FilingDetailsFormValues>) => (
                                  <GenericDate
                                    field={field}
                                    form={form}
                                    label="Record End Date"
                                    fieldName={field.name}
                                  />
                                )}
                              />
                            )}
                          </>
                        ) : (
                          <Flex flexDirection="column">
                            <RecordDate />
                            <RecordDateCompare />
                            {formikBag.values.recordDateCompare && (
                              <AdditionalRecordDates
                                error={additionalRecordDatesError}
                                additionalRecordDates={
                                  formikBag.values.additionalRecordDates
                                }
                              />
                            )}
                          </Flex>
                        )}

                        {(filing.type === FilingType.MeetingContest ||
                          filing.type === FilingType.FundMeeting ||
                          filing.type === FilingType.FirmMeeting) && (
                          <MeetingDate />
                        )}
                        {filing.type === FilingType.CorporateAction && (
                          <Field
                            name="electionCutoffDate"
                            render={({
                              field,
                              form,
                            }: FieldProps<FilingDetailsFormValues>) => (
                              <GenericDate
                                field={field}
                                form={form}
                                label="Election Cutoff Date"
                                fieldName={field.name}
                              />
                            )}
                          />
                        )}
                        {filing.type === FilingType.CorporateAction && (
                          <CorporateActionType />
                        )}

                        {(filing.type === FilingType.FirmMeeting ||
                          filing.type === FilingType.MeetingContest ||
                          filing.type === FilingType.FundMeeting) && (
                          <MeetingTypeField />
                        )}

                        {filing.type === FilingType.FundReport && (
                          <ReportTypeField />
                        )}

                        {(filing.type === FilingType.FirmInformationStatement ||
                          filing.type === FilingType.FirmMeeting ||
                          filing.type === FilingType.FirmConsentSolicitation ||
                          filing.type === FilingType.MeetingContest ||
                          filing.type === FilingType.FundMeeting) && (
                          <DeliveryMethodField />
                        )}

                        {filing.type === FilingType.CorporateAction && (
                          <BrokerTypeahead />
                        )}
                      </FilingDetailsHeaderFields>

                      <StyledTabs>
                        <Tab link="mailings">
                          <Flex alignItems="center" justifyContent="center">
                            <MailIcon />
                            <Text ml={2}>Mailings</Text>
                            {formikBag.errors.brokerSearchReceivedDate && (
                              <ErrorDot />
                            )}
                          </Flex>
                        </Tab>

                        {filing.type === FilingType.CorporateAction && (
                          <Tab link="action-details">
                            <Flex alignItems="center" justifyContent="center">
                              <ActionIcon />
                              <Text ml={2}>Action Details</Text>
                            </Flex>
                          </Tab>
                        )}

                        {(filing.type === FilingType.FirmConsentSolicitation ||
                          filing.type === FilingType.FirmMeeting ||
                          filing.type === FilingType.CorporateAction ||
                          filing.type === FilingType.FundMeeting) && (
                          <Tab link="vote">
                            <Flex alignItems="center" justifyContent="center">
                              <VoteIcon />
                              <Text ml={2}>Vote</Text>
                            </Flex>
                          </Tab>
                        )}

                        {(filing.type === FilingType.FundMeeting ||
                          filing.type === FilingType.FirmMeeting ||
                          filing.type === FilingType.FirmConsentSolicitation ||
                          filing.type === FilingType.MeetingContest) && (
                          <Tab link="meeting-details">
                            <Flex alignItems="center" justifyContent="center">
                              <CalendarIcon />
                              <Text ml={2}>Meeting Details</Text>
                            </Flex>
                          </Tab>
                        )}

                        <Tab link="contact-details">
                          <Flex alignItems="center" justifyContent="center">
                            <UserIcon />
                            <Text ml={2}>Contact Details</Text>
                            {(formikBag.errors.fundOwner ||
                              formikBag.errors.solicitor ||
                              formikBag.errors.replyTo) && <ErrorDot />}
                          </Flex>
                        </Tab>
                      </StyledTabs>

                      <Box>
                        <Router primary={false}>
                          <Redirect
                            from=":id"
                            to="/old/filings/:id/materials"
                            noThrow={true}
                          />
                          <Mailings path="mailings" />
                          <VoteContainer path="vote" type={filing.type} />
                          <ActionDetails path="action-details" />
                          <MeetingDetails path="meeting-details" />
                          <ContactDetails
                            path="contact-details"
                            formikBag={formikBag}
                          />
                        </Router>
                      </Box>

                      <Flex flexDirection="row-reverse" py={3}>
                        <StyledOutlineButton
                          type="submit"
                          mx={3}
                          disabled={isSaving}
                        >
                          {isSaving ? 'Saving...' : 'Save'}
                        </StyledOutlineButton>

                        <PlainDangerButton
                          type="button"
                          disabled={
                            filing.operationsStatus ===
                            OperationsStatus.Approved
                          }
                          mx={3}
                          onClick={(event) => {
                            event.preventDefault();
                            setIsDeleteFilingWarningDialogOpen(true);
                          }}
                        >
                          Delete Filing
                        </PlainDangerButton>
                      </Flex>

                      <MarkReadyForReviewDialog
                        isOpen={isMarkReadyForReviewDialogOpen}
                        onMarkReadyForReviewAndSave={() => {
                          patchFiling(formikBag.values, true);
                          setMarkReadyForReviewDialogOpen(false);
                        }}
                        onRequestClose={() =>
                          setMarkReadyForReviewDialogOpen(false)
                        }
                        onSaveOnly={() => {
                          patchFiling(formikBag.values);

                          setMarkReadyForReviewDialogOpen(false);
                        }}
                      />

                      <BrokerChangesDialog
                        isOpen={isBrokerChangesDialogOpen}
                        onRequestClose={() => {
                          isMarkReadyForReviewDialogOpen &&
                            setMarkReadyForReviewDialogOpen(false);
                          toggleBrokerChangesDialog(false);
                          formikBag.setSubmitting(false);
                        }}
                        onContinueSave={() => {
                          !isMarkReadyForReviewDialogOpen &&
                            patchFiling(formikBag.values);
                          toggleBrokerChangesDialog(false);
                        }}
                        text={brokerDialogText}
                        buttonText={brokerDialogButtonText}
                      />
                    </Form>
                  );
                }}
              />
            </Flex>
          </Box>
        )}
      </Box>

      <DeleteFilingWarningDialog
        isOpen={isDeleteFilingWarningDialogOpen}
        onConfirmDelete={() => {
          if (id) {
            deleteFiling(id, location.backButtonPath);
          }
        }}
        onRequestClose={() => setIsDeleteFilingWarningDialogOpen(false)}
      />
    </AllFilingsLayout>
  );
};
