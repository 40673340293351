import { makeStyles } from '@material-ui/core/styles';

const useTableStyles = makeStyles((theme) => ({
  table: () => ({
    borderCollapse: 'separate',
    borderSpacing: '0px 10px',
  }),
  tableBodyRow: {
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow:
        '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    },
  },
  tableHeadCell: () => ({
    fontSize: '12px',
    fontWeight: 'bold',
  }),
  tableBodyCell: () => ({
    padding: 'auto',
    maxWidth: '100px',
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:last-of-type': {
      borderRight: `1px solid ${theme.palette.divider}`,
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
    },
    '& > a': {
      color: '#1853b4 !important',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
  tableBodyCellCheckbox: () => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    padding: `0 0 0 4px`,
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
  }),
}));

export { useTableStyles };
