import React from 'react';
import { Link, LinkGetProps } from '@reach/router';

type StatusTabsProps = {
  counts: {
    active: number;
    upcoming: number;
    archived: number;
  };
};

function StatusTabs({
  counts: { active, upcoming, archived },
}: StatusTabsProps) {
  return (
    <div className="mt-4 px-8 py-4 bg-white border-b border-very-light-gray">
      <Tab to="">Active ({active})</Tab>
      <Tab to="upcoming">Upcoming ({upcoming})</Tab>
      <Tab to="archived">Archived ({archived})</Tab>
    </div>
  );
}

type TabProps = {
  to: string;
  children: React.ReactNode;
};

function Tab({ to, children }: TabProps) {
  const isActive = ({ isCurrent }: LinkGetProps) => {
    return isCurrent
      ? {
          className:
            'font-medium text-primary-text mr-8 last:mr-0 hover:no-underline hover:text-green border-b-2 border-green py-4',
        }
      : {
          className:
            'text-primary-text mr-8 last:mr-0 hover:no-underline hover:text-green border-b-2 border-transparent py-4',
        };
  };

  return (
    <Link to={to} getProps={isActive}>
      {children}
    </Link>
  );
}

export { StatusTabs };
