import React from 'react';
import Dropzone from 'react-dropzone';
import {
  FaFile,
  FaTrash,
  FaSave,
  FaEdit,
  FaEye,
  FaArrowLeft,
} from 'react-icons/fa';
import { Label } from '../../components/Label';
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
import { documentTypeOptions } from './select-options';
import { DocumentType } from './types';
import { Tooltip } from '../../components/Tooltip';

type CreatePageDocumentsProps = {
  documents: { file: File; name: string; type: DocumentType }[];
  onAddDocuments: (files: File[]) => void;
  onChangeName: (index: number, name: string) => void;
  onChangeType: (index: number, type: DocumentType) => void;
  onDeleteDocument: (index: number) => void;
};

function CreatePageDocuments({
  documents,
  onAddDocuments,
  onChangeName,
  onChangeType,
  onDeleteDocument,
}: CreatePageDocumentsProps) {
  const [previewingDocumentIndex, setPreviewingDocumentIndex] = React.useState<
    number | null
  >(null);

  return previewingDocumentIndex !== null ? (
    <DocumentPreview
      previewingDocumentIndex={previewingDocumentIndex}
      documents={documents}
      onExit={() => setPreviewingDocumentIndex(null)}
      onSelectDocument={(index) => setPreviewingDocumentIndex(index)}
    />
  ) : (
    <React.Fragment>
      <div className="py-4 px-8 block w-full border-b border-light-gray">
        <h2 className="text-secondary-text font-medium text-xs uppercase">
          Documents
        </h2>
      </div>
      <div>
        {documents.length
          ? documents.map((document, index) => (
              <Document
                key={index}
                document={document}
                onDelete={() => onDeleteDocument(index)}
                onChangeName={(name) => onChangeName(index, name)}
                onChangeType={(type: DocumentType) => onChangeType(index, type)}
                onPreview={() => setPreviewingDocumentIndex(index)}
              />
            ))
          : null}

        <Dropzone onDrop={onAddDocuments}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={`flex items-center justify-center border border-dashed ${
                  isDragActive ? 'border-black' : 'border-gray  '
                }`}
                style={{ height: '29rem' }}
              >
                <input {...getInputProps({ accept: 'application/pdf' })} />

                <p>
                  {isDragActive
                    ? 'Drag or browse documents to complete filing'
                    : 'Drop documents here'}
                </p>
              </div>
            );
          }}
        </Dropzone>
      </div>
    </React.Fragment>
  );
}

type DocumentPreviewProps = {
  documents: {
    file: File;
    name: string;
    type: DocumentType;
  }[];
  previewingDocumentIndex: number;
  onExit: () => void;
  onSelectDocument: (index: number) => void;
};

function DocumentPreview({
  documents,
  previewingDocumentIndex,
  onExit,
  onSelectDocument,
}: DocumentPreviewProps) {
  return (
    <div>
      <div className="px-4 py-2 grid grid-cols-2 col-gap-8">
        <button className="text-green justify-self-start" onClick={onExit}>
          <FaArrowLeft />
        </button>
        <div className="justify-self-end">
          <Select
            options={documents.map((document, index) => ({
              label: document.name,
              value: String(index),
            }))}
            onChange={(event) => {
              onSelectDocument(event.currentTarget.selectedIndex);
            }}
            value={String(previewingDocumentIndex)}
            name="documentPreview"
          />
        </div>
      </div>
      <embed
        src={URL.createObjectURL(documents[previewingDocumentIndex].file)}
        className="h-screen w-full"
        key={URL.createObjectURL(documents[previewingDocumentIndex].file)}
      />
    </div>
  );
}

type DocumentProps = {
  document: { file: File; name: string; type: DocumentType };
  onDelete: () => void;
  onChangeType: (type: DocumentType) => void;
  onChangeName: (name: string) => void;
  onPreview: () => void;
};

function Document({
  document,
  onDelete,
  onChangeName,
  onChangeType,
  onPreview,
}: DocumentProps) {
  const [isEditing, setIsEditing] = React.useState(true);

  return isEditing ? (
    <div
      id="EditedDocContainer"
      className="border-t border-light-gray first:border-t-0"
    >
      <div id="EditedDocName" className="flex items-center w-full p-4">
        <FaFile className="mr-2" />
        <span>{document.file.name}</span>
        <div className="ml-auto">
          <Tooltip id="trash" />
          <button
            data-for="trash"
            data-tip="Delete document"
            onClick={onDelete}
            className="text-gray hover:text-red transition-colors duration-300 mr-2"
          >
            <FaTrash />
          </button>
          <Tooltip id="disk" />
          <button
            onClick={() => setIsEditing(false)}
            className="text-gray hover:text-green transition-colors duration-300"
            data-for="disk"
            data-tip="Save document"
          >
            <FaSave />
          </button>
        </div>
      </div>

      <div className="grid gap-4 grid-cols-2 p-4 border-t border-light-gray">
        <div className="flex flex-col">
          <Label>Doc Type</Label>
          <Select
            options={documentTypeOptions}
            onChange={(event) => {
              onChangeType(event.currentTarget.value as DocumentType);
            }}
            value={document.type}
            name="docType"
          />
        </div>

        <div className="flex flex-col">
          <Label>Doc Name</Label>
          <Input
            type="text"
            value={document.name}
            onChange={(event) => onChangeName(event.currentTarget.value)}
            name="docName"
          />
        </div>
      </div>
    </div>
  ) : (
    <div
      id="UploadedDocContainer"
      className="flex items-center justify-between p-4 border-t border-light-gray first:border-t-0 bg-very-light-gray"
    >
      <div id="UploadedDocName" className="flex items-center">
        <FaFile className="mr-2" />
        <span>{document.name}</span>
      </div>

      <div className="flex items-center">
        <Tooltip id="eye" />
        <button
          type="button"
          className="text-gray hover:text-primary-text transition-colors duration-300"
          data-for="eye"
          data-tip="View document"
        >
          <FaEye className="mr-2" onClick={onPreview} />
        </button>
        <Tooltip id="disk" />
        <button
          className="text-gray hover:text-primary-text transition-colors duration-300"
          data-for="disk"
          data-tip="Save document"
          onClick={() => setIsEditing(true)}
        >
          <FaEdit className="mr-2" />
        </button>
        <Tooltip id="trash" />
        <button
          className="text-gray hover:text-red transition-colors duration-300"
          data-for="trash"
          data-tip="Delete document"
          onClick={onDelete}
        >
          <FaTrash />
        </button>
      </div>
    </div>
  );
}

export { CreatePageDocuments };
