import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import {
  IVoteProposalFormValues,
  ProposalTypes,
  RoutineTypes,
  VoteType,
  RecommendationTypes,
} from '../../models/vote-proposal';
import { InputGroup } from '../InputGroup';
import { Label } from '../Label';
import { Asterisk } from '../Asterik/Asterik';
import { Select } from '../Select';
import { formatVoteProposalType } from '../../utils/format-vote-proposal-type';

interface IProps {
  disabled: boolean;
}

export const ProposalType: FC<IProps> = ({ disabled }) => {
  const proposalTypes = Object.values(ProposalTypes);

  return (
    <Field
      name="proposalType"
      render={({ field, form }: FieldProps<IVoteProposalFormValues>) => {
        const setDefaults = (proposalType: ProposalTypes) => {
          switch (proposalType) {
            case 'BoardOfDirectorsNomination':
              form.setFieldValue('routine', 'Non-Routine' as RoutineTypes);
              form.setFieldValue('voteType', 'For, Withhold' as VoteType);
              form.setFieldValue(
                'recommendation',
                'For' as RecommendationTypes,
              );
              form.setFieldValue('proposalTitle', 'Election of Directors');
              form.setFieldValue('proposalType', proposalType);
              break;
            case 'ManagementProposalItem':
              form.setFieldValue('routine', 'Non-Routine' as RoutineTypes);
              form.setFieldValue(
                'voteType',
                'For, Against, Abstain' as VoteType,
              );
              form.setFieldValue(
                'recommendation',
                'For' as RecommendationTypes,
              );
              form.setFieldValue('proposalType', proposalType);
              form.setFieldValue('proposalTitle', '');
              break;
            case 'ExecutiveCompensationProposalItem':
              form.setFieldValue('routine', 'Non-Routine' as RoutineTypes);
              form.setFieldValue(
                'voteType',
                'For, Against, Abstain' as VoteType,
              );
              form.setFieldValue(
                'recommendation',
                'For' as RecommendationTypes,
              );
              form.setFieldValue('proposalTitle', 'Executive Compensation');
              form.setFieldValue('proposalType', proposalType);
              break;
            case 'AdjournmentProposalItem':
              form.setFieldValue('routine', 'Non-Routine' as RoutineTypes);
              form.setFieldValue(
                'voteType',
                'For, Against, Abstain' as VoteType,
              );
              form.setFieldValue(
                'recommendation',
                'For' as RecommendationTypes,
              );
              form.setFieldValue('proposalTitle', 'Adjournment of Meeting');
              form.setFieldValue('proposalType', proposalType);
              break;
            case 'PlanOfMergerProposalItem':
              form.setFieldValue('routine', 'Non-Routine' as RoutineTypes);
              form.setFieldValue(
                'voteType',
                'For, Against, Abstain' as VoteType,
              );
              form.setFieldValue(
                'recommendation',
                'For' as RecommendationTypes,
              );
              form.setFieldValue('proposalTitle', 'Plan of Merger');
              form.setFieldValue('proposalType', proposalType);
              break;
            case 'ShareholderProposalItem':
              form.setFieldValue('routine', 'Non-Routine' as RoutineTypes);
              form.setFieldValue(
                'voteType',
                'For, Against, Abstain' as VoteType,
              );
              form.setFieldValue(
                'recommendation',
                'Against' as RecommendationTypes,
              );
              form.setFieldValue('proposalType', proposalType);
              form.setFieldValue('proposalTitle', '');
              break;
            case 'RatificationOfAuditors':
              form.setFieldValue('proposalTitle', 'Ratification of Auditors');
              form.setFieldValue('proposalType', proposalType);
              form.setFieldValue('routine', 'Routine' as RoutineTypes);
              form.setFieldValue(
                'voteType',
                'For, Against, Abstain' as VoteType,
              );
              form.setFieldValue(
                'recommendation',
                'For' as RecommendationTypes,
              );

              break;
            case 'SayOnPay':
              form.setFieldValue('proposalTitle', 'Say on Pay');
              form.setFieldValue('proposalType', proposalType);
              form.setFieldValue('routine', 'Non-Routine' as RoutineTypes);
              form.setFieldValue(
                'voteType',
                'One year, Two years, Three years, Abstain' as VoteType,
              );
              form.setFieldValue(
                'recommendation',
                'None' as RecommendationTypes,
              );
              break;
            default:
              form.setFieldValue('proposalType', proposalType);
              form.setFieldValue('proposalTitle', '');
              return;
          }
        };

        return (
          <InputGroup>
            <Label htmlFor="proposalType">
              Proposal Type <Asterisk />
            </Label>
            <Select
              id="proposalType"
              onChange={(e) => {
                const { value } = e.target;
                setDefaults(value as ProposalTypes);
              }}
              value={field.value}
              disabled={disabled}
            >
              {proposalTypes.map((proposalType) => (
                <option key={proposalType} value={proposalType}>
                  {formatVoteProposalType(proposalType)}
                </option>
              ))}
            </Select>
          </InputGroup>
        );
      }}
    />
  );
};
