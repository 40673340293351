import React from 'react';
import { hubApi } from '../../old/data/hubApiConfig';
import { format } from 'date-fns';
import axios from 'axios';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';

type Prospectus = {
  id: string;
  type: ProspectusType;
  name: string;
  frontLink: string;
  website: string;
  attachment: string;
  securities: string[];
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  description: string;
};

type ProspectusType =
  | 'summary'
  | 'sticker'
  | 'statement_of_additional_information'
  | 'statutory'
  | 'other';

type UpdateDateProps = {
  onCancel: () => void;
  onSuccess: () => void;
  prospectuses: Prospectus[];
};

const UpdateDate = ({ onCancel, onSuccess, prospectuses }: UpdateDateProps) => {
  const [expectedDate, setExpectedDate] = React.useState(new Date());
  const isDateInFuture =
    expectedDate && new Date().getTime() < expectedDate.getTime();

  const handleChangeExpectedDate = (date: Date | null) => {
    if (date) {
      setExpectedDate(date);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const allSummaryAndStatutoryProspectuses = prospectuses.filter(
      (prospectus) =>
        prospectus.type === 'summary' || prospectus.type === 'statutory',
    );

    const promises = allSummaryAndStatutoryProspectuses.map((prospectus) =>
      hubApi.patch(`/securities/prospectuses/${prospectus.id}/`, {
        name: prospectus.name,
        type: prospectus.type,
        frontLink: prospectus.frontLink,
        website: prospectus.website,
        securityIds: prospectus.securities,
        effectiveStartDate: prospectus.effectiveStartDate,
        effectiveEndDate: format(expectedDate, 'yyyy-MM-dd'),
      }),
    );

    axios
      .all(promises)
      .then(onSuccess)
      .catch((error) => {
        if (error.response.data) {
          return toast.error(error.response.data.nonFieldErrors.join('\n'), {
            autoClose: false,
          });
        }

        return toast.error(
          'An unknown error occurred. Please ensure your selections are correct.',
          { autoClose: false },
        );
      });
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div
        className="p-8 border border-t-0 border-light-gray"
        style={{ justifySelf: 'start' }}
      >
        <DatePicker
          name="effectiveStartDate"
          className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
          required={true}
          onChange={handleChangeExpectedDate}
          selected={expectedDate}
        />
      </div>

      <div className="flex flex-col items-center mt-8">
        <button
          className="bg-green text-primary-text text-sm font-medium h-10 w-40 rounded-full disabled:text-secondary-text disabled:bg-light-gray disabled:cursor-not-allowed"
          disabled={!isDateInFuture}
          type="submit"
        >
          Update
        </button>
        <button
          className="text-secondary-text text-sm mt-4"
          onClick={onCancel}
          type="button"
        >
          Go Back
        </button>
      </div>
    </form>
  );
};

export { UpdateDate };
