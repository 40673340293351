import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

function useCanLogIn(): boolean {
  const jwt: string | undefined = Cookies.get('authToken');

  if (jwt) {
    const decodedJWT: { groups: string[] } = jwtDecode(jwt);
    const canLogIn: boolean =
      decodedJWT.groups.includes('proxy_admins') &&
      decodedJWT.groups.includes('hub_admin');

    return canLogIn;
  }

  return false;
}

export { useCanLogIn };
