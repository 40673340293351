import React from 'react';
import { useBrokers } from '../useBrokers';
import { Thumbnail } from './Thumbnail';
import styled from 'styled-components';

interface Props {
  filingId: string;
  onClick: (slug: string) => void;
  selectedBroker: string;
}

function ThumbnailList({ filingId, onClick, selectedBroker }: Props) {
  const brokers = useBrokers();

  if (brokers.data) {
    return (
      <Wrapper>
        {brokers.data.data.results.map((broker) => (
          <Thumbnail
            key={broker.id}
            logo={
              broker.theme ? (broker.theme.logo ? broker.theme.logo : '') : ''
            }
            name={broker.name}
            slug={broker.slug}
            filingId={filingId}
            onClick={onClick}
            selected={selectedBroker === broker.slug}
          />
        ))}
      </Wrapper>
    );
  }

  return null;
}

const Wrapper = styled.div`
  background: #fff;
  grid-area: thumbnailList;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  overflow: scroll;
`;

export { ThumbnailList };
