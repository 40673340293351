import React, { FC, useState } from 'react';
import { Flex } from '@rebass/grid';
import styled from 'styled-components/macro';

const Wrapper = styled(Flex)`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Tag = styled(Flex)`
  align-items: center;
  background: #595959;
  border-radius: 3px;
  font-size: 12px;
  min-height: 20px;
  height: auto;
  margin-right: 5px;
  margin-top: 5px;
  padding: 3px 10px;
  text-transform: capitalize;
  width: min-content;
`;

const ShowButton = styled.button`
  background: transparent;
  border: none;
  color: #8f949e;
  font-size: 12px;
  font-weight: 600;
  padding: 0;
  text-align: left;
  transition: color 0.25s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
  }
`;

interface BrokerCellProps {
  brokers: { id: number; name: string; slug: string }[];
}

export const BrokerCell: FC<BrokerCellProps> = ({ brokers }) => {
  const numberOfBrokers = brokers.length;
  const firstBroker: string = brokers.length > 0 ? brokers[0].slug : '';
  const remainingBrokerLenth = brokers.slice(1).length;
  const [brokerOverflowShown, toggleBrokerOverflow] = useState(false);

  return (
    <Wrapper>
      {brokerOverflowShown ? (
        <>
          <ul>
            {brokers.length > 0 &&
              brokers.map((broker) => (
                <li key={broker.slug}>
                  <Tag>{broker.slug}</Tag>
                </li>
              ))}
          </ul>
          <ShowButton
            onClick={(event) => {
              event.preventDefault();
              toggleBrokerOverflow(false);
            }}
          >
            Show Less
          </ShowButton>
        </>
      ) : (
        <>
          <Tag>{firstBroker}</Tag>
          {numberOfBrokers > 1 && (
            <ShowButton
              onClick={(event) => {
                event.preventDefault();
                toggleBrokerOverflow(true);
              }}
            >
              Show More ({remainingBrokerLenth})
            </ShowButton>
          )}
        </>
      )}
    </Wrapper>
  );
};
