import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Security } from '../Prospectus';
import { SecurityRow } from '../Prospectus/SecurityRow';
import { ProspectusStatus } from '../Prospectus';
import { hubApi } from '../../../old/data/hubApiConfig';
import { format } from 'date-fns';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Tooltip } from '../../../components/Tooltip';
import DatePicker from 'react-datepicker';
import { ReactComponent as UploadIcon } from '../../../components/Icons/upload.svg';
import { ReactComponent as CalendarIcon } from '../../../components/Icons/calendar.svg';
import { UploadView } from './UploadView';
import { mutate } from 'swr';

type EditableProspectusListProps = {
  securities: Security[];
  status: ProspectusStatus;
};

type View = 'default' | 'uploading' | 'editing date';

const EditableProspectusList = ({
  securities,
  status,
  id,
}: EditableProspectusListProps & RouteComponentProps<{ id: string }>) => {
  const [selectedSecurities, setSelectedSecurities] = React.useState(new Set());
  const [view, setView] = React.useState<View>('default');
  const [showingCusips, setShowingCusips] = React.useState(true);
  const [expectedDate, setExpectedDate] = React.useState(new Date());
  const validSecurities = securities.filter(
    (security) => security.prospectuses[status].length,
  );
  const areAllChecked = React.useMemo(
    () => Array.from(selectedSecurities).length === validSecurities.length,
    [selectedSecurities, validSecurities],
  );

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      const allSecurityIds = validSecurities.map((security) => security.id);
      setSelectedSecurities(new Set(allSecurityIds));
    } else {
      setSelectedSecurities(new Set());
    }
  };

  const handleSecuritySelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    if (event.currentTarget.checked) {
      setSelectedSecurities(new Set(selectedSecurities.add(id)));
    } else {
      const copySet = new Set(selectedSecurities);
      copySet.delete(id);
      if (copySet.size === 0) {
        setView('default');
      }
      setSelectedSecurities(copySet);
    }
  };

  const handleUpdateExpectedDate = () => {
    const allSummaryAndStatutoryProspectuses = getSecuritiesById(
      [...selectedSecurities] as string[],
      validSecurities,
    )
      .map((security) => security.prospectuses[status])
      .reduce((acc, curr) => [...acc, ...curr])
      .filter(
        (prospectus) =>
          prospectus.type === 'summary' || prospectus.type === 'statutory',
      );

    const promises = allSummaryAndStatutoryProspectuses.map((prospectus) =>
      hubApi.patch(`/securities/prospectuses/${prospectus.id}/`, {
        name: prospectus.name,
        type: prospectus.type,
        frontLink: prospectus.frontLink,
        website: prospectus.website,
        securityIds: prospectus.securities,
        effectiveStartDate: prospectus.effectiveStartDate,
        effectiveEndDate: format(expectedDate, 'yyyy-MM-dd'),
      }),
    );

    axios
      .all(promises)
      .then(() => {
        mutate(`/admin/issuers/${id}/prospectus/`);
        setView('default');
      })
      .catch((error) => {
        if (error.response.data) {
          return toast.error(error.response.data.nonFieldErrors.join('\n'), {
            autoClose: false,
          });
        }

        return toast.error(
          'An unknown error occurred. Please ensure your selections are correct.',
          { autoClose: false },
        );
      });
  };

  const handleChangeExpectedDate = (date: Date | null) => {
    if (date) {
      setExpectedDate(date);
    }
  };

  const handleCancelUpload = () => setView('default');

  const handleCreateProspectusSuccess = () => {
    mutate(`/admin/issuers/${id}/prospectus/`);
    setView('default');
    toast.success('Your prospectus documents were successfully created.');
  };

  const handleUpdateSuccess = () => mutate(`/admin/issuers/${id}/prospectus/`);

  return validSecurities.length ? (
    <div>
      <div className="flex items-center">
        <div
          className={`border border-primary-text rounded h-8 px-2 flex items-center ${
            view !== 'default'
              ? 'border-gray bg-very-light-gray text-secondary-text'
              : 'border-primary-text bg-white text-primary-text'
          }`}
        >
          <input
            type="checkbox"
            id="select-all"
            onChange={handleSelectAll}
            checked={areAllChecked}
            disabled={view !== 'default'}
            className="appearance-none border border-gray h-4 w-4 bg-white checkbox focus:outline-none hover:border-primary-text cursor-pointer"
          />
          <label htmlFor="select-all" className="ml-2 font-medium text-sm">
            Select all
          </label>
        </div>
        <Tooltip id="upload" />
        <button
          data-for="upload"
          data-tip="Upload document"
          className={`border rounded h-8 px-2 bg-white ml-2 focus:outline-none
                  ${
                    selectedSecurities.size === 0
                      ? 'border-gray bg-very-light-gray text-secondary-text'
                      : ''
                  }{" "}
                    ${
                      view !== 'uploading'
                        ? 'border-primary-text bg-white text-primary-text'
                        : 'border-green bg-white text-green'
                    }
                  `}
          onClick={() =>
            view === 'uploading' ? setView('default') : setView('uploading')
          }
          disabled={selectedSecurities.size === 0}
        >
          <UploadIcon />
        </button>
        <Tooltip id="expected-date" />
        <div
          data-tip="Edit expected date"
          data-for="expected-date"
          className={`border rounded h-8 px-2 bg-white ml-2 flex items-center
                    ${
                      selectedSecurities.size === 0
                        ? 'border-gray bg-very-light-gray text-secondary-text'
                        : ''
                    }{" "} 
                    ${
                      view !== 'editing date'
                        ? 'border-primary-text bg-white text-primary-text'
                        : 'border-green bg-white text-primary-text'
                    }
                `}
        >
          <CalendarIcon className="mr-2" />
          <DatePicker
            className="bg-transparent focus:outline-none w-20"
            selected={expectedDate}
            onFocus={() => setView('editing date')}
            onChange={handleChangeExpectedDate}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && expectedDate) {
                handleUpdateExpectedDate();
              }
            }}
            disabled={selectedSecurities.size === 0}
          />
        </div>

        {view === 'editing date' && (
          <div>
            <button
              className="text-green font-sm ml-4"
              onClick={() => handleUpdateExpectedDate()}
              disabled={!expectedDate}
            >
              Save
            </button>
          </div>
        )}
      </div>

      {(view === 'editing date' || view === 'uploading') && (
        <div className="rounded-lg border border-gray mt-4 overflow-hidden">
          <div className="flex items-center px-5 py-4 bg-very-light-gray">
            <span>Cusip(s):</span>
            {[...selectedSecurities].map((security) => (
              <div
                key={security as string}
                className="border border-secondary-text text-secondary-text rounded text-xs px-1 ml-2"
              >
                {getCusipById(security as string, validSecurities)}
              </div>
            ))}
          </div>

          {view === 'uploading' && (
            <UploadView
              onCancel={handleCancelUpload}
              securities={[...selectedSecurities] as string[]}
              onSuccess={handleCreateProspectusSuccess}
            />
          )}
        </div>
      )}

      {view === 'uploading' && (
        <button
          className="my-8 mx-auto font-medium text-primary-text text-xs border border-primary-text rounded h-8 w-32 block"
          onClick={() => setShowingCusips(!showingCusips)}
        >
          {showingCusips ? 'Hide cusips' : 'Show cusips'}
        </button>
      )}

      {showingCusips && (
        <div>
          {securities
            .filter((security) => security.prospectuses[status].length > 0)
            .map((security) => (
              <SecurityRow
                key={security.id}
                security={security}
                status={status}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleSecuritySelect(event, security.id)
                }
                isChecked={selectedSecurities.has(security.id)}
                onUpdateSuccess={handleUpdateSuccess}
              />
            ))}
        </div>
      )}
    </div>
  ) : null;
};

const getCusipById = (id: string, securities: Security[]) => {
  const security = securities.find((security) => security.id === id);

  return security && security.cusip;
};

const getSecuritiesById = (ids: string[], securities: Security[]) => {
  const filteredSecurities = securities.filter(
    (item) => ids.indexOf(item.id) !== -1,
  );

  return filteredSecurities;
};

export { EditableProspectusList };
