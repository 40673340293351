import React from 'react';
import Dropzone from 'react-dropzone';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik, FormikProps, Field, FormikActions } from 'formik';
import { toast } from 'react-toastify';
import { DocumentType } from '../types';
import { documentTypeOptions } from '../select-options';
import {
  privateApi,
  privateApiAsFormData,
} from '../../../old/utils/api-adapter';
import { useProxy } from '../useProxy';
import { documentValidationSchema } from '../validation-schema';

import {
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@material-ui/core/';

import PublishIcon from '@material-ui/icons/Publish';
import { DocumentCusipTypeahead } from '../../../components/typeaheads/DocumentCusipTypeahead';
import { DocumentSnackbar } from './DocumentSnackbar';

const useStyles = makeStyles(() =>
  createStyles({
    dropzone: {
      display: 'flex',
      height: '29rem',
      alignItems: 'center',
      justifyContent: 'center',
    },
    activeDropzone: {
      display: 'flex',
      height: '29rem',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(17, 204, 153, 0.08)',
    },
    rootDialog: {
      position: 'absolute',
    },
    backdrop: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,.8)',
    },
    dialogContent: {
      '& > *': {
        margin: '12px 0',
      },
    },
  }),
);

export type DocumentFormValues = {
  document: null | File;
  documentTitle: string;
  documentType: DocumentType;
  securities: {
    id: number;
    cusip: string;
    name: string;
  }[];
};

type UploadProps = {
  filingId: string;
};

export const DocumentUpload = ({ filingId }: UploadProps) => {
  const classes = useStyles();
  const { data, mutate } = useProxy(filingId);
  const [isDocumentUploading, updateIsDocumentUploading] = React.useState<
    boolean
  >(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState<boolean>(false);

  const [open, setOpen] = React.useState(false);

  const createNewDocument = async (
    values: DocumentFormValues,
    actions: FormikActions<DocumentFormValues>,
  ) => {
    try {
      updateIsDocumentUploading(true);
      const formData = new FormData();
      formData.append(
        'file',
        values.document as File,
        (values.document as File).name,
      );
      const attachmentPayload = {
        filing: data && data.data.id,
        securities: values.securities.map((security) => security.id),
        name: values.documentTitle,
        type: values.documentType,
      };
      const attachmentsResponse = await privateApi.post(
        '/attachments/',
        attachmentPayload,
      );
      await privateApiAsFormData.post(
        `/attachments/${attachmentsResponse.data.id}/upload/`,
        formData,
      );
      updateIsDocumentUploading(false);
      actions.resetForm();
      mutate();
    } catch (error) {
      toast.error(error);
      updateIsDocumentUploading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsSnackbarOpen(true);
  };

  if (data) {
    return (
      <div>
        <Formik
          initialValues={{
            securities: (data && data.data.securities) || [],
            document: null,
            documentType: '' as DocumentType,
            documentTitle: '',
          }}
          enableReinitialize={true}
          validationSchema={documentValidationSchema}
          onSubmit={createNewDocument}
          render={(docFormikBag: FormikProps<DocumentFormValues>) => (
            <>
              <Dropzone
                multiple={false}
                onDrop={(acceptedFiles: File[]) => {
                  docFormikBag.setFieldValue('document', acceptedFiles[0]);
                  setOpen(true);
                }}
              >
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div
                      {...getRootProps()}
                      className={
                        isDragActive ? classes.activeDropzone : classes.dropzone
                      }
                    >
                      <input
                        {...getInputProps({ accept: 'application/pdf' })}
                      />

                      <div className="flex items-center flex-col justify-center font-medium uppercase py-1 whitespace-no-wrap text-secondary-text tracking-wider">
                        <PublishIcon fontSize="large" />
                        <p className="pt-1">UPLOAD FILES (PDF)</p>
                      </div>
                    </div>
                  );
                }}
              </Dropzone>
              <Dialog
                fullWidth
                maxWidth="sm"
                container={document.getElementById('proxy-body')}
                open={open}
                onClose={handleClose}
                classes={{
                  root: classes.rootDialog,
                }}
                BackdropProps={{
                  classes: { root: classes.backdrop },
                }}
                style={{ position: 'absolute' }}
                className={classes.rootDialog}
              >
                <DialogTitle>
                  {docFormikBag.values.document
                    ? docFormikBag.values.document.name
                    : data.data.issuer.companyName}
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      size="small"
                      select
                      required
                      label="Document Type"
                      value={docFormikBag.values.documentType || ''}
                      variant="outlined"
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const optionText = documentTypeOptions.filter(
                          (option) => option.value === e.target.value,
                        )[0]['label'];
                        docFormikBag.setFieldValue(
                          'documentType',
                          e.target.value,
                        );
                        docFormikBag.setFieldValue('documentTitle', optionText);
                      }}
                    >
                      <MenuItem value="" disabled />
                      {documentTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value + ''}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <Field
                    required
                    name="documentTitle"
                    variant="outlined"
                    type="text"
                    fullWidth
                    component={TextField}
                    size="small"
                    value={docFormikBag.values.documentTitle || ''}
                    // A value assignment is required (despite formik-material-ui docs) for autofill to work.
                    label="Document Title"
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      docFormikBag.setFieldValue(
                        'documentTitle',
                        e.target.value,
                      );
                    }}
                  />
                  <DocumentCusipTypeahead
                    docFormikBag={docFormikBag}
                    cusips={data.data.securities}
                  />
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose} type="button">
                    Cancel
                  </Button>
                  <Button
                    disabled={!docFormikBag.isValid}
                    type="submit"
                    color="primary"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.preventDefault();
                      docFormikBag.submitForm();
                      handleClose();
                    }}
                  >
                    {isDocumentUploading ? 'Saving...' : 'Save'}
                  </Button>
                </DialogActions>
              </Dialog>
              <DocumentSnackbar
                open={isSnackbarOpen}
                saving={isDocumentUploading}
                filename={
                  docFormikBag.values.document
                    ? docFormikBag.values.document.name
                    : docFormikBag.values.documentTitle
                }
                onClose={() => setIsSnackbarOpen(false)}
              />
            </>
          )}
        />
      </div>
    );
  }
  return null;
};
