import React, { useState, useEffect, ReactNode } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  RouteComponentProps,
  navigate,
  useLocation,
  Router,
} from '@reach/router';
import {
  FilingDetailsFormValues,
  FilingType,
  DeliveryMethod,
  MeetingType,
  ReportType,
  Action,
  OperationsStatus,
} from './types';
import { getValidationSchemaByStatus } from './validation-schema';
import { Formik, Form as FormikForm, FormikActions, FormikProps } from 'formik';
import {
  Tabs,
  Tab,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
} from '@material-ui/core/';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { ProductIcon } from '../../components/ProductIcon';
import { CusipTagList } from './CusipTagList';
import { EdgarDateTableCell } from './EdgarDateTableCell';
import { StatusBadge } from './StatusBadge';
import { SimpleSnackbar } from '../../components/Snackbar/';
import { DeleteConfirmationDialog } from '../../components/Dialog/DeleteConfirmationDialog';
import { UpdateStatusDialog } from '../../components/Dialog/UpdateStatusDialog';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { privateApi } from '../../old/utils/api-adapter';
import { useProxy } from './useProxy';
import { toast } from 'react-toastify';
import { userIdEvent } from '../../old/utils/analytics-helpers';
import { formatUpdateStatusError } from '../../utils/format-update-status-error';
import { formatDate } from '../../utils/format-date';
import { formatNumber } from '../../utils/format-number';
import { formatCamelCaseString } from '../../utils/format-camel-case';
import { getCurrentTabValueFromUrl } from './getCurrentTabValueFromUrl';
import { DetailsIndex } from './DetailsIndex';
import { ReviewEmail } from './ReviewEmail';
import { BatchBar } from './BatchBar';
import {
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from './batch-filings-context';
import { ActivityFeed } from './ActivityFeed';
import { DirtyFormDialog } from '../../components/Dialog/DirtyFormDialog';
import {
  useProxyWarningState,
  useProxyWarningDispatch,
  DirtyPromptLink,
  navigateAway,
} from './proxy-warning-context';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useBrokers } from './useBrokers';

type DetailsProps = RouteComponentProps<{ id: string }>;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      overflow: 'hidden',
      position: 'sticky',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      boxSizing: 'border-box',
      zIndex: 1100,
      flexShrink: 0,
      position: 'sticky',
      top: 0,
      left: 'auto',
      right: 0,
      backgroundColor: '#FFFFFF',
      '& > *': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #e5e5e5',
        padding: '0 24px',
      },
    },
    tab: {
      fontSize: '12px',
    },
    mainTitleBar: {
      height: '58px',
    },
    mainTitle: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        marginRight: '16px',
      },
    },
    filingTitleBar: {
      padding: '8px 24px',
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    filingTitle: {
      maxWidth: '12%',
    },
    errorHeaderNone: {
      display: 'none',
    },
    errorHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '12px 24px',
      fontSize: '.75rem',
    },
    saveButton: {
      marginRight: '12px',
      width: '112px',
    },
    updateStatusButton: {
      width: '112px',
    },
    body: {
      flexGrow: 2,
      display: 'flex',
      alignItems: 'stretch',
      backgroundColor: '#F8f8f8',
    },
  }),
);

function Details({ id }: DetailsProps) {
  const classes = useStyles();
  const location = useLocation();
  const { data, mutate } = useProxy(id);
  const brokers = useBrokers();
  const batchFilings = useBatchFilingsState();
  const dispatch = useBatchFilingsDispatch();
  const [activityFeedOpen, setActivityFeedOpen] = useState(false);
  const stateDirty = useProxyWarningState();
  const dispatchDirty = useProxyWarningDispatch();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const handleMenuClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);
  const [query] = useQueryParams({
    broker: withDefault(
      StringParam,
      brokers.data ? brokers.data.data.results[0].slug : '',
    ),
    view: withDefault(StringParam, 'email'),
  });

  // Alerting
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
  const [errorBanner, setErrorBanner] = React.useState<boolean>(false);

  const [toastMessage, setToastMessage] = React.useState('');
  const [badRequest, setBadRequest] = useState('');
  const [additionalRecordDatesError, setAdditionalRecordDatesError] = useState(
    '',
  );

  // Update Filing Status vs. Save Only
  const [updateStatus, setUpdateStatus] = useState<boolean>(false);

  const [
    isDeleteFilingWarningDialogOpen,
    setIsDeleteFilingWarningDialogOpen,
  ] = useState<boolean>(false);
  const [isUpdateStatusDialogOpen, setIsUpdateStatusDialogOpen] = useState<
    boolean
  >(false);

  const mapBatchPathToActionName = new Map([
    ['/proxy/tasks/drafts', 'confirmed'],
    ['/proxy/tasks/ready', 'completed'],
    ['/proxy/tasks/review', 'approved'],
  ]);

  useEffect(() => {
    dispatchDirty({ type: 'INITIALIZE_PROXY' });
  }, [dispatchDirty]);

  useEffect(() => {
    if (
      batchFilings.filings.length &&
      batchFilings.filings.length === batchFilings.finishedFilings.length
    ) {
      dispatch({
        type: 'OPEN_MESSAGE',
        message: `You’ve ${mapBatchPathToActionName.get(
          batchFilings.taskListPath,
        )} ${batchFilings.finishedFilings.length} of ${
          batchFilings.filings.length
        } ${batchFilings.taskListPath
          .split('/')
          .pop()} filings previously selected. ✅`,
      });
      navigate(batchFilings.taskListPath);
    }
  }, [
    batchFilings.filings,
    batchFilings.finishedFilings,
    batchFilings.taskListPath,
    dispatch,
    mapBatchPathToActionName,
  ]);

  const handleSnackbar = (message?: string) => {
    if (message) setToastMessage(message);
    setOpenSnackbar(true);
  };

  function handleTabChange(_: unknown, value: string) {
    const queryParams = `?broker=${query.broker}&view=${query.view}`;
    navigateAway(
      `/proxy/${id}/${value}/${queryParams}`,
      stateDirty,
      dispatchDirty,
    );
  }

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastMessage('');
    setOpenSnackbar(false);
  };

  const deleteFiling = async (id: string) => {
    try {
      await privateApi.delete(`/filings/${id}/`);
      navigate('/proxy');
    } catch (error) {
      toast.error(error);
    }
  };

  const handleFormSubmit = (
    values: FilingDetailsFormValues,
    { setSubmitting }: FormikActions<FilingDetailsFormValues>,
  ) => {
    setErrorBanner(false);
    if (values.recordDateCompare && values.additionalRecordDates) {
      const everyAddRecordDate = values.additionalRecordDates.every(
        (recordDate: string) => Boolean(recordDate),
      );
      if (!everyAddRecordDate) {
        setAdditionalRecordDatesError(
          'All additional record dates must be filled in.',
        );
        setSubmitting(false);
        return;
      }
    }
    if (
      updateStatus &&
      data &&
      data.data.operationsStatus !== OperationsStatus.Draft &&
      data.data.documents.length === 0
    ) {
      setErrorBanner(true);
      setBadRequest(
        'Missing at least one document that should be distributed to shareholders.',
      );
      setSubmitting(false);
      return;
    }
    if (updateStatus) setIsUpdateStatusDialogOpen(true);
    else patchFiling(values);
    setSubmitting(false);
    dispatchDirty({
      type: 'INITIALIZE_PROXY',
    });
  };

  const patchFiling = (
    values: FilingDetailsFormValues,
    targetStatus?: OperationsStatus,
  ) => {
    setIsSaving(true);
    if (data) {
      const {
        solicitor,
        tabulator,
        replyTo,
        sendTo,
        cusips,
        meetingDate,
        meetingTime,
        meetingTimezone,
        voteCutoffDate,
        voteCutoffTime,
        voteCutoffTimezone,
        meetingType,
        meetingAddress,
        virtualMeetingUrl,
        expectedDate,
        reportType,
        fundOwner,
        recordDateCompare,
        additionalRecordDates,
        ...theRest
      } = values;

      const meeting = {
        date: meetingDate,
        time: meetingTime,
        timezone: meetingTimezone,
        type: meetingType,
        address: meetingAddress ? meetingAddress : '',
        virtualMeetingUrl: virtualMeetingUrl ? virtualMeetingUrl : '',
      };

      const securityIds = cusips.map((cusip) => cusip.id);

      let payload: any = {
        ...theRest,
        digitalMaterialsExpectedDate: expectedDate,
        companyContactIds: [],
        issuerId: data.data.issuer.id,
        securityIds,
      };

      if (solicitor) {
        payload.solicitorId = solicitor.id;
      }

      if (replyTo) {
        const ids = replyTo.map((contact) => contact.id);
        payload.companyContactIds = [...payload.companyContactIds, ...ids];
      }

      if (
        data.data.type === 'FirmMeeting' ||
        data.data.type === 'FundMeeting' ||
        data.data.type === 'FirmConsentSolicitation' ||
        data.data.type === 'MeetingContest'
      ) {
        if (tabulator) {
          payload.tabulatorId = tabulator.id;
        }
        if (sendTo) {
          const ids = sendTo.map((contact) => contact.id);
          payload.companyContactIds = [...payload.companyContactIds, ...ids];
        }
      }

      if (recordDateCompare) {
        payload.additionalRecordDates = additionalRecordDates;
      } else if (!recordDateCompare && data.data.additionalRecordDates) {
        payload.additionalRecordDates = [];
      }

      switch (data && data.data.type) {
        case 'MeetingContest':
        case 'FundMeeting':
        case 'FirmMeeting':
          payload = {
            ...payload,
            voteCutoffTime,
            voteCutoffDate,
            voteCutoffTimezone,
            meeting,
          };
          break;
        case 'FirmConsentSolicitation':
          payload = {
            ...payload,
            voteCutoffTime,
            voteCutoffDate,
            voteCutoffTimezone,
            meeting,
          };
          break;
        case 'FundInformationStatement':
          payload = {
            ...payload,
            fundOwner,
          };
          break;
        case 'FundReport':
          payload = {
            ...payload,
            fundOwner,
            reportType,
          };
          break;
        default:
          break;
      }
      const response = privateApi.patch(`/filings/${data.data.id}/`, payload);
      response
        .then(() => {
          setIsSaving(false);
          setBadRequest('');
          if (targetStatus) {
            handleUpdateStatus(targetStatus);
          } else {
            handleSnackbar('This filing has been saved. 🎉');
            userIdEvent('Save Filing Clicked', {
              filingId: id,
              operationsStatus: data.data.operationsStatus,
              filingType: data.data.type,
              save: true,
            });
          }
        })
        .catch((error) => {
          setIsSaving(false);
          if (error.response) {
            const err = Object.keys(error.response.data).reduce(
              (acc: string, cv: string) => {
                acc = `${acc} ${formatCamelCaseString(cv)
                  .split('_')
                  .join(' ')}: ${error.response.data[cv]}.`;
                return acc;
              },
              '',
            );
            handleSnackbar('There was an error saving the filing.');
            setBadRequest(err);
            setErrorBanner(true);
          }
        });
    }
  };

  const mapStatusToUpdateAction = new Map([
    [OperationsStatus.Draft, 'confirm'],
    [OperationsStatus.Active, 'complete'],
    [OperationsStatus.MaterialsRequested, 'complete'],
    [OperationsStatus.ReadyForReview, 'approve'],
  ]);

  async function handleUpdateStatus(targetStatus: OperationsStatus) {
    if (id) {
      try {
        await privateApi.patch(`/filings/${id}/update-status/`, {
          operationsStatus: targetStatus,
        });
        mutate();
        handleSnackbar('Status updated');
        if (batchFilings.filings.length) {
          dispatch({ type: 'ADD_FINISHED_FILING', filing: Number(id) });
          navigate(
            `/proxy/${getNextBatchFiling(batchFilings.filings, Number(id))}`,
          );
        }
      } catch (error) {
        handleSnackbar(formatUpdateStatusError(error));
      }
      setIsUpdateStatusDialogOpen(false);
    }
  }

  if (data) {
    const filing = data.data;
    return (
      <Formik
        initialValues={{
          brokerSearchReceivedDate: filing.brokerSearchReceivedDate || null,
          recordDateCompare: Boolean(
            filing.additionalRecordDates &&
              filing.additionalRecordDates.length > 0,
          ),
          additionalRecordDates: filing.additionalRecordDates,
          cusips:
            filing.securities.map((security) => {
              return {
                id: security.id,
                cusip: security.cusip,
                name: security.name,
              };
            }) || [],
          deliveryMethod:
            filing.deliveryMethod || DeliveryMethod.NoticeAndAccess,
          digitalMaterialsReceivedDate:
            filing.digitalMaterialsReceivedDate || null,
          expectedDate: filing.digitalMaterialsExpectedDate || null,
          digitalSendDeadline: filing.stats.digitalSendDeadline || '',
          paperSendDeadline: filing.stats.paperSendDeadline || '',
          meetingAddress: (filing.meeting && filing.meeting.address) || '',
          meetingDate: (filing.meeting && filing.meeting.date) || null,
          meetingTime: (filing.meeting && filing.meeting.time) || null,

          meetingTimezone: (filing.meeting && filing.meeting.timezone) || null,
          meetingType:
            (filing.meeting && filing.meeting.type) || MeetingType.Annual,
          notes: filing.notes || '',
          paperMaterialsReceivedDate: filing.paperMaterialsReceivedDate || null,
          recordDate: filing.recordDate || null,
          replyTo: filing.companyContacts.filter(
            (companyContact) => companyContact.role === 'solicitor_reply_to',
          ),
          sendTo: filing.companyContacts.filter(
            (companyContact) => companyContact.role === 'tabulator_reply_to',
          ),
          solicitor: filing.solicitor || null,
          solicitorJobNumber: filing.solicitorJobNumber || undefined,
          tabulator: filing.tabulator || undefined,
          type: (filing.type as FilingType) || ('' as FilingType),
          virtualMeetingUrl:
            (filing.meeting && filing.meeting.virtualMeetingUrl) || '',
          voteCutoffDate: filing.voteCutoffDate || null,
          voteCutoffTime: filing.voteCutoffTime || null,
          voteCutoffTimezone: filing.voteCutoffTimezone || null,
          invoicerContactEmail: filing.invoicerContactEmail || '',
          reportType: filing.reportType
            ? filing.reportType
            : ('annual' as ReportType),
          fundOwner: filing.fundOwner || '',
          invoicerAddress: filing.invoicerAddress || '',
          invoicerAddressLine1: filing.invoicerAddressLine1 || '',
          invoicerAddressLine2: filing.invoicerAddressLine2 || '',
          invoicerAddressLine3: filing.invoicerAddressLine3 || '',
          invoicerCity: filing.invoicerCity || '',
          invoicerState: filing.invoicerState || '',
          invoicerPostalCode: filing.invoicerPostalCode || '',
          invoicerCountry: filing.invoicerCountry || '',
          issuer:
            filing.issuer.id && filing.issuer.companyName
              ? {
                  id: filing.issuer.id,
                  name: filing.issuer.companyName,
                }
              : null,
          meetingFormat:
            filing.meeting &&
            filing.meeting.address &&
            filing.meeting.virtualMeetingUrl
              ? 'hybrid'
              : filing.meeting && filing.meeting.address
              ? 'physical'
              : filing.meeting && filing.meeting.virtualMeetingUrl
              ? 'online'
              : '',
        }}
        validationSchema={getValidationSchemaByStatus(
          filing.type,
          filing.operationsStatus,
          updateStatus,
        )}
        isInitialValid={true}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {(formikBag: FormikProps<FilingDetailsFormValues>) => (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <FormikForm
              noValidate={true}
              onChange={() => {
                if (!formikBag.dirty) {
                  dispatchDirty({
                    type: 'MARK_DIRTY',
                  });
                }
              }}
            >
              <div className={classes.root} id="proxy-body">
                <div className={classes.header}>
                  {batchFilings.filings.length ? (
                    <BatchBar
                      totalSelected={batchFilings.filings.length}
                      finishedSelected={batchFilings.finishedFilings.length}
                      currentPosition={
                        batchFilings.filings.indexOf(Number(id)) + 1
                      }
                    />
                  ) : null}
                  <div className={classes.mainTitleBar}>
                    <div className={classes.mainTitle}>
                      <ProductIcon product="px" />
                      <h1 className="font-medium text-primary-text text-xl capitalize inline-block">
                        {`${formatCamelCaseString(filing.type)}: ${
                          filing.issuer.companyName
                        }`}
                      </h1>
                      <StatusBadge filingStatus={filing.operationsStatus} />
                      <Timestamp actionHistory={filing.actionHistory} />
                    </div>
                    <div onClick={handleMenuClick}>
                      <IconButton>
                        <MoreHorizIcon />
                      </IconButton>
                    </div>

                    <Menu
                      anchorEl={menuAnchorEl}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      keepMounted
                      open={Boolean(menuAnchorEl)}
                      onClose={handleMenuClose}
                      getContentAnchorEl={null}
                    >
                      <MenuItem
                        onClick={() => {
                          handleMenuClose();
                          setActivityFeedOpen(true);
                        }}
                      >
                        Activity
                      </MenuItem>
                      <MenuItem
                        disabled={
                          filing.operationsStatus === OperationsStatus.Approved
                        }
                        onClick={() => {
                          handleMenuClose();
                          setIsDeleteFilingWarningDialogOpen(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>

                  <div
                    id="ProxyDetailHeader"
                    className={classes.filingTitleBar}
                  >
                    <div className={classes.filingTitle}>
                      <HeaderTitle label="Issuer">
                        <div>
                          <DirtyPromptLink
                            to={`/issuers/${filing.issuer.globalIssuerId}`}
                            className="text-blue hover:underline hover:text-blue"
                          >
                            {filing.issuer.companyName}
                          </DirtyPromptLink>
                        </div>
                      </HeaderTitle>
                    </div>

                    <HeaderTitle label="CUSIP">
                      <CusipTagList
                        cusips={filing.securities.map(
                          (security) => security.cusip,
                        )}
                      />
                    </HeaderTitle>

                    <HeaderTitle
                      label="Filing Type"
                      value={formatCamelCaseString(filing.type)}
                    />

                    <HeaderTitle
                      label="Record Date"
                      value={formatDate(new Date(filing.recordDate))}
                    />

                    <HeaderTitle label="EDGAR Date">
                      {filing.edgarFilings && filing.edgarFilings.length > 0 ? (
                        <EdgarDateTableCell
                          edgarFilings={filing.edgarFilings}
                          issuer={filing.issuer}
                        />
                      ) : (
                        <span style={{ color: 'rgba(0,0,0,.38)' }}>N/A</span>
                      )}
                    </HeaderTitle>

                    <HeaderTitle
                      label="Positions"
                      value={formatNumber(filing.stats.totalPositions)}
                    />

                    <HeaderTitle
                      label="Materials"
                      value={
                        filing.actualMaterialsRequired
                          ? filing.actualMaterialsRequired.toString()
                          : '0'
                      }
                    />
                  </div>

                  <div id="FilingDetailTab">
                    <Tabs
                      value={getCurrentTabValueFromUrl(location)}
                      indicatorColor="primary"
                      textColor="inherit"
                      onChange={handleTabChange}
                      variant="fullWidth"
                    >
                      <Tab label="Filing" value="" className={classes.tab} />
                      <Tab
                        label="Email"
                        value="email"
                        className={classes.tab}
                      />
                    </Tabs>

                    <div id="FilingDetailSave">
                      <Button
                        className={classes.saveButton}
                        variant="outlined"
                        color="primary"
                        disabled={isSaving}
                        type="submit"
                        onClick={() => {
                          setErrorBanner(false);
                          setUpdateStatus(false);
                        }}
                      >
                        {isSaving ? 'Saving' : 'Save'}
                      </Button>

                      {mapStatusToUpdateAction.has(
                        data.data.operationsStatus,
                      ) ? (
                        <Button
                          className={classes.updateStatusButton}
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={() => {
                            if (formikBag.errors || !formikBag.isValid) {
                              setErrorBanner(true);
                            }
                            setUpdateStatus(true);
                          }}
                        >
                          {mapStatusToUpdateAction.get(
                            data.data.operationsStatus,
                          )}
                        </Button>
                      ) : null}
                    </div>
                  </div>

                  {/* Very basic - replace with error message(s) mapping fields to specific tabs. */}
                  <div
                    className={
                      errorBanner
                        ? classes.errorHeader
                        : classes.errorHeaderNone
                    }
                  >
                    <div>
                      <ErrorOutlineIcon
                        style={{ fill: '#d33f33', marginRight: '12px' }}
                      />
                      <span>Incomplete submission. {badRequest}</span>
                    </div>
                    <IconButton
                      size="small"
                      onClick={() => setErrorBanner(false)}
                    >
                      <CancelIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                </div>

                <div className={classes.body} id="proxy-body-inner">
                  {/* inline styles for router exist because reach router adds an
                empty div around each route in order to handle focus */}
                  <Router primary={false} style={{ display: 'contents' }}>
                    <DetailsIndex
                      path="/"
                      additionalRecordDatesError={additionalRecordDatesError}
                      formikBag={formikBag}
                    />
                    <ReviewEmail path="email" />
                  </Router>
                  <SimpleSnackbar
                    open={openSnackbar}
                    handleClose={handleCloseSnackbar}
                    message={toastMessage}
                  />
                  <DirtyFormDialog />
                  <DeleteConfirmationDialog
                    deleteType="filing"
                    isOpen={isDeleteFilingWarningDialogOpen}
                    onConfirmDelete={() => {
                      if (id) {
                        deleteFiling(id); // RouteComponentProps id
                      }
                    }}
                    onRequestClose={() =>
                      setIsDeleteFilingWarningDialogOpen(false)
                    }
                  />
                  <UpdateStatusDialog
                    actionType={
                      mapStatusToUpdateAction.get(
                        data.data.operationsStatus,
                      ) as any
                    }
                    isOpen={isUpdateStatusDialogOpen}
                    onConfirmUpdate={(targetStatus: OperationsStatus) =>
                      patchFiling(formikBag.values, targetStatus)
                    }
                    onRequestClose={() => {
                      setErrorBanner(false);
                      setIsUpdateStatusDialogOpen(false);
                      formikBag.setSubmitting(false);
                    }}
                  />
                </div>

                <Drawer
                  anchor="right"
                  open={activityFeedOpen}
                  onClose={() => setActivityFeedOpen(false)}
                >
                  <ActivityFeed
                    changesets={data.data.changesets}
                    onClose={() => setActivityFeedOpen(false)}
                  />
                </Drawer>
              </div>
            </FormikForm>
          </MuiPickersUtilsProvider>
        )}
      </Formik>
    );
  }
  return null;
}

type HeaderTitleProps = {
  label: string;
  value?: string | JSX.Element | null;
  children?: ReactNode | JSX.Element;
};

export const HeaderTitle = ({ label, value, children }: HeaderTitleProps) => {
  return (
    <div className="flex flex-col align-left">
      <div
        className="py-1 whitespace-no-wrap"
        style={{ fontSize: '12px', fontWeight: 'bold' }}
      >
        {label}
      </div>
      {children ? children : <div>{value}</div>}
    </div>
  );
};

type TimestampProps = {
  actionHistory: Action[];
};

export const Timestamp = ({ actionHistory }: TimestampProps) => {
  if (actionHistory.length > 0) {
    if (actionHistory[0]['modified']) {
      return (
        <div className="text-gray">
          {`Last modified: ${formatDate(
            new Date(actionHistory[0]['modified']),
          )}`}
        </div>
      );
    } else if (actionHistory[0]['created']) {
      return (
        <div className="text-gray">
          {`Created: ${formatDate(new Date(actionHistory[0]['created']))}`}
        </div>
      );
    }
  }
  return null;
};

function getNextBatchFiling(filings: number[], selected: number) {
  const currentIndex = filings.indexOf(selected);
  const next = filings[(currentIndex + 1) % filings.length];

  return next;
}

export { Details };
