import React from 'react';
import { FilingDetailsFormValues } from '../types';

import MenuItem from '@material-ui/core/MenuItem';

import { TextField } from 'formik-material-ui';
import { FilingDatePicker } from './FilingDatePicker';
import { AdditionalRecordDates } from './AdditionalRecordDates';

import { Field, FieldProps, FormikProps } from 'formik';
import { CusipTypeahead } from '../../../components/typeaheads';
import {
  createStyles,
  makeStyles,
  TextField as MUITextField,
} from '@material-ui/core';

import { formatCamelCaseString } from '../../../utils/format-camel-case';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > *': {
        margin: '12px 0',
      },
    },
  }),
);

type BaseProps = {
  formikBag: FormikProps<FilingDetailsFormValues>;
  additionalRecordDatesError: string;
  isDraft: boolean;
};

export const BaseDetails: React.FC<BaseProps> = ({
  formikBag,
  additionalRecordDatesError,
  isDraft,
}) => {
  const classes = useStyles();
  const filing = formikBag.values;

  return (
    <div id="ProxyDetailsFormField" className={classes.root}>
      <MUITextField
        id="ProxyDetailFilingType"
        fullWidth
        required
        disabled
        variant="outlined"
        size="small"
        label="Filing Type"
        value={formatCamelCaseString(filing.type)}
      />
      <div id="ProxyDetailCusip">
        <Field name="cusips">
          {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
            <CusipTypeahead
              value={field.value}
              onSetCusips={(cusips) => form.setFieldValue('cusips', cusips)}
              issuerId={
                form.values.issuer ? String(form.values.issuer.id) : null
              }
            />
          )}
        </Field>
      </div>

      <Field
        component={TextField}
        size="small"
        fullWidth
        required
        type="text"
        disabled={true}
        variant="outlined"
        label="Issuer"
        name="issuer.name"
      />
      <FilingDatePicker
        required={true}
        label="Broker Search Date"
        name="brokerSearchReceivedDate"
        formikBag={formikBag}
      />

      <Field
        component={TextField}
        size="small"
        fullWidth
        label="Job Number"
        name="solicitorJobNumber"
        variant="outlined"
      />

      <div className="grid grid-cols-2 gap-6">
        <FilingDatePicker
          label="Record Date"
          name="recordDate"
          required
          formikBag={formikBag}
        />
        <FilingDatePicker
          label="Expected Date"
          name="expectedDate"
          formikBag={formikBag}
        />
      </div>

      <AdditionalRecordDates
        error={additionalRecordDatesError}
        formikBag={formikBag}
        additionalRecordDates={filing.additionalRecordDates}
      />

      {filing.type === 'FundReport' && (
        <Field
          id="ProxyDetailReportType"
          component={TextField}
          size="small"
          fullWidth
          type="text"
          select={true}
          variant="outlined"
          label="Report Type"
          name="reportType"
          required={!isDraft}
        >
          <MenuItem value="annual">Annual</MenuItem>
          <MenuItem value="semi_annual">Semi-Annual</MenuItem>
        </Field>
      )}

      {(filing.type === 'MeetingContest' ||
        filing.type === 'FundMeeting' ||
        filing.type === 'FirmMeeting') && (
        <div className="grid grid-cols-2 gap-6">
          <FilingDatePicker
            label="Meeting Date"
            name="meetingDate"
            required={!isDraft}
            formikBag={formikBag}
          />

          <Field
            id="ProxyDetailMeetingType"
            component={TextField}
            fullWidth
            size="small"
            required={!isDraft}
            type="text"
            select={true}
            variant="outlined"
            label="Meeting Type"
            name="meetingType"
          >
            <MenuItem value="annual">Annual</MenuItem>
            <MenuItem value="special">Special</MenuItem>
          </Field>
        </div>
      )}

      <Field
        id="ProxyDetailDeliveryMethod"
        component={TextField}
        fullWidth
        size="small"
        type="text"
        select={true}
        required
        variant="outlined"
        label="Delivery Method"
        name="deliveryMethod"
      >
        <MenuItem value="traditional">Traditional</MenuItem>
        <MenuItem value="notice_and_access">Notice and Access</MenuItem>
      </Field>
    </div>
  );
};
