import React from 'react';
import { Changeset, OperationsStatus } from '../types';
import styled from 'styled-components';
import { ChangesetRow } from './ChangesetRow';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

interface Props {
  changesets: Changeset[];
  onClose: () => void;
}

function ActivityFeed({ changesets, onClose }: Props) {
  let status: OperationsStatus;

  return (
    <Wrapper>
      <CloseButton onClick={onClose}>
        <Close />
      </CloseButton>

      <Header>Activity</Header>

      {changesets.map((changeset, i) => {
        if (changeset.after.operationsStatus) {
          status = changeset.after.operationsStatus;
        }

        return <ChangesetRow key={i} changeset={changeset} status={status} />;
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 615px;
  padding: 0 24px;
  position: relative;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 12px;
  top: 12px;
`;

const Header = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000000de;
  padding: 24px 0;
`;

export { ActivityFeed };
