import { CorporateActionType, FormValues } from './types';
import { format } from 'date-fns';

export function createFormPayload(values: FormValues) {
  let payload: any = {
    issuerId: values.issuer!.id,
    securityIds: values.securities.map((security) => security.id),
    announcementDate: values.announcementDate
      ? values.announcementDate.toISOString()
      : '',
    electionCutoffDatetime: values.electionCutoffDate
      ? values.electionCutoffDate.toISOString()
      : '',
    dtcExpirationDate: values.dtcExpirationDate
      ? format(values.dtcExpirationDate, 'yyyy-MM-dd')
      : null,
    type: values.type,
    operationsStatus: values.operationsStatus,
  };

  if (values.recordDate) {
    payload = {
      ...payload,
      recordDate: values.recordDate
        ? format(values.recordDate, 'yyyy-MM-dd')
        : null,
    };
  }

  switch (values.type) {
    case 'ConsentSolicitation':
      payload = {
        ...payload,
        consentPayment: values.consentPayment,
      };
      break;
    case 'DividendOption':
      payload = {
        ...payload,
        frequency: values.frequency,
        grossDividend: values.grossDividend,
      };
      break;
    case 'DividendReinvestmentPlan':
      payload = {
        ...payload,
        dripReinvestmentPrice: values.dripReinvestmentPrice,
      };
      break;
    case 'ExchangeOffer':
      payload = {
        ...payload,
        contraCusip: values.contraCusip,
        ratio: values.ratio,
      };
      break;
    case 'MergerConsiderationElection':
      payload = {
        ...payload,
        consentPayment: values.consentPayment,
      };
      break;
    case 'OddLotOffer':
      payload = {
        ...payload,
        minimumQualifyingQuantity: values.minimumQualifyingQuantity,
        maximumQualifyingQuantity: values.maximumQualifyingQuantity,
        price: values.price,
      };
      break;
    case 'OfferToPurchaseBuyBack':
      payload = {
        ...payload,
        bidIncrement: values.bidIncrement,
        maximumBidPrice: values.maximumBidPrice,
        minimumBidPrice: values.minimumBidPrice,
        price: values.price,
      };
      break;
    case 'OfferToPurchaseThirdPartyOffer':
      payload = {
        ...payload,
        bidIncrement: values.bidIncrement,
        maximumBidPrice: values.maximumBidPrice,
        minimumBidPrice: values.minimumBidPrice,
        offererParty: values.offererParty,
        price: values.price,
      };
      break;
    case 'RightsIssue':
      payload = {
        ...payload,
        tradeSecurity: values.tradeSecurity,
        outTurn: values.outTurn,
      };
      break;
  }

  return payload;
}

export function getElectionChoices(type: CorporateActionType) {
  switch (type) {
    case 'DividendOption':
    case 'MergerConsiderationElection':
      return ['cash', 'stock', 'mixed'];
    case 'OfferToPurchaseThirdPartyOffer':
    case 'OfferToPurchaseBuyBack':
    case 'DividendReinvestmentPlan':
    case 'ConsentSolicitation':
    case 'ExchangeOffer':
    case 'RightsIssue':
      return ['yes', 'no'];
    case 'OddLotOffer':
      return ['purchase', 'sell', 'no'];
  }
}
