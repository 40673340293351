import React from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { RouteComponentProps, Link } from '@reach/router';
import { TableHeader } from '../../components/TableHeader';
import { responseInterface } from 'swr';
import { AxiosResponse } from 'axios';
import { Response, MissingExpiredResult, Query, OnUpdateQuery } from './index';
import { TableBodyRow } from '../../components/TableBodyRow';
import { TableBodyCell } from '../../components/TableBodyCell';
import { TableHeadCell } from '../../components/TableHeadCell';
import { formatNumber } from '../../utils/format-number';
import { Table } from '../../components/Table';
import { formatDate } from '../../utils/format-date';
import { LoadingState } from '../../components/LoadingState';
import { MaintenanceModal } from '../../components/MaintenanceModal';
import { useQueryParam, StringParam } from 'use-query-params';
import { notEmpty } from '../../utils/not-empty';
import { TickerAvatar } from '../../components/TickerAvatar';
import { CusipTagList } from '../../components/CusipTagList';
import { AlertIcon } from '../../components/AlertIcon';

type MissingExpiredProps = {
  response: responseInterface<
    AxiosResponse<Response<MissingExpiredResult>>,
    any
  >;
  query: Query;
  onUpdateQuery: OnUpdateQuery;
};

const MissingExpired = ({
  response,
  query,
  onUpdateQuery,
}: MissingExpiredProps & RouteComponentProps) => {
  const { data, error } = response;
  const [id, setId] = useQueryParam('id', StringParam);

  const handleCloseMaintenanceModal = () => setId('');

  if (!error && !data)
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );

  if (error) return <PageWrapper>Error...</PageWrapper>;

  if (data)
    return (
      <PageWrapper>
        <TableHeader
          count={data.data.count}
          page={query.page}
          pageSize={query.pageSize}
          itemType="fund"
          onPageChange={(page) =>
            onUpdateQuery('missingExpired', 'page', page - 1)
          }
          onPageSizeChange={(pageSize) =>
            onUpdateQuery('missingExpired', 'pageSize', pageSize)
          }
        />
        <Table>
          <thead className="bg-white">
            <tr>
              <TableHeadCell name="Ticker" />
              <TableHeadCell name="Security" />
              <TableHeadCell name="CUSIP" />
              <TableHeadCell
                name="Trades"
                align="right"
                orderingKey="trade_count"
                currentOrdering={query.ordering}
                onOrderingClick={(ordering: string) =>
                  onUpdateQuery('missingExpired', 'ordering', ordering)
                }
              />
              <TableHeadCell
                name="Effective Date"
                align="right"
                orderingKey="effective_date"
                currentOrdering={query.ordering}
                onOrderingClick={(ordering: string) =>
                  onUpdateQuery('missingExpired', 'ordering', ordering)
                }
              />
              <TableHeadCell
                name="Expect Date"
                align="right"
                orderingKey="expected_date"
                currentOrdering={query.ordering}
                onOrderingClick={(ordering: string) =>
                  onUpdateQuery('missingExpired', 'ordering', ordering)
                }
              />
            </tr>
          </thead>
          <tbody>
            {data.data.results.map((result) => (
              <TableBodyRow key={result.id} onClick={() => setId(result.id)}>
                <TableBodyCell>
                  <TickerAvatar tickers={getTickers(result)} />
                </TableBodyCell>
                <TableBodyCell>
                  <Link
                    className="text-blue hover:underline hover:text-blue"
                    to={`/issuers/${result.id}`}
                    target="_blank"
                    onClick={(event) => event.stopPropagation()}
                  >
                    {result.name}
                  </Link>
                </TableBodyCell>
                <TableBodyCell>
                  <CusipTagList cusips={getCusips(result)} />
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-primary-text font-medium text-right">
                    {formatNumber(result.tradeCount)}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-right">
                    {result.effectiveDate
                      ? formatDate(new Date(result.effectiveDate))
                      : '--'}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-red text-right">
                    {result.expectedDate ? (
                      formatDate(new Date(result.expectedDate))
                    ) : (
                      <div className="flex items-center justify-end">
                        -- <AlertIcon className="ml-2" />
                      </div>
                    )}
                  </div>
                </TableBodyCell>
              </TableBodyRow>
            ))}
          </tbody>
        </Table>

        {id && (
          <MaintenanceModal id={id} onClose={handleCloseMaintenanceModal} />
        )}
      </PageWrapper>
    );

  return null;
};

const getTickers = (result: MissingExpiredResult) => {
  const tickers = result.securities
    .map((security) => security.ticker)
    .filter(notEmpty);

  return tickers;
};

const getCusips = (result: MissingExpiredResult) => {
  const cusips = result.securities
    .map((security) => security.cusip)
    .filter(notEmpty);

  return cusips;
};

export { MissingExpired };
