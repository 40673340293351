import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { theme } from './theme';

export const GlobalStyles = createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
  }

  @import url('https://rsms.me/inter/inter-ui.css');
  

  @font-face {
  font-family: "Franklin Gothic";
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicBook.eot")
    format("embedded-opentype");
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicBook.ttf")
    format("truetype");
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicBook.woff")
    format("woff");
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicBook.woff2")
    format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicMedium.eot")
    format("embedded-opentype");
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicMedium.ttf")
    format("truetype");
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicMedium.woff")
    format("woff");
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicMedium.woff2")
    format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicDemi.eot")
    format("embedded-opentype");
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicDemi.ttf")
    format("truetype");
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicDemi.woff")
    format("woff");
  src: url("https://assets.saytechnologies.com/fonts/ITCFranklinGothicDemi.woff2")
    format("woff2");
  font-weight: 600;
}

  body, input, textarea {
    font-family: 'Franklin Gothic', 'Inter UI var alt', sans-serif;
    font-feature-settings: 'calt' 1, 'ss02' 1, 'tnum' 1;
  }

  span.bp3-popover-target {
    display: block;
  }

  .bp3-input {
    border: 2px solid ${theme.colors.lightDivider};
    box-shadow: none;
    font-family: "Franklin Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    border-radius: ${theme.radii[2]}px;
    font-weight: ${theme.fontWeights[4]};
    height: 30px;
    padding: 0 10px;

    &:focus {
      outline: none;
      border: 2px solid ${theme.colors.divider};
      box-shadow: none;
    }
  }

  .bp3-tag-input {
    min-height: 30px;
    height: auto;

    .bp3-tag-input-values {
      margin-top: 3px;
    }

    .bp3-button {
      margin: 1px;
    }
  }

  .bp3-tag {
    background-color: #595959;
  }

  .issuer-popover,
  .fund-owner-popover {
    position: absolute;
    max-height: 500px;
    overflow-y: scroll;
  }

  #nprogress .bar {
    background: #11CC99 !important;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px #29d, 0 0 5px #11CC99 !important;
  }
`;
