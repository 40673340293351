import React, { FC } from 'react';
import styled from 'styled-components';
import { formatDate } from '../utils/format-date';
import { userIdEvent } from '../utils/analytics-helpers';

type EdgarDateLinkProps = {
  date: string;
  edgarLandingPage: string;
  operationStatus: string;
};

const EdgarDateLink: FC<EdgarDateLinkProps> = ({
  date,
  edgarLandingPage,
  operationStatus,
}) => {
  return (
    <Link
      onClick={(event) => {
        event.stopPropagation();
        userIdEvent('Edgar Link Clicked', {
          operationStatus,
        });
      }}
      href={edgarLandingPage}
      target="_blank"
    >
      {formatDate(date)}
    </Link>
  );
};

export { EdgarDateLink };

const Link = styled.a`
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: underline;
`;
