import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { IFilingCreateValues } from '../../models/filing-form';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { Textarea } from '../Textarea';

export const Notes: FC<{}> = () => {
  return (
    <Field
      name="notes"
      render={({
        field,
      }: FieldProps<IFilingCreateValues | FilingDetailsFormValues>) => (
        <InputGroup>
          <Label htmlFor="notes">Notes</Label>
          <Textarea id="notes" {...field} rows={5} />
        </InputGroup>
      )}
    />
  );
};
