import { DocumentType } from './types';

export const documentTypeOptions: {
  value: DocumentType | '';
  label: string;
}[] = [
  {
    value: DocumentType.ProxyStatement,
    label: 'Proxy Statement',
  },
  {
    value: DocumentType.ProxyCard,
    label: 'Proxy Card',
  },
  {
    value: DocumentType.TenK,
    label: 'Form 10-K',
  },
  {
    value: DocumentType.AnnualReport,
    label: 'Annual Report',
  },
  {
    value: DocumentType.SemiAnnualReport,
    label: 'Semi-Annual Report',
  },
  {
    value: DocumentType.StatutoryProspectus,
    label: 'Statutory Prospectus',
  },
  {
    value: DocumentType.SummaryProspectus,
    label: 'Summary Prospectus',
  },
  {
    value: DocumentType.ConsentSolicitation,
    label: 'Consent Solicitation',
  },
  {
    value: DocumentType.InformationStatement,
    label: 'Information Statement',
  },
];
