import React from 'react';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

type CusipTagListProps = {
  cusips: string[];
};

const CusipTagList = ({ cusips }: CusipTagListProps) => {
  const [cusipPopover, setCusipPopover] = React.useState<null | HTMLElement>(
    null,
  );
  const handleCusipClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation(); // isolates click to popover when used on task lists
    event.preventDefault();
    setCusipPopover(event.currentTarget);
  };
  const handleCusipClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setCusipPopover(null);
  };
  const totalCusips = cusips.length;
  const firstCusip = cusips[0];
  const remainingCusipsLength = cusips.length - 1;

  if (totalCusips === 0) {
    return null;
  }

  return (
    <div>
      <Chip size="small" label={firstCusip} />

      {totalCusips > 1 && (
        <>
          <Button
            size="small"
            onClick={(e) => handleCusipClick(e)}
            style={{ fontSize: '12px', marginLeft: '6px', paddingRight: 0 }}
          >
            {`+ ${remainingCusipsLength}`}
            <div style={{ opacity: 0.5 }}>
              <ArrowDropDownIcon />
            </div>
          </Button>
          <Popover
            PaperProps={{ style: { padding: '12px', minWidth: '160px' } }}
            open={Boolean(cusipPopover)}
            anchorEl={cusipPopover}
            onClose={handleCusipClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
          >
            <ul>
              {cusips.map((cusip: string) => (
                <li className="py-2" key={cusip}>
                  {cusip}
                </li>
              ))}
            </ul>
          </Popover>
        </>
      )}
    </div>
  );
};

export { CusipTagList };
