import React, { FC, ReactNode, useState } from 'react';
import { Flex, Box } from '@rebass/grid';

import { ReactComponent as Close } from '../assets/Close.svg';
import styled from 'styled-components/macro';

const Header = styled(Flex)<{ isGray?: boolean }>`
  border-bottom: 1px solid
    ${({ theme, isGray }) =>
      isGray ? theme.colors.background : theme.colors.green};
  font-weight: 600;
  text-align: center;
`;

const Nav = styled(Flex)`
  width: 100%;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 18px 16px 8px 8px;
  position: absolute;
  top: 0;
  right: 0;
`;

const NavItem = styled(Box)<{ isActive: boolean }>`
  padding: 8px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  opacity: ${({ isActive }) => (isActive ? '1' : '.3')};
  border-bottom: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.green : theme.colors.background};
  cursor: pointer;
`;

const Container = styled(Flex)`
  max-height: 80vh;
  overflow: scroll;
`;

interface IProps {
  children: ReactNode;
  title: string;
  onRequestClose: () => void;
  navItems?: string[];
  isGray?: boolean;
  setSelectedNavItem?: (navItem: string) => void;
}

export const Dialog: FC<IProps> = ({
  children,
  title,
  navItems,
  setSelectedNavItem,
  onRequestClose,
  isGray,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <>
      <CloseButton
        onClick={() => {
          onRequestClose();
        }}
      >
        <Close />
      </CloseButton>

      <Header isGray={isGray} p={3} justifyContent="flex-start">
        <Box>{title}</Box>
      </Header>

      {navItems && setSelectedNavItem && (
        <Nav flexDirection="row">
          {navItems.map((navItem: string, i: number) => {
            return (
              <NavItem
                key={navItem}
                width={1 / navItems.length}
                isActive={i === currentIndex}
                onClick={() => {
                  setCurrentIndex(i);
                  setSelectedNavItem(navItems[i]);
                }}
              >
                {navItem}
              </NavItem>
            );
          })}
        </Nav>
      )}

      <Container p={4}>{children}</Container>
    </>
  );
};
