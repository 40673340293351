import styled from 'styled-components/macro';
import {
  space,
  SpaceProps,
  width,
  WidthProps,
  alignItems,
  justifyContent,
  display,
  AlignItemsProps,
  JustifyContentProps,
  DisplayProps,
} from 'styled-system';

interface PlainButtonProps
  extends SpaceProps,
    WidthProps,
    AlignItemsProps,
    JustifyContentProps,
    DisplayProps {
  small?: boolean;
}

export const PlainButton = styled.button<PlainButtonProps>`
  ${space}
  ${width}
  ${alignItems}
  ${justifyContent}
  ${display}
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme, small }) => theme.fontSizes[small ? 0 : 1]}px;
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  transition: color 0.25s ease;

  &:enabled:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
