import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  OperationsStatus,
  FilingListResponse,
  FilingListFiling,
} from '../../../models/filing-list';
import { UnmarkReadyForReviewModal } from '../UnmarkReadyForReviewModal';
import { SolicitorCell } from '../../../common/List/SolicitorCell';
import { CusipCell } from '../../../common/List/CusipCell';
import { Button } from '../../../common/Buttons/Button';
import { formatDate } from '../../../utils/format-date';
import { formatCamelCaseString } from '../../../utils/format-camel-case-string';
import { StatusBar } from '../../../common/StatusBar';
import { GrayButton } from '../../../common/Buttons/GrayButton';
import { formatMeetingDateCell } from '../../../utils/format-meeting-date-cell';
import { Ordering } from '../../../data/Ordering.Context';
import Table, { TableColumnsProps } from '../../../common/Table/Table';
import TableRow from '../../../common/Table/TableRow';
import Cell from '../../../common/Table/Cell';
import { EdgarDateTableCell } from '../../../common/EdgarDateTableCell';

interface CompleteProps extends RouteComponentProps {
  filings: FilingListResponse;
  isLoading: boolean;
  onChangePage: (path: string) => void;
  onMarkReadyForReview: (
    filingId: number,
    operationsStatus: OperationsStatus.ReadyForReview,
  ) => void;
  onUnmarkReadyForReview: (
    filingId: number,
    operationsStatus: OperationsStatus.ReadyForReview,
  ) => void;
}

export const CompleteFilings: FC<CompleteProps> = ({
  filings,
  isLoading,
  onChangePage,
  onMarkReadyForReview,
  onUnmarkReadyForReview,
}) => {
  const { ordering, setOrder } = Ordering.useContainer();
  const [
    unmarkReadyForReviewModalState,
    setUnmarkReadyForReviewModalState,
  ] = useState<{
    isOpen: boolean;
    filingId: number;
  }>({ isOpen: false, filingId: 0 });

  const handleSetOrdering = (order: string) => {
    setOrder('completeFilings', order);
  };

  const markReadyForReview = (filingId: number) => {
    return onMarkReadyForReview(filingId, OperationsStatus.ReadyForReview);
  };

  const unmarkReadyForReview = (filingId: number) => {
    return setUnmarkReadyForReviewModalState({ isOpen: true, filingId });
  };

  const handleUndoMarkReadyForReview = () => {
    onUnmarkReadyForReview(
      unmarkReadyForReviewModalState.filingId,
      OperationsStatus.ReadyForReview,
    );
    return setUnmarkReadyForReviewModalState({ isOpen: false, filingId: 0 });
  };

  const renderReadyForReviewButton = (filing: FilingListFiling) => {
    return filing.operationsStatus === OperationsStatus.ReadyForReview ? (
      <GrayButton
        small={true}
        m="0 auto"
        width={'185px'}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          return unmarkReadyForReview(filing.id);
        }}
      >
        Ready for Review
      </GrayButton>
    ) : (
      <Button
        small={true}
        width={'185px'}
        m="0 auto"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          return markReadyForReview(filing.id);
        }}
      >
        Mark Ready for Review
      </Button>
    );
  };

  return (
    <>
      <StatusBar
        count={filings.count}
        next={filings.next}
        onChangePage={onChangePage}
        previous={filings.previous}
        typeLabel="Proxy Tasks"
      />

      <Table
        isLoading={isLoading}
        columns={columns}
        onSetOrdering={handleSetOrdering}
        ordering={ordering.completeFilings}
        variant="green"
      >
        {filings.results.map((filing) => {
          return (
            <TableRow
              key={filing.id}
              to={`old/filings/${filing.id}/mailings`}
              variant="green"
            >
              <Cell>{filing.issuer.companyName}</Cell>
              <Cell>
                {filing.edgarFilings && (
                  <EdgarDateTableCell
                    edgarFilings={filing.edgarFilings}
                    issuer={filing.issuer}
                  />
                )}
              </Cell>
              <Cell>
                <CusipCell
                  cusips={filing.securities.map((security) => security.cusip)}
                />
              </Cell>
              <Cell>{formatCamelCaseString(filing.type)}</Cell>
              <Cell align="right">
                {filing.recordDate ? formatDate(filing.recordDate) : ''}
              </Cell>
              <Cell align="right">
                {filing.stats &&
                filing.stats.estimatedDigitalMaterialsExpectedDate &&
                filing.stats.estimatedDigitalMaterialsExpectedDate
                  ? formatDate(
                      filing.stats.estimatedDigitalMaterialsExpectedDate,
                    )
                  : ''}
              </Cell>
              <Cell align="right">
                {formatMeetingDateCell(filing.type, filing.meetingDate)}
              </Cell>
              <Cell>
                <SolicitorCell
                  actualMaterialsRequired={
                    filing.stats && filing.stats.materialsRequired
                  }
                  recordDate={filing.recordDate}
                  solicitor={filing.solicitor}
                  solicitorJobNumber={filing.solicitorJobNumber}
                />
              </Cell>
              <Cell align="center">{renderReadyForReviewButton(filing)}</Cell>
            </TableRow>
          );
        })}
      </Table>

      <UnmarkReadyForReviewModal
        isOpen={unmarkReadyForReviewModalState.isOpen}
        onRequestClose={() =>
          setUnmarkReadyForReviewModalState({ isOpen: false, filingId: 0 })
        }
        onUndoMarkReadyForReview={handleUndoMarkReadyForReview}
      />
    </>
  );
};

const columns: TableColumnsProps[] = [
  {
    name: 'Issuer',
    key: 'issuer',
  },
  {
    name: 'Edgar',
    key: 'edgar',
    orderingKey: 'latest_edgar_filing_date',
  },
  {
    name: 'CUSIP',
    key: 'cusip',
  },
  {
    name: 'Filing Type',
    key: 'filing-type',
  },
  {
    name: 'Record Date',
    key: 'record-date',
    align: 'right',
    orderingKey: 'first_record_date',
  },
  {
    name: 'Expect Date',
    key: 'expect-date',
    orderingKey: 'digital_materials_expected_date',
    align: 'right',
  },
  {
    name: 'Meeting Date',
    key: 'meeting-date',
    align: 'right',
    orderingKey: 'meeting_date',
  },
  {
    name: 'Solicitor / Contact',
    key: 'solicitor-contact',
  },
  {
    name: 'Action',
    key: 'action',
    align: 'center',
  },
];
