import React, { FC, ReactNode } from 'react';
import { RouteComponentProps } from '@reach/router';
import { FormikProps, Field, FieldProps } from 'formik';
import {
  InvoicerAddress,
  InvoicerAddressFields,
} from '../common/FormComponents';
import { SolicitorTypeahead } from '../common/FormComponents/Solicitor';
import { FilingDetailsFormValues } from './Filing';
import { ReplyToTypeahead } from '../common/FormComponents/ReplyTo';
import { TabulatorTypeahead } from '../common/FormComponents/Tabulator';
import { SendToTypeahead } from '../common/FormComponents/SendTo';
import { Notes } from '../common/FormComponents/Notes';
import { SolicitorJobNumber } from '../common/FormComponents/SolicitorJobNumber';
import { InvoicerContactEmail } from '../common/FormComponents/InvoicerContactEmail';
import { ElevatedCard } from '../common/ElevatedCard';
import styled from 'styled-components/macro';
import { FundOwner } from '../common/FormComponents/FundOwner';
import { InputGroup } from '../common/InputGroup';
import { Label } from '../common/Label';
import { Input } from '../common/Input';
import { ErrorLabel } from '../common/ErrorLabel';

const Wrapper = styled(ElevatedCard)`
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 16px;
  padding: 32px 16px;
`;

interface IProps extends RouteComponentProps {
  formikBag: FormikProps<FilingDetailsFormValues>;
}

export const ContactDetails: FC<IProps> = ({ formikBag }) => {
  const { type, solicitor, tabulator } = formikBag.values;

  const fields = (): ReactNode => {
    switch (type) {
      case 'FundReport':
        return (
          <>
            <div>
              <SolicitorTypeahead />
              {solicitor && (
                <ReplyToTypeahead
                  companyId={solicitor.id}
                  company={solicitor}
                  setFieldValue={formikBag.setFieldValue}
                  isUpdate={true}
                />
              )}
              <SolicitorJobNumber />
            </div>

            <div>
              <FundOwner />
              <InvoicerContactEmail />
              <InvoicerAddressFields />
              <Notes />
            </div>
          </>
        );
      case 'FundMeeting':
        return (
          <>
            <div>
              <SolicitorTypeahead />
              {solicitor && (
                <ReplyToTypeahead
                  companyId={solicitor.id}
                  company={solicitor}
                  setFieldValue={formikBag.setFieldValue}
                  isUpdate={true}
                />
              )}
              <SolicitorJobNumber />
              <TabulatorTypeahead />
              {tabulator && (
                <SendToTypeahead
                  companyId={tabulator.id}
                  company={tabulator}
                  setFieldValue={formikBag.setFieldValue}
                  isUpdate={true}
                />
              )}
            </div>

            <div>
              <InvoicerContactEmail />
              <InvoicerAddressFields />
              <Notes />
            </div>
          </>
        );
      case 'FirmMeeting':
        return (
          <>
            <div>
              <SolicitorTypeahead />
              {solicitor && (
                <ReplyToTypeahead
                  companyId={solicitor.id}
                  company={solicitor}
                  setFieldValue={formikBag.setFieldValue}
                  isUpdate={true}
                />
              )}
              <SolicitorJobNumber />
              <TabulatorTypeahead />
              {tabulator && (
                <SendToTypeahead
                  companyId={tabulator.id}
                  company={tabulator}
                  setFieldValue={formikBag.setFieldValue}
                  isUpdate={true}
                />
              )}
            </div>

            <div>
              <InvoicerContactEmail />
              <InvoicerAddressFields />
              <Notes />
            </div>
          </>
        );
      case 'FirmInformationStatement':
        return (
          <>
            <div>
              <SolicitorTypeahead />
              {solicitor && (
                <ReplyToTypeahead
                  companyId={solicitor.id}
                  company={solicitor}
                  setFieldValue={formikBag.setFieldValue}
                  isUpdate={true}
                />
              )}
              <SolicitorJobNumber />
            </div>

            <div>
              <InvoicerContactEmail />
              <InvoicerAddressFields />
              <Notes />
            </div>
          </>
        );
      case 'FundInformationStatement':
        return (
          <>
            <div>
              <SolicitorTypeahead />
              {solicitor && (
                <ReplyToTypeahead
                  companyId={solicitor.id}
                  company={solicitor}
                  setFieldValue={formikBag.setFieldValue}
                  isUpdate={true}
                />
              )}
              <SolicitorJobNumber />
            </div>

            <div>
              <FundOwner />
              <InvoicerContactEmail />
              <InvoicerAddressFields />
              <Notes />
            </div>
          </>
        );
      case 'FirmConsentSolicitation':
        return (
          <>
            <div>
              <SolicitorTypeahead />
              {solicitor && (
                <ReplyToTypeahead
                  companyId={solicitor.id}
                  company={solicitor}
                  setFieldValue={formikBag.setFieldValue}
                  isUpdate={true}
                />
              )}
              <SolicitorJobNumber />
              <TabulatorTypeahead />
              {tabulator && (
                <SendToTypeahead
                  companyId={tabulator.id}
                  company={tabulator}
                  setFieldValue={formikBag.setFieldValue}
                  isUpdate={true}
                />
              )}
            </div>

            <div>
              <InvoicerContactEmail />
              <InvoicerAddressFields />
              <Notes />
            </div>
          </>
        );
      case 'MeetingContest':
        return (
          <>
            <div>
              <SolicitorTypeahead />
              {solicitor && (
                <ReplyToTypeahead
                  companyId={solicitor.id}
                  company={solicitor}
                  setFieldValue={formikBag.setFieldValue}
                  isUpdate={true}
                />
              )}
              <SolicitorJobNumber />
              <TabulatorTypeahead />
              {tabulator && (
                <SendToTypeahead
                  companyId={tabulator.id}
                  company={tabulator}
                  setFieldValue={formikBag.setFieldValue}
                  isUpdate={true}
                />
              )}
            </div>

            <div>
              <InvoicerContactEmail />
              <InvoicerAddressFields />
              <Notes />
            </div>
          </>
        );
      case 'CorporateAction':
        return (
          <>
            <div>
              <Field
                name="informationAgent"
                render={({
                  field,
                  form,
                }: FieldProps<FilingDetailsFormValues>) => (
                  <InputGroup>
                    <Label htmlFor="information-agent">
                      Information Agent{' '}
                      {form.errors['informationAgent'] && (
                        <ErrorLabel>
                          {form.errors['informationAgent']}
                        </ErrorLabel>
                      )}
                    </Label>
                    <Input {...field} id="information-agent" />
                  </InputGroup>
                )}
              />

              <Field
                name="informationAgentEmail"
                render={({
                  field,
                  form,
                }: FieldProps<FilingDetailsFormValues>) => (
                  <InputGroup>
                    <Label htmlFor="information-agent-email">
                      Information Agent Email{' '}
                      {form.errors['informationAgentEmail'] && (
                        <ErrorLabel>
                          {form.errors['informationAgentEmail']}
                        </ErrorLabel>
                      )}
                    </Label>
                    <Input
                      {...field}
                      id="information-agent-email"
                      type="email"
                    />
                  </InputGroup>
                )}
              />

              <Field
                name="informationAgentAddress"
                render={({ field }: FieldProps<FilingDetailsFormValues>) => (
                  <InputGroup>
                    <Label htmlFor="information-agent-address">
                      Information Agent Address
                    </Label>
                    <Input {...field} id="information-agent-address" />
                  </InputGroup>
                )}
              />

              <Field
                name="informationAgentPhone"
                render={({ field }: FieldProps<FilingDetailsFormValues>) => (
                  <InputGroup>
                    <Label htmlFor="information-agent-phone">
                      Information Agent Phone
                    </Label>
                    <Input {...field} id="information-agent-phone" type="tel" />
                  </InputGroup>
                )}
              />
            </div>

            <div>
              <InvoicerAddress />
              <InvoicerContactEmail />
              <Notes />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return <Wrapper>{fields()}</Wrapper>;
};
