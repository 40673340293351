import React, { FC, useState, KeyboardEvent } from 'react';
import { Flex } from '@rebass/grid';
import { ReactComponent as MagnifyingGlass } from '../assets/magnifying-glass.svg';
import styled from 'styled-components/macro';
import { SearchTag } from './SearchTag';

const Wrapper = styled(Flex)<{ isFocused: boolean }>`
  border-bottom: 1px solid
    ${({ isFocused }) => (isFocused ? `#000` : `#d9d9d9`)};
  height: 41px;
  transition: border-bottom 0.25s ease;
  width: 100%;
`;

const MagnifyingGlassContainer = styled.div`
  margin: 7px 5px 0 0;
`;

const Input = styled.input`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  outline: none;
  caret-color: ${({ theme }) => theme.colors.green}

  &::placeholder {
    color: #d9d9d9;
  }
`;

interface SearchProps {
  onSearch: (searchTerm: string) => void;
  searchTerm: string;
}

export const Search: FC<SearchProps> = ({ onSearch, searchTerm }) => {
  const [isInputFocused, setInputFocused] = useState<boolean>(false);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(event.currentTarget.value);
    } else {
      return false;
    }
  };

  return (
    <Wrapper py={1} isFocused={isInputFocused}>
      <MagnifyingGlassContainer>
        <MagnifyingGlass />
      </MagnifyingGlassContainer>

      {searchTerm ? (
        <SearchTag
          tag={searchTerm}
          onClearSearch={() => {
            setInputFocused(false);
            return onSearch('');
          }}
        />
      ) : (
        <Input
          placeholder="Search"
          onBlur={() => setInputFocused(false)}
          onKeyDown={handleKeyPress}
          onFocus={() => setInputFocused(true)}
          type="search"
        />
      )}
    </Wrapper>
  );
};
