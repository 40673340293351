import React from 'react';
import { hubApi } from '../../old/data/hubApiConfig';
import useSWR, { mutate } from 'swr';
import { LoadingState } from '../LoadingState';
import { ProspectusSecurityRow } from '../ProspectusSecurityRow';
import { Choice } from './Choice';
import { Upload } from './Upload';
import { toast } from 'react-toastify';
import { UpdateDate } from './UpdateDate';
import { Dismiss } from './Dismiss';
import { Link } from '@reach/router';
import { createStyles, Dialog, makeStyles } from '@material-ui/core';

type Security = {
  id: string;
  cusip: string;
  ticker: null | string;
  name: string;
  securityType: null | string;
  price: null | string;
  priceLastUpdated: null | string;
  primaryColor: null | string;
  iconUrl: null | string;
  prospectuses: Prospectus[];
  stats: SecurityStats;
  issuerName: string;
  issuerId: string;
};

type Prospectus = {
  id: string;
  type: ProspectusType;
  name: string;
  frontLink: string;
  website: string;
  attachment: string;
  securities: string[];
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  description: string;
};

type ProspectusType =
  | 'summary'
  | 'sticker'
  | 'statement_of_additional_information'
  | 'statutory';

type SecurityStats = {
  onHoldTrades: number;
  monthlyTrades: number;
};

type Action = 'upload' | 'update-date' | 'dismiss' | 'none';

const fetcher = (url: string) => hubApi.get<Security>(url);

const useStyles = makeStyles(() =>
  createStyles({
    paperFullScreen: { margin: '2rem' },
  }),
);

type TradeAlertModalProps = {
  id: string;
  onClose: () => void;
};

const TradeAlertModal = ({ id, onClose }: TradeAlertModalProps) => {
  const classes = useStyles();
  const response = useSWR(`/securities/security/${id}/prospectus/`, fetcher);
  const { data, error } = response;
  const [action, setAction] = React.useState<Action>('none');

  const handleActionChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setAction(event.currentTarget.value as Action);

  const handleCancelAction = () => setAction('none');

  const handleCreateProspectusSuccess = () => {
    mutate(`/securities/security/${id}/prospectus/`);
    toast.success('Your prospectus documents were successfully created.');
    onClose();
  };

  const handleUpdateDateSuccess = () => {
    toast.success('Expected date was updated successfully.');
    mutate(`/securities/security/${id}/prospectus/`);
    onClose();
  };

  const handleDismissSuccess = () => {
    mutate(`/securities/security/${id}/prospectus/`);
    toast.success(
      'This trade alert has been dismissed and the existing document was sent',
    );
    onClose();
  };

  if (!error && !data)
    return (
      <Dialog
        open={true}
        className={classes.paperFullScreen}
        onClose={onClose}
        fullScreen
      >
        <div className="flex items-center justify-center h-full w-full">
          <LoadingState />
        </div>
      </Dialog>
    );

  if (error)
    return (
      <Dialog
        open={true}
        className={classes.paperFullScreen}
        onClose={onClose}
        fullScreen
      >
        <div className="flex items-center justify-center h-full w-full">
          Error...
        </div>
      </Dialog>
    );

  if (data) {
    const security = data.data;
    const hasDocuments = Boolean(
      security.prospectuses && security.prospectuses.length,
    );

    return (
      <Dialog
        className={classes.paperFullScreen}
        open={true}
        onClose={onClose}
        fullScreen
      >
        <header className="p-8 border-b border-light-gray">
          <h1 className="font-medium">
            <Link
              className="text-blue hover:underline hover:text-blue"
              target="_blank"
              to={`/issuers/funds/${security.issuerId}`}
            >
              {security.issuerName}
            </Link>
          </h1>
        </header>

        <div className="p-8 flex flex-col items-center">
          <ProspectusSecurityRow security={security} showCheckmark={false} />

          {(action === 'none' || action === 'upload') && (
            <React.Fragment>
              <Choice
                value="upload"
                label="Upload new doc(s)."
                onChange={handleActionChange}
              />

              {action === 'upload' && (
                <Upload
                  onCancel={handleCancelAction}
                  id={security.id}
                  onSuccess={handleCreateProspectusSuccess}
                />
              )}
            </React.Fragment>
          )}

          {(action === 'none' || action === 'update-date') && hasDocuments && (
            <React.Fragment>
              <Choice
                value="update-date"
                label="Update upcoming date."
                onChange={handleActionChange}
              />

              {action === 'update-date' && (
                <UpdateDate
                  onCancel={handleCancelAction}
                  prospectuses={security.prospectuses}
                  onSuccess={handleUpdateDateSuccess}
                />
              )}
            </React.Fragment>
          )}

          {(action === 'none' || action === 'dismiss') && (
            <React.Fragment>
              <Choice
                value="dismiss"
                label="Dismiss. No new information available."
                onChange={handleActionChange}
              />

              {action === 'dismiss' && (
                <Dismiss
                  onCancel={handleCancelAction}
                  id={security.id}
                  onSuccess={handleDismissSuccess}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </Dialog>
    );
  }

  return null;
};

export { TradeAlertModal };
