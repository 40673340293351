import React, { FC, useContext, useState } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Formik, FormikProps, FormikActions } from 'formik';
import { decamelize } from 'humps';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { useContainer } from 'unstated-next';

import {
  IElectionItemsFormValues,
  ElectionType,
  RecommendationTypes,
  ICusip,
} from '../../models/vote-proposal';
import { FilingContext } from '../../data/Filing.Context';
import { ElectionType as ElectionTypeField } from '../../common/VoteFormComponents/ElectionType';
import { RecommendationType } from '../../common/VoteFormComponents/Recommendation';
import { ProposalTitle } from '../../common/VoteFormComponents/ProposalTitle';
import { CusipTypeaheadWithoutApi } from '../../common/VoteFormComponents/CusipTypeaheadWithoutApi';
import { ProposalDetail } from '../../common/VoteFormComponents/ProposalDetail';
import { OutlineButton } from '../../common/Buttons/OutlineButton';
import { Button } from '../../common/Buttons/Button';
import { ErrorModal } from './ErrorModal';
import { privateApi } from '../../utils/api-adapter';
import { VoteProposals } from '../../data/VoteProposal';
import { EditTypes } from './VoteContainer';

interface IProps {
  isEditing: boolean;
  currentGroupNumber: number;
  resetElectionsState: (type: EditTypes) => void;
  handleCancel: () => void;
  initialValues: IElectionItemsFormValues | null;
}

const SubmitContainer = styled(Flex)`
  border-top: 1px solid #d8d8d8;
`;

export const ElectionForm: FC<IProps> = ({
  isEditing,
  currentGroupNumber,
  resetElectionsState,
  initialValues,
  handleCancel,
}) => {
  const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([] as string[]);

  const { filing } = useContext(FilingContext);
  const { addElection, updateElection } = useContainer(VoteProposals);

  const cusips: ICusip[] = filing
    ? filing.securities.map((security) => {
        return {
          id: security.id,
          cusip: security.cusip,
          name: security.name,
        };
      })
    : ([] as ICusip[]);

  return (
    <Flex width={1} px={25} pt={20} pb={45}>
      <Formik
        initialValues={
          initialValues
            ? { ...initialValues }
            : {
                electionType: 'yes_no' as ElectionType,
                recommendation: 'Yes' as RecommendationTypes,
                securities: cusips,
                details: '',
                proposalTitle: '',
                proposalType: 'Corporate Action',
                proposalNumber: currentGroupNumber,
                groupNumber: currentGroupNumber,
                id: null,
              }
        }
        onSubmit={(
          values: IElectionItemsFormValues,
          formikActions: FormikActions<IElectionItemsFormValues>,
        ) => {
          if (filing) {
            const securities: number[] = values.securities.map(
              (security) => security.id,
            );

            const payload = {
              corporateAction: Number(filing.id),
              details: values.details,
              electionType: values.electionType,
              groupNumber: currentGroupNumber,
              proposalNumber: currentGroupNumber,
              title: values.proposalTitle,
              securities,
              recommendationType: values.recommendation.toLowerCase(),
            };

            if (!isEditing) {
              privateApi
                .post(`/election-items/`, payload)
                .then((response) => {
                  addElection(response.data);
                  resetElectionsState(EditTypes.create);
                  formikActions.resetForm();
                })
                .catch((error) => {
                  if (error.response.status === 404) {
                    const errors = [
                      'There was 404 status on this request. Make sure the endpoint you are hitting exists!',
                    ];
                    setErrorMessages(errors);
                    setShowErrorScreen(true);
                  } else if (error.response.status === 500) {
                    const errors = [
                      'Yikes! 500 error. This means a rare exception came up. Please check Sentry or contact engineering.',
                    ];
                    setErrorMessages(errors);
                    setShowErrorScreen(true);
                  } else {
                    const errors: string[] = Object.keys(
                      error.response.data,
                    ).map((key: string) => {
                      const reducedError: string = error.response.data[
                        key
                      ].reduce((acc: string, cv: string) => {
                        return `${acc} ${cv}`;
                      }, '');
                      return `${decamelize(key, {
                        separator: ' ',
                      })}: ${reducedError}`;
                    });
                    setErrorMessages(errors);
                    setShowErrorScreen(true);
                  }
                });
            } else {
              if (initialValues && initialValues.id) {
                privateApi
                  .patch(`/election-items/${initialValues.id}/`, payload)
                  .then((response) => {
                    resetElectionsState(EditTypes.edit);
                    updateElection(response.data);
                    formikActions.resetForm();
                  })
                  .catch((error) => {
                    if (error.response.status === 404) {
                      const errors = [
                        'There was 404 status on this request. Make sure the endpoint you are hitting exists!',
                      ];
                      setErrorMessages(errors);
                      setShowErrorScreen(true);
                    } else if (error.response.status === 500) {
                      const errors = [
                        'Yikes! 500 error. This means a rare exception came up. Please check Sentry or contact engineering.',
                      ];
                      setErrorMessages(errors);
                      setShowErrorScreen(true);
                    } else {
                      const errors: string[] = Object.keys(
                        error.response.data,
                      ).map((key: string) => {
                        const reducedError: string = error.response.data[
                          key
                        ].reduce((acc: string, cv: string) => {
                          return `${acc} ${cv}`;
                        }, '');
                        return `${decamelize(key, {
                          separator: ' ',
                        })}: ${reducedError}`;
                      });
                      setErrorMessages(errors);
                      setShowErrorScreen(true);
                    }
                  });
              }
            }
          }
        }}
        validationSchema={(
          formikBag: FormikProps<IElectionItemsFormValues>,
        ) => {
          return Yup.object().shape({
            electionType: Yup.string().required(),
            securities: Yup.array().required(
              'You must include at least one cusip',
            ),
          });
        }}
        render={(props: FormikProps<IElectionItemsFormValues>) => (
          <Flex width={1} flexDirection="column">
            <Flex flexDirection="column" width={1}>
              <Flex flexDirection="row" justifyContent="space-between">
                <Box width={1 / 3}>
                  <ElectionTypeField />
                </Box>
                <Box width={1 / 3}>
                  <RecommendationType />
                </Box>
              </Flex>

              <Flex flexDirection="row" justifyContent="space-between">
                <Box width={1 / 2}>
                  <ProposalTitle />
                </Box>
                <Box width={1 / 3}>
                  <CusipTypeaheadWithoutApi
                    cusips={
                      isEditing && initialValues
                        ? initialValues.securities
                        : cusips
                    }
                  />
                </Box>
              </Flex>
              <Flex width={1}>
                <ProposalDetail />
              </Flex>
            </Flex>
            <SubmitContainer
              mt={45}
              width={1}
              pt={25}
              justifyContent="flex-end"
            >
              <OutlineButton
                children="Cancel"
                small={true}
                width={130}
                type="button"
                mr={15}
                onClick={(e) => {
                  e.preventDefault();
                  props.resetForm();
                  handleCancel();
                }}
              />
              <Button
                children="Save Proposal"
                small={true}
                width={130}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  props.submitForm();
                }}
              />
            </SubmitContainer>
          </Flex>
        )}
      />
      <ErrorModal
        isOpen={showErrorScreen}
        onClose={() => setShowErrorScreen(false)}
        errorMessages={errorMessages}
      />
    </Flex>
  );
};
