import React from 'react';
import { Grid } from '@material-ui/core';
import { DetailsForm } from './DetailsForm';
import { DetailsPane } from './DetailsPane';
import { RouteComponentProps } from '@reach/router';
import { FormikProps } from 'formik';
import { CurrentDisplay, FilingDetailsFormValues } from './types';
import { useProxy } from './useProxy';
import styled from 'styled-components';

type Props = RouteComponentProps<{ id: string }> & {
  formikBag: FormikProps<FilingDetailsFormValues>;
  additionalRecordDatesError: string;
};

function DetailsIndex({ id, formikBag, additionalRecordDatesError }: Props) {
  const { data } = useProxy(id);
  const [currentDisplay, setCurrentDisplay] = React.useState<CurrentDisplay>({
    itemType: null,
  });

  return (
    <GridWrapper container={true} spacing={3} justify="center">
      <Grid item xs={6}>
        <div id="ProxyDetailsFormTab">
          <DetailsForm
            data={data}
            formikBag={formikBag}
            additionalRecordDatesError={additionalRecordDatesError}
            currentDisplay={currentDisplay}
            setCurrentDisplay={setCurrentDisplay}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <div id="ProxyDetailsPane">
          <DetailsPane
            data={data}
            currentDisplay={currentDisplay}
            setCurrentDisplay={setCurrentDisplay}
          />
        </div>
      </Grid>
    </GridWrapper>
  );
}

const GridWrapper = styled(Grid)`
  padding: 24px 24px 0;
`;

export { DetailsIndex };
