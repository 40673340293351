import React from 'react';
import { Brokers } from '../../features/CorporateActions/types';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

type BrokerTagListProps = {
  brokers: Brokers[];
};

const BrokerTagList = ({ brokers }: BrokerTagListProps) => {
  const totalBrokers = brokers.length;
  const firstBroker = brokers[0];
  const remainingBrokersLength = brokers.length - 1;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  if (totalBrokers === 0) {
    return null;
  } else
    return (
      <div>
        {firstBroker.name}{' '}
        <Button
          size="small"
          style={{ backgroundColor: '#1a1a19', color: '#FFF' }}
          onClick={handleClick}
        >
          + {remainingBrokersLength}
        </Button>
        <Popover
          PaperProps={{ style: { padding: '12px' } }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
        >
          {brokers.map((broker) => (
            <p key={broker.id}>{broker.name}</p>
          ))}
        </Popover>
      </div>
    );
};

export { BrokerTagList };
