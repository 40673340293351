import React from 'react';
import { OperationsStatus } from './types';
import { StatusBadge } from './StatusBadge';

type FormStatusBarProps = {
  status: OperationsStatus;
  isSubmitting: boolean;
  isValid: boolean;
  actionButton?: React.ReactNode;
};

function FormStatusBar({
  status,
  isSubmitting,
  isValid,
  actionButton,
}: FormStatusBarProps) {
  return (
    <div className="border-b border-light-gray py-4">
      <div className="px-8 flex items-center justify-center relative">
        <div className="font-medium text-sm text-primary-text flex items-center">
          <span className="mr-1">Status:</span> <StatusBadge status={status} />
        </div>

        <div className="absolute flex items-center" style={{ right: '2rem' }}>
          <button
            className={`${
              isValid ? 'text-green' : 'text-light-gray'
            } font-medium text-sm`}
            type="submit"
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </button>

          {actionButton ? <div className="ml-8">{actionButton}</div> : null}
        </div>
      </div>
    </div>
  );
}

export { FormStatusBar };
