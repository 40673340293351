import { AxiosError } from 'axios';

function formatUpdateStatusError(error: AxiosError): string {
  if (error.response) {
    const errorData = error.response.data;

    if (errorData.error) {
      const errorSubstring = errorData.error.match(/'([^']+)'/);

      if (errorSubstring) {
        return errorSubstring;
      }

      return JSON.stringify(errorData);
    }

    return JSON.stringify(errorData);
  } else {
    return 'Unable to update status.';
  }
}

export { formatUpdateStatusError };
