import React from 'react';
import { Login } from '../Auth/Login';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { getTokenFromCookies } from '../utils/get-token';
import { GlobalStyles } from '../utils/global-styles';

const MeetingResultsRoute = ({ component: Component, ...rest }: any) => {
  const token = getTokenFromCookies();
  const decoded = jwt_decode(token) as any;
  const hasPermissions =
    decoded &&
    decoded.permissions &&
    decoded.permissions.find(
      (per: string) => per === 'admin_can_view_meeting_results',
    );

  return Cookies.get('authToken') && hasPermissions ? (
    <React.Fragment>
      <GlobalStyles />
      <Component {...rest} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <GlobalStyles />
      <Login />
    </React.Fragment>
  );
};

export { MeetingResultsRoute };
