import {
  Grid,
  MenuItem,
  Button,
  TextField as MaterialTextField,
  FormHelperText,
  Box,
} from '@material-ui/core';
import React from 'react';
import {
  Formik,
  Form,
  Field,
  FormikActions,
  FieldArray,
  FieldProps,
} from 'formik';
import { GroupedProposal } from './BallotDetails';
import { TextField } from 'formik-material-ui';
import { BallotDetailsProposalGroupRow } from './BallotDetailsProposalGroupRow';
import { VoteProposalPayload, VoteType } from '../types';
import styled from 'styled-components';
import { Add } from '@material-ui/icons';
import { privateApi } from '../../../old/utils/api-adapter';
import * as Yup from 'yup';
import { ProposalContextEditor } from './ProposalContextEditor';

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Type is required.'),
  title: Yup.string().required('Title is required.'),
  voteType: Yup.string().required('Vote type is required.'),
  routineness: Yup.string().required('Routineness is required.'),
  recommendationType: Yup.string().required('Recommendation type is required.'),
  proposals: Yup.array()
    .when('type', {
      is: 'BoardOfDirectorsNomination',
      then: Yup.array().of(
        Yup.object().shape({
          directorName: Yup.string().required('Director name is required.'),
        }),
      ),
      otherwise: Yup.array().of(
        Yup.object().shape({
          details: Yup.string().required('Details are required.'),
        }),
      ),
    })
    .min(1, 'At least 1 proposal is required.'),
});

type Props = {
  value: GroupedProposal;
  onCancelEditing: () => void;
  isNew: boolean;
  securities: number[];
  onSave: () => void;
  filingId: string;
};

type Values = GroupedProposal;

function BallotDetailsProposalForm({
  value,
  onCancelEditing,
  isNew,
  securities,
  onSave,
  filingId,
}: Props) {
  const initialValues = value || {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values: Values, { setSubmitting }: FormikActions<Values>) => {
        setSubmitting(true);
        let payloads: VoteProposalPayload[];

        if (values.type === 'BoardOfDirectorsNomination') {
          payloads = values.proposals.map((proposal, index) => ({
            id: proposal.id,
            isRoutine: values.routineness === 'nonRoutine' ? false : true,
            recommendationType: values.recommendationType,
            securities,
            title: values.title,
            type: values.type,
            voteType: values.voteType,
            directorName: proposal.directorName,
            proposalNumber: index + 1,
            tooltipText: proposal.tooltipText,
            groupNumber: values.groupNumber,
          }));
        } else {
          payloads = values.proposals.map((proposal, index) => ({
            id: proposal.id,
            isRoutine: values.routineness === 'nonRoutine' ? false : true,
            recommendationType: values.recommendationType,
            securities,
            title: values.title,
            type: values.type,
            voteType: values.voteType,
            details: proposal.details,
            proposalNumber: index + 1,
            tooltipText: proposal.tooltipText,
            groupNumber: values.groupNumber,
          }));
        }

        const apiCalls = payloads.map((payload: VoteProposalPayload) => {
          const { id, ...rest } = payload;
          return isFinite(id)
            ? privateApi.patch(`/vote-proposals/${id}/`, rest)
            : privateApi.post(`/vote-proposals/`, {
                ...rest,
                filing: filingId,
              });
        });

        Promise.all(apiCalls)
          .then(() => {
            setSubmitting(false);
          })
          .catch((error) => {
            setSubmitting(false);
            console.error(error.response);
          })
          .finally(onSave);
      }}
    >
      {({ values, setFieldValue, isSubmitting, submitForm, errors }) => (
        <Form>
          <BallotDetailsProposalGroupRow
            title={values.title}
            routineness={values.routineness}
            numberOfProposals={values.proposals.length}
            recommendationType={values.recommendationType}
            voteType={values.voteType}
            proposalType={values.type}
          />

          <FormFields>
            <Grid container={true} spacing={3}>
              {isNew && (
                <Grid item={true} xs={12}>
                  <Field
                    id="type"
                    name="type"
                    label="Proposal Type"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    component={TextField}
                    select={true}
                    required={true}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      const proposalType = event.target.value;
                      setFieldValue('proposals', []);

                      switch (proposalType) {
                        case 'BoardOfDirectorsNomination':
                          setFieldValue('routineness', 'nonRoutine');
                          setFieldValue('voteType', 'election_plurality');
                          setFieldValue('recommendationType', 'for');
                          setFieldValue('title', 'Election of Directors');
                          setFieldValue('type', proposalType);
                          break;
                        case 'ManagementProposalItem':
                          setFieldValue('routineness', 'nonRoutine');
                          setFieldValue('voteType', 'election_majority');
                          setFieldValue('recommendationType', 'for');
                          setFieldValue('type', proposalType);
                          setFieldValue('title', '');
                          break;
                        case 'ExecutiveCompensationProposalItem':
                          setFieldValue('routineness', 'nonRoutine');
                          setFieldValue('voteType', 'election_majority');
                          setFieldValue('recommendationType', 'for');
                          setFieldValue('title', 'Executive Compensation');
                          setFieldValue('type', proposalType);
                          break;
                        case 'AdjournmentProposalItem':
                          setFieldValue('routineness', 'nonRoutine');
                          setFieldValue('voteType', 'election_majority');
                          setFieldValue('recommendationType', 'for');
                          setFieldValue('title', 'Adjournment of Meeting');
                          setFieldValue('type', proposalType);
                          break;
                        case 'PlanOfMergerProposalItem':
                          setFieldValue('routineness', 'nonRoutine');
                          setFieldValue('voteType', 'election_majority');
                          setFieldValue('recommendationType', 'for');
                          setFieldValue('title', 'Plan of Merger');
                          setFieldValue('type', proposalType);
                          break;
                        case 'ShareholderProposalItem':
                          setFieldValue('routineness', 'nonRoutine');
                          setFieldValue('voteType', 'election_majority');
                          setFieldValue('recommendationType', 'against');
                          setFieldValue('type', proposalType);
                          setFieldValue('title', '');
                          break;
                        case 'RatificationOfAuditors':
                          setFieldValue('title', 'Ratification of Auditors');
                          setFieldValue('type', proposalType);
                          setFieldValue('routineness', 'routine');
                          setFieldValue('voteType', 'election_majority');
                          setFieldValue('recommendationType', 'for');

                          break;
                        case 'SayOnPay':
                          setFieldValue('title', 'Say on Pay');
                          setFieldValue('type', proposalType);
                          setFieldValue('routineness', 'nonRoutine');
                          setFieldValue('voteType', '');
                          setFieldValue('recommendationType', 'None');
                          break;
                        default:
                          setFieldValue('type', proposalType);
                          setFieldValue('title', '');
                          return;
                      }
                    }}
                  >
                    <MenuItem value="BoardOfDirectorsNomination">
                      Election of Directors
                    </MenuItem>
                    <MenuItem value="PlanOfMergerProposalItem">
                      Plan of Merger
                    </MenuItem>
                    <MenuItem value="AdjournmentProposalItem">
                      Adjournment of Meeting
                    </MenuItem>
                    <MenuItem value="ExecutiveCompensationProposalItem">
                      Executive Compensation
                    </MenuItem>
                    <MenuItem value="ManagementProposalItem">
                      Management Proposal
                    </MenuItem>
                    <MenuItem value="ShareholderProposalItem">
                      Shareholder Proposal
                    </MenuItem>
                    <MenuItem value="SayOnPay">Say on Pay</MenuItem>
                    <MenuItem value="RatificationOfAuditors">
                      Ratification of Auditors
                    </MenuItem>
                    <MenuItem value="Other">Other Proposal</MenuItem>
                  </Field>
                </Grid>
              )}

              {isNew && (
                <Grid item={true} xs={12}>
                  <Field
                    id="title"
                    name="title"
                    label="Proposal Title"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    required={true}
                    component={TextField}
                  />
                </Grid>
              )}

              <Grid item={true} xs={6}>
                {values.type === 'SayOnPay' ? (
                  <MaterialTextField
                    label="Vote Type"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    select={true}
                    required={true}
                    defaultValue="default"
                  >
                    <MenuItem value="default">
                      One year, Two years, Three years, Abstain
                    </MenuItem>
                  </MaterialTextField>
                ) : (
                  <Field
                    id="voteType"
                    name="voteType"
                    label="Vote Type"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    component={TextField}
                    select={true}
                    required={true}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      const voteType = event.target.value as VoteType;
                      setFieldValue('voteType', voteType);
                      const firstVoteChoice = getVoteChoices(voteType)[0];
                      setFieldValue('recommendationType', firstVoteChoice);
                    }}
                  >
                    <MenuItem value="election_majority_foragainst">
                      For, Against
                    </MenuItem>
                    <MenuItem value="election_majority_forabstain">
                      For, Abstain
                    </MenuItem>
                    <MenuItem value="election_majority">
                      For, Against, Abstain
                    </MenuItem>
                    <MenuItem value="election_majority_foragainstwithhold">
                      For, Against, Withhold
                    </MenuItem>
                    <MenuItem value="election_plurality">
                      For, Withhold
                    </MenuItem>
                    <MenuItem value="election_majority_forwithholdabstain">
                      For, Withhold, Abstain
                    </MenuItem>
                    <MenuItem value="election_majority_yesno">Yes, No</MenuItem>
                  </Field>
                )}
              </Grid>

              <Grid item={true} xs={6}>
                <Field
                  id="routineness"
                  name="routineness"
                  label="Routineness"
                  fullWidth={true}
                  variant="outlined"
                  size="small"
                  component={TextField}
                  required={true}
                  select={true}
                >
                  <MenuItem value="routine">Routine</MenuItem>
                  <MenuItem value="nonRoutine">Non-Routine</MenuItem>
                </Field>
              </Grid>

              <Grid item={true} xs={6}>
                <Field
                  id="recommendationType"
                  name="recommendationType"
                  label="Recommendation Type"
                  fullWidth={true}
                  variant="outlined"
                  size="small"
                  component={TextField}
                  required={true}
                  select={true}
                >
                  {getVoteChoices(values.voteType).map((voteChoice) => (
                    <MenuItem value={voteChoice} key={voteChoice}>
                      {voteChoiceMap[voteChoice]}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
          </FormFields>

          <ProposalRowTitle>
            {values.type === 'BoardOfDirectorsNomination'
              ? 'List of Director Nominees'
              : 'Proposal Details'}
          </ProposalRowTitle>

          <FieldArray
            name="proposals"
            render={(arrayHelpers) => (
              <div>
                {values.proposals.map((proposal, index) => (
                  <React.Fragment key={proposal.id}>
                    <ProposalDetailsOrDirectorName>
                      {values.type === 'BoardOfDirectorsNomination' ? (
                        <Field
                          id={`proposals.${index}.directorName`}
                          name={`proposals.${index}.directorName`}
                          placeholder="Director Name"
                          fullWidth={true}
                          size="small"
                          component={TextField}
                          required={true}
                          InputProps={{
                            disableUnderline: true,
                            style: { fontSize: '14px' },
                          }}
                        />
                      ) : (
                        <Field
                          id={`proposals.${index}.details`}
                          name={`proposals.${index}.details`}
                          placeholder="Details"
                          fullWidth={true}
                          size="small"
                          component={TextField}
                          required={true}
                          InputProps={{
                            disableUnderline: true,
                            style: { fontSize: '14px' },
                          }}
                        />
                      )}
                    </ProposalDetailsOrDirectorName>

                    <Field
                      id={`proposals.${index}.tooltipText`}
                      name={`proposals.${index}.tooltipText`}
                    >
                      {({ field }: FieldProps) => (
                        <ProposalContextEditor
                          value={field.value}
                          onChange={(value: any) =>
                            setFieldValue(
                              `proposals.${index}.tooltipText`,
                              value,
                            )
                          }
                        />
                      )}
                    </Field>
                  </React.Fragment>
                ))}

                {typeof errors.proposals === 'string' ? (
                  <Box width="100%" px={3} py={1}>
                    <FormHelperText error={true}>
                      {errors.proposals}
                    </FormHelperText>
                  </Box>
                ) : null}

                <AddDetailsOrDirectorButtonWrapper>
                  <AddDetailsOrDirectorButton
                    startIcon={<Add />}
                    onClick={() =>
                      arrayHelpers.push({
                        id: Infinity,
                        proposalNumber: Infinity,
                        tooltipText: [
                          {
                            children: [{ text: '' }],
                          },
                        ],
                        details: '',
                        directorName: '',
                      })
                    }
                  >
                    {values.type === 'BoardOfDirectorsNomination'
                      ? 'Director'
                      : 'Details'}
                  </AddDetailsOrDirectorButton>
                </AddDetailsOrDirectorButtonWrapper>
              </div>
            )}
          />

          <ActionButtons>
            <Button type="button" onClick={onCancelEditing}>
              Cancel
            </Button>
            <Button
              color="primary"
              type="button"
              disabled={isSubmitting}
              onClick={(event) => {
                event.stopPropagation();
                submitForm();
              }}
            >
              {isSubmitting ? 'Saving...' : 'Done'}
            </Button>
          </ActionButtons>
        </Form>
      )}
    </Formik>
  );
}

const voteChoiceMap: { [index: string]: string } = {
  abstain: 'Abstain',
  against: 'Against',
  for: 'For',
  no: 'No',
  None: 'None',
  one_year: 'One Year',
  three_years: 'Three Years',
  two_years: 'Two Years',
  withhold: 'Withhold',
  cash: 'Cash',
  stock: 'Stock',
  mixed: 'Mixed',
  yes: 'Yes',
};

function getVoteChoices(voteType: VoteType) {
  switch (voteType) {
    case 'election_majority':
      return ['for', 'against', 'abstain'];
    case 'election_majority_forabstain':
      return ['for', 'abstain'];
    case 'election_majority_foragainst':
      return ['for', 'against'];
    case 'election_majority_foragainstwithhold':
      return ['for', 'against', 'withhold'];
    case 'election_majority_forwithholdabstain':
      return ['for', 'withhold', 'abstain'];
    case 'election_majority_yesno':
      return ['yes', 'no'];
    case 'election_plurality':
      return ['for', 'withhold'];
    default:
      return ['none'];
  }
}

const FormFields = styled.div`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const ProposalRowTitle = styled.div`
  padding: 14px 24px;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const ProposalDetailsOrDirectorName = styled.div`
  padding: 11px 24px 7px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const AddDetailsOrDirectorButton = styled(Button)`
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
`;

const AddDetailsOrDirectorButtonWrapper = styled.div`
  padding: 8px 16px;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 16px;
`;

export { BallotDetailsProposalForm };
