import { IVoteProposal } from '../models/vote-proposal';

export const groupProposals = (
  proposals: IVoteProposal[],
): IVoteProposal[][] => {
  const groupedProposalsObj: {
    [key: number]: IVoteProposal[];
  } = (proposals as IVoteProposal[]).reduce(
    (acc: { [key: number]: IVoteProposal[] }, cv: IVoteProposal) => {
      acc[cv.groupNumber]
        ? acc[cv.groupNumber].push(cv)
        : (acc[cv.groupNumber] = new Array(cv));
      return acc;
    },
    {} as { [key: number]: IVoteProposal[] },
  );

  return Object.keys(groupedProposalsObj)
    .sort((a: string, b: string) => parseInt(a, 10) - parseInt(b, 10))
    .map((key: string, i: number) => {
      return groupedProposalsObj[parseInt(key, 10)].sort(
        (a: IVoteProposal, b: IVoteProposal) =>
          a.proposalNumber - b.proposalNumber,
      );
    });
};
