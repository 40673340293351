export enum TimeZone {
  USAtlantic = 'Atlantic/Bermuda',
  USAlaska = 'US/Alaska',
  USArizona = 'US/Arizona',
  USCentral = 'US/Central',
  USEastern = 'US/Eastern',
  USHawaii = 'US/Hawaii',
  USMountain = 'US/Mountain',
  USPacific = 'US/Pacific',
  AsiaJerusalem = 'Asia/Jerusalem',
  AsiaShanghai = 'Asia/Shanghai',
  AsiaSeoul = 'Asia/Seoul',
  EuropeAmsterdam = 'Europe/Amsterdam',
  EuropeBerlin = 'Europe/Berlin',
  EuropeLondon = 'Europe/London',
  EuropeMadrid = 'Europe/Madrid',
  EuropeParis = 'Europe/Paris',
  EuropeRome = 'Europe/Rome',
  EuropeAthens = 'Europe/Athens',
}
