import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Raven from 'raven-js';

Raven.config('https://e66d881abf434aae94cb217119b389e4@sentry.io/303033', {
  environment: process.env.NODE_ENV,
}).install();

const Root = (): JSX.Element => <App />;

ReactDOM.render(<Root />, document.getElementById('root'));
