import React from 'react';
import { Link } from '@reach/router';
import { FaArrowLeft } from 'react-icons/fa';

type BackArrowProps = {
  to: string;
};

function BackArrow({ to }: BackArrowProps) {
  return (
    <Link
      to={to}
      className="inline-flex items-center font-xs font-medium text-primary-text hover:no-underline hover:text-green"
    >
      <FaArrowLeft className="mr-2" /> Back
    </Link>
  );
}

export { BackArrow };
