import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { Label } from '../Label';
import { Select } from '../Select';
import { InputGroup } from '../InputGroup';
import { IFilingCreateValues } from '../../models/filing-form';
import { CorporateActionType as CorporateActionTypeValues } from '../../models/corporate-action-type';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { Asterisk } from '../Asterik/Asterik';
import { ErrorLabel } from '../ErrorLabel';

interface CorporateActionTypeProps {
  isRequired?: boolean;
}

export const CorporateActionType: FC<CorporateActionTypeProps> = ({
  isRequired = false,
}) => {
  const corporateActionTypes = Object.entries(CorporateActionTypeValues);

  return (
    <Field
      name="actionType"
      render={({
        field,
        form,
      }: FieldProps<IFilingCreateValues | FilingDetailsFormValues>) => (
        <InputGroup>
          <Label htmlFor="corporate-action-type">
            Corporate Action Type {isRequired && <Asterisk />}
            {form.errors['actionType'] && (
              <ErrorLabel>{form.errors['actionType']}</ErrorLabel>
            )}
          </Label>

          <Select required={true} id="corporate-action-type" {...field}>
            <option value="" disabled={true}>
              Select an Action Type
            </option>

            {corporateActionTypes.map((corporateActionType) => {
              const [symbol, name] = corporateActionType;

              return (
                <option key={symbol} value={symbol}>
                  {name}
                </option>
              );
            })}
          </Select>
        </InputGroup>
      )}
    />
  );
};
