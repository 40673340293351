import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import useSWR from 'swr';
import { LoadingState } from '../../../components/LoadingState';
import { hubApi } from '../../../old/data/hubApiConfig';
import { PageWrapper } from '../../../components/PageWrapper';
import { EditableProspectusList } from '../EditableProspectusList';
import { ArchivedProspectusList } from '../ArchivedProspectusList';
import { StatusTabs } from './StatusTabs';

type Response = {
  id: string;
  name: string;
  issuerType: string;
  securities: Security[];
};

export type Security = {
  id: string;
  cusip: string;
  ticker: string | null;
  name: string;
  securityType: string;
  price: null | string;
  priceLastUpdated: null | string;
  primaryColor: null | string;
  iconUrl: null | string;
  issuer: Issuer;
  prospectuses: Prospectuses;
  stats: SecurityStats;
};

type SecurityStats = {
  onHoldTrades: number;
  monthlyTrades: number;
};

type Issuer = {
  id: string;
  name: string;
  issuerType: string;
  fiscalYearEnd: null | string;
  fundOwner: null | string;
  cik: null | string;
};

type Prospectuses = {
  active: Prospectus[];
  upcoming: Prospectus[];
  archived: Prospectus[];
};

type Prospectus = {
  id: string;
  type: ProspectusType;
  name: string;
  frontLink: string;
  website: string;
  attachment: string;
  securities: string[];
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  description: string;
};

type ProspectusType =
  | 'summary'
  | 'sticker'
  | 'statement_of_additional_information'
  | 'statutory';

export type ProspectusStatus = 'active' | 'upcoming' | 'archived';

const issuerFetcher = (url: string) => hubApi.get<Response>(url);

function Prospectus({ id }: RouteComponentProps<{ id: string }>) {
  const { data, error } = useSWR(
    `/admin/issuers/${id}/prospectus/`,
    issuerFetcher,
  );

  const getCount = (status: ProspectusStatus) => {
    if (data) {
      return data.data.securities.reduce(
        (acc, curr) => acc + curr.prospectuses[status].length,
        0,
      );
    }

    return 0;
  };

  if (!error && !data)
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );

  if (error) return <PageWrapper>Error...</PageWrapper>;

  if (data) {
    return (
      <div>
        <StatusTabs
          counts={{
            active: getCount('active'),
            archived: getCount('archived'),
            upcoming: getCount('upcoming'),
          }}
        />
        <PageWrapper>
          <Router>
            <EditableProspectusList
              path="/"
              securities={data.data.securities}
              status="active"
            />
            <EditableProspectusList
              path="upcoming"
              securities={data.data.securities}
              status="upcoming"
            />
            <ArchivedProspectusList
              path="archived"
              securities={data.data.securities}
            />
          </Router>
        </PageWrapper>
      </div>
    );
  }

  return null;
}

export { Prospectus };
