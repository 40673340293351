import React, { FC, useMemo } from 'react';
import { ReactComponent as SortIcon } from './sort.svg';
import { ReactComponent as SortDesc } from './sort-up.svg';
import { ReactComponent as SortAsc } from './sort-down.svg';
import { ReactComponent as Customer } from '../Icons/customer.svg';

type TableHeadCellProps = {
  name: string;
  align?: 'right' | 'left';
  orderingKey?: string;
  onOrderingClick?: (ordering: string) => void;
  currentOrdering?: string;
};

type SortDirection = 'asc' | 'desc' | 'none' | null;

export const TableHeadCell: FC<TableHeadCellProps> = ({
  align = 'left',
  name,
  orderingKey = '',
  onOrderingClick,
  currentOrdering,
}) => {
  const sortDirection: SortDirection = useMemo(() => {
    if (currentOrdering) {
      if (currentOrdering.includes(orderingKey)) {
        if (currentOrdering.startsWith('-')) {
          return 'desc';
        }

        return 'asc';
      }

      return 'none';
    }

    return null;
  }, [currentOrdering, orderingKey]);

  const handleOrderingClick = () => {
    if (onOrderingClick) {
      switch (sortDirection) {
        case 'desc':
          return onOrderingClick(orderingKey);
        case 'asc':
        default:
          return onOrderingClick(`-${orderingKey}`);
      }
    }
  };

  return (
    <th
      key={name}
      className={`text-xs font-medium uppercase px-4 py-2 whitespace-no-wrap text-secondary-text ${
        align === 'right' ? 'text-right' : 'text-left'
      }`}
    >
      <span>
        {name === 'Positions' ? <Customer className="inline" /> : name}
      </span>
      {orderingKey && (
        <button className="ml-2" onClick={() => handleOrderingClick()}>
          {sortDirection === 'none' && <SortIcon className="inline" />}
          {sortDirection === 'asc' && <SortDesc className="inline" />}
          {sortDirection === 'desc' && <SortAsc className="inline" />}
        </button>
      )}
    </th>
  );
};
