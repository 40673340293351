import Cookies from 'js-cookie';

const setAuthToken = (token: string) => {
  const domainParts = window.location.hostname.split('.');
  const topDomain = domainParts.slice(-2).join('.');
  const domain =
    process.env.NODE_ENV === 'production'
      ? topDomain
      : window.location.hostname;

  Cookies.set('authToken', token, { path: '/', domain, sameSite: 'strict' });
  return;
};

export { setAuthToken };
