import React from 'react';
import { PageHeader } from '../../components/PageHeader';
import { Tabs, Tab, Box } from '@material-ui/core';
import { useNavigate, useLocation } from '@reach/router';
import { useTasks } from './useTasks';
import numeral from 'numeral';
import { getCurrentTabValueFromUrl } from './getCurrentTabValueFromUrl';
import { SimpleSnackbar } from '../../components/Snackbar';
import {
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from './batch-filings-context';
import styled from 'styled-components';

type LayoutProps = {
  children: React.ReactNode;
  path: string;
};

const TaskListLayout = ({ children }: LayoutProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { drafts, ready, review } = useTasks();
  const draftsCount = drafts.data ? drafts.data.data.count : 0;
  const readyCount = ready.data ? ready.data.data.count : 0;
  const reviewCount = review.data ? review.data.data.count : 0;
  const batchFilings = useBatchFilingsState();
  const dispatch = useBatchFilingsDispatch();

  function handleChange(_: unknown, value: string) {
    navigate(`/proxy/tasks/${value}`);
  }

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({ type: 'CLOSE_MESSAGE' });
    dispatch({ type: 'RESET_BATCH' });
  };

  return (
    <React.Fragment>
      <PageHeader product="px" />

      <Box
        px={3}
        borderColor="divider"
        border={1}
        borderLeft={0}
        borderTop={0}
        borderRight={0}
      >
        <Tabs
          onChange={handleChange}
          indicatorColor="primary"
          value={getCurrentTabValueFromUrl(location)}
        >
          <StyledTab
            label={`Drafts (${formatCount(draftsCount)})`}
            value="drafts"
          />
          <StyledTab
            label={`Ready (${formatCount(readyCount)})`}
            value="ready"
          />
          <StyledTab
            label={`Review (${formatCount(reviewCount)})`}
            value="review"
          />
        </Tabs>
      </Box>

      {children}

      <SimpleSnackbar
        open={batchFilings.message.length > 0}
        message={batchFilings.message}
        handleClose={handleCloseSnackbar}
      />
    </React.Fragment>
  );
};

function formatCount(count: number) {
  return numeral(count).format('0,0');
}

const StyledTab = styled(Tab)`
  font-size: 12px;
`;

export { TaskListLayout };
