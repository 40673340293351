import { FilingType } from '../models/filing-type';
import { formatDate } from './format-date';

export const formatMeetingDateCell = (
  filingType: FilingType,
  meetingDate?: Date | null,
) => {
  switch (filingType) {
    case 'MeetingContest':
    case 'FundMeeting':
    case 'FirmMeeting':
      return meetingDate ? formatDate(meetingDate) : '';
    default:
      return 'N/A';
  }
};
