import { createContainer } from 'unstated-next';
import { useImmer } from 'use-immer';
import { toast } from 'react-toastify';
import qs from 'query-string';

import { SECResultsResponse, ISECFilingList } from '../models/sec-filing-list';
import { privateApi } from '../utils/api-adapter';
import { ISECFiling } from '../models/sec-filing';

export enum TabChoice {
  Pending = 'pending',
  Confirmed = 'confirmed',
  Dismissed = 'dismissed',
}

interface SECFilingsState {
  pending: {
    results: SECResultsResponse;
  };
  confirmed: {
    results: SECResultsResponse;
  };
  dismissed: {
    results: SECResultsResponse;
  };
  isLoading: boolean;
  hasData: boolean;
  search: string;
}

const initialSECFilingsState: SECFilingsState = {
  pending: {
    results: {
      count: 0,
      next: null,
      previous: null,
      results: [] as ISECFilingList[],
    },
  },
  confirmed: {
    results: {
      count: 0,
      next: null,
      previous: null,
      results: [] as ISECFilingList[],
    },
  },
  dismissed: {
    results: {
      count: 0,
      next: null,
      previous: null,
      results: [] as ISECFilingList[],
    },
  },
  isLoading: false,
  hasData: false,
  search: '',
};

export interface IMeetingResultsPayload {
  voteProposal: number;
  choice: string;
  totalShares: number;
}

const useAllResults = () => {
  const [allResults, updateAllResults] = useImmer<SECFilingsState>(
    initialSECFilingsState,
  );
  const [SECFiling, updateSECFiling] = useImmer<ISECFiling | null>(null);

  const updateIsLoading = (isLoading: boolean) => {
    updateAllResults((draft) => {
      draft.isLoading = isLoading;
    });
  };

  const getSECFilingById = async (id: string) => {
    updateIsLoading(true);
    try {
      const secResponse = await privateApi.get(`/admin/sec-filings/${id}/`);
      updateSECFiling((draft) => (draft = secResponse.data));
      updateIsLoading(false);
    } catch (error) {
      toast.error("Couldn't find this SEC filing");
      updateIsLoading(false);
    }
  };

  const getResultsByTab = async (tab: TabChoice, order: string) => {
    const orderingQuery = `&ordering=${order}`;
    const filter = '&type=8-K';

    try {
      updateIsLoading(true);
      const resultsResponse = await privateApi.get(
        `/admin/sec-filings/?status=${tab}${orderingQuery}${filter}`,
      );
      updateAllResults((draft) => {
        draft[tab as TabChoice].results = resultsResponse.data;
        draft.isLoading = false;
        draft.hasData = true;
      });
    } catch (error) {
      updateIsLoading(false);
      toast.error("We couldn't those, man");
    }
  };

  const changePage = async (path: string) => {
    const { tab } = qs.parse(path);
    const url = path.split('/v1')[1];

    try {
      updateIsLoading(true);
      const { data } = await privateApi.get(url);
      updateAllResults((draft) => {
        draft[tab as TabChoice].results = data;
        draft.isLoading = false;
        draft.hasData = true;
      });
    } catch (error) {
      updateIsLoading(false);
      toast.error(error);
    }
  };

  const setSearch = (search: string) =>
    updateAllResults((draft) => {
      draft.search = search;
    });

  const postMeetingResults: any = async (payload: IMeetingResultsPayload) => {
    try {
      const response = await privateApi.post(`admin/meeting-results/`, payload);
      return response;
    } catch (error) {
      toast.error(error);
    }
  };

  const patchMeetingResults: any = async (
    payload: IMeetingResultsPayload,
    id: number,
  ) => {
    try {
      const response = await privateApi.patch(
        `admin/meeting-results/${id}`,
        payload,
      );
      return response;
    } catch (error) {
      toast.error(error);
    }
  };

  return {
    allResults,
    changePage,
    getResultsByTab,
    setSearch,
    SECFiling,
    getSECFilingById,
    postMeetingResults,
    patchMeetingResults,
  };
};

const AllResults = createContainer(useAllResults);

export { AllResults, useAllResults };
