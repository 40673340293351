import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { Loading } from '../Loading/Loading';

interface TableProps {
  columns: TableColumnsProps[];
  isLoading?: boolean;
  onSetOrdering: (order: string) => void;
  ordering: string;
  top?: number;
  variant?: string;
}

export interface TableColumnsProps {
  align?: Align;
  key: string;
  name: string;
  orderingKey?: string;
}

type Align = 'left' | 'center' | 'right';

const Table: FC<TableProps> = ({
  columns,
  children,
  onSetOrdering,
  ordering,
  top = 177,
  variant = 'gray',
  isLoading,
}) => (
  <StyledTable>
    <thead>
      <tr>
        <TableHeadCells
          data={columns}
          onSetOrdering={onSetOrdering}
          ordering={ordering}
          top={top}
          variant={variant}
        />
      </tr>
    </thead>
    <tbody>
      {isLoading ? (
        <tr>
          <td colSpan={columns.length}>
            <Loading />
          </td>
        </tr>
      ) : (
        <>{children}</>
      )}
    </tbody>
  </StyledTable>
);

interface TableHeadCellsProps {
  data: TableColumnsProps[];
  onSetOrdering: (order: string) => void;
  ordering: string;
  top?: number;
  variant: string;
}

const TableHeadCells: FC<TableHeadCellsProps> = ({
  data,
  onSetOrdering,
  ordering,
  top,
  variant,
}) => {
  return (
    <Fragment>
      {data.map((column) => (
        <TableHeadCell key={column.key} top={top} variant={variant}>
          <TableHeadCellWrapper
            align={column.align}
            onClick={() => {
              if (column.orderingKey) {
                if (ordering.includes(column.orderingKey)) {
                  if (ordering.includes('-')) {
                    return onSetOrdering(column.orderingKey);
                  } else {
                    return onSetOrdering(`-${column.orderingKey}`);
                  }
                } else {
                  return onSetOrdering(column.orderingKey);
                }
              } else {
                return;
              }
            }}
            orderingKey={column.orderingKey}
          >
            {column.name}{' '}
            {column.orderingKey && (
              <OrderingArrows
                ordering={ordering}
                orderingKey={column.orderingKey}
              />
            )}
          </TableHeadCellWrapper>
        </TableHeadCell>
      ))}
    </Fragment>
  );
};

interface OrderingArrowsProps {
  ordering: string;
  orderingKey: string;
}

const OrderingArrows: FC<OrderingArrowsProps> = ({ ordering, orderingKey }) => {
  const isOrdered = ordering.includes(orderingKey);
  const isAscending = isOrdered && !ordering.includes('-');
  const isDescending = isOrdered && ordering.includes('-');

  return (
    <OrderingArrowsWrapper>
      <OrderingArrow isActive={isAscending}>&#x25B2;</OrderingArrow>{' '}
      <OrderingArrow isActive={isDescending}>&#x25BC;</OrderingArrow>
    </OrderingArrowsWrapper>
  );
};

export default Table;

// eslint-disable-next-line
const TableHeadCellWrapper = styled.div<{
  orderingKey?: string;
  align?: Align;
}>`
  align-items: center;
  cursor: ${({ orderingKey }) => (orderingKey ? 'pointer' : 'normal')};
  display: flex;
  justify-content: ${({ align }) => {
    switch (align) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  }};
`;

const OrderingArrowsWrapper = styled.div`
  display: grid;
  font-size: 8px;
  margin-left: 8px;
`;

const OrderingArrow = styled.span<{ isActive: boolean }>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
`;

const StyledTable = styled.table`
  background: white;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
`;

const TableHeadCell = styled.th<{ top?: number; variant: string }>`
  color: ${({ theme, variant }) =>
    variant === 'blue' ? theme.colors.white : theme.colors.black};
  background: ${({ variant, theme }) => {
    switch (variant) {
      case 'gold':
        return theme.colors.gold;
      case 'blue':
        return theme.colors.blue;
      case 'green':
        return theme.colors.green;
      case 'gray':
        return '#f2f2f2';
      default:
        return '#f2f2f2';
    }
  }};
  padding: 8px;
  position: ${({ top }) => (top ? 'sticky' : 'relative')};
  top: ${({ top }) => (top ? `${top}px` : 'none')};
  vertical-align: middle;
`;
