import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';

type SearchInput = {
  onChange: (value: string) => void;
  onClear: () => void;
  value: string;
  setIsSearchWide: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icons: {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    search: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    inputRoot: {
      width: '360px',
      height: '44px',
      background: '#fff',
      padding: '0 16px',
      color: 'inherit',
    },
    inputInput: {
      color: 'inherit',
      paddingLeft: '12px',
      transition: theme.transitions.create('backgroundColor'),
      '&:focus': {
        backgroundColor: '#FFFFFF',
      },
    },
  }),
);

const SearchInput = ({
  onChange,
  value,
  onClear,
  setIsSearchWide,
}: SearchInput) => {
  const classes = useStyles();
  return (
    <div className={classes.search}>
      <InputBase
        placeholder="Search by issuer name or CUSIP"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={(event) => onChange(event.currentTarget.value)}
        onFocus={() => setIsSearchWide(true)}
        onBlur={() => setIsSearchWide(false)}
        startAdornment={
          <InputAdornment disablePointerEvents position="start">
            <IconButton edge="start">
              <SearchIcon className={classes.icons} />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {value ? (
              <IconButton
                onClick={() => onClear()}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
              >
                <ClearIcon className={classes.icons} />
              </IconButton>
            ) : (
              ''
            )}
          </InputAdornment>
        }
      />
    </div>
  );
};

export { SearchInput };
