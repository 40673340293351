import { createContainer } from 'unstated-next';
import { useImmer } from 'use-immer';
import { FilingListResponse, FilingListFiling } from '../models/filing-list';
import { privateApi } from '../utils/api-adapter';
import { toast } from 'react-toastify';
import qs from 'query-string';
import { FilingType } from '../models/filing-type';

export enum TabChoice {
  Closed = 'closed',
  Open = 'open',
}

interface AllFilingsState {
  open: {
    filings: FilingListResponse;
  };
  closed: {
    filings: FilingListResponse;
  };
  isLoading: boolean;
  hasData: boolean;
  filingTypeFilter: FilingType | '';
  search: string;
  isNewFilingDialogOpen: boolean;
}

const initialAllFilingsState: AllFilingsState = {
  open: {
    filings: {
      count: 0,
      next: null,
      previous: null,
      results: [] as FilingListFiling[],
    },
  },
  closed: {
    filings: {
      count: 0,
      next: null,
      previous: null,
      results: [] as FilingListFiling[],
    },
  },
  isLoading: false,
  hasData: false,
  filingTypeFilter: '',
  search: '',
  isNewFilingDialogOpen: false,
};

const useAllFilings = () => {
  const [allFilings, updateAllFilings] = useImmer<AllFilingsState>(
    initialAllFilingsState,
  );

  const updateIsLoading = (isLoading: boolean) => {
    updateAllFilings((draft) => {
      draft.isLoading = isLoading;
    });
  };

  const getFilingsByTab = async (
    tab: TabChoice,
    order: string,
    searchTerm: string,
    filingType: FilingType | '',
  ) => {
    const searchQuery = searchTerm
      ? `&search=${encodeURIComponent(searchTerm)}`
      : '';
    const orderingQuery = `&ordering=${order}`;
    const filingTypeQuery = filingType
      ? `&filing_type=${filingType.toLowerCase()}`
      : '';
    try {
      updateIsLoading(true);
      const filingsReponse = await privateApi.get(
        `/filings/?tab=${tab}${searchQuery}${orderingQuery}${filingTypeQuery}`,
      );
      updateAllFilings((draft) => {
        draft[tab as TabChoice].filings = filingsReponse.data;
        draft.isLoading = false;
        draft.hasData = true;
      });
    } catch (error) {
      updateIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
        return;
      }

      console.error(error);
    }
  };

  const changePage = async (path: string) => {
    const { tab } = qs.parse(path);
    const url = path.split('/v1')[1];

    try {
      updateIsLoading(true);
      const { data } = await privateApi.get(url);
      updateAllFilings((draft) => {
        draft[tab as TabChoice].filings = data;
        draft.isLoading = false;
        draft.hasData = true;
      });
    } catch (error) {
      updateIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
        return;
      }

      console.error(error);
    }
  };

  const setFilingTypeFilter = (filingType: FilingType | '') =>
    updateAllFilings((draft) => {
      draft.filingTypeFilter = filingType;
    });

  const setSearch = (search: string) =>
    updateAllFilings((draft) => {
      draft.search = search;
    });

  const setIsNewFilingDialogOpen = (isOpen: boolean) =>
    updateAllFilings((draft) => {
      draft.isNewFilingDialogOpen = isOpen;
    });

  return {
    allFilings,
    changePage,
    getFilingsByTab,
    setFilingTypeFilter,
    setSearch,
    setIsNewFilingDialogOpen,
  };
};

const AllFilings = createContainer(useAllFilings);

export { AllFilings, useAllFilings };
