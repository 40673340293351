import React, { FC, ReactNode, useState } from 'react';
import moment from 'moment';
import { Callout, Intent } from '@blueprintjs/core';
import { navigate } from '@reach/router';
import { Box, Flex } from '@rebass/grid';
import { Formik, FormikProps, FormikActions, Field, FieldProps } from 'formik';
import * as Yup from 'yup';
import {
  IFilingCreateValues,
  MeetingType,
  ReportType,
  DeliveryMethod,
} from '../models/filing-form';
import {
  IssuerTypeahead,
  FundOwner,
  CusipTypeahead,
  BrokerSearchReceivedDate,
  RecordDate,
  MaterialsExpectedDate,
  DeliveryMethodField,
  MeetingDate,
  FilingTypeSelect,
  SolicitorTypeahead,
  ReplyToTypeahead,
  ReportTypeField,
  InvoicerAddressFields,
  MeetingTypeField,
  TabulatorTypeahead,
  SendToTypeahead,
  ContestingEntity,
  Notes,
  CorporateActionType,
} from '../common/FormComponents';
import { FilingType as Type, FilingType } from '../models/filing-type';
import { OutlineButton } from '../common/Buttons/OutlineButton';
import { Button } from '../common/Buttons/Button';
import styled from 'styled-components/macro';
import { Loading } from '../common/Loading/Loading';
import { privateApi } from '../utils/api-adapter';
import { ICompanyContact } from '../models/intermediaries';
import { SolicitorJobNumber } from '../common/FormComponents/SolicitorJobNumber';
import { GenericDate } from '../common/FormComponents/GenericDate';
import { InputGroup } from '../common/InputGroup';
import { Label } from '../common/Label';
import { Input } from '../common/Input';
import { Asterisk } from '../common/Asterik/Asterik';
import { ErrorLabel } from '../common/ErrorLabel';
import { CorporateActionType as ActionType } from '../models/corporate-action-type';
import { toast } from 'react-toastify';
import { Textarea } from '../common/Textarea';
import { Location } from '../data/Location.Container';
import { BrokerTypeahead } from '../common/FormComponents/BrokerTypeahead';
import { BrokerChangesDialog } from '../Filing/BrokerChangesDialog';
import { IBroker } from '../EmailPreview/EmailPreview';
import { RecordDateCompare } from '../common/FormComponents/RecordDateCompare';
import { AdditionalRecordDates } from '../common/FormComponents/AdditionalRecordDates';
import { userIdEvent } from '../utils/analytics-helpers';
import { InvoicerContactEmail } from '../common/FormComponents/InvoicerContactEmail';

const Form = styled.div`
  width: 100%;
  height: 100%;
`;

const InputGroupRow = styled(InputGroup)`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 32px;

  &:first-of-type {
    margin-left: 0;
  }
`;

const InlineLabel = styled(Label)`
  margin-bottom: 0;
  margin-right: 8px;
`;

const WarningLabel = styled(ErrorLabel)`
  color: orange;
`;

enum RecordDateView {
  SingleDate,
  DateRange,
}

interface IProps {
  onClose: () => void;
}

const calculateBackButtonPath = () => {
  if (
    window.location.pathname.includes('/old/filings/proxy') ||
    window.location.pathname.includes('/old/filings/all') ||
    window.location.pathname.includes('/old/filings/corporate-actions')
  ) {
    return window.location.pathname;
  }

  return `/old/filings/all/open`;
};

export const SingleFiling: FC<IProps> = ({ onClose }) => {
  const { setBackButtonPath } = Location.useContainer();
  const [existingFiling, setExistingFiling] = useState<boolean>(false);
  const [existingFilingId, setExistingFilingId] = useState<number>(NaN);
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);
  const [additionalRecordDatesError, setAdditionalRecordDatesError] = useState<
    string
  >('');
  const [recordDateView, updateRecordDateView] = useState<RecordDateView>(
    RecordDateView.SingleDate,
  );
  const [isBrokerChangesDialogOpen, toggleBrokerChangesDialog] = useState<
    boolean
  >(false);

  const submitFiling = async (
    payload: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      await privateApi.post('/filings/', payload);
      setExistingFiling(false);
      setExistingFilingId(NaN);
      onClose();
      setSubmitting(false);
      setBackButtonPath(calculateBackButtonPath());
      toast.success('Your filing was saved!');
      navigate('/proxy/tasks/drafts');
    } catch (error) {
      if (error.response) {
        userIdEvent('Create Filing Unsuccessful', {
          validationError: error.response.data.nonFieldErrors[0],
        });
        toast.error(error.response.data.nonFieldErrors[0]);
      }

      setSubmitting(false);
    }
  };

  const checkIfFilingExists = async (
    type: Type,
    issuerId: number,
    recordDate: string,
    payload: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      await privateApi.post(`/filings/check/`, {
        filingType: type,
        issuer: issuerId,
        recordDate,
      });
      submitFiling(payload, setSubmitting);
    } catch (error) {
      setSubmitting(false);
      if (error.response.status === 400) {
        const errors = error.response.data.nonFieldErrors;

        if (errors) {
          const filingAlreadyExistsErrorIndex = errors.findIndex(
            (error: string) => error.includes('already exists'),
          );

          if (filingAlreadyExistsErrorIndex >= 0) {
            const existingFilingIdFromResponse = errors[
              filingAlreadyExistsErrorIndex
            ].match(/\d+/)[0];
            setExistingFiling(true);
            setExistingFilingId(existingFilingIdFromResponse);
          }
        }
      }
    }
  };

  const createProxyFiling = (
    values: IFilingCreateValues,
    formikActions: FormikActions<IFilingCreateValues>,
  ) => {
    if (values.issuer) {
      const {
        solicitor,
        tabulator,
        replyTo,
        sendTo,
        issuer,
        cusips,
        ...theRest
      } = values;

      const securityIds = cusips.map((cusip) => cusip.id);
      const payload: any = {
        ...theRest,
        companyContactIds: [],
        issuerId: issuer.id,
        meeting: {
          date: values.meetingDate,
          type: values.meetingType,
        },
        securityIds,
      };

      formikActions.setSubmitting(true);

      if (solicitor) {
        payload.solicitorId = solicitor.id;
      }
      if (tabulator) {
        payload.tabulatorId = tabulator.id;
      }
      if (replyTo) {
        const ids = replyTo.map((contact) => contact.id);
        payload.companyContactIds = [...payload.companyContactIds, ...ids];
      }
      if (sendTo) {
        const ids = sendTo.map((contact) => contact.id);
        payload.companyContactIds = [...payload.companyContactIds, ...ids];
      }

      if (
        values.type === Type.FirmMeeting ||
        values.type === Type.FundMeeting ||
        values.type === Type.FirmConsentSolicitation
      ) {
        if (values.meetingDate) {
          payload.voteCutoffDate = moment(values.meetingDate)
            .subtract(1, 'days')
            .format('YYYY-MM-DD');
        }
        payload.voteCutoffTime = '23:59';
        payload.voteCutoffTimezone = 'US/Eastern';
      } else if (values.type === Type.MeetingContest) {
        if (values.relatedFiling && values.relatedFiling.meetingDate) {
          payload.voteCutoffDate = values.relatedFiling.meetingDate;
        }
        payload.voteCutoffTime = '23:59';
        payload.voteCutoffTimezone = 'US/Eastern';
      }

      if (values.recordDateCompare) {
        payload.additionalRecordDates = values.additionalRecordDates;
      }

      if (!existingFiling && !existingFilingId) {
        checkIfFilingExists(
          values.type,
          issuer.id,
          values.recordDate ? values.recordDate : '',
          payload,
          formikActions.setSubmitting,
        );
      } else {
        submitFiling(payload, formikActions.setSubmitting);
      }
    }
  };

  const createCAFiling = (
    values: IFilingCreateValues,
    formikActions: FormikActions<IFilingCreateValues>,
  ) => {
    const {
      actionDetail,
      actionType,
      cusips,
      dtcExpirationDate,
      electionCutoffDate,
      electionCutoffTime,
      expirationDate,
      informationAgent,
      informationAgentEmail,
      issuer,
      notes,
      notificationReceivedDate,
      recordDateEnd,
      recordDateStart,
      type,
      brokers,
    } = values;

    const securityIds = cusips.map((cusip) => cusip.id);
    let brokerIds: { broker: number }[] = [] as { broker: number }[];

    if (brokers) {
      brokerIds = brokers.map((broker: IBroker) => {
        return { broker: broker.id };
      });
    }

    if (issuer) {
      const payload: any = {
        actionDetail,
        actionType,
        dtcExpirationDate,
        electionCutoffDate,
        electionCutoffTime,
        expirationDate,
        informationAgent,
        informationAgentEmail,
        issuerId: issuer.id,
        notes,
        notificationReceivedDate,
        recordDateEnd: recordDateEnd ? recordDateEnd : recordDateStart,
        recordDateStart,
        securityIds,
        corporateActionApprovals: brokerIds,
        type,
      };

      formikActions.setSubmitting(true);

      submitFiling(payload, formikActions.setSubmitting);
    }
  };

  return (
    <Formik
      initialValues={{
        issuer: {
          companyName: '',
          contactEmail: '',
          id: 0,
        },
        invoicerAddress: '',
        invoicerAddressLine1: '',
        invoicerAddressLine2: '',
        invoicerAddressLine3: '',
        invoicerCity: '',
        invoicerState: '',
        invoicerPostalCode: '',
        invoicerContactEmail: '',
        type: '' as Type,
        cusips: [],
        brokers: [],
        actionType: '' as ActionType,
        meetingType: MeetingType.Annual,
        reportType: 'annual' as ReportType,
        deliveryMethod: 'traditional' as DeliveryMethod,
        relatedFiling: null,
        contestingEntity: '',
        solicitor: undefined,
        tabulator: undefined,
        replyTo: [] as ICompanyContact[],
        sendTo: [] as ICompanyContact[],
        recordDateCompare: false,
      }}
      onSubmit={(
        values: IFilingCreateValues,
        formikActions: FormikActions<IFilingCreateValues>,
      ) => {
        setShouldScroll(false);
        if (values.recordDateCompare && values.additionalRecordDates) {
          const everyAddRecordDate = values.additionalRecordDates.every(
            (recordDate: string) => Boolean(recordDate),
          );
          if (!everyAddRecordDate) {
            setAdditionalRecordDatesError(
              'All additional record dates must be filled in.',
            );
            formikActions.setSubmitting(false);
            return;
          }
        }

        switch (values.type) {
          case 'CorporateAction':
            return createCAFiling(values, formikActions);
          default:
            return createProxyFiling(values, formikActions);
        }
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={(formikBag: FormikProps<IFilingCreateValues>) => {
        return Yup.object().shape({
          issuer: Yup.object().required('Must include issuer'),
          type: Yup.string().required('Must include type'),
          cusips: Yup.string().required('You must include at least one Cusip'),
          recordDate: Yup.string().when('type', {
            is: (value) =>
              value === 'FirmConsentSolicitation' ||
              value === 'FirmInformationStatement' ||
              value === 'FirmMeeting' ||
              value === 'FundInformationStatement' ||
              value === 'FundMeeting' ||
              value === 'FundReport' ||
              (value === 'MeetingContest' && !formikBag.values.relatedFiling),
            then: Yup.string().required('Record Date cannot be blank'),
            otherwise: Yup.string().nullable(),
          }),
          brokerSearchReceivedDate: Yup.string().when('type', {
            is: (value) =>
              value === 'FirmConsentSolicitation' ||
              value === 'FirmInformationStatement' ||
              value === 'FirmMeeting' ||
              value === 'FundInformationStatement' ||
              value === 'FundMeeting' ||
              value === 'FundReport' ||
              value === 'MeetingContest',
            then: Yup.string().required('Search Date cannot be blank'),
            otherwise: Yup.string().nullable(),
          }),
          fundOwner: Yup.string().when('type', {
            is: (value) =>
              value === 'FundReport' || value === 'FundInformationStatement',
            then: Yup.string().required('Please include a fund report'),
            otherwise: Yup.string().nullable(),
          }),
          contestingEntity: Yup.string().when('type', {
            is: 'MeetingContest',
            then: Yup.string().required(
              'Please include the the contesting entity',
            ),
            otherwise: Yup.string().nullable(),
          }),
          issuerFiling: Yup.string().when('type', {
            is: 'MeetingContest',
            then: Yup.string().required(),
            otherwise: Yup.string().nullable(),
          }),
          solicitor: Yup.object().when('type', {
            is: (value) =>
              value === 'FirmConsentSolicitation' ||
              value === 'FirmInformationStatement' ||
              value === 'FirmMeeting' ||
              value === 'FundInformationStatement' ||
              value === 'FundMeeting' ||
              value === 'FundReport' ||
              value === 'MeetingContest',
            then: Yup.object().required('You must include a solicitor'),
            otherwise: Yup.object().nullable(),
          }),
          replyTo: Yup.array().when('type', {
            is: (value) =>
              value === 'FirmConsentSolicitation' ||
              value === 'FirmInformationStatement' ||
              value === 'FirmMeeting' ||
              value === 'FundInformationStatement' ||
              value === 'FundMeeting' ||
              value === 'FundReport' ||
              value === 'MeetingContest',
            then: Yup.array().of(Yup.object()).min(1),
            otherwise: Yup.array().of(Yup.object()).min(0),
          }),
          actionType: Yup.string().when('type', {
            is: 'CorporateAction',
            then: Yup.string().required('Action Type is required.'),
            otherwise: Yup.string().nullable(),
          }),
          notificationReceivedDate: Yup.string().when('type', {
            is: 'CorporateAction',
            then: Yup.string().required(
              'Notification Received Date is required.',
            ),
            otherwise: Yup.string().nullable(),
          }),
          recordDateStart: Yup.string().when('type', {
            is: 'CorporateAction',
            then: Yup.string().required('Record Start Date is required.'),
            otherwise: Yup.string().nullable(),
          }),
          electionCutoffDate: Yup.string().when('type', {
            is: 'CorporateAction',
            then: Yup.string().required('Election Cutoff Date is required.'),
            otherwise: Yup.string().nullable(),
          }),
          electionCutoffTime: Yup.string().when('type', {
            is: 'CorporateAction',
            then: Yup.string().required('Election Cutoff Time is required.'),
            otherwise: Yup.string().nullable(),
          }),
          informationAgentEmail: Yup.string().when('type', {
            is: 'CorporateAction',
            then: Yup.string().email('Must be a valid email address.'),
            otherwise: Yup.string().nullable(),
          }),
        });
      }}
      render={(formikBag: FormikProps<IFilingCreateValues>) => {
        const { type, cusips, issuer, solicitor, tabulator } = formikBag.values;

        if (formikBag.errors && shouldScroll) {
          setTimeout(() => {
            const elements = document.getElementsByClassName('error-label');
            if (elements.length > 0) {
              elements[0].scrollIntoView({
                behavior: 'smooth',
              });
              setShouldScroll(false);
            }
          }, 0);
        }

        const fields = (): ReactNode => {
          switch (type) {
            case 'FundReport':
              return (
                <>
                  <FundOwner />
                  <BrokerSearchReceivedDate />
                  <SolicitorJobNumber />
                  <RecordDate />
                  {formikBag.values.type !== FilingType.CorporateAction && (
                    <RecordDateCompare />
                  )}
                  {formikBag.values.recordDateCompare && (
                    <AdditionalRecordDates
                      error={additionalRecordDatesError}
                      additionalRecordDates={
                        formikBag.values.additionalRecordDates
                      }
                    />
                  )}
                  <MaterialsExpectedDate />
                  <DeliveryMethodField />
                  <ReportTypeField />
                  <SolicitorTypeahead />
                  {solicitor && (
                    <ReplyToTypeahead
                      companyId={solicitor.id}
                      company={solicitor}
                      setFieldValue={formikBag.setFieldValue}
                    />
                  )}
                  <InvoicerAddressFields />
                  <InvoicerContactEmail />
                  <Notes />
                </>
              );
            case 'FundMeeting':
              return (
                <>
                  <FundOwner />
                  <BrokerSearchReceivedDate />
                  <SolicitorJobNumber />
                  <RecordDate />
                  {formikBag.values.type !== FilingType.CorporateAction && (
                    <RecordDateCompare />
                  )}
                  {formikBag.values.recordDateCompare && (
                    <AdditionalRecordDates
                      error={additionalRecordDatesError}
                      additionalRecordDates={
                        formikBag.values.additionalRecordDates
                      }
                    />
                  )}
                  <MaterialsExpectedDate />
                  <MeetingDate />
                  <MeetingTypeField />
                  <DeliveryMethodField />
                  <SolicitorTypeahead />
                  {solicitor && (
                    <ReplyToTypeahead
                      companyId={solicitor.id}
                      company={solicitor}
                      setFieldValue={formikBag.setFieldValue}
                    />
                  )}
                  <TabulatorTypeahead />
                  {tabulator && (
                    <SendToTypeahead
                      companyId={tabulator.id}
                      company={tabulator}
                      setFieldValue={formikBag.setFieldValue}
                    />
                  )}
                  <InvoicerAddressFields />
                  <InvoicerContactEmail />
                  <Notes />
                </>
              );
            case 'FirmMeeting':
              return (
                <>
                  <BrokerSearchReceivedDate />
                  <SolicitorJobNumber />
                  <RecordDate />
                  {formikBag.values.type !== FilingType.CorporateAction && (
                    <RecordDateCompare />
                  )}
                  {formikBag.values.recordDateCompare && (
                    <AdditionalRecordDates
                      error={additionalRecordDatesError}
                      additionalRecordDates={
                        formikBag.values.additionalRecordDates
                      }
                    />
                  )}
                  <MaterialsExpectedDate />
                  <MeetingDate />
                  <MeetingTypeField />
                  <DeliveryMethodField />
                  <SolicitorTypeahead />
                  {solicitor && (
                    <ReplyToTypeahead
                      companyId={solicitor.id}
                      company={solicitor}
                      setFieldValue={formikBag.setFieldValue}
                    />
                  )}
                  <TabulatorTypeahead />
                  {tabulator && (
                    <SendToTypeahead
                      companyId={tabulator.id}
                      company={tabulator}
                      setFieldValue={formikBag.setFieldValue}
                    />
                  )}
                  <InvoicerAddressFields />
                  <InvoicerContactEmail />
                  <Notes />
                </>
              );
            case 'FirmInformationStatement':
              return (
                <>
                  <BrokerSearchReceivedDate />
                  <SolicitorJobNumber />
                  <RecordDate />
                  {formikBag.values.type !== FilingType.CorporateAction && (
                    <RecordDateCompare />
                  )}
                  {formikBag.values.recordDateCompare && (
                    <AdditionalRecordDates
                      error={additionalRecordDatesError}
                      additionalRecordDates={
                        formikBag.values.additionalRecordDates
                      }
                    />
                  )}
                  <MaterialsExpectedDate />
                  <DeliveryMethodField />
                  <SolicitorTypeahead />
                  {solicitor && (
                    <ReplyToTypeahead
                      companyId={solicitor.id}
                      company={solicitor}
                      setFieldValue={formikBag.setFieldValue}
                    />
                  )}

                  <InvoicerAddressFields />
                  <InvoicerContactEmail />
                  <Notes />
                </>
              );
            case 'FundInformationStatement':
              return (
                <>
                  <FundOwner />
                  <BrokerSearchReceivedDate />
                  <SolicitorJobNumber />
                  <RecordDate />
                  {formikBag.values.type !== FilingType.CorporateAction && (
                    <RecordDateCompare />
                  )}
                  {formikBag.values.recordDateCompare && (
                    <AdditionalRecordDates
                      error={additionalRecordDatesError}
                      additionalRecordDates={
                        formikBag.values.additionalRecordDates
                      }
                    />
                  )}
                  <MaterialsExpectedDate />
                  <DeliveryMethodField />
                  <SolicitorTypeahead />
                  {solicitor && (
                    <ReplyToTypeahead
                      companyId={solicitor.id}
                      company={solicitor}
                      setFieldValue={formikBag.setFieldValue}
                    />
                  )}

                  <InvoicerAddressFields />
                  <InvoicerContactEmail />
                  <Notes />
                </>
              );
            case 'FirmConsentSolicitation':
              return (
                <>
                  <BrokerSearchReceivedDate />
                  <SolicitorJobNumber />
                  <RecordDate />
                  {formikBag.values.type !== FilingType.CorporateAction && (
                    <RecordDateCompare />
                  )}
                  {formikBag.values.recordDateCompare && (
                    <AdditionalRecordDates
                      error={additionalRecordDatesError}
                      additionalRecordDates={
                        formikBag.values.additionalRecordDates
                      }
                    />
                  )}
                  <MaterialsExpectedDate />
                  <DeliveryMethodField />
                  <ReportTypeField />
                  <SolicitorTypeahead />
                  {solicitor && (
                    <ReplyToTypeahead
                      companyId={solicitor.id}
                      company={solicitor}
                      setFieldValue={formikBag.setFieldValue}
                    />
                  )}
                  <TabulatorTypeahead />
                  {tabulator && (
                    <SendToTypeahead
                      companyId={tabulator.id}
                      company={tabulator}
                      setFieldValue={formikBag.setFieldValue}
                      isUpdate={true}
                    />
                  )}
                  <InvoicerAddressFields />
                  <InvoicerContactEmail />
                  <Notes />
                </>
              );
            case 'MeetingContest':
              return (
                <>
                  <ContestingEntity />
                  <BrokerSearchReceivedDate />
                  <SolicitorJobNumber />
                  <MaterialsExpectedDate />
                  <DeliveryMethodField />
                  <SolicitorTypeahead />
                  {solicitor && (
                    <ReplyToTypeahead
                      companyId={solicitor.id}
                      company={solicitor}
                      setFieldValue={formikBag.setFieldValue}
                    />
                  )}
                  <TabulatorTypeahead />
                  {tabulator && (
                    <SendToTypeahead
                      companyId={tabulator.id}
                      company={tabulator}
                      setFieldValue={formikBag.setFieldValue}
                    />
                  )}
                  <InvoicerAddressFields />
                  <InvoicerContactEmail />
                  <Notes />
                </>
              );
            case 'CorporateAction':
              return (
                <>
                  <CorporateActionType isRequired={true} />
                  <BrokerTypeahead />
                  <Field
                    name="notificationReceivedDate"
                    render={({
                      field,
                      form,
                    }: FieldProps<IFilingCreateValues>) => (
                      <GenericDate
                        field={field}
                        form={form}
                        label="Notification Received Date"
                        fieldName={field.name}
                        isRequired={true}
                      />
                    )}
                  />

                  <Flex alignItems="center" mb={3}>
                    <InputGroupRow>
                      <InlineLabel htmlFor="single-record-date">
                        Single Record Date
                      </InlineLabel>
                      <Input
                        type="radio"
                        id="single-record-date"
                        value={RecordDateView.SingleDate}
                        checked={recordDateView === RecordDateView.SingleDate}
                        onChange={() =>
                          updateRecordDateView(RecordDateView.SingleDate)
                        }
                      />
                    </InputGroupRow>

                    <InputGroupRow>
                      <InlineLabel htmlFor="date-range">Date Range</InlineLabel>
                      <Input
                        type="radio"
                        id="date-range"
                        value={RecordDateView.DateRange}
                        checked={recordDateView === RecordDateView.DateRange}
                        onChange={() =>
                          updateRecordDateView(RecordDateView.DateRange)
                        }
                      />
                    </InputGroupRow>
                  </Flex>

                  <Field
                    name="recordDateStart"
                    render={({
                      field,
                      form,
                    }: FieldProps<IFilingCreateValues>) => (
                      <GenericDate
                        field={field}
                        form={form}
                        label="Record Date"
                        fieldName={field.name}
                        isRequired={true}
                      />
                    )}
                  />

                  {recordDateView === RecordDateView.DateRange && (
                    <Field
                      name="recordDateEnd"
                      validate={(value: string) => {
                        if (formikBag.values.recordDateStart) {
                          const dateEnd = moment(value, 'YYYY-MM-DD');
                          const dateStart = moment(
                            formikBag.values.recordDateStart,
                            'YYYY-MM-DD',
                          );
                          if (dateEnd.isBefore(dateStart)) {
                            return 'Record date end cannot be before record date start';
                          }
                        }
                      }}
                      render={({
                        field,
                        form,
                      }: FieldProps<IFilingCreateValues>) => (
                        <>
                          <GenericDate
                            field={field}
                            form={form}
                            label="Record End Date"
                            fieldName={field.name}
                          />

                          {form.values.recordDateStart &&
                            form.values.recordDateEnd &&
                            form.values.recordDateEnd ===
                              form.values.recordDateStart && (
                              <WarningLabel>
                                Are you sure you meant to create a record date
                                range with the same date? This will be converted
                                to a single record date.
                              </WarningLabel>
                            )}
                        </>
                      )}
                    />
                  )}

                  <Field
                    name="electionCutoffDate"
                    render={({
                      field,
                      form,
                    }: FieldProps<IFilingCreateValues>) => (
                      <GenericDate
                        field={field}
                        form={form}
                        label="Election Cutoff Date"
                        fieldName={field.name}
                        isRequired={true}
                      />
                    )}
                  />

                  <Field
                    name="electionCutoffTime"
                    render={({
                      field,
                      form,
                    }: FieldProps<IFilingCreateValues>) => (
                      <InputGroup>
                        <Label htmlFor="election-cutoff-time">
                          Election Cutoff Time <Asterisk />{' '}
                          {form.errors['electionCutoffTime'] && (
                            <ErrorLabel>
                              {form.errors['electionCutoffTime']}
                            </ErrorLabel>
                          )}
                        </Label>
                        <Input
                          {...field}
                          id="election-cutoff-time"
                          type="time"
                        />
                      </InputGroup>
                    )}
                  />

                  <Field
                    name="expirationDate"
                    validate={(value: string) => {
                      const expirationDate = moment(value, 'YYYY-MM-DD');
                      if (formikBag.values.recordDateEnd) {
                        const dateEnd = moment(
                          formikBag.values.recordDateEnd,
                          'YYYY-MM-DD',
                        );
                        if (dateEnd.isAfter(expirationDate)) {
                          return 'Record date cannot be after expiration date';
                        }
                      } else if (formikBag.values.recordDateStart) {
                        const dateStart = moment(
                          formikBag.values.recordDateStart,
                          'YYYY-MM-DD',
                        );
                        if (dateStart.isAfter(expirationDate)) {
                          return 'Record date cannot be after expiration date';
                        }
                      }
                    }}
                    render={({
                      field,
                      form,
                    }: FieldProps<IFilingCreateValues>) => (
                      <GenericDate
                        field={field}
                        form={form}
                        label="Expiration Date"
                        fieldName={field.name}
                      />
                    )}
                  />

                  <Field
                    name="dtcExpirationDate"
                    render={({
                      field,
                      form,
                    }: FieldProps<IFilingCreateValues>) => (
                      <GenericDate
                        field={field}
                        form={form}
                        label="DTC Expiration Date"
                        fieldName={field.name}
                      />
                    )}
                  />

                  <Field
                    name="informationAgent"
                    render={({ field }: FieldProps<IFilingCreateValues>) => (
                      <InputGroup>
                        <Label htmlFor="information-agent">
                          Information Agent
                        </Label>
                        <Input {...field} id="information-agent" />
                      </InputGroup>
                    )}
                  />

                  <Field
                    name="informationAgentEmail"
                    render={({
                      field,
                      form,
                    }: FieldProps<IFilingCreateValues>) => (
                      <InputGroup>
                        <Label htmlFor="information-agent-email">
                          Information Agent Email{' '}
                          {form.errors['informationAgentEmail'] && (
                            <ErrorLabel>
                              {form.errors['informationAgentEmail']}
                            </ErrorLabel>
                          )}
                        </Label>
                        <Input
                          {...field}
                          id="information-agent-email"
                          type="email"
                        />
                      </InputGroup>
                    )}
                  />

                  <Field
                    name="actionDetail"
                    render={({ field }: FieldProps<IFilingCreateValues>) => (
                      <InputGroup>
                        <Label htmlFor="action-detail">Action Detail</Label>
                        <Textarea id="action-detail" {...field} rows={5} />
                      </InputGroup>
                    )}
                  />

                  <Notes />
                </>
              );
            default:
              return <></>;
          }
        };

        return (
          <Form>
            {formikBag.isSubmitting ? (
              <Loading />
            ) : (
              <>
                {existingFiling && existingFilingId ? (
                  <Box mb={4}>
                    <Callout
                      icon="warning-sign"
                      intent={Intent.WARNING}
                      title="Duplicate Filing?"
                    >
                      <p>
                        A filing with the same filing type, issuer, and record
                        date already exists. Would you like to add this filing
                        anyway?
                      </p>

                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/old/filings/${existingFilingId}`}
                        >
                          Filing #{existingFilingId}
                        </a>
                      </p>
                    </Callout>
                  </Box>
                ) : (
                  <>
                    <FilingTypeSelect />
                    {type && <CusipTypeahead />}
                    {type && cusips && cusips.length > 0 && <IssuerTypeahead />}
                    {type && cusips && cusips.length > 0 && issuer && fields()}
                  </>
                )}

                <Flex mt={4}>
                  <OutlineButton
                    type="button"
                    width={1}
                    mr={2}
                    onClick={() => {
                      if (existingFiling && existingFilingId) {
                        setExistingFiling(false);
                        setExistingFilingId(NaN);
                      } else {
                        onClose();
                      }
                    }}
                  >
                    Cancel
                  </OutlineButton>

                  <Button
                    type="submit"
                    disabled={formikBag.isSubmitting}
                    width={1}
                    ml={2}
                    onClick={() => {
                      if (
                        formikBag.values.type === Type.CorporateAction &&
                        (!formikBag.values.brokers ||
                          (formikBag.values.brokers &&
                            formikBag.values.brokers.length === 0))
                      ) {
                        toggleBrokerChangesDialog(true);
                      } else {
                        formikBag.submitForm();
                        setShouldScroll(true);
                      }
                    }}
                  >
                    {formikBag.isSubmitting
                      ? 'Adding...'
                      : `Add Filing ${
                          existingFiling && existingFilingId ? 'Anyway' : ''
                        }`}
                  </Button>
                </Flex>
              </>
            )}
            {isBrokerChangesDialogOpen && (
              <BrokerChangesDialog
                isOpen={isBrokerChangesDialogOpen}
                onContinueSave={() => {
                  formikBag.submitForm();
                  toggleBrokerChangesDialog(false);
                }}
                onRequestClose={() => {
                  toggleBrokerChangesDialog(false);
                }}
                buttonText="Yes"
                text="Are you sure you want to create a corporate action filing for 0 brokers?"
              />
            )}
          </Form>
        );
      }}
    />
  );
};
