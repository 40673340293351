import React, { FC, useContext, MouseEvent } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components/macro';
import { formatNumberWithComma } from '../../utils/formatNumberWithComma';
import { OperationsStatus } from '../../models/filing-details';
import { DocumentPreview } from '../DocumentPreview';
import { NewDocumentForm } from '../NewDocumentForm';
import { FilingContext } from '../../data/Filing.Context';
import { EditDocumentForm } from '../EditDocumentForm';
import { ElevatedCard } from '../../common/ElevatedCard';
import { FilingType } from '../../models/filing-type';
import { ProxyFields } from './ProxyFields';
import { CorporateActionsFields } from './CorporateActionsFields';
import { ProxyStatusList } from './StatusList/Proxy';
import { CorporateActionStatusList } from './StatusList/CorporateAction';

const MailingsWrapper = styled(ElevatedCard)`
  margin-top: 16px;
  padding-bottom: 32px;
`;

const InfoBar = styled.div`
  background: #595959;
  color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: repeat(2, auto);
  font-size: 14px;
  font-weight: 300;
  grid-gap: 32px;
  padding: 8px 16px;
`;

const InfoBarValue = styled.span`
  color: ${({ theme }) => theme.colors.green};
`;

const DocumentsWrapper = styled.div`
  border: 1px solid #d9d9d9;
  display: flex;
  padding: 16px;
`;

const UploadDocument = styled.button`
  align-items: center;
  background: #fff;
  border: 1px dashed #595959;
  color: ${({ theme }) => theme.colors.green};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 115px;
  justify-content: center;
  padding: 16px;
  width: 95px;
`;

const DocumentActionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 350px;
  grid-gap: 16px;
  padding: 0 16px;
  margin-top: 16px;
`;

const MailingsFieldsWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 16px;
  margin-bottom: 32px;
  padding: 0 16px;
  margin-top: 16px;
`;

export const Mailings: FC<RouteComponentProps> = () => {
  const { filing, setNewDocumentDialogOpen } = useContext(FilingContext);

  return (
    filing && (
      <>
        <MailingsWrapper>
          <InfoBar>
            {filing.operationsStatus === OperationsStatus.New ? (
              <>
                <div>
                  Estimated Positions:{' '}
                  <InfoBarValue>
                    {formatNumberWithComma(
                      filing.stats.estimatedTotalPositions,
                    )}
                  </InfoBarValue>
                </div>

                <div>
                  Estimated Paper Materials Needed:{' '}
                  <InfoBarValue>
                    {formatNumberWithComma(
                      filing.stats.estimatedMaterialsRequired,
                    )}
                  </InfoBarValue>
                </div>
              </>
            ) : (
              <>
                <div>
                  Positions:{' '}
                  <InfoBarValue>
                    {formatNumberWithComma(filing.stats.totalPositions)}
                  </InfoBarValue>
                </div>

                <div>
                  Paper Materials Needed:{' '}
                  <InfoBarValue>
                    {formatNumberWithComma(filing.stats.materialsRequired)}
                  </InfoBarValue>
                </div>
              </>
            )}
          </InfoBar>

          <MailingsFieldsWrapper>
            {filing.type === FilingType.CorporateAction ? (
              <CorporateActionsFields />
            ) : (
              <ProxyFields />
            )}
          </MailingsFieldsWrapper>

          <DocumentActionWrapper>
            <DocumentsWrapper>
              <UploadDocument
                type="button"
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault();
                  setNewDocumentDialogOpen(true);
                }}
              >
                + Upload Document
              </UploadDocument>

              {filing.documents.map((document) => (
                <DocumentPreview key={document.id} document={document} />
              ))}
            </DocumentsWrapper>

            <div>
              {filing.type === FilingType.CorporateAction ? (
                <CorporateActionStatusList />
              ) : (
                <ProxyStatusList />
              )}
            </div>
          </DocumentActionWrapper>
        </MailingsWrapper>

        <NewDocumentForm />
        <EditDocumentForm />
      </>
    )
  );
};
