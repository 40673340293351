import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Security, Document as FilingDocument, DocumentType } from '../types';
import { useProxy } from '../useProxy';
import { Formik, FormikProps, Field } from 'formik';
import { editDocumentValidationSchema } from '../validation-schema';
import { documentTypeOptions } from '../select-options';

import { toast } from 'react-toastify';
import { privateApi } from '../../../old/utils/api-adapter';
import { DocumentCusipTypeahead } from '../../../components/typeaheads/DocumentCusipTypeahead';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(() =>
  createStyles({
    rootDialog: {
      position: 'absolute',
    },
    backdrop: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,.8)',
    },
    dialogContent: {
      '& > *': {
        margin: '12px 0',
      },
    },
  }),
);

export type DocumentEditFormValues = {
  documentTitle: string;
  documentType: DocumentType;
  securities: {
    id: number;
    cusip: string;
    name: string;
  }[];
};

type DocumentEditProps = {
  documentIdEditing: number | null;
  setDocumentIdEditing: (id: number | null) => void;
  filingId: string;
};

export const DocumentEdit = ({
  documentIdEditing,
  setDocumentIdEditing,
  filingId,
}: DocumentEditProps) => {
  const classes = useStyles();
  const { data, mutate } = useProxy(filingId);
  const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
  const [doc, setDoc] = useState<FilingDocument>({} as FilingDocument);
  const [open, setOpen] = React.useState(false);

  const filing = data && data.data;

  React.useEffect(() => {
    if (documentIdEditing && filing && filing.documents) {
      const foundDocument = filing.documents.find(
        (document) => document.id === documentIdEditing,
      );

      if (foundDocument) {
        setOpen(true);
        setDoc(foundDocument);
      }
    }
    return () => {
      setDocumentIdEditing(null);
    };
  }, [documentIdEditing, filing, setDocumentIdEditing]);

  const editDocument = async (values: DocumentEditFormValues) => {
    try {
      setIsDocumentLoading(true);
      const attachmentPayload = {
        filing: filing && filing.id,
        securities: values.securities.map((security) => security.id),
        name: values.documentTitle,
        type: values.documentType,
      };
      await privateApi.patch(`/attachments/${doc.id}/`, attachmentPayload);
      mutate();
      setIsDocumentLoading(false);
    } catch (error) {
      toast.error(error);
      setIsDocumentLoading(false);
    }
  };

  // find security on filing given a document.security id
  const initialSecurities = React.useMemo(() => {
    if (doc.securities) {
      return doc.securities.reduce((acc: Security[], curr: number) => {
        if (filing) {
          const foundSecurity = filing.securities.find(
            (filingSecurity) => filingSecurity.id === curr,
          );

          if (foundSecurity) {
            acc.push(foundSecurity);
          }
        }

        return acc;
      }, []);
    }
  }, [doc.securities, filing]);

  const handleClose = () => {
    setOpen(false);
  };
  if (data) {
    return (
      <div>
        <Formik
          initialValues={{
            securities: initialSecurities || [],
            documentType: doc.type,
            documentTitle: doc.name || '',
          }}
          enableReinitialize={true}
          validationSchema={editDocumentValidationSchema}
          onSubmit={editDocument}
          render={(docFormikBag: FormikProps<DocumentEditFormValues>) => (
            <>
              <Dialog
                fullWidth
                maxWidth="sm"
                container={document.getElementById('proxy-body')}
                open={open}
                onClose={handleClose}
                classes={{
                  root: classes.rootDialog,
                }}
                BackdropProps={{
                  classes: { root: classes.backdrop },
                }}
                style={{ position: 'absolute' }}
                className={classes.rootDialog}
              >
                <DialogTitle></DialogTitle>

                <DialogContent className={classes.dialogContent}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      size="small"
                      select
                      required
                      label="Document Type"
                      value={docFormikBag.values.documentType || ''}
                      variant="outlined"
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const optionText = documentTypeOptions.filter(
                          (option) => option.value === e.target.value,
                        )[0]['label'];
                        docFormikBag.setFieldValue(
                          'documentType',
                          e.target.value,
                        );
                        docFormikBag.setFieldValue('documentTitle', optionText);
                      }}
                    >
                      <MenuItem value="" disabled />
                      {documentTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value + ''}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <Field
                    required
                    name="documentTitle"
                    variant="outlined"
                    type="text"
                    fullWidth
                    component={TextField}
                    size="small"
                    value={docFormikBag.values.documentTitle || ''}
                    // A value assignment is required (despite formik-material-ui docs) for autofill to work.
                    label="Document Title"
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      docFormikBag.setFieldValue(
                        'documentTitle',
                        e.target.value,
                      );
                    }}
                  />

                  <DocumentCusipTypeahead
                    docFormikBag={docFormikBag}
                    cusips={data.data.securities}
                  />
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose} type="button">
                    Cancel
                  </Button>
                  <Button
                    disabled={!docFormikBag.isValid}
                    type="submit"
                    color="primary"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.preventDefault();
                      docFormikBag.submitForm();
                      handleClose();
                    }}
                  >
                    {isDocumentLoading ? 'Saving...' : 'Save'}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        />
      </div>
    );
  }
  return null;
};
