import React, { FC } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { FilingListResponse } from '../../../models/filing-list';
import { StatusBar } from '../../../common/StatusBar';
import { Ordering } from '../../../data/Ordering.Context';
import { CorporateActionType } from '../../../models/corporate-action-type';
import { CusipCell } from '../../../common/List/CusipCell';
import { formatRecordDateRange } from '../../../utils/formatRecordDateRange';
import { formatNumberWithComma } from '../../../utils/formatNumberWithComma';
import { formatDate } from '../../../utils/format-date';
import { Button } from '../../../common/Buttons/Button';
import { BrokerCell } from '../../../common/List/BrokerCell';
import { Location } from '../../../data/Location.Container';
import Table, { TableColumnsProps } from '../../../common/Table/Table';
import TableRow from '../../../common/Table/TableRow';
import Cell from '../../../common/Table/Cell';

interface OnGoingProps extends RouteComponentProps {
  filings: FilingListResponse;
  isLoading: boolean;
  onChangePage: (path: string) => void;
}

export const OnGoing: FC<OnGoingProps> = ({
  filings,
  isLoading,
  onChangePage,
}) => {
  const { ordering, setOrder } = Ordering.useContainer();
  const { setBackButtonPath } = Location.useContainer();

  const handleSetOrdering = (order: string) => {
    setOrder('caOnGoing', order);
  };

  const renderFullActionTypeText = (actionType: CorporateActionType) =>
    CorporateActionType[actionType as any];

  return (
    <>
      <StatusBar
        count={filings.count}
        next={filings.next}
        onChangePage={onChangePage}
        previous={filings.previous}
        typeLabel="Corporate Action Tasks"
      />

      <Table
        columns={columns}
        ordering={ordering.caOnGoing}
        onSetOrdering={handleSetOrdering}
        variant="gold"
        isLoading={isLoading}
      >
        {filings.results.map((filing) => {
          return (
            <TableRow
              key={filing.id}
              to={`old/filings/${filing.id}/mailings`}
              variant="gold"
            >
              <Cell>{filing.issuer.companyName}</Cell>
              <Cell>
                <CusipCell
                  cusips={filing.securities.map((security) => security.cusip)}
                />
              </Cell>
              <Cell>
                {filing.actionType &&
                  renderFullActionTypeText(filing.actionType)}
              </Cell>
              <Cell align="right">
                {filing.recordDateStart &&
                  formatRecordDateRange(
                    filing.recordDateStart,
                    filing.recordDateEnd,
                  )}
              </Cell>
              <Cell align="right">
                {filing.stats &&
                  formatNumberWithComma(filing.stats.fullSharePositions)}
              </Cell>
              <Cell align="right">
                {filing.electionCutoffDate &&
                  formatDate(filing.electionCutoffDate)}
              </Cell>
              <Cell>
                {filing.brokers && filing.brokers.length > 0 && (
                  <BrokerCell brokers={filing.brokers} />
                )}
              </Cell>
              <Cell align="right">
                {filing.stats && filing.stats.digitalSendDeadline
                  ? formatDate(filing.stats.digitalSendDeadline)
                  : ''}
              </Cell>
              <Cell align="center">
                <Button
                  small={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setBackButtonPath(window.location.pathname);
                    navigate && navigate(`old/filings/${filing.id}/mailings`);
                  }}
                  m="0 auto"
                  width={'150px'}
                >
                  Add Broker
                </Button>
              </Cell>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
};

const columns: TableColumnsProps[] = [
  {
    name: 'Issuer',
    key: 'issuer',
    orderingKey: 'issuer_name',
  },
  {
    name: 'CUSIP',
    key: 'cusip',
  },
  {
    name: 'Action Type',
    key: 'action-type',
    orderingKey: 'action_type',
  },
  {
    name: 'Record Date',
    key: 'record-date',
    orderingKey: 'record_date_start',
    align: 'right',
  },
  {
    name: 'Positions',
    key: 'positions',
    orderingKey: 'stats__full_share_positions',
    align: 'right',
  },
  {
    name: 'Cutoff Date',
    key: 'cutoff-date',
    orderingKey: 'cutoff_date',
    align: 'right',
  },
  {
    name: 'Broker',
    key: 'broker',
  },
  {
    name: 'Send Deadline',
    key: 'send-deadline',
    orderingKey: 'stats__digital_send_deadline',
    align: 'right',
  },
  {
    name: 'Action',
    key: 'action',
    align: 'center',
  },
];
