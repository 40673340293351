import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { IVoteProposalFormValues } from '../../models/vote-proposal';
import { InputGroup } from '../InputGroup';
import { Label } from '../Label';
import { Asterisk } from '../Asterik/Asterik';
import { Select } from '../Select';

export const ElectionType: FC<{}> = () => {
  return (
    <Field
      name="electionType"
      render={({ field, form }: FieldProps<IVoteProposalFormValues>) => {
        return (
          <InputGroup>
            <Label htmlFor="electionType">
              Election Type <Asterisk />
            </Label>
            <Select
              id="electionType"
              {...field}
              onChange={(e) => {
                const { value } = e.target;
                const splitValue = value.split('_')[0];
                form.setFieldValue('electionType', value);
                form.setFieldValue('recommendation', splitValue);
              }}
            >
              <option value="yes_no">Yes, No</option>
              <option value="yes_no_partial">Yes, No, Partial</option>
              <option value="cash_stock">Cash, Stock</option>
              <option value="cash_stock_mixed">Cash, Stock, Mixed</option>
            </Select>
          </InputGroup>
        );
      }}
    />
  );
};
