import React from 'react';
import { BackArrow } from '../../../components/BackArrow';
import { ReactComponent as IssuerIcon } from './issuer-icon.svg';
import { PageTitle } from '../../../components/PageTitle';

type HeaderProps = {
  issuerName: string;
};

function Header({ issuerName }: HeaderProps) {
  return (
    <div className="p-8 pt-2 border-b border-light-gray">
      <BackArrow to="/issuers" />

      <div className="flex items-center mt-5">
        <IssuerIcon className="mr-3" />
        <PageTitle>{issuerName}</PageTitle>
      </div>
    </div>
  );
}

export { Header };
