import React, { FC, MouseEvent } from 'react';

import styled from 'styled-components/macro';
import { ReactComponent as Minus } from './assets/minus.svg';

const RemoveContainer = styled.button`
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: ${({ theme }) => theme.radii[2]}px;
  text-align: center;
  height: 30px;
  margin: 0 0 0 5px;
  cursor: pointer;
`;

const StyledMinus = styled(Minus)`
  vertical-align: middle;
`;

interface IProps {
  handleClick: () => void;
}

export const RemoveField: FC<IProps> = ({ handleClick }) => (
  <RemoveContainer
    onClick={(e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      handleClick();
    }}
  >
    <StyledMinus />
  </RemoveContainer>
);
