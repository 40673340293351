import React from 'react';

function CusipTag({ children }: { children: React.ReactNode }) {
  return (
    <div className="border border-gray rounded text-xs mt-1 first:mt-0 text-primary-text h-4 w-20 text-center">
      {children}
    </div>
  );
}

export { CusipTag };
