import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import { GenericDate } from './GenericDate';
import { IFilingCreateValues } from '../../models/filing-form';

export const BrokerSearchReceivedDate: FC<{}> = () => {
  return (
    <Field
      name="brokerSearchReceivedDate"
      render={({ field, form }: FieldProps<IFilingCreateValues>) => (
        <GenericDate
          field={field}
          form={form}
          label="Broker Search Received Date"
          fieldName={'brokerSearchReceivedDate'}
          isRequired={true}
        />
      )}
    />
  );
};
