import { Text, TextProps } from './Text';
import React, { ReactNode } from 'react';

interface LabelProps extends TextProps {
  children: ReactNode;
  htmlFor?: string;
}

export const ErrorLabel = (props: LabelProps) => (
  <Text
    {...props}
    as="label"
    color="red"
    fontSize={0}
    fontWeight={4}
    mb={1}
    className="error-label"
  />
);
