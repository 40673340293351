import styled from 'styled-components';
import { FC } from 'react';

interface CellProps {
  align?: Align;
}

type Align = 'left' | 'center' | 'right';

const Cell: FC<CellProps> = styled.td<{ align?: Align }>`
  padding: 16px 8px;
  text-align: ${({ align }) => {
    if (align) {
      return align;
    }

    return 'left';
  }};
  vertical-align: middle;
`;

export default Cell;
