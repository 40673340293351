import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { RouteComponentProps, navigate } from '@reach/router';
import { Grid, Paper, Card, MenuItem } from '@material-ui/core/';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  IFilingCreateValues,
  FilingType,
  MeetingType,
  ReportType,
  DeliveryMethod,
  CompanyContact,
} from '../types';
import {
  Formik,
  Form as FormikForm,
  FormikActions,
  FormikProps,
  Field,
} from 'formik';
import { TextField } from 'formik-material-ui';
import { draftValidationSchema } from '../validation-schema';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { CreateHeader } from './Header';
import { ChipNav } from './ChipNav';
import { FilingDatePicker } from '../DetailsForm/FilingDatePicker';

import { DuplicateConfirmationDialog } from '../../../components/Dialog/DuplicateConfirmationDialog';
import { SimpleSnackbar } from '../../../components/Snackbar';
import { CusipTypeahead } from '../../../components/typeaheads/CusipTypeahead';
import { privateApi } from '../../../old/utils/api-adapter';
import { userIdEvent } from '../../../old/utils/analytics-helpers';
import { formatCamelCaseString } from '../../../utils/format-camel-case';

import { ContactDetails } from '../DetailsForm/Contact';
import { EmptyPane } from '../DetailsPane';
import { DirtyFormDialog } from '../../../components/Dialog/DirtyFormDialog';
import { useProxyWarningDispatch } from '../proxy-warning-context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'sticky',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
    },
    body: {
      flexGrow: 2,
      display: 'flex',
      alignItems: 'stretch',
      backgroundColor: '#F8f8f8',
      paddingTop: '24px',
    },
    sideCard: {
      marginRight: '24px',
      minHeight: 'calc(100vh - 16.5rem)',
    },
    paperRoot: {
      overflowX: 'hidden',
      overflowY: 'auto',
      height: 'calc(100vh - 16.5rem)',
      marginLeft: '24px',
    },
    formRoot: {
      padding: '0 24px',
      '& > *': {
        margin: '12px 0',
      },
    },
    formError: {
      color: '#d33f33',
      fontSize: '.75rem',
      margin: '3px 0 0 14px',
    },
  }),
);

type CreateProps = RouteComponentProps;

export const Create = (_: CreateProps) => {
  const classes = useStyles();
  const [existingFiling, setExistingFiling] = useState<boolean>(false);
  const [existingFilingId, setExistingFilingId] = useState<number>(NaN);
  const [existingFilingDialog, setExistingFilingDialog] = useState<boolean>(
    false,
  );
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
  const [errorBanner, setErrorBanner] = React.useState<boolean>(false);
  const [badRequest, setBadRequest] = React.useState('');
  const [toastMessage, setToastMessage] = React.useState('');

  const dispatchDirty = useProxyWarningDispatch();

  useEffect(() => {
    dispatchDirty({ type: 'INITIALIZE_PROXY' });
  }, [dispatchDirty]);

  const handleSnackbar = (message?: string) => {
    if (message) setToastMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastMessage('');
    setOpenSnackbar(false);
  };

  const submitFiling = async (
    payload: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      const response = await privateApi.post('/filings/', payload);
      setExistingFiling(false);
      setExistingFilingId(NaN);
      setSubmitting(false);
      navigate(`/proxy/${response.data.id}`);
      handleSnackbar('This filing has been created. 🎉');
    } catch (error) {
      if (error.response) {
        userIdEvent('Create Filing Unsuccessful', {
          validationError: error.response.data.nonFieldErrors[0],
        });
        setBadRequest(error.response.data.nonFieldErrors[0]);
        handleSnackbar(error.response.data.nonFieldErrors[0]);
      }

      setSubmitting(false);
    }
  };

  const checkIfFilingExists = async (
    type: FilingType,
    issuerId: number | null,
    recordDate: Date | string,
    payload: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      await privateApi.post(`/filings/check/`, {
        filingType: type,
        issuer: issuerId,
        recordDate: recordDate,
      });
      submitFiling(payload, setSubmitting);
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.nonFieldErrors;

        if (errors) {
          const filingAlreadyExistsErrorIndex = errors.findIndex(
            (error: string) => error.includes('already exists'),
          );

          if (filingAlreadyExistsErrorIndex >= 0) {
            const existingFilingIdFromResponse = errors[
              filingAlreadyExistsErrorIndex
            ].match(/\d+/)[0];
            setExistingFiling(true);
            setExistingFilingId(existingFilingIdFromResponse);
            setExistingFilingDialog(true);
          }
        }
      }
    }
  };

  const createProxyFiling = (
    values: IFilingCreateValues,
    formikActions: FormikActions<IFilingCreateValues>,
  ) => {
    if (values.issuer) {
      const {
        solicitor,
        tabulator,
        replyTo,
        sendTo,
        issuer,
        cusips,
        expectedDate,
        ...theRest
      } = values;

      const securityIds = cusips.map((cusip) => cusip.id);
      const payload: any = {
        ...theRest,
        companyContactIds: [],
        issuerId: issuer.id,
        meeting: {
          date: values.meetingDate,
          type: values.meetingType,
        },
        securityIds,
      };

      formikActions.setSubmitting(true);

      if (expectedDate) {
        payload.digitalMaterialsExpectedDate = expectedDate;
      }
      if (solicitor) {
        payload.solicitorId = solicitor.id;
      }
      if (tabulator) {
        payload.tabulatorId = tabulator.id;
      }
      if (replyTo) {
        const ids = replyTo.map((contact) => contact.id);
        payload.companyContactIds = [...payload.companyContactIds, ...ids];
      }
      if (sendTo) {
        const ids = sendTo.map((contact) => contact.id);
        payload.companyContactIds = [...payload.companyContactIds, ...ids];
      }

      if (
        values.type === FilingType.FirmMeeting ||
        values.type === FilingType.FundMeeting ||
        values.type === FilingType.FirmConsentSolicitation
      ) {
        if (values.meetingDate) {
          payload.voteCutoffDate = moment(values.meetingDate)
            .subtract(1, 'days')
            .format('YYYY-MM-DD');
        }
        payload.voteCutoffTime = '23:59';
        payload.voteCutoffTimezone = 'US/Eastern';
      }

      if (!existingFiling && !existingFilingId) {
        checkIfFilingExists(
          values.type,
          issuer.id,
          values.recordDate ? values.recordDate : '',
          payload,
          formikActions.setSubmitting,
        );
      } else {
        submitFiling(payload, formikActions.setSubmitting);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={draftValidationSchema}
        initialValues={{
          issuer: {
            companyName: '',
            contactEmail: '',
            id: null,
          },
          invoicerAddress: '',
          invoicerAddressLine1: '',
          invoicerAddressLine2: '',
          invoicerAddressLine3: '',
          invoicerCity: '',
          invoicerState: '',
          invoicerPostalCode: '',
          invoicerContactEmail: '',
          type: '' as FilingType,
          cusips: [],
          brokers: [],
          meetingDate: null,
          meetingType: MeetingType.Annual,
          reportType: 'annual' as ReportType,
          deliveryMethod: 'traditional' as DeliveryMethod,
          relatedFiling: null,
          solicitor: undefined,
          tabulator: undefined,
          replyTo: [] as CompanyContact[],
          sendTo: [] as CompanyContact[],
          expectedDate: null,
          brokerSearchReceivedDate: null,
          recordDate: null,
        }}
        onSubmit={(
          values: IFilingCreateValues,
          formikActions: FormikActions<IFilingCreateValues>,
        ) => {
          createProxyFiling(values, formikActions);
        }}
      >
        {(formikBag: FormikProps<IFilingCreateValues>) => (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <FormikForm
              noValidate={true}
              onChange={() => {
                if (!formikBag.dirty) {
                  dispatchDirty({
                    type: 'MARK_DIRTY',
                  });
                }
              }}
            >
              <CreateHeader
                errorBanner={errorBanner}
                setErrorBanner={setErrorBanner}
                badRequest={badRequest}
                formikBag={formikBag}
              />
              <div className={classes.body} id="proxy-body">
                <Grid container={true} justify="center" spacing={3}>
                  <Grid item xs={6}>
                    <Paper elevation={3} className={classes.paperRoot}>
                      <div
                        id="ProxyDetailsFormTab"
                        style={{
                          position: 'sticky',
                          top: 0,
                          backgroundColor: '#FFF',
                          height: '60px',
                          borderBottom: '1px solid #e5e5e5',
                          display: 'flex',
                          paddingLeft: '24px',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          zIndex: 1000,
                        }}
                      >
                        <ChipNav type={formikBag.values.type} />
                      </div>
                      <div className={classes.formRoot}>
                        <div id="ProxyDetailFilingType" className="py-3">
                          <Field
                            component={TextField}
                            size="small"
                            fullWidth
                            required
                            select={true}
                            variant="outlined"
                            label="Filing Type"
                            name="type"
                          >
                            {Object.values(FilingType).map((type) => (
                              <MenuItem key={type} value={type}>
                                {formatCamelCaseString(type)}
                              </MenuItem>
                            ))}
                          </Field>
                        </div>
                        <div id="ProxyDetailCusip">
                          <CusipTypeahead
                            value={formikBag.values.cusips}
                            onSetIssuer={(issuer) =>
                              formikBag.setFieldValue('issuer', issuer)
                            }
                            onSetCusips={(cusips) =>
                              formikBag.setFieldValue('cusips', cusips)
                            }
                            issuerId={
                              formikBag.values.issuer &&
                              formikBag.values.issuer.id
                                ? String(formikBag.values.issuer.id)
                                : null
                            }
                          />
                          {formikBag.errors.cusips ? (
                            <div className={classes.formError}>
                              {formikBag.errors.cusips}
                            </div>
                          ) : null}
                        </div>
                        <Field
                          component={TextField}
                          fullWidth
                          required
                          type="text"
                          size="small"
                          disabled={true}
                          variant="outlined"
                          label="Issuer"
                          name="issuer.companyName"
                        />
                        <FilingDatePicker
                          required={true}
                          label="Broker Search Date"
                          name="brokerSearchReceivedDate"
                          formikBag={formikBag}
                        />

                        <Field
                          component={TextField}
                          fullWidth
                          size="small"
                          label="Job Number"
                          name="solicitorJobNumber"
                          variant="outlined"
                        />

                        <div className="grid grid-cols-2 gap-6">
                          <FilingDatePicker
                            label="Record Date"
                            name="recordDate"
                            required
                            formikBag={formikBag}
                          />
                          <FilingDatePicker
                            label="Expected Date"
                            name="expectedDate"
                            formikBag={formikBag}
                          />
                        </div>

                        {formikBag.values.type === FilingType.FundReport && (
                          <div id="ProxyDetailReportType" className="pt-3">
                            <Field
                              component={TextField}
                              fullWidth
                              type="text"
                              size="small"
                              select={true}
                              variant="outlined"
                              label="Report Type"
                              name="reportType"
                              required
                            >
                              <MenuItem value="annual">Annual</MenuItem>
                              <MenuItem value="semi_annual">
                                Semi-Annual
                              </MenuItem>
                            </Field>
                          </div>
                        )}
                        {(formikBag.values.type === FilingType.MeetingContest ||
                          formikBag.values.type === FilingType.FundMeeting ||
                          formikBag.values.type === FilingType.FirmMeeting) && (
                          <div className="grid grid-cols-2 gap-6 pt-3">
                            <FilingDatePicker
                              label="Meeting Date"
                              name="meetingDate"
                              formikBag={formikBag}
                            />

                            <div id="ProxyDetailMeetingType">
                              <Field
                                component={TextField}
                                fullWidth
                                size="small"
                                type="text"
                                select={true}
                                variant="outlined"
                                label="Meeting Type"
                                name="meetingType"
                              >
                                <MenuItem value="annual">Annual</MenuItem>
                                <MenuItem value="special">Special</MenuItem>
                              </Field>
                            </div>
                          </div>
                        )}

                        <div id="ProxyDetailDeliveryMethod" className="py-3">
                          <Field
                            component={TextField}
                            fullWidth
                            type="text"
                            size="small"
                            select={true}
                            required
                            variant="outlined"
                            label="Delivery Method"
                            name="deliveryMethod"
                          >
                            <MenuItem value="traditional">Traditional</MenuItem>
                            <MenuItem value="notice_and_access">
                              Notice and Access
                            </MenuItem>
                          </Field>
                        </div>
                        <div id="ProxyDetailContacts">
                          <ContactDetails
                            isDraft={true}
                            formikBag={formikBag}
                          />
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Card elevation={3} className={classes.sideCard}>
                      <EmptyPane />
                    </Card>
                  </Grid>
                </Grid>

                <DuplicateConfirmationDialog
                  existingFilingId={existingFilingId}
                  isOpen={existingFilingDialog}
                  onConfirm={() => {
                    setExistingFilingDialog(false);
                    formikBag.submitForm();
                  }}
                  onRequestClose={() => {
                    if (existingFiling && existingFilingId) {
                      setExistingFiling(false);
                      setExistingFilingId(NaN);
                    }
                    setExistingFilingDialog(false);
                    formikBag.setSubmitting(false);
                  }}
                />
              </div>
              <DirtyFormDialog />
            </FormikForm>
          </MuiPickersUtilsProvider>
        )}
      </Formik>
      <SimpleSnackbar
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        message={toastMessage}
      />
    </div>
  );
};
