import React, { FC, useState } from 'react';
import { Flex } from '@rebass/grid';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';

import { ReactComponent as SendIcon } from './SendIcon.svg';
import { EmailPreviewModal } from './EmailPreviewModal';
import styled from 'styled-components/macro';
import { Button } from '../common/Buttons/Button';
import { privateApi } from '../utils/api-adapter';
import { OperationsStatus } from '../models/filing-details';
import { Input } from '../common/Input';
import { Location } from '../data/Location.Container';
import { ITemplate } from './EmailPreview';
import { FilingDetails } from '../models/filing-details';
import { userIdEvent } from '../utils/analytics-helpers';

const StyledFooter = styled(Flex)`
  height: 100%;
  box-shadow: 0 2px 3px 0 #d9d9d9;
  padding: 0 20px 0 38px;
  background-color: #fff;
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  border: 1px solid #b2b2b2;
  margin: 0 15px 0 0;
  &:hover {
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledSendIcon = styled(SendIcon)<{ canSend: boolean }>`
  g {
    g {
      stroke: ${({ theme, canSend }) =>
        canSend ? theme.colors.green : theme.colors.gray};
      fill: ${({ theme, canSend }) =>
        canSend ? theme.colors.green : theme.colors.gray};
    }
  }
  cursor: ${({ canSend }) => (canSend ? 'pointer' : 'default')};
`;

const StyledInput = styled(Input)`
  margin: 0 0 0 15px;
  width: 100%;
`;

interface IProps {
  template: ITemplate | null;
  canSendEmail: boolean;
  filing: FilingDetails | null;
  id?: number;
  isCorpAction?: boolean;
}
export const EmailPreviewFooter: FC<IProps> = ({
  template,
  canSendEmail,
  isCorpAction,
  id,
  filing,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [emailValue, setEmailValue] = useState<string>('');
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const { setBackButtonPath } = Location.useContainer();

  const handleUpdateOperationsStatus = async (
    operationsStatus: OperationsStatus.Approved,
  ) => {
    try {
      await privateApi.patch(`/filings/${id}/update-status/`, {
        operationsStatus,
      });
      toast.success('Approved!');
      isCorpAction
        ? navigate('/old/filings/corporate-actions/review-emails/')
        : navigate('/old/filings/proxy/review-emails/');

      userIdEvent('Approve Clicked', {
        filingId: id,
        filingType: filing ? filing.type : '',
        save: true,
      });
    } catch (error) {
      toast.error(error.response.data.error);
      userIdEvent('Approve Clicked', {
        filingId: id,
        filingType: filing ? filing.type : '',
        save: false,
      });
    }
  };

  const handleSendTestEmail = () => {
    const emails = emailValue.split(',').map((e: string) => e.trim());
    if (id && emailValue.length > 0 && template) {
      try {
        privateApi
          .post(`filings/${id}/preview/${template.slug}/`, {
            emails,
          })
          .then((res) => {
            if (filing) {
              userIdEvent('Send Test Email Clicked', {
                operationStatus: filing.operationsStatus,
                filingId: filing.id,
                filingType: filing.type,
              });
            }
            toast.success(
              `Sent test ${emails.length > 1 ? 'emails' : 'email'}!`,
            );
          });
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
    return;
  };

  return (
    <StyledFooter
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex flexDirection="row" alignItems="center" width={1 / 2}>
        <StyledSendIcon
          canSend={emailValue.length > 0 && canSendEmail}
          onClick={handleSendTestEmail}
        />
        <StyledInput
          disabled={!canSendEmail || !template}
          placeholder={`Send Test Email (separate emails by comma) ${
            !canSendEmail ? '(disabled)' : ''
          }`}
          onChange={(e) => {
            setEmailValue(e.target.value);
          }}
        />
      </Flex>
      <Flex flexDirection="row" width={1 / 2} justifyContent="flex-end">
        <StyledButton
          onClick={() => {
            if (filing) {
              userIdEvent('Edit Filing Clicked', {
                operationStatus: filing.operationsStatus,
                id: filing.id,
                type: filing.type,
              });
            }
            navigate(`/old/filings/${id}/mailings`);
            setBackButtonPath('/old/filings/proxy/review-emails');
          }}
        >
          Edit Filing
        </StyledButton>
        <Button onClick={() => setIsModalOpen(true)}>Approve</Button>
      </Flex>
      <EmailPreviewModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        handleUpdateOperationsStatus={handleUpdateOperationsStatus}
      />
    </StyledFooter>
  );
};
