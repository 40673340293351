import React from 'react';
import Dropzone from 'react-dropzone';
import {
  FaArrowLeft,
  FaEdit,
  FaEye,
  FaFile,
  FaSave,
  FaTrash,
} from 'react-icons/fa';

import { Select } from '../../components/Select';
import { DetailsDocument, DocumentType } from './types';
import { privateApi, privateApiAsFormData } from '../../old/utils/api-adapter';
import { Label } from '../../components/Label';
import { Input } from '../../components/Input';
import { documentTypeOptions } from './select-options';
import { useCorporateAction } from './useCorporateAction';
import { toast } from 'react-toastify';
import { Tooltip } from '../../components/Tooltip';

type DetailsPageDocumentsProps = {
  corporateActionId: string;
};

function DetailsPageDocuments({
  corporateActionId,
}: DetailsPageDocumentsProps) {
  const { data, mutate } = useCorporateAction(corporateActionId);
  const [documentsCopy, setDocumentsCopy] = React.useState<DetailsDocument[]>(
    data ? data.data.documents : [],
  );
  const [previewingDocumentIndex, setPreviewingDocumentIndex] = React.useState<
    number | null
  >(null);

  async function handleUploadDocuments(files: File[]) {
    files.forEach(async (file) => {
      const attachmentData = {
        corporateAction: corporateActionId,
        type: 'amendment_to_offer_to_purchase' as DocumentType,
        name: 'Amendment to Offer to Purchase',
      };
      const formData = new FormData();
      formData.append('file', file, file.name);
      const attachmentResponse = await privateApi.post(
        `/attachments/`,
        attachmentData,
      );
      const uploadResponse = await privateApiAsFormData.post(
        `/attachments/${attachmentResponse.data.id}/upload/`,
        formData,
      );

      if (data) {
        setDocumentsCopy([...documentsCopy, uploadResponse.data]);
      }
    });
  }

  async function handleDelete(id: number) {
    try {
      await privateApi.delete(`/attachments/${id}/`);
      await mutate();
      setDocumentsCopy(documentsCopy.filter((doc) => doc.id !== id));
      toast.success('Successfully deleted document.');
    } catch (error) {
      toast.error(error.response.data);
    }
  }

  function handleChangeName(id: number, name: string) {
    const updatedDocuments = documentsCopy.map((document) => {
      if (id === document.id) {
        return {
          ...document,
          name,
        };
      }
      return document;
    });

    setDocumentsCopy(updatedDocuments);
  }

  function handleChangeType(id: number, type: DocumentType) {
    const updatedDocuments = documentsCopy.map((document) => {
      if (id === document.id) {
        return {
          ...document,
          type,
        };
      }
      return document;
    });

    setDocumentsCopy(updatedDocuments);
  }

  async function handleSave(document: DetailsDocument) {
    const { id, name, type } = document;
    const payload = {
      name,
      type,
    };

    try {
      await privateApi.patch(`/attachments/${id}/`, payload);
      await mutate();
      toast.success('Successfully updated document.');
    } catch (error) {
      toast.error(error.response.data);
    }
  }

  return previewingDocumentIndex !== null ? (
    <DocumentPreview
      previewingDocumentIndex={previewingDocumentIndex}
      documents={documentsCopy}
      onExit={() => setPreviewingDocumentIndex(null)}
      onSelectDocument={(index) => setPreviewingDocumentIndex(index)}
    />
  ) : (
    <React.Fragment>
      <div className="py-4 px-8 block w-full border-b border-light-gray">
        <h2 className="text-secondary-text font-medium text-xs uppercase">
          Documents
        </h2>
      </div>
      <div>
        {documentsCopy.length
          ? documentsCopy
              // filter out auto created edi reports/documents
              .filter(
                (document) => document.type !== 'corporate_action_details',
              )
              .map((document, index) => (
                <Document
                  key={index}
                  document={document}
                  onDelete={() => handleDelete(document.id)}
                  onChangeName={(name) => handleChangeName(document.id, name)}
                  onChangeType={(type: DocumentType) =>
                    handleChangeType(document.id, type)
                  }
                  onSave={() => handleSave(document)}
                  onPreview={() => setPreviewingDocumentIndex(index)}
                />
              ))
          : null}

        <Dropzone onDrop={handleUploadDocuments}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={`flex items-center justify-center border border-dashed ${
                  isDragActive ? 'border-black' : 'border-gray  '
                }`}
                style={{ height: '29rem' }}
              >
                <input {...getInputProps({ accept: 'application/pdf' })} />

                <p>
                  {isDragActive
                    ? 'Drag or browse documents to complete filing'
                    : 'Drop documents here'}
                </p>
              </div>
            );
          }}
        </Dropzone>
      </div>
    </React.Fragment>
  );
}

type DocumentPreviewProps = {
  documents: DetailsDocument[];
  previewingDocumentIndex: number;
  onExit: () => void;
  onSelectDocument: (index: number) => void;
};

function DocumentPreview({
  documents,
  previewingDocumentIndex,
  onExit,
  onSelectDocument,
}: DocumentPreviewProps) {
  return (
    <div>
      <div className="px-4 py-2 grid grid-cols-2 col-gap-8">
        <button
          className="text-green justify-self-start"
          type="button"
          onClick={onExit}
        >
          <FaArrowLeft />
        </button>
        <div className="justify-self-end">
          <Select
            options={documents.map((document, index) => ({
              label: document.name,
              value: String(index),
            }))}
            onChange={(event) => {
              onSelectDocument(event.currentTarget.selectedIndex);
            }}
            value={String(previewingDocumentIndex)}
            name="document"
          />
        </div>
      </div>
      <embed
        src={documents[previewingDocumentIndex].attachment}
        className="h-screen w-full"
        key={documents[previewingDocumentIndex].id}
      />
    </div>
  );
}

type DocumentProps = {
  document: DetailsDocument;
  onDelete: () => void;
  onChangeType: (type: DocumentType) => void;
  onChangeName: (name: string) => void;
  onSave: () => void;
  onPreview: () => void;
};

function Document({
  document,
  onDelete,
  onChangeName,
  onChangeType,
  onSave,
  onPreview,
}: DocumentProps) {
  const [isEditing, setIsEditing] = React.useState(false);

  return isEditing ? (
    <div className="border-t border-light-gray first:border-t-0">
      <div className="flex items-center w-full p-4">
        <FaFile className="mr-2" />
        <span>{document.name}</span>
        <div className="ml-auto">
          <Tooltip id="trash" />
          <button
            onClick={onDelete}
            data-for="trash"
            data-tip="Delete document"
            className="text-gray hover:text-red transition-colors duration-300 mr-2"
            type="button"
          >
            <FaTrash />
          </button>
          <Tooltip id="disk" />
          <button
            type="button"
            onClick={() => {
              setIsEditing(false);
              onSave();
            }}
            data-for="disk"
            data-tip="Save document"
            className="text-gray hover:text-green transition-colors duration-300"
          >
            <FaSave />
          </button>
        </div>
      </div>

      <div className="grid gap-4 grid-cols-2 p-4 border-t border-light-gray">
        <div className="flex flex-col">
          <Label>Doc Type</Label>
          <Select
            options={documentTypeOptions}
            onChange={(event) => {
              onChangeType(event.currentTarget.value as DocumentType);
            }}
            value={document.type}
            name="docName"
          />
        </div>

        <div className="flex flex-col">
          <Label>Doc Name</Label>
          <Input
            type="text"
            value={document.name}
            onChange={(event) => onChangeName(event.currentTarget.value)}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex items-center justify-between p-4 border-t border-light-gray first:border-t-0 bg-very-light-gray">
      <div className="flex items-center">
        <FaFile className="mr-2" />
        <span>{document.name}</span>
      </div>

      <div className="flex items-center">
        <Tooltip id="eye" />
        <button
          type="button"
          className="text-gray hover:text-primary-text transition-colors duration-300"
          data-for="eye"
          data-tip="View document"
        >
          <FaEye className="mr-2" onClick={onPreview} />
        </button>
        <Tooltip id="disk" />
        <button
          type="button"
          className="text-gray hover:text-primary-text transition-colors duration-300"
          data-for="disk"
          data-tip="Save document"
          onClick={() => setIsEditing(true)}
        >
          <FaEdit className="mr-2" />
        </button>
        <Tooltip id="trash" />
        <button
          type="button"
          className="text-gray hover:text-red transition-colors duration-300"
          data-for="trash"
          data-tip="Delete document"
          onClick={onDelete}
        >
          <FaTrash />
        </button>
      </div>
    </div>
  );
}

export { DetailsPageDocuments };
