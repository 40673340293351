import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { Textarea } from '../Textarea';

type Props = {
  id: string;
  name: string;
  displayName: string;
};

export const InvoicerStructuredAddressField: FC<Props> = (props) => {
  return (
    <Field
      name={props.name}
      render={({ field }: FieldProps<FilingDetailsFormValues>) => (
        <InputGroup>
          <Label htmlFor={props.id}>{props.displayName}</Label>
          <Textarea id={props.id} {...field} rows={1} />
        </InputGroup>
      )}
    />
  );
};
