import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { IFilingCreateValues } from '../../models/filing-form';
import { Asterisk } from '../Asterik/Asterik';
import { Label } from '../Label';
import { Select } from '../Select';
import { InputGroup } from '../InputGroup';

interface DeliveryMethodFieldProps {
  disabled?: boolean;
}

export const DeliveryMethodField: FC<DeliveryMethodFieldProps> = ({
  disabled,
}) => {
  return (
    <Field
      name="deliveryMethod"
      render={({ field }: FieldProps<IFilingCreateValues>) => (
        <InputGroup>
          <Label htmlFor="delivery-method">
            Delivery Method <Asterisk />
          </Label>

          <Select id="delivery-method" {...field}>
            <option value="traditional">Traditional</option>
            <option value="notice_and_access">Notice and Access</option>
          </Select>
        </InputGroup>
      )}
    />
  );
};
