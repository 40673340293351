import React, { FC, MouseEvent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { FormikProps } from 'formik';
import { FilingDetailsFormValues } from '../types';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { FilingDatePicker } from './FilingDatePicker';

type AdditionalRecordDates = {
  error: string;
  additionalRecordDates?: string[];
  formikBag: FormikProps<FilingDetailsFormValues>;
};

const useStyles = makeStyles(() =>
  createStyles({
    formError: {
      color: '#d33f33',
      fontSize: '.75rem',
      margin: '3px 0 0 14px',
    },
  }),
);

export const AdditionalRecordDates: FC<AdditionalRecordDates> = ({
  additionalRecordDates,
  error,
  formikBag,
}) => {
  const canAddAnotherField = () => {
    return (
      !additionalRecordDates ||
      additionalRecordDates.every((date: string) => date !== '')
    );
  };

  const classes = useStyles();

  return (
    <div className="grid grid-cols-2 gap-6 pt-3 pb-3">
      <div>
        <Button
          startIcon={<AddIcon color="primary" />}
          disabled={!canAddAnotherField()}
          onClick={() => {
            if (!formikBag.values.recordDateCompare) {
              formikBag.setFieldValue('recordDateCompare', true);
              formikBag.setFieldValue('additionalRecordDates', new Array(''));
            } else {
              formikBag.setFieldValue('additionalRecordDates', [
                ...additionalRecordDates,
                '',
              ]);
            }
          }}
        >
          Record Date
        </Button>
      </div>
      {formikBag.values.recordDateCompare &&
        additionalRecordDates &&
        additionalRecordDates.map((additionalRecordDate: string, i: number) => {
          return (
            <div key={`add-record-date-${i}`}>
              <FilingDatePicker
                formikBag={formikBag}
                label={`Addtional Record Date ${i + 1}`}
                name={`additionalRecordDates.${i}`}
                required={true}
              />
              {error && i === 0 && <p className={classes.formError}>{error}</p>}

              {i + 1 === additionalRecordDates.length && (
                <Button
                  size="small"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (additionalRecordDates.length === 1) {
                      formikBag.setFieldValue('recordDateCompare', false);
                      formikBag.setFieldValue(
                        'additionalRecordDates',
                        undefined,
                      );
                    } else {
                      const filteredRecordDates = [
                        ...additionalRecordDates,
                      ].filter(
                        (recordDate) => recordDate !== additionalRecordDate,
                      );
                      formikBag.setFieldValue('additionalRecordDates', [
                        ...filteredRecordDates,
                      ]);
                    }
                  }}
                >
                  Remove
                </Button>
              )}
            </div>
          );
        })}
    </div>
  );
};
