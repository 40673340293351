import React, { FC } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons';

import { IVoteProposal } from '../../models/vote-proposal';

import {
  ProposalNumber,
  ProposalRecommendation,
  RecommendationType,
  VoteChoice,
  Circle,
} from './VoteStyledComponents';
import { theme } from '../../utils/theme';

interface IProps {
  voteProposalGroup: IVoteProposal[];
  groupNumber: number;
}

export const ProposalGroup: FC<IProps> = ({
  voteProposalGroup,
  groupNumber,
}) => {
  return (
    <Flex width={1} flexDirection="column">
      <Flex px={35} pt={20} flexDirection="column">
        <ProposalNumber>Proposal {groupNumber}</ProposalNumber>
      </Flex>
      <Droppable
        droppableId={`proxy-group-${groupNumber}`}
        type={`PROPOSAL-${groupNumber}`}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver
                ? transparentize('.5', '#6BE0BA')
                : 'transparent',
            }}
            {...provided.droppableProps}
          >
            {voteProposalGroup.map((proposal: IVoteProposal, j: number) => {
              return (
                <Draggable
                  draggableId={`proxyVoteItem-${groupNumber}-${proposal.id}`}
                  key={`draggableProxyVoteItem-${proposal.id}`}
                  index={j}
                >
                  {(dragProposalProvided, dragProposalSnapshot) => (
                    <div
                      ref={dragProposalProvided.innerRef}
                      {...dragProposalProvided.draggableProps}
                    >
                      <Flex
                        px={35}
                        pb={20}
                        flexDirection="column"
                        key={`vote-proposal-${j}`}
                      >
                        <Flex flexDirection="row" mt={20}>
                          <Box {...dragProposalProvided.dragHandleProps} mr={2}>
                            <FontAwesomeIcon
                              icon={faGripHorizontal}
                              color={theme.colors.green}
                            />
                          </Box>
                          <ProposalRecommendation width={1 / 2}>
                            Voting Recommendation:{' '}
                            <RecommendationType>
                              {proposal.recommendationType.split('_').join(' ')}
                            </RecommendationType>
                          </ProposalRecommendation>
                        </Flex>
                        <Flex flexDirection="row" mt={20}>
                          <Box width={1 / 2}>
                            {proposal.type === 'BoardOfDirectorsNomination'
                              ? proposal.directorName
                              : proposal.details}
                          </Box>
                          <Flex width={1 / 2}>
                            {proposal.voteChoices.map((choice) => (
                              <VoteChoice key={choice} alignItems="center">
                                <Circle />
                                <Box>{choice.split('_').join(' ')}</Box>
                              </VoteChoice>
                            ))}
                          </Flex>
                        </Flex>
                      </Flex>
                    </div>
                  )}
                </Draggable>
              );
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Flex>
  );
};
