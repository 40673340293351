import React, { FC, ChangeEvent } from 'react';
import { Field, FieldProps } from 'formik';

import { InputGroup } from '../InputGroup';
import { Label } from '../Label';
import { AddField } from '../AddField';
import styled from 'styled-components/macro';
import { Input } from '../Input';
import { RemoveField } from '../RemoveField';
import {
  IVoteProposalFormValues,
  IVoteProposalFormDirector,
} from '../../models/vote-proposal';
import { privateApi } from '../../utils/api-adapter';
import { ErrorLabel } from '../ErrorLabel';

interface IProps {
  directors?: IVoteProposalFormDirector[];
}

const StyledLabel = styled(Label)`
  margin: 25px 0 5px 0;
`;

export const ProposalDirectors: FC<IProps> = ({ directors }) => {
  return (
    <InputGroup>
      {directors && directors.length > 0 && (
        <>
          {directors.map(
            (director: IVoteProposalFormDirector, index: number) => {
              return (
                <Field
                  name={`directors[${index}]`}
                  key={`directors.${index}`}
                  value={director}
                  render={({
                    field,
                    form,
                  }: FieldProps<IVoteProposalFormValues>) => (
                    <>
                      <StyledLabel htmlFor={`proposal-directors-${index}`}>
                        Director {index + 1}{' '}
                        {directors.length > 1 && (
                          <RemoveField
                            handleClick={() => {
                              const filteredDirectors = directors.filter(
                                (director: IVoteProposalFormDirector, i) =>
                                  i !== index,
                              );

                              if (directors[index].id !== null) {
                                privateApi
                                  .delete(
                                    `/vote-proposals/${directors[index].id}/`,
                                  )
                                  .then((response) => {
                                    form.setFieldValue(
                                      'directors',
                                      filteredDirectors,
                                    );
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                  });
                              } else {
                                form.setFieldValue(
                                  'directors',
                                  filteredDirectors,
                                );
                              }
                            }}
                          />
                        )}
                      </StyledLabel>
                      {form.errors.directors &&
                        form.errors.directors[index] &&
                        form.touched.directors && (
                          <ErrorLabel>Directors cannot be blank</ErrorLabel>
                        )}
                      <Input
                        width={1}
                        value={field.value.director}
                        id={`proposal-directors-${index}`}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(
                            `directors[${index}].director`,
                            e.target.value,
                          );
                        }}
                      />
                      {index === directors.length - 1 && (
                        <AddField
                          label="Directors"
                          disabled={
                            directors[directors.length - 1].director === ''
                          }
                          onClick={() => {
                            if (
                              directors[directors.length - 1].director !== ''
                            ) {
                              form.setFieldValue('directors', [
                                ...directors,
                                { director: '', id: null },
                              ]);
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                />
              );
            },
          )}
        </>
      )}
    </InputGroup>
  );
};
