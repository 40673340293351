import React, { FC } from 'react';
import { CompanyContactCreateTypeahead } from './CompanyContactCreateTypeahead';
import { CompanyContactUpdateTypeahead } from './CompanyContactUpdateTypeahead';
import { ISolicitor, ITabulator } from '../../models/intermediaries';

interface ReplyToTypeaheadProps {
  companyId: number;
  company: ISolicitor | ITabulator;
  setFieldValue: (prop: string, value: any) => void;
  isUpdate?: boolean;
}

export const ReplyToTypeahead: FC<ReplyToTypeaheadProps> = ({
  companyId,
  company,
  setFieldValue,
  isUpdate,
}) => {
  return (
    <>
      {isUpdate ? (
        <CompanyContactUpdateTypeahead
          label="Solicitor Contacts"
          // eslint-disable-next-line
          role="solicitor_reply_to"
          companyId={companyId}
          company={company}
          setFieldValueFromProps={setFieldValue}
        />
      ) : (
        <CompanyContactCreateTypeahead
          label="Solicitor Contacts"
          // eslint-disable-next-line
          role="solicitor_reply_to"
          companyId={companyId}
          company={company}
          setFieldValueFromProps={setFieldValue}
        />
      )}
    </>
  );
};
