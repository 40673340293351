/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { RouteComponentProps, Router, Redirect } from '@reach/router';
import { Open } from './Tabs/Open';
import { Closed } from './Tabs/Closed';
import { Flex } from '@rebass/grid';
import { Text } from '../../common/Text';
import { Tab } from '../../common/Tab';
import { Tabs } from '../../common/Tabs';
import styled from 'styled-components/macro';
import { Ordering } from '../../data/Ordering.Context';
import { AllFilings as AllFilingsData, TabChoice } from '../../data/AllFilings';
import { AllFilingsLayout } from './AllFilingsLayout';

const StickyTabs = styled(Flex)`
  background: ${({ theme }) => theme.colors.white};
  position: sticky;
  top: 73px;
  z-index: 1;
`;

export const AllFilings: FC<RouteComponentProps> = () => {
  const { ordering } = Ordering.useContainer();
  const { allFilings, getFilingsByTab } = AllFilingsData.useContainer();

  useEffect(() => {
    getFilingsByTab(
      TabChoice.Open,
      ordering.open,
      allFilings.search,
      allFilings.filingTypeFilter,
    );
  }, [ordering.open, allFilings.search, allFilings.filingTypeFilter]);

  useEffect(() => {
    getFilingsByTab(
      TabChoice.Closed,
      ordering.closed,
      allFilings.search,
      allFilings.filingTypeFilter,
    );
  }, [ordering.closed, allFilings.search, allFilings.filingTypeFilter]);

  return (
    <AllFilingsLayout>
      <StickyTabs flexDirection="column" width={1}>
        <Tabs columns={2}>
          <Tab link="open">
            <Flex justifyContent="space-between">
              <Text>Open</Text>
              <Text className="count">{allFilings.open.filings.count}</Text>
            </Flex>
          </Tab>

          <Tab link="closed">
            <Flex justifyContent="space-between">
              <Text>Closed</Text>
              <Text className="count">{allFilings.closed.filings.count}</Text>
            </Flex>
          </Tab>
        </Tabs>
      </StickyTabs>

      <div>
        <Router>
          <Redirect from="/" to="/old/filings/all/open" noThrow={true} />
          <Open path="open" />
          <Closed path="closed" />
        </Router>
      </div>
    </AllFilingsLayout>
  );
};
