import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { formatNumber } from '../../utils/format-number';

type TabsProps = {
  tabs: { title: string; count: number; to: string }[];
};

const Tabs = ({ tabs }: TabsProps) => {
  return (
    <div className="border-b border-light-gray py-4 px-8">
      {tabs.map((tab) => (
        <Tab className="text-primary-text" key={tab.title} to={tab.to}>
          {`${tab.title} (${formatNumber(tab.count)})`}
        </Tab>
      ))}
    </div>
  );
};

const Tab = styled(Link)`
  font-size: 0.875rem;
  margin-left: 2rem;
  border-bottom: 1px solid transparent;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &[aria-current='page'] {
    border-color: #11cc99;
  }

  &:first-of-type {
    margin-left: 0;
  }
`;

export { Tabs };
