import React, { FC, useState, useEffect, useContext } from 'react';
import { Field, FieldProps } from 'formik';
import { MultiSelect, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import {
  MenuItem,
  ITagProps,
  Intent,
  Button,
  PopoverPosition,
} from '@blueprintjs/core';

import { IFilingCreateValues, Issuer } from '../../models/filing-form';
import { Asterisk } from '../Asterik/Asterik';
import { privateApi } from '../../utils/api-adapter';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { ErrorLabel } from '../ErrorLabel';
import { toast } from 'react-toastify';
import { FilingContext } from '../../data/Filing.Context';
import { FilingDetails } from '../../models/filing-details';

interface ICusip {
  readonly id: number;
  readonly cusip: string;
  readonly name: string;
  readonly issuer: Issuer;
}

interface IProps {
  hideAddNewCusip?: boolean;
  isUpdate?: boolean;
}

const CusipMultiSelect = MultiSelect.ofType<ICusip>();

const renderTag = (cusip: ICusip) => {
  return cusip.cusip;
};

const isString = (arg: string | null): string => {
  if (typeof arg === 'string') {
    return arg;
  } else {
    return '';
  }
};

const fetchCusips = async (
  filing: FilingDetails | null,
  issuerId: number,
  query?: string,
  isUpdate?: boolean,
) => {
  try {
    let queryString = '';
    if (filing && query && isUpdate) {
      queryString = `cusip=${query}&issuer_id=${filing.issuer.id.toString()}`;
    } else if (filing && isUpdate) {
      queryString = `issuer_id=${isString(filing.issuer.id.toString())}`;
    } else if (query && issuerId) {
      queryString = `cusip=${query}&issuer_id=${issuerId.toString()}`;
    } else if (issuerId) {
      queryString = `issuer_id=${issuerId.toString()}`;
    } else if (query) {
      queryString = `cusip=${isString(query)}`;
    }

    const response = await privateApi.get(
      `/issuers/cusip-typeahead/?${queryString}`,
    );
    return response;
  } catch (error) {
    toast.error('Not able to find cusips');
  }
};

const filterCusip: ItemPredicate<ICusip> = (query, cusip) => {
  return `${cusip.cusip.toLocaleLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

const addCusip = async (query: string, issuerId?: number) => {
  if (issuerId) {
    return privateApi
      .post(`/securities/`, {
        issuer: issuerId,
        cusip: query.toUpperCase(),
      })
      .then((response) => {
        return {
          id: response.data.id,
          cusip: response.data.cusip,
          name: response.data.name,
        };
      })
      .catch((error) => {
        toast.error("Couldn't add this cusip to this issuer");
        return {
          id: NaN,
          cusip: query,
          name: 'Not added yet',
        };
      });
  } else {
    return {
      id: NaN,
      cusip: query.toUpperCase(),
      name: 'Not added yet',
    };
  }
};

export const CusipTypeahead: FC<IProps> = ({ hideAddNewCusip, isUpdate }) => {
  const [query, setQuery] = useState<string>('');
  const [allCusips, setAllCusips] = useState<ICusip[]>([] as ICusip[]);
  const [issuerId, setIssuerId] = useState<number>(NaN);
  const { filing } = useContext(FilingContext);

  useEffect(() => {
    fetchCusips(filing, issuerId, query, isUpdate).then((response) => {
      if (response) {
        setAllCusips(response.data);
      }
    });
  }, [query, issuerId, filing, isUpdate]);

  return (
    <Field
      name="cusips"
      render={({
        field,
        form,
      }: FieldProps<IFilingCreateValues | FilingDetailsFormValues>) => {
        const renderCusip: ItemRenderer<ICusip> = (
          cusip,
          { handleClick, modifiers },
        ) => {
          if (query.length === 0 && !field.value.length) {
            return null;
          }

          return (
            <MenuItem
              active={modifiers.active}
              icon={isCusipSelected(cusip) ? 'tick' : 'blank'}
              key={cusip.id}
              onClick={handleClick}
              text={cusip.cusip}
              shouldDismissPopover={false}
            />
          );
        };

        const isCusipSelected = (cusip: ICusip) => {
          return getSelectedCusipIndex(cusip) !== -1;
        };

        const getSelectedCusipIndex = (cusip: ICusip) => {
          return field.value
            .map((mappedCusip: ICusip) => mappedCusip.id)
            .indexOf(cusip.id);
        };

        const deselectCusip = (index: number) => {
          const filteredCusips = field.value.filter(
            (cusip: ICusip, i: number) => i !== index,
          );
          if (filteredCusips.length === 0) {
            setIssuerId(NaN);
            form.setFieldValue('issuer', null);
          }
          form.setFieldValue('cusips', filteredCusips);
        };

        const handleCusipSelect = (cusip: ICusip) => {
          if (!isCusipSelected(cusip)) {
            selectCusip(cusip);
          } else {
            deselectCusip(getSelectedCusipIndex(cusip));
          }
        };
        const selectCusip = (cusip: ICusip) => {
          const cusipsToAdd = [...field.value, cusip];
          setQuery('');

          if (cusip.issuer) {
            setIssuerId(cusip.issuer.id);
          }

          form.setFieldValue('issuer', cusip.issuer);
          form.setFieldValue('cusips', cusipsToAdd);
        };

        const getTagProps = (): ITagProps => {
          return {
            intent: Intent.NONE,
            minimal: false,
          };
        };

        const handleTagRemove = (tag: string, index: number) => {
          deselectCusip(index);
        };

        const handleClear = () => {
          form.setFieldValue('cusips', []);
          setIssuerId(NaN);
          form.setFieldValue('issuer', null);
        };

        const clearButton = (): JSX.Element => {
          return field.value.length > 0 ? (
            <Button icon="cross" minimal={true} onClick={handleClear} />
          ) : (
            <></>
          );
        };

        return (
          <InputGroup>
            <Label htmlFor="cusips">
              CUSIP(s) <Asterisk />{' '}
              {form.errors.cusips && (
                <ErrorLabel>{form.errors.cusips}</ErrorLabel>
              )}
            </Label>
            <CusipMultiSelect
              itemPredicate={filterCusip}
              itemRenderer={renderCusip}
              items={allCusips}
              noResults={
                hideAddNewCusip ? (
                  <MenuItem
                    disabled={true}
                    icon="info-sign"
                    text="No Cusips Found"
                  />
                ) : (
                  <MenuItem
                    icon="plus"
                    disabled={query.length !== 9}
                    onClick={() => {
                      addCusip(query, issuerId).then((response) => {
                        if (response) {
                          setQuery('');
                          form.setFieldValue('cusips', [
                            ...field.value,
                            response,
                          ]);
                        }
                      });
                    }}
                    text="Add New Cusip"
                  />
                )
              }
              onItemSelect={(cusip: ICusip) => {
                handleCusipSelect(cusip);
              }}
              onQueryChange={(typeQuery: string) => {
                setQuery(typeQuery);
              }}
              popoverProps={{
                position: PopoverPosition.BOTTOM,
              }}
              query={query}
              tagRenderer={renderTag}
              tagInputProps={{
                tagProps: getTagProps(),
                onRemove: (tag: string, index: number) => {
                  handleTagRemove(tag, index);
                },
                rightElement: clearButton(),
              }}
              selectedItems={field.value}
            />
          </InputGroup>
        );
      }}
    />
  );
};
