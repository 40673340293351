import React from 'react';
import {
  FilingDetailsFormValues,
  Document,
  FilingDetails,
  CurrentDisplay,
  DisplayType,
} from '../types';
import { useProxy } from '../useProxy';
import { FormikProps } from 'formik';
import { documentTypeOptions } from '../select-options';

import { toast } from 'react-toastify';
import { privateApi } from '../../../old/utils/api-adapter';

import { FilingDatePicker } from './FilingDatePicker';
import { DocumentUpload } from './DocumentUpload';
import { DocumentEdit } from './DocumentEdit';
import { DeleteConfirmationDialog } from '../../../components/Dialog/DeleteConfirmationDialog';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

type MaterialsProps = {
  filingId: string;
  formikBag: FormikProps<FilingDetailsFormValues>;
  hasPaper: boolean;
  currentDisplay: CurrentDisplay;
  setCurrentDisplay: React.Dispatch<React.SetStateAction<CurrentDisplay>>;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 2,
      '& > *': {
        margin: '12px 0',
      },
    },
    materialsBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'space-between',
      paddingTop: '12px',
    },
    documentRow: {
      display: 'flex',
      alignItems: 'space-between',
      border: '1px solid rgba(0, 0, 0, 0.2)',
      width: '100%',
      marginBottom: '24px',
      borderRadius: '4px',
      padding: '20px',
      '&:hover': {
        backgroundColor: 'rgba(17, 204, 153, 0.04)',
        boxShadow:
          ' 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)',
      },
    },
    documentLabel: {
      flexGrow: 2,
      color: 'rgba(18, 18, 18, 0.87)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      lineHeight: '1.5',
    },
    chipBag: {
      marginTop: '8px',
      '& > *': {
        marginRight: '8px',
      },
    },
    buttonBox: {
      textAlign: 'right',
      minWidth: '200px',
    },
  }),
);

export const MaterialsDetails: React.FC<MaterialsProps> = ({
  formikBag,
  hasPaper,
  setCurrentDisplay,
  filingId,
  currentDisplay,
}) => {
  const classes = useStyles();
  const { data } = useProxy(filingId);
  const [documentIdEditing, setDocumentIdEditing] = React.useState<
    number | null
  >(null);

  if (data) {
    return (
      <div className={classes.root}>
        <div className={classes.materialsBox}>
          <div className="grid grid-cols-2 gap-6 mb-6">
            <FilingDatePicker
              label="Digital Date"
              name="digitalMaterialsReceivedDate"
              formikBag={formikBag}
            />
            {hasPaper && (
              <FilingDatePicker
                label="Paper Date"
                name="paperMaterialsReceivedDate"
                formikBag={formikBag}
              />
            )}
          </div>
          <div>
            {data.data.documents.map((document: Document, index) => (
              <DocumentRow
                key={document.id}
                index={index}
                document={document}
                filing={data.data}
                setDocumentIdEditing={setDocumentIdEditing}
                currentDisplay={currentDisplay}
                setCurrentDisplay={setCurrentDisplay}
              />
            ))}
          </div>
          <DocumentEdit
            filingId={filingId}
            documentIdEditing={documentIdEditing}
            setDocumentIdEditing={setDocumentIdEditing}
          />
          <DocumentUpload filingId={filingId} />
        </div>
      </div>
    );
  }
  return null;
};

type DocumentRowProps = {
  document: Document;
  setDocumentIdEditing: (id: number | null) => void;
  filing: FilingDetails;
  index: number;
  currentDisplay: CurrentDisplay;
  setCurrentDisplay: React.Dispatch<React.SetStateAction<CurrentDisplay>>;
};

const DocumentRow = ({
  document,
  setDocumentIdEditing,
  filing,
  index,
  currentDisplay,
  setCurrentDisplay,
}: DocumentRowProps) => {
  const classes = useStyles();
  const [
    isDeleteDocumentDialogOpen,
    setIsDeleteDocumentDialogOpen,
  ] = React.useState<boolean>(false);
  const { mutate } = useProxy(filing.id.toString());
  const deleteDocument = async (id: number) => {
    try {
      await privateApi.delete(`/attachments/${id}/`);
      if (
        currentDisplay.itemType === DisplayType.Document &&
        currentDisplay.index &&
        filing.documents.length > 1
        // note if you delete the *only* document while it's being previewed
        // you'll fall back to the catchall error screen in the DocumentView component.
      ) {
        if (currentDisplay.index === index) {
          setCurrentDisplay({
            itemType: DisplayType.Document,
            index: filing.documents.length - 2,
            // if you delete the document being previewed,
            // jump to last item in documents - aka the most recently uploaded
          });
        } else if (currentDisplay.index > index) {
          setCurrentDisplay({
            itemType: DisplayType.Document,
            index: currentDisplay.index - 1,
            // if you delete a document earlier in the array,
            // decrement index to stay with current document
          });
        }
      }
      mutate();
      setIsDeleteDocumentDialogOpen(false);
    } catch (error) {
      toast.error(error);
    }
  };

  function getFilename(url: string | null) {
    if (!url) {
      return null;
    }
    const key = url.split('/')[4];
    const decoded = atob(decodeURIComponent(key));
    const filename = decoded.split('/').pop();
    return filename;
  }

  // find security on filing given a document.security id
  const cusips = React.useMemo(() => {
    if (document.securities) {
      return document.securities.reduce((acc: string[], curr: number) => {
        if (filing) {
          const foundSecurity = filing.securities.find(
            (filingSecurity) => filingSecurity.id === curr,
          );

          if (foundSecurity) {
            acc.push(foundSecurity.cusip);
          }
        }

        return acc;
      }, []);
    }
  }, [document.securities, filing]);

  return (
    <div className={classes.documentRow}>
      <InsertDriveFileIcon
        style={{ color: 'rgba(0, 0, 0, 0.5)', marginRight: '32px' }}
      />
      <div className={classes.documentLabel}>
        <p>
          <b>Type:</b>{' '}
          {
            documentTypeOptions.filter(
              (option) => option.value === document.type,
            )[0]['label']
          }{' '}
          | <b>Title:</b> {document.name}
        </p>
        <p>
          <a
            className="text-blue hover:underline hover:text-blue"
            href={document.attachment ? document.attachment : '#'}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              event.stopPropagation()
            }
          >
            {getFilename(document.attachment) || 'Document'}
          </a>
        </p>
        <div className={classes.chipBag}>
          {cusips && cusips.map((i) => <Chip size="small" label={i} key={i} />)}
        </div>
      </div>
      <div className={classes.buttonBox}>
        <Tooltip title="Preview">
          <IconButton
            size="small"
            aria-label="preview"
            onClick={() => {
              setCurrentDisplay({
                itemType: DisplayType.Document,
                index,
              });
            }}
          >
            <VisibilityOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton
            size="small"
            aria-label="edit"
            onClick={() => {
              setDocumentIdEditing(document.id);
            }}
          >
            <CreateIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => {
              setIsDeleteDocumentDialogOpen(true);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DeleteConfirmationDialog
        deleteType="document"
        isOpen={isDeleteDocumentDialogOpen}
        onConfirmDelete={() => deleteDocument(document.id)}
        onRequestClose={() => setIsDeleteDocumentDialogOpen(false)}
      />
    </div>
  );
};
