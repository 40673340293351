import React, { FC, useEffect } from 'react';
import { RouteComponentProps, Router, navigate } from '@reach/router';
import {
  fetchCompanyFlow,
  toggleConfirmSaveCompanyDialog,
  updateCompanyFlow,
  clearSelectedCompany,
  fetchFilingsByIssuerFlow,
} from '../../data/companies';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../data/store';
import { Loading } from '../../common/Loading/Loading';
import {
  Formik,
  Form,
  Field,
  FormikProps,
  FieldProps,
  FormikActions,
} from 'formik';
import { Box, Flex } from '@rebass/grid';
import * as Yup from 'yup';
import { ReactComponent as ArrowLeft } from '../../common/assets/arrow-left.svg';
import styled from 'styled-components/macro';
import { Input } from '../../common/Input';
import { InputGroup } from '../../common/InputGroup';
import { Label } from '../../common/Label';
import { TagList } from '../../common/TagList';
import { OutlineButton } from '../../common/Buttons/OutlineButton';
import { ConfirmSaveCompanyDialog } from './ConfirmSaveCompanyDialog';
import { CompaniesLayout } from './CompaniesLayout';
import { formatDate } from '../../utils/format-date';
import { Tabs as UnstyledTabs } from '../../common/Tabs';
import { Tab } from '../../common/Tab';
import { Text } from '../../common/Text';
import { ReactComponent as DollarSignIcon } from '../../common/assets/dollar-sign.svg';
import { ReactComponent as ListIcon } from '../../common/assets/list.svg';
import { InvoicingDetails } from './tabs/InvoicingDetails';
import { FilingsList } from './tabs/FilingsList';
import { StyledBackButton } from '../Funds/FundDetails/FundDetails';

const Header = styled(Box)`
  background: ${({ theme }) => theme.colors.white};
  border-left: 5px solid ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.black};
  padding: 30px;
  width: 100%;
`;

const HeaderFields = styled.div`
  margin-top: 20px;
`;

const HeadingInput = styled(Input)`
  border: none;
  font-size: 20px;
  font-weight: 500;
  height: auto;

  &:focus {
    border: none;
  }
`;

const InlineInputs = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing[3]}px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-top: 20px;
`;

const Value = styled.p`
  text-transform: capitalize;
`;

const Tabs = styled(UnstyledTabs)`
  border: none;
  box-shadow: 0 2px 3px 0 #c9c6c1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin-top: 16px;
`;

const TransparentOutlineButton = styled(OutlineButton)`
  background: transparent;
`;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
});

export interface FormValues {
  name: string;
  address: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  company: string;
  contact: string;
  notes: string;
  email: string;
}

type CompanyDetailsProps = RouteComponentProps<{ id: string }>;

const CompanyDetails: FC<CompanyDetailsProps> = ({ id }) => {
  const { selectedCompany: company, filingsList } = useSelector(
    (state: AppState) => state.companies,
  );
  const { ordering } = filingsList;
  const dispatch = useDispatch();

  useEffect(() => {
    return function cleanup() {
      dispatch(clearSelectedCompany());
    };
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(fetchCompanyFlow(id));
      dispatch(fetchFilingsByIssuerFlow({ id, ordering }));
    }
  }, [id, dispatch, ordering]);

  const handleConfirmSave = (formikBag: FormikProps<FormValues>) => {
    formikBag.submitForm();
    dispatch(toggleConfirmSaveCompanyDialog);
  };

  return company ? (
    <CompaniesLayout>
      <Box bg="background" color="black" p={3}>
        <Formik
          initialValues={{
            name: company.name || '',
            address:
              (company.invoicingAddress && company.invoicingAddress.address) ||
              '',
            addressLine1:
              (company.invoicingAddress &&
                company.invoicingAddress.addressLine1) ||
              '',
            addressLine2:
              (company.invoicingAddress &&
                company.invoicingAddress.addressLine2) ||
              '',
            addressLine3:
              (company.invoicingAddress &&
                company.invoicingAddress.addressLine3) ||
              '',
            city:
              (company.invoicingAddress && company.invoicingAddress.city) || '',
            state:
              (company.invoicingAddress && company.invoicingAddress.state) ||
              '',
            postalCode:
              (company.invoicingAddress &&
                company.invoicingAddress.postalCode) ||
              '',
            country:
              (company.invoicingAddress && company.invoicingAddress.country) ||
              '',
            company:
              (company.invoicingAddress && company.invoicingAddress.company) ||
              '',
            contact:
              (company.invoicingAddress && company.invoicingAddress.contact) ||
              '',
            notes:
              (company.invoicingAddress && company.invoicingAddress.notes) ||
              '',
            email:
              (company.invoicingAddress && company.invoicingAddress.email) ||
              '',
          }}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={(
            values: FormValues,
            actions: FormikActions<FormValues>,
          ) => {
            const payload = {
              id: company.id,
              name: values.name,
              invoicingAddress: {
                address: values.address,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                addressLine3: values.addressLine3,
                city: values.city,
                state: values.state,
                postalCode: values.postalCode,
                country: values.country,
                company: values.company,
                contact: values.contact,
                notes: values.notes,
                email: values.email,
              },
            };
            dispatch(updateCompanyFlow(payload));
            actions.setSubmitting(false);
          }}
          render={(formikBag: FormikProps<FormValues>) => (
            <Form>
              <Header>
                <StyledBackButton
                  onClick={() => {
                    navigate('../');
                  }}
                >
                  <ArrowLeft /> Back
                </StyledBackButton>

                <HeaderFields>
                  <Field
                    name="name"
                    render={({ field }: FieldProps<FormValues>) => (
                      <InputGroup>
                        <HeadingInput {...field} placeholder="Fund Name" />
                      </InputGroup>
                    )}
                  />

                  <InlineInputs>
                    <InputGroup>
                      <Label>CUSIP(s)</Label>
                      <TagList values={company.cusips} />
                    </InputGroup>

                    <InputGroup>
                      <Label>Type</Label>
                      <Value>{company.issuerType}</Value>
                    </InputGroup>

                    <InputGroup>
                      <Label>Last Record Date</Label>
                      <Value>{formatDate(company.lastAnnualRecordDate)}</Value>
                    </InputGroup>

                    <InputGroup>
                      <Label>Last Annual Meeting</Label>
                      <Value>{formatDate(company.lastAnnualMeetingDate)}</Value>
                    </InputGroup>
                  </InlineInputs>
                </HeaderFields>
              </Header>

              <Tabs columns={2}>
                <Tab link="">
                  <Flex alignItems="center" justifyContent="center">
                    <ListIcon />
                    <Text ml={2}>Filings</Text>
                  </Flex>
                </Tab>
                <Tab link="invoicing-details">
                  <Flex alignItems="center" justifyContent="center">
                    <DollarSignIcon />
                    <Text ml={2}>Invoicing Details</Text>
                  </Flex>
                </Tab>
              </Tabs>

              <Router>
                <FilingsList path="/" />
                <InvoicingDetails path="invoicing-details" />
              </Router>

              <Flex flexDirection="row-reverse" py={3}>
                <TransparentOutlineButton
                  type="button"
                  onClick={() => dispatch(toggleConfirmSaveCompanyDialog())}
                  mx={3}
                  disabled={formikBag.isSubmitting}
                >
                  Save
                </TransparentOutlineButton>

                <ConfirmSaveCompanyDialog
                  onConfirmSave={() => handleConfirmSave(formikBag)}
                />
              </Flex>
            </Form>
          )}
        />
      </Box>
    </CompaniesLayout>
  ) : (
    <Loading />
  );
};

export { CompanyDetails };
