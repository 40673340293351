import React, { FC } from 'react';
import { Flex } from '@rebass/grid';

import { Modal } from '../../common/Modal';
import { Dialog } from '../../common/Dialog/Dialog';
import { StyledDeleteMessage } from './VoteStyledComponents';
import { Button } from '../../common/Buttons/Button';
import { OutlineButton } from '../../common/Buttons/OutlineButton';

interface IProps {
  isOpen: boolean;
  message: string;
  handleModalToggle: () => void;
  handleDeleteProposalGroup: () => void;
}

export const DeleteModal: FC<IProps> = ({
  isOpen,
  message,
  handleModalToggle,
  handleDeleteProposalGroup,
}) => {
  return (
    <Modal isOpen={isOpen}>
      <Dialog
        title={message}
        onRequestClose={() => {
          handleModalToggle();
        }}
      >
        <Flex flexDirection="column" width={1}>
          <StyledDeleteMessage>
            You will not get them back{' '}
            <span role="img" aria-label="crying">
              😭
            </span>{' '}
            <span role="img" aria-label="crying">
              😭
            </span>{' '}
            <span role="img" aria-label="crying">
              😭
            </span>
          </StyledDeleteMessage>
          <Flex flexDirection="row" justifyContent="space-between" width={1}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleDeleteProposalGroup();
              }}
            >
              Yes, I'm sure
            </Button>
            <OutlineButton
              onClick={(e) => {
                e.preventDefault();
                handleModalToggle();
              }}
            >
              Nevermind
            </OutlineButton>
          </Flex>
        </Flex>
      </Dialog>
    </Modal>
  );
};
