import React, { FC, ReactNode } from 'react';
import ReactModal from 'react-modal';

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  onRequestClose?: () => void;
  shouldCloseOnEsc?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  top?: number | 'auto';
}

ReactModal.setAppElement('#root');

export const Modal: FC<ModalProps> = ({
  children,
  isOpen,
  onRequestClose,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  top = 'auto',
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255,255,255,0.7)',
          zIndex: 2,
        },
        content: {
          borderRadius: 0,
          top,
          right: 'auto',
          left: 'auto',
          bottom: 'auto',
          maxWidth: '400px',
          margin: '0 auto',
          width: '100%',
          padding: 0,
        },
      }}
    >
      {children}
    </ReactModal>
  );
};
