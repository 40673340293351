import React from 'react';
import { ReactComponent as Icon } from './issuers-header-icon.svg';
import { pluralize } from '../../utils/pluralize';
import { formatNumber } from '../../utils/format-number';
import { PageTitle } from '../PageTitle';

type IssuersPageHeaderProps = {
  count: number;
};

function IssuersPageHeader({ count }: IssuersPageHeaderProps) {
  return (
    <div className="p-8 border-b border-light-gray flex items-center">
      <Icon className="mr-3" />

      <PageTitle>
        {formatNumber(count)}{' '}
        {pluralize({ singular: 'issuer', plural: 'issuers', count })}
      </PageTitle>
    </div>
  );
}

export { IssuersPageHeader };
