import React, { FC, useMemo, MouseEvent } from 'react';
import { Flex } from '@rebass/grid';
import styled from 'styled-components/macro';
import { renderFullActionTypeText } from '../../utils/renderFullActionTypeText';

const Wrapper = styled(Flex)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
`;

const Link = styled.a`
  color: #007aff;
  text-decoration: underline;
`;

interface MailToCellProps {
  actionType: string;
  email: string;
  issuerName: string;
  name: string;
}

export const MailToCell: FC<MailToCellProps> = ({
  actionType,
  email,
  issuerName,
  name,
}) => {
  const actionTypeLabel = useMemo(() => renderFullActionTypeText(actionType), [
    actionType,
  ]);
  const link = `mailto:${email}?subject=Say Communications - New Information Request&body=Hi ${name} We would like to request additional information for the following event. ${actionTypeLabel} for ${issuerName}`;
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) =>
    event.stopPropagation();

  return (
    <Wrapper>
      <Link
        data-testid="mail-to-cell-link"
        href={link}
        target="_blank"
        onClick={handleClick}
      >
        {name}
      </Link>
    </Wrapper>
  );
};
