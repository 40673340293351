import jwt_decode from 'jwt-decode';
import { getTokenFromCookies } from './get-token';
import { navigate } from '@reach/router';
import { setAuthToken } from './set-token';
import axios from 'axios';

interface JWT {
  exp: number;
  refresh_token: string;
}

const refreshToken = async (failedRequest: any) => {
  const decodedJWT: JWT = jwt_decode(getTokenFromCookies());
  const { refresh_token: refreshToken } = decodedJWT;

  try {
    const tokenRefreshResponse = await axios.post(
      `${process.env.REACT_APP_SSO_URL}/v1/auth/refresh-token/`,
      {
        token: refreshToken,
      },
    );

    const token = tokenRefreshResponse.data.access_token;

    setAuthToken(token);

    failedRequest.response.config.headers['Authorization'] = `JWT ${token}`;

    return Promise.resolve();
  } catch (error) {
    navigate('/logout');
    return Promise.reject(error);
  }
};

export { refreshToken };
