import React from 'react';
import styled from 'styled-components/macro';

const Circle = styled.div`
  background: ${({ theme }) => theme.colors.red};
  border-radius: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  height: 15px;
  line-height: 15px;
  text-align: center;
  width: 15px;
`;

export const AlertIcon = () => <Circle>!</Circle>;
