import React, { FC, useState, useEffect } from 'react';
import { Flex, Box } from '@rebass/grid';
import ReactModal from 'react-modal';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import { EmailPreviewHeader } from './EmailPreviewHeader';
import { MeetingResultsEmailPreviewFooter } from './MeetingResultsEmailPreviewFooter';
import { EmailPreviewSidebar } from './EmailPreviewSidebar';
import { Spinner } from '../common/Spinner/Spinner';
import { privateApi } from '../utils/api-adapter';

export interface IDocument {
  link: string;
  name: string;
}

export interface IBroker {
  name: string;
  id: number;
  slug: string;
}

export interface ITemplate {
  slug: string;
  body: string;
  subject: string;
}

const customStyles = {
  overlay: {
    zIndex: 2,
  },
  content: {
    top: '0',
    left: '0',
    right: 'auto',
    bottom: 'auto',
    width: '100vw',
    height: '100vh',
    padding: 0,
  },
};

const PreviewContainer = styled(Flex)`
  height: calc(100% - 60px);
`;

const FooterContainer = styled(Box)`
  height: 50px;
`;

const Main = styled(Box)`
  height: calc(100% - 50px);
  overflow-y: scroll;
`;

const SpinnerContainer = styled.div`
  width: 40px;
  margin: 25% auto;
`;

const meetingResultFetcher = (url: string) => privateApi.get(url);

const MeetingResultsEmailPreview: FC<RouteComponentProps<{
  id: number;
}>> = ({ id }) => {
  const { data, error } = useSWR(
    `/admin/sec-filings/${id}/`,
    meetingResultFetcher,
  );

  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(
    null,
  );
  const [cachedTemplates, setCachedTemplates] = useState<ITemplate[]>(
    [] as ITemplate[],
  );
  const [brokers, setBrokers] = useState<IBroker[]>([] as IBroker[]);
  const [selectedBrokerSlug, setSelectedBrokerSlug] = useState<string | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const changeBroker = (slug: string) => {
    setSelectedBrokerSlug(slug);
  };

  useEffect(() => {
    privateApi
      .get(`/brokers/?is_proxy_broker=true`)
      .then((response) => {
        const { data } = response;
        const filteredBrokers: IBroker[] = data.results.map(
          (broker: { id: number; name: string; slug: string }) => ({
            id: broker.id,
            name: broker.name,
            slug: broker.slug,
          }),
        );
        setSelectedBrokerSlug(filteredBrokers[0].slug);
        setBrokers(filteredBrokers);
      })
      .catch(() => toast('Could not fetch brokers list'));
  }, []);

  const fetchTemplatePreview = () => {
    if (selectedBrokerSlug && data && data.data.filing.id) {
      privateApi
        .get(
          `/meeting-results/${data.data.filing.id}/preview/${selectedBrokerSlug}/`,
        )
        .then((response) => {
          const { data } = response;
          const template: ITemplate = {
            slug: selectedBrokerSlug,
            body: data.body,
            subject: data.subject,
          };
          if (
            template.body ===
            'Broker does not have a template matching the given criteria'
          ) {
            template.body = `<div style="width: 500px; margin: 150px auto;">${template.body}</div>`;
          } else if (
            template.body ===
            'This broker does not have an available email template'
          ) {
            template.body = `<div style="width: 500px; margin: 150px auto;">${template.body}</div>`;
          }
          setIsLoading(false);
          setSelectedTemplate(template);
          setCachedTemplates([...cachedTemplates, template]);
        })
        .catch(() => {
          const template = {
            slug: selectedBrokerSlug,
            body:
              '<div style="width: 500px; margin: 150px auto;">This broker does not have a default template for this meeting result</div>',
            subject: 'No Subject',
          };
          setIsLoading(false);
          setSelectedTemplate(template);
          setCachedTemplates([...cachedTemplates, template]);
        });
    }
  };

  useEffect(() => {
    if (selectedBrokerSlug) {
      setIsLoading(true);
      const alreadyFetched: ITemplate | undefined = cachedTemplates.find(
        (template: ITemplate) => template.slug === selectedBrokerSlug,
      );

      if (alreadyFetched) {
        setSelectedTemplate(alreadyFetched);
        setIsLoading(false);
      } else {
        fetchTemplatePreview();
      }
    }
    // eslint-disable-next-line
  }, [selectedBrokerSlug]);

  if (data && !error) {
    return (
      <ReactModal isOpen={true} style={customStyles}>
        <EmailPreviewHeader
          subject={selectedTemplate ? selectedTemplate.subject : 'No Subject'}
        />
        <PreviewContainer flexDirection="row" width={1}>
          <Flex flexDirection="column" width={7 / 8}>
            {isLoading ? (
              <Main>
                <SpinnerContainer>
                  <Spinner />
                </SpinnerContainer>
              </Main>
            ) : selectedTemplate ? (
              <Main
                dangerouslySetInnerHTML={{
                  __html: selectedTemplate.body,
                }}
              />
            ) : (
              <Main>No preview</Main>
            )}

            {id && data && data.data.filing.id ? (
              <FooterContainer width={1}>
                <MeetingResultsEmailPreviewFooter
                  filingId={data.data.filing.id}
                  meetingResultId={id}
                  canSendEmail={Boolean(id && selectedTemplate)}
                  template={selectedTemplate}
                />
              </FooterContainer>
            ) : null}
          </Flex>
          <Box width={1 / 8}>
            <EmailPreviewSidebar
              brokers={brokers}
              selectedBrokerSlug={selectedBrokerSlug}
              changeBroker={changeBroker}
            />
          </Box>
        </PreviewContainer>
      </ReactModal>
    );
  }

  return null;
};

export { MeetingResultsEmailPreview };
