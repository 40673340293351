import { formatDate } from './format-date';

export const formatRecordDateRange = (
  recordDateStart: string | Date,
  recordDateEnd?: string | null | Date,
) => {
  if (recordDateStart === recordDateEnd) {
    return formatDate(recordDateStart);
  } else {
    return `${formatDate(recordDateStart)}${
      recordDateEnd ? ` - ${formatDate(recordDateEnd)}` : ''
    }`;
  }
};
