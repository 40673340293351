import React from 'react';

type StatusLabelProps = {
  status: string;
};

const StatusLabel = ({ status }: StatusLabelProps) => {
  if (isClosed(status)) {
    return (
      <div className={`${commonClassNames} bg-light-gray`} style={commonStyle}>
        Closed
      </div>
    );
  } else {
    return (
      <div className={`${commonClassNames} bg-light-green`} style={commonStyle}>
        Open
      </div>
    );
  }
};

const isClosed = (status: string) =>
  status === 'closed' || status === 'approved';

const commonClassNames =
  'h-4 rounded text-xs font-medium text-primary-text inline-flex px-2 items-center justify-center uppercase';
const commonStyle = { width: 'max-content' };

export { StatusLabel };
