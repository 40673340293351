import { createContainer } from 'unstated-next';
import { useState, useContext } from 'react';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import axios from 'axios';
import { useImmer } from 'use-immer';
import { toast } from 'react-toastify';

import { FilingContext } from './Filing.Context';
import {
  IVoteProposal,
  IElectionItems,
  IReorderPayload,
} from '../models/vote-proposal';
import { FilingType } from '../models/filing-type';
import { privateApi } from '../utils/api-adapter';
import { groupProposals } from '../utils/groupVoteProposals';
import { reIndexVPGroups } from '../utils/reIndexVPGroups';
import { reIndexElections } from '../utils/reIndexElections';
import { orderElections } from '../utils/orderElections';

const useVoteProposals = () => {
  const [groupedProposals, setGroupedProposals] = useImmer<IVoteProposal[][]>(
    [] as IVoteProposal[][],
  );

  const [electionItems, setElectionItems] = useState<IElectionItems[]>(
    [] as IElectionItems[],
  );
  const [shouldReorder, setShouldReorder] = useState<boolean>(false);

  const { filing } = useContext(FilingContext);

  const handleSaveReorder = async () => {
    if (filing && filing.type !== FilingType.CorporateAction) {
      const payload: IReorderPayload[] = groupedProposals.reduce(
        (reorders: IReorderPayload[], current: IVoteProposal[]) => {
          const strippedFromGroup: IReorderPayload[] = current.map(
            (vp: IVoteProposal) => {
              const { id, proposalNumber, groupNumber } = vp;
              return {
                id,
                proposalNumber,
                groupNumber,
              };
            },
          );

          return [...reorders, ...strippedFromGroup];
        },
        [] as IReorderPayload[],
      );

      try {
        const response = await privateApi.patch(
          '/admin/vote-proposals/reorder/',
          payload,
        );
        const vpGroups: IVoteProposal[][] = groupProposals(
          response.data as IVoteProposal[],
        );
        setGroupedProposals((draft) => (draft = vpGroups));
      } catch (error) {
        toast.error('Something went wrong, you may need to log back in.');
      }
    } else {
      const payload: IReorderPayload[] = electionItems.map(
        (electionItem: IElectionItems) => {
          const { id, proposalNumber, groupNumber } = electionItem;
          return {
            id,
            proposalNumber,
            groupNumber,
          };
        },
      );

      try {
        const response = await privateApi.patch(
          '/admin/election-items/reorder/',
          payload,
        );
        const orderedElections = orderElections(
          response.data as IElectionItems[],
        );
        setElectionItems((draft) => (draft = orderedElections));
      } catch (error) {
        toast.error('Something went wrong, you may need to log back in.');
      }
    }
  };

  const reorderGroups = (startIndex: number, endIndex: number) => {
    const result: IVoteProposal[][] = [...groupedProposals];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const reIndexedResults: IVoteProposal[][] = reIndexVPGroups(result);

    setGroupedProposals((draft) => (draft = reIndexedResults));
    setShouldReorder(true);
  };

  const reorderProposals = (
    startIndex: number,
    endIndex: number,
    groupNumber: number,
  ) => {
    const result: IVoteProposal[] | undefined = [...groupedProposals].find(
      (proposal: IVoteProposal[]) => proposal[0].groupNumber === groupNumber,
    );

    if (result) {
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      const { groupNumber } = [...result][0];

      const updatedProposals: IVoteProposal[][] = [
        ...groupedProposals,
      ].map((group: IVoteProposal[]) =>
        group[0].groupNumber === groupNumber ? [...result] : [...group],
      );
      const reIndexedProposals: IVoteProposal[][] = reIndexVPGroups(
        updatedProposals,
      );
      setGroupedProposals((draft) => (draft = reIndexedProposals));
      setShouldReorder(true);
    }
  };

  const addProposalGroup = (group: IVoteProposal[]) => {
    setGroupedProposals((draft) => {
      draft.push(group);
    });
  };

  const updateProposalGroup = (changedGroup: IVoteProposal[]) => {
    const updatedProposalGroups: IVoteProposal[][] = groupedProposals
      .map((proposalGroup: IVoteProposal[]) =>
        proposalGroup[0].groupNumber === changedGroup[0].groupNumber
          ? changedGroup
          : proposalGroup,
      )
      .sort(
        (groupA: IVoteProposal[], groupB: IVoteProposal[]) =>
          groupA[0].groupNumber - groupB[0].groupNumber,
      );

    setGroupedProposals((draft) => (draft = updatedProposalGroups));
  };

  const deleteProposalGroup = (groupNumber: number) => {
    const groups = {
      groupToDelete: [] as IVoteProposal[][],
      remainingGroups: [] as IVoteProposal[][],
    };

    groupedProposals.forEach((group: IVoteProposal[]) =>
      group[0].groupNumber === groupNumber
        ? groups.groupToDelete.push(group)
        : groups.remainingGroups.push(group),
    );

    axios
      .all(
        groups.groupToDelete.map((proposals: IVoteProposal[]) =>
          proposals.map((proposal: IVoteProposal) =>
            privateApi.delete(`/vote-proposals/${proposal.id}/`),
          ),
        ),
      )
      .then((response) => {
        const reIndexedGroups = reIndexVPGroups(groups.remainingGroups);
        setGroupedProposals((draft) => (draft = reIndexedGroups));
        setShouldReorder(true);
      })
      .catch((error) => {
        toast.error('Could not delete this group');
      });
  };

  // Elections

  const reorderElections = (startIndex: number, endIndex: number) => {
    const result: IElectionItems[] = [...electionItems];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const reIndexedElections = reIndexElections(result);

    setElectionItems((draft) => (draft = reIndexedElections));
    setShouldReorder(true);
  };

  const addElection = (election: IElectionItems) => {
    setElectionItems((draft) => (draft = [...electionItems, election]));
  };

  const updateElection = (election: IElectionItems) => {
    const updatedElections = [
      ...electionItems,
    ].map((electionItem: IElectionItems) =>
      electionItem.id === election.id ? election : electionItem,
    );

    setElectionItems((draft) => (draft = [...updatedElections]));
  };

  const deleteElection = (id: number) => {
    privateApi
      .delete(`/election-items/${id}/`)
      .then((response) => {
        const remaining = electionItems.filter(
          (item: IElectionItems) => item.id !== id,
        );

        const reIndexedRemaining = reIndexElections(remaining);

        setElectionItems((draft) => (draft = [...reIndexedRemaining]));
        setShouldReorder(true);
      })
      .catch((error) => toast.error("Couldn't delete this election "));
  };

  // DND action handlers
  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const [draggableIdType, groupNumber] = result.draggableId.split('-');

    if (!result.destination) {
      return;
    } else if (result.destination) {
      if (result.destination.index === result.source.index) {
        return;
      }

      if (draggableIdType === 'proxyVoteGroup') {
        reorderGroups(result.source.index, result.destination.index);
      } else if (draggableIdType === 'corpActionGroup') {
        reorderElections(result.source.index, result.destination.index);
      } else if (draggableIdType === 'proxyVoteItem') {
        const parsedGroupNumber: number = parseInt(groupNumber, 10);
        reorderProposals(
          result.source.index,
          result.destination.index,
          parsedGroupNumber,
        );
      }
    }
  };

  return {
    groupedProposals,
    electionItems,
    setGroupedProposals,
    setElectionItems,
    setShouldReorder,
    shouldReorder,
    handleSaveReorder,
    reorderGroups,
    reorderProposals,
    addProposalGroup,
    updateProposalGroup,
    deleteProposalGroup,
    reorderElections,
    addElection,
    updateElection,
    deleteElection,
    onDragEnd,
  };
};

const VoteProposals = createContainer(useVoteProposals);

export { VoteProposals, useVoteProposals };
