import { useState } from 'react';
import { createContainer } from 'unstated-next';

type TabValues =
  | 'caInProgress'
  | 'caOnGoing'
  | 'caReviewEmails'
  | 'closed'
  | 'completeFilings'
  | 'open'
  | 'requestMaterials'
  | 'reviewEmails'
  | 'resultsPending'
  | 'resultsConfirmed'
  | 'resultsDismissed';

interface OrderingState {
  caInProgress: string;
  caReviewEmails: string;
  caOnGoing: string;
  closed: string;
  completeFilings: string;
  open: string;
  requestMaterials: string;
  reviewEmails: string;
  resultsPending: string;
  resultsConfirmed: string;
  resultsDismissed: string;
}

const initialOrderingState = {
  caInProgress: 'stats__digital_send_deadline',
  caOnGoing: 'stats__digital_send_deadline',
  caReviewEmails: 'stats__digital_send_deadline',
  closed: '-first_record_date',
  completeFilings: 'digital_materials_expected_date',
  open: 'first_record_date',
  requestMaterials: 'first_record_date',
  reviewEmails: 'stats__digital_send_deadline',
  resultsPending: 'meeting_date',
  resultsConfirmed: 'meeting_date',
  resultsDismissed: 'meeting_date',
};

const useOrdering = () => {
  const [ordering, updateOrdering] = useState<OrderingState>(
    initialOrderingState,
  );

  const setOrder = (tab: TabValues, order: string) => {
    updateOrdering({
      ...ordering,
      [tab]: order,
    });
  };

  return { ordering, setOrder };
};

const Ordering = createContainer(useOrdering);

export { Ordering, useOrdering };
