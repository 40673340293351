import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SectionTitle } from '../SectionTitle';
import { Label } from '../../../components/Label';
import { Input } from '../../../components/Input';
import useSWR, { mutate } from 'swr';
import {
  Formik,
  Form,
  FormikActions,
  Field,
  FieldProps,
  FormikProps,
} from 'formik';
import { LoadingState } from '../../../components/LoadingState';
import { hubApi } from '../../../old/data/hubApiConfig';
import { toast } from 'react-toastify';
import { geocode } from './geocode';
import { PageWrapper } from '../../../components/PageWrapper';

type Issuer = {
  id: string;
  name: string;
  issuerType: string;
  fiscalYearEnd: Date;
  fundOwner: null;
  cik: null;
  securities: Security[];
  stats: Stats;
  invoicingAddress: InvoicingAddress;
  prospectuses: Prospectuses;
};

type InvoicingAddress = {
  address: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  company: string;
  contact: string;
  notes: string;
  email: string;
};

type Prospectuses = {
  active: any[];
  upcoming: any[];
  archived: any[];
};

type Security = {
  id: string;
  cusip: string;
  ticker: string;
  name: string;
  securityType: string;
  price: string;
  priceLastUpdated: string;
  primaryColor: null;
  iconUrl: null;
};

type Stats = {
  monthlyTrades: number;
};

type InvoicingFormValues = {
  email: string;
  company: string;
  contact: string;
  notes: string;
  address: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postalCode: string;
  state: string;
  country: string;
  city: string;
};

const issuerFetcher = (url: string) => hubApi.get<Issuer>(url);

function Invoicing({ id }: RouteComponentProps<{ id: string }>) {
  const { data, error } = useSWR(`/issuers/issuer/${id}/`, issuerFetcher);

  const handleAddressFieldChange = ({
    event,
    form,
  }: {
    event: React.ChangeEvent<HTMLInputElement>;
    form: FormikProps<InvoicingFormValues>;
  }) => {
    form.setFieldValue('address', event.currentTarget.value);
    geocode(event.currentTarget.value).then((address) => {
      if (address) {
        form.setFieldValue('addressLine1', address.line1);
        form.setFieldValue('addressLine2', address.line2);
        form.setFieldValue('addressLine3', address.line3);
        form.setFieldValue('city', address.city);
        form.setFieldValue('state', address.state);
        form.setFieldValue('postalCode', address.postalCode);
        form.setFieldValue('country', address.country);
      }
    });
  };

  const handleSubmit = async (
    values: InvoicingFormValues,
    actions: FormikActions<InvoicingFormValues>,
  ) => {
    if (data) {
      try {
        actions.setSubmitting(true);
        const response = await hubApi.patch(`/issuers/issuer/${id}/`, {
          ...data.data,
          invoicingAddress: values,
        });
        actions.setSubmitting(false);
        mutate(`/issuers/issuer/${id}/`, response);
        toast.success('Issuer contact information was updated successfully.');
      } catch (error) {
        toast.error(JSON.stringify(error.response.data, null, 2));
      }
    }
  };

  if (!error && !data)
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );

  if (error) return <PageWrapper>Error...</PageWrapper>;

  if (data) {
    const { invoicingAddress } = data.data;

    return (
      <PageWrapper>
        <Formik
          initialValues={{
            address: invoicingAddress ? invoicingAddress.address : '',
            addressLine1: invoicingAddress ? invoicingAddress.addressLine1 : '',
            addressLine2: invoicingAddress ? invoicingAddress.addressLine2 : '',
            addressLine3: invoicingAddress ? invoicingAddress.addressLine3 : '',
            company: invoicingAddress ? invoicingAddress.company : '',
            contact: invoicingAddress ? invoicingAddress.contact : '',
            country: invoicingAddress ? invoicingAddress.country : '',
            email: invoicingAddress ? invoicingAddress.email : '',
            notes: invoicingAddress ? invoicingAddress.notes : '',
            postalCode: invoicingAddress ? invoicingAddress.postalCode : '',
            state: invoicingAddress ? invoicingAddress.state : '',
            city: invoicingAddress ? invoicingAddress.city : '',
          }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isValid, isSubmitting }: FormikProps<InvoicingFormValues>) => (
            <Form>
              <div className="flex items-center justify-between">
                <SectionTitle>Contact form</SectionTitle>

                <div>
                  <button
                    className="font-medium text-green hover:underline mr-4 disabled:text-light-gray disabled:cursor-not-allowed disabled:no-underline"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    {isSubmitting ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>

              <div className="bg-very-light-gray border border-light-gray p-8 pt-6 rounded-md grid gap-16 grid-cols-2 mt-4">
                <div>
                  <Field name="address">
                    {({ field, form }: FieldProps<InvoicingFormValues>) => (
                      <div className={`flex flex-col mt-2`}>
                        <Label htmlFor="address">Address</Label>
                        <Input
                          id="address"
                          name={field.name}
                          value={field.value}
                          onBlur={field.onBlur}
                          onChange={(event) =>
                            handleAddressFieldChange({ event, form })
                          }
                        />
                      </div>
                    )}
                  </Field>
                  <InputField name="contact" label="Full Name" />
                  <InputField name="email" label="Email" type="email" />
                  <InputField name="company" label="Company" />
                  <InputField name="addressLine1" label="Address Line 1" />
                  <InputField name="addressLine2" label="Address Line 2" />
                  <InputField name="addressLine3" label="Address Line 3" />
                  <InputField name="city" label="City" />
                  <div className="grid gap-4 grid-cols-3">
                    <InputField name="state" label="State" />
                    <InputField name="postalCode" label="Zip" />
                    <InputField name="country" label="Country" />
                  </div>
                </div>
                <div>
                  <Field name="notes">
                    {({ field }: FieldProps<InvoicingFormValues>) => (
                      <div className="flex flex-col mt-2">
                        <Label htmlFor="notes">Notes</Label>
                        <textarea
                          className="border border-light-gray rounded outline-none focus:border-primary-text p-2"
                          style={{ minHeight: '15rem' }}
                          id="notes"
                          {...field}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </PageWrapper>
    );
  }

  return null;
}

type InputFieldProps = {
  label: string;
  name: string;
  type?: 'text' | 'email' | 'tel';
  className?: string;
};

const InputField = ({
  label,
  name,
  type = 'text',
  className,
}: InputFieldProps) => (
  <Field name={name}>
    {({ field }: FieldProps<InvoicingFormValues>) => (
      <div className={`flex flex-col mt-2 ${className || ''}`}>
        <Label htmlFor={name}>{label}</Label>
        <Input id={name} type={type} {...field} />
      </div>
    )}
  </Field>
);

export { Invoicing };
