import React, { useContext } from 'react';
import { FilingContext } from '../../../data/Filing.Context';
import { Action, ActionTitle, ActionDate } from './styles';
import { OperationsStatus } from '../../../models/filing-details';
import { ActionStatus } from './types';
import { findLatestAction } from './utils';
import { formatDate } from '../../../utils/format-date';

export const ProxyStatusList = () => {
  const { filing } = useContext(FilingContext);

  const newAction = findLatestAction(OperationsStatus.New, filing);
  const activeAction = findLatestAction(OperationsStatus.Active, filing);
  const materialsRequestedAction = findLatestAction(
    OperationsStatus.MaterialsRequested,
    filing,
  );
  const readyForReviewAction = findLatestAction(
    OperationsStatus.ReadyForReview,
    filing,
  );
  const approvedAction = findLatestAction(OperationsStatus.Approved, filing);
  const closedAction = findLatestAction(OperationsStatus.Closed, filing);

  return (
    filing && (
      <>
        {/* New */}
        {filing.operationsStatus === OperationsStatus.New && (
          <>
            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                New {newAction && `(${newAction.username})`}
              </ActionTitle>
              {newAction && (
                <ActionDate>{formatDate(newAction.performedAt)}</ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Active}>
                Awaiting Positions
              </ActionTitle>
            </Action>
          </>
        )}

        {/* Closed */}
        {filing.operationsStatus === OperationsStatus.Closed && (
          <>
            <Action>
              <ActionTitle status={ActionStatus.Danger}>
                New {newAction && `(${newAction.username})`}
              </ActionTitle>
              {newAction && (
                <ActionDate>{formatDate(newAction.performedAt)}</ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Danger}>
                No Positions Found{' '}
                {activeAction && `(${activeAction.username})`}
              </ActionTitle>
              {activeAction && (
                <ActionDate>{formatDate(activeAction.performedAt)}</ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Danger}>
                Closed {closedAction && `(${closedAction.username})`}
              </ActionTitle>
              {closedAction && (
                <ActionDate>{formatDate(closedAction.performedAt)}</ActionDate>
              )}
            </Action>
          </>
        )}

        {/* Active */}
        {filing.operationsStatus === OperationsStatus.Active && (
          <>
            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                New {newAction && `(${newAction.username})`}
              </ActionTitle>
              {newAction && (
                <ActionDate>{formatDate(newAction.performedAt)}</ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                Positions Found {activeAction && `(${activeAction.username})`}
              </ActionTitle>
              {activeAction && (
                <ActionDate>{formatDate(activeAction.performedAt)}</ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Incomplete}>
                Request Materials
              </ActionTitle>
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Incomplete}>
                Mark Ready for Review
              </ActionTitle>
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Incomplete}>
                Approve
              </ActionTitle>
            </Action>
          </>
        )}

        {/* Materials Requested */}
        {filing.operationsStatus === OperationsStatus.MaterialsRequested && (
          <>
            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                New {newAction && `(${newAction.username})`}
              </ActionTitle>
              {newAction && (
                <ActionDate>{formatDate(newAction.performedAt)}</ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                Positions Found {activeAction && `(${activeAction.username})`}
              </ActionTitle>
              {activeAction && (
                <ActionDate>{formatDate(activeAction.performedAt)}</ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                Materials Requested{' '}
                {materialsRequestedAction &&
                  `(${materialsRequestedAction.username})`}
              </ActionTitle>
              {materialsRequestedAction && (
                <ActionDate>
                  {formatDate(materialsRequestedAction.performedAt)}
                </ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Incomplete}>
                Mark Ready for Review
              </ActionTitle>
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Incomplete}>
                Approve
              </ActionTitle>
            </Action>
          </>
        )}

        {/* Ready for Review */}
        {filing.operationsStatus === OperationsStatus.ReadyForReview && (
          <>
            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                New {newAction && `(${newAction.username})`}
              </ActionTitle>
              {newAction && (
                <ActionDate>{formatDate(newAction.performedAt)}</ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                Positions Found {activeAction && `(${activeAction.username})`}
              </ActionTitle>
              {activeAction && (
                <ActionDate>{formatDate(activeAction.performedAt)}</ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                Materials Requested{' '}
                {materialsRequestedAction &&
                  `(${materialsRequestedAction.username})`}
              </ActionTitle>
              {materialsRequestedAction && (
                <ActionDate>
                  {formatDate(materialsRequestedAction.performedAt)}
                </ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                Ready for Review{' '}
                {readyForReviewAction && `(${readyForReviewAction.username})`}
              </ActionTitle>
              {readyForReviewAction && (
                <ActionDate>
                  {formatDate(readyForReviewAction.performedAt)}
                </ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Incomplete}>
                Approve
              </ActionTitle>
            </Action>
          </>
        )}

        {/* Approved */}
        {filing.operationsStatus === OperationsStatus.Approved && (
          <>
            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                New {newAction && `(${newAction.username})`}
              </ActionTitle>
              {newAction && (
                <ActionDate>{formatDate(newAction.performedAt)}</ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                Positions Found {activeAction && `(${activeAction.username})`}
              </ActionTitle>
              {activeAction && (
                <ActionDate>{formatDate(activeAction.performedAt)}</ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                Materials Requested{' '}
                {materialsRequestedAction &&
                  `(${materialsRequestedAction.username})`}
              </ActionTitle>
              {materialsRequestedAction && (
                <ActionDate>
                  {formatDate(materialsRequestedAction.performedAt)}
                </ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                Ready for Review{' '}
                {readyForReviewAction && `(${readyForReviewAction.username})`}
              </ActionTitle>
              {readyForReviewAction && (
                <ActionDate>
                  {formatDate(readyForReviewAction.performedAt)}
                </ActionDate>
              )}
            </Action>

            <Action>
              <ActionTitle status={ActionStatus.Complete}>
                Approved {approvedAction && `(${approvedAction.username})`}
              </ActionTitle>
              {approvedAction && (
                <ActionDate>
                  {formatDate(approvedAction.performedAt)}
                </ActionDate>
              )}
            </Action>
          </>
        )}
      </>
    )
  );
};
