import React, { FC, useState } from 'react';
import { Flex } from '@rebass/grid';
import styled from 'styled-components/macro';

const Wrapper = styled(Flex)`
  color: #fff;
  display: inline-flex;
  flex-direction: column;
`;

const Tag = styled(Flex)`
  align-items: center;
  background: #595959;
  border-radius: 3px;
  font-size: 12px;
  height: 20px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 10px;
  width: min-content;
`;

const ShowButton = styled.button`
  background: transparent;
  border: none;
  color: #8f949e;
  font-size: 12px;
  font-weight: 600;
  padding: 0;
  text-align: left;
  transition: color 0.25s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
  }
`;

interface CusipCellProps {
  cusips: string[];
}

export const CusipCell: FC<CusipCellProps> = ({ cusips }) => {
  const numberOfCusips = cusips.length;
  const firstCusip = cusips[0];
  const remainingCusipLenth = cusips.slice(1).length;
  const [cusipOverflowShown, toggleCusipOverflow] = useState(false);

  return cusips.length ? (
    <Wrapper>
      {cusipOverflowShown ? (
        <>
          <ul>
            {cusips.map((cusip) => (
              <li key={cusip}>
                <Tag>{cusip}</Tag>
              </li>
            ))}
          </ul>
          <ShowButton
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              toggleCusipOverflow(false);
            }}
          >
            Show Less
          </ShowButton>
        </>
      ) : (
        <>
          <Tag>{firstCusip}</Tag>
          {numberOfCusips > 1 && (
            <ShowButton
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                toggleCusipOverflow(true);
              }}
            >
              Show More ({remainingCusipLenth})
            </ShowButton>
          )}
        </>
      )}
    </Wrapper>
  ) : null;
};
