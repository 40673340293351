import styled from 'styled-components/macro';
import {
  FontFamilyProps,
  FontWeightProps,
  TextAlignProps,
  LineHeightProps,
  LetterSpacingProps,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  fontSize,
  FontSizeProps,
} from 'styled-system';
import { Box } from '@rebass/grid';

export interface TextProps
  extends FontFamilyProps,
    FontWeightProps,
    TextAlignProps,
    LineHeightProps,
    LetterSpacingProps,
    FontSizeProps {
  href?: string;
}

export const Text = styled(Box)<TextProps>`
  ${fontSize}
  ${fontFamily}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${letterSpacing}
`;

Text.displayName = 'Text';
