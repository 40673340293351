import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { Textarea } from '../Textarea';
import { IFilingCreateValues } from '../../models/filing-form';
import { Flex } from '@rebass/grid';

export const InvoicerAddress: FC<{}> = () => {
  return (
    <>
      <Field
        name="invoicerAddress"
        render={({
          field,
        }: FieldProps<FilingDetailsFormValues | IFilingCreateValues>) => (
          <Flex flexDirection="column">
            <InputGroup>
              <Label htmlFor="invoicer-address">Invoicer Address</Label>
              <Textarea id="invoicer-address" {...field} rows={5} />
            </InputGroup>
          </Flex>
        )}
      />
    </>
  );
};
