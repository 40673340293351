import React, { FC, useState, useEffect } from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import axios from 'axios';

import styled from 'styled-components/macro';
import { IFilingCreateValues } from '../../models/filing-form';
import { Asterisk } from '../Asterik/Asterik';
import { privateApi } from '../../utils/api-adapter';
import { InputGroup } from '../InputGroup';
import { toast } from 'react-toastify';
import { Label } from '../Label';
import { ICusip } from '../../models/vote-proposal';
import { ErrorLabel } from '../ErrorLabel';

interface IIssuer {
  readonly id: number;
  readonly companyName: string;
  readonly contactEmail: null | string;
}

const IssuerSuggest = styled(Suggest.ofType<IIssuer>())`
  input {
    &:focus {
      outline: none;
    }
  }
`;
const renderInputValue = (issuer: IIssuer) => {
  return issuer.companyName;
};
const renderIssuer: ItemRenderer<IIssuer> = (
  issuer,
  { handleClick, modifiers, query },
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  const text = `${issuer.companyName}`;

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={issuer.id}
      onClick={handleClick}
      text={text}
    />
  );
};

const fetchIssuers = async (query?: string) => {
  try {
    const queryString = query
      ? `?company_name=${encodeURIComponent(query)}`
      : '';
    const response = await privateApi.get(`/issuer-typeahead/${queryString}`);
    return response;
  } catch (error) {
    toast.error('Not able to get issuers');
  }
};

const filterIssuer: ItemPredicate<IIssuer> = (query, issuer) => {
  return (
    `${issuer.companyName.toLocaleLowerCase()}`.indexOf(query.toLowerCase()) >=
    0
  );
};

const addIssuer = async (
  query: string,
  form: FormikProps<IFilingCreateValues>,
) => {
  privateApi
    .post(`/issuers/`, {
      // eslint-disable-next-line no-control-regex
      companyName: query.replace(/[^\x00-\xFF]/g, ''),
    })
    .then((response) => {
      const issuer = response.data.id;
      const { cusips } = form.values;

      const cusipRequests = cusips.map((cusip: ICusip) => {
        return privateApi.post(`/securities/`, {
          issuer,
          cusip: cusip.cusip,
        });
      });

      axios
        .all(cusipRequests)
        .then((responses) => {
          const cusipsToAdd: ICusip[] = responses.map((res) => {
            return {
              id: res.data.id,
              cusip: res.data.cusip,
              name: res.data.name,
            };
          });
          form.setFieldValue('cusips', cusipsToAdd);
          form.setFieldValue('issuer', {
            id: issuer,
            companyName: response.data.companyName,
          });
        })
        .catch((errors) => {
          toast.error('Could not create securities with this issuer');
        });
    })
    .catch((error) => {
      toast.error(error.response.data);
    });
};

const handleItemSelect = (
  fieldValue: IIssuer | null,
  form: FormikProps<IFilingCreateValues>,
  issuer: IIssuer,
) => {
  if (!fieldValue) {
    const { cusips } = form.values;
    const cusipsRequests = cusips.map((cus: ICusip) => {
      const { id, cusip } = cus;
      if (id) {
        return privateApi.patch(`/securities/${id}/`, {
          issuer: issuer.id,
          cusip,
        });
      } else {
        return privateApi.post(`/securities/`, {
          issuer: issuer.id,
          cusip,
        });
      }
    });
    axios
      .all(cusipsRequests)
      .then((responses) => {
        const cusipsToAdd: ICusip[] = responses.map((res) => {
          return {
            id: res.data.id,
            cusip: res.data.cusip,
            name: res.data.name,
          };
        });
        form.setFieldValue('cusips', cusipsToAdd);
        form.setFieldValue('issuer', {
          id: issuer.id,
          companyName: issuer.companyName,
        });
      })
      .catch((errors) => console.log(errors));
  }
};

export const IssuerTypeahead: FC<{}> = () => {
  const [query, setQuery] = useState<string>('');
  const [issuers, setIssuers] = useState<IIssuer[]>([] as IIssuer[]);

  useEffect(() => {
    fetchIssuers(query).then((response) => {
      if (response) {
        setIssuers(response.data);
      }
    });
  }, [query]);

  return (
    <Field
      name="issuer"
      render={({ field, form }: FieldProps<IFilingCreateValues>) => (
        <InputGroup>
          <Label>
            Issuer <Asterisk />{' '}
            {form.errors['issuer'] && (
              <ErrorLabel>{form.errors['issuer']}</ErrorLabel>
            )}
          </Label>
          <IssuerSuggest
            inputValueRenderer={renderInputValue}
            items={field.value ? [] : issuers}
            disabled={field.value && field.value.id}
            itemRenderer={renderIssuer}
            itemPredicate={filterIssuer}
            noResults={
              <MenuItem
                icon={'plus'}
                disabled={!query}
                onClick={() => {
                  addIssuer(query, form);
                }}
                text={'Add New Issuer'}
              />
            }
            query={query}
            popoverProps={{
              popoverClassName: 'issuer-popover',
              position: 'bottom-left',
            }}
            onQueryChange={(typeQuery: string) => {
              setQuery(typeQuery);
            }}
            onItemSelect={(item: IIssuer) => {
              handleItemSelect(field.value, form, item);
            }}
            selectedItem={field.value}
          />
        </InputGroup>
      )}
    />
  );
};
