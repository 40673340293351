import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import { Field, FieldProps } from 'formik';
import { MultiSelect, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import {
  MenuItem,
  ITagProps,
  Intent,
  Button as BPButton,
} from '@blueprintjs/core';
import {
  ICompanyContact,
  IContact,
  CompanyContactRole,
  ITabulator,
  ISolicitor,
} from '../../models/intermediaries';
import { Asterisk } from '../Asterik/Asterik';
import { privateApi } from '../../utils/api-adapter';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { Input } from '../Input';
import { Button } from '../Buttons/Button';
import { Flex } from '@rebass/grid';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { ErrorLabel } from '../ErrorLabel';
import { toast } from 'react-toastify';

interface CompanyContactUpdateTypeaheadProps {
  companyId: number;
  company: ITabulator | ISolicitor;
  role: CompanyContactRole;
  label: string;
  setFieldValueFromProps: (prop: string, value: any) => void;
}

const CompanyContactMultiSelect = MultiSelect.ofType<ICompanyContact>();

const findNameOrEmail = (contact: IContact): string => {
  return contact
    ? contact.email !== ''
      ? contact.email
      : contact.name && contact.name !== ''
      ? contact.name
      : 'No name found'
    : 'No email found';
};
const filterCompanyContact: ItemPredicate<ICompanyContact> = (
  query,
  companyContact,
) => {
  const text = companyContact.contact
    ? `${findNameOrEmail(companyContact.contact)}`
    : 'No contact found';
  return `${text.toLocaleLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

const renderTag = (companyContact: ICompanyContact) => {
  return companyContact.contact
    ? findNameOrEmail(companyContact.contact)
    : 'No contact found';
};

export const CompanyContactUpdateTypeahead: FC<CompanyContactUpdateTypeaheadProps> = ({
  companyId,
  company,
  label,
  role,
  setFieldValueFromProps,
}) => {
  const [query, setQuery] = useState<string>('');
  const [allCompanyContacts, setAllCompanyContacts] = useState<
    ICompanyContact[]
  >([] as ICompanyContact[]);
  const [newContact, updateNewContact] = useState<{
    name: string;
    email: string;
    isDefaultForRole: boolean;
  }>({ name: '', email: '', isDefaultForRole: false });
  const [isCreatingNewContact, setIsCreatingNewContact] = useState<boolean>(
    false,
  );

  const fieldName = role === 'tabulator_reply_to' ? 'sendTo' : 'replyTo';

  useEffect(() => {
    if (fieldName === 'replyTo') {
      const solicitorCompanyContacts = company.companyContacts.filter(
        (companyContact) => companyContact.role === 'solicitor_reply_to',
      );
      setAllCompanyContacts(solicitorCompanyContacts);
    } else if (fieldName === 'sendTo') {
      const tabulatorCompanyContacts = company.companyContacts.filter(
        (companyContact) => companyContact.role === 'tabulator_reply_to',
      );
      setAllCompanyContacts(tabulatorCompanyContacts);
    }
  }, [company, fieldName]);

  return (
    <Field
      name={fieldName}
      render={({ field, form }: FieldProps<FilingDetailsFormValues>) => {
        const getSelectedCompanyContactIndex = (
          companyContact: ICompanyContact,
        ) => {
          return field.value
            .map(
              (mappedCompanyContact: ICompanyContact) =>
                mappedCompanyContact.id,
            )
            .indexOf(companyContact.id);
        };

        const isCompanyContactSelected = (companyContact: ICompanyContact) => {
          return getSelectedCompanyContactIndex(companyContact) !== -1;
        };

        const renderCompanyContact: ItemRenderer<ICompanyContact> = (
          companyContact,
          { handleClick, modifiers },
        ) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }

          const text = companyContact.contact
            ? `${findNameOrEmail(companyContact.contact)}`
            : 'No contact found';

          return (
            <MenuItem
              active={modifiers.active}
              icon={isCompanyContactSelected(companyContact) ? 'tick' : 'blank'}
              key={companyContact.id}
              onClick={handleClick}
              text={text}
            />
          );
        };

        const deselectCompanyContact = (index: number) => {
          const filteredCompanyContacts = field.value.filter(
            (companyContact: ICompanyContact, i: number) => i !== index,
          );
          form.setFieldValue(fieldName, filteredCompanyContacts);
        };

        const selectCompanyContact = (CompanyContact: ICompanyContact) => {
          const CompanyContactsToAdd = [...field.value, CompanyContact];
          form.setFieldValue(fieldName, CompanyContactsToAdd);
        };

        const handleCompanyContactSelect = (
          companyContact: ICompanyContact,
        ) => {
          if (!isCompanyContactSelected(companyContact)) {
            selectCompanyContact(companyContact);
          } else {
            deselectCompanyContact(
              getSelectedCompanyContactIndex(companyContact),
            );
          }
        };

        const getTagProps = (): ITagProps => {
          return {
            intent: Intent.NONE,
            minimal: false,
            style: {
              width: '100%',
            },
          };
        };

        const handleTagRemove = (tag: string, index: number) => {
          deselectCompanyContact(index);
        };

        const handleClear = () => {
          form.setFieldValue(fieldName, []);
        };

        const clearButton = (): JSX.Element => {
          return field.value.length > 0 ? (
            <BPButton icon="cross" minimal={true} onClick={handleClear} />
          ) : (
            <></>
          );
        };

        const handleCreateNewContact = async () => {
          try {
            const contactRequestData = {
              name: newContact.name,
              email: newContact.email,
            };
            setIsCreatingNewContact(true);
            const contactResponse = await privateApi.post(
              `/contacts/`,
              contactRequestData,
            );

            const companyContactRequestData = {
              contactId: contactResponse.data.id,
              companyId: companyId,
              role,
              isDefaultForRole: newContact.isDefaultForRole,
            };
            const companyContactResponse = await privateApi.post(
              '/company-contacts/',
              companyContactRequestData,
            );
            setIsCreatingNewContact(false);
            setAllCompanyContacts([
              ...allCompanyContacts,
              companyContactResponse.data,
            ]);
            form.setFieldValue(fieldName, [
              ...field.value,
              companyContactResponse.data,
            ]);
          } catch (error) {
            setIsCreatingNewContact(false);
            toast.error(error);
          }
        };

        return (
          <InputGroup>
            <Label>
              {label} <Asterisk />{' '}
              {form.errors.replyTo && (
                <ErrorLabel>{form.errors.replyTo}</ErrorLabel>
              )}
            </Label>
            <CompanyContactMultiSelect
              itemPredicate={filterCompanyContact}
              itemRenderer={renderCompanyContact}
              items={allCompanyContacts}
              noResults={
                <Flex
                  as="form"
                  flexDirection="column"
                  width={1}
                  p={2}
                  onSubmit={(e: React.FormEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleCreateNewContact();
                  }}
                >
                  <InputGroup>
                    <Label>
                      Name <Asterisk />
                    </Label>
                    <Input
                      type="text"
                      placeholder="Jeffrey Lebowski"
                      required={true}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        updateNewContact({
                          ...newContact,
                          name: event.currentTarget.value,
                        })
                      }
                    />
                  </InputGroup>
                  <InputGroup>
                    <Label>
                      Email <Asterisk />
                    </Label>
                    <Input
                      type="email"
                      placeholder="dude@ccrfanclub.net"
                      required={true}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        updateNewContact({
                          ...newContact,
                          email: event.currentTarget.value,
                        })
                      }
                    />
                  </InputGroup>
                  <Flex mt={3} alignItems="center">
                    <Input
                      type="checkbox"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        updateNewContact({
                          ...newContact,
                          isDefaultForRole: event.currentTarget.checked,
                        })
                      }
                    />
                    <Label>Is Default for Role?</Label>
                  </Flex>
                  <Button
                    mt={3}
                    disabled={
                      !newContact.email ||
                      !newContact.name ||
                      isCreatingNewContact
                    }
                    type="submit"
                    width={1}
                  >
                    {isCreatingNewContact
                      ? '⏳ Creating Contact'
                      : 'Create New Contact'}
                  </Button>
                </Flex>
              }
              onItemSelect={(item: ICompanyContact) => {
                handleCompanyContactSelect(item);
              }}
              popoverProps={{
                position: 'auto-end',
              }}
              query={query}
              onQueryChange={(typedQuery: string) => {
                setQuery(typedQuery);
              }}
              tagRenderer={renderTag}
              tagInputProps={{
                tagProps: getTagProps(),
                onRemove: (tag: string, index: number) => {
                  handleTagRemove(tag, index);
                },
                rightElement: clearButton(),
              }}
              selectedItems={field.value}
            />
          </InputGroup>
        );
      }}
    />
  );
};
