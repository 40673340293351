import { Node } from 'slate';

export enum DisplayType {
  Document = 'document',
  Email = 'email',
  Ballot = 'ballot',
  CSV = 'csv',
}

export type CurrentDisplay = {
  itemType: DisplayType | null;
  index?: number;
};

// .oOo.oOo.oOo.oOo.oO   FILING   Oo.oOo.oOo.oOo.oOo.

export type FilingListFiling = {
  actionType?: CorporateActionType;
  actualMaterialsRequired: number;
  brokers?: { id: number; name: string; slug: string }[];
  created: Date;
  daysOpen: number;
  deliveryMethod: DeliveryMethod;
  digitalMaterialsExpectedDate: Date | null;
  digitalMaterialsReceivedDate: Date | null;
  digitalSendDeadline: Date | null;
  documents: Document[];
  edgarFilings?: EdgarFiling[];
  electionCutoffDate?: string | null;
  electionCutoffTimezome?: TimeZone;
  expirationDate?: string;
  haveMaterialsBeenRequested: boolean;
  id: number;
  informationAgent?: string;
  informationAgentEmail?: string | null;
  invoicerContactEmail: string | null;
  invoiceSentDate: Date | null;
  issuer: Issuer;
  meetingDate?: Date | null;
  operationsStatus: OperationsStatus;
  paperMaterialsExpectedDate: Date | null;
  paperMaterialsReceivedDate: Date | null;
  paperSendDeadline: Date | null;
  recordDate: string | null;
  recordDateEnd?: string | null;
  recordDateStart?: string;
  referenceMaterials: unknown[];
  securities: Security[];
  solicitor: Solicitor | null;
  solicitorCompany: null | string;
  solicitorContactEmail: null | string;
  solicitorContactName: null | string;
  solicitorJobNumber: null | string;
  stats: null | Stats;
  status: Status;
  type: FilingType;
};

export enum DeliveryMethod {
  NoticeAndAccess = 'notice_and_access',
  Traditional = 'traditional',
}

export enum OperationsStatus {
  Draft = 'draft',
  New = 'new',
  Active = 'active',
  MaterialsRequested = 'materials_requested',
  ReadyForReview = 'ready_for_review',
  Approved = 'approved',
  Closed = 'closed',
}

export enum Status {
  SendMaterialsEstimate = 'send_materials_estimate',
  ConfirmMeetingReportInfo = 'comfirm_meeting_report_info',
  ConfirmMeetingInfo = 'confirm_meeting_info',
  ConfirmReportInfo = 'confirm_report_info',
  SendMaterialsCount = 'send_materials_count',
  ReceiveMaterials = 'receive_materials',
  SendMaterials = 'send_materials',
  SendInvoice = 'send_invoice',
  Closed = 'closed',
}

// .oOo.oOo.oOo.oOo.oO   STATS   Oo.oOo.oOo.oOo.oOo.

export type Stats = {
  brokerCount: number;
  digitalSendDeadline: string;
  internalDigitalSendDeadline: string;
  estimatedDigitalMaterialsExpectedDate: string | null;
  estimatedFullSharePositions: number;
  estimatedMaterialsRequired: number;
  estimatedTotalPositions: number;
  fractionalSharePositions: number;
  fullSharePositions: number;
  invoiceDate: string;
  invoiceDueDate: string;
  lastDigitalMaterialSendDate: string;
  lastPaperMaterialSendDate: string;
  materialsRequired: number;
  paperSendDeadline: string;
  totalPositions: number;
};

// .oOo.oOo.oOo.oOo.oO   FILING TYPE   Oo.oOo.oOo.oOo.oOo.

export enum FilingType {
  FundReport = 'FundReport',
  FirmInformationStatement = 'FirmInformationStatement',
  FundInformationStatement = 'FundInformationStatement',
  FirmConsentSolicitation = 'FirmConsentSolicitation',
  FirmMeeting = 'FirmMeeting',
  FundMeeting = 'FundMeeting',
  MeetingContest = 'MeetingContest',
}

// .oOo.oOo.oOo.oOo.oO   DOCUMENT   Oo.oOo.oOo.oOo.oOo.

export type Document = {
  attachment: string | null;
  filing: number;
  id: number;
  isInternal: boolean;
  name: string;
  pdf: null | string;
  securities: number[];
  shouldDistributeInNextMailing: boolean;
  sortOrder: number | null;
  type: DocumentType;
};

export enum DocumentType {
  ProxyStatement = 'proxy_statement',
  ProxyCard = 'proxy_card',
  TenK = '10_k',
  AnnualReport = 'annual_report',
  SemiAnnualReport = 'semi_annual_report',
  StatutoryProspectus = 'statutory',
  SummaryProspectus = 'summary',
  ConsentSolicitation = 'consent_solicitation',
  InformationStatement = 'information_statement',
}

// .oOo.oOo.oOo.oOo.oO   VOTE PROPOSAL   Oo.oOo.oOo.oOo.oOo.

export type ProposalType =
  | 'BoardOfDirectorsNomination'
  | 'PlanOfMergerProposalItem'
  | 'AdjournmentProposalItem'
  | 'ExecutiveCompensationProposalItem'
  | 'ManagementProposalItem'
  | 'ShareholderProposalItem'
  | 'SayOnPay'
  | 'RatificationOfAuditors'
  | 'Other';

export type VoteType =
  | ''
  | 'election_majority'
  | 'election_plurality'
  | 'election_majority_yesno'
  | 'election_majority_foragainst'
  | 'election_majority_forwithholdabstain'
  | 'election_majority_foragainstwithhold'
  | 'election_majority_forabstain';

export type ElectionType =
  | 'yes_no'
  | 'yes_no_partial'
  | 'cash_stock'
  | 'cash_stock_mixed';

export type RecommendationType =
  | 'abstain'
  | 'against'
  | 'for'
  | 'no'
  | 'None'
  | 'one_year'
  | 'three_years'
  | 'two_years'
  | 'withhold'
  | 'cash'
  | 'stock'
  | 'mixed'
  | 'yes';

export type Routineness = 'routine' | 'nonRoutine';

export type VoteProposalPayload = {
  id: number;
  isRoutine: boolean;
  proposalNumber: number;
  recommendationType: RecommendationType;
  securities: number[];
  title: string;
  type: ProposalType;
  tooltipText: TooltipText;
  voteType?: VoteType;
  directorName?: string;
  detail?: string;
  groupNumber: number;
};

export type TooltipText = Node[] | string;

export enum SayOnPayRecTypes {
  None = 'None',
  OneYear = 'One_year',
  ThreeYears = 'Three_years',
  TwoYears = 'Two_years',
  Abstain = 'Abstain',
}

export type MeetingResult = {
  id: number;
  voteProposal: number;
  choice: string;
  totalShares: string;
};

export type VoteProposal = {
  details?: string;
  directorName?: string;
  filing: number;
  groupNumber: number;
  id: number;
  isRoutine: boolean;
  proposalNumber: number;
  recommendationType: RecommendationType;
  securities: number[];
  title: string;
  votedAtMeeting: boolean;
  type: ProposalType;
  voteChoices: string[];
  voteType: VoteType;
  meetingResults?: MeetingResult[];
  tooltipText: TooltipText;
};

export type ElectionItems = {
  id: number;
  corporateAction: number;
  proposalNumber: number;
  groupNumber: number;
  electionType: ElectionType;
  recommendationType: RecommendationType;
  securities: number[];
  details: string;
  voteChoices: string[];
  title: string;
};

export type Cusip = {
  readonly id: number;
  readonly cusip: string;
  readonly name: string;
};

// .oOo.oOo.oOo.oOo.oO   TIMEZONE   Oo.oOo.oOo.oOo.oOo.

export enum TimeZone {
  USAtlantic = 'Atlantic/Bermuda',
  USAlaska = 'US/Alaska',
  USArizona = 'US/Arizona',
  USCentral = 'US/Central',
  USEastern = 'US/Eastern',
  USHawaii = 'US/Hawaii',
  USMountain = 'US/Mountain',
  USPacific = 'US/Pacific',
  AsiaJerusalem = 'Asia/Jerusalem',
  AsiaShanghai = 'Asia/Shanghai',
  AsiaSeoul = 'Asia/Seoul',
  EuropeAmsterdam = 'Europe/Amsterdam',
  EuropeBerlin = 'Europe/Berlin',
  EuropeLondon = 'Europe/London',
  EuropeMadrid = 'Europe/Madrid',
  EuropeParis = 'Europe/Paris',
  EuropeRome = 'Europe/Rome',
  EuropeAthens = 'Europe/Athens',
}

// .oOo.oOo.oOo.oOo.oO   INTERMEDIARIES   Oo.oOo.oOo.oOo.oOo.

export type Tabulator = {
  id: number;
  created: Date;
  modified: Date;
  name: string;
  address: string;
  companyContacts: CompanyContact[];
  type: string;
};

export type Solicitor = {
  id: number;
  created: Date;
  modified: Date;
  name: string;
  defaultTabulator: Tabulator | null;
  companyContacts: CompanyContact[];
  type: string;
};

export type Contact = {
  id: number;
  email: string;
  phone: string;
  name: string | null;
};

export type CompanyContact = {
  id: number;
  contact?: Contact;
  role: CompanyContactRole;
  isDefaultForRole: boolean;
};

export type CompanyContactRole =
  | 'solicitor_receive_from'
  | 'solicitor_reply_to'
  | 'tabulator_reply_to'
  | 'other';

// .oOo.oOo.oOo.oOo.oO   FILING DETAILS   Oo.oOo.oOo.oOo.oOo.

export type Action = {
  action: OperationsStatus;
  created: string;
  id: number;
  modified: string;
  performedAt: string;
  username: string;
};

export type Security = {
  id: number;
  created: string;
  modified: string;
  ticker: string | null;
  cusip: string;
  name: string;
  isin: string | null;
  issuer: number;
};

export type Issuer = {
  cik: number | null;
  edgarLandingPage: string | null;
  id: number;
  companyName: string;
  contactEmail: string | null;
  globalIssuerId: string;
};

export enum MeetingType {
  Annual = 'annual',
  Special = 'special',
}

export enum ProxyType {
  Traditional = 'traditional',
  Unspecified = 'unspecified',
}

export type Meeting = {
  address: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  contested: boolean;
  country: string;
  date: Date;
  isPhysical: boolean;
  postalCode: string;
  proxyType: ProxyType;
  state: string;
  time: string;
  timezone: TimeZone;
  title: null | string;
  type: MeetingType;
  virtualMeetingUrl: null | string;
};

export enum ReportType {
  Annual = 'annual',
  SemiAnnual = 'semi_annual',
}

export type BulkUploadSet = {
  id: number;
  csvFile: string;
};

export type CorporateActionApproval = {
  id: number;
  broker: number;
  corporateAction: number;
};

export type FilingDetails = {
  actionDetail?: string;
  actionHistory: Action[];
  actionType?: CorporateActionType;
  actualMaterialsRequired: number;
  brokerSearchReceivedDate: Date;
  bulkUploadSet: BulkUploadSet;
  bulkUploadSetId: number;
  changesets: Changeset[];
  checkListId: number;
  companyContactIds: number[];
  companyContacts: CompanyContact[];
  contestingEntity?: string;
  contraCusip?: string;
  corporateActionApprovals?: CorporateActionApproval[];
  deliveryMethod: DeliveryMethod;
  digitalMaterialsExpectedDate: Date;
  digitalMaterialsReceivedDate: Date;
  documents: Document[];
  dtcExpirationDate?: string | null;
  edgarFilings?: EdgarFiling[];
  electionCutoffDate?: string | null;
  electionCutoffTime?: string | null;
  electionItems?: ElectionItems[];
  expirationDate?: string;
  externalHostUrl: string;
  filingDate: Date;
  fundOwner?: string;
  haveMaterialsBeenRequested: boolean;
  id: number;
  informationAgent?: string;
  informationAgentAddress?: string;
  informationAgentEmail?: string;
  informationAgentPhone?: string;
  invoicerAddress: string;
  invoicerAddressLine1: string;
  invoicerAddressLine2: string;
  invoicerAddressLine3: string;
  invoicerCity: string;
  invoicerCompanyName: string;
  invoicerContactEmail: string;
  invoicerContactName: string;
  invoicerContactPhoneNumber: string;
  invoicerCountry: string;
  invoicerPostalCode: string;
  invoicerState: string;
  isSolicitorInvoicing: boolean;
  isSolicitorPrinting: boolean;
  isSolicitorTabulating: boolean;
  issuer: Issuer;
  issuerFiling?: number;
  issuerId: number;
  meeting?: Meeting;
  meetingDate?: string | null;
  notes: string;
  notificationReceivedDate?: string | null;
  offerer?: string;
  offererTerms?: string;
  operationsStatus: OperationsStatus;
  paperMaterialsExpectedDate: Date;
  paperMaterialsReceivedDate: Date;
  paperMaterialsSentDate: Date;
  paperSendDeadline: Date;
  payoutCurrency?: string;
  recordDate: string;
  additionalRecordDates?: string[];
  recordDateEnd?: string | null;
  recordDateStart?: string;
  referenceMaterials: Reference[];
  reportType?: ReportType;
  securities: Security[];
  securityIds: number[];
  solicitor: Solicitor;
  solicitorAddressLine1: string;
  solicitorAddressLine2: string;
  solicitorCity: string;
  solicitorCompany: string;
  solicitorContactEmail: string;
  solicitorContactName: string;
  solicitorContactPhoneNumber: string;
  solicitorId: number;
  solicitorJobNumber: string;
  solicitorState: string;
  solicitorZip: string;
  stats: Stats;
  tabulator?: Tabulator;
  tabulatorAddressLine1?: string;
  tabulatorAddressLine2?: string;
  tabulatorCity?: string;
  tabulatorCompanyName?: string;
  tabulatorContactEmail?: string;
  tabulatorContactName?: string;
  tabulatorId?: number;
  tabulatorPhoneNumber?: string;
  tabulatorState?: string;
  tabulatorZip?: string;
  type: FilingType;
  voteCutoffDate?: Date;
  voteCutoffTime?: string;
  voteCutoffTimezone?: TimeZone;
  voteProposals?: VoteProposal[];
};

export interface Changeset {
  after: any;
  before: any;
  created: string;
  type: ChangesetType;
  user: {
    username: string;
    firstName: string;
    lastName: string;
  } | null;
}

export type ChangesetType =
  | 'attribute'
  | 'security'
  | 'filing_created'
  | 'vote_proposal_created'
  | 'vote_proposal_modified'
  | 'vote_proposal_deleted';

export type Reference = {
  created: string;
  csvData: null | string;
  csvSource: null | string;
  emailAddress: null | string;
  html: null | string;
  sourceType: SourceType;
  subject: null | string;
  text: null | string;
  wasApplied: boolean;
};

enum SourceType {
  'new_email' = 'Broker Search',
  'update_email' = 'Broker Search Update',
  'proposals_email' = 'Meeting Agenda',
  'materials_email' = 'Material URL(s)',
  'csv' = 'CSV',
}

export enum CorporateActionType {
  BIDS = 'Repurchase Offer',
  DRIP = 'Dividend Reinvestment',
  DIVO = 'Dividend Option',
  CONE = 'Consideration Election',
  TEND = 'Tender Offer',
  EXOF = 'Exchange Offer',
  ODLT = 'Odd Lot Sale/Purchase',
  RHTS = 'Rights Issue',
  PRIO = 'Priority Issue',
}

// .oOo.oOo.oOo.oOo.oO   EDGAR   Oo.oOo.oOo.oOo.oOo.

export type EdgarFiling = {
  cik: number;
  id: number;
  type: EdgarFilingType;
  url: string;
  status: EdgarFilingStatus;
  filingDate: string | null;
  created: string;
};

export type EdgarFilingType =
  | 'DEF 14A'
  | 'DEF 14C'
  | 'DEFA14A'
  | 'DEFA14C'
  | 'DEFC14A'
  | 'DEFC14C'
  | 'DEFM14A'
  | 'DEFM14C'
  | 'DEFN14A'
  | 'DEFR14A'
  | 'DEFR14C'
  | 'DFAN14A'
  | 'DFRN14A'
  | '8-K'
  | '6-K'
  | '6-K/A'
  | 'S-4'
  | 'S-4/A';

export type EdgarFilingStatus = 'pending' | 'confirmed' | 'dismissed';

// .oOo.oOo.oOo.oOo.oO   DATA   Oo.oOo.oOo.oOo.oOo.

export type FilingDetailsFormValues = {
  brokerSearchReceivedDate: Date | null;
  cusips: Cusip[];
  brokers?: { id: number; name: string; slug: string }[];
  deliveryMethod: DeliveryMethod;
  digitalMaterialsReceivedDate: Date | null;
  digitalSendDeadline: string | null;
  expectedDate: Date | null;
  fundOwner?: string;
  invoicerAddress?: string;
  invoicerAddressLine1?: string;
  invoicerAddressLine2?: string;
  invoicerAddressLine3?: string;
  invoicerCity?: string;
  invoicerState?: string;
  invoicerPostalCode?: string;
  invoicerCountry?: string;
  invoicerContactEmail?: string;
  issuer: { id: number; name: string } | null;
  meetingAddress: string | null;
  meetingDate: Date | null;
  meetingTime: string | null;
  meetingTimezone: TimeZone | null;
  meetingType: MeetingType;
  notes?: string;
  paperMaterialsReceivedDate: Date | null;
  paperSendDeadline: string | null;
  recordDate: string | null;
  recordDateCompare: boolean;
  additionalRecordDates?: string[];
  replyTo?: CompanyContact[];
  reportType?: ReportType;
  sendTo?: CompanyContact[];
  solicitor?: Solicitor;
  solicitorJobNumber?: string;
  tabulator?: Tabulator;
  type: FilingType;
  virtualMeetingUrl: string | null;
  voteCutoffDate: Date | null;
  voteCutoffTime: string | null;
  voteCutoffTimezone: TimeZone | null;
  meetingFormat: string | '';
};

export type IFilingCreateValues = {
  issuer: IFilingCreateIssuer | null;
  deliveryMethod: DeliveryMethod | null;
  type: FilingType;
  reportType: ReportType;
  fundOwner?: string;
  cusips: {
    id: number;
    cusip: string;
    name: string;
  }[];
  brokers?: Broker[];
  expectedDate?: Date | null;
  recordDate?: Date | null;
  brokerSearchReceivedDate?: Date | null;
  meetingDate?: Date | null;
  meetingType?: MeetingType;
  solicitorJobNumber?: string;
  solicitor?: Solicitor;
  tabulator?: Tabulator;
  issuerFiling?: string;
  notes?: string;
  replyTo?: CompanyContact[];
  sendTo?: CompanyContact[];
  invoicerAddress?: string;
  invoicerAddressLine1?: string;
  invoicerAddressLine2?: string;
  invoicerAddressLine3?: string;
  invoicerCity?: string;
  invoicerState?: string;
  invoicerPostalCode?: string;
  invoicerContactEmail?: string;
  voteCutoffDate?: string;
  relatedFiling?: RelatedFiling | null;
};

export type RelatedFiling = {
  deliveryMethod: DeliveryMethod;
  meetingDate: string | null;
  solicitorCompany: string | null;
  issuer: Issuer;
  recordDate: string | null;
};

export type Broker = {
  id: number;
  name: string;
  slug: string;
};

type IFilingCreateIssuer = {
  id: number | null;
  companyName: string;
  contactEmail: null | string;
};

// Review email

export interface BrokersResponse {
  count: number;
  next: string;
  previous: string;
  results: Result[];
}

export interface Result {
  id: number;
  name: string;
  slug: string;
  theme: Theme | null;
  introducingBroker: null | string;
  isElectronicOnly: boolean;
}

export interface Theme {
  primaryColor: null | string;
  secondaryColor: null | string;
  bannerBackgroundColor: null | string;
  bannerTextColor: string | null;
  bannerGradient: null | string;
  ctaBackgroundColor: null | string;
  ctaTextColor: string | null;
  linkColor: null | string;
  logo: null | string;
  logoMultiplier: string;
  logoEmailWidth: number;
  logoEmailHeight: number;
  brokerTermsUrl: null | string;
  brokerPrivacyUrl: null | string;
}
