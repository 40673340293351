import React, { FC } from 'react';
import { Modal } from '../../common/Modal';
import styled from 'styled-components/macro';
import { Dialog } from '../../common/Dialog/Dialog';
import { OutlineButton as DefaultOutlineButton } from '../../common/Buttons/OutlineButton';
import { Button as DefaultButton } from '../../common/Buttons/Button';

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'text text'
    'cancel-button confirm-button';
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  width: 100%;
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights[2]};
  color: ${({ theme }) => theme.colors.black};
  grid-area: text;
`;

const OutlineButton = styled(DefaultOutlineButton)`
  grid-area: cancel-button;
`;

const Button = styled(DefaultButton)`
  grid-area: confirm-button;
`;

interface UnmarkRequestedModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onUndoMarkRequested: () => void;
}

export const UnmarkRequestedModal: FC<UnmarkRequestedModalProps> = ({
  isOpen,
  onRequestClose,
  onUndoMarkRequested,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Dialog title="Didn't Request Materials?" onRequestClose={onRequestClose}>
        <Wrapper>
          <Text>
            Are you sure you want to unmark this filing as materials requested?
          </Text>

          <OutlineButton as="button" onClick={onRequestClose}>
            No
          </OutlineButton>

          <Button as="button" onClick={onUndoMarkRequested}>
            Yes
          </Button>
        </Wrapper>
      </Dialog>
    </Modal>
  );
};
