import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { IFilingCreateValues } from '../../models/filing-form';
import { Label } from '../Label';
import { Select } from '../Select';
import { InputGroup } from '../InputGroup';
import { FilingDetailsFormValues } from '../../Filing/Filing';

interface ReportTypeFieldProps {
  disabled?: boolean;
}

export const ReportTypeField: FC<ReportTypeFieldProps> = ({ disabled }) => {
  return (
    <Field
      name="reportType"
      render={({
        field,
      }: FieldProps<IFilingCreateValues | FilingDetailsFormValues>) => (
        <InputGroup>
          <Label htmlFor="report-type">Report Type</Label>

          <Select id="repory-type" {...field} disabled={disabled}>
            <option value="annual">Annual</option>
            <option value="semi_annual">Semi-Annual</option>
          </Select>
        </InputGroup>
      )}
    />
  );
};
