import styled from 'styled-components/macro';
import { PlainButton } from './PlainButton';
import { darken } from 'polished';

export const PlainDangerButton = styled(PlainButton)`
  color: ${({ theme }) => theme.colors.darkGrayText};
  transition: color 0.25s ease;

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.colors.red)};
  }
`;
