import React from 'react';
import { OperationsStatus } from './types';
import { StatusBadge } from './StatusBadge';

type BrokerEmailStatusBarProps = {
  status: OperationsStatus;
  actionButton: React.ReactNode;
};

function BrokerEmailStatusBar({
  status,
  actionButton,
}: BrokerEmailStatusBarProps) {
  return (
    <div className="border-b border-light-gray py-4">
      <div className="px-8 flex items-center justify-center">
        <div className="font-medium text-sm text-primary-text flex items-center">
          <span className="mr-1">Status:</span> <StatusBadge status={status} />
        </div>

        <div className="absolute" style={{ right: '2rem' }}>
          {actionButton}
        </div>
      </div>
    </div>
  );
}

export { BrokerEmailStatusBar };
