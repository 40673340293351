import React, { FC, ChangeEvent } from 'react';
import { Field, FieldProps } from 'formik';

import { InputGroup } from '../InputGroup';
import { Label } from '../Label';
import { AddField } from '../AddField';
import styled from 'styled-components/macro';
import { RemoveField } from '../RemoveField';
import {
  IVoteProposalFormValues,
  IVoteProposalFormDetail,
} from '../../models/vote-proposal';
import { Textarea } from '../Textarea';
import { privateApi } from '../../utils/api-adapter';
import { ErrorLabel } from '../ErrorLabel';

interface IProps {
  details?: IVoteProposalFormDetail[];
}

const StyledLabel = styled(Label)`
  margin: 25px 0 5px 0;
`;

export const ProposalDetails: FC<IProps> = ({ details }) => {
  return (
    <InputGroup>
      {details && details.length > 0 && (
        <>
          {details.map((director: IVoteProposalFormDetail, index: number) => {
            return (
              <Field
                name={`details[${index}]`}
                key={`details.${index}`}
                value={director}
                render={({
                  field,
                  form,
                }: FieldProps<IVoteProposalFormValues>) => (
                  <>
                    <StyledLabel htmlFor={`proposal-details-${index}`}>
                      Proposal Details {index + 1}{' '}
                      {details.length > 1 && (
                        <RemoveField
                          handleClick={() => {
                            const filteredDetails = details.filter(
                              (director: IVoteProposalFormDetail, i) =>
                                i !== index,
                            );
                            if (details[index].id !== null) {
                              privateApi
                                .delete(`/vote-proposals/${details[index].id}/`)
                                .then((response) => {
                                  form.setFieldValue(
                                    'details',
                                    filteredDetails,
                                  );
                                })
                                .catch((error) => {
                                  console.error(error);
                                });
                            } else {
                              form.setFieldValue('details', filteredDetails);
                            }
                            form.setFieldValue('details', filteredDetails);
                          }}
                        />
                      )}
                    </StyledLabel>
                    {form.errors.details &&
                      form.errors.details[index] &&
                      form.touched.details && (
                        <ErrorLabel>
                          Proposal Details cannot be blank
                        </ErrorLabel>
                      )}
                    <Textarea
                      width={1}
                      value={field.value.detail}
                      id={`proposal-details-${index}`}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        form.setFieldValue(
                          `details[${index}].detail`,
                          e.target.value,
                        );
                      }}
                    />
                    {index === details.length - 1 && (
                      <AddField
                        label="Details"
                        disabled={details[details.length - 1].detail === ''}
                        onClick={() => {
                          if (details[details.length - 1].detail !== '') {
                            form.setFieldValue('details', [
                              ...details,
                              { detail: '', id: null },
                            ]);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              />
            );
          })}
        </>
      )}
    </InputGroup>
  );
};
