import React from 'react';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';

interface Props {
  totalSelected: number;
  finishedSelected: number;
  currentPosition: number;
}

function BatchBar({ totalSelected, finishedSelected, currentPosition }: Props) {
  const progress = (finishedSelected / totalSelected) * 100;

  return (
    <Wrapper>
      <Text>
        {currentPosition} of {totalSelected} selected
      </Text>

      <ProgressWrapper>
        <Text>Finished</Text>{' '}
        <Progress variant="determinate" value={progress} />{' '}
        <Text>{progress}%</Text>
      </ProgressWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #11cc99;
  height: 24px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.span`
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: #ffffff;
  text-transform: uppercase;
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Progress = styled(LinearProgress)`
  background: #00000033;
  width: 94px;
  margin: 0 8px;

  .MuiLinearProgress-barColorPrimary {
    background: #fff;
  }
`;

export { BatchBar };
