import React from 'react';
import { formatDate } from '../../../../utils/format-date';
import { ReactComponent as CustomerIcon } from '../../../../components/Icons/customer.svg';
import { ReactComponent as ChevronIcon } from '../../../../components/Icons/chevron.svg';
import { ReactComponent as CalendarIcon } from '../../../../components/Icons/calendar.svg';
import { Security, ProspectusStatus } from '../../Prospectus';
import { ProspectusRow } from './ProspectusRow';
import { formatNumber } from '../../../../utils/format-number';

type SecurityRowProps = {
  security: Security;
  status: ProspectusStatus;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  showCheckmark?: boolean;
  isEditable?: boolean;
  onUpdateSuccess?: () => void;
};

const SecurityRow = ({
  security,
  status,
  onChange,
  isChecked,
  showCheckmark = true,
  isEditable = true,
  onUpdateSuccess,
}: SecurityRowProps) => {
  const hasProspectuses = security.prospectuses[status].length > 0;
  const [isExpanded, setIsExpanded] = React.useState(hasProspectuses);
  const summaryAndStatutoryProspectus = security.prospectuses[status].find(
    (prospectus) =>
      prospectus.type === 'summary' || prospectus.type === 'statutory',
  );
  const expectedDate =
    summaryAndStatutoryProspectus &&
    summaryAndStatutoryProspectus.effectiveEndDate;
  const isPastExpectedDate =
    expectedDate && determineIfPastToday(new Date(expectedDate));

  const toggleProspectusRows = () => setIsExpanded(!isExpanded);

  return (
    <React.Fragment>
      <div
        className={`grid ${
          showCheckmark
            ? 'grid-cols-prospectusSecurityRow'
            : 'grid-cols-prospectusSecurityRowWithoutCheckmark'
        } gap-4 items-center w-full border border-light-gray px-2 py-4 cursor-pointer bg-white mt-2`}
        onClick={toggleProspectusRows}
      >
        {showCheckmark && (
          <label className="hover:bg-light-gray flex items-center justify-center rounded-md w-6 h-6">
            <input
              type="checkbox"
              onChange={onChange}
              onClick={(event) => event.stopPropagation()}
              checked={isChecked}
              className="appearance-none border border-gray h-4 w-4 bg-white checkbox focus:outline-none hover:border-primary-text cursor-pointer"
            />
          </label>
        )}
        <div className="border border-primary-text rounded text-xs px-1">
          {security.cusip}
        </div>
        <div>
          <span className="text-primary-text font-medium border-b border-green">
            {security.ticker}:
          </span>{' '}
          <span className="text-secondary-text">{security.name}</span>
        </div>
        <div className="flex items-center">
          <span className="text-primary-text font-medium">
            {formatNumber(security.stats.monthlyTrades)}
          </span>
          <CustomerIcon className="ml-2" />
        </div>
        <div
          className={`flex items-center ${
            isPastExpectedDate
              ? 'text-red'
              : expectedDate
              ? 'text-primary-text'
              : 'text-secondary-text'
          }`}
        >
          <span>
            {expectedDate ? formatDate(new Date(expectedDate)) : '-- -- -- --'}
          </span>
          <CalendarIcon className="text-secondary-text ml-3" />
        </div>

        <div
          className={`flex items-center ${
            !summaryAndStatutoryProspectus ? 'justify-between' : 'justify-end'
          }`}
        >
          {!summaryAndStatutoryProspectus && (
            <div className="bg-red text-white font-medium text-xxs h-3 w-3 rounded-full flex items-center justify-center mr-2">
              !
            </div>
          )}

          <div className={`mr-2 ${isExpanded ? 'transform rotate-90' : ''}`}>
            <ChevronIcon />
          </div>
        </div>
      </div>

      {isExpanded &&
        hasProspectuses &&
        security.prospectuses[status].map((prospectus) => (
          <ProspectusRow
            key={prospectus.id}
            isEditable={isEditable}
            prospectus={prospectus}
            onUpdateSuccess={onUpdateSuccess}
          />
        ))}

      {isExpanded && !hasProspectuses && (
        <div className="p-8 border border-light-gray flex items-center justify-center w-full bg-white text-sm text-gray">
          No Documents
        </div>
      )}
    </React.Fragment>
  );
};

const determineIfPastToday = (date: Date) =>
  new Date().getTime() > date.getTime();

export { SecurityRow, determineIfPastToday };
