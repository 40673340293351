import React from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import { ThumbnailList } from './ThumbnailList';
import { PreviewPane } from './PreviewPane';
import styled from 'styled-components';
import { SendTestForm } from './SendTestForm';
import { PreviewViewSwitcher } from './PreviewViewSwitcher';
import { useBrokers } from '../useBrokers';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

type Props = RouteComponentProps;

function ReviewEmail(_: Props) {
  const params = useParams();
  const filingId = params.id;
  const brokers = useBrokers();
  const [query, setQuery] = useQueryParams({
    broker: withDefault(
      StringParam,
      brokers.data ? brokers.data.data.results[0].slug : '',
    ),
    view: withDefault(StringParam, 'email'),
  });

  return (
    <Wrapper>
      <ThumbnailList
        filingId={filingId}
        onClick={(slug) => setQuery({ broker: slug })}
        selectedBroker={query.broker}
      />

      <TopBar>
        <SendTestForm filingId={filingId} selectedBroker={query.broker} />
        <PreviewViewSwitcher
          onChange={(view) => setQuery({ view })}
          selectedView={query.view}
        />
      </TopBar>

      <PreviewPane
        selectedBroker={query.broker}
        filingId={filingId}
        selectedView={query.view}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'thumbnailList topBar'
    'thumbnailList previewPane';
  grid-template-columns: 220px 1fr;
  grid-template-rows: 40px 1fr;
  grid-row-gap: 24px;
  width: 100%;
  overflow: hidden;
  max-height: calc(100vh - 15rem);
`;

const TopBar = styled.div`
  background: #fff;
  grid-area: topBar;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  position: sticky;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export { ReviewEmail };
