import styled from 'styled-components/macro';
import { Flex } from '@rebass/grid';

export const InputGroup = styled(Flex)`
  margin-top: ${({ theme }) => theme.spacing[3]}px;
  flex-direction: column;
  position: relative;
`;

export const InputGroupRow = styled(InputGroup)`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`;
