import styled from 'styled-components/macro';
import { space, SpaceProps, width, WidthProps } from 'styled-system';

interface InputProps extends SpaceProps, WidthProps {}

export const Input = styled.input<InputProps>`
  ${space}
  ${width}
  border: 2px solid ${({ theme }) => theme.colors.lightDivider};
  border-radius: ${({ theme }) => theme.radii[2]}px;
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  height: 30px;
  padding: 0 10px;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.divider};
    outline: none;
  }
`;
