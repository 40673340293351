import React from 'react';
import { PageHeader } from '../../components/PageHeader';
import { Tabs } from '../../components/Tabs';
import useSWR from 'swr';
import { hubApi } from '../../old/data/hubApiConfig';
import { TradeAlerts } from './trade-alerts';
import { Upcoming } from './upcoming';
import { Router, RouteComponentProps } from '@reach/router';
import { MissingExpired } from './missing-expired';
import { getPageOffset } from '../../utils/get-page-offset';

export type Response<T> = {
  count: number;
  next: null | string;
  previous: null | string;
  results: T[];
};

export type UpcomingResult = {
  id: string;
  name: string;
  securities: Security[];
  tradeCount: number;
  earliestEffectiveEndDate: string;
  summaryEffectiveEndDate: string;
};

export type Security = {
  id: string;
  cusip: string;
  ticker: null | string;
  name: string;
  securityType: null | string;
  price: null | string;
  priceLastUpdated: null | string;
  primaryColor: null | string;
  iconUrl: null | string;
};

export type TradeAlertsResult = {
  id: string;
  securityName: string;
  issuerName: string;
  cusip: string;
  tradeCount: number;
  deadline: string;
  effectiveDate: null | string;
  estimatedUpcomingDate: null | string;
  issuerId: string;
  ticker: string;
};

export type MissingExpiredResult = {
  hasMissingProspectus: boolean;
  id: string;
  name: string;
  securities: Security[];
  tradeCount: number;
  effectiveDate: null | string;
  expectedDate: null | string;
};

export type Query = {
  pageSize: number;
  page: number;
  ordering: string;
};

export type OnUpdateQuery = (
  taskList: 'tradeAlerts' | 'upcoming' | 'missingExpired',
  query: 'pageSize' | 'page' | 'ordering',
  value: string | number,
) => void;

const upcomingFetcher = (url: string) =>
  hubApi.get<Response<UpcomingResult>>(url);
const tradeAlertsFetcher = (url: string) =>
  hubApi.get<Response<TradeAlertsResult>>(url);
const missingExpiredFetcher = (url: string) =>
  hubApi.get<Response<MissingExpiredResult>>(url);

const Prospectuses: React.FC<RouteComponentProps> = () => {
  const [tradeAlertsQuery, setTradeAlertsQuery] = React.useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: 'deadline',
  });
  const [upcomingQuery, setUpcomingQuery] = React.useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: 'summary_effective_end_date',
  });
  const [missingExpiredQuery, setMissingExpiredQuery] = React.useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: 'expected_date',
  });

  const tradeAlerts = useSWR(
    `/securities/prospectuses/trade-alerts/?limit=${
      tradeAlertsQuery.pageSize
    }&ordering=${tradeAlertsQuery.ordering}&offset=${getPageOffset({
      page: tradeAlertsQuery.page,
      pageSize: tradeAlertsQuery.pageSize,
    })}`,
    tradeAlertsFetcher,
  );
  const upcoming = useSWR(
    `/securities/prospectuses/upcoming/?limit=${
      upcomingQuery.pageSize
    }&ordering=${upcomingQuery.ordering}&offset=${getPageOffset({
      page: upcomingQuery.page,
      pageSize: upcomingQuery.pageSize,
    })}`,
    upcomingFetcher,
  );
  const missingExpired = useSWR(
    `/securities/prospectuses/missing/?limit=${
      missingExpiredQuery.pageSize
    }&ordering=${missingExpiredQuery.ordering}&offset=${getPageOffset({
      page: missingExpiredQuery.page,
      pageSize: missingExpiredQuery.pageSize,
    })}`,
    missingExpiredFetcher,
  );

  const handleUpdateQuery: OnUpdateQuery = (taskList, query, value) => {
    switch (taskList) {
      case 'missingExpired':
        setMissingExpiredQuery({ ...missingExpiredQuery, [query]: value });
        break;
      case 'tradeAlerts':
        setTradeAlertsQuery({ ...tradeAlertsQuery, [query]: value });
        break;
      case 'upcoming':
        setUpcomingQuery({ ...upcomingQuery, [query]: value });
        break;
    }
  };

  const handleRefreshTradeAlertsList = () => {
    tradeAlerts.mutate();
  };

  return (
    <React.Fragment>
      <PageHeader product="ps" />
      <div id="ProspectusTabs">
        <Tabs
          tabs={[
            {
              title: 'Trade Alerts',
              count: (tradeAlerts.data && tradeAlerts.data.data.count) || 0,
              to: '/prospectuses',
            },
            {
              title: 'Missing/Expired',
              count:
                (missingExpired.data && missingExpired.data.data.count) || 0,
              to: '/prospectuses/missing-expired',
            },
            {
              title: 'Upcoming Expiration',
              count: (upcoming.data && upcoming.data.data.count) || 0,
              to: '/prospectuses/upcoming',
            },
          ]}
        />
      </div>

      {/* Setting this to false to prevent Reach Router from scrolling to content.
      Instead, it should scroll top of page */}
      <Router primary={false}>
        <TradeAlerts
          path="/"
          response={tradeAlerts}
          query={tradeAlertsQuery}
          onUpdateQuery={handleUpdateQuery}
          onRefreshList={handleRefreshTradeAlertsList}
        />
        <MissingExpired
          path="/missing-expired"
          response={missingExpired}
          query={missingExpiredQuery}
          onUpdateQuery={handleUpdateQuery}
        />
        <Upcoming
          path="/upcoming"
          response={upcoming}
          query={upcomingQuery}
          onUpdateQuery={handleUpdateQuery}
        />
      </Router>
    </React.Fragment>
  );
};

export { Prospectuses };
