export interface Document {
  attachment: string | null;
  filing: number;
  id: number;
  isInternal: boolean;
  name: string;
  pdf: null | string;
  securities: number[];
  shouldDistributeInNextMailing: boolean;
  sortOrder: number | null;
  type: DocumentType;
}

export enum DocumentType {
  ProxyStatement = 'proxy_statement',
  ProxyCard = 'proxy_card',
  TenK = '10_k',
  AnnualReport = 'annual_report',
  SemiAnnualReport = 'semi_annual_report',
  StatutoryProspectus = 'statutory',
  SummaryProspectus = 'summary',
  ConsentSolicitation = 'consent_solicitation',
  InformationStatement = 'information_statement',
}

export enum CorporateActionDocumentType {
  AmendmentToOfferToPurchase = 'amendment_to_offer_to_purchase',
  ClientLetter = 'client_letter',
  LetterOfTransmittal = 'letter_of_transmittal',
  MergerProposal = 'merger_proposal',
  Notice19A = 'notice_19a',
  NoticeOfGuarenteedDelivery = 'notice_of_guaranteed_delivery',
  OfferingCircular = 'offering_circular',
  OfferToPurchase = 'offer_to_purchase',
  Schedule14D9 = 'schedule_14d_9',
  ScheduleTo = 'schedule_to',
  ShareholderLetter = 'shareholder_letter',
}
