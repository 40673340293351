import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { OperationsStatus } from '../../features/Proxy/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    rootDialog: {
      position: 'absolute',
      overflow: 'hidden',
    },
    backdrop: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,.8)',
      width: '100vw',
      height: '100vh',
    },
    dialogContainer: {
      maxHeight: '90vh',
      overflow: 'hidden',
    },
    dialogContent: {
      '& > *': {
        margin: '12px 0',
      },
    },
  }),
);

type Props = {
  isOpen: boolean;
  onConfirmUpdate: (targetStatus: OperationsStatus) => void;
  onRequestClose: () => void;
  actionType?: Option;
};

type Option = 'confirm' | 'complete' | 'approve';

const mapActionToTargetStatus = new Map([
  ['confirm', OperationsStatus.New],
  ['complete', OperationsStatus.ReadyForReview],
  ['approve', OperationsStatus.Approved],
]);

export const UpdateStatusDialog = ({
  isOpen,
  onConfirmUpdate,
  onRequestClose,
  actionType,
}: Props) => {
  const classes = useStyles();

  const renderTitle = (actionType: Option) => {
    switch (actionType) {
      case 'confirm':
        return 'Confirm Filing';
      case 'complete':
        return 'Complete Filing';
      case 'approve':
        return 'Approve Filing';
    }
  };
  const renderWarningText = (actionType: Option) => {
    switch (actionType) {
      case 'confirm':
        return 'Are you sure you want to confirm this filing? This filing will be added to Mission Control and we will look for positions with this record date and these CUSIPs';
      case 'complete':
        return 'Would you like to mark this filing ready for review?';
      case 'approve':
        return 'Are you sure you would like to approve this filing? Approved emails will be sent to shareholders.';
    }
  };
  if (!actionType) return null;
  return (
    <div>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={onRequestClose}
        classes={{
          root: classes.rootDialog,
          container: classes.dialogContainer,
        }}
        BackdropProps={{
          classes: { root: classes.backdrop },
        }}
        style={{ position: 'absolute' }}
        className={classes.rootDialog}
      >
        <DialogTitle>{renderTitle(actionType)}</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {renderWarningText(actionType)}
        </DialogContent>

        <DialogActions>
          <Button onClick={onRequestClose} type="button">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              const targetStatus = mapActionToTargetStatus.get(actionType);
              event.preventDefault();
              if (!targetStatus) return null;
              onConfirmUpdate(targetStatus);
            }}
          >
            {actionType}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
