import React, { MouseEvent, useState, FC } from 'react';
import { Modal } from '../../common/Modal';
import { Dialog } from '../../common/Dialog/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../data/store';
import {
  toggleDocumentDialog,
  toggleConfirmCloseDocumentDialog,
  createProspectusFlow,
} from '../../data/funds';
import { OutlineButton } from '../../common/Buttons/OutlineButton';
import { Button } from '../../common/Buttons/Button';
import {
  Formik,
  FormikProps,
  Form as FormikForm,
  Field,
  FieldProps,
  FormikActions,
} from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { InputGroup } from '../../common/InputGroup';
import { Label } from '../../common/Label';
import { Asterisk } from '../../common/Asterik/Asterik';
import { Select } from '../../common/Select';
import { capitalize } from '../../utils/capitalize';
import { Flex, Box } from '@rebass/grid';
import { Text } from '../../common/Text';
import Dropzone from 'react-dropzone';
import { Input } from '../../common/Input';
import { GenericDate } from '../../common/FormComponents/GenericDate';
import { FundDocumentType } from '../../models/prospectus/fund';
import { IssuerTypeahead } from '../IssuerTypeahead';
import { SecurityTypeahead } from '../SecurityTypeahead';
import {
  getIssuerById,
  createSecurity,
  createIssuer,
} from '../../data/hubApiRoutes';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import { ErrorLabel } from '../../common/ErrorLabel';
import { ToastErrorList } from '../../common/ToastErrorList';

const Form = styled(FormikForm)`
  width: 100%;
`;

const Buttons = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
`;

const FileDropWrapper = styled.div`
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  margin: 32px 0;
  padding: 32px;
`;

const FileDropText = styled.p`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
`;

export interface FormValues {
  type: FundDocumentType | '';
  issuer: {
    id: string;
    name: string | null;
  } | null;
  securities: {
    id: string;
    cusip: string;
  }[];
  name: string;
  effectiveDate: string | null;
  expirationDate: string | null;
  frontLink: string;
  fundWebsite: string;
  document: null | File;
}

const ValidationSchema = Yup.object().shape({
  type: Yup.string().required(),
  issuer: Yup.object().required(),
  securities: Yup.array().required('You must include at least one security'),
  name: Yup.string().required(),
  effectiveDate: Yup.string().required(),
  document: Yup.mixed().required(),
  frontLink: Yup.string()
    .url('Please enter a valid url (including http:// or https://')
    .notRequired(),
  fundWebsite: Yup.string()
    .url('Please enter a valid url (including http:// or https://')
    .notRequired(),
});

interface AddDocumentProps {
  selectableSecurities?: {
    id: string;
    cusip: string;
  }[];
  issuer?: {
    id: string;
    name: string | null;
  };
}

export const AddDocument: FC<AddDocumentProps> = ({
  selectableSecurities,
  issuer,
}) => {
  const { isDocumentDialogOpen } = useSelector(
    (state: AppState) => state.funds,
  );
  const dispatch = useDispatch();
  const [securitiesByIssuer, setSecuritiesByIssuer] = useState<
    {
      id: string;
      cusip: string;
    }[]
  >([]);

  const handleClose = () => dispatch(toggleDocumentDialog());
  const handleCancelClick = (isFormDirty: boolean) => {
    if (isFormDirty) {
      dispatch(toggleConfirmCloseDocumentDialog());
    } else {
      dispatch(toggleDocumentDialog());
    }
  };
  const handleSubmit = (
    values: FormValues,
    formikActions: FormikActions<FormValues>,
  ) => {
    const formData = new FormData();
    formData.append(
      'attachment',
      values.document as File,
      (values.document as File).name,
    );
    formData.append('type', values.type);
    formData.append('name', values.name);
    formData.append('front_link', values.frontLink);
    formData.append('website', values.fundWebsite);
    formData.append(
      'effective_start_date',
      values.effectiveDate ? values.effectiveDate : '',
    );
    values.securities.map((security) =>
      formData.append('security_ids', security.id),
    );

    if (values.issuer) {
      dispatch(createProspectusFlow(formData, values.issuer.id));
      dispatch(toggleDocumentDialog());
      formikActions.setSubmitting(false);
      navigate(`/issuers/funds/${values.issuer.id}`);
    }
  };

  return (
    <>
      <Modal
        isOpen={isDocumentDialogOpen}
        onRequestClose={handleClose}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        top={20}
      >
        <Dialog title="Add Document" isGray={true} onRequestClose={handleClose}>
          <Formik
            initialValues={{
              type: '',
              issuer: issuer || null,
              securities: [],
              name: '',
              effectiveDate: '',
              expirationDate: '',
              frontLink: '',
              fundWebsite: '',
              document: null,
            }}
            enableReinitialize={true}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
            render={(formikBag: FormikProps<FormValues>) => (
              <Form>
                <Field
                  name="type"
                  render={({ field, form }: FieldProps<FormValues>) => (
                    <InputGroup>
                      <Label>
                        Document Type <Asterisk />
                      </Label>
                      <Select
                        {...field}
                        required={true}
                        onChange={(event) => {
                          form.setFieldValue('type', event.currentTarget.value);
                          if (
                            event.currentTarget.value !== FundDocumentType.Other
                          ) {
                            const optionText =
                              event.target.options[event.target.selectedIndex]
                                .text;
                            form.setFieldValue('name', optionText);
                          }
                        }}
                      >
                        <option value="" disabled={true}>
                          Select a Document Type
                        </option>
                        {Object.values(FundDocumentType).map(
                          (fundDocumentType) => (
                            <option
                              key={fundDocumentType}
                              value={fundDocumentType}
                            >
                              {capitalize(
                                fundDocumentType.split('_').join(' '),
                              )}
                            </option>
                          ),
                        )}
                      </Select>
                    </InputGroup>
                  )}
                />

                <Field
                  name="issuer"
                  render={({ field, form }: FieldProps<FormValues>) => {
                    const handleSelectIssuer = (issuer: {
                      name: string;
                      id: string;
                    }) => {
                      getIssuerById(issuer.id)
                        .then((response) => {
                          const securities = response.data.securities.map(
                            (security: { id: string; cusip: string }) => ({
                              id: security.id,
                              cusip: security.cusip,
                            }),
                          );
                          setSecuritiesByIssuer(securities);

                          if (securities.length === 1) {
                            form.setFieldValue('securities', securities);
                          }
                        })
                        .catch((error) => {
                          toast.error(JSON.stringify(error.response.data));
                        });
                      form.setFieldValue('issuer', issuer);
                    };

                    const handleAddIssuer = (name: string) => {
                      createIssuer(name)
                        .then((response) =>
                          form.setFieldValue('issuer', {
                            name: response.data.name,
                            id: response.data.id,
                          }),
                        )
                        .catch((error) => {
                          toast.error(JSON.stringify(error.response.data));
                        });
                    };

                    return (
                      <InputGroup>
                        <Label>
                          Issuer <Asterisk />
                        </Label>
                        <IssuerTypeahead
                          onAddIssuer={handleAddIssuer}
                          onSelectIssuer={handleSelectIssuer}
                          selectedItem={field.value}
                        />
                      </InputGroup>
                    );
                  }}
                />

                {formikBag.values.issuer && (
                  <Field
                    name="securities"
                    render={({ field, form }: FieldProps<FormValues>) => {
                      const handleSelectSecurities = (
                        securities: {
                          id: string;
                          cusip: string;
                        }[],
                      ) => form.setFieldValue('securities', securities);

                      const handleAddSecurity = (query: string) => {
                        const security = {
                          cusip: query,
                          issuerId: form.values.issuer && form.values.issuer.id,
                        };
                        createSecurity(security)
                          .then((response) => {
                            return form.setFieldValue('securities', [
                              ...field.value,
                              {
                                id: response.data.id,
                                cusip: response.data.cusip,
                              },
                            ]);
                          })
                          .catch((error) => {
                            return toast.error(
                              <ToastErrorList error={error.response.data} />,
                            );
                          });
                      };

                      return (
                        <InputGroup>
                          <Label>
                            CUSIP(s) <Asterisk />
                          </Label>
                          <SecurityTypeahead
                            onAddSecurity={handleAddSecurity}
                            onSelectSecurities={handleSelectSecurities}
                            selectedItems={field.value}
                            items={selectableSecurities || securitiesByIssuer}
                          />
                        </InputGroup>
                      );
                    }}
                  />
                )}

                <Field
                  name="name"
                  render={({ field }: FieldProps<FormValues>) => (
                    <InputGroup>
                      <Label>
                        Document Name <Asterisk />
                      </Label>
                      <Input {...field} placeholder="Fund" />
                    </InputGroup>
                  )}
                />

                <Field
                  name="effectiveDate"
                  render={({ field, form }: FieldProps<FormValues>) => (
                    <GenericDate
                      field={field}
                      form={form}
                      label="Effective Date"
                      fieldName={field.name}
                      isRequired={true}
                    />
                  )}
                />

                {formikBag.values.type === FundDocumentType.Summary && (
                  <Field
                    name="expirationDate"
                    render={({ field, form }: FieldProps<FormValues>) => (
                      <GenericDate
                        field={field}
                        form={form}
                        label="Expiration Date"
                        fieldName={field.name}
                      />
                    )}
                  />
                )}

                <Field
                  name="frontLink"
                  render={({ field, form }: FieldProps<FormValues>) => (
                    <InputGroup>
                      <Label>
                        Front Link{' '}
                        {form.errors['frontLink'] && (
                          <ErrorLabel>{form.errors['frontLink']}</ErrorLabel>
                        )}
                      </Label>
                      <Input
                        {...field}
                        type="url"
                        placeholder="https://front.com/..."
                      />
                    </InputGroup>
                  )}
                />

                <Field
                  name="fundWebsite"
                  render={({ field, form }: FieldProps<FormValues>) => (
                    <InputGroup>
                      <Label>
                        Fund Website{' '}
                        {form.errors['fundWebsite'] && (
                          <ErrorLabel>{form.errors['fundWebsite']}</ErrorLabel>
                        )}
                      </Label>
                      <Input
                        {...field}
                        type="url"
                        placeholder="https://example.com"
                      />
                    </InputGroup>
                  )}
                />

                <Field
                  name="document"
                  render={({ field, form }: FieldProps<FormValues>) =>
                    field.value ? (
                      <Flex
                        p={2}
                        my={3}
                        bg="background"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text color="blue" fontSize={0} letterSpacing="body">
                          {field.value.name}
                        </Text>

                        <button
                          onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            event.preventDefault();
                            formikBag.setFieldValue('document', null);
                          }}
                        >
                          x
                        </button>
                      </Flex>
                    ) : (
                      <Dropzone
                        onDrop={(acceptedFiles: File[]) =>
                          form.setFieldValue('document', acceptedFiles[0])
                        }
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps, isDragActive }) => {
                          return (
                            <FileDropWrapper {...getRootProps()}>
                              <input {...getInputProps()} />
                              <FileDropText>
                                {isDragActive
                                  ? 'Drop file here'
                                  : 'Drag and drop a file or click to browse'}
                              </FileDropText>
                            </FileDropWrapper>
                          );
                        }}
                      </Dropzone>
                    )
                  }
                />

                <Buttons>
                  <OutlineButton
                    type="button"
                    width={1}
                    mr={2}
                    onClick={() => handleCancelClick(formikBag.dirty)}
                  >
                    Cancel
                  </OutlineButton>

                  <Button
                    type="submit"
                    width={1}
                    ml={2}
                    disabled={formikBag.isSubmitting || !formikBag.isValid}
                  >
                    {formikBag.isSubmitting ? 'Adding...' : 'Add Doc'}
                  </Button>
                </Buttons>
              </Form>
            )}
          />
        </Dialog>
      </Modal>

      <ConfirmCancelAddDocument />
    </>
  );
};

const ConfirmCancelAddDocument = () => {
  const { isConfirmCancelDocumentDialogOpen } = useSelector(
    (state: AppState) => state.funds,
  );
  const dispatch = useDispatch();

  const handleKeepEditing = () => dispatch(toggleConfirmCloseDocumentDialog());
  const handleCancelAddingNewDocument = () => {
    dispatch(toggleConfirmCloseDocumentDialog());
    dispatch(toggleDocumentDialog());
  };

  return (
    <Modal
      isOpen={isConfirmCancelDocumentDialogOpen}
      onRequestClose={handleKeepEditing}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      top={20}
    >
      <Dialog
        title="Are you sure?"
        isGray={true}
        onRequestClose={handleKeepEditing}
      >
        <Box width={1}>
          <Text as="p" mb={3}>
            Are you sure you would like to cancel adding this document?
          </Text>

          <Buttons>
            <OutlineButton
              type="button"
              width={1}
              mr={2}
              onClick={handleKeepEditing}
            >
              No
            </OutlineButton>

            <Button
              type="button"
              width={1}
              ml={2}
              onClick={handleCancelAddingNewDocument}
            >
              Yes
            </Button>
          </Buttons>
        </Box>
      </Dialog>
    </Modal>
  );
};
