import React, { FC, ReactNode } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Button } from '../../common/Buttons/Button';
import styled from 'styled-components/macro';
import { NewFiling } from '../../NewFiling/NewFiling';
import { Search } from '../../common/Search/Search';
import { Navigation } from '../../common/Navigation';
import { SayLogotype } from '../../common/SayLogotype';
import { CorporateActions as CorporateActionsContext } from '../../data/CorporateActions';

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 73px 1fr;
  grid-template-areas:
    'header header'
    'nav body';
`;

const Header = styled.header`
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  padding: 16px 0;
  position: sticky;
  top: 0;
  z-index: 1;
  grid-area: header;
`;

const ComponentContainer = styled.div`
  grid-area: body;
`;

const CorporateActionsLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    corporateActions,
    setSearch,
    setIsNewFilingDialogOpen,
  } = CorporateActionsContext.useContainer();

  const handleSearch = (searchTerm: string) => {
    setSearch(searchTerm);
  };

  return (
    <Container>
      <Navigation />

      <Header>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          px={3}
          width={1}
        >
          <Flex alignItems="center" width={2 / 3}>
            <SayLogotype />

            <Box ml={4} width={1 / 2}>
              <Search
                onSearch={handleSearch}
                searchTerm={corporateActions.search}
              />
            </Box>
          </Flex>

          <Flex width={1 / 3} justifyContent="flex-end">
            <Button onClick={() => setIsNewFilingDialogOpen(true)}>
              Create New Filing
            </Button>
          </Flex>
        </Flex>
      </Header>

      <ComponentContainer>{children}</ComponentContainer>

      <NewFiling
        isOpen={corporateActions.isNewFilingDialogOpen}
        onClose={() => setIsNewFilingDialogOpen(false)}
      />
    </Container>
  );
};

export { CorporateActionsLayout };
