import React from 'react';
import { BrokerLogo } from './BrokerLogo';
import { useTemplatePreview } from './useTemplatePreview';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  logo: string;
  name: string;
  slug: string;
  filingId: string;
  onClick: (slug: string) => void;
  selected: boolean;
}

function Thumbnail({ logo, name, slug, filingId, onClick, selected }: Props) {
  const template = useTemplatePreview(filingId, slug);

  return (
    <Wrapper selected={selected} onClick={() => onClick(slug)}>
      <BrokerLogo logo={logo} name={name} />

      <Right>
        {!template.data && !template.error && (
          <SpinnerWrapper>
            <CircularProgress />
          </SpinnerWrapper>
        )}

        {template.data && !template.error && (
          <Preview
            selected={selected}
            dangerouslySetInnerHTML={{ __html: template.data.data.body }}
          />
        )}

        <Name>{name}</Name>
      </Right>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ selected: boolean }>`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 24px 1fr;
  padding: 8px 8px 8px 24px;
  background: ${({ selected }) => (selected ? '#11cc9914' : '#fff')};
  cursor: pointer;
`;

const Right = styled.div`
  overflow: hidden;
`;

const Name = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
`;

const Preview = styled.div<{ selected: boolean }>`
  height: 100px;
  overflow: hidden;
  background: #fff;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ selected }) => (selected ? '#11cc99' : '#e5e5e5')};
  border-radius: 4px;
  display: flex;
  justify-content: center;

  & > div:nth-of-type(2) {
    zoom: 0.5;
    transform: translateY(-120px);
  }
`;

const SpinnerWrapper = styled.div`
  height: 100px;
  overflow: hidden;
  background: #fff;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Thumbnail };
