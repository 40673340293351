import React, { FC } from 'react';
import { Flex } from '@rebass/grid';
import { ReactComponent as Plus } from './assets/plus.svg';
import styled from 'styled-components/macro';

const StyledDiv = styled(Flex)<{ disabled: boolean }>`
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: ${({ theme }) => theme.radii[2]}px;
  &:focus {
    outline: ${({ disabled }) =>
      disabled ? 'none' : 'rgba(19, 124, 189, 0.6) auto 2px'};
    outline-offset: ${({ disabled }) => (disabled ? 'none' : '2px')};
    -moz-outline-radius: ${({ disabled }) => (disabled ? 'none' : '6pxs')};
  }
`;
const Text = styled.p`
  font-size: 12px;
  margin: 0 0 0 10px;
  font-weight: ${({ theme }) => theme.fontWeights[5]};
`;

interface IProps {
  label: string;
  disabled: boolean;
  onClick: () => void;
}

export const AddField: FC<IProps> = ({ label, onClick, disabled }) => (
  <StyledDiv
    flexDirection="row"
    disabled={disabled}
    tabIndex={0}
    mt={15}
    alignItems="center"
    p={15}
    onClick={onClick}
    onKeyPress={(e: React.KeyboardEvent) => {
      if (e.charCode === 13) {
        onClick();
      }
    }}
  >
    <Plus />
    <Text>Add Proposal {label}</Text>
  </StyledDiv>
);
