import React, { ChangeEvent } from 'react';
import { InvoicerStructuredAddressField } from '.';
import { Flex } from '@rebass/grid';
import { Text } from '../Text';
import { Field, FieldProps } from 'formik';
import { InputGroup } from '../InputGroup';
import { Label } from '../Label';
import { Input } from '../Input';
import { geocode } from '../../utils/geocode';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { IFilingCreateValues } from '../../models/filing-form';

export const InvoicerAddressFields = () => {
  return (
    <Flex flexDirection="column">
      <Field
        name="invoicerAddress"
        render={({
          field,
          form,
        }: FieldProps<FilingDetailsFormValues | IFilingCreateValues>) => (
          <InputGroup>
            <Label htmlFor="invoicerAddress">Invoicer Address</Label>
            <Input
              id="address"
              {...field}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                form.setFieldValue(
                  'invoicerAddress',
                  event.currentTarget.value,
                );
                geocode(event.currentTarget.value).then((address) => {
                  if (address) {
                    form.setFieldValue('invoicerAddressLine1', address.line1);
                    form.setFieldValue('invoicerAddressLine2', address.line2);
                    form.setFieldValue('invoicerAddressLine3', address.line3);
                    form.setFieldValue('invoicerCity', address.city);
                    form.setFieldValue('invoicerState', address.state);
                    form.setFieldValue(
                      'invoicerPostalCode',
                      address.postalCode,
                    );
                    form.setFieldValue('invoicerCountry', address.country);
                  }
                });
              }}
            />
          </InputGroup>
        )}
      />

      <Text fontSize={1} fontWeight={400} mt={2} lineHeight="body">
        Invoice fields will be autofilled based on the above invoice address. If
        they are not properly filled, please update them before adding the
        filing
      </Text>

      <InvoicerStructuredAddressField
        id="address-address1"
        name="invoicerAddressLine1"
        displayName="Invoicer Address 1"
      />
      <InvoicerStructuredAddressField
        id="address-address2"
        name="invoicerAddressLine2"
        displayName="Invoicer Address 2"
      />
      <InvoicerStructuredAddressField
        id="address-address3"
        name="invoicerAddressLine3"
        displayName="Invoicer Address 3"
      />
      <InvoicerStructuredAddressField
        id="address-city"
        name="invoicerCity"
        displayName="Invoicer City"
      />
      <InvoicerStructuredAddressField
        id="address-state"
        name="invoicerState"
        displayName="Invoicer State"
      />
      <InvoicerStructuredAddressField
        id="address-postal"
        name="invoicerPostalCode"
        displayName="Invoicer Postal Code"
      />
    </Flex>
  );
};
