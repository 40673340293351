import React from 'react';
import { LinkGetProps } from '@reach/router';
import { DirtyPromptLink } from '../../features/Proxy/proxy-warning-context';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type NavItemProps = {
  children: React.ReactNode;
  to: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      height: '48px',
      alignItems: 'center',
      paddingLeft: '24px',
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
      },
    },
    grayscale: {
      color: 'rgba(0, 0, 0, 0.6)',
      filter: 'grayscale(100)',
      '&:hover': {
        backgroundColor: 'rgba(17, 204, 153, 0.04)',
        filter: 'none',
      },
    },
    current: {
      backgroundColor: 'rgba(17, 204, 153, 0.08)',
    },
  }),
);

const NavItem = ({ children, to }: NavItemProps) => {
  const classes = useStyles();
  const isActive = ({ isPartiallyCurrent }: LinkGetProps) => {
    return isPartiallyCurrent
      ? {
          className: `${classes.root} ${classes.current} active`,
        }
      : {
          className: `${classes.root} ${classes.grayscale}`,
        };
  };

  return (
    <DirtyPromptLink to={to} getProps={isActive}>
      {children}
    </DirtyPromptLink>
  );
};

export { NavItem };
