import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { useTemplatePreview } from './useTemplatePreview';
import { useCommunicationPreview } from './useCommunicationPreview';

interface Props {
  selectedBroker: string;
  filingId: string;
  selectedView: string;
}

function PreviewPane({ selectedBroker, filingId, selectedView }: Props) {
  const template = useTemplatePreview(filingId, selectedBroker);
  const communication = useCommunicationPreview(filingId, selectedBroker);

  return (
    <>
      <Wrapper>
        <MenuBar>
          <BrowserButton color="red" />
          <BrowserButton color="yellow" />
          <BrowserButton color="green" />

          {selectedView === 'email' && template.data && !template.error && (
            <BrowserTitle>
              <BoldText>Subject:</BoldText> {template.data.data.subject}
            </BrowserTitle>
          )}

          {selectedView === 'ballot' &&
            communication.data &&
            !communication.error && (
              <BrowserTitle>
                {communication.data.data.sampleBallotUrl}
              </BrowserTitle>
            )}
        </MenuBar>

        {selectedView === 'email' && template.data && !template.error && (
          <div dangerouslySetInnerHTML={{ __html: template.data.data.body }} />
        )}

        {selectedView === 'ballot' &&
          communication.data &&
          !communication.error && (
            <Iframe src={communication.data.data.sampleBallotUrl} />
          )}

        {((!template.data && !template.error) ||
          (!communication.data && !communication.error)) && (
          <SpinnerWrapper>
            <CircularProgress />
          </SpinnerWrapper>
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  grid-area: previewPane;
  border-radius: 4px;
  background: #f8f8f8;
  margin: 0 24px;
  box-shadow: 0 5px 5px -3px #00000033, 0 3px 14px 2px #0000001e,
    0 8px 10px 1px #00000023;
  overflow-y: auto;
  overflow-x: hidden;
`;

const MenuBar = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  background: #fff;
  overflow: hidden;
`;

type BrowserButtonColor = 'red' | 'yellow' | 'green';

const BrowserButton = styled.div<{ color: BrowserButtonColor }>`
  background: ${({ color }) => {
    switch (color) {
      case 'red':
        return '#fd3b57';
      case 'yellow':
        return '#ffc206';
      case 'green':
        return '#12d43a';
    }
  }};
  margin-right: 6px;
  height: 8px;
  width: 8px;
  border-radius: 50%;

  &:last-of-type {
    margin-right: 0;
  }
`;

const BrowserTitle = styled.p`
  margin-left: 24px;
  font-size: 14px;
`;

const BoldText = styled.span`
  font-weight: 500;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

export { PreviewPane };
