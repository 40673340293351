import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { IVoteProposalFormValues } from '../../models/vote-proposal';
import { InputGroup } from '../InputGroup';
import { Label } from '../Label';
import { Asterisk } from '../Asterik/Asterik';
import { Select } from '../Select';

export const Routine: FC<{}> = () => {
  return (
    <Field
      name="routine"
      render={({ field }: FieldProps<IVoteProposalFormValues>) => {
        return (
          <InputGroup>
            <Label htmlFor="routine">
              Routine Type <Asterisk />
            </Label>
            <Select id="routine" {...field}>
              <option value="Routine">Routine</option>
              <option value="Non-Routine">Non-Routine</option>
            </Select>
          </InputGroup>
        );
      }}
    />
  );
};
