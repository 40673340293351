import React from 'react';

export const BallotView = () => {
  return (
    <>
      <div
        style={{ height: '50vh' }}
        className="flex w-full flex-col items-center justify-center font-medium uppercase py-1 whitespace-no-wrap text-secondary-text tracking-wider"
      >
        <p className="pt-1">BALLOT (IN PROGRESS)</p>
      </div>
    </>
  );
};
