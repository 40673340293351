import styled from 'styled-components/macro';
import { space, SpaceProps, width, WidthProps } from 'styled-system';

interface OutlineButtonProps extends SpaceProps, WidthProps {
  small?: boolean;
}

export const OutlineButton = styled.button<OutlineButtonProps>`
  ${space}
  ${width}
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightBorder};
  border-radius: ${({ theme }) => theme.radii[3]}px;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme, small }) => theme.fontSizes[small ? 1 : 2]}px;
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  padding: ${({ theme, small }) =>
    `${theme.spacing[small ? 1 : 2]}px ${theme.spacing[small ? 3 : 4]}px`};
  transition: border 0.25s ease;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.black};
    cursor: pointer;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray};
    border: 1px solid ${({ theme }) => theme.colors.gray};
    cursor: not-allowed;
  }
`;
