import React from 'react';

interface Props {
  csv: string | null;
}

function CSVView({ csv }: Props) {
  console.log(csv ? csv.split(' ') : null);
  return (
    <div
      style={{ height: '50vh' }}
      className="flex w-full flex-col font-medium uppercase p-4"
    >
      {csv
        ? csv.split('\n').map((row, index) => <p key={index}>{row}</p>)
        : null}
    </div>
  );
}

export { CSVView };
