import React, { FC, ReactNode } from 'react';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components/macro';
import { Navigation } from '../../common/Navigation';
import { SayLogotype } from '../../common/SayLogotype';
import { Search } from '../../common/Search/Search';
import { AppState } from '../../data/store';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchTerm } from '../../data/companies';

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 73px 1fr;
  grid-template-areas:
    'header header'
    'nav body';
`;

const Header = styled.header`
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  padding: 16px 0;
  position: sticky;
  top: 0;
  z-index: 1;
  grid-area: header;
`;

const ComponentContainer = styled.div`
  grid-area: body;
`;

export const CompaniesLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { search } = useSelector((state: AppState) => state.companies);
  const dispatch = useDispatch();

  const handleSearch = (searchTerm: string) => {
    dispatch(setSearchTerm(searchTerm));
  };

  return (
    <Container>
      <Navigation />

      <Header>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          px={3}
          width={1}
        >
          <Flex alignItems="center" width={1 / 3}>
            <SayLogotype />

            <Box ml={4} width={1}>
              <Search onSearch={handleSearch} searchTerm={search} />
            </Box>
          </Flex>
        </Flex>
      </Header>

      <ComponentContainer>{children}</ComponentContainer>
    </Container>
  );
};
