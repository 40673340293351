import React, { FC, useState, useContext } from 'react';
import { Flex, Box } from '@rebass/grid';
import { useContainer } from 'unstated-next';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import {
  Card,
  RowHeader,
  SecurityBox,
  ProposalTypeContainer,
  StyledButton,
  DeleteButton,
} from './VoteStyledComponents';
import { FilingContext } from '../../data/Filing.Context';
import { cusip } from './VoteHelpers';
import {
  IElectionItems,
  IElectionItemsFormValues,
} from '../../models/vote-proposal';
import { theme } from '../../utils/theme';
import { ElectionItem } from './ElectionItem';
import { ElectionForm } from './ElectionForm';
import { AddButton } from '../../common/AddButton';
import { DeleteModal } from './DeleteModal';
import { VoteProposals } from '../../data/VoteProposal';
import { EditTypes } from './VoteContainer';

export const CAElection: FC<{}> = () => {
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [
    initialValues,
    setInitialValues,
  ] = useState<IElectionItemsFormValues | null>(null);
  const [electionToEdit, setElectionToEdit] = useState<number>(NaN);
  const [electionToDelete, setElectionToDelete] = useState<number>(NaN);

  const { filing } = useContext(FilingContext);
  const { electionItems, deleteElection } = useContainer(VoteProposals);

  const composeInitialValues = (
    election: IElectionItems,
  ): IElectionItemsFormValues => {
    return {
      proposalType: 'Corporate Action',
      electionType: election.electionType,
      proposalTitle: election.title,
      securities: filing ? cusip(filing.securities, election.securities) : [],
      recommendation: election.recommendationType,
      details: election.details,
      proposalNumber: election.proposalNumber,
      groupNumber: election.groupNumber,
      id: election.id,
    };
  };

  const resetElectionsState = (type: EditTypes) => {
    if (type === EditTypes.create) {
      setShowCreateForm(false);
      setInitialValues(null);
    } else if (type === EditTypes.edit) {
      setShowCreateForm(false);
      setElectionToEdit(NaN);
      setIsEditing(false);
      setInitialValues(null);
    }
  };

  return (
    <Flex bg="background" pb={15} flexDirection="column">
      <Droppable
        droppableId="corp-action-groups"
        type="GROUP"
        isDropDisabled={showCreateForm || isEditing}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver
                ? transparentize('.5', theme.colors.gold)
                : 'transparent',
            }}
            {...provided.droppableProps}
          >
            {electionItems.map((electionItem: IElectionItems, i: number) => (
              <Draggable
                draggableId={`corpActionGroup-${electionItem.groupNumber}`}
                key={`draggable-corp-action-group-${electionItem.groupNumber}`}
                index={i}
              >
                {(dragProvided, dragSnapshot) => (
                  <div
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                  >
                    <Card
                      key={`corp-action-group-${i}`}
                      px={'1px'}
                      flexDirection="column"
                      bg="white"
                    >
                      <RowHeader
                        flexDirection="row"
                        width={1}
                        pt={20}
                        pb={15}
                        pr={35}
                        pl={10}
                      >
                        <Box mr={4} pt={1} {...dragProvided.dragHandleProps}>
                          <FontAwesomeIcon
                            icon={faGripVertical}
                            color={theme.colors.gold}
                          />{' '}
                        </Box>
                        <Flex flexDirection="row" width={3 / 4}>
                          <ProposalTypeContainer width={1 / 3} pt="5px">
                            {electionItem.electionType.split('_').join(' ')}
                          </ProposalTypeContainer>

                          <Flex width={1 / 3} flexDirection="row">
                            <Box width={1 / 5} pt="5px">
                              CUSIP(s):
                            </Box>
                            <Flex width={4 / 5} flexDirection="column">
                              {filing &&
                                cusip(
                                  filing.securities,
                                  electionItem.securities,
                                ).map((security) => {
                                  return (
                                    <SecurityBox key={security.cusip}>
                                      {security.cusip}
                                    </SecurityBox>
                                  );
                                })}
                            </Flex>
                          </Flex>
                        </Flex>

                        {!isEditing && (
                          <Flex
                            flexDirection="row"
                            justifyContent="flex-end"
                            width={1 / 4}
                          >
                            {!showCreateForm && (
                              <>
                                <StyledButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setInitialValues(
                                      composeInitialValues(electionItem),
                                    );
                                    setElectionToEdit(electionItem.groupNumber);
                                    setIsEditing(true);
                                    setShowCreateForm(true);
                                  }}
                                >
                                  Edit
                                </StyledButton>
                                <DeleteButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setElectionToDelete(electionItem.id);
                                  }}
                                >
                                  Delete
                                </DeleteButton>
                              </>
                            )}
                          </Flex>
                        )}
                      </RowHeader>
                      <ElectionItem election={electionItem} />
                      {showCreateForm &&
                        isEditing &&
                        electionToEdit === electionItem.groupNumber && (
                          <ElectionForm
                            initialValues={initialValues}
                            isEditing={true}
                            resetElectionsState={resetElectionsState}
                            currentGroupNumber={electionItem.groupNumber}
                            handleCancel={() => {
                              setShowCreateForm(false);
                              setIsEditing(false);
                              setElectionToEdit(NaN);
                            }}
                          />
                        )}
                    </Card>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {showCreateForm && !isEditing && (
        <Card bg="white">
          <ElectionForm
            isEditing={false}
            currentGroupNumber={electionItems.length + 1}
            initialValues={initialValues}
            resetElectionsState={resetElectionsState}
            handleCancel={() => setShowCreateForm(false)}
          />
        </Card>
      )}
      <Card>
        <AddButton
          label="Add Election Item"
          disabled={showCreateForm}
          hanldeClick={() => {
            if (!showCreateForm) {
              setShowCreateForm(true);
            }
          }}
        />
      </Card>
      <DeleteModal
        isOpen={Boolean(electionToDelete)}
        message={'Are you sure you want to delete this election?'}
        handleDeleteProposalGroup={() => {
          deleteElection(electionToDelete);
          setElectionToDelete(NaN);
        }}
        handleModalToggle={() => setElectionToDelete(NaN)}
      />
    </Flex>
  );
};
