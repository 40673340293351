import React, { FC, ChangeEvent } from 'react';
import { Field, FieldProps } from 'formik';

import { Textarea } from '../Textarea';
import { Label } from '../Label';
import { InputGroup } from '../InputGroup';
import { IElectionItemsFormValues } from '../../models/vote-proposal';
import { ErrorLabel } from '../ErrorLabel';

export const ProposalDetail: FC<{}> = () => {
  return (
    <Field
      name="details"
      render={({ field, form }: FieldProps<IElectionItemsFormValues>) => (
        <InputGroup width={1}>
          <Label htmlFor="proposal-detail">Proposal Detail</Label>
          {form.errors.details && form.touched.details && (
            <ErrorLabel>{form.errors.details}</ErrorLabel>
          )}

          <Textarea
            width={1}
            value={field.value}
            id={`proposal-detail`}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              form.setFieldValue(`details`, e.target.value);
            }}
          />
        </InputGroup>
      )}
    />
  );
};
