import React from 'react';
import { NavItem } from './NavItem';
import { ReactComponent as SayLogo } from './say-logo.svg';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { ProductIcon } from '../ProductIcon';
import { Menu, AccountBalance /* BarChart */ } from '@material-ui/icons';

type NavProps = {
  isCollapsed: boolean;
  handleToggleNav: () => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100vh',
      zIndex: 1101,
      top: 0,
      overflow: 'hidden',
      position: 'sticky',
      boxShadow:
        '0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14)',
    },
    logo: {
      display: 'flex',
      paddingLeft: '24px',
      height: '4.375rem',
      alignItems: 'center',
      borderBottom: '1px solid rgba(0,0,0,0.12)',
    },
  }),
);

const Nav = ({ isCollapsed, handleToggleNav }: NavProps) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root}`}>
      <div id="SayLogo" className={classes.logo}>
        <IconButton
          onClick={handleToggleNav}
          edge="start"
          style={{ marginRight: '20px' }}
        >
          <Menu />
        </IconButton>
        {isCollapsed ? null : <SayLogo />}
      </div>

      <div id="NavMenu" className="flex flex-col">
        <NavItem to="/proxy">
          <ProductIcon product="px" size="small" />
          {isCollapsed ? null : <PageTitle>Proxy</PageTitle>}
        </NavItem>
        <NavItem to="/prospectuses">
          <ProductIcon product="ps" size="small" />
          {isCollapsed ? null : <PageTitle>Prospectus</PageTitle>}
        </NavItem>
        <NavItem to="/corporate-actions">
          <ProductIcon product="ca" size="small" />
          {isCollapsed ? null : <PageTitle>Corporate Actions</PageTitle>}
        </NavItem>
        <NavItem to="/issuers">
          <AccountBalance
            style={{ fill: '#11CC99', width: '24px', height: '24px' }}
          />

          {isCollapsed ? null : <PageTitle>Issuers</PageTitle>}
        </NavItem>
      </div>
    </div>
  );
};

const PageTitle = ({ children }: { children: React.ReactNode }) => (
  <span style={{ marginLeft: '32px' }}>{children}</span>
);

export { Nav };
