import React, { createContext, ReactNode, useReducer, useContext } from 'react';
import {
  WindowLocation,
  Link,
  LinkProps,
  navigate,
  useLocation,
} from '@reach/router';

type Action =
  | { type: 'CHECK_LOCATION'; isProxy: boolean }
  | { type: 'INITIALIZE_PROXY' }
  | { type: 'MARK_DIRTY' }
  | { type: 'OPEN_DIALOG'; handlers: Handlers }
  | { type: 'CONFIRM_LEAVE' }
  | { type: 'CANCEL_STAY' }
  | { type: 'RESET' };

type Dispatch = (action: Action) => void;

interface Handlers {
  resolve: (value?: {} | PromiseLike<{}>) => void;
  reject: () => void;
}

interface State {
  isProxy: boolean;
  isDirty: boolean;
  isDialogOpen: boolean;
  handlers: Handlers | null;
}

interface ProxyWarningProviderProps {
  children: ReactNode;
}

const initialState = {
  isProxy: false,
  isDirty: false,
  isDialogOpen: false,
  handlers: null,
};

const ProxyWarningStateContext = createContext<State | undefined>(undefined);
const ProxyWarningDispatchContext = createContext<Dispatch | undefined>(
  undefined,
);

function proxyWarningReducer(state: State, action: Action) {
  switch (action.type) {
    case 'CHECK_LOCATION':
      return { ...state, isProxy: action.isProxy };
    case 'INITIALIZE_PROXY':
      return { ...state, isProxy: true, isDirty: false };
    case 'MARK_DIRTY':
      return { ...state, isDirty: true };
    case 'OPEN_DIALOG':
      return { ...state, isDialogOpen: true, handlers: action.handlers };
    case 'CONFIRM_LEAVE':
      return { ...state, isDialogOpen: false, isDirty: false };
    case 'CANCEL_STAY':
      return { ...state, isDialogOpen: false };
    case 'RESET':
      return initialState;
  }
}

function ProxyWarningProvider({ children }: ProxyWarningProviderProps) {
  const [state, dispatch] = useReducer(proxyWarningReducer, initialState);

  return (
    <ProxyWarningStateContext.Provider value={state}>
      <ProxyWarningDispatchContext.Provider value={dispatch}>
        {children}
      </ProxyWarningDispatchContext.Provider>
    </ProxyWarningStateContext.Provider>
  );
}

function useProxyWarningState() {
  const context = useContext(ProxyWarningStateContext);
  if (context === undefined) {
    throw new Error(
      'useProxyWarningState must be used within a ProxyWarningProvider',
    );
  }
  return context;
}

function useProxyWarningDispatch() {
  const context = useContext(ProxyWarningDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useProxyWarningDispatch must be used within a ProxyWarningProvider',
    );
  }
  return context;
}

const checkLocation = (location: WindowLocation<any>) => {
  const urlParts = location.pathname.split('/');
  if (urlParts[urlParts.length - 1] === '') {
    // for urls ending in slash, eliminate final str
    urlParts.pop();
  }
  function isNumeric(val: string) {
    return Number(parseFloat(val)).toString() === val;
  }
  // returns true if route is /proxy/{filing.id}
  return (
    urlParts[urlParts.length - 2] === 'proxy' &&
    (isNumeric(urlParts[urlParts.length - 1]) ||
      urlParts[urlParts.length - 1] === 'create')
  );
};

// Navigate
async function navigateAway(toPath: string, state: State, dispatch: Dispatch) {
  if (state.isDirty && state.isProxy) {
    const userAction = new Promise((resolve, reject) =>
      dispatch({
        type: 'OPEN_DIALOG',
        handlers: { resolve, reject },
      }),
    );
    try {
      await userAction;
      dispatch({ type: 'CONFIRM_LEAVE' });
    } catch (_) {
      dispatch({ type: 'CANCEL_STAY' });
      return;
    }
  }
  navigate(toPath);
}

// Link
const DirtyPromptLink = (props: LinkProps<any>) => {
  const { isDirty, isProxy } = useProxyWarningState();

  const dispatch = useProxyWarningDispatch();

  // For Links used in MC Header/Sidebar
  const location = useLocation();
  if (!checkLocation(location)) {
    dispatch({ type: 'RESET' });
  }

  if (!isDirty || !isProxy) {
    return <Link {...(props as any)} />;
  }
  async function test(e: Event) {
    e.preventDefault();
    const userAction = new Promise((resolve, reject) =>
      dispatch({
        type: 'OPEN_DIALOG',
        handlers: { resolve, reject },
      }),
    );
    try {
      await userAction;
      dispatch({ type: 'CONFIRM_LEAVE' });
      navigate(props.to);
    } catch (_) {
      dispatch({ type: 'CANCEL_STAY' });
      return;
    }
  }
  return <Link onClick={test} {...(props as any)} />;
};

export {
  ProxyWarningProvider,
  useProxyWarningState,
  useProxyWarningDispatch,
  checkLocation,
  navigateAway,
  DirtyPromptLink,
};
