import React, { useState } from 'react';
import {
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import styled, { css } from 'styled-components';

const options = ['email', 'ballot'];

interface Props {
  onChange: (view: string) => void;
  selectedView: string;
}

function PreviewViewSwitcher({ onChange, selectedView }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option: string) => {
    onChange(option);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledList>
        <StyledListItem
          button={true}
          aria-haspopup="true"
          dense={true}
          onClick={handleClickListItem}
        >
          <StyledListItemText primary={selectedView} />
          <StyledListItemIcon>
            <ArrowDropDown />
          </StyledListItemIcon>
        </StyledListItem>
      </StyledList>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
      >
        {options.map((option) => (
          <StyledMenuItem
            dense={true}
            key={option}
            selected={option === selectedView}
            onClick={() => handleMenuItemClick(option)}
          >
            {option}
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  );
}

const StyledList = styled(List)`
  padding: 0;
`;

const StyledListItem = styled(ListItem)`
  justify-content: flex-end;
`;

const capitalizedText = css`
  text-transform: capitalize;
`;

const StyledMenuItem = styled(MenuItem)`
  ${capitalizedText}
`;

const StyledListItemText = styled(ListItemText)`
  ${capitalizedText}
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 0;
`;

export { PreviewViewSwitcher };
