import React from 'react';
import { Router, RouteComponentProps, Redirect } from '@reach/router';
import { Ready } from './Ready';
import { Review } from './Review';
import { TaskListLayout } from './TaskListLayout';
import { Details } from './Details';
import { Drafts } from './Drafts';
import { Create } from './Create';

function Proxy(_: RouteComponentProps) {
  return (
    <>
      <Router primary={false}>
        <Details path=":id/*" />
        <Create path="create" />
        <TaskListLayout path="tasks">
          <Drafts path="drafts" default={true} />
          <Ready path="ready" />
          <Review path="review" />
        </TaskListLayout>
        <Redirect from="/" to="tasks/drafts" noThrow={true} />
      </Router>
    </>
  );
}

export { Proxy };
