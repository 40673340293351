import { privateApi } from '../../../old/utils/api-adapter';

function useSendTestEmail() {
  async function sendTestEmail(
    filingId: string,
    slug: string,
    payload: { emails: string[] },
  ) {
    await privateApi.post(`/filings/${filingId}/preview/${slug}/`, payload);
  }

  return { sendTestEmail };
}

export { useSendTestEmail };
