import useSWR from 'swr';
import { AxiosResponse, AxiosError } from 'axios';
import { CommunicationPreviewResponse } from './types';

function useCommunicationPreview(filingId: string, slug: string) {
  const { data, mutate, error } = useSWR<
    AxiosResponse<CommunicationPreviewResponse>,
    AxiosError
  >(`/filings/${filingId}/communication-preview/${slug}/`);

  return { data, mutate, error };
}

export { useCommunicationPreview };
