import React, { FC } from 'react';
import { Issuer, EdgarFiling } from './types';
import { formatDate } from '../../utils/format-date';
import { userIdEvent } from '../../old/utils/analytics-helpers';
import { Link } from '@material-ui/core';

type EdgarDateTableCellProps = {
  edgarFilings: EdgarFiling[];
  issuer: Issuer;
};

type LatestEdgarFiling = undefined | EdgarFiling;

const EdgarDateTableCell: FC<EdgarDateTableCellProps> = ({
  edgarFilings,
  issuer,
}) => {
  const { edgarLandingPage } = issuer;
  const latestEdgarFiling: LatestEdgarFiling = edgarFilings[0];
  const operationStatus: string = latestEdgarFiling.status;

  if (latestEdgarFiling && latestEdgarFiling.filingDate && edgarLandingPage) {
    return (
      <Link
        className="text-blue hover:underline hover:text-blue"
        href={edgarLandingPage}
        style={{ color: '#1853B4' }}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          event.stopPropagation();
          userIdEvent('Edgar Link Clicked', { operationStatus });
        }}
      >
        {formatDate(new Date(latestEdgarFiling.filingDate))}
      </Link>
    );
  }

  return null;
};

export { EdgarDateTableCell };
