import React, { FC } from 'react';
import { TimeZone } from '../../models/time-zone';
import { Select } from '../Select';

interface TimeZoneSelectProps {
  id: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: any) => void;
  value: any;
  name: string;
}

export const TimeZoneSelect: FC<TimeZoneSelectProps> = (props) => {
  const timeZoneOptions = Object.values(TimeZone).map((timeZone) => (
    <option key={timeZone} value={timeZone}>
      {timeZone}
    </option>
  ));

  return (
    <Select {...props} value={props.value ? props.value : ''}>
      <option value="" disabled={true}>
        Select a Time Zone
      </option>
      {timeZoneOptions}
    </Select>
  );
};
