import axios, { AxiosTransformer } from 'axios';
import humps from 'humps';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getTokenFromCookies } from './get-token';
import { refreshToken } from './refresh-auth-token';

const sharedAxiosConfiguration = (isSSO?: boolean) => {
  return {
    baseURL: isSSO
      ? `${process.env.REACT_APP_SSO_URL}/v1`
      : `${process.env.REACT_APP_HOST_URL}/v1`,
    transformRequest: [
      (data: any) => humps.decamelizeKeys(data),
      ...(axios.defaults.transformRequest as AxiosTransformer[]),
    ],
    transformResponse: [
      ...(axios.defaults.transformResponse as AxiosTransformer[]),
      (data: any) => humps.camelizeKeys(data),
    ],
  };
};

const formDataAxiosConfiguration = {
  baseURL: `${process.env.REACT_APP_HOST_URL}/v1`,
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    (data: any) => humps.camelizeKeys(data),
  ],
};

const nonTransformedAxiosConfiguration = {
  baseURL: `${process.env.REACT_APP_HOST_URL}/v1`,
};

const privateApi = axios.create(sharedAxiosConfiguration());
const privateApiNo404Interceptors = axios.create(sharedAxiosConfiguration());
const publicApi = axios.create(sharedAxiosConfiguration());
const ssoPublicApi = axios.create(sharedAxiosConfiguration(true));
const privateApiAsFormData = axios.create(formDataAxiosConfiguration);
const privateApiWithoutTransformation = axios.create(
  nonTransformedAxiosConfiguration,
);

createAuthRefreshInterceptor(privateApi, refreshToken, {
  skipWhileRefreshing: false,
});
createAuthRefreshInterceptor(privateApiNo404Interceptors, refreshToken, {
  skipWhileRefreshing: false,
});
createAuthRefreshInterceptor(privateApiAsFormData, refreshToken, {
  skipWhileRefreshing: false,
});
createAuthRefreshInterceptor(privateApiWithoutTransformation, refreshToken, {
  skipWhileRefreshing: false,
});

privateApi.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `JWT ${getTokenFromCookies()}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

privateApiNo404Interceptors.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `JWT ${getTokenFromCookies()}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

privateApiAsFormData.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `JWT ${getTokenFromCookies()}`;
    config.headers['Content-Type'] = 'multipart/form-data';
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

privateApiWithoutTransformation.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `JWT ${getTokenFromCookies()}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

ssoPublicApi.interceptors.response.use((config) => {
  return config;
});

privateApi.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    switch (error.response.status) {
      case 404:
        break;
      case 500:
        break;
      default:
        break;
    }

    return Promise.reject(error);
  },
);

privateApiNo404Interceptors.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    switch (error.response.status) {
      case 500:
        break;
      default:
        break;
    }

    return Promise.reject(error);
  },
);

privateApiAsFormData.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    switch (error.response.status) {
      case 404:
        break;
      case 500:
        break;
      default:
        break;
    }
  },
);

privateApiWithoutTransformation.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `JWT ${getTokenFromCookies()}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export {
  publicApi,
  privateApi,
  privateApiAsFormData,
  ssoPublicApi,
  privateApiNo404Interceptors,
  privateApiWithoutTransformation,
};
