import React from 'react';
import { Snackbar, CircularProgress } from '@material-ui/core/';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

type Props = {
  open: boolean;
  saving: boolean;
  filename: string;
  onClose: () => void;
};

export function DocumentSnackbar({ open, saving, filename, onClose }: Props) {
  // This component is actually 2 separate Snackbar messages, necessitating use of key. (Multiple actions/messages are a Material antipattern)

  return (
    <>
      <Snackbar
        open={open}
        key={filename}
        autoHideDuration={saving ? null : 1000}
        action={
          saving ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            <CheckCircleIcon style={{ fill: '#11cc99' }} />
          )
        }
        onClose={onClose}
        message={saving ? filename : 'Upload complete!'}
        ContentProps={{ style: { paddingRight: '24px', height: '48px' } }}
      />
    </>
  );
}
