import React from 'react';
import { Field, FieldProps } from 'formik';
import { Label } from '../Label';
import { Input } from '../Input';
import { InputGroup } from '../InputGroup';
import { FilingDetailsFormValues } from '../../Filing/Filing';
import { IFilingCreateValues } from '../../models/filing-form';

export const InvoicerContactEmail = () => {
  return (
    <Field
      name="invoicerContactEmail"
      render={({
        field,
      }: FieldProps<FilingDetailsFormValues | IFilingCreateValues>) => (
        <InputGroup>
          <Label htmlFor="invoicer-email-address">Invoicer Email Address</Label>

          <Input id="invoicer-email-address" {...field} />
        </InputGroup>
      )}
    />
  );
};
