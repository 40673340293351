import React from 'react';
import './styles/tailwind.generated.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Router, Redirect } from '@reach/router';
import { Prospectuses } from './features/Prospectuses';
import { AppLayout } from './components/AppLayout';

import { Issuers } from './features/IssuersList';
import { Issuer } from './features/IssuerDetails';
import { CorporateActions } from './features/CorporateActions';
import { Proxy } from './features/Proxy';
import { QueryParamProvider } from 'use-query-params';
import { globalHistory } from '@reach/router';

import { ThemeProvider } from 'styled-components/macro';
import { theme } from './old/utils/theme';
import { Old } from './Old';
import { FilingProvider } from './old/data/Filing.Context';
import { Location } from './old/data/Location.Container';
import { ToastContainer, Slide } from 'react-toastify';
import { Ordering } from './old/data/Ordering.Context';
import 'react-toastify/dist/ReactToastify.min.css';
import { AllFilings as AllFilingsContext } from './old/data/AllFilings';
import { Proxy as ProxyContext } from './old/data/Proxy';
import { CorporateActions as CorporateActionsContext } from './old/data/CorporateActions';
import { Provider } from 'react-redux';
import store from './old/data/store';
import { AllResults } from './old/data/SECFilings';
import { VoteProposals } from './old/data/VoteProposal';
import { Logout } from './old/Auth/Logout';
import { PrivateRoute } from './components/PrivateRoute';
import { SWRConfig } from 'swr';
import { privateApi } from './old/utils/api-adapter';
import { BatchFilingsProvider } from './features/Proxy/batch-filings-context';
import { ProxyWarningProvider } from './features/Proxy/proxy-warning-context';

function fetcher<T>(url: string) {
  return privateApi.get<T>(url);
}

const App = () => {
  return (
    <>
      <ToastContainer transition={Slide} />
      <Provider store={store}>
        <AllFilingsContext.Provider>
          <AllResults.Provider>
            <ProxyContext.Provider>
              <CorporateActionsContext.Provider>
                <Ordering.Provider>
                  <Location.Provider>
                    <FilingProvider>
                      <VoteProposals.Provider>
                        <BatchFilingsProvider>
                          <ProxyWarningProvider>
                            <SWRConfig value={{ fetcher }}>
                              <ThemeProvider theme={theme}>
                                <Router>
                                  <QueryParamProvider
                                    {...{ path: '/' }}
                                    reachHistory={globalHistory}
                                  >
                                    <Old path="old/*" />
                                    <PrivateRoute
                                      component={AppLayout}
                                      path="/"
                                    >
                                      <Proxy path="proxy/*" />
                                      <CorporateActions path="corporate-actions/*" />
                                      <Prospectuses path="prospectuses/*" />
                                      <Issuers path="issuers" />
                                      <Issuer path="issuers/:id/*" />
                                    </PrivateRoute>
                                    <Logout path="logout" />
                                    <Redirect
                                      from="/"
                                      to="/proxy/"
                                      noThrow={true}
                                    />
                                  </QueryParamProvider>
                                </Router>
                              </ThemeProvider>
                            </SWRConfig>
                          </ProxyWarningProvider>
                        </BatchFilingsProvider>
                      </VoteProposals.Provider>
                    </FilingProvider>
                  </Location.Provider>
                </Ordering.Provider>
              </CorporateActionsContext.Provider>
            </ProxyContext.Provider>
          </AllResults.Provider>
        </AllFilingsContext.Provider>
      </Provider>
    </>
  );
};

export default App;
