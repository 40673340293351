import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  useProxyWarningState,
  useProxyWarningDispatch,
} from '../../features/Proxy/proxy-warning-context';

const useStyles = makeStyles(() =>
  createStyles({
    rootDialog: {
      position: 'absolute',
      overflow: 'hidden',
    },
    backdrop: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,.8)',
      width: '100vw',
      height: '100vh',
    },
    dialogContainer: {
      maxHeight: '90vh',
      overflow: 'hidden',
    },
    dialogContent: {
      '& > *': {
        margin: '12px 0',
      },
    },
  }),
);

export const DirtyFormDialog = () => {
  const classes = useStyles();
  const { isDialogOpen, handlers } = useProxyWarningState();
  const dispatch = useProxyWarningDispatch();
  if (handlers) {
    return (
      <div>
        <Dialog
          fullWidth
          open={isDialogOpen}
          onClose={() => {
            handlers.reject();
            dispatch({ type: 'CANCEL_STAY' });
          }}
          classes={{
            root: classes.rootDialog,
            container: classes.dialogContainer,
          }}
          BackdropProps={{
            classes: { root: classes.backdrop },
          }}
          style={{ position: 'absolute' }}
          className={classes.rootDialog}
        >
          <DialogTitle>Are you sure you want to leave this page?</DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <p>
              It looks like you have unsaved edits. If you leave this page your
              changes will be lost.
            </p>
            <p>Save before you go!</p>
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={() => {
                handlers.reject();
                dispatch({ type: 'CANCEL_STAY' });
              }}
            >
              Stay
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                handlers.resolve();
                dispatch({ type: 'CONFIRM_LEAVE' });
              }}
            >
              Leave
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return null;
};
