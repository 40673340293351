import React from 'react';
import styled from 'styled-components';
import { decamelize } from 'humps';
import { ChangesetType } from '../../Proxy/types';

interface Props {
  afterEntries: GenericEntries;
  beforeEntries: GenericEntries;
  type: ChangesetType;
}

type GenericEntries = [string, any][];

function ChangesetRowDetails({ afterEntries, beforeEntries, type }: Props) {
  function formatFieldName(name: string) {
    const fieldName = decamelize(name).split('_').join(' ');

    return fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
  }

  function filterOutNonInformativeFields(entries: GenericEntries) {
    return entries.filter(([key, value]) => {
      if (
        key === 'polymorphicCtypeId' ||
        key === 'id' ||
        key === 'filingPtrId' ||
        key === 'created' ||
        key === 'modified' ||
        key === 'votedAtMeeting' ||
        key === 'voteproposalitemPtrId'
      ) {
        return null;
      } else {
        return [key, value];
      }
    });
  }

  switch (type) {
    case 'filing_created':
      return (
        <Wrapper>
          {filterOutNonInformativeFields(afterEntries).map(([key, value]) => {
            if (
              (typeof value === 'string' && value.length) ||
              (value &&
                typeof value === 'object' &&
                !Array.isArray(value) &&
                Object.keys(value).length === 0) ||
              (Array.isArray(value) && value.length)
            ) {
              return (
                <Field key={key}>
                  {formatFieldName(key)}: &rarr; {value}
                </Field>
              );
            } else {
              return null;
            }
          })}
        </Wrapper>
      );
    case 'attribute':
      return (
        <Wrapper>
          {filterOutNonInformativeFields(afterEntries).map(
            ([key, value], i) => {
              if (
                (typeof value === 'string' && value.length) ||
                (value &&
                  typeof value === 'object' &&
                  !Array.isArray(value) &&
                  Object.keys(value).length === 0) ||
                (Array.isArray(value) && value.length)
              ) {
                return (
                  <Field key={key}>
                    {formatFieldName(key)}: {beforeEntries[i][1]} &rarr; {value}
                  </Field>
                );
              } else {
                return null;
              }
            },
          )}
        </Wrapper>
      );
    case 'vote_proposal_modified':
    case 'vote_proposal_created':
      return (
        <Wrapper>
          {filterOutNonInformativeFields(afterEntries).map(
            ([key, value], i) => {
              if (
                (typeof value === 'string' && value.length) ||
                (typeof value === 'number' && value) ||
                (value &&
                  typeof value === 'object' &&
                  !Array.isArray(value) &&
                  Object.keys(value).length === 0) ||
                (Array.isArray(value) && value.length)
              ) {
                return (
                  <Field key={key}>
                    {formatFieldName(key)}:{' '}
                    {type === 'vote_proposal_modified'
                      ? formatProposalValue(key, beforeEntries[i][1])
                      : null}{' '}
                    &rarr; {formatProposalValue(key, value)}
                  </Field>
                );
              } else {
                return null;
              }
            },
          )}
        </Wrapper>
      );
    case 'security':
      return (
        <Wrapper>
          {afterEntries.map(([key, value], i) => {
            return (
              <Field key={key}>
                security: {beforeEntries[i][1].join(', ')} &rarr;{' '}
                {value.join(', ')}
              </Field>
            );
          })}
        </Wrapper>
      );
    default:
      return null;
  }
}

function formatProposalValue(field: string, value: string) {
  switch (field) {
    case 'voteType':
      return voteTypeDisplayMap[value];
    case 'type':
      return proposalTypeDisplayMap[value];
    default:
      return value;
  }
}

const voteTypeDisplayMap: { [index: string]: string } = {
  election_majority: 'For, Against, Abstain',
  election_majority_forabstain: 'For, Abstain',
  election_majority_foragainst: 'For, Against',
  election_majority_foragainstwithhold: 'For, Against, Withhold',
  election_majority_forwithholdabstain: 'For, Withhold, Abstain',
  election_majority_yesno: 'Yes, No',
  election_plurality: 'For, Withhold',
};

const proposalTypeDisplayMap: { [index: string]: string } = {
  BoardOfDirectorsNomination: 'Election of Directors',
  PlanOfMergerProposalItem: 'Plan of Merger',
  AdjournmentProposalItem: 'Adjournment of Meeting',
  ExecutiveCompensationProposalItem: 'Executive Compensation',
  ManagementProposalItem: 'Management Proposal',
  ShareholderProposalItem: 'Shareholder Proposal',
  SayOnPay: 'Say on Pay',
  RatificationOfAuditors: 'Ratification of Auditors',
  Other: 'Other Proposal',
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Field = styled.p`
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 1.33;
  color: #121212de;
`;

export { ChangesetRowDetails };
