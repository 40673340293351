import React from 'react';

type PageTitleProps = {
  children: React.ReactNode;
};

function PageTitle({ children }: PageTitleProps) {
  return (
    <h1 className="font-medium text-primary-text text-2xl capitalize">
      {children}
    </h1>
  );
}

export { PageTitle };
