import React, { FC, useState, useEffect, useContext } from 'react';
import { Flex, Box } from '@rebass/grid';
import ReactModal from 'react-modal';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';

import { EmailPreviewHeader } from './EmailPreviewHeader';
import { EmailPreviewFooter } from './EmailPreviewFooter';
import { EmailPreviewSidebar } from './EmailPreviewSidebar';
import { Spinner } from '../common/Spinner/Spinner';
import { privateApi, privateApiNo404Interceptors } from '../utils/api-adapter';
import { FilingContext } from '../data/Filing.Context';

export interface IDocument {
  link: string;
  name: string;
}

export interface IBroker {
  name: string;
  id: number;
  slug: string;
}

export interface ITemplate {
  slug: string;
  body: string;
  subject: string;
}

const customStyles = {
  overlay: {
    zIndex: 2,
  },
  content: {
    top: '0',
    left: '0',
    right: 'auto',
    bottom: 'auto',
    width: '100vw',
    height: '100vh',
    padding: 0,
  },
};

const PreviewContainer = styled(Flex)`
  height: calc(100% - 60px);
`;

const FooterContainer = styled(Box)`
  height: 50px;
`;

const Main = styled(Box)`
  height: calc(100% - 50px);
  overflow-y: scroll;
`;

const SpinnerContainer = styled.div`
  width: 40px;
  margin: 25% auto;
`;

export const EmailPreview: FC<RouteComponentProps<{
  id: number;
  isCorpAction?: boolean;
}>> = ({ id, isCorpAction, location }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(
    null,
  );
  const [cachedTemplates, setCachedTemplates] = useState<ITemplate[]>(
    [] as ITemplate[],
  );
  const [brokers, setBrokers] = useState<IBroker[]>([] as IBroker[]);
  const [selectedBrokerSlug, setSelectedBrokerSlug] = useState<string | null>(
    null,
  );
  const [documents, setDocuments] = useState<IDocument[]>([] as IDocument[]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const changeBroker = (slug: string) => {
    setSelectedBrokerSlug(slug);
  };

  const { fetchFiling, filing } = useContext(FilingContext);

  useEffect(() => {
    if (id) {
      if (!filing) {
        fetchFiling(id.toString());
      } else if (filing && filing.documents) {
        const docs: IDocument[] = filing.documents.map((document: any) => {
          return {
            link: document.attachment,
            name: document.name,
          };
        });
        setDocuments(docs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, filing]);

  useEffect(() => {
    const brokerRequest =
      location && location.state
        ? `/brokers/?${location.state.param}=true`
        : '/brokers/';
    privateApi
      .get(brokerRequest)
      .then((response) => {
        const { data } = response;
        const filteredBrokers: IBroker[] = data.results.map((broker: any) => {
          return {
            id: broker.id,
            name: broker.name,
            slug: broker.slug,
          };
        });
        setSelectedBrokerSlug(filteredBrokers[0].slug);
        setBrokers(filteredBrokers);
      })
      .catch((error) => toast('Could not fetch brokers list'));
  }, [location]);

  const fetchTemplatePreview = () => {
    if (selectedBrokerSlug) {
      privateApiNo404Interceptors
        .get(`/filings/${id}/preview/${selectedBrokerSlug}/`)
        .then((response) => {
          const { data } = response;
          const template: ITemplate = {
            slug: selectedBrokerSlug,
            body: data.body,
            subject: data.subject,
          };
          if (
            template.body ===
            'Broker does not have a template matching the given criteria'
          ) {
            template.body = `<div style="width: 500px; margin: 150px auto;">${template.body}</div>`;
          } else if (
            template.body ===
            'This broker does not have an available email template'
          ) {
            template.body = `<div style="width: 500px; margin: 150px auto;">${template.body}</div>`;
          }
          setIsLoading(false);
          setSelectedTemplate(template);
          setCachedTemplates([...cachedTemplates, template]);
        })
        .catch((error) => {
          const template = {
            slug: selectedBrokerSlug,
            body:
              '<div style="width: 500px; margin: 150px auto;">This broker does not have a default template for this filing type</div>',
            subject: 'No Subject',
          };
          setIsLoading(false);
          setSelectedTemplate(template);
          setCachedTemplates([...cachedTemplates, template]);
        });
    }
  };

  useEffect(() => {
    if (selectedBrokerSlug) {
      setIsLoading(true);
      const alreadyFetched: ITemplate | undefined = cachedTemplates.find(
        (template: ITemplate) => template.slug === selectedBrokerSlug,
      );

      if (alreadyFetched) {
        setSelectedTemplate(alreadyFetched);
        setIsLoading(false);
      } else {
        fetchTemplatePreview();
      }
    }
    // eslint-disable-next-line
  }, [selectedBrokerSlug]);

  return (
    <ReactModal isOpen={true} style={customStyles}>
      <EmailPreviewHeader
        subject={selectedTemplate ? selectedTemplate.subject : 'No Subject'}
      />
      <PreviewContainer flexDirection="row" width={1}>
        <Flex flexDirection="column" width={7 / 8}>
          {isLoading ? (
            <Main>
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            </Main>
          ) : selectedTemplate ? (
            <Main
              dangerouslySetInnerHTML={{
                __html: selectedTemplate.body,
              }}
            />
          ) : (
            <Main>No preview</Main>
          )}

          <FooterContainer width={1}>
            <EmailPreviewFooter
              id={id}
              filing={filing}
              isCorpAction={isCorpAction}
              canSendEmail={Boolean(id && selectedTemplate)}
              template={selectedTemplate}
            />
          </FooterContainer>
        </Flex>
        <Box width={1 / 8}>
          <EmailPreviewSidebar
            brokers={brokers}
            selectedBrokerSlug={selectedBrokerSlug}
            changeBroker={changeBroker}
            documents={documents}
          />
        </Box>
      </PreviewContainer>
    </ReactModal>
  );
};
