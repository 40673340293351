import React, { FC, useState } from 'react';
import { Flex, Box } from '@rebass/grid';
import { ParentLi } from './ParentLi';
import { ListItem } from './ListItem';

import styled from 'styled-components/macro';
import { IDocument, IBroker } from './EmailPreview';

const Sidebar = styled(Flex)`
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 2px 3px 0 #d9d9d9;
  border-left: 1px solid ${({ theme }) => theme.colors.background};
  height: 100%;
`;

const UnorderedList = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItemContainer = styled.div`
  text-transform: capitalize;
`;

const Bold = styled.b`
  font-weight: ${({ theme }) => theme.fontWeights[7]};
`;

const StyledBox = styled(Box)`
  overflow: scroll;
`;

interface IProps {
  documents?: IDocument[];
  brokers: IBroker[];
  selectedBrokerSlug: string | null;
  changeBroker: (arg: string) => void;
}

export const EmailPreviewSidebar: FC<IProps> = ({
  documents = [],
  brokers,
  selectedBrokerSlug,
  changeBroker,
}) => {
  const [isDocumentsOpen, setIsDocumentsOpen] = useState<boolean>(true);
  const [isTemplatesOpen, setIsTemplatesOpen] = useState<boolean>(true);
  return (
    <Sidebar flexDirection="column">
      {documents.length ? (
        <StyledBox mt={30}>
          <UnorderedList>
            <ParentLi
              title="Documents"
              customHeight={200}
              open={isDocumentsOpen}
              onClick={() => {
                setIsDocumentsOpen(!isDocumentsOpen);
              }}
            >
              {documents.map((document, i) => {
                return (
                  <div onClick={(e) => e.stopPropagation()} key={document.link}>
                    <ListItem selected={false} key={`${document.name}-${i}`}>
                      <a
                        href={document.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {document.name
                          ? document.name
                          : 'No name for this document'}
                      </a>
                    </ListItem>
                  </div>
                );
              })}
            </ParentLi>
          </UnorderedList>
        </StyledBox>
      ) : null}

      <StyledBox mt={documents.length ? 100 : 30}>
        <UnorderedList>
          <ParentLi
            title="Templates"
            open={isTemplatesOpen}
            onClick={() => {
              setIsTemplatesOpen(!isTemplatesOpen);
            }}
          >
            {brokers.map((broker) => (
              <ListItemContainer
                onClick={(e) => e.stopPropagation()}
                key={`${broker.name}-${broker.id}`}
              >
                <ListItem
                  selected={broker.slug === selectedBrokerSlug}
                  onClick={() => {
                    changeBroker(broker.slug);
                  }}
                >
                  <div>
                    <Bold>Broker: </Bold>
                    {broker.name}
                  </div>
                </ListItem>
              </ListItemContainer>
            ))}
          </ParentLi>
        </UnorderedList>
      </StyledBox>
    </Sidebar>
  );
};
