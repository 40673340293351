import { createSlice, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { AppState } from './store';
import { getCompanies, getIssuerById, updateIssuerById } from './hubApiRoutes';
import { Company } from '../models/prospectus/company';
import { Issuer, IssuerInvoicingAddress } from '../models/prospectus/issuer';
import {
  privateApiWithoutTransformation,
  privateApi,
} from '../utils/api-adapter';
import { toast } from 'react-toastify';
import { FilingType } from '../models/filing-type';
import { userIdEvent } from '../utils/analytics-helpers';

export interface CompanyFiling {
  cusips: string[];
  id: number;
  positions: number;
  recordDateEnd: Date;
  recordDateStart: Date;
  type: string;
  meetingDate?: string | null;
  invoicingAddress: IssuerInvoicingAddress | null;
}

interface CompaniesState {
  count: number;
  companies: Company[];
  filingsList: {
    filings: CompanyFiling[];
    isLoading: boolean;
    ordering: string;
  };
  next: null | string;
  ordering: string;
  previous: null | string;
  isConfirmSaveCompanyDialogOpen: boolean;
  isLoading: boolean;
  search: string;
  selectedCompany: null | Company;
}

const initialState: CompaniesState = {
  count: 0,
  companies: [],
  filingsList: {
    isLoading: false,
    filings: [],
    ordering: '-date',
  },
  next: null,
  ordering: 'name',
  previous: null,
  isConfirmSaveCompanyDialogOpen: false,
  isLoading: false,
  search: '',
  selectedCompany: null,
};

export const issuersSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    clearSelectedCompany: (state) => {
      state.selectedCompany = null;
    },
    fetchCompaniesFailure: (state) => {
      state.isLoading = false;
    },
    fetchCompaniesRequest: (state) => {
      state.isLoading = true;
    },
    fetchCompaniesSuccess: (
      state,
      action: {
        payload: {
          companies: Company[];
          count: number;
          next: null | string;
          previous: null | string;
        };
      },
    ) => {
      state.companies = action.payload.companies;
      state.isLoading = false;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
    },
    fetchCompanyFailure: (state) => {
      state.isLoading = false;
    },
    fetchCompanyRequest: (state) => {
      state.isLoading = true;
    },
    fetchCompanySuccess: (state, action: { payload: Company }) => {
      state.isLoading = false;
      state.selectedCompany = action.payload;
    },
    fetchFilingsByIssuerFailure: (state) => {
      state.filingsList.isLoading = false;
    },
    fetchFilingsByIssuerRequest: (state) => {
      state.filingsList.isLoading = true;
    },
    fetchFilingsByIssuerSuccess: (
      state,
      action: {
        payload: CompanyFiling[];
      },
    ) => {
      state.filingsList.isLoading = false;
      state.filingsList.filings = action.payload;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setCompaniesOrdering: (state, action: { payload: string }) => {
      state.ordering = action.payload;
    },
    setFilingsListOrdering: (state, action: { payload: string }) => {
      state.filingsList.ordering = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.search = action.payload;
    },
    toggleConfirmSaveCompanyDialog: (state) => {
      state.isConfirmSaveCompanyDialogOpen = !state.isConfirmSaveCompanyDialogOpen;
    },
    updateCompanyFailure: () => {},
    updateCompanyRequest: () => {},
    updateCompanySuccess: (state, action: { payload: Company }) => {
      state.selectedCompany = action.payload;
      state.isConfirmSaveCompanyDialogOpen = false;
    },
  },
});

const { actions, reducer } = issuersSlice;

export const {
  clearSelectedCompany,
  fetchCompaniesFailure,
  fetchCompaniesRequest,
  fetchCompaniesSuccess,
  fetchCompanyFailure,
  fetchCompanyRequest,
  fetchCompanySuccess,
  fetchFilingsByIssuerFailure,
  fetchFilingsByIssuerRequest,
  fetchFilingsByIssuerSuccess,
  setCompanies,
  setCompaniesOrdering,
  setFilingsListOrdering,
  setSearchTerm,
  toggleConfirmSaveCompanyDialog,
  updateCompanyFailure,
  updateCompanyRequest,
  updateCompanySuccess,
} = actions;

export const fetchCompaniesFlow = ({
  limit,
  offset,
  ordering,
  search,
}: {
  limit?: string;
  offset?: string;
  ordering?: string;
  search?: string;
}): ThunkAction<void, AppState, null, Action<string>> => {
  return (dispatch) => {
    dispatch(fetchCompaniesRequest());
    getCompanies({ limit, offset, ordering, search })
      .then((response) => {
        const { count, next, previous, results } = response.data;
        const companies = results.map((issuer: Issuer) => ({
          id: issuer.id,
          name: issuer.name,
          cusips: issuer.securities.map(
            (security: { cusip: string }) => security.cusip,
          ),
          issuerType: issuer.issuerType,
        }));
        const issuerIds: string = results
          .map((issuer: Issuer) => issuer.id)
          .join();

        // we have to use this custom axios config because the stats response has dashes in the keys that we don't want formatted - this is also why we have to use snake case below for the values
        privateApiWithoutTransformation
          .get(`/issuers/stats/?global_ids=${issuerIds}`)
          .then((statsResponse) => {
            const stats = statsResponse.data;
            const companiesWithStats = companies.map((company: Company) => ({
              ...company,
              lastAnnualRecordDate: stats[company.id]
                ? stats[company.id].last_record_date
                : null,
              lastAnnualMeetingDate: stats[company.id]
                ? stats[company.id].last_annual_meeting_date
                : null,
            }));

            dispatch(
              fetchCompaniesSuccess({
                companies: companiesWithStats,
                count,
                previous,
                next,
              }),
            );
          })
          .catch((error) => {
            toast.error(JSON.stringify(error.response.data));
            dispatch(fetchCompaniesFailure());
          });
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data));
        dispatch(fetchCompaniesFailure());
      });
  };
};

export const fetchCompanyFlow = (
  id: string,
): ThunkAction<void, AppState, null, Action<string>> => {
  return (dispatch) => {
    dispatch(fetchCompanyRequest());
    getIssuerById(id)
      .then((response) => {
        const company: Issuer = response.data;

        privateApiWithoutTransformation
          .get(`/issuers/stats/?global_ids=${company.id}`)
          .then((statsResponse) => {
            const stats = statsResponse.data;
            const companyWithStats = {
              id: company.id,
              name: company.name || '',
              issuerType: company.issuerType,
              cusips: company.securities.map(
                (security: { cusip: string }) => security.cusip,
              ),
              lastAnnualRecordDate: stats[company.id]
                ? stats[company.id].last_record_date
                : null,
              lastAnnualMeetingDate: stats[company.id]
                ? stats[company.id].last_annual_meeting_date
                : null,
              invoicingAddress: company.invoicingAddress,
            };

            dispatch(fetchCompanySuccess(companyWithStats));
          })
          .catch((error) => {
            toast.error(JSON.stringify(error.response.data));
            dispatch(fetchCompanyFailure());
          });
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data));
        dispatch(fetchCompanyFailure());
      });
  };
};

export const updateCompanyFlow = ({
  id,
  name,
  invoicingAddress,
}: {
  id: string;
  name: string;
  invoicingAddress: IssuerInvoicingAddress;
}): ThunkAction<void, AppState, null, Action<string>> => {
  return (dispatch) => {
    dispatch(updateCompanyRequest());
    updateIssuerById(id, { name }, invoicingAddress)
      .then((response) => {
        const company: Issuer = response.data;

        userIdEvent('Save Company Clicked', {
          globalIssuerId: id,
          securityType: company.issuerType,
          save: true,
        });

        privateApiWithoutTransformation
          .get(`/issuers/stats/?global_ids=${company.id}`)
          .then((statsResponse) => {
            const stats = statsResponse.data;
            const companyWithStats = {
              id: company.id,
              name: company.name || '',
              issuerType: company.issuerType,
              cusips: company.securities.map(
                (security: { cusip: string }) => security.cusip,
              ),
              lastAnnualRecordDate: stats[company.id]
                ? stats[company.id].last_record_date
                : null,
              lastAnnualMeetingDate: stats[company.id]
                ? stats[company.id].last_annual_meeting_date
                : null,
              invoicingAddress: company.invoicingAddress,
            };

            dispatch(updateCompanySuccess(companyWithStats));
            toast.success('Company saved.');
          })
          .catch((error) => {
            toast.error(JSON.stringify(error.response.data));
            userIdEvent('Save Company Clicked', {
              globalIssuerId: id,
              securityType: company.issuerType,
              save: false,
              validationError: error.response.data,
            });
            dispatch(updateCompanyFailure());
          });
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data));
        dispatch(updateCompanyFailure());
      });
  };
};

export const fetchFilingsByIssuerFlow = ({
  id,
  ordering,
}: {
  id: string;
  ordering: string;
}): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch) => {
    dispatch(fetchFilingsByIssuerRequest());

    try {
      const { data } = await privateApi.get(
        `/issuers/${id}/filings/?ordering=${ordering}`,
      );
      const filings: CompanyFiling[] = data.map(
        (filing: {
          securities: { cusip: string }[];
          stats: { totalPositions: number };
          id: number;
          recordDateStart: Date;
          recordDateEnd: Date;
          type: FilingType;
          meetingDate?: string | null;
        }) => ({
          cusips: filing.securities.map(
            (security: { cusip: string }) => security.cusip,
          ),
          type: filing.type,
          id: filing.id,
          recordDateStart: filing.recordDateStart,
          recordDateEnd: filing.recordDateEnd,
          positions: filing.stats.totalPositions,
          meetingDate: filing.meetingDate && filing.meetingDate,
        }),
      );
      dispatch(fetchFilingsByIssuerSuccess(filings));
    } catch (error) {
      toast.error(JSON.stringify(error.response.data));
      dispatch(fetchFilingsByIssuerFailure());
    }
  };
};

export default reducer;
