import React from 'react';
import { FormikProps } from 'formik';
import { DocumentFormValues } from '../../features/Proxy/DetailsForm/DocumentUpload';
import { DocumentEditFormValues } from '../../features/Proxy/DetailsForm/DocumentEdit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

type Props = {
  cusips: {
    id: number;
    cusip: string;
    name: string;
  }[];
  docFormikBag: FormikProps<DocumentFormValues | DocumentEditFormValues>;
};

function DocumentCusipTypeahead({ cusips, docFormikBag }: Props) {
  const [searchTerm, setSearchTerm] = React.useState('');

  return (
    <Autocomplete
      multiple
      limitTags={3}
      options={cusips}
      inputValue={searchTerm}
      defaultValue={cusips}
      getOptionLabel={(option) => option.cusip}
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      filterSelectedOptions
      ChipProps={{ size: 'small' }}
      onChange={(_, changeValue) => {
        docFormikBag.setFieldValue('securities', changeValue);
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'clear') {
          docFormikBag.setFieldValue('securities', []);
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="CUSIP"
          required={true}
          size="small"
        />
      )}
    />
  );
}

export { DocumentCusipTypeahead };
