import React from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { RouteComponentProps, Link } from '@reach/router';
import { TableHeader } from '../../components/TableHeader';
import { responseInterface } from 'swr';
import { AxiosResponse } from 'axios';
import { Response, TradeAlertsResult, Query, OnUpdateQuery } from './index';
import { TableBodyRow } from '../../components/TableBodyRow';
import { TableBodyCell } from '../../components/TableBodyCell';
import { TableHeadCell } from '../../components/TableHeadCell';
import { TickerAvatar } from '../../components/TickerAvatar';
import { formatNumber } from '../../utils/format-number';
import { Table } from '../../components/Table';
import { formatDate } from '../../utils/format-date';
import { AlertIcon } from '../../components/AlertIcon';
import { LoadingState } from '../../components/LoadingState';
import { TradeAlertModal } from '../../components/TradeAlertModal';
import { useQueryParam, StringParam } from 'use-query-params';

type TradeAlertsProps = {
  response: responseInterface<AxiosResponse<Response<TradeAlertsResult>>, any>;
  query: Query;
  onUpdateQuery: OnUpdateQuery;
  onRefreshList: () => void;
};

const TradeAlerts = ({
  response,
  query,
  onUpdateQuery,
  onRefreshList,
}: TradeAlertsProps & RouteComponentProps) => {
  const { data, error } = response;
  const [id, setId] = useQueryParam('id', StringParam);

  const handleCloseModal = () => {
    onRefreshList();
    setId('');
  };

  if (!error && !data)
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );

  if (error) return <PageWrapper>Error...</PageWrapper>;

  if (data)
    return (
      <PageWrapper>
        <TableHeader
          count={data.data.count}
          page={query.page}
          pageSize={query.pageSize}
          itemType="fund"
          onPageChange={(page) =>
            onUpdateQuery('tradeAlerts', 'page', page - 1)
          }
          onPageSizeChange={(pageSize) =>
            onUpdateQuery('tradeAlerts', 'pageSize', pageSize)
          }
        />

        <Table>
          <thead className="bg-white">
            <tr>
              <TableHeadCell name="Ticker" />
              <TableHeadCell name="Issuer" />
              <TableHeadCell name="CUSIP" />
              <TableHeadCell
                name="Trades"
                align="right"
                orderingKey="trade_count"
                currentOrdering={query.ordering}
                onOrderingClick={(ordering: string) =>
                  onUpdateQuery('tradeAlerts', 'ordering', ordering)
                }
              />
              <TableHeadCell
                name="Deadline"
                align="right"
                orderingKey="deadline"
                currentOrdering={query.ordering}
                onOrderingClick={(ordering: string) =>
                  onUpdateQuery('tradeAlerts', 'ordering', ordering)
                }
              />
              <TableHeadCell
                name="Effective Date"
                align="right"
                orderingKey="effective_date"
                currentOrdering={query.ordering}
                onOrderingClick={(ordering: string) =>
                  onUpdateQuery('tradeAlerts', 'ordering', ordering)
                }
              />
              <TableHeadCell
                name="Expect Date"
                align="right"
                orderingKey="estimated_upcoming_date"
                currentOrdering={query.ordering}
                onOrderingClick={(ordering: string) =>
                  onUpdateQuery('tradeAlerts', 'ordering', ordering)
                }
              />
            </tr>
          </thead>
          <tbody>
            {data.data.results.map((result) => (
              <TableBodyRow key={result.id} onClick={() => setId(result.id)}>
                <TableBodyCell>
                  <TickerAvatar tickers={[result.ticker]} />
                </TableBodyCell>
                <TableBodyCell>
                  <Link
                    className="text-blue hover:underline hover:text-blue"
                    to={`/issuers/${result.issuerId}`}
                    target="_blank"
                    onClick={(event) => event.stopPropagation()}
                  >
                    {result.issuerName}
                  </Link>
                </TableBodyCell>
                <TableBodyCell>
                  <span className="border border-primary-text rounded text-sm px-2 mt-1 first:mt-0 text-primary-text">
                    {result.cusip}
                  </span>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-primary-text font-mediuma text-right">
                    {formatNumber(result.tradeCount)}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-right text-primary-text">
                    {formatDate(new Date(result.deadline))}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-right">
                    {result.effectiveDate
                      ? formatDate(new Date(result.effectiveDate))
                      : '--'}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-right text-red">
                    {result.estimatedUpcomingDate ? (
                      formatDate(new Date(result.estimatedUpcomingDate))
                    ) : (
                      <div className="flex items-center justify-end">
                        -- <AlertIcon className="ml-2" />
                      </div>
                    )}
                  </div>
                </TableBodyCell>
              </TableBodyRow>
            ))}
          </tbody>
        </Table>

        {id && <TradeAlertModal id={id} onClose={handleCloseModal} />}
      </PageWrapper>
    );

  return null;
};

export { TradeAlerts };
