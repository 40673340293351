import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { AxiosResponse } from 'axios';
import { FilingDetails, CurrentDisplay, DisplayType } from '../types';
import { Card, Divider, IconButton } from '@material-ui/core';

import { ArrowDropDown, VisibilityOutlined } from '@material-ui/icons';

import { PreviewMenu } from './PreviewMenu';
import { DocumentView } from './DocumentView';
import { BallotView } from './BallotView';
import { EmailView } from './EmailView';
import { CSVView } from './CSVView';

type PreviewPaneProps = {
  data: AxiosResponse<FilingDetails> | undefined;
  setCurrentDisplay: React.Dispatch<React.SetStateAction<CurrentDisplay>>;
  currentDisplay: CurrentDisplay;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pane: {
      position: 'sticky',
      top: '16.375rem',
      right: '1.5rem',
      left: 'auto',
      height: 'calc(100vh - 16.5rem)',
    },
    cardHeader: {
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    documentHeader: {
      width: '50%',
      paddingLeft: theme.spacing(4),
    },
    documentDropdown: {
      width: '400px',
      height: '100%',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderLeft: '1px solid #e5e5e5',
    },
    documentViewer: {},
  }),
);

function DetailsPane({
  data,
  currentDisplay,
  setCurrentDisplay,
}: PreviewPaneProps) {
  const classes = useStyles();

  // Dropdown Select - MaterialUI Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectItem = ({ ...selected }: CurrentDisplay) => {
    setCurrentDisplay((prevState) => ({
      ...prevState,
      itemType: selected.itemType,
      index: selected.index,
    }));
    handleClose();
  };

  if (data) {
    const sourceTypeNames = new Map([
      ['new_email', 'Broker Search'],
      ['update_email', 'Broker Search Update'],
      ['proposals_email', 'Meeting Agenda'],
      ['materials_email', 'Material URL(s)'],
      ['csv', 'Morrow .csv'],
    ]);

    const renderName = ({ itemType, index }: CurrentDisplay) => {
      if (index === undefined || !itemType) return 'Preview';
      if (
        itemType === DisplayType.Document &&
        index !== undefined &&
        data.data.documents[index]
      ) {
        return data.data.documents[index].name;
      } else if (itemType === DisplayType.Ballot) {
        return 'Vote Website';
      } else if (itemType === DisplayType.Email && index !== undefined) {
        return sourceTypeNames.get(
          data.data.referenceMaterials[index].sourceType,
        );
      } else return 'Preview';
    };

    const renderPane = ({ itemType, index }: CurrentDisplay) => {
      if (index === undefined || !itemType) return <EmptyPane />;
      if (itemType === DisplayType.Document && index !== undefined) {
        return <DocumentView filing={data.data} index={index} />;
      } else if (itemType === DisplayType.Ballot) {
        return <BallotView />;
      } else if (itemType === DisplayType.Email && index !== undefined) {
        return <EmailView html={data.data.referenceMaterials[index].html} />;
      } else if (itemType === DisplayType.CSV && index !== undefined) {
        return <CSVView csv={data.data.referenceMaterials[index].csvData} />;
      } else return <EmptyPane />;
    };

    return (
      <Card className={classes.pane} elevation={3}>
        <div className={classes.cardHeader}>
          <div className={classes.documentHeader}>
            {renderName(currentDisplay)}
          </div>
          <div className={classes.documentDropdown} onClick={handleClick}>
            Select
            <IconButton size="small">
              <ArrowDropDown />
            </IconButton>
          </div>
        </div>
        <PreviewMenu
          filing={data.data}
          anchorEl={anchorEl}
          onClose={handleClose}
          selectItem={selectItem}
        />
        <Divider />
        <div className={classes.documentViewer}>
          {renderPane(currentDisplay)}
        </div>
      </Card>
    );
  }
  return null;
}

export { DetailsPane };

export const EmptyPane = () => {
  return (
    <div
      style={{ height: '50vh' }}
      className="flex w-full flex-col items-center justify-center font-medium uppercase py-1 whitespace-no-wrap text-secondary-text tracking-wider"
    >
      <VisibilityOutlined fontSize="large" />
      <p className="pt-1">PREVIEW</p>
    </div>
  );
};
