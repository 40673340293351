import React, { FC } from 'react';
import { Flex } from '@rebass/grid';

import { ReactComponent as Close } from '../../common/assets/Close.svg';
import { Modal } from '../../common/Modal';
import styled from 'styled-components/macro';

const Title = styled.h1``;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.red};
  margin: 0 0 10px 0;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights[2]};
`;

const CloseContainer = styled.div`
  cursor: pointer;
`;

const TitleContainer = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray};
  padding: 0 0 10px 0;
`;

interface IProps {
  isOpen: boolean;
  errorMessages: string[];
  onClose: () => void;
}

export const ErrorModal: FC<IProps> = ({ isOpen, errorMessages, onClose }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Flex flexDirection="column" p={20}>
        <TitleContainer flexDirection="row" justifyContent="space-between">
          <Title>
            Something went wrong with this request{' '}
            <span role="img" aria-label="Errg! Errors!">
              😬
            </span>
          </Title>
          <CloseContainer onClick={() => onClose()}>
            <Close />
          </CloseContainer>
        </TitleContainer>
        <Flex mt={10} flexDirection="column">
          {errorMessages.map((errorMessage) => (
            <ErrorText key={errorMessage}>{errorMessage}</ErrorText>
          ))}
        </Flex>
      </Flex>
    </Modal>
  );
};
