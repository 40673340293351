import { Stats } from './stats';
import { FilingType } from './filing-type';

export interface Issuer {
  cik: number | null;
  edgarLandingPage: string | null;
  id: number;
  companyName: string;
  contactEmail: string | null;
}

export interface IAddress {
  line1: string;
  line2: string;
  line3: string;
  city: string;
  state: string;
  postalCode: string;
  country: string | null;
}

export interface Filing {
  deliveryMethod: DeliveryMethod;
  digitalMaterialsExpectedDate: string | null;
  digitalMaterialsReceivedDate: string | null;
  lastDigitalMaterialSendDate: string | null;
  id: number;
  issuer: Issuer;
  paperMaterialsExpectedDate: string | null;
  paperMaterialsReceivedDate: string | null;
  paperSendDeadline: string | null;
  recordDate: string | null;
  securities: Security[];
  solicitorCompany: null | string;
  solicitorContactEmail: null | string;
  solicitorContactName: null | string;
  stats: Stats | null;
  status: Status;
  created: string;
  digitalSendDeadline: string | null;
  invoiceSentDate: string | null;
  invoicerAddress: string | null;
  invoicerContactEmail: string | null;
  invoicerAddressLine1: string | null;
  invoicerAddressLine2: string | null;
  invoicerAddressLine3: string | null;
  invoicerCity: string | null;
  invoicerState: string | null;
  invoicerPostalCode: string | null;
  invoicerCountry: string | null;
  invoicerCompany: string | null;
  invoicerContact: string | null;
  invoicerNotes: string | null;
  daysOpen: number;
  haveMaterialsBeenRequested: boolean;
  type: FilingType;
  documents: any[];
  meetingDate: string | null;
}

export enum DeliveryMethod {
  NoticeAndAccess = 'notice_and_access',
  Traditional = 'traditional',
}

export interface Meeting {
  type: MeetingType;
  title: null;
  date: string | null;
  time: null | string;
  timezone: string;
  contested: boolean;
  proxyType: ProxyType;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  country: string;
  state: string;
  isPhysical: boolean;
  virtualMeetingURL: null | string;
}

export enum ProxyType {
  Traditional = 'traditional',
  Unspecified = 'unspecified',
}

export enum MeetingType {
  Annual = 'annual',
  Special = 'special',
}

export interface Security {
  id: number;
  created: string;
  modified: string;
  ticker: string | null;
  cusip: string;
  name: string;
  isin: string | null;
  issuer: number;
}

export enum Status {
  ConfirmMeetingInfo = 'confirm_meeting_info',
  ConfirmReportInfo = 'confirm_report_info',
  ReceiveMaterials = 'receive_materials',
  SendMaterials = 'send_materials',
  SendMaterialsEstimate = 'send_materials_estimate',
}
