import React from 'react';
import { CusipTag } from '../CusipTag';

type CusipTagListProps = {
  cusips: string[];
};

const CusipTagList = ({ cusips }: CusipTagListProps) => {
  const totalCusips = cusips.length;
  const firstCusip = cusips[0];
  const remainingCusipsLength = cusips.length - 1;
  const [isOverflowVisible, setIsOverflowVisible] = React.useState(false);

  const handleShowToggle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setIsOverflowVisible(!isOverflowVisible);
  };

  if (totalCusips === 0) {
    return null;
  }

  if (isOverflowVisible) {
    return (
      <Wrapper>
        {cusips.map((cusip) => (
          <CusipTag key={cusip}>{cusip}</CusipTag>
        ))}
        <Button onClick={handleShowToggle}>Show Less</Button>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <CusipTag>{firstCusip}</CusipTag>
        {totalCusips > 1 && (
          <Button onClick={handleShowToggle}>
            Show {remainingCusipsLength} More
          </Button>
        )}
      </Wrapper>
    );
  }
};

function Wrapper({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-col items-start">{children}</div>;
}

function Button({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  return (
    <button
      className="text-xxs text-secondary-text font-medium uppercase mt-1 text-center"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export { CusipTagList };
