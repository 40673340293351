import React, {
  FC,
  useState,
  ChangeEvent,
  MouseEvent,
  useEffect,
  ReactElement,
} from 'react';
import { decamelize, camelizeKeys } from 'humps';
import { transparentize } from 'polished';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components/macro';
import {
  Formik,
  FormikProps,
  FormikActions,
  Form,
  Field,
  FieldProps,
} from 'formik';
import { RouteComponentProps, navigate } from '@reach/router';
import axios from 'axios';
import { toast } from 'react-toastify';

import { AllResults, IMeetingResultsPayload } from '../data/SECFilings';
import { ReactComponent as ArrowLeft } from '../common/assets/arrow-left.svg';
import { Text } from '../common/Text';
import { ResultsLayout } from './ResultsLayout';
import { PlainButton } from '../common/Buttons/PlainButton';
import { GrayButton } from '../common/Buttons/GrayButton';
import { Select } from '../common/Select';
import {
  Card,
  RowHeader,
  ProposalTypeContainer,
  SecurityBox,
  VoteChoice,
} from '../Filing/Vote/VoteStyledComponents';
import { Input } from '../common/Input';
import { Location } from '../data/Location.Container';
import { SECFilingStatus } from '../models/sec-filing-list';
import { Button } from '../common/Buttons/Button';
import { Dialog } from '../common/Dialog/Dialog';
import { Modal } from '../common/Modal';
import { OutlineButton } from '../common/Buttons/OutlineButton';
import { formatCamelCaseString } from '../utils/format-camel-case-string';
import { formatDate } from '../utils/format-date';
import { Security } from '../models/filing';
import { IVoteProposal, IMeetingResult } from '../models/vote-proposal';
import { groupProposals } from '../utils/groupVoteProposals';
import {
  ISECFilingForm,
  requestType as requestTypes,
  ISECFilingProposalResults,
  IChoiceResults,
} from '../models/sec-filing';
import { ErrorLabel } from '../common/ErrorLabel';
import { privateApi } from '../utils/api-adapter';
import { Spinner } from '../common/Spinner/Spinner';
import { Label } from '@blueprintjs/core';
import { InputGroupRow } from '../common/InputGroup';
import { userIdEvent } from '../utils/analytics-helpers';

const Infobar = styled.div`
  background: #595959;
  color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: repeat(2, auto);
  font-size: 14px;
  font-weight: 300;
  grid-gap: 32px;
  padding: 8px 16px;
`;

const InfoBarValue = styled.span`
  color: ${({ theme }) => theme.colors.green};
`;

const Issuer = styled(Text)`
  text-transform: capitalize;
`;

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr 50px;
  grid-template-areas:
    'filing-details'
    'proposals'
    'submit';
  width: 100%;
  min-height: 80vh;
  grid-row-gap: 25px;
  background-color: ${({ theme }) => transparentize(0.7, theme.colors.gray)};
  padding: 20px;
`;

const FilingDetailsContainer = styled(Flex)`
  grid-area: filing-details;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-left: 5px solid ${({ theme }) => theme.colors.orange};
  background-color: ${({ theme }) => theme.colors.white};
`;

const ProposalsContainer = styled(Flex)`
  grid-area: proposals;
  overflow: scroll;
`;

const SubmitContainer = styled(Flex)`
  grid-area: 'submit';
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledSelect = styled(Select)<{ edgarValue: SECFilingStatus }>`
  width: 100%;
  background-color: ${({ edgarValue, theme }) =>
    edgarValue === 'confirmed'
      ? transparentize(0.2, theme.colors.green)
      : edgarValue === 'pending'
      ? transparentize(0.2, theme.colors.yellow)
      : transparentize(0.2, theme.colors.red)};
`;

const StyledHr = styled.hr`
  border: 2px solid ${({ theme }) => transparentize(0.7, theme.colors.gray)};
  width: 100%;
  margin: 0;
`;

const RecType = styled.span`
  text-transform: uppercase;
`;

const ProposalMetaContainer = styled(Flex)`
  width: 300px;
`;

const BoldUpCase = styled.span`
  text-transform: uppercase;
  font-weight: 800;
`;

const StyledInput = styled(Input)`
  vertical-align: middle;
  height: auto;
  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.divider};
  }
`;

const ResultsInput = styled(Input)`
  &:disabled {
    opacity: 0.5;
  }
`;

const VoteChoiceContainer = styled(Flex)<{ isRequestingVotedOn: boolean }>`
  opacity: ${({ isRequestingVotedOn }) => (isRequestingVotedOn ? '.5' : '1')};
`;

const StyledVoteChoice = styled(VoteChoice)<{ notDisabled: boolean }>`
  opacity: ${({ notDisabled }) => (notDisabled ? '1' : '.5')};
`;

interface IProps extends RouteComponentProps {
  id?: string;
}

export const routine = (isRoutine: boolean) =>
  isRoutine ? 'Routine' : 'Non-Routine';

export const ResultsDetail: FC<IProps> = ({ id }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [proposalGroups, setProposalGroups] = useState<IVoteProposal[][]>(
    [] as IVoteProposal[][],
  );
  const [initialValueGroups, setInitialValueGroups] = useState<
    ISECFilingProposalResults
  >({} as ISECFilingProposalResults);
  const [totalSharrors, setTotalSharrors] = useState<{
    [key: string]: ReactElement;
  }>({} as { [key: string]: ReactElement });
  const [isRequestingVotedOn, setIsRequestingVotedOn] = useState<{
    isRequesting: boolean;
    groupNumber: number;
  }>({ isRequesting: false, groupNumber: NaN });
  const [status, setStatus] = useState<SECFilingStatus>('pending');

  const { SECFiling, getSECFilingById, allResults } = AllResults.useContainer();
  const { location } = Location.useContainer();

  useEffect(() => {
    if (id) {
      getSECFilingById(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (SECFiling && SECFiling.filing && SECFiling.filing.voteProposals) {
      const groupedProposals = groupProposals(SECFiling.filing.voteProposals);
      setProposalGroups(groupedProposals);
      setStatus(SECFiling.status);
    }
    // eslint-disable-next-line
  }, [SECFiling]);

  useEffect(() => {
    if (proposalGroups) {
      const groupsOfInitialValues: ISECFilingProposalResults = proposalGroups.reduce(
        (
          secProposalResults: ISECFilingProposalResults,
          currentGroup: IVoteProposal[],
        ) => {
          currentGroup.forEach((proposal: IVoteProposal) => {
            secProposalResults[proposal.id.toString()] = {};
            proposal.voteChoices.forEach((choice: string) => {
              if (proposal.meetingResults) {
                const foundMeetingResult:
                  | IMeetingResult
                  | undefined = proposal.meetingResults.find(
                  (meetingResult: IMeetingResult) =>
                    meetingResult.choice.toUpperCase() === choice.toUpperCase(),
                );
                if (foundMeetingResult) {
                  secProposalResults[proposal.id.toString()][choice] = {
                    requestType: requestTypes.PATCH,
                    voteProposalId: proposal.id,
                    votedAtMeeting: proposal.votedAtMeeting,
                    choice,
                    totalShares: parseInt(
                      foundMeetingResult.totalShares,
                      10,
                    ).toString(),
                    meetingResultId: foundMeetingResult.id,
                  };
                } else {
                  secProposalResults[proposal.id.toString()][choice] = {
                    requestType: requestTypes.POST,
                    voteProposalId: proposal.id,
                    votedAtMeeting: proposal.votedAtMeeting,
                    choice,
                    totalShares: '',
                  };
                }
              } else {
                secProposalResults[proposal.id.toString()][choice] = {
                  requestType: requestTypes.POST,
                  votedAtMeeting: proposal.votedAtMeeting,
                  voteProposalId: proposal.id,
                  choice,
                  totalShares: '',
                };
              }
            });
          });

          return secProposalResults;
        },
        {} as ISECFilingProposalResults,
      );

      setInitialValueGroups(groupsOfInitialValues);
    }
    // eslint-disable-next-line
  }, [proposalGroups]);

  const handleClearError = (name: string) => {
    delete totalSharrors[name];
    setTotalSharrors(totalSharrors);
  };

  const formatErrorModel = (
    values: ISECFilingForm,
  ): { [key: string]: ReactElement } => {
    const errors = {} as { [key: string]: ReactElement };
    SECFiling &&
      SECFiling.filing.voteProposals &&
      SECFiling.filing.voteProposals.forEach((proposal: IVoteProposal) => {
        proposal.voteChoices.forEach((choice: string) => {
          if (
            !values.meetingResults[`${proposal.id}`][`${choice}`].totalShares &&
            values.meetingResults[`${proposal.id}`][`${choice}`].votedAtMeeting
          ) {
            errors[
              `meetingResults.${
                proposal.id
              }.${choice.toLowerCase()}.totalShares`
            ] = (
              <Box mt={3}>
                Must include total shares for{' '}
                <BoldUpCase>{choice.split('_').join(' ')}</BoldUpCase>
              </Box>
            ) as ReactElement;
          }
        });
      });

    return errors;
  };
  return (
    <ResultsLayout>
      {SECFiling &&
      proposalGroups[0] &&
      proposalGroups[0][0] &&
      initialValueGroups.hasOwnProperty(proposalGroups[0][0].id) ? (
        <Formik
          initialValues={{
            status: status,
            meetingResults: { ...initialValueGroups },
          }}
          onSubmit={(
            values: ISECFilingForm,
            actions: FormikActions<ISECFilingForm>,
          ) => {
            const errors = formatErrorModel(values);

            if (Object.keys(errors).length > 0) {
              setTotalSharrors(errors);
              actions.setSubmitting(false);
              return;
            }

            actions.setSubmitting(true);

            const allRequests = Object.keys(values.meetingResults).reduce(
              (promises: any[], proposalId: string) => {
                const arrayOfChoiceResults = Object.keys(
                  values.meetingResults[proposalId],
                ).map((choice: string) => {
                  const {
                    totalShares,
                    requestType,
                    meetingResultId,
                    votedAtMeeting,
                  } = values.meetingResults[`${proposalId}`][
                    `${choice}`
                  ] as IChoiceResults;

                  if (votedAtMeeting) {
                    const payload: IMeetingResultsPayload = {
                      voteProposal: parseInt(proposalId, 10),
                      choice: correctCase(choice),
                      totalShares: parseInt(totalShares, 10),
                    };

                    if (requestType === requestTypes.PATCH) {
                      const matchingVoteProposal =
                        SECFiling.filing.voteProposals &&
                        SECFiling.filing.voteProposals.find(
                          (proposal: IVoteProposal) =>
                            proposal.id === parseInt(proposalId, 10),
                        );

                      if (
                        matchingVoteProposal &&
                        matchingVoteProposal.meetingResults
                      ) {
                        const matchingMeetingResult = matchingVoteProposal.meetingResults.find(
                          (meetingResult: IMeetingResult) =>
                            meetingResult.id === meetingResultId,
                        );
                        if (
                          matchingMeetingResult &&
                          parseInt(
                            matchingMeetingResult.totalShares,
                            10,
                          ).toString() === parseInt(totalShares, 10).toString()
                        ) {
                          return null;
                        }
                      }

                      return privateApi.patch(
                        `admin/meeting-results/${meetingResultId}/`,
                        payload,
                      );
                    } else {
                      return privateApi.post(`admin/meeting-results/`, payload);
                    }
                  }

                  return null;
                });

                const filteredArrayOfChoiceResults = arrayOfChoiceResults.filter(
                  (result: any) => result !== null,
                );

                return [...promises, ...filteredArrayOfChoiceResults];
              },
              [] as any[],
            );

            axios
              .all(allRequests)
              .then((responses) => {
                toast.success('Meeting results were successfully saved.');
                actions.setSubmitting(false);
                if (id) {
                  privateApi
                    .patch(`/admin/sec-filings/${id}/`, {
                      status: values.status.toLowerCase(),
                    })
                    .then((response) => {
                      actions.setSubmitting(false);

                      userIdEvent('Save Meeting Results Submit', {
                        edgarFilingId: id,
                        edgarFilingStatus: values.status,
                        save: true,
                      });
                      getSECFilingById(id);
                    })
                    .catch((error) => {
                      toast.error('Unable to change filing status.');
                    });
                }
              })
              .catch((error) => {
                const { voteProposal, choice } = camelizeKeys(
                  JSON.parse(error.config.data),
                ) as any;
                const name = `meetingResults.${voteProposal}.${choice.toLowerCase()}.totalShares`;
                const sharror: { [key: string]: ReactElement } = {
                  [`${name}`]: (
                    <Box mt={3}>
                      Could not make request for for{' '}
                      <BoldUpCase>{choice.split('_').join(' ')}</BoldUpCase> on{' '}
                      {voteProposal}
                    </Box>
                  ),
                };

                setTotalSharrors(sharror);
                actions.setSubmitting(false);
              });
          }}
          render={({
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
            values,
          }: FormikProps<ISECFilingForm>) => {
            return (
              <Form>
                <StyledContainer>
                  <FilingDetailsContainer py={3} px={4}>
                    <Flex flexDirection="column" mb={3}>
                      <PlainButton
                        type="button"
                        width={100}
                        display="flex"
                        pl={0}
                        alignItems="center"
                        onClick={() => {
                          navigate(location.backButtonPath);
                        }}
                      >
                        <ArrowLeft /> Back
                      </PlainButton>
                      <Flex
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Issuer as="h1" fontSize={4} fontWeight={4} mt={4}>
                          {SECFiling.filing.issuer.companyName}
                        </Issuer>
                        <Text as="h1" fontSize={2} fontWeight={4} mt={0}>
                          {formatCamelCaseString(SECFiling.filing.type)}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      flexDirection="row"
                      width={1}
                      justifyContent="space-between"
                    >
                      <Flex flexDirection="column" width={1 / 4}>
                        <Box>
                          <Text as="h1" fontSize={2} fontWeight={6} mb={2}>
                            Cusip(s):
                          </Text>
                          <Flex flexDirection="column">
                            {SECFiling.filing.securities.map(
                              (security: Security) => (
                                <SecurityBox key={security.cusip}>
                                  {security.cusip}
                                </SecurityBox>
                              ),
                            )}
                          </Flex>
                        </Box>
                      </Flex>
                      <Flex flexDirection="column" width={1 / 4}>
                        <Box mb={4}>
                          <Text as="h1" fontSize={2} fontWeight={6} mb={2}>
                            Record Date
                          </Text>
                          <Text as="p" fontSize={2} fontWeight={2}>
                            {formatDate(SECFiling.filing.recordDate)}
                          </Text>
                        </Box>
                        <Box>
                          <Text as="h1" fontSize={2} fontWeight={6} mb={2}>
                            Edgar Date
                          </Text>
                          <Text
                            as="p"
                            fontSize={2}
                            fontWeight={2}
                            onClick={() => {
                              userIdEvent('Edgar Clicked', {
                                edgarFilingId: id,
                                edgarFilingStatus: SECFiling.status,
                              });
                            }}
                          >
                            <a
                              href={SECFiling.url}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {formatDate(SECFiling.filingDate)}
                            </a>
                          </Text>
                        </Box>
                      </Flex>
                      <Flex flexDirection="column" width={1 / 4}>
                        {SECFiling.filing.meetingDate && (
                          <Box mb={4}>
                            <Text as="h1" fontSize={2} fontWeight={6} mb={2}>
                              Meeting Date
                            </Text>
                            <Text as="p" fontSize={2} fontWeight={2}>
                              {formatDate(SECFiling.filing.meetingDate)}
                            </Text>
                          </Box>
                        )}
                        <Box>
                          <Text as="h1" fontSize={2} fontWeight={6} mb={2}>
                            Meeting Type
                          </Text>
                          <Text as="p" fontSize={2} fontWeight={2}>
                            {formatCamelCaseString(SECFiling.filing.type)}
                          </Text>
                        </Box>
                      </Flex>

                      <Flex flexDirection="column" width={1 / 4}>
                        <Box width={1}>
                          <Text as="h1" fontSize={2} fontWeight={6} mb={2}>
                            Status
                          </Text>
                          <Field
                            name="status"
                            render={({ field }: FieldProps<ISECFilingForm>) => (
                              <StyledSelect edgarValue={field.value} {...field}>
                                <option value="pending">Pending</option>
                                <option value="confirmed">Confirmed</option>
                                <option value="dismissed">Dismissed</option>
                              </StyledSelect>
                            )}
                          />
                        </Box>
                      </Flex>
                    </Flex>
                  </FilingDetailsContainer>
                  <ProposalsContainer>
                    {/* map through proposals */}
                    <Flex flexDirection={'column'} width={1}>
                      {SECFiling.filing.stats && (
                        <Infobar>
                          <span>
                            Positions:{' '}
                            <InfoBarValue>
                              {SECFiling.filing.stats.totalPositions}
                            </InfoBarValue>
                          </span>
                        </Infobar>
                      )}
                      {proposalGroups.map(
                        (proposalGroup: IVoteProposal[], i: number) => {
                          const votedAtMeetingNameFirstChoice: string = proposalGroup[0].voteChoices[0].toLowerCase();
                          const votedAtMeetingName = `meetingResults.${proposalGroup[0].id}.${votedAtMeetingNameFirstChoice}.votedAtMeeting`;

                          return (
                            <Card
                              mt={0}
                              key={`vp-group-${proposalGroup[0].groupNumber}-${i}`}
                              flexDirection="column"
                              bg="white"
                            >
                              <RowHeader
                                flexDirection="row"
                                width={1}
                                pt={20}
                                pb={15}
                                px={35}
                              >
                                <Flex flexDirection="row" width={1}>
                                  <ProposalTypeContainer width={1 / 3} pt="5px">
                                    {decamelize(proposalGroup[0].type, {
                                      separator: ' ',
                                    })}
                                  </ProposalTypeContainer>
                                  <Flex
                                    flexDirection="row"
                                    justifyContent="flex-end"
                                    width={2 / 3}
                                  >
                                    <Box mr={4} pt="5px">
                                      {routine(proposalGroup[0].isRoutine)}
                                    </Box>
                                    <Flex flexDirection="row">
                                      <Box pt="5px" mr={2}>
                                        CUSIP(s):
                                      </Box>
                                      <Flex flexDirection="column">
                                        {SECFiling.filing.securities.map(
                                          (security: Security) => (
                                            <SecurityBox
                                              key={`${security.cusip}-2`}
                                            >
                                              {security.cusip}
                                            </SecurityBox>
                                          ),
                                        )}
                                      </Flex>
                                    </Flex>
                                  </Flex>
                                </Flex>
                              </RowHeader>
                              <Flex
                                flexDirection="column"
                                key={`proposalGroup-${proposalGroup[0].groupNumber}`}
                              >
                                <Text
                                  as="h2"
                                  fontWeight={5}
                                  fontSize={2}
                                  mt={3}
                                  px={4}
                                >
                                  Proposal {proposalGroup[0].groupNumber}
                                </Text>

                                <Box px={4}>
                                  <Field
                                    name={votedAtMeetingName}
                                    render={({
                                      field,
                                      form,
                                    }: FieldProps<ISECFilingForm>) => {
                                      return (
                                        <InputGroupRow>
                                          <Label>
                                            Voted On
                                            <StyledInput
                                              ml={2}
                                              pt={1}
                                              checked={field.value}
                                              {...field}
                                              disabled={
                                                isRequestingVotedOn.isRequesting &&
                                                isRequestingVotedOn.groupNumber ===
                                                  proposalGroup[0].groupNumber
                                              }
                                              type="checkbox"
                                              onChange={(
                                                e: React.ChangeEvent<
                                                  HTMLInputElement
                                                >,
                                              ) => {
                                                setIsRequestingVotedOn({
                                                  isRequesting: true,
                                                  groupNumber:
                                                    proposalGroup[0]
                                                      .groupNumber,
                                                });
                                                const currentValue =
                                                  field.value;

                                                const proposalsToPatch = proposalGroup.map(
                                                  (proposal: IVoteProposal) => {
                                                    return privateApi.patch(
                                                      `vote-proposals/${proposal.id}/`,
                                                      {
                                                        votedAtMeeting: !currentValue,
                                                      },
                                                    );
                                                  },
                                                );

                                                axios
                                                  .all(proposalsToPatch)
                                                  .then((responses) => {
                                                    setIsRequestingVotedOn({
                                                      isRequesting: false,
                                                      groupNumber: NaN,
                                                    });
                                                    [...proposalGroup].forEach(
                                                      (vp: IVoteProposal) => {
                                                        vp.voteChoices.forEach(
                                                          (voteChoice) => {
                                                            if (
                                                              values
                                                                .meetingResults[
                                                                `${vp.id}`
                                                              ][`${voteChoice}`]
                                                                .votedAtMeeting
                                                            ) {
                                                              handleClearError(
                                                                `meetingResults.${
                                                                  vp.id
                                                                }.${voteChoice.toLowerCase()}.totalShares`,
                                                              );
                                                            }
                                                            form.setFieldValue(
                                                              `meetingResults.${
                                                                vp.id
                                                              }.${voteChoice.toLowerCase()}.votedAtMeeting`,
                                                              !currentValue,
                                                            );
                                                          },
                                                        );
                                                      },
                                                    );
                                                  })
                                                  .catch(() => {
                                                    setIsRequestingVotedOn({
                                                      isRequesting: false,
                                                      groupNumber: NaN,
                                                    });
                                                    toast.error(
                                                      "Couldn't set these results as voted on or not voted on",
                                                    );
                                                  });
                                              }}
                                            />
                                          </Label>
                                        </InputGroupRow>
                                      );
                                    }}
                                  />
                                </Box>

                                {proposalGroup.map(
                                  (proposal: IVoteProposal, j: number) => (
                                    <Flex
                                      key={`${proposal.id}`}
                                      flexDirection="row"
                                      justifyContent="flex-start"
                                      mt={3}
                                      px={4}
                                      width={1}
                                    >
                                      <ProposalMetaContainer
                                        flexDirection="column"
                                        mr={5}
                                      >
                                        <Text
                                          as="h2"
                                          fontSize={1}
                                          fontWeight={5}
                                          lineHeight={1.2}
                                          mr={5}
                                        >
                                          Voting Recommendation:{' '}
                                          <RecType>
                                            {proposal.recommendationType
                                              .split('_')
                                              .join(' ')
                                              .toUpperCase()}
                                          </RecType>
                                        </Text>
                                        <Text
                                          as="h2"
                                          fontSize={2}
                                          fontWeight={3}
                                          mr={5}
                                          my={3}
                                        >
                                          <BoldUpCase>{proposal.id}</BoldUpCase>
                                          :{' '}
                                          {proposal.directorName &&
                                            proposal.directorName}
                                          {proposal.details && proposal.details}
                                        </Text>
                                      </ProposalMetaContainer>

                                      {proposal.voteChoices.map(
                                        (choice: string, i: number) => {
                                          const name = `meetingResults.${
                                            proposal.id
                                          }.${choice.toLowerCase()}.totalShares`;

                                          return (
                                            <VoteChoiceContainer
                                              key={`${choice}-${proposal.id}`}
                                              isRequestingVotedOn={
                                                isRequestingVotedOn.isRequesting &&
                                                proposalGroup[0].groupNumber ===
                                                  isRequestingVotedOn.groupNumber
                                              }
                                              flexDirection="column"
                                              mr={4}
                                              alignItems="flex-start"
                                            >
                                              <StyledVoteChoice
                                                notDisabled={
                                                  values.meetingResults[
                                                    `${proposal.id}`
                                                  ][`${choice}`]
                                                    .votedAtMeeting ||
                                                  (isRequestingVotedOn.isRequesting &&
                                                    isRequestingVotedOn.groupNumber ===
                                                      proposalGroup[0]
                                                        .groupNumber)
                                                }
                                              >
                                                {choice
                                                  .split('_')
                                                  .join(' ')
                                                  .toUpperCase()}
                                              </StyledVoteChoice>
                                              <Box my={3}>
                                                <Field
                                                  name={name}
                                                  render={({
                                                    field,
                                                    form,
                                                  }: FieldProps<
                                                    ISECFilingForm
                                                  >) => {
                                                    return (
                                                      <>
                                                        <ResultsInput
                                                          {...field}
                                                          width={130}
                                                          disabled={
                                                            !form.values
                                                              .meetingResults[
                                                              `${proposal.id}`
                                                            ][`${choice}`]
                                                              .votedAtMeeting ||
                                                            (isRequestingVotedOn.isRequesting &&
                                                              isRequestingVotedOn.groupNumber ===
                                                                proposalGroup[0]
                                                                  .groupNumber)
                                                          }
                                                          id={name}
                                                          type="number"
                                                          onChange={(
                                                            e: ChangeEvent<
                                                              HTMLInputElement
                                                            >,
                                                          ) => {
                                                            if (
                                                              totalSharrors[
                                                                name
                                                              ]
                                                            ) {
                                                              handleClearError(
                                                                name,
                                                              );
                                                              setFieldValue(
                                                                name,
                                                                e.target.value,
                                                              );
                                                            } else {
                                                              setFieldValue(
                                                                name,
                                                                e.target.value,
                                                              );
                                                            }
                                                          }}
                                                        />
                                                        {totalSharrors[
                                                          name
                                                        ] && (
                                                          <div>
                                                            <ErrorLabel
                                                              children={
                                                                totalSharrors[
                                                                  name
                                                                ]
                                                              }
                                                              htmlFor={name}
                                                            />
                                                          </div>
                                                        )}
                                                      </>
                                                    );
                                                  }}
                                                />
                                              </Box>
                                            </VoteChoiceContainer>
                                          );
                                        },
                                      )}
                                    </Flex>
                                  ),
                                )}
                              </Flex>

                              <StyledHr />
                            </Card>
                          );
                        },
                      )}
                    </Flex>
                  </ProposalsContainer>
                  <SubmitContainer>
                    <GrayButton
                      mr={3}
                      onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        navigate(`${id}/email-preview`);
                      }}
                    >
                      Review Email
                    </GrayButton>
                    <Button
                      disabled={isSubmitting}
                      onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        setShowDialog(true);
                      }}
                    >
                      {isSubmitting ? 'Saving... ⏳' : 'Save'}
                    </Button>
                  </SubmitContainer>
                  <Modal
                    isOpen={showDialog}
                    onRequestClose={() => setShowDialog(false)}
                  >
                    <Dialog
                      title="Save Meeting Results"
                      onRequestClose={() => setShowDialog(false)}
                    >
                      <Flex flexDirection="column">
                        <Text as="h1" fontSize={3}>
                          Are you sure you want to save this meeting's results?
                          Confirmed results will be sent to shareholders.
                          Dismissed results will be ignored.
                        </Text>

                        <Flex
                          flexDirection="row"
                          justifyContent="flex-end"
                          mt={5}
                        >
                          <Button
                            onClick={() => {
                              userIdEvent('Save Meeting Results Clicked', {
                                edgarFilingId: id,
                                edgarFilingStatus: SECFiling.status,
                              });
                              handleSubmit();
                              setShowDialog(false);
                            }}
                            width={150}
                            mr={4}
                            type="submit"
                          >
                            Confirm
                          </Button>
                          <OutlineButton
                            onClick={() => setShowDialog(false)}
                            width={150}
                          >
                            Cancel
                          </OutlineButton>
                        </Flex>
                      </Flex>
                    </Dialog>
                  </Modal>
                </StyledContainer>
              </Form>
            );
          }}
        />
      ) : allResults.isLoading ? (
        <>
          <Flex justifyContent="center" alignItems="center" mt={4} width={1}>
            <Spinner />
          </Flex>
        </>
      ) : (
        <Flex justifyContent="center" alignItems="center" mt={4} width={1}>
          Sorry there aren't vote proposals, dood.
        </Flex>
      )}
    </ResultsLayout>
  );
};

function correctCase(choice: string) {
  const humanizedChoice = choice.split('_').join(' ');
  return humanizedChoice.replace(/(^|[\s-])\S/g, function (match) {
    return match.toUpperCase();
  });
}
