import { createContainer } from 'unstated-next';
import { useImmer } from 'use-immer';
import {
  FilingListResponse,
  FilingListFiling,
  OperationsStatus,
} from '../models/filing-list';
import { toast } from 'react-toastify';
import humps from 'humps';
import { privateApi } from '../utils/api-adapter';
import qs from 'query-string';
import { FilingType } from '../models/filing-type';

enum TabChoice {
  Complete = 'complete',
  RequestMaterials = 'request_materials',
  ReviewEmails = 'review_emails',
}

interface ProxyState {
  complete: {
    filings: FilingListResponse;
  };
  requestMaterials: {
    filings: FilingListResponse;
  };
  reviewEmails: {
    filings: FilingListResponse;
  };
  isLoading: boolean;
  hasData: boolean;
  filingTypeFilter: FilingType | '';
  search: string;
  isNewFilingDialogOpen: boolean;
}

const initialProxyState: ProxyState = {
  complete: {
    filings: {
      count: 0,
      next: null,
      previous: null,
      results: [] as FilingListFiling[],
    },
  },
  requestMaterials: {
    filings: {
      count: 0,
      next: null,
      previous: null,
      results: [] as FilingListFiling[],
    },
  },
  reviewEmails: {
    filings: {
      count: 0,
      next: null,
      previous: null,
      results: [] as FilingListFiling[],
    },
  },
  isLoading: false,
  hasData: false,
  filingTypeFilter: '',
  search: '',
  isNewFilingDialogOpen: false,
};

const useProxy = () => {
  const [proxy, updateProxy] = useImmer<ProxyState>(initialProxyState);

  const setIsLoading = (isLoading: boolean) => {
    updateProxy((draft) => {
      draft.isLoading = isLoading;
    });
  };

  const getTasksFilingByTab = async (
    tab: TabChoice,
    order: string,
    search: string,
    filingType: FilingType | '',
  ) => {
    const camelizedTab = humps.camelize(tab);
    const searchQuery = search ? `&search=${encodeURIComponent(search)}` : '';
    const orderingQuery = `&ordering=${order}`;
    const filingTypeQuery = filingType
      ? `&filing_type=${filingType.toLowerCase()}`
      : '';

    try {
      setIsLoading(true);
      const filingsResponse = await privateApi.get(
        `/filings/?tab=${tab}${searchQuery}${orderingQuery}${filingTypeQuery}`,
      );

      updateProxy((draft) => {
        // @ts-ignore
        draft[camelizedTab].filings = filingsResponse.data;
        draft.isLoading = false;
        draft.hasData = true;
      });
    } catch (error) {
      setIsLoading(false);

      if (error.response) {
        toast.error(error.response.data.error);
      }
    }
  };

  const changePage = async (path: string) => {
    const { tab } = qs.parse(path);
    const url = path.split('/v1')[1];

    try {
      setIsLoading(true);
      const { data } = await privateApi.get(url);
      const type = humps.camelize(tab as string);
      updateProxy((draft) => {
        // @ts-ignore
        draft[type as TabChoice].filings = data;
        draft.isLoading = false;
        draft.hasData = true;
      });
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      }
    }
  };

  const markMaterialsRequested = async (
    filingId: number,
    operationsStatus: OperationsStatus.MaterialsRequested,
  ) => {
    try {
      await privateApi.patch(`/filings/${filingId}/update-status/`, {
        operationsStatus,
      });
      updateProxy((draft) => {
        const { results } = draft.requestMaterials.filings;
        results[
          results.findIndex((result) => result.id === filingId)
        ].operationsStatus = operationsStatus;
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      }
    }
  };

  const unmarkMaterialsRequested = async (
    filingId: number,
    operationsStatus: OperationsStatus.MaterialsRequested,
  ) => {
    try {
      await privateApi.patch(`/filings/${filingId}/unmark-status/`, {
        operationsStatus,
      });
      updateProxy((draft) => {
        const { results } = draft.requestMaterials.filings;
        results[
          results.findIndex((result) => result.id === filingId)
        ].operationsStatus = OperationsStatus.Active;
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      }
    }
  };

  const markReadyForReview = async (
    filingId: number,
    operationsStatus: OperationsStatus.ReadyForReview,
  ) => {
    try {
      await privateApi.patch(`/filings/${filingId}/update-status/`, {
        operationsStatus,
      });
      updateProxy((draft) => {
        const { results } = draft.complete.filings;
        results[
          results.findIndex((result) => result.id === filingId)
        ].operationsStatus = operationsStatus;
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      }
    }
  };

  const setFilingTypeFilter = (filingType: FilingType | '') =>
    updateProxy((draft) => {
      draft.filingTypeFilter = filingType;
    });

  const setSearch = (search: string) =>
    updateProxy((draft) => {
      draft.search = search;
    });

  const unmarkReadyForReview = async (
    filingId: number,
    operationsStatus: OperationsStatus.ReadyForReview,
  ) => {
    try {
      await privateApi.patch(`/filings/${filingId}/unmark-status/`, {
        operationsStatus,
      });
      updateProxy((draft) => {
        const { results } = draft.complete.filings;
        results[
          results.findIndex((result) => result.id === filingId)
        ].operationsStatus = OperationsStatus.MaterialsRequested;
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      }
    }
  };

  const setIsNewFilingDialogOpen = (isOpen: boolean) =>
    updateProxy((draft) => {
      draft.isNewFilingDialogOpen = isOpen;
    });

  return {
    changePage,
    getTasksFilingByTab,
    markMaterialsRequested,
    markReadyForReview,
    proxy,
    setFilingTypeFilter,
    setSearch,
    unmarkMaterialsRequested,
    unmarkReadyForReview,
    setIsNewFilingDialogOpen,
  };
};

const Proxy = createContainer(useProxy);

export { Proxy, useProxy };
