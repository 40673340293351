import React from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

type ActionButtonsProps = {
  onDelete?: OnClick;
  onEdit: OnClick;
};

type OnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

function ActionButtons({ onDelete, onEdit }: ActionButtonsProps) {
  return (
    <div
      className="flex items-center text-light-gray text-base"
      style={{ justifySelf: 'end' }}
    >
      {onEdit && (
        <button onClick={onEdit} className="mr-3 hover:text-green">
          <FaEdit />
        </button>
      )}
      {onDelete && (
        <button onClick={onDelete} className="hover:text-red">
          <FaTrashAlt />
        </button>
      )}
    </div>
  );
}

export { ActionButtons };
