import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { GenericDate } from './GenericDate';
import { IFilingCreateValues } from '../../models/filing-form';
import { FilingDetailsFormValues } from '../../Filing/Filing';

interface RecordDateProps {
  disabled?: boolean;
}

export const RecordDate: FC<RecordDateProps> = ({ disabled }) => {
  return (
    <Field
      name="recordDate"
      render={({
        field,
        form,
      }: FieldProps<IFilingCreateValues | FilingDetailsFormValues>) => (
        <GenericDate
          field={field}
          form={form}
          label="Record Date"
          fieldName={'recordDate'}
          isRequired={true}
          disabled={disabled}
        />
      )}
    />
  );
};
