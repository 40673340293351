import React, { FC } from 'react';
import { Flex, Box } from '@rebass/grid';
import { IElectionItems } from '../../models/vote-proposal';
import {
  ProposalNumber,
  ProposalRecommendation,
  RecommendationType,
} from './VoteStyledComponents';

interface IProps {
  election: IElectionItems;
}

export const ElectionItem: FC<IProps> = ({ election }) => {
  return (
    <Flex width={1} flexDirection="column">
      <Flex px={35} pt={20} flexDirection="column">
        <ProposalNumber>Proposal {election.groupNumber}</ProposalNumber>
      </Flex>
      <Flex px={35} pb={20} flexDirection="column">
        <Flex flexDirection="row" mt={20}>
          <ProposalRecommendation width={1 / 2}>
            Voting Recommendation:{' '}
            <RecommendationType>
              {election.recommendationType}
            </RecommendationType>
          </ProposalRecommendation>
        </Flex>
        <Flex flexDirection="row" mt={20}>
          <Box width={1 / 2}>{election.details}</Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
