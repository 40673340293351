import React, { MouseEvent, useState, useContext } from 'react';
import { Modal } from '../common/Modal';
import { Dialog } from '../common/Dialog/Dialog';
import {
  Formik,
  FormikProps,
  Field,
  FieldProps,
  Form,
  FormikActions,
} from 'formik';
import { DocumentType, CorporateActionDocumentType } from '../models/document';
import Yup from 'yup';
import { InputGroup } from '../common/InputGroup';
import { Label } from '../common/Label';
import { Select } from '../common/Select';
import { Asterisk } from '../common/Asterik/Asterik';
import { Input } from '../common/Input';
import { CusipTypeaheadWithoutApi } from '../common/VoteFormComponents/CusipTypeaheadWithoutApi';
import { Text } from '../common/Text';
import { Flex } from '@rebass/grid';
import Dropzone from 'react-dropzone';
import { OutlineButton } from '../common/Buttons/OutlineButton';
import { Button } from '../common/Buttons/Button';
import styled from 'styled-components/macro';
import { privateApiAsFormData, privateApi } from '../utils/api-adapter';
import { FilingContext } from '../data/Filing.Context';
import { capitalize } from '../utils/capitalize';
import { toast } from 'react-toastify';
import { FilingType } from '../models/filing-type';

const NewDocumentFormForm = styled(Form)`
  width: 100%;
`;

const NewDocumentFormButtons = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
`;

const FileDropWrapper = styled.div`
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  margin: 32px 0;
  padding: 32px;
`;

const FileDropText = styled.p`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
`;

interface DocumentFormValues {
  document: null | File;
  documentTitle: string;
  documentType: DocumentType;
  securities: {
    id: number;
    cusip: string;
    name: string;
  }[];
  shouldDistribute: boolean;
}

export const NewDocumentForm = () => {
  const {
    addDocument,
    filing,
    isNewDocumentDialogOpen,
    setNewDocumentDialogOpen,
  } = useContext(FilingContext);
  const [isDocumentUploading, updateIsDocumentUploading] = useState<boolean>(
    false,
  );

  const createNewDocument = async (
    values: DocumentFormValues,
    actions: FormikActions<DocumentFormValues>,
  ) => {
    try {
      updateIsDocumentUploading(true);
      const formData = new FormData();
      formData.append(
        'file',
        values.document as File,
        (values.document as File).name,
      );
      const attachmentPayload = {
        filing: filing && filing.id,
        securities: values.securities.map((security) => security.id),
        name: values.documentTitle,
        type: values.documentType,
        shouldDistributeInNextMailing: values.shouldDistribute,
      };
      const attachmentsResponse = await privateApi.post(
        '/attachments/',
        attachmentPayload,
      );
      const uploadResponse = await privateApiAsFormData.post(
        `/attachments/${attachmentsResponse.data.id}/upload/`,
        formData,
      );
      updateIsDocumentUploading(false);
      addDocument(uploadResponse.data);
      actions.resetForm();
      setNewDocumentDialogOpen(false);
    } catch (error) {
      toast.error(error);
      updateIsDocumentUploading(false);
    }
  };

  return (
    filing && (
      <Modal
        isOpen={isNewDocumentDialogOpen}
        onRequestClose={() => setNewDocumentDialogOpen(false)}
      >
        <Dialog
          title="Upload Document"
          onRequestClose={() => setNewDocumentDialogOpen(false)}
        >
          <Formik
            initialValues={{
              securities: (filing && filing.securities) || [],
              document: null,
              documentType: '' as DocumentType,
              documentTitle: '',
              shouldDistribute: true,
            }}
            enableReinitialize={true}
            validationSchema={() =>
              Yup.object().shape({
                securities: Yup.array().required(),
                document: Yup.object().required(),
                documentType: Yup.string().required(),
                documentTitle: Yup.string().required(),
                shouldDistribute: Yup.bool().required(),
              })
            }
            onSubmit={createNewDocument}
            render={(formikBag: FormikProps<DocumentFormValues>) => (
              <NewDocumentFormForm>
                <Field
                  name="documentType"
                  render={({ field, form }: FieldProps<DocumentFormValues>) => (
                    <InputGroup>
                      <Label>
                        Document Type <Asterisk />
                      </Label>
                      <Select
                        {...field}
                        required={true}
                        onChange={(event) => {
                          form.setFieldValue(
                            'documentType',
                            event.currentTarget.value,
                          );
                          const optionText =
                            event.target.options[event.target.selectedIndex]
                              .text;
                          form.setFieldValue('documentTitle', optionText);
                        }}
                      >
                        <option value="" disabled={true}>
                          Select a Document Type
                        </option>
                        {Object.values(
                          filing.type === FilingType.CorporateAction
                            ? CorporateActionDocumentType
                            : DocumentType,
                        ).map((documentType) => (
                          <option key={documentType} value={documentType}>
                            {capitalize(documentType.split('_').join(' '))}
                            {(documentType === 'summary' ||
                              documentType === 'statutory') &&
                              ' Prospectus'}
                          </option>
                        ))}
                      </Select>
                    </InputGroup>
                  )}
                />

                <Field
                  name="documentTitle"
                  render={({ field, form }: FieldProps<DocumentFormValues>) => (
                    <InputGroup>
                      <Label>
                        Document Title <Asterisk />
                      </Label>
                      <Input {...field} placeholder="Super Great Document" />
                    </InputGroup>
                  )}
                />

                {filing && (
                  <CusipTypeaheadWithoutApi cusips={filing.securities} />
                )}

                <Field
                  name="shouldDistribute"
                  render={({ field }: FieldProps<DocumentFormValues>) => (
                    <InputGroup>
                      <Label>
                        Distribute <Asterisk />
                      </Label>
                      <Select {...field}>
                        <option value="true">Distribute to Shareholders</option>
                        <option value="false">
                          Don't Distribute to Shareholders
                        </option>
                      </Select>
                    </InputGroup>
                  )}
                />

                <Field
                  name="document"
                  render={({ field, form }: FieldProps<DocumentFormValues>) =>
                    field.value ? (
                      <Flex
                        p={2}
                        my={3}
                        bg="background"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text color="blue" fontSize={0} letterSpacing="body">
                          {field.value.name}
                        </Text>

                        <button
                          onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            event.preventDefault();
                            formikBag.setFieldValue('document', null);
                          }}
                        >
                          x
                        </button>
                      </Flex>
                    ) : (
                      <Dropzone
                        onDrop={(acceptedFiles: File[]) =>
                          form.setFieldValue('document', acceptedFiles[0])
                        }
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps, isDragActive }) => {
                          return (
                            <FileDropWrapper {...getRootProps()}>
                              <input {...getInputProps()} />
                              <FileDropText>
                                {isDragActive
                                  ? 'Drop file here'
                                  : 'Drag and drop a file or click to browse'}
                              </FileDropText>
                            </FileDropWrapper>
                          );
                        }}
                      </Dropzone>
                    )
                  }
                />

                <NewDocumentFormButtons>
                  <OutlineButton
                    type="button"
                    onClick={() => setNewDocumentDialogOpen(false)}
                  >
                    Cancel
                  </OutlineButton>
                  <Button
                    disabled={!formikBag.isValid || isDocumentUploading}
                    type="submit"
                    onClick={(event: MouseEvent<HTMLButtonElement>) => {
                      event.preventDefault();
                      formikBag.submitForm();
                    }}
                  >
                    {isDocumentUploading ? 'Uploading...' : 'Upload'}
                  </Button>
                </NewDocumentFormButtons>
              </NewDocumentFormForm>
            )}
          />
        </Dialog>
      </Modal>
    )
  );
};
